import { useApolloClient } from '@apollo/client';
import {
  useToast,
  useCurrentBoardId,
  SortBoardType,
  TOAST_ERROR_GENERIC,
  getBoardFromCache,
  optimisticSortBoard,
  log,
} from '@spoke/common';
import { useSortBoardMutation, List } from '@spoke/graphql';

export const useSortBoardHandler = () => {
  const [toast] = useToast();
  const [boardId] = useCurrentBoardId();
  const [sortBoard] = useSortBoardMutation();
  const { cache } = useApolloClient();

  const handleSortBoard = async (type: SortBoardType) => {
    if (!boardId || !type) {
      log.error('Missing critial data to sort board', { boardId, type });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const boardFromCache = getBoardFromCache({ cache, boardId });

    const currentLists = boardFromCache?.lists;

    const expected = optimisticSortBoard({
      lists: currentLists as List[],
      type,
    });

    const { data, errors } = await sortBoard({
      variables: { boardId, type },
      optimisticResponse: expected,
    });

    if (errors) {
      log.error('sortBoard call responded with errors', {
        boardId,
        type,
        errors,
        data,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }
  };

  return [handleSortBoard];
};
