import { ImportBoardFieldMapping } from '../../types';
import { groupBy } from '../etc';
import { BoardsInput, ListInput } from '@spoke/graphql';

export const csvMapToImportBoardInputs = (
  data: Record<string, string>[],
  map: ImportBoardFieldMapping
): BoardsInput[] => {
  const dataByBoard = groupBy(data, map.boardName as string);
  const boardInputs: BoardsInput[] = [];

  for (const boardName in dataByBoard) {
    const dataByList = groupBy(dataByBoard[boardName], map.listName as string);

    const lists: ListInput[] = [];

    for (const listName in dataByList) {
      const listInput: ListInput = {
        name: listName,
        cards: dataByList[listName].map((row) => ({
          text: row[map.cardText as string],
          votes: map.voteCount ? Number(row[map.voteCount as string]) : 0,
          groupAnchor: null, // TODO: add support for groups
          comments: [], // TODO add support for comments
        })),
      };
      lists.push(listInput);
    }

    const boardDate = new Date(
      dataByBoard[boardName][0][map.boardDate as string] || Date.now()
    ).getTime();

    const boardInput: BoardsInput = {
      boardName,
      boardDate,
      lists,
    };

    boardInputs.push(boardInput);
  }

  return boardInputs;
};
