import { useCallback } from 'react';
import {
  useCurrentBoardId,
  useToast,
  TOAST_ERROR_GENERIC,
  SpkHttp,
  log,
} from '@spoke/common';

export const useExportBoardEmailHandler = () => {
  const [boardId] = useCurrentBoardId();
  const [toast] = useToast();

  const handleExportBoardEmail = useCallback(
    async (emails: string[]) => {
      if (!boardId) {
        log.error('Cannot export board to email without loaded board id', {
          boardId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const to = emails.join(',');
      const success = await SpkHttp.exportBoardEmail(boardId, to);

      if (!success) {
        log.error('Export board to email responded with unsuccessful status', {
          boardId,
          to,
          success,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      toast({
        status: 'success',
        title: 'Request processed',
        description:
          'Your request has been processed. Emails should be received shortly.',
      });
    },
    [boardId, toast]
  );

  return [handleExportBoardEmail];
};
