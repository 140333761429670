import { TimeSeries, Trend } from '@spoke/graphql';

export class SpkMath {
  static coerce(value: number, minInclusive: number, maxInclusive: number) {
    return Math.min(Math.max(value, minInclusive), maxInclusive);
  }

  static percentChange(value: number, prevValue: number): number {
    const diff = value - prevValue;
    const prevValueIsIndivisible =
      prevValue === 0 || !Number.isFinite(prevValue);
    const percentChange = Math.round(
      (diff / (prevValueIsIndivisible ? 1 : prevValue)) * 100
    );
    return percentChange;
  }

  static findMiddleOfLargestGap(values: number[]) {
    const sortedValues = values.sort((a, b) => a - b);
    const gaps = sortedValues
      .slice(1)
      .map((value, index) => value - sortedValues[index]);
    const largestGapIndex = gaps.indexOf(Math.max(...gaps));
    const middleOfLargestGap =
      (sortedValues[largestGapIndex] + sortedValues[largestGapIndex + 1]) / 2;
    return middleOfLargestGap;
  }

  static changeByTrend(
    values: TimeSeries[],
    searchForTrend: Trend
  ): {
    changeValue: number;
    changePercent: number;
    changeTimeMs: number;
    latestValue: number;
    comparedValue: number;
  } {
    const sortedValues = [...values].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    const latest = sortedValues[sortedValues.length - 1];
    const pointToCompare = sortedValues
      .slice(0, sortedValues.length - 1)
      .reduce(
        (acc, cur) =>
          searchForTrend === Trend.Up
            ? (cur.value || 0) < (acc.value || 0)
              ? cur
              : acc
            : (cur.value || 0) > (acc.value || 0)
            ? cur
            : acc,
        sortedValues[0]
      );
    const changeTimeMs =
      new Date(latest.date).getTime() - new Date(pointToCompare.date).getTime();
    const changeValue = (latest.value || 0) - (pointToCompare.value || 0);
    const changePercent = SpkMath.percentChange(
      latest.value || 0,
      pointToCompare.value || 0
    );

    return {
      changeValue,
      changePercent,
      changeTimeMs,
      latestValue: latest.value || 0,
      comparedValue: pointToCompare.value || 0,
    };
  }
}
