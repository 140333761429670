import { AnimatePresence } from 'framer-motion';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { TeamInsightFactCauseSolutionCause } from './TeamInsightFactCauseSolutionCause';
import { TeamInsightFactCauseSolutionSolution } from './TeamInsightFactCauseSolutionSolution';
import { TeamInsightFactCauseSolutionFact } from './TeamInsightFactCauseSolutionFact';
import { TeamInsightFactCauseSolutionFragmentFragment } from '@spoke/graphql';
import { Flex, FadeUp, Typewriter, Carousel, Text } from '@spoke/common';

// We're using @splidejs/react-splide
// TODO either drop it for a hand rolled more lightweight solution
// or lazy load it to reduce bundle bloat
type Props = {
  insight: TeamInsightFactCauseSolutionFragmentFragment;
  skipAnimations?: boolean;
};
export const TeamInsightFactCauseSolution: FC<Props> = ({
  insight,
  skipAnimations,
}) => {
  const [selectedCauseIdx, setSelectedCauseIdx] = useState(0);
  const [animateSuggestions, setAnimateSuggestions] = useState(true);

  /**
   * Resets all animations times by dumping and rerendering all from scratch
   */
  const [fullReset, setFullReset] = useState(false);

  const onSelectedCauseChange = (newIdx: number) => {
    setAnimateSuggestions(false);
    setSelectedCauseIdx(newIdx);
  };

  useLayoutEffect(() => {
    setFullReset(true);
  }, [insight.id]);

  useEffect(() => {
    if (!fullReset) return;
    setSelectedCauseIdx(0);
    setAnimateSuggestions(true);
    setFullReset(false);
  }, [fullReset]);

  const hasSolutions = Boolean(insight.solutions?.[selectedCauseIdx]?.length);
  const hasCauses = Boolean(insight.causes?.length);

  if (fullReset) return <Flex minH={480} />;

  return (
    <Flex flexDir="column" minH={hasSolutions ? 480 : 0}>
      <AnimatePresence>
        <TeamInsightFactCauseSolutionFact
          skipAnimations={skipAnimations}
          date={new Date(insight.date)}
          fact={insight.fact}
        />
        {hasCauses && (
          <>
            <FadeUp
              key="possible-causes-title"
              delay={skipAnimations ? 0 : 7}
              duration={skipAnimations ? 0 : 1}
            >
              <Text
                textTransform="uppercase"
                color="gray.500"
                mx="auto"
                fontSize={12}
                fontWeight={500}
                mt={2}
                mb={1}
              >
                Possible Causes
              </Text>
            </FadeUp>
            <FadeUp
              key="possible-causes"
              delay={skipAnimations ? 0 : 8}
              duration={skipAnimations ? 0 : 1}
            >
              <Carousel
                selectedIndex={selectedCauseIdx}
                onChange={onSelectedCauseChange}
                pages={
                  insight?.causes?.map((cause, idx) => (
                    <TeamInsightFactCauseSolutionCause
                      key={idx}
                      cause={cause}
                    />
                  )) ?? []
                }
              />
            </FadeUp>
          </>
        )}
        {hasSolutions && (
          <Typewriter
            key="suggestions-title"
            skipAnimation={skipAnimations || !animateSuggestions}
            startDelayMs={skipAnimations || !animateSuggestions ? 0 : 10000}
            caretFadeDelayMs={1000}
            fontSize={18}
            mt={3}
            mb={1}
          >
            We suggest that you...
          </Typewriter>
        )}
        {hasSolutions && (
          <Flex key="suggestions" flexDir="column" gap={2} mb={2}>
            {insight.solutions?.[selectedCauseIdx]?.map((solution, idx) => {
              const _skipAnimations = skipAnimations || !animateSuggestions;
              // eslint-disable-next-line prettier/prettier
              const delay = 11 + 2 * idx;
              return (
                <FadeUp
                  key={`${selectedCauseIdx}-${idx}`}
                  delay={_skipAnimations ? 0 : delay}
                  duration={_skipAnimations ? 0 : 1}
                  w="full"
                  flexDir="column"
                >
                  <TeamInsightFactCauseSolutionSolution
                    solution={solution}
                    typeStartDelayMs={
                      skipAnimations
                        ? 0
                        : animateSuggestions
                        ? delay * 1000
                        : 100
                    }
                  />
                </FadeUp>
              );
            })}
          </Flex>
        )}
      </AnimatePresence>
    </Flex>
  );
};
