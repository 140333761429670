import { FC, useState } from 'react';
import { MdOutlineEmail } from 'react-icons/md';
import { useExportBoardEmailHandler } from '../../handlers';
import {
  ModalProps,
  useDisclosure,
  callIfExists,
  EMAIL_PATTERN,
  INVALID_EMAIL_ADDRESS,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Heading,
  ModalBody,
  Flex,
  MultiTypeAhead,
  Icon,
  MultiTypeAheadFields,
  HStack,
  MultiTypeAheadAddField,
  Button,
  Modal,
  ModalOverlay,
  Text,
} from '@spoke/common';

type ExportBoardEmailModalProps = Omit<ModalProps, 'children'>;

export const ExportBoardEmailModalContent: FC<ExportBoardEmailModalProps> = (
  props
) => {
  const [emails, setEmails] = useState<string[]>(['']);
  const emailsInputModal = useDisclosure();

  const [handleExportEmail] = useExportBoardEmailHandler();

  const exportToEmail = async () => {
    emailsInputModal.close();
    callIfExists(props.onClose);
    await handleExportEmail(emails);
  };

  const validateExportEmail = (text: string) => {
    const isValid = text.match(EMAIL_PATTERN);
    if (!isValid) return INVALID_EMAIL_ADDRESS;
    return null;
  };

  return (
    <ModalContent p={8} maxW={580}>
      <ModalCloseButton />
      <ModalHeader p={0}>
        <Heading mb={1} fontSize={24} color="gray.900">
          Export to email
        </Heading>
        <Text mb={4} fontSize={16} fontWeight={400} color="gray.500">
          Enter emails of everyone to send to
        </Text>
      </ModalHeader>
      <ModalBody p={0}>
        <Flex gap={6} alignItems="center" justifyContent="center">
          <MultiTypeAhead
            values={emails}
            setValues={setEmails}
            validateField={validateExportEmail}
            icon={<Icon color="gray.500" w={6} h={6} as={MdOutlineEmail} />}
            placeholder="Enter an email"
          >
            <Flex flexDir="column" alignItems="stretch" w="full">
              <MultiTypeAheadFields mb={3} />
              <HStack justifyContent="space-between">
                <MultiTypeAheadAddField>+ Add email</MultiTypeAheadAddField>
                <HStack>
                  <Button
                    size="lg"
                    variant="outlineGray"
                    onClick={props.onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={exportToEmail}
                    disabled={emails.some(validateExportEmail)}
                    size="lg"
                  >
                    Send
                  </Button>
                </HStack>
              </HStack>
            </Flex>
          </MultiTypeAhead>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

export const ExportBoardEmailModal: FC<ExportBoardEmailModalProps> = (
  props
) => (
  <Modal {...props}>
    <ModalOverlay />
    <ExportBoardEmailModalContent {...props} />
  </Modal>
);
