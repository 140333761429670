import { FC, useMemo, useState } from 'react';
import { useImportBoardsHandler } from '../../handlers';
import { ImportBoardWizardFieldMapping } from './ImportBoardWizardFieldMapping';
import { ImportBoardWizardSummary } from './ImportBoardWizardSummary';
import { ImportBoardWizardUpload } from './ImportBoardWizardUpload';
import {
  ImportBoardFieldMapping,
  csvMapToImportBoardInputs,
  ModalProps,
  Modal,
  ModalOverlay,
  log,
} from '@spoke/common';

type ImportBoardWizardModalContentProps = {
  onClose: () => void;
};
const ImportBoardWizardModalContent: FC<ImportBoardWizardModalContentProps> = ({
  onClose,
}) => {
  const [importBoards] = useImportBoardsHandler();

  const [data, setData] = useState<Record<string, string>[] | null>(null);
  const [fieldMapping, setFieldMapping] =
    useState<ImportBoardFieldMapping | null>(null);

  const parsedData = useMemo(
    () =>
      !data || !fieldMapping
        ? null
        : csvMapToImportBoardInputs(data, fieldMapping),
    [data, fieldMapping]
  );

  const onImportBoard = async () => {
    if (!parsedData) {
      log.error('No-op: tried importing boards without parsed data', {
        parsedData,
      });
      return;
    }
    log.info('Importing board', { parsedData });
    await importBoards(parsedData);
    onClose();
  };

  if (!data) {
    return <ImportBoardWizardUpload onClose={onClose} onSubmit={setData} />;
  }

  if (!fieldMapping || !parsedData) {
    return (
      <ImportBoardWizardFieldMapping
        data={data}
        onCancel={() => setData(null)}
        onSubmit={setFieldMapping}
      />
    );
  }

  return (
    <ImportBoardWizardSummary
      parsedData={parsedData}
      onCancel={() => setFieldMapping(null)}
      onConfirm={onImportBoard}
    />
  );
};

type ImportBoardModalWrapperProps = Omit<ModalProps, 'children'>;
export const ImportBoardWizardModal: FC<ImportBoardModalWrapperProps> = (
  props
) => (
  <Modal {...props}>
    <ModalOverlay />
    <ImportBoardWizardModalContent onClose={props.onClose} />
  </Modal>
);
