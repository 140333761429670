import { FC, memo, MutableRefObject } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { getIconByTeamType } from './teamSwitcherUtil';
import { TeamType } from '@spoke/graphql';
import {
  MotionFlex,
  Flex,
  Spinner,
  truncateText,
  PopoverAnchor,
  HStack,
  Icon,
  ChevronRightIcon,
  Tooltip,
  Text,
} from '@spoke/common';

type TeamBreadcrumbProps = {
  breadcrumbRefs: MutableRefObject<Record<string, HTMLDivElement>>;
  teamId: string;
  teamName: string;
  teamType: TeamType;
  isLast: boolean;
  isCurrent: boolean;
  isLoading: boolean;
  onSwitchTeam: (teamId: string) => void;
  onToggleTeamTree: () => void;
};
export const TeamBreadcrumb: FC<TeamBreadcrumbProps> = ({
  isLast,
  isLoading,
  isCurrent,
  onToggleTeamTree,
  onSwitchTeam,
  breadcrumbRefs: _breadcrumbRefs,
  teamId,
  teamName,
  teamType,
}) => (
  <Tooltip
    key={teamId}
    label={(teamName?.length || 0) >= 50 ? teamName : undefined}
    maxW={500}
    variant="white"
    placement="bottom"
    openDelay={200}
    hasArrow
  >
    <MotionFlex
      initial={{ opacity: 0, translateY: -10 }}
      key={teamId}
      animate={{ opacity: 1, translateY: 0 }}
      // exit={{ opacity: 0, translateY: -10 }} // Issues when changing from a program child to another program's child
      transition={{
        ease: 'easeOut',
        duration: 0.5,
      }}
      alignItems="center"
      ref={(ref) =>
        ref ? (_breadcrumbRefs.current[teamId as string] = ref) : null
      }
    >
      <Flex
        py={1}
        px={2}
        alignItems="center"
        transition="background-color 0.1s ease-out, box-shadow 0.1s ease-out"
        bg={isCurrent ? 'primary.50' : 'transparent'}
        cursor="pointer"
        as="button"
        borderRadius="md"
        onClick={
          isLast ? onToggleTeamTree : () => onSwitchTeam(teamId as string)
        }
        _hover={{
          bg: isCurrent ? 'primary.50' : 'gray.100',
          boxShadow: isCurrent ? 'extralight' : 'none',
        }}
      >
        {isLoading ? (
          <Spinner size="xs" style={{ marginRight: 10 }} />
        ) : (
          getIconByTeamType(teamType as TeamType)
        )}
        <Text
          mx={2}
          pt="2px"
          fontSize={14}
          fontWeight={400}
          color={isCurrent ? 'primary.500' : 'gray.600'}
        >
          {truncateText(teamName as string, 50)}
        </Text>
        {Boolean(isLast) && (
          <PopoverAnchor>
            <HStack>
              <Icon as={FiChevronDown} />
            </HStack>
          </PopoverAnchor>
        )}
      </Flex>
      {!isLast && <Icon as={ChevronRightIcon} color="gray.400" w={6} h={6} />}
    </MotionFlex>
  </Tooltip>
);

export const MemoizedTeamBreadcrumb = memo(TeamBreadcrumb);
