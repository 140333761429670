import { FC, memo } from 'react';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { useBoardSidebarState } from '../util';
import { BoardStage } from '@spoke/graphql';
import { Flex, HStack, Icon, Spacer, Text } from '@spoke/common';

type Content = { title: string; text: string; Icon: FC };

const CONTENT_BY_STAGE: Record<BoardStage, Content | null> = {
  [BoardStage.Reflect]: {
    title: '1. Reflect',
    text: 'Cards added are only visible by you',
    Icon: BsEyeSlash,
  },
  [BoardStage.Group]: {
    title: '2. Group',
    text: 'Groups created are visible to everyone',
    Icon: BsEye,
  },
  [BoardStage.Vote]: {
    title: '3. Vote',
    text: 'Votes added are only visible by you',
    Icon: BsEyeSlash,
  },
  [BoardStage.FactsStats]: null,
  [BoardStage.Discuss]: null,
  [BoardStage.Finished]: null,
};

type BoardInstructionsProps = {
  isFacilitator: boolean;
  isGuided: boolean;
  currentStage: BoardStage | null;
  votesLeft: number | null;
};
export const BoardInstructions: FC<BoardInstructionsProps> = ({
  currentStage,
  isFacilitator,
  isGuided,
  votesLeft,
}) => {
  const { sidebarIsCollapsed, sidebarWidth } = useBoardSidebarState();
  const content = currentStage ? CONTENT_BY_STAGE[currentStage] : null;

  const maxW =
    sidebarIsCollapsed || !isFacilitator
      ? isFacilitator
        ? 'calc(100vw - 50px)'
        : 'calc(100vw - 20px)'
      : `calc(100vw - ${sidebarWidth}px)`;

  return (
    <Flex
      px={6}
      pl={4}
      pt={2}
      w="full"
      maxW={maxW}
      position="sticky"
      left="-19px"
      top="0"
      justifyContent="space-between"
      transition="max-width 0.2s ease-out"
      alignItems="center"
      zIndex={1}
      bg="gray.50"
    >
      {content && isGuided ? (
        <Flex flexDir="column" mb={2}>
          <Text fontSize={16} color="gray.600" fontWeight={500}>
            {content?.title}
          </Text>
          <HStack>
            <Text color="gray.500" fontSize={13}>
              {content?.text}
            </Text>
            <Icon as={content?.Icon} color="gray.500" />
          </HStack>
        </Flex>
      ) : (
        <Spacer />
      )}
      {(currentStage === BoardStage.Vote || !isGuided) && (
        <Flex alignItems="baseline">
          <Text fontWeight={500} fontSize={18} color="gray.600" mr={1}>
            {votesLeft}{' '}
          </Text>
          <Text
            display="inline"
            fontWeight={400}
            fontSize={12}
            color="gray.500"
          >
            votes left{' '}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export const MemoizedBoardInstructions = memo(BoardInstructions);
