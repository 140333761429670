import { ChakraProps } from '@chakra-ui/react';
import { FC } from 'react';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { Icon } from './Icon';
import { HStack } from './Stack/HStack';
import { Text } from './Text';

type PaginationControlsProps = ChakraProps & {
  pageCount: number;
  currentPage: number;
  onChangePage: (newPage: number) => unknown;
};

export const PaginationControls: FC<PaginationControlsProps> = ({
  pageCount,
  onChangePage,
  currentPage,
  ...rest
}) => {
  if (pageCount <= 1) return null;
  return (
    <HStack
      color="gray.600"
      fontSize={13}
      spacing={9}
      w="full"
      justifyContent="center"
      {...rest}
    >
      <Icon
        onClick={() =>
          currentPage === 1 ? null : onChangePage(currentPage - 1)
        }
        color={currentPage === 1 ? 'gray.400' : 'inherit'}
        cursor={currentPage === 1 ? 'default' : 'pointer'}
        as={BsArrowLeft}
      />
      {Array(pageCount)
        .fill(null)
        .map((_, idx) => {
          const page = idx + 1;
          return (
            <Text
              key={page}
              as="button"
              onClick={() => onChangePage(page)}
              lineHeight={3}
              borderColor="primary.500"
              borderBottomStyle="solid"
              paddingBottom="2px"
              color={currentPage === page ? 'primary.500' : 'inherit'}
              borderBottomWidth={currentPage === page ? '1px' : 0}
            >
              {page}
            </Text>
          );
        })}
      <Icon
        onClick={() =>
          currentPage === pageCount ? null : onChangePage(currentPage + 1)
        }
        color={currentPage === pageCount ? 'gray.400' : 'inherit'}
        cursor={currentPage === pageCount ? 'default' : 'pointer'}
        as={BsArrowRight}
      />
    </HStack>
  );
};
