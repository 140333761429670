import { makeVar } from '@apollo/client';
import { useRef, useEffect } from 'react';
import { useCurrentBoardRules } from './useCurrentBoardRules';
import {
  useNetworkContext,
  sleep,
  log,
  appIsInactiveVar,
  useInactivityDetection,
  useCurrentBoardId,
} from '@spoke/common';
import {
  usePingBoardParticipationMutation,
  usePingBoardParticipationFacilitatorMutation,
} from '@spoke/graphql';

const PARTICIPATION_PING_INTERVAL = 10000;

const consecutivePingErrorsVar = makeVar<number>(0);

export const usePresencePing = (): null => {
  const { shouldPoll } = useNetworkContext();
  const [appIsInactive] = useInactivityDetection();
  const [boardId] = useCurrentBoardId();
  const [pingAsParticipant] = usePingBoardParticipationMutation();
  const [pingAsFacilitator] = usePingBoardParticipationFacilitatorMutation();

  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const { isFacilitator, isLoading } = useCurrentBoardRules();

  useEffect(() => {
    const cleanup = () => {
      if (!pingIntervalRef.current) return;
      log.info('Cleaning up presence detection ping interval', {
        isFacilitator,
        boardId,
      });
      clearInterval(pingIntervalRef.current as NodeJS.Timeout);
      pingIntervalRef.current = null;
    };

    if (
      appIsInactive ||
      pingIntervalRef.current ||
      !boardId ||
      isLoading ||
      (shouldPoll && isFacilitator) // Facilitator polling is equivalent to presence pinging (useParticipations.tsx)
    ) {
      return cleanup;
    }

    log.info('Starting presence detection ping interval', {
      isFacilitator,
      boardId,
    });

    const ping = async () => {
      const pingResult = isFacilitator
        ? await pingAsFacilitator({ variables: { input: { boardId } } })
        : await pingAsParticipant({ variables: { input: { boardId } } });

      if (pingResult.errors) {
        log.warn(
          `Presence detection ping responded with errors. Trying again in 10 seconds.`,
          { error: pingResult.errors, isFacilitator, boardId }
        );
        if (pingIntervalRef.current) clearInterval(pingIntervalRef.current);
        await sleep(10000);

        consecutivePingErrorsVar(consecutivePingErrorsVar() + 1);
        // After two minutes we give up
        if (consecutivePingErrorsVar() >= 12) return;

        if (pingIntervalRef.current) clearInterval(pingIntervalRef.current);
        pingIntervalRef.current = setInterval(
          ping,
          PARTICIPATION_PING_INTERVAL
        );
      } else {
        consecutivePingErrorsVar(0);
      }

      if (appIsInactiveVar()) {
        log.info('App is inactive, stopping presence ping');
        clearInterval(pingIntervalRef.current as NodeJS.Timeout);
        return;
      }
    };

    if (pingIntervalRef.current) clearInterval(pingIntervalRef.current);
    pingIntervalRef.current = setInterval(ping, PARTICIPATION_PING_INTERVAL);

    return cleanup;
  }, [
    boardId,
    isFacilitator,
    isLoading,
    pingAsFacilitator,
    pingAsParticipant,
    shouldPoll,
    appIsInactive,
  ]);

  return null;
};
