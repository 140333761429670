import { motion } from 'framer-motion';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  SpokeFormRules,
  EMAIL_PATTERN,
  useRouteState,
  SpkHttp,
  getEmailDomain,
  clientSideRedirect,
  SpkHead,
  Card,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  Input,
  log,
  Link,
  Image,
  Text,
  useRouter,
  useAssets,
} from '@spoke/common';

type SsoLoginFormSchema = {
  email: string;
};

const rules: SpokeFormRules<SsoLoginFormSchema> = {
  email: {
    required: 'Email is required',
    pattern: { value: EMAIL_PATTERN, message: 'Please enter a valid email' },
  },
};

type AuthSsoProps = {
  onSelectMethod?: () => void;
};
export const AuthSso: FC<AuthSsoProps> = ({ onSelectMethod }) => {
  const router = useRouter();

  const {
    handleSubmit,
    register,
    formState: { errors: formErrors, isSubmitting, isValid },
    setValue,
  } = useForm<SsoLoginFormSchema>();

  const [error, setError] = useState('');

  // This is to get context from board invites
  const [boardId] = useRouteState(null, 'boardId');
  const [inviteToken] = useRouteState(null, 'invitation');
  const [teamByInviteToken] = useRouteState(null, 'teamInviteToken');
  const [inviter] = useRouteState(null, 'inviter');

  const { illustrationHello } = useAssets();

  const handleLogin = useCallback(
    async (formData: SsoLoginFormSchema) => {
      log.info('Submitting login with SSO form', formData);
      const { email } = formData;

      const ssoState = JSON.stringify({
        boardId,
        inviteToken: teamByInviteToken ?? inviteToken,
        inviterId: inviter,
      });

      const entrypoint = await SpkHttp.getSsoRedirectUrl(email);

      if (!entrypoint) {
        log.warn('SSO entrypoint not found for email', { email });
        const domain = getEmailDomain(email);
        setError(`${domain} is not configured for SSO.`);
        return;
      }

      const ssoUrl = new URL(entrypoint);
      ssoUrl.searchParams.append('state', ssoState);
      log.info('Redirecting to SSO entrypoint', { entrypoint, ssoState });
      clientSideRedirect(ssoUrl.toString());
    },
    [boardId, inviteToken, inviter, teamByInviteToken]
  );

  useEffect(() => {
    if (router.query.email) {
      setValue('email', router.query.email as string);
      handleLogin({ email: router.query.email as string });
    }
  }, [handleLogin, router.query.email, setValue]);

  return (
    <motion.div
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{ duration: 0.15, ease: 'easeOut' }}
    >
      <SpkHead title="Login" />
      <Card w={460} p={10}>
        <Image src={illustrationHello} alt="Hello from ScatterSpoke" />
        <VStack>
          <Heading
            textAlign="center"
            size="md"
            fontWeight={500}
            color="gray.700"
          >
            Welcome Back!
          </Heading>
          <Text fontSize="sm" color="gray.500" textAlign="center" px={10}>
            Continue to your organization{' '}
            {onSelectMethod && (
              <>
                or{' '}
                <Link onClick={() => onSelectMethod()}>
                  choose another method.
                </Link>
              </>
            )}
          </Text>
        </VStack>
        <VStack
          spacing={4}
          w="full"
          as="form"
          onSubmit={handleSubmit(handleLogin)}
        >
          <FormControl isInvalid={!isValid}>
            <VStack spacing={4}>
              <FormControl isInvalid={Boolean(formErrors.email)}>
                <FormLabel htmlFor="email">Email address</FormLabel>
                <Input id="email" {...register('email', rules.email)} />
                <FormErrorMessage>
                  {formErrors?.email?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </FormControl>
          {error && (
            <FormControl isInvalid={Boolean(error)} m="0">
              <FormErrorMessage textAlign="center">{error}</FormErrorMessage>
            </FormControl>
          )}
          <Button w="full" isLoading={isSubmitting} type="submit">
            Sign in with SSO
          </Button>
        </VStack>
      </Card>
    </motion.div>
  );
};
