import {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { GoRepo } from 'react-icons/go';
import { useFetchLinkableJiraBoards } from '../../fetchers/useFetchLinkableJiraBoards';
import { useUpdateLinkedJiraBoardsHandler } from '../../handlers';
import { useCurrentTeamJiraBoards } from '../../queries';
import { useCurrentTeam } from '@spoke/user';
import { JiraBoard } from '@spoke/graphql';
import {
  FlexProps,
  useJiraRateLimitLock,
  useDisclosure,
  truncateWithCount,
  Flex,
  Icon,
  JiraIcon,
  MultiSelect,
  Button,
  Spinner,
  Spacer,
  Text,
} from '@spoke/common';

const MAX_LINKS = 1;

type Props = Omit<FlexProps, 'onChange'> & {
  maxLabelLength?: number;
  showSaveButton?: boolean;
  onSave?: () => void;
  onChange?: (linksChanged: boolean) => void;
  onLoadingChange?: (loading: boolean) => void;
  showProductIcon?: boolean;
};

export type TeamJiraBoardLinkSelectorRefAttributes = {
  onSaveLinkedJiraBoards: () => void;
};

const TeamJiraBoardLinkSelectorComponent: ForwardRefRenderFunction<
  TeamJiraBoardLinkSelectorRefAttributes,
  Props
> = (
  {
    maxLabelLength = 60,
    showSaveButton = true,
    showProductIcon = true,
    onSave,
    onChange,
    onLoadingChange,
    ...rest
  },
  ref
) => {
  const [jiraRateLimited] = useJiraRateLimitLock();
  const [currentTeam] = useCurrentTeam();

  const [fetchLinkableBoards] = useFetchLinkableJiraBoards();
  const [updateLinkedJiraBoards] = useUpdateLinkedJiraBoardsHandler();

  const [linkedJiraBoards, { loading: linkedJiraBoardsLoading }] =
    useCurrentTeamJiraBoards();
  const [linkedJiraBoardsSubmitting, setLinkedJiraBoardsSubmitting] =
    useState(false);

  const jiraBoardsDropdown = useDisclosure();

  const [editedJiraBoards, setEditedJiraBoards] = useState<JiraBoard[]>([]);
  useEffect(() => {
    if (!linkedJiraBoards || linkedJiraBoardsLoading) return;
    setEditedJiraBoards(linkedJiraBoards);
  }, [linkedJiraBoards, linkedJiraBoardsLoading]);

  const jiraBoardsLabel = editedJiraBoards.length
    ? truncateWithCount(editedJiraBoards, (board) => board.name, maxLabelLength)
    : 'Select your Jira boards';

  const linkedJiraBoardsWereNotEdited =
    linkedJiraBoards
      ?.map((r) => r.id)
      .sort()
      .join('-') ===
    editedJiraBoards
      .map((r) => r.id)
      .sort()
      .join('-');

  const onSaveLinkedJiraBoards = useCallback(async () => {
    if (linkedJiraBoardsWereNotEdited) return;
    onSave?.();
    setLinkedJiraBoardsSubmitting(true);
    await updateLinkedJiraBoards({
      jiraBoardIds: editedJiraBoards.map((board) => board.id),
    });
    setLinkedJiraBoardsSubmitting(false);
  }, [
    editedJiraBoards,
    linkedJiraBoardsWereNotEdited,
    onSave,
    updateLinkedJiraBoards,
  ]);

  const getLinkableJiraBoardsByTerm = useCallback(
    async (term: string): Promise<JiraBoard[]> => {
      const values: Array<{
        id: string;
        name: string;
        isSoftwareDevProject?: boolean;
      }> = term
        ? []
        : [...(linkedJiraBoards ?? [])].filter((board) =>
            term ? board.name.toLowerCase().includes(term.toLowerCase()) : true
          );

      if (!currentTeam?.id) return values;
      const results = await fetchLinkableBoards({ search: term });
      values.push(...results);

      return values
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((board) =>
          board.isSoftwareDevProject
            ? { ...board, disabled: false }
            : { ...board, name: `${board.name} (Unsupported)`, disabled: true }
        )
        .sort((a, b) => {
          if (a.disabled && !b.disabled) return 1;
          if (!a.disabled && b.disabled) return -1;
          return 0;
        })
        .filter(
          (board, index, self) =>
            self.findIndex((r) => r.id === board.id) === index
        );
    },
    [currentTeam?.id, fetchLinkableBoards, linkedJiraBoards]
  );

  useEffect(() => {
    onChange?.(!linkedJiraBoardsWereNotEdited);
  }, [linkedJiraBoardsWereNotEdited, onChange]);

  useEffect(() => {
    onLoadingChange?.(linkedJiraBoardsSubmitting);
  }, [linkedJiraBoardsSubmitting, onLoadingChange]);

  useImperativeHandle(
    ref,
    () => ({
      onSaveLinkedJiraBoards,
    }),
    [onSaveLinkedJiraBoards]
  );

  if (jiraRateLimited) {
    return (
      <Flex w="full" {...rest}>
        <Text color="orange.300" fontSize="sm" fontWeight="400">
          Our <Icon as={JiraIcon} pb="2px" />{' '}
          <Text as="span" fontWeight={600}>
            Jira
          </Text>{' '}
          integration is currently unavailable. Please try again in a few
          moments.
        </Text>
      </Flex>
    );
  }

  return (
    <Flex justifyContent="flex-end" w="full" gap={2} flexWrap="wrap" {...rest}>
      <MultiSelect
        searchable
        values={editedJiraBoards}
        onChange={setEditedJiraBoards}
        onClose={jiraBoardsDropdown.close}
        isOpen={jiraBoardsDropdown.isOpen}
        getOptions={getLinkableJiraBoardsByTerm}
        limit={MAX_LINKS}
        idKey="id"
        labelKeyOrFn="name"
        flex={rest.flexDir === 'column' ? undefined : '1'}
        placeholder="Type to search more boards"
      >
        <Button
          justifyContent="start"
          onClick={jiraBoardsDropdown.toggle}
          variant="outlineGray"
          leftIcon={
            <Icon
              as={showProductIcon ? JiraIcon : GoRepo}
              mr="-2px"
              ml="-4px"
            />
          }
          disabled={linkedJiraBoardsLoading}
          w="full"
          flex="1"
          overflow="hidden"
        >
          {linkedJiraBoardsLoading ? (
            <Spinner size="sm" ml={2} />
          ) : (
            <Text
              color={editedJiraBoards.length ? 'gray.700' : 'gray.500'}
              fontWeight={editedJiraBoards.length ? 500 : 400}
              fontSize={15}
              noOfLines={1}
            >
              {jiraBoardsLabel}
            </Text>
          )}
          <Spacer />
          <Icon as={BsChevronDown} />
        </Button>
      </MultiSelect>
      {showSaveButton && (
        <Button
          isDisabled={linkedJiraBoardsWereNotEdited}
          isLoading={linkedJiraBoardsSubmitting}
          height={10}
          onClick={onSaveLinkedJiraBoards}
          alignSelf={rest.flexDir === 'column' ? 'flex-end' : undefined}
        >
          Save
        </Button>
      )}
    </Flex>
  );
};

export const TeamJiraBoardLinkSelector = forwardRef(
  TeamJiraBoardLinkSelectorComponent
);
