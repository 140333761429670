import { FC } from 'react';
import { InFlightWorkItemsQueryData } from '../queries';
import { MetricsInsight } from './MetricsInsight';
import { WorkItemHeatmap } from './WorkItemHeatmap/WorkItemHeatmap';
import { InFlightWorkItemFragmentFragment } from '@spoke/graphql';
import { FlexProps, Skeleton, HStack, Text } from '@spoke/common';

type Props = FlexProps & {
  workItems: InFlightWorkItemFragmentFragment[];
  statusNames: string[];
  insight: InFlightWorkItemsQueryData['insight'];
  loading?: boolean;
};
export const InFlightWorkItems: FC<Props> = ({
  insight,
  statusNames,
  workItems,
  loading,
  ...rest
}) => (
  <Skeleton
    minH="235px"
    d="flex"
    isLoaded={!loading}
    layerStyle="outlineGray"
    flexDir="column"
    px={5}
    pt={4}
    {...rest}
  >
    <HStack justifyContent="space-between" w="full">
      <Text color="gray.700" fontSize={16} fontWeight={600} mb={3}>
        In-flight work item ages
      </Text>
    </HStack>
    <WorkItemHeatmap
      workItems={workItems ?? []}
      statusNames={statusNames ?? []}
      w="full"
    />
    <MetricsInsight mt={5} insight={insight ?? null} />
  </Skeleton>
);
