import { FC } from 'react';

export type MetricsStateSwitcherProps = {
  enabled: boolean;
  showPlaceholder: boolean;
  placeholder: () => any;
  children: any;
};

export const MetricsStateSwitcher: FC<MetricsStateSwitcherProps> = ({
  enabled,
  showPlaceholder,
  placeholder,
  children,
}) => {
  if (!enabled) return null;
  if (showPlaceholder) return placeholder();
  return children;
};
