import {
  ComponentStyleConfig,
  RadioGroup as ChakraRadioGroup,
  RadioGroupProps as ChakraRadioGroupProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const RadioGroupComponentStyle: ComponentStyleConfig = {};

type Props = ChakraRadioGroupProps;

export const RadioGroup: FC<Props> = (props) => <ChakraRadioGroup {...props} />;
