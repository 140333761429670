import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useCurrentBoardId,
  useToast,
  optimisticAddParkingLotList,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useAddParkingLotListMutation } from '@spoke/graphql';

export const useAddParkingLotListHandler = () => {
  const [addParkingLotList] = useAddParkingLotListMutation();
  const [boardId] = useCurrentBoardId();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  /**
   * Passing no parking lot item ids means we want all parking lot items
   */
  const handleAddParkingLotList = useCallback(
    async (parkingLotItemIds?: string[]) => {
      if (!boardId) {
        log.error('Missing critical data to add parking lot list', {
          boardId,
          parkingLotItemIds,
        });
        return;
      }

      // No way to build optimistic response when no ids specified
      // https://github.com/apollographql/apollo-client/issues/6797
      const expected = parkingLotItemIds
        ? optimisticAddParkingLotList({
            cache,
            boardId,
            parkingLotItemIds,
          })
        : null;

      const { errors } = await addParkingLotList({
        variables: {
          boardId,
          parkingLotItemIds,
        },
        optimisticResponse: !expected
          ? undefined
          : {
              __typename: 'Mutation',
              addParkingLotList: expected,
            },
      });

      if (errors) {
        log.error('AddParkingLotList call responded with errors', {
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [addParkingLotList, boardId, cache, toast]
  );

  return [handleAddParkingLotList];
};
