import { FC } from 'react';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import {
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  Flex,
  Center,
  TeamIconColor,
  Button,
  ProgramIconColor,
  Text,
} from '@spoke/common';
import { TeamType } from '@spoke/graphql';

type ChooseTeamTypeModalContentProps = {
  onSelect: (teamType: TeamType) => void;
  canCreatePrograms: boolean;
  hasTeamsCreated: boolean;
};
export const ChooseTeamTypeModalContent: FC<
  ChooseTeamTypeModalContentProps
> = ({ onSelect, canCreatePrograms, hasTeamsCreated }) => (
  <ModalContent p={8} pb={4} maxW={600}>
    <ModalCloseButton />
    <ModalBody p={0}>
      <ModalHeader p={0}>
        <HStack>
          <Icon w={6} h={6} as={AiOutlineUsergroupAdd} />
          <Heading mb={1} fontSize={24} color="gray.900">
            Create Team
          </Heading>
        </HStack>
        <Text fontSize={16} fontWeight={400} color="gray.700" mt={1}>
          Choose team type
        </Text>
      </ModalHeader>
      <HStack spacing={2} my={4}>
        <Flex flexDir="column" flex={1}>
          <Center layerStyle="outlineGray" minH={120} flex={1}>
            <TeamIconColor w="62px" h="62px" />
          </Center>
          <Text mt={1} fontWeight={500} color="gray.700">
            Team
          </Text>
          <Text fontWeight={400} color="gray.500" fontSize={12}>
            Create a group of individuals to participate in retrospectives
          </Text>
          <Button
            onClick={() => onSelect(TeamType.Team)}
            mt={2}
            w="full"
            variant="outlineGray"
          >
            Choose
          </Button>
        </Flex>
        <Flex flexDir="column" flex={1}>
          <Center layerStyle="outlineGray" minH={120} flex={1}>
            <ProgramIconColor mt={1} w="72px" h="72px" />
          </Center>
          <Text mt={1} fontWeight={500} color="gray.700">
            Program
          </Text>
          <Text fontWeight={400} color="gray.500" fontSize={12}>
            Create a group of teams to run scaled retrospectives
          </Text>

          <Button
            onClick={() => onSelect(TeamType.Program)}
            disabled={canCreatePrograms && !hasTeamsCreated}
            mt={2}
            w="full"
            variant="outlineGray"
          >
            {!canCreatePrograms
              ? 'Upgrade plan'
              : !hasTeamsCreated
              ? 'Please create a team first'
              : 'Choose'}
          </Button>
        </Flex>
      </HStack>
    </ModalBody>
  </ModalContent>
);
