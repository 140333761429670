import { FC } from 'react';
import { FiInbox } from 'react-icons/fi';
import { useParkingLot } from '../queries';
import { ParkingLotModal } from './ParkingLotModal';
import {
  MenuItemProps,
  useDisclosure,
  MenuItem,
  Icon,
  Flex,
  HStack,
  Badge,
  Text,
} from '@spoke/common';

type ParkingLotNavbarItemProps = MenuItemProps;
export const ParkingLotNavbarItem: FC<ParkingLotNavbarItemProps> = (props) => {
  const [parkingLot] = useParkingLot({}, { pollIfNeeded: true });
  const parkingLotItemCount = parkingLot?.length || 0;

  const parkingLotModal = useDisclosure();

  return (
    <MenuItem
      onClick={parkingLotModal.open}
      display="flex"
      gap={2}
      maxW={200}
      alignItems="start"
      {...props}
    >
      <Icon as={FiInbox} mt="2px" w={4} h={4} />
      <Flex flexDir="column">
        <HStack mb="2px">
          <Text>Parking Lot</Text>
          <Badge bg="gray.100" color="gray.600" pt="1px" lineHeight={1}>
            {parkingLotItemCount}
          </Badge>
        </HStack>
        <Text
          lineHeight="16px"
          fontSize={12}
          color="gray.500"
          fontWeight={500}
          transition="color 0.2s ease-out"
          _hover={{ color: 'gray.600' }}
        >
          Add notes or save items for future retrospectives. Parking lot items
          are tied for this team.
        </Text>
      </Flex>
      <ParkingLotModal
        isOpen={parkingLotModal.isOpen}
        onClose={parkingLotModal.close}
      />
    </MenuItem>
  );
};
