import { useCallback } from 'react';
import {
  useToast,
  useCurrentUser,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import {
  useUninstallJiraMetricsIntegrationMutation,
  InstalledOrgIntegrationsDocument,
} from '@spoke/graphql';

export const useUninstallJiraMetricsIntegrationHandler = () => {
  const [toast] = useToast();
  const [currentUser] = useCurrentUser();
  const [uninstallJiraMetricsIntegrationMutation] =
    useUninstallJiraMetricsIntegrationMutation();

  const handleUninstallJiraMetricsIntegration =
    useCallback(async (): Promise<boolean> => {
      log.info(`Uninstalling Jira Metrics integration`);

      const { data, errors } = await uninstallJiraMetricsIntegrationMutation({
        refetchQueries: [
          {
            query: InstalledOrgIntegrationsDocument,
            variables: { organizationId: currentUser?.organization?.id ?? '' },
          },
        ],
      });

      if (errors?.length) {
        toast(TOAST_ERROR_GENERIC);
        log.error(`Could not uninstall Jira Metrics integration`, {
          data,
          errors,
          currentUser: currentUser?.id,
        });
        return false;
      }

      toast({
        title: 'Success',
        description: 'Jira Metrics integration has been uninstalled.',
        status: 'success',
      });

      return true;
    }, [
      currentUser?.id,
      currentUser?.organization?.id,
      uninstallJiraMetricsIntegrationMutation,
      toast,
    ]);

  return [handleUninstallJiraMetricsIntegration] as const;
};
