import { FC } from 'react';
import { As } from '@chakra-ui/react';
import { BsChevronDown } from 'react-icons/bs';
import { useCurrentTeam } from '../queries';
import {
  Flex,
  Icon,
  Spacer,
  Menu,
  MenuButton,
  featureDisableReasonNames,
  MenuList,
  MenuItem,
  Text,
} from '@spoke/common';
import { DisableFeatureState } from '@spoke/graphql';

export type TeamFeatureSetModalItemProps = {
  name: string;
  icon: As;
  value: DisableFeatureState;
  onChange: (value: DisableFeatureState) => void;
};

export const TeamFeatureSetModalItem: FC<TeamFeatureSetModalItemProps> = ({
  name,
  icon,
  value,
  onChange,
}) => {
  const [currentTeam] = useCurrentTeam();
  if (!currentTeam) return null;

  return (
    <Flex w="full">
      <Flex flexGrow={1} alignItems="center">
        {icon && <Icon as={icon} mr={2} w={4} h={4} />}
        <Text fontSize={16} lineHeight="18px" color="gray.700">
          {name}
        </Text>
        <Spacer />
        <Menu placement="bottom-end" variant="unstyled">
          <MenuButton>
            <Flex alignItems="center">
              <Text
                ml={1}
                color={
                  value === DisableFeatureState.Enabled
                    ? 'primary.500'
                    : 'gray.500'
                }
                decoration={
                  value === DisableFeatureState.Enabled ? 'underline' : 'none'
                }
                fontSize={16}
                fontWeight={value !== DisableFeatureState.Enabled ? 400 : 500}
                cursor="pointer"
              >
                {value !== DisableFeatureState.Enabled && 'Disabled: "'}
                {featureDisableReasonNames[value]}
                {value !== DisableFeatureState.Enabled && '"'}
              </Text>
              <Icon ml={1} as={BsChevronDown} />
            </Flex>
          </MenuButton>
          <MenuList maxH="256px" overflowY="auto">
            <MenuItem
              h={8}
              fontSize={14}
              onClick={() => onChange(DisableFeatureState.Enabled)}
            >
              Enabled
            </MenuItem>
            {Object.values(DisableFeatureState)
              .filter(
                (state) =>
                  state !== DisableFeatureState.Enabled &&
                  state !== DisableFeatureState.Other
              )
              .map((reason) => (
                <MenuItem
                  key={reason}
                  h={8}
                  fontSize={14}
                  onClick={() => onChange(reason)}
                >
                  {featureDisableReasonNames[reason]}
                </MenuItem>
              ))}
            <MenuItem
              h={8}
              fontSize={14}
              onClick={() => onChange(DisableFeatureState.Other)}
            >
              {featureDisableReasonNames[DisableFeatureState.Other]}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};
