import { FC } from 'react';
import { IoLinkSharp } from 'react-icons/io5';
import { InviteTeamMemberModal } from './InviteTeamMemberModal';
import { ButtonProps, useDisclosure, Button, Icon } from '@spoke/common';

export const InviteToTeamButton: FC<ButtonProps> = ({ ...rest }) => {
  const inviteToTeamModal = useDisclosure();

  return (
    <>
      <Button
        id="invite-to-team-btn"
        variant="outlineGray"
        leftIcon={<Icon as={IoLinkSharp} color="gray.600" />}
        minW={24}
        onClick={inviteToTeamModal.open}
        {...rest}
      >
        Invite
      </Button>
      <InviteTeamMemberModal
        isOpen={inviteToTeamModal.isOpen}
        onClose={inviteToTeamModal.close}
      />
    </>
  );
};
