import {
  ChakraProps,
  ComponentStyleConfig,
  forwardRef,
  InputRightElement as ChakraInputRightElement,
} from '@chakra-ui/react';

export const InputRightElementComponentStyle: ComponentStyleConfig = {};

type Props = ChakraProps;

export const InputRightElement = forwardRef<Props, 'div'>((props, ref) => (
  <ChakraInputRightElement {...props} ref={ref} />
));

// Required for InputGroup to work correctly
InputRightElement.id = 'InputRightElement';
