import {
  PopoverArrow as ChakraPopoverArrow,
  PopoverArrowProps as ChakraPopoverArrowProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export type PopoverArrowProps = ChakraPopoverArrowProps;

export const PopoverArrow: FC<PopoverArrowProps> = (props) => (
  <ChakraPopoverArrow {...props} />
);
