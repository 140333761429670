import { useCallback } from 'react';
import {
  useToast,
  useCurrentUser,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useSetupGithubIntegrationMutation } from '@spoke/graphql';

export const useSetupGithubIntegrationHandler = () => {
  const [toast] = useToast();
  const [currentUser] = useCurrentUser();
  const [setupGithubIntegrationMutation, mutationResult] =
    useSetupGithubIntegrationMutation();

  const handleSetupGithubIntegration = useCallback(
    async (state: string) => {
      if (!state) {
        toast(TOAST_ERROR_GENERIC);
        log.error(`Invalid state string when setting up Github integration`, {
          state,
          currentUser: currentUser?.id,
        });
        return;
      }

      log.info(`Setting up Github integration`, {
        state,
      });

      const { data, errors } = await setupGithubIntegrationMutation({
        variables: { state },
      });

      if (errors?.length) {
        toast(TOAST_ERROR_GENERIC);
        log.error(`Could not install GitHub integration`, {
          state,
          data,
          errors,
          currentUser: currentUser?.id,
        });
      }

      return { data, errors };
    },
    [currentUser?.id, setupGithubIntegrationMutation, toast]
  );

  return [handleSetupGithubIntegration, mutationResult] as const;
};
