import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  ComponentStyleConfig,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

const VARIANT_OUTLINE_ROUNDED = {
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 70,
  textAlign: 'center',
  fontSize: 14,
  color: 'gray.700',
  bg: 'white',
  fontWeight: 500,
  whiteSpace: 'nowrap',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'gray.300',
  h: '42px',
  transition: 'transform 0.1s ease-out',
};

export const ButtonComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 500,
    cursor: 'pointer',
  },
  variants: {
    outlineGray: {
      borderColor: 'gray.200',
      borderWidth: 1,
      borderStyle: 'solid',
      color: 'gray.700',
      _hover: { backgroundColor: 'gray.100', borderColor: 'gray.100' },
    },
    squareOutlineGray: {
      borderColor: 'gray.200',
      borderWidth: 1,
      borderStyle: 'solid',
      color: 'gray.700',
      flexDir: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      h: 150,
      w: 150,
      gap: 4,
      _hover: { boxShadow: 'lg' },
    },
    outlinePrimary: {
      borderColor: 'gray.200',
      borderWidth: 1,
      borderStyle: 'solid',
      justifyContent: 'flex-start',
      textAlign: 'left',
      color: 'primary.600',
      h: 12,
      transition: 'background-color 0.1s ease-out',
      _hover: { backgroundColor: 'primary.25' },
      overflow: 'hidden',
    },
    outlineRounded: VARIANT_OUTLINE_ROUNDED,
    outlineRoundedIcon: {
      ...VARIANT_OUTLINE_ROUNDED,
      w: '48px',
      h: '48px',
    },
  },
  sizes: {
    lg: {
      fontSize: 16,
    },
  },
};

export type ButtonProps = ChakraButtonProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => <ChakraButton ref={ref} {...props} />
);
