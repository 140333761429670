import { FC } from 'react';
import { CreateGoalWizardModal } from './CreateGoalWizard';
import {
  FlexProps,
  useDisclosure,
  Flex,
  Button,
  Divider,
  StepsTutorial,
  SpkLink,
  HELP_DESK_URL,
  Link,
  Image,
  Text,
  useAssets,
  SpkAssetKey,
} from '@spoke/common';
import { useTeamType } from '@spoke/user';

const TUTORIAL_STEPS = [
  {
    image: SpkAssetKey.ImageGoalTutorial1,
    text: 'Choose from our library of goals, we cover a vast range of metrics from SPACE, DORA and Agile.',
  },
  {
    image: SpkAssetKey.ImageGoalTutorial2,
    text: 'Set a target for your goals. You can also define the initial value if you already track your goals.',
  },
  {
    image: SpkAssetKey.ImageGoalTutorial3,
    text: 'Track if your team is above or below set target with ease.',
  },
];

type TeamGoalsSummaryEmptyProps = FlexProps & {
  withTutorial?: boolean;
  afterCreated?: () => void;
};
export const TeamGoalsEmptyState: FC<TeamGoalsSummaryEmptyProps> = ({
  withTutorial = false,
  afterCreated,
  ...rest
}) => {
  const { lowercaseTeamType } = useTeamType();
  const createGoalModal = useDisclosure();

  const { illustrationIncrease } = useAssets();

  return (
    <Flex flexDir="column" alignItems="center" mb={4} {...rest}>
      <Image
        src={illustrationIncrease}
        alt="No Improvement Goals to show"
        w={300}
      />
      <Text fontSize={18} fontWeight={700} color="gray.900">
        You didn&apos;t set any goals for this {lowercaseTeamType} yet.
      </Text>
      <Text fontSize={14} fontWeight={400} mt="2px" color="gray.500">
        Go ahead and
        <Button
          onClick={createGoalModal.open}
          variant="link"
          textDecor="underline"
          fontWeight={400}
          ml={1}
          fontSize={14}
        >
          set your {lowercaseTeamType} goals.
        </Button>
      </Text>
      <CreateGoalWizardModal
        isOpen={createGoalModal.isOpen}
        onClose={createGoalModal.close}
        afterCreate={afterCreated}
      />
      {withTutorial && (
        <>
          <Divider my={7} />
          <StepsTutorial
            title={`How do ${lowercaseTeamType} goals work?`}
            subtitle={`We have a library of metrics for your ${lowercaseTeamType} to choose from and track your progress.`}
            steps={TUTORIAL_STEPS}
            footer={
              <Text color="gray.600" fontSize={14} mt={8}>
                {`You can find more information about how our ${lowercaseTeamType} goals work in
                our`}{' '}
                <SpkLink href={HELP_DESK_URL} passHref>
                  <Link target="_blank" textDecor="underline">
                    Help Desk.
                  </Link>
                </SpkLink>
              </Text>
            }
          />
        </>
      )}
    </Flex>
  );
};
