import { log } from '../../../../SpkLog';
import { Card, List, UpdateCardMutation } from '@spoke/graphql';

export type OptimisticUpdateCardOriginalListArgs = {
  card?: Card | null;
  list?: List | null;
};
export const optimisticUpdateCardOriginalList = ({
  card,
  list,
}: OptimisticUpdateCardOriginalListArgs): UpdateCardMutation | undefined => {
  if (!card || !list) {
    log.error(
      'Missing critical data for updateCardOriginalList optimistic response',
      { card, list }
    );
    return undefined;
  }

  const newCard: Card = {
    ...card,
    originalListBadge: {
      ...card.originalListBadge,
      originalListId: list.id,
      color: list.cardColor,
      text: list.name,
    },
  };

  return {
    __typename: 'Mutation',
    updateCard: newCard,
  };
};
