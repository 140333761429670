import { FC } from 'react';
import {
  SpkButtonGroupProps,
  SpkButtonGroupElement,
  SpkButtonGroup,
} from '@spoke/common';

export enum Tab {
  Unresolved = 'UNRESOLVED',
  Resolved = 'RESOLVED',
}

type ActionItemTabsProps = Omit<
  SpkButtonGroupProps,
  'selectedElementKey' | 'onSelect' | 'elements'
> & {
  tab: Tab;
  setTab: (tab: Tab) => void;
  resolvedLength: number;
  unresolvedLength: number;
};

export const ActionItemTabs: FC<ActionItemTabsProps> = ({
  tab,
  setTab,
  resolvedLength,
  unresolvedLength,
  ...rest
}) => {
  const tabs: SpkButtonGroupElement[] = [
    {
      key: Tab.Unresolved,
      label: `Unresolved (${unresolvedLength})`,
    },
    {
      key: Tab.Resolved,
      label: `Resolved (${resolvedLength})`,
    },
  ];

  return (
    <SpkButtonGroup
      elements={tabs}
      onSelect={(newTab) => setTab(newTab as Tab)}
      selectedElementKey={tab}
      {...rest}
    />
  );
};
