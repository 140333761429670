import { User } from '@sentry/nextjs';
import { FC } from 'react';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BiTrashAlt, BiUser } from 'react-icons/bi';
import { BsThreeDots } from 'react-icons/bs';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import {
  truncateWithCount,
  SpkTime,
  truncateText,
  Flex,
  HStack,
  Menu,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
  Box,
  Text,
} from '@spoke/common';

type ActionPlanActionItemCardProps = {
  actionItem: {
    id: string;
    title: string;
    assignees?: Array<Partial<User> | null | undefined> | null;
    dueDate?: number | null;
  };
  onEditClick: (actionItemId: string) => void;
  onDelete: (actionItemId: string) => void;
};
export const ActionPlanActionItemCard: FC<ActionPlanActionItemCardProps> = ({
  actionItem,
  onEditClick,
  onDelete,
}) => {
  const { title, assignees, dueDate, id } = actionItem;

  const assigneesLabel = assignees?.length
    ? truncateWithCount(assignees as User[], 'name', 15)
    : 'Unassig...';

  const dueDateLabel = dueDate
    ? SpkTime.format(dueDate, 'MMM dd, yyyy')
    : 'No due date';

  return (
    <Flex
      borderWidth={2}
      borderColor="gray.200"
      bg="white"
      borderRadius="lg"
      justifyContent="space-between"
      flexDir="column"
      w="full"
      h={85}
      py={3}
      px={3}
    >
      <HStack justifyContent="space-between">
        <Text
          fontSize={16}
          fontWeight={500}
          _hover={{ textDecoration: 'underline' }}
          cursor="pointer"
          onClick={() => onEditClick(id)}
        >
          {truncateText(title, 23)}
        </Text>
        <Box maxH="18px">
          <Menu>
            <MenuButton>
              <Icon color="gray.600" as={BsThreeDots} w={5} h={5} />
            </MenuButton>
            <MenuList>
              {onEditClick && (
                <MenuItem
                  alignItems="center"
                  gap={2}
                  onClick={() => onEditClick(id)}
                >
                  <Icon color="gray.600" as={MdOutlineModeEditOutline} />
                  <Text>Edit</Text>
                </MenuItem>
              )}
              {onDelete && (
                <MenuItem
                  alignItems="center"
                  gap={2}
                  onClick={() => onDelete(id)}
                >
                  <Icon color="gray.600" as={BiTrashAlt} />
                  <Text>Delete</Text>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        </Box>
      </HStack>
      <HStack cursor="default">
        <Flex color="gray.500" fontSize={14} fontWeight="500" gap={1}>
          <Icon as={AiTwotoneCalendar} w={4} h={4} />
          <Text whiteSpace="nowrap">{dueDateLabel}</Text>
        </Flex>
        <Flex color="gray.500" fontSize={14} fontWeight="500" gap={1}>
          <Icon as={BiUser} w={4} h={4} />
          <Text whiteSpace="nowrap">{assigneesLabel}</Text>
        </Flex>
      </HStack>
    </Flex>
  );
};
