import { MutationUpdaterFn } from '@apollo/client';
import { useCallback } from 'react';
import { useSourceControlMetrics } from '../queries';
import { useToast, TOAST_ERROR_GENERIC, NO_OP, log } from '@spoke/common';
import {
  useUpdateLinkedBitbucketRepositoriesMutation,
  UpdateLinkedBitbucketRepositoriesMutation,
  LinkedBitbucketRepositoriesQuery,
  LinkedBitbucketRepositoriesDocument,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

export const useUpdateLinkedBitbucketRepositoriesHandler = () => {
  const [_, sourceControlMetricsQuery] = useSourceControlMetrics();
  const [updateLinkedBitbucketRepositories] =
    useUpdateLinkedBitbucketRepositoriesMutation();
  const [currentTeam] = useCurrentTeam();
  const [toast] = useToast();

  const handleUpdateLinkedBitbucketRepositories = useCallback(
    async ({
      bitbucketRepositoryIds,
    }: {
      bitbucketRepositoryIds: string[];
    }): Promise<boolean> => {
      const teamId = currentTeam?.id;
      if (!Array.isArray(bitbucketRepositoryIds) || !teamId) {
        log.error(
          'Missing critical data to updateLinkedBitbucketRepositories',
          {
            bitbucketRepositoryIds,
            teamId,
          }
        );
        return false;
      }

      log.info('Updating currently linked BitBucket repositories', {
        bitbucketRepositoryIds,
        teamId,
      });

      const { errors } = await updateLinkedBitbucketRepositories({
        variables: { bitbucketRepositoryIds, teamId },
        update: updateLinkedBitbucketReposQueryAfterMutation,
      });

      if (errors) {
        log.error(
          'updateLinkedBitbucketRepositories call responded with errors',
          {
            bitbucketRepositoryIds,
            teamId,
            errors,
          }
        );
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      toast({
        status: 'success',
        title: 'Success',
        description: 'Successfully updated linked BitBucket repositories',
      });

      sourceControlMetricsQuery.refetch().catch(NO_OP);

      return true;
    },
    [
      currentTeam?.id,
      updateLinkedBitbucketRepositories,
      toast,
      sourceControlMetricsQuery,
    ]
  );

  return [handleUpdateLinkedBitbucketRepositories];
};

type UpdateLinkedBitbucketReposQueryAfterMutation =
  MutationUpdaterFn<UpdateLinkedBitbucketRepositoriesMutation>;

const updateLinkedBitbucketReposQueryAfterMutation: UpdateLinkedBitbucketReposQueryAfterMutation =
  (cache, { data }) => {
    cache.updateQuery<LinkedBitbucketRepositoriesQuery>(
      {
        query: LinkedBitbucketRepositoriesDocument,
        variables: { teamId: data?.updateLinkedBitbucketRepositories.id },
      },
      (prev) => ({
        ...prev,
        team: {
          id: data?.updateLinkedBitbucketRepositories.id ?? '',
          linkedBitbucketRepositories:
            data?.updateLinkedBitbucketRepositories
              .linkedBitbucketRepositories ?? [],
        },
      })
    );
  };
