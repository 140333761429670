import { FC, memo } from 'react';
import { MemoizedParticipantMenu } from './ParticipantMenu';
import { HStack, Flex, Badge, Spacer, Text } from '@spoke/common';
import {
  BoardParticipation,
  BoardStage,
  BoardParticipationStatus,
} from '@spoke/graphql';

type ParticipantProps = {
  data: BoardParticipation;
  currentStage?: BoardStage | null;
  isClient?: boolean;
  isFacilitator?: boolean;
  onGrantFacilitator?: (userId: string) => void;
  onRevokeFacilitator?: (userId: string) => void;
};
export const Participant: FC<ParticipantProps> = ({
  data,
  isFacilitator,
  isClient,
  currentStage,
  onGrantFacilitator,
  onRevokeFacilitator,
}) => {
  const displayVoteCount = Boolean(currentStage === BoardStage.Vote);
  const displayCardCount = !displayVoteCount;

  const { numberOfCards, numberOfVotes, participant, status } = data;

  const isOnline = status === BoardParticipationStatus.Online;

  return (
    <HStack color={isOnline || isClient ? 'gray.700' : 'gray.500'}>
      <Flex flexDir="column">
        <Text fontSize={14}>{participant.name}</Text>
        <HStack>
          {isClient && (
            <Badge
              variant="outlineRounded"
              color="gray.700"
              fontFamily="unset"
              fontWeight={600}
              textTransform="none"
              borderRadius="sm"
            >
              You
            </Badge>
          )}
          {isFacilitator && (
            <Badge
              variant="outlineRounded"
              color="gray.700"
              fontFamily="unset"
              fontWeight={600}
              textTransform="none"
              borderRadius="sm"
            >
              Facilitator
            </Badge>
          )}
        </HStack>
      </Flex>
      <Spacer />
      {displayVoteCount && (
        <Text fontSize={14} fontWeight={500}>
          {numberOfVotes} votes
        </Text>
      )}
      {displayCardCount && (
        <Text fontSize={14} fontWeight={500}>
          {numberOfCards} cards
        </Text>
      )}
      {!isClient && (
        <MemoizedParticipantMenu
          isFacilitator={isFacilitator}
          onGrantFacilitator={onGrantFacilitator}
          onRevokeFacilitator={onRevokeFacilitator}
          participantId={participant.id}
        />
      )}
    </HStack>
  );
};

export const MemoizedParticipant = memo(Participant);
