import {
  Square as ChakraSquare,
  SquareProps as ChakraSquareProps,
  forwardRef,
} from '@chakra-ui/react';

type SquareProps = ChakraSquareProps;

export const Square = forwardRef<SquareProps, 'div'>((props, ref) => (
  <ChakraSquare ref={ref} {...props} />
));
