import {
  ComponentStyleConfig,
  NumberInput as ChakraNumberInput,
  NumberInputProps as ChakraNumberInputProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export const NumberInputComponentStyle: ComponentStyleConfig = {
  sizes: {
    md: {
      field: {
        fontSize: 'md',
        height: 9,
        px: 2,
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'primary.500',
  },
};

type Props = ChakraNumberInputProps;

export const NumberInput = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ChakraNumberInput {...props} ref={ref} />
));
