import { FC, useEffect } from 'react';
import { BiLinkAlt } from 'react-icons/bi';
import { BsArrowRight, BsChevronDown } from 'react-icons/bs';
import { useTrelloIntegration } from '@spoke/user';
import {
  TrelloBoard,
  TrelloList,
  Button,
  Icon,
  HStack,
  Menu,
  MotionFlex,
  MenuButton,
  truncateText,
  MenuList,
  MenuItem,
  Divider,
} from '@spoke/common';

type TrelloOAuthBoardAndListSelectorProps = {
  selectedBoard: TrelloBoard | null;
  selectedList: TrelloList | null;
  onSelectBoard: (trelloBoard: TrelloBoard | null) => void;
  onSelectList: (trelloList: TrelloList | null) => void;
};
export const TrelloOAuthBoardAndListSelector: FC<
  TrelloOAuthBoardAndListSelectorProps
> = ({ onSelectBoard, onSelectList, selectedBoard, selectedList }) => {
  const {
    authenticateTrello,
    selectTrelloBoard,
    trelloBoards,
    trelloLists,
    trelloListsLoading,
    trelloLoading,
  } = useTrelloIntegration();

  useEffect(() => {
    selectTrelloBoard(selectedBoard || null);
  }, [selectedBoard, selectTrelloBoard]);

  return !trelloBoards ? (
    <Button
      variant="outlineGray"
      onClick={authenticateTrello}
      isLoading={trelloLoading}
      leftIcon={<Icon as={BiLinkAlt} />}
    >
      Connect
    </Button>
  ) : (
    <HStack>
      <Menu>
        <MotionFlex layout="position">
          <MenuButton type="button" _hover={{ bg: 'gray.100' }}>
            <Button
              as="span"
              variant="outlineGray"
              overflowX="hidden"
              type="button"
              rightIcon={<Icon as={BsChevronDown} />}
            >
              {truncateText(selectedBoard?.name || '', 25) || 'Select a board'}
            </Button>
          </MenuButton>
        </MotionFlex>
        <MenuList>
          {trelloBoards.map?.((board) => (
            <MenuItem key={board.id} onClick={() => onSelectBoard(board)}>
              {board.name}
            </MenuItem>
          ))}
          <Divider my={2} />
          <MenuItem
            key="unselect"
            fontWeight={500}
            onClick={() => onSelectBoard(null)}
          >
            None
          </MenuItem>
        </MenuList>
      </Menu>
      {selectedBoard && (
        <>
          <MotionFlex layout>
            <Icon as={BsArrowRight} />
          </MotionFlex>
          <Menu>
            <MotionFlex layout="position">
              <MenuButton
                type="button"
                disabled={trelloListsLoading}
                _hover={{ bg: 'gray.100' }}
              >
                <Button
                  as="span"
                  variant="outlineGray"
                  overflowX="hidden"
                  type="button"
                  isLoading={trelloListsLoading}
                  rightIcon={<Icon as={BsChevronDown} />}
                >
                  {truncateText(selectedList?.name || '', 25) ||
                    'Select a list'}
                </Button>
              </MenuButton>
            </MotionFlex>
            <MenuList>
              {trelloLists?.map((list) => (
                <MenuItem key={list.id} onClick={() => onSelectList(list)}>
                  {list.name}
                </MenuItem>
              ))}
              <Divider my={2} />
              <MenuItem
                key="unselect"
                fontWeight={500}
                onClick={() => onSelectList(null)}
              >
                None
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      )}
    </HStack>
  );
};
