import { makeVar } from '@apollo/client';
import { useCallback, useRef, useState } from 'react';
import { randomString } from '../../util';
import { useDebounce } from './useDebounce';

type UseAsyncDebounce<T extends (...args: any[]) => any> = [
  (...args: Parameters<T>) => void,
  Awaited<ReturnType<T>> | null,
  boolean
];

export const useAsyncDebounce = <T extends (...args: any[]) => any>(
  callback: T,
  ms = 0
): UseAsyncDebounce<T> => {
  const [loading, setLoading] = useState(false);
  const [latestResults, setLatestResults] = useState<ReturnType<T> | null>(
    null
  );
  const queryIdVarRef = useRef(makeVar(''));

  const load = useCallback(
    async (...args: Parameters<T>) => {
      setLoading(true);
      const queryId = randomString();
      queryIdVarRef.current(queryId);
      const results = (await callback(...args)) as ReturnType<T>;
      if (queryIdVarRef.current() !== queryId) {
        // Means a more recent call has been made. Ignore this result.
        return;
      }
      setLatestResults(results);
      setLoading(false);
    },
    [callback]
  );

  const debouncedLoad = useDebounce(load, ms);

  const debouncedLoadWithLoadingUpdate = useCallback(
    (...args: Parameters<T>) => {
      setLoading(true);
      debouncedLoad(...args);
    },
    [debouncedLoad]
  );

  return [debouncedLoadWithLoadingUpdate, latestResults, loading];
};
