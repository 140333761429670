import { makeVar } from '@apollo/client';
import { useRef } from 'react';

const sequentialIdVar = makeVar(0);

/**
 * This generates a unique ID for each component that calls it
 */
export const useId = (): [string] => {
  const ref = useRef<string>();

  if (!ref.current) {
    sequentialIdVar(sequentialIdVar() + 1);
    ref.current = sequentialIdVar().toString(10);
  }

  return [ref.current];
};
