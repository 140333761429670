import { createIcon } from '@chakra-ui/react';

export const UpDownIcon = createIcon({
  displayName: 'OrganizationIcon',
  path: [
    <path
      key={1}
      d="M12 24C5.4 24 0 18.6 0 12C0 5.4 5.4 0 12 0C18.6 0 24 5.4 24 12C24 18.6 18.6 24 12 24ZM12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2Z"
      fill="currentColor"
    />,
    <path
      key={2}
      d="M15 11C14.7 11 14.5 10.9 14.3 10.7L12 8.4L9.7 10.7C9.3 11.1 8.7 11.1 8.3 10.7C7.9 10.3 7.9 9.7 8.3 9.3L11.3 6.3C11.7 5.9 12.3 5.9 12.7 6.3L15.7 9.3C16.1 9.7 16.1 10.3 15.7 10.7C15.5 10.9 15.3 11 15 11Z"
      fill="currentColor"
    />,
    <path
      key={3}
      d="M12 18C11.7 18 11.5 17.9 11.3 17.7L8.3 14.7C7.9 14.3 7.9 13.7 8.3 13.3C8.7 12.9 9.3 12.9 9.7 13.3L12 15.6L14.3 13.3C14.7 12.9 15.3 12.9 15.7 13.3C16.1 13.7 16.1 14.3 15.7 14.7L12.7 17.7C12.5 17.9 12.3 18 12 18Z"
      fill="currentColor"
    />,
  ],
});
