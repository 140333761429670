import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentBoardId,
  TOAST_ERROR_GENERIC,
  DeepPartial,
  optimisticUpdateBoard,
  log,
} from '@spoke/common';
import { useUpdateBoardMutation, Board } from '@spoke/graphql';

export const useBoardTimerChangeHandler = () => {
  const { cache } = useApolloClient();
  const [toast] = useToast();
  const [boardId] = useCurrentBoardId();
  const [updateBoard] = useUpdateBoardMutation();

  const handleTimerChange = useCallback(
    async (startTimeMs: number | null, stopTimeMs: number | null) => {
      if (!boardId) {
        log.error('Cannot update timer without loaded boardId', {
          boardId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const updateBoardFields: DeepPartial<Board> = {
        state: {},
      };

      if (startTimeMs !== null) {
        updateBoardFields.state!.timerStartTimeMiliseconds = startTimeMs;
      }
      if (stopTimeMs !== null) {
        updateBoardFields.state!.timerStopTimeMiliseconds = stopTimeMs;
      }

      const expected = optimisticUpdateBoard({
        cache,
        boardId,
        fields: updateBoardFields,
      });

      await updateBoard({
        variables: {
          boardId,
          fields: updateBoardFields,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateBoard: expected,
        },
      });
    },
    [boardId, cache, toast, updateBoard]
  );

  return [handleTimerChange];
};
