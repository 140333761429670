import {
  ModalContent as ChakraModalContent,
  ModalContentProps as ChakraModalContentProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export type ModalContentProps = ChakraModalContentProps;

export const ModalContent: FC<ModalContentProps> = (props) => (
  <ChakraModalContent {...props} />
);
