import { FC, Fragment } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { BiTrashAlt } from 'react-icons/bi';
import { BsArrowUpCircle } from 'react-icons/bs';
import { useAddParkingLotListHandler } from '../handlers';
import { useCurrentTeamParentPrograms } from '@spoke/user';
import {
  useCurrentBoardId,
  Icon,
  ColumnBoardIcon,
  MotionFlex,
  Divider,
  Tooltip,
  Text,
  useRouter,
  useRoutes,
} from '@spoke/common';

type ParkingLotToolbarProps = {
  selectedItemIds: string[];
  setSelectedItemIds: (ids: string[]) => void;
  onBulkDeleteClicked: () => void;
  onPromoteClicked: () => void;
};

export const ParkingLotToolbar: FC<ParkingLotToolbarProps> = ({
  selectedItemIds,
  setSelectedItemIds,
  onBulkDeleteClicked,
  onPromoteClicked,
}) => {
  const [parents] = useCurrentTeamParentPrograms();
  const [boardId] = useCurrentBoardId();
  const router = useRouter();
  const routes = useRoutes();

  const [handleAddParkingLotList] = useAddParkingLotListHandler();

  const handleConfirm = () => {
    if (!boardId) {
      const base64EncodedCommaSeparatedSelectedIds = Buffer.from(
        selectedItemIds.join(',')
      ).toString('base64');

      router.push({
        pathname: routes.CreateBoard,
        query: { parkingLot: base64EncodedCommaSeparatedSelectedIds },
      });
    } else {
      handleAddParkingLotList(selectedItemIds);
      setSelectedItemIds([]);
    }
  };

  const confirmIcon = boardId ? (
    <Icon
      onClick={handleConfirm}
      cursor="pointer"
      as={ColumnBoardIcon}
      mb={1}
      color="white"
    />
  ) : (
    <Icon
      onClick={handleConfirm}
      cursor="pointer"
      as={AiOutlineCheck}
      mt="5px"
      color="white"
    />
  );

  const confirmText = boardId
    ? 'Create parking lot column with selected items'
    : 'Start retro';

  return (
    <MotionFlex
      initial={{ opacity: 0, transform: 'translateY(80px)' }}
      animate={{
        opacity: 1,
        transform: 'translateY(0px)',
      }}
      exit={{
        opacity: 0,
        transform: 'translateY(80px)',
      }}
      transition={{
        duration: 0.4,
        ease: 'anticipate',
      }}
      mx="auto"
      position="absolute"
      bottom={0}
      left={0}
      right={0}
      maxW={parents?.length ? 200 : 170}
      mb={6}
      justifyContent="center"
      bg="primary.500"
      boxShadow="spread"
      borderRadius="md"
      alignItems="center"
      h="46px"
      p={2}
      px={5}
      gap={3}
    >
      <Text color="white" fontSize={14} whiteSpace="nowrap">
        {selectedItemIds.length} item
        {selectedItemIds.length !== 1 ? 's' : ''}
      </Text>
      <Divider orientation="vertical" borderColor="primary.400" h="85%" />
      <Tooltip
        variant="white"
        label={confirmText}
        shouldWrapChildren
        placement="top"
        maxW={200}
        textAlign="center"
      >
        {confirmIcon}
      </Tooltip>
      <Divider orientation="vertical" borderColor="primary.400" h="85%" />
      {!!parents?.length && (
        <Fragment>
          <Tooltip
            variant="white"
            label="Promote to Program"
            shouldWrapChildren
            placement="top"
            maxW={200}
            textAlign="center"
          >
            <Icon
              onClick={onPromoteClicked}
              cursor="pointer"
              as={BsArrowUpCircle}
              mt={1}
              color="white"
            />
          </Tooltip>
          <Divider orientation="vertical" borderColor="primary.400" h="85%" />
        </Fragment>
      )}
      <Tooltip
        variant="white"
        label="Delete selected items"
        shouldWrapChildren
        placement="top"
        maxW={200}
        textAlign="center"
      >
        <Icon
          onClick={onBulkDeleteClicked}
          cursor="pointer"
          as={BiTrashAlt}
          mt={1}
          color="white"
        />
      </Tooltip>
    </MotionFlex>
  );
};
