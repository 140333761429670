import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  UpDown,
  getBoardFromCache,
  TOAST_ERROR_GENERIC,
  optimisticUpdateListOrder,
  log,
} from '@spoke/common';
import { useUpdateListOrderMutation } from '@spoke/graphql';

type UseMoveListHandlerArgs = {
  listId: string;
  boardId: string;
};
export const useMoveListHandler = ({
  boardId,
  listId,
}: UseMoveListHandlerArgs) => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [updateListOrder] = useUpdateListOrderMutation();

  const handleMoveList = useCallback(
    async (upDown: UpDown) => {
      const boardFromCache = getBoardFromCache({ cache, boardId });

      if (!boardFromCache?.id) {
        log.error(
          'Failed to optimistic update moveList. Could not find cached board',
          { boardFromCache, boardId }
        );
      }

      const currentIdx = boardFromCache?.lists?.findIndex(
        (l) => l?.id === listId
      );

      if (typeof currentIdx !== 'number' || currentIdx < 0) {
        log.error('Failed to move list. Could not find list index in board', {
          boardFromCache,
          listId,
          currentIdx,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const toAdd = upDown === UpDown.Up ? -1 : 1;
      const newIdx = currentIdx + toAdd;
      const maxBoardListId = (boardFromCache?.lists?.length || 0) - 1;

      if (newIdx < 0 || newIdx > maxBoardListId) {
        log.warn(
          'No-op: moving list to an index less than zero or greater than max',
          { boardFromCache, upDown, currentIdx, newIdx, maxBoardListId }
        );
        return;
      }

      const newListIdsOrder =
        (boardFromCache?.lists?.map((l) => l?.id) as string[]) || [];

      newListIdsOrder[currentIdx] = newListIdsOrder.splice(
        currentIdx + toAdd,
        1,
        newListIdsOrder[currentIdx]
      )[0];

      const expected = boardFromCache
        ? optimisticUpdateListOrder({ board: boardFromCache, newListIdsOrder })
        : undefined;

      const { data, errors } = await updateListOrder({
        variables: { boardId, listIds: newListIdsOrder },
        optimisticResponse: expected,
      });

      if (errors) {
        log.error('Update list mutation (move list) responded with errors', {
          errors,
          data,
          upDown,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [boardId, cache, listId, toast, updateListOrder]
  );

  return [handleMoveList];
};
