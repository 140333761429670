import { AlertProps } from '@chakra-ui/react';
import { FC } from 'react';
import { AiFillInfoCircle, AiOutlineWarning } from 'react-icons/ai';
import {
  useTeamType,
  useCurrentOrgLockdown,
  useCurrentOrg,
  useCurrentOrgMemberships,
} from '../queries';
import { OverFreeLimitModal } from './OverFreeLimitModal';
import { PaymentStatus } from '@spoke/graphql';
import {
  useRoutes,
  useDisclosure,
  ONE_DAY_MS,
  Alert,
  Icon,
  plural,
  DefaultPlanSlug,
  FREE_MAX_USER_COUNT,
  useRouter,
  Link,
  Text,
} from '@spoke/common';

const TRIAL_PLAN_SLUG = 'unlimited-trial';

export const OrgLockdownWarning: FC<AlertProps> = ({ ...props }) => {
  const router = useRouter();
  const routes = useRoutes();
  const { isAdmin } = useTeamType();

  const [isOrgLocked] = useCurrentOrgLockdown();
  const [org] = useCurrentOrg();

  const ownerEmail = org?.owner?.email;
  const isTrialing = org?.plan?.slug === TRIAL_PLAN_SLUG;

  const [{ totalUserCount }] = useCurrentOrgMemberships({
    variables: { limit: 0 },
  });
  const overFreeLimitDisclosure = useDisclosure();

  if (
    !isOrgLocked &&
    isTrialing &&
    org?.trialEndsAt &&
    org.status === PaymentStatus.Active
  ) {
    const msRemaining = new Date(org.trialEndsAt).getTime() - Date.now();
    const daysRemaining = Math.max(Math.floor(msRemaining / ONE_DAY_MS), 0);
    const MAX_DAYS_REMAINING = 5;
    if (daysRemaining < MAX_DAYS_REMAINING) {
      return (
        <Alert bg="blue.50" color="blue.500" status="info" {...props}>
          <Icon
            as={AiFillInfoCircle}
            mr={2}
            color="blue.400"
            w={5}
            h={5}
            borderRadius="sm"
          />
          <Text>
            Your trial period{' '}
            {daysRemaining === 0
              ? 'is about to expire'
              : `ends in ${daysRemaining} ${plural('day', daysRemaining)}`}
            .{' '}
            <Text as="span">
              You can visit{' '}
              <Link
                textDecor="underline"
                color="inherit"
                onClick={() => router.push(routes.Billing)}
              >
                billing
              </Link>{' '}
              to chose a plan at anytime.
            </Text>
          </Text>
        </Alert>
      );
    }
  }

  if (!isOrgLocked) return null;

  if (org?.status === PaymentStatus.TrialExpired) {
    return (
      <Alert bg="red.50" color="red.500" status="info" {...props}>
        <Icon
          as={AiFillInfoCircle}
          mr={2}
          color="red.400"
          w={5}
          h={5}
          borderRadius="sm"
        />
        <Text>
          Trial expired. Need more time?{' '}
          <Link
            href="https://meet.scatterspoke.com/meetings/organization/trial-optimizer-book-onboarding"
            textDecor="underline"
            color="inherit"
            isExternal
          >
            Schedule
          </Link>{' '}
          an onboarding call{' '}
          {isAdmin ? (
            <Text as="span">
              or pick a plan in{' '}
              <Link
                textDecor="underline"
                color="inherit"
                onClick={() => router.push(routes.Billing)}
              >
                billing{' '}
              </Link>
              to continue.
            </Text>
          ) : (
            <Text as="span">
              or contact your administrator
              {!ownerEmail ? ' ' : ' ('}
              {!ownerEmail ? '' : <Text as="span">{ownerEmail}</Text>}
              {!ownerEmail ? '' : ') '}
              for next steps.
            </Text>
          )}
        </Text>
      </Alert>
    );
  }

  // TODO: this should go away, leaving for now
  if (
    org?.plan?.slug === DefaultPlanSlug.Free &&
    totalUserCount &&
    totalUserCount > FREE_MAX_USER_COUNT
  ) {
    return (
      <>
        <OverFreeLimitModal
          isOpen={overFreeLimitDisclosure.isOpen}
          onClose={overFreeLimitDisclosure.close}
        />
        <Alert bg="orange.100" color="orange.800" status="info" {...props}>
          <Icon
            as={AiOutlineWarning}
            mr={2}
            color="orange.800"
            w={5}
            h={5}
            borderRadius="sm"
          />
          <Text>
            You are over the free plan limit of {FREE_MAX_USER_COUNT} members.{' '}
            {isAdmin ? (
              <Link
                textDecor="underline"
                color="inherit"
                onClick={overFreeLimitDisclosure.open}
              >
                Click here to learn more.
              </Link>
            ) : (
              <Text as="span">
                Please contact your administrator
                {!ownerEmail ? ' ' : ' ('}
                {!ownerEmail ? (
                  ''
                ) : (
                  <Text as="span" fontWeight={500}>
                    {ownerEmail}
                  </Text>
                )}
                {!ownerEmail ? '' : ') '}
                for more information.
              </Text>
            )}
          </Text>
        </Alert>
      </>
    );
  }

  return (
    // this is if payments fail etc..
    <Alert bg="orange.100" color="orange.800" status="info" {...props}>
      <Icon
        as={AiOutlineWarning}
        mr={2}
        color="orange.800"
        w={5}
        h={5}
        borderRadius="sm"
      />
      <Text>
        This organization is temporarily disabled.{' '}
        {isAdmin ? (
          <Text as="span">
            Please check your{' '}
            <Link
              textDecor="underline"
              color="orange.600"
              onClick={() => router.push(routes.Billing)}
            >
              billing
            </Link>{' '}
            details.
          </Text>
        ) : (
          <Text as="span">
            Please contact your administrator
            {!ownerEmail ? ' ' : ' ('}
            {!ownerEmail ? (
              ''
            ) : (
              <Text as="span" fontWeight={500}>
                {ownerEmail}
              </Text>
            )}
            {!ownerEmail ? '' : ') '}
            for more information.
          </Text>
        )}
      </Text>
    </Alert>
  );
};
