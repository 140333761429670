import { isNullish } from '@apollo/client/cache/inmemory/helpers';
import { FC, useMemo } from 'react';
import { BsPlus } from 'react-icons/bs';
import { RetroHistoryCard } from './RetroHistoryCard';
import { TeamType, Board, Team } from '@spoke/graphql';
import {
  noneAreNullish,
  VStack,
  Skeleton,
  Flex,
  Heading,
  Button,
  Icon,
  Spinner,
  SquareGridIcon,
  Image,
  Text,
  useAssets,
} from '@spoke/common';

// TODO enforce TeamType enum?
const CREATE_RETRO_DESCRIPTION: Record<TeamType, string> = {
  [TeamType.Organization]:
    'A organization level retrospective will be visible to everyone in the account. Create a team to have private retrospectives.',
  [TeamType.Program]:
    'A program level retrospective allows you to have scaled retrospectives across teams. You can edit your teams in the program settings or invite individuals to the program.',
  [TeamType.Team]:
    'A team level retrospective is private to team members. Add team members by inviting them to the team or by making your retrospectives visible to anyone.',
  [TeamType.Portfolio]: '',
};

type RetroHistoryProps = {
  boards: Board[];
  onCreateBoard: () => unknown;
  onCloneBoard: (board: Board) => unknown;
  onSelectBoard: (id: string, isCanvas: boolean) => unknown;
  onArchiveBoard: (board: Board) => unknown;
  onToggleBoardPrivacy: (board: Board) => unknown;
  onMoveBoard: (board: Board) => unknown;
  onFetchMore: () => unknown;
  fetchMoreIsLoading: boolean;
  team?: Team | null;
  totalCount?: number | null;
};
export const RetroHistory: FC<RetroHistoryProps> = ({
  boards,
  team,
  totalCount,
  onCreateBoard,
  onSelectBoard,
  fetchMoreIsLoading,
  onFetchMore,
  onCloneBoard,
  onToggleBoardPrivacy,
  onMoveBoard,
  onArchiveBoard,
}) => {
  const noBoardsToShow: boolean = useMemo(() => totalCount === 0, [totalCount]);

  const teamType: TeamType = useMemo(() => team?.type ?? TeamType.Team, [team]);

  const { illustrationYellowPage } = useAssets();

  const retrosNotLoadedYetCount: number | null = useMemo(
    () =>
      noneAreNullish(totalCount, boards) ? totalCount! - boards.length : null,
    [totalCount, boards]
  );

  const loading = useMemo(
    () =>
      !team ||
      !boards ||
      isNullish(totalCount) ||
      isNullish(retrosNotLoadedYetCount),
    [team, boards, totalCount, retrosNotLoadedYetCount]
  );

  return (
    <VStack
      minH={400}
      alignItems="stretch"
      pb={5}
      backgroundColor="transparent"
    >
      <Skeleton
        isLoaded={!loading}
        flex="1"
        w="full"
        display="flex"
        flexDirection="column"
        justifyContent={noBoardsToShow ? 'center' : 'flex-start'}
      >
        {noBoardsToShow ? (
          <VStack w="full">
            <VStack textAlign="center" maxW={410} px={4} spacing={4}>
              <Flex direction="column" alignItems="center">
                <Image
                  src={illustrationYellowPage}
                  alt="No retrospectives created yet"
                  w={200}
                  mb={5}
                />
                <Heading size="md" color="gray.700" fontWeight={500} mb={2}>
                  No retrospectives created yet
                </Heading>
                <Text color="gray.500" fontSize={14}>
                  {CREATE_RETRO_DESCRIPTION[teamType]}
                </Text>
              </Flex>
              <Button
                onClick={onCreateBoard}
                fontSize="15px"
                iconSpacing={1}
                px={6}
                leftIcon={<Icon as={BsPlus} color="white" w={7} h={7} />}
              >
                Create {teamType.toLowerCase()} retrospective
              </Button>
            </VStack>
          </VStack>
        ) : (
          <>
            <Flex flexWrap="wrap" gap={3}>
              {boards.map((board) => (
                <RetroHistoryCard
                  key={board.id}
                  board={board}
                  onSelectBoard={onSelectBoard}
                  onCloneBoard={onCloneBoard}
                  onToggleBoardPrivacy={onToggleBoardPrivacy}
                  onMoveBoard={onMoveBoard}
                  onArchiveBoard={onArchiveBoard}
                />
              ))}
            </Flex>

            {retrosNotLoadedYetCount && retrosNotLoadedYetCount > 0 ? (
              <Button
                variant="link"
                w="fit-content"
                mx="auto"
                justifyContent="center"
                onClick={() => onFetchMore()}
                mt={8}
                fontFamily="proxima-nova-md"
                color="primary.600"
                mb={4}
                display="flex"
                alignItems="center"
                _focus={{}}
                disabled={fetchMoreIsLoading}
                leftIcon={
                  fetchMoreIsLoading ? (
                    <Spinner mb={1} h="18px" w="18px" />
                  ) : (
                    <Icon
                      as={SquareGridIcon}
                      mb={1}
                      w={5}
                      h={5}
                      cursor="pointer"
                      fontSize={15}
                    />
                  )
                }
              >
                Load more retrospectives ({retrosNotLoadedYetCount})
              </Button>
            ) : null}
          </>
        )}
      </Skeleton>
    </VStack>
  );
};
