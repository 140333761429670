export function shallowEquals<T extends Record<string, unknown>>(
  objA: T | null,
  objB: T | null
): boolean {
  if (!objA || !objB) return false;
  if (objA === objB) return true;

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);

  if (bKeys.length !== aKeys.length) return false;

  for (const key of aKeys) {
    if (
      objA[key] !== objB[key] ||
      !Object.prototype.hasOwnProperty.call(objB, key)
    ) {
      return false;
    }
  }

  return true;
}
