import { FC, useState, useMemo } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import {
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  HStack,
  Heading,
  VStack,
  plural,
  pluralSwitch,
  SpkTime,
  Button,
  Icon,
  Text,
} from '@spoke/common';
import { ImprovementGoalDataImportInput } from '@spoke/graphql';

type ImportDataWizardSummaryProps = {
  parsedData: ImprovementGoalDataImportInput[];
  onConfirm: () => Promise<void>;
  onCancel: () => void;
};
export const ImportDataWizardSummary: FC<ImportDataWizardSummaryProps> = ({
  parsedData,
  onCancel,
  onConfirm,
}) => {
  const [loading, setLoading] = useState(false);
  const pointsLength = parsedData.length;

  const minDate = useMemo(
    () => new Date(Math.min(...parsedData.map((point) => point.date))),
    [parsedData]
  );

  const maxDate = useMemo(
    () => new Date(Math.max(...parsedData.map((point) => point.date))),
    [parsedData]
  );

  const onImportData = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  };

  return (
    <ModalContent p={8} pb={4} maxW={600}>
      <ModalCloseButton />
      <ModalBody p={0}>
        <ModalHeader p={0}>
          <HStack>
            <Heading mb={1} fontSize={24} color="gray.900">
              Summary of data to import
            </Heading>
          </HStack>
          <Text fontSize={16} fontWeight={400} color="gray.700" mt={1}>
            Please review the data below and confirm that it looks correct.
          </Text>
        </ModalHeader>
        <VStack spacing={2} my={4}>
          <Text alignSelf="flex-start" fontWeight={500}>
            {pointsLength} {plural('data point', pointsLength)}{' '}
            {pluralSwitch('was', 'were', pointsLength)} found:
          </Text>
          <Text alignSelf="flex-start">
            From {SpkTime.format(minDate, 'MMM dd, yyyy')} to{' '}
            {SpkTime.format(maxDate, 'MMM dd, yyyy')}
          </Text>
        </VStack>
        <HStack mb={1} justifyContent="flex-end">
          <Button
            size="lg"
            variant="outlineGray"
            type="button"
            w="fit-content"
            onClick={onCancel}
            isDisabled={loading}
            leftIcon={
              <Icon as={FaChevronLeft} pb="2px" color="gray.600" mr="-4px" />
            }
          >
            Go back
          </Button>
          <Button
            isLoading={loading}
            size="lg"
            w="fit-content"
            type="submit"
            onClick={onImportData}
          >
            Next
          </Button>
        </HStack>
      </ModalBody>
    </ModalContent>
  );
};
