import { ApolloCache } from '@apollo/client';
import { DeepPartial } from 'react-hook-form';
import { deepMerge } from '../../../etc';
import { getActionItemFromCache } from '../../getters';
import { ActionItem } from '@spoke/graphql';

type OptimisticUpdateActionItemArgs = {
  actionItemId: string;
  fields: DeepPartial<ActionItem>;
  cache: ApolloCache<object>;
};
export const optimisticUpdateActionItem = ({
  cache,
  actionItemId,
  fields,
}: OptimisticUpdateActionItemArgs): ActionItem | null => {
  const cached = getActionItemFromCache({ actionItemId, cache });
  if (!cached) return null;
  const updated = deepMerge(cached, fields) as ActionItem;
  return updated;
};
