import {
  ComponentStyleConfig,
  Skeleton as ChakraSkeleton,
  SkeletonProps as ChakraSkeletonProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const SkeletonComponentStyle: ComponentStyleConfig = {
  defaultProps: {
    startColor: 'gray.100',
    endColor: 'gray.200',
    speed: 50,
    width: 'fit-content',
  },
};

type Props = ChakraSkeletonProps;

export const Skeleton: FC<Props> = (props) => <ChakraSkeleton {...props} />;
