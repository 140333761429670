import {
  ChakraProps,
  ComponentStyleConfig,
  forwardRef,
  InputLeftElement as ChakraInputLeftElement,
} from '@chakra-ui/react';

export const InputLeftElementComponentStyle: ComponentStyleConfig = {};

type Props = ChakraProps;

export const InputLeftElement = forwardRef<Props, 'div'>((props, ref) => (
  <ChakraInputLeftElement {...props} ref={ref} />
));

// Required for InputGroup to work correctly
InputLeftElement.id = 'InputLeftElement';
