import { FC, useMemo } from 'react';
import {
  FlexProps,
  SpkColumn,
  Tooltip,
  truncateText,
  HStack,
  COLOR_BY_QUALITY_NAME,
  SpkTime,
  Icon,
  Skeleton,
  SpokeTable,
  Link,
  Text,
  ICON_BY_WORK_ITEM_ORIGIN,
} from '@spoke/common';
import { InFlightWorkItemFragmentFragment, QualityName } from '@spoke/graphql';

const TITLE_TRUNCATE_SIZE = 55;

type Props = FlexProps & {
  workItems: InFlightWorkItemFragmentFragment[];
  loading?: boolean;
};
export const WorkItemAgeTable: FC<Props> = ({
  workItems,
  loading,
  ...rest
}) => {
  const sortedWorkItems = useMemo(
    () =>
      [...workItems].sort(
        (a, b) =>
          a.timeLeftToExternalStatusCycleTimeMs -
          b.timeLeftToExternalStatusCycleTimeMs
      ),
    [workItems]
  ).slice(0, 7);

  const columns = useMemo(() => {
    const tableColumns: SpkColumn<InFlightWorkItemFragmentFragment>[] = [
      {
        colKey: 'title',
        width: '34%',
        header: () => (
          <Text
            pl={3}
            py={3}
            color="gray.700"
            fontWeight={600}
            whiteSpace="nowrap"
          >
            Title
          </Text>
        ),
        cell: (item) => (
          <Tooltip
            label={
              item.title.length > TITLE_TRUNCATE_SIZE
                ? `[${item.slug}] ${item.title}`
                : undefined
            }
            variant="white"
            placement="top"
            fontSize={12}
            hasArrow
          >
            <Text
              color="gray.600"
              fontSize={14}
              py="8px"
              pl={3}
              w="fit-content"
              whiteSpace="nowrap"
            >
              {truncateText(item.title, TITLE_TRUNCATE_SIZE)}
            </Text>
          </Tooltip>
        ),
      },
      {
        colKey: 'status',
        width: '20%',
        header: () => (
          <Text
            pl={3}
            py={3}
            color="gray.700"
            fontWeight={600}
            whiteSpace="nowrap"
          >
            Status
          </Text>
        ),
        cell: (item) => (
          <Text
            color="gray.600"
            fontSize={14}
            py="8px"
            pl={3}
            noOfLines={1}
            whiteSpace="nowrap"
          >
            {truncateText(item.externalStatusName, 30)}
          </Text>
        ),
      },
      {
        colKey: 'cycleTime',
        width: '30%',
        header: () => (
          <HStack spacing={2}>
            <Text
              pl={3}
              py={3}
              color="gray.700"
              fontWeight={600}
              whiteSpace="nowrap"
            >
              Cycle Time Gap
            </Text>
            {/* <InfoTooltip
              size={14}
              text="Time in status of work item relative to its current status's usual cycle time"
            /> */}
          </HStack>
        ),
        cell: (item) => (
          <Text
            bg={
              COLOR_BY_QUALITY_NAME[
                item.cycleTimeQuality === QualityName.Poor ||
                item.cycleTimeQuality === QualityName.Regular
                  ? item.cycleTimeQuality
                  : item.statusAgeQuality
              ]
            }
            color={
              item.statusAgeQuality === QualityName.Regular
                ? 'gray.700'
                : 'white'
            }
            fontSize={13}
            borderRadius="4px"
            w="fit-content"
            px="5px"
            ml={3}
            py="2px"
          >
            {item.cycleTimeQuality === QualityName.Poor
              ? `${SpkTime.formatDuration(
                  Math.abs(item.timeLeftToExternalStatusCycleTimeMs),
                  'days'
                )} over overall cycle time`
              : item.cycleTimeQuality === QualityName.Regular
              ? `${SpkTime.formatDuration(
                  item.timeLeftToOverallCycleTimeMs,
                  'days'
                )} from exceeding overall cycle time`
              : item.statusAgeQuality === QualityName.Poor
              ? `${SpkTime.formatDuration(
                  Math.abs(item.timeLeftToExternalStatusCycleTimeMs),
                  'days'
                )} over current status cycle time`
              : item.statusAgeQuality === QualityName.Regular
              ? `${SpkTime.formatDuration(
                  item.timeLeftToExternalStatusCycleTimeMs,
                  'days'
                )} from exceeding current status cycle time`
              : `${SpkTime.formatDuration(
                  item.timeLeftToExternalStatusCycleTimeMs,
                  'days'
                )} from exceeding current status cycle time`}
          </Text>
        ),
      },
      {
        colKey: 'view',
        width: '12%',
        header: () => (
          <Text
            pl={3}
            py={3}
            color="gray.700"
            fontWeight={600}
            whiteSpace="nowrap"
          >
            View
          </Text>
        ),
        cell: (item) => (
          <Text color="gray.600" fontSize={14} py="8px" pl={3}>
            <Icon
              w={4}
              h={4}
              as={ICON_BY_WORK_ITEM_ORIGIN[item.origin]}
              pb="2px"
            />{' '}
            <Link
              href={item.externalUrl}
              target="_blank"
              textDecor="underline"
              fontSize={12}
              fontWeight={400}
              color="gray.600"
            >
              {item.slug}
            </Link>
          </Text>
        ),
      },
    ];

    return tableColumns;
  }, []);

  return (
    <Skeleton
      minH={loading ? '246px' : undefined}
      d="flex"
      w="full"
      isLoaded={!loading}
      {...rest}
    >
      <SpokeTable
        data={sortedWorkItems ?? []}
        keyExtractor={(item) => item.slug}
        columns={columns}
      />
    </Skeleton>
  );
};
