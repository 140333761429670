import { useCurrentUser } from '@spoke/common';
import { useCurrentUserQuery } from '@spoke/graphql';

type QueryRef = ReturnType<typeof useCurrentUserQuery>;
type QueryData = NonNullable<ReturnType<typeof useCurrentUser>[0]>['team'];

export const useCurrentTeam = (): [QueryData | null, QueryRef] => {
  const [currentUser, userQuery] = useCurrentUser();
  const currentTeam = currentUser?.team ?? null;
  return [currentTeam, userQuery];
};
