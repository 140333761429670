import { ImageProps } from '@chakra-ui/react';
import { FC } from 'react';
import { Center, CenterProps } from './Center';
import { Flex } from './Flex';
import { Image } from './Image';

type Props = Omit<CenterProps, 'background'> & {
  background?: Partial<ImageProps>;
  hoverVisible?: boolean;
};
export const HoverPlaceholder: FC<Props> = ({
  background,
  children,
  hoverVisible,
  ...rest
}) => {
  const isControlled = hoverVisible !== undefined;
  const hoverVisibleValue = hoverVisible ? 1 : 0;
  const isAlwaysHidden = isControlled && !hoverVisible;

  return (
    <Center
      bg="#F5F7FA"
      px="80px"
      position="relative"
      borderRadius="md"
      overflow="hidden"
      role="group"
      {...rest}
    >
      <Image alt="" w="full" maxW={400} {...background} />
      <Flex
        opacity={isControlled ? hoverVisibleValue : 0}
        transition="opacity 0.1s ease-out"
        _groupHover={{ opacity: isControlled ? hoverVisibleValue : 1 }}
        bg="rgba(0, 0, 0, 0.4)"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        left={0}
        top={0}
        w="full"
        height="full"
      >
        {!isAlwaysHidden && children}
      </Flex>
    </Center>
  );
};
