import { useApolloClient } from '@apollo/client';
import { FC, memo, useCallback } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import {
  HStackProps,
  UpDown,
  useSpringState,
  getUserVoteCountFromCache,
  useDebounce,
  plural,
  Box,
  HStack,
  Icon,
  Tooltip,
  log,
  isNullish,
  Text,
} from '@spoke/common';

type BoardCardVoteControlsProps = HStackProps & {
  cardId: string;
  boardId: string;
  currentUserId: string;
  voteCount: number;
  containsVotesFromCurrentUser: boolean;
  hasVotesToSpend: boolean;
  onVote?: (cardId: string, upOrDown: UpDown) => void;
};
export const BoardCardVoteControls: FC<BoardCardVoteControlsProps> = ({
  cardId,
  containsVotesFromCurrentUser,
  hasVotesToSpend,
  voteCount,
  boardId,
  currentUserId,
  onVote,
  ...rest
}) => {
  const { cache } = useApolloClient();

  const [remainingVoteCount, setRemainingVoteCount] = useSpringState<
    number | null
  >(null, 1000);

  const showRemainingVotesTooltip = useCallback(
    (upOrDown: UpDown) => {
      // This isn't ideal but actually passing this data all
      // the way to this component would reeeallly hurt performance
      const cachedData = getUserVoteCountFromCache({
        boardId,
        cache,
        userId: currentUserId,
      });
      if (isNullish(cachedData)) {
        log.warn(
          'Could not show remaining votes tooltip after vote: cached data not found'
        );
        return;
      }
      const toAdd = upOrDown === UpDown.Up ? -1 : 1;
      const newVoteCount = (cachedData?.remainingVotes ?? 0) + toAdd;
      setRemainingVoteCount(newVoteCount);
    },
    [boardId, cache, currentUserId, setRemainingVoteCount]
  );

  const onVoteClick = useCallback(
    async (upOrDown: UpDown) => {
      if (!onVote) return;
      onVote(cardId, upOrDown);
      showRemainingVotesTooltip(upOrDown);
    },
    [cardId, onVote, showRemainingVotesTooltip]
  );

  const debouncedVote = useDebounce(onVoteClick, 200);

  return (
    <HStack
      alignItems="center"
      justifyContent="center"
      mx="auto"
      mb={3}
      w="fit-content"
      bg="gray.75"
      maxH={7}
      borderRadius="2xl"
      px={2}
      py={1}
      {...rest}
    >
      <Box
        cursor="pointer"
        onClick={() => voteCount > 0 && debouncedVote(UpDown.Down)}
        display="inline"
        as="button"
        borderRadius="2xl"
        h="21px"
      >
        <Icon
          color={containsVotesFromCurrentUser ? 'gray.800' : 'gray.400'}
          bg={containsVotesFromCurrentUser ? 'white' : 'gray.75'}
          cursor={containsVotesFromCurrentUser ? 'pointer' : 'default'}
          as={AiOutlineMinus}
          borderRadius="2xl"
          p={1}
          w={5}
          h={5}
        />
      </Box>
      <Tooltip
        label={`${remainingVoteCount} ${plural(
          'vote',
          remainingVoteCount || 0
        )} remaining`}
        isOpen={remainingVoteCount !== null}
        variant="white"
        placement="bottom"
        hasArrow={false}
      >
        <Text
          tabIndex={-1}
          h={5}
          fontSize={14}
          fontWeight={500}
          textAlign="center"
          color="gray.600"
        >
          {voteCount}
        </Text>
      </Tooltip>
      <Tooltip
        label={hasVotesToSpend ? '' : "You're out of votes"}
        variant="white"
        openDelay={200}
        hasArrow
      >
        <Box
          cursor={hasVotesToSpend ? 'pointer' : 'default'}
          onClick={() => hasVotesToSpend && debouncedVote(UpDown.Up)}
          display="inline"
          as="button"
          borderRadius="2xl"
          h="21px"
        >
          <Icon
            as={AiOutlinePlus}
            color={hasVotesToSpend ? 'gray.800' : 'gray.400'}
            bg={hasVotesToSpend ? 'white' : 'gray.75'}
            borderRadius="2xl"
            p={1}
            w={5}
            h={5}
          />
        </Box>
      </Tooltip>
    </HStack>
  );
};

export const MemoizedBoardCardVoteControls = memo(BoardCardVoteControls);
