import { AnimatePresence } from 'framer-motion';
import { useState, useCallback } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { GoPlus } from 'react-icons/go';
import {
  useDeleteParkingLotItemHandler,
  useUpdateParkingLotItemTextHandler,
} from '../handlers';
import { useParkingLot } from '../queries';
import { MemoizedParkingLotItem } from './ParkingLotItem';
import { ParkingLotPromoteModal } from './ParkingLotPromoteModal';
import { ParkingLotQuickAdd } from './ParkingLotQuickAdd';
import { ParkingLotToolbar } from './ParkingLotToolbar';
import {
  useCurrentBoardId,
  Flex,
  Icon,
  Alert,
  VStack,
  Image,
  Text,
  useAssets,
} from '@spoke/common';

export const ParkingLotModalBody = () => {
  const [boardId] = useCurrentBoardId();
  const [parkingLotItems] = useParkingLot();

  const { illustrationWriting } = useAssets();

  const [handleDeleteParkingLotItem] = useDeleteParkingLotItemHandler();
  const [handleEditParkingLotItem] = useUpdateParkingLotItemTextHandler();

  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [promotingParkingLotItemIds, setPromotingParkingLotItemIds] = useState<
    string[]
  >([]);

  const handleDeleteOne = useCallback(
    (id: string) => {
      handleDeleteParkingLotItem(id);
      setSelectedItemIds((ids) => ids.filter((itemId) => itemId !== id));
    },
    [handleDeleteParkingLotItem]
  );

  const handleDeleteMany = async () => {
    await Promise.all(selectedItemIds.map(handleDeleteParkingLotItem));
    setSelectedItemIds([]);
  };

  const infoText = boardId
    ? 'Check items to create parking lot column in current retrospective'
    : 'Check items to kick off a retrospective with items from your parking lot';

  const handleToggleSelect = useCallback(
    (id: string, newSelected: boolean) => {
      if (newSelected) setSelectedItemIds([...selectedItemIds, id]);
      else {
        setSelectedItemIds(selectedItemIds.filter((itemId) => itemId !== id));
      }
    },
    [selectedItemIds]
  );

  const handlePromote = useCallback((ids: string[]) => {
    setPromotingParkingLotItemIds(ids);
  }, []);

  const onPromotionCompleted = () => {
    setSelectedItemIds([]);
    setPromotingParkingLotItemIds([]);
  };

  return (
    <Flex
      position="relative"
      flexDir="column"
      gap={2}
      pb={selectedItemIds.length ? 16 : 0}
      transition="padding-bottom 0.2s ease-out"
    >
      <ParkingLotQuickAdd confirmButtonLabel={<Icon as={GoPlus} />} />
      {Boolean(parkingLotItems?.length) && (
        <Alert bg="blue.50" color="blue.500" status="info" mb={2}>
          <Icon
            as={AiFillInfoCircle}
            mr={2}
            color="blue.400"
            w={5}
            h={5}
            borderRadius="sm"
          />
          {infoText}
        </Alert>
      )}
      {!parkingLotItems?.length && (
        <VStack mt={4} spacing={1}>
          <Image
            src={illustrationWriting}
            alt="No parking lot items to review"
            w={150}
          />
          <Text pt={2} textAlign="center" color="gray.800" fontWeight={500}>
            No parking lot items to review
          </Text>
          <Text textAlign="center" color="gray.500" maxW={350}>
            You can add parking lot items to either a new or existing
            retrospective at any time.
          </Text>
        </VStack>
      )}
      <Flex flexDir="column" gap={2} maxH="45vh" overflowX="auto" px={2}>
        {Boolean(parkingLotItems?.length) &&
          parkingLotItems?.map((item) => (
            <MemoizedParkingLotItem
              key={item.id}
              id={item.id}
              text={item.text}
              onDelete={handleDeleteOne}
              onEditText={handleEditParkingLotItem}
              isSelected={selectedItemIds.includes(item.id)}
              onPromoteToProgram={(id) => handlePromote([id])}
              onSelect={handleToggleSelect}
            />
          ))}
      </Flex>
      <AnimatePresence>
        {Boolean(selectedItemIds.length) && (
          <ParkingLotToolbar
            {...{ selectedItemIds, setSelectedItemIds }}
            onBulkDeleteClicked={handleDeleteMany}
            onPromoteClicked={() => handlePromote(selectedItemIds)}
          />
        )}
      </AnimatePresence>
      <ParkingLotPromoteModal
        isOpen={!!promotingParkingLotItemIds.length}
        onClose={() => setPromotingParkingLotItemIds([])}
        onPromoted={onPromotionCompleted}
        parkingLotItemIds={promotingParkingLotItemIds}
      />
    </Flex>
  );
};
