import {
  endOfMonth,
  endOfWeek,
  format,
  formatDistance,
  isMatch,
  parse,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { ONE_DAY_MS, ONE_HOUR_MS, ONE_MINUTE_MS } from './constants';
import { log } from './SpkLog';
import { DateRange } from './types';

type FormatDateType = 'MMM dd, yyyy' | 'MMM dd' | 'MMMM dd, yyyy';
type DateSource = Date | string | number;

export class SpkTime {
  static timeAgo(source: DateSource): string {
    const date = new Date(source);
    return formatDistance(date, new Date(), { addSuffix: true });
  }

  static millisToDays(millis: number): number {
    return millis / ONE_DAY_MS;
  }

  static format(source: DateSource, type: FormatDateType): string {
    const date = new Date(source);
    if (Number.isNaN(date.getTime())) {
      log.error('Invalid date received', { type, source, date });
      return '';
    }
    if (type === 'MMM dd, yyyy') return format(date, 'MMM dd, yyyy');
    if (type === 'MMM dd') return format(date, 'MMM dd');
    if (type === 'MMMM dd, yyyy') return format(date, 'MMMM dd, yyyy');

    log.error('Unknown date format type', { type, date });
    return '';
  }

  static getCurrent(period: 'month' | 'week'): DateRange {
    if (period === 'month') {
      return [startOfMonth(new Date()), endOfMonth(new Date())];
    }

    if (period === 'week') {
      return [startOfWeek(new Date()), endOfWeek(new Date())];
    }

    return [null, null];
  }

  static getStartOf(
    period: 'ten-minutes' | 'day' | 'week',
    source: DateSource = new Date()
  ): Date {
    if (period === 'ten-minutes') {
      const newDate = new Date(source);
      const newMinutes = Math.floor(newDate.getMinutes() / 10) * 10;
      newDate.setMinutes(newMinutes);
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);
      return newDate;
    }

    if (period === 'day') {
      const newDate = new Date(source);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    }

    if (period === 'week') {
      // Week starts on Monday
      const newDate = new Date(source);
      newDate.setHours(0, 0, 0, 0);
      newDate.setDate(newDate.getDate() - newDate.getDay() + 1);
      return newDate;
    }

    log.error(`Unknown period to get start of: ${period}`, { period });
    return new Date();
  }

  static getEndOf(
    period: 'ten-minutes' | 'day',
    source: DateSource = new Date()
  ): Date {
    if (period === 'ten-minutes') {
      const startOfTenMinutes = SpkTime.getStartOf('ten-minutes', source);
      const TEN_MINUTES_MS = 10 * 60 * 1000;
      const endOfTenMinutes = new Date(
        startOfTenMinutes.getTime() + TEN_MINUTES_MS
      );
      return endOfTenMinutes;
    }

    if (period === 'day') {
      const newDate = new Date(source);
      newDate.setHours(23, 59, 59, 999);
      return newDate;
    }

    log.error(`Unknown period to get end of: ${period}`, { period });
    return new Date();
  }

  static getAbsoluteMsFromNow(date: DateSource): number {
    const now = Date.now();
    const target = new Date(date).getTime();
    return Math.abs(now - target);
  }

  static add(source: DateSource, amount: number, unit: 'days' | 'ms'): Date {
    if (unit !== 'days' && unit !== 'ms') {
      log.error(`Unknown time unit to add: ${unit}`, { source, unit, amount });
      return new Date(source);
    }
    const multiplier = unit === 'days' ? ONE_DAY_MS : 1;
    const msToAdd = amount * multiplier;
    const ms = new Date(source).getTime() + msToAdd;
    return new Date(ms);
  }

  static amountBetween(
    sourceA: DateSource,
    sourceB: DateSource,
    unit: 'days' | 'ms'
  ): number {
    if (unit !== 'days' && unit !== 'ms') {
      log.error(`Unknown time unit to compare: ${unit}`, {
        sourceA,
        sourceB,
        unit,
      });
      return 0;
    }
    const divider = unit === 'days' ? ONE_DAY_MS : 1;
    const msDiff = new Date(sourceB).getTime() - new Date(sourceA).getTime();
    const result = Math.round(msDiff / divider);
    return result;
  }

  static fillMissingDaysBetween = (
    dateA: DateSource,
    dateB: DateSource
  ): Date[] => {
    const daysBetween = SpkTime.amountBetween(dateA, dateB, 'days');
    const dates = [dateA];
    for (let i = 1; i < daysBetween; i++) {
      dates.push(SpkTime.getStartOf('day', SpkTime.add(dateA, i, 'days')));
    }
    dates.push(dateB);
    return dates.map((d) => new Date(d));
  };

  static getLatest(sources: DateSource[]): Date | null {
    if (!sources.length) return null;
    const dates = sources.map((s) => new Date(s));
    return new Date(Math.max(...dates.map((d) => d.getTime())));
  }

  static getEarliest(sources: DateSource[]): Date | null {
    if (!sources.length) return null;
    const dates = sources.map((s) => new Date(s));
    return new Date(Math.min(...dates.map((d) => d.getTime())));
  }

  static parseIfValid(date: string): Date | null {
    const ACCEPTED_FORMATS = [
      'dd/MM/yy',
      'MM/dd/yy',
      'dd/MM/yyyy',
      'MM/dd/yyyy',
      'yy/MM/dd',
      'yy/dd/MM',
      'yyyy/MM/dd',
      'yyyy/dd/MM',
      'M/d/yy',
      'd/M/yy',
      'M/d/yyyy',
      'd/M/yyyy',
      'yyyy/M/d',
      'yyyy/d/M',
      'yy/M/d',
      'yy/d/M',
      "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
    ];

    const ALL_ACCEPTED_FORMATS = [
      ...ACCEPTED_FORMATS,
      ...ACCEPTED_FORMATS.map((f) => f.replace(/\//g, '-')),
    ];

    for (const attempt of ALL_ACCEPTED_FORMATS) {
      if (!isMatch(date, attempt)) continue;
      return parse(date, attempt, new Date(0));
    }

    return null;
  }

  static summarizedTimeToSecondsValue({
    minutes,
    seconds,
  }: {
    minutes: number;
    seconds: number;
  }) {
    return minutes * 60 + seconds;
  }

  static summarizeTimeInSeconds(amount: number) {
    const minutes = Math.floor(amount / 60);
    const seconds = amount % 60;
    return { minutes, seconds };
  }

  static getUtcOffsetMs(): number {
    // This is the offset (difference), so positive and negative are reversed
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset#negative_values_and_positive_values
    return -(new Date().getTimezoneOffset() * 60 * 1000);
  }

  static getUserTimezoneString(): string {
    const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcOffsetHours = Math.round(
      SpkTime.getUtcOffsetMs() / 1000 / 60 / 60
    );
    const utcOffsetHoursString =
      utcOffsetHours === 0
        ? ''
        : utcOffsetHours > 0
        ? `+${utcOffsetHours}`
        : utcOffsetHours;
    return `GMT${utcOffsetHoursString} (${timezoneName})`.replace(/_/g, ' ');
  }

  static formatDuration(
    ms: number,
    upTo: 'hours' | 'days' = 'hours',
    zeroAsNA = false
  ): string {
    if (ms === 0 && zeroAsNA) return 'N/A';
    if (ms === 0 && upTo === 'hours') return '0h';
    if (ms === 0 && upTo === 'days') return '0d';
    if (upTo === 'hours' && ms < ONE_HOUR_MS) return '< 1h';
    if (upTo === 'days' && ms < ONE_DAY_MS) return '< 1d';

    try {
      const duration = SpkTime.msToDaysHoursMinutes(ms);

      const durationParts: string[] = [];
      if (duration.days) durationParts.push(`${duration.days}d`);
      if (duration.hours && upTo === 'hours') {
        durationParts.push(`${duration.hours}h`);
      }

      if (!durationParts.length) return '0d';

      return durationParts.join(', ');
    } catch (err) {
      log.error(`Something went wrong in SpkTime.formatDuration: ${err}`);
      return '0d';
    }
  }

  static msToDaysHoursMinutes(ms: number): {
    days: number;
    hours: number;
    minutes: number;
  } {
    const days = Math.floor(ms / ONE_DAY_MS);
    const hours = Math.floor((ms % ONE_DAY_MS) / ONE_HOUR_MS);
    const minutes = Math.floor((ms % ONE_HOUR_MS) / ONE_MINUTE_MS);

    return { days, hours, minutes };
  }
}
