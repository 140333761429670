import { FC } from 'react';
import { useTeamMetricsFeatureState } from '../../queries';
import {
  FlexProps,
  Flex,
  Button,
  Icon,
  GithubIcon,
  BitbucketIcon,
  useRouter,
  useRoutes,
} from '@spoke/common';

export const SourceControllMetricsOrgInstallButtons: FC<
  FlexProps & { onInstallClick?: () => void }
> = ({ onInstallClick, ...props }) => {
  const router = useRouter();
  const routes = useRoutes();

  const _onInstallClick =
    onInstallClick ?? (() => router.push(routes.Integrations));

  const { githubMetrics, bitbucketMetrics } = useTeamMetricsFeatureState();

  return (
    <Flex flexDir="column" gap={2} {...props}>
      {!githubMetrics.hidden && (
        <Button
          variant="outlineGray"
          size="md"
          fontSize={15}
          h={9}
          w="full"
          mx="auto"
          bg="white"
          onClick={_onInstallClick}
          leftIcon={
            <Icon as={GithubIcon} w="18px" h="18px" mb="2px" mr="-2px" />
          }
        >
          Connect GitHub
        </Button>
      )}
      {!bitbucketMetrics.hidden && (
        <Button
          variant="outlineGray"
          size="md"
          fontSize={15}
          h={9}
          w="full"
          mx="auto"
          bg="white"
          onClick={_onInstallClick}
          leftIcon={<Icon as={BitbucketIcon} mb="2px" />}
        >
          Connect BitBucket
        </Button>
      )}
    </Flex>
  );
};
