import { useInstalledOrgIntegrationsQuery } from '@spoke/graphql';
import { useCurrentOrg } from '@spoke/user';

type QueryRef = ReturnType<typeof useInstalledOrgIntegrationsQuery>;
type QueryData = NonNullable<
  QueryRef['data']
>['installedOrganizationIntegrations'];

export const useInstalledOrgIntegrations = (): Omit<QueryData, '__typename'> & {
  loading: boolean;
} => {
  const [org] = useCurrentOrg();
  const installedIntegrations = useInstalledOrgIntegrationsQuery({
    skip: !org?.id,
    variables: { organizationId: org?.id || '' },
  });

  const data = installedIntegrations.data?.installedOrganizationIntegrations;

  const result = {
    slack: data?.slack || false,
    github: data?.github || false,
    jira: data?.jira || false,
    bitbucket: data?.bitbucket || false,
    loading: installedIntegrations.loading,
  };

  return result;
};
