import { useCallback } from 'react';
import { log } from '@spoke/common';
import {
  useCreateOrganizationMutation,
  CreateOrganizationMutation,
  CurrentUserDocument,
  TeamTreeDocument,
} from '@spoke/graphql';

export type CreateOrganizationHandlerProps = {
  orgName: string;
  skipUserRefetch?: boolean;
};

export const useCreateOrganizationHandler = () => {
  const [createOrganization] = useCreateOrganizationMutation();

  const createOrg = useCallback(
    async ({
      orgName,
      skipUserRefetch = false,
    }: CreateOrganizationHandlerProps): Promise<
      | NonNullable<
          CreateOrganizationMutation['createOrganization']
        >['organization']
      | null
    > => {
      log.info('Creating organization', { orgName });

      const { errors, data } = await createOrganization({
        variables: { orgName },
        awaitRefetchQueries: true,
        refetchQueries: [
          ...(skipUserRefetch ? [] : [{ query: CurrentUserDocument }]),
          { query: TeamTreeDocument },
        ].filter(Boolean),
      });

      const organization = data?.createOrganization?.organization;

      if (errors) {
        log.warn('createOrganization mutation responded with error', {
          error: errors,
        });
        return null;
      }

      return organization ?? null;
    },
    [createOrganization]
  );

  return [createOrg];
};
