import { Card, ParkingLotItem } from '@spoke/graphql';

export const convertParkingLotItemToCard = (
  parkingLotItem: ParkingLotItem
): Card => ({
  __typename: 'Card',
  id: parkingLotItem.id,
  archived: parkingLotItem.archived,
  authorId: parkingLotItem.author.id,
  comments: [],
  index: 0,
  listId: '',
  organizationId: '',
  sentiment: 0,
  text: parkingLotItem.text,
  votes: new Array(parkingLotItem.votes).fill('__anonymous'),
  children: [],
  originalListBadge: null,
  wasDiscussed: false,
  x: 0,
  y: 0,
  promotedToPrograms: [],
  createdAt: parkingLotItem.createdAt,
  updatedAt: Date.now(),
  parentId: null,
});
