import { IconProps, Icon } from '../Icon';

export const BusinessPlanIcon = (props: IconProps) => (
  <Icon viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Business Plan Icon</title>
    <circle
      cx="45.9129"
      cy="31.3044"
      r="1.3913"
      transform="rotate(90 45.9129 31.3044)"
      fill="currentColor"
    />
    <circle
      cx="42.3298"
      cy="41.3461"
      r="1.3913"
      transform="rotate(135 42.3298 41.3461)"
      fill="currentColor"
    />
    <circle
      cx="32.6954"
      cy="45.913"
      r="1.3913"
      transform="rotate(-180 32.6954 45.913)"
      fill="currentColor"
    />
    <circle
      cx="22.6538"
      cy="42.3299"
      r="1.3913"
      transform="rotate(-135 22.6538 42.3299)"
      fill="currentColor"
    />
    <circle
      cx="18.0869"
      cy="32.6957"
      r="1.3913"
      transform="rotate(-90 18.0869 32.6957)"
      fill="currentColor"
    />
    <circle
      cx="21.67"
      cy="22.6539"
      r="1.3913"
      transform="rotate(-45 21.67 22.6539)"
      fill="currentColor"
    />
    <circle cx="31.3041" cy="18.087" r="1.3913" fill="currentColor" />
    <circle
      cx="41.3459"
      cy="21.6701"
      r="1.3913"
      transform="rotate(45 41.3459 21.6701)"
      fill="currentColor"
    />
    <circle
      cx="47.249"
      cy="15.7675"
      r="1.21739"
      transform="rotate(45 47.249 15.7675)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="52.3"
      cy="22.8385"
      r="1.21739"
      transform="rotate(67.5 52.3 22.8385)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="54.2608"
      cy="31.3044"
      r="1.21739"
      transform="rotate(90 54.2608 31.3044)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="52.8328"
      cy="39.8761"
      r="1.21739"
      transform="rotate(112.5 52.8328 39.8761)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="48.2328"
      cy="47.2488"
      r="1.21739"
      transform="rotate(135 48.2328 47.2488)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="41.1617"
      cy="52.3001"
      r="1.21739"
      transform="rotate(157.5 41.1617 52.3001)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="32.6956"
      cy="54.2607"
      r="1.21739"
      transform="rotate(180 32.6956 54.2607)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="24.124"
      cy="52.8325"
      r="1.21739"
      transform="rotate(-157.5 24.124 52.8325)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="16.751"
      cy="48.2327"
      r="1.21739"
      transform="rotate(-135 16.751 48.2327)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="11.7"
      cy="41.1615"
      r="1.21739"
      transform="rotate(-112.5 11.7 41.1615)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="9.7392"
      cy="32.6956"
      r="1.21739"
      transform="rotate(-90 9.7392 32.6956)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="11.1674"
      cy="24.1238"
      r="1.21739"
      transform="rotate(-67.5 11.1674 24.1238)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="15.7674"
      cy="16.7512"
      r="1.21739"
      transform="rotate(-45 15.7674 16.7512)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="22.8383"
      cy="11.6999"
      r="1.21739"
      transform="rotate(-22.5 22.8383 11.6999)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="31.3044"
      cy="9.7392"
      r="1.21739"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="39.8763"
      cy="11.1677"
      r="1.21739"
      transform="rotate(22.5 39.8763 11.1677)"
      stroke="currentColor"
      strokeWidth="0.347826"
    />
    <circle
      cx="46.7019"
      cy="5.14433"
      r="1.3913"
      transform="rotate(30 46.7019 5.14433)"
      fill="currentColor"
    />
    <circle
      cx="53.1519"
      cy="9.86445"
      r="1.3913"
      transform="rotate(45 53.1519 9.86445)"
      fill="currentColor"
    />
    <circle
      cx="58.1599"
      cy="16.0932"
      r="1.3913"
      transform="rotate(60 58.1599 16.0932)"
      fill="currentColor"
    />
    <circle
      cx="61.3856"
      cy="23.4059"
      r="1.3913"
      transform="rotate(75 61.3856 23.4059)"
      fill="currentColor"
    />
    <circle
      cx="62.6087"
      cy="31.3043"
      r="1.3913"
      transform="rotate(90 62.6087 31.3043)"
      fill="currentColor"
    />
    <circle
      cx="61.7457"
      cy="39.2502"
      r="1.3913"
      transform="rotate(105 61.7457 39.2502)"
      fill="currentColor"
    />
    <circle
      cx="58.8558"
      cy="46.7019"
      r="1.3913"
      transform="rotate(120 58.8558 46.7019)"
      fill="currentColor"
    />
    <circle
      cx="54.1357"
      cy="53.1517"
      r="1.3913"
      transform="rotate(135 54.1357 53.1517)"
      fill="currentColor"
    />
    <circle
      cx="47.9068"
      cy="58.1601"
      r="1.3913"
      transform="rotate(150 47.9068 58.1601)"
      fill="currentColor"
    />
    <circle
      cx="40.5941"
      cy="61.3857"
      r="1.3913"
      transform="rotate(165 40.5941 61.3857)"
      fill="currentColor"
    />
    <circle
      cx="32.6959"
      cy="62.6087"
      r="1.3913"
      transform="rotate(180 32.6959 62.6087)"
      fill="currentColor"
    />
    <circle
      cx="24.7498"
      cy="61.7458"
      r="1.3913"
      transform="rotate(-165 24.7498 61.7458)"
      fill="currentColor"
    />
    <circle
      cx="17.2981"
      cy="58.8557"
      r="1.3913"
      transform="rotate(-150 17.2981 58.8557)"
      fill="currentColor"
    />
    <circle
      cx="10.8484"
      cy="54.1354"
      r="1.3913"
      transform="rotate(-135 10.8484 54.1354)"
      fill="currentColor"
    />
    <circle
      cx="5.84006"
      cy="47.9068"
      r="1.3913"
      transform="rotate(-120 5.84006 47.9068)"
      fill="currentColor"
    />
    <circle
      cx="2.61441"
      cy="40.5939"
      r="1.3913"
      transform="rotate(-105 2.61441 40.5939)"
      fill="currentColor"
    />
    <circle
      cx="1.3913"
      cy="32.6955"
      r="1.3913"
      transform="rotate(-90 1.3913 32.6955)"
      fill="currentColor"
    />
    <circle
      cx="2.25428"
      cy="24.7497"
      r="1.3913"
      transform="rotate(-75 2.25428 24.7497)"
      fill="currentColor"
    />
    <circle
      cx="5.14421"
      cy="17.2981"
      r="1.3913"
      transform="rotate(-60 5.14421 17.2981)"
      fill="currentColor"
    />
    <circle
      cx="9.86457"
      cy="10.8482"
      r="1.3913"
      transform="rotate(-45 9.86457 10.8482)"
      fill="currentColor"
    />
    <circle
      cx="16.0932"
      cy="5.83988"
      r="1.3913"
      transform="rotate(-30 16.0932 5.83988)"
      fill="currentColor"
    />
    <circle
      cx="23.4059"
      cy="2.61429"
      r="1.3913"
      transform="rotate(-15 23.4059 2.61429)"
      fill="currentColor"
    />
    <circle cx="31.3041" cy="1.3913" r="1.3913" fill="currentColor" />
    <circle
      cx="39.2502"
      cy="2.25428"
      r="1.3913"
      transform="rotate(15 39.2502 2.25428)"
      fill="currentColor"
    />
  </Icon>
);
