import { StackProps } from '@chakra-ui/react';
import { FC, useState, useCallback, useMemo } from 'react';
import { BiTrashAlt, BiDotsHorizontalRounded, BiPencil } from 'react-icons/bi';

import { BsArrowUpCircle } from 'react-icons/bs';
import { useParkingLot } from '../queries';
import { useCurrentTeamParentPrograms } from '@spoke/user';
import {
  Center,
  Checkbox,
  Menu,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
  SpkColumn,
  SpkTime,
  SpokeTable,
  Input,
} from '@spoke/common';

type ParkingLotItem = NonNullable<ReturnType<typeof useParkingLot>[0]>[0];
type ParkingLotListProps = StackProps & {
  parkingLotItems: ParkingLotItem[];
  onRename?: (id: string, newName: string) => void;
  onDelete?: (item: ParkingLotItem) => void;
  onPromoteToProgram?: (id: string) => void;
  setSelected?: (id: string, selected: boolean) => void;
  selectedIds?: string[];
};

export const ParkingLotTable: FC<ParkingLotListProps> = ({
  parkingLotItems,
  onPromoteToProgram,
  onRename,
  onDelete,
  setSelected,
  selectedIds,
}) => {
  const [teamParents] = useCurrentTeamParentPrograms();

  const allowSelect = !!setSelected && !!selectedIds;
  const allowActions = !!onRename || !!onDelete;

  const [isRenaming, setRenaming] = useState<string | null>(null);

  const isSelected = useCallback(
    (item: ParkingLotItem) => !!selectedIds?.includes(item.id),
    [selectedIds]
  );

  const finishRenaming = useCallback(
    (itemId: string, text: string) => {
      if (text) onRename?.(itemId, text);
      setRenaming(null);
    },
    [onRename]
  );

  const makeSelectCheckbox = useCallback(
    (item: ParkingLotItem) => (
      <Center>
        <Checkbox
          isChecked={isSelected(item)}
          onChange={(e) => setSelected?.(item.id, e.target.checked)}
        />
      </Center>
    ),
    [setSelected, isSelected]
  );

  const makeActionsMenu = useCallback(
    (item: ParkingLotItem) => (
      <Menu>
        <Center>
          <MenuButton
            cursor="pointer"
            _hover={{
              '& .dots-icon': {
                color: 'gray.900',
              },
            }}
          >
            <Icon
              w={4}
              h={4}
              as={BiDotsHorizontalRounded}
              transition="color 0.1s ease-out"
              color="gray.400"
              className="dots-icon"
            />
          </MenuButton>
        </Center>
        <MenuList>
          {!!onRename && (
            <MenuItem icon={<BiPencil />} onClick={() => setRenaming(item.id)}>
              Rename
            </MenuItem>
          )}

          {!!onPromoteToProgram && !!teamParents?.length && (
            <MenuItem
              icon={<BsArrowUpCircle />}
              onClick={() => onPromoteToProgram?.(item.id)}
            >
              Promote to Program
            </MenuItem>
          )}

          {!!onDelete && (
            <MenuItem icon={<BiTrashAlt />} onClick={() => onDelete?.(item)}>
              Delete
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    ),
    [onRename, onPromoteToProgram, teamParents?.length, onDelete]
  );

  const makeNameColumn = useCallback(
    (item: ParkingLotItem) => {
      if (isRenaming !== item.id) return item.text;
      return (
        <Input
          size="md"
          onBlur={() => setRenaming(null)}
          defaultValue={item.text}
          onKeyDown={(e) =>
            e.code === 'Enter' && finishRenaming(item.id, e.currentTarget.value)
          }
          autoFocus
        />
      );
    },
    [isRenaming, finishRenaming]
  );

  const columns = useMemo(() => {
    const tableColumns: SpkColumn<ParkingLotItem>[] = [
      { colKey: 'name', header: 'Item Name', cell: makeNameColumn },
      {
        colKey: 'created',
        header: 'Created',
        cell: (item) => SpkTime.format(item.createdAt, 'MMM dd, yyyy'),
      },
    ];

    if (allowSelect) {
      tableColumns.unshift({
        colKey: 'selected',
        header: '',
        cell: makeSelectCheckbox,
        width: 16,
      });
    }

    if (allowActions) {
      tableColumns.push({
        colKey: 'actions',
        header: '',
        cell: makeActionsMenu,
        width: 16,
      });
    }

    return tableColumns;
  }, [
    makeSelectCheckbox,
    makeActionsMenu,
    makeNameColumn,
    allowSelect,
    allowActions,
  ]);

  return (
    <SpokeTable
      data={parkingLotItems}
      columns={columns}
      isHighlighted={(item) => !!selectedIds?.includes(item.id)}
    />
  );
};
