import { AscDesc } from '../../types';

export const sortByCreatedAt =
  (ascDesc: AscDesc) =>
  <
    T extends
      | { createdAt?: Date | number | string | null | undefined }
      | null
      | undefined
  >(
    a: T,
    b: T
  ) =>
    ascDesc === AscDesc.Asc
      ? new Date(a?.createdAt ?? 0).getTime() ||
        0 - new Date(b?.createdAt ?? 0).getTime() ||
        0
      : new Date(b?.createdAt ?? 0).getTime() ||
        0 - new Date(a?.createdAt ?? 0).getTime() ||
        0;
