import { ApolloCache } from '@apollo/client';
import { log } from '../../../../SpkLog';
import { getTeamFromCache } from '../../getters';
import { LeaveTeamMutation, TeamFragmentFragment } from '@spoke/graphql';

type OptimisticLeaveTeamArgs = {
  teamId: string;
  userId: string;
  cache: ApolloCache<object>;
};
export const optimisticLeaveTeam = ({
  cache,
  teamId,
  userId,
}: OptimisticLeaveTeamArgs): LeaveTeamMutation | undefined => {
  const teamFromCache = getTeamFromCache({ cache, teamId });

  if (!teamFromCache) {
    log.error(
      'Could not perform optimistic response for leaveTeam: team not found in cache',
      { teamId, userId }
    );
    return undefined;
  }

  const { members, ...team } = teamFromCache;

  const newMembers = members.filter((member) => member.id !== userId);

  const newTeam: TeamFragmentFragment = {
    ...team,
    members: newMembers,
  };

  return {
    __typename: 'Mutation',
    leaveTeam: {
      __typename: 'Team',
      ...newTeam,
    },
  };
};
