import { QueryConfig } from '@spoke/common';
import { useTeamParentsByTeamIdQuery, TeamType } from '@spoke/graphql';

type Config = QueryConfig<typeof useTeamParentsByTeamIdQuery> &
  Required<Pick<QueryConfig<typeof useTeamParentsByTeamIdQuery>, 'variables'>>;

type QueryRef = ReturnType<typeof useTeamParentsByTeamIdQuery>;

type QueryData = NonNullable<
  NonNullable<
    NonNullable<
      ReturnType<typeof useTeamParentsByTeamIdQuery>['data']
    >['teamById']
  >['parents']
>;

export const useTeamParentPrograms = (
  config: Config
): [QueryData | null, QueryRef] => {
  const query = useTeamParentsByTeamIdQuery(config);
  const { data } = query;

  const teams = data?.teamById?.parents?.filter(
    (parent) => parent.type === TeamType.Program
  );

  return [teams ?? null, query];
};
