import { useToken } from '@chakra-ui/react';
import { Group } from '@visx/group';
import { FC, useMemo } from 'react';
import { motion } from 'framer-motion';
import { PointChartData } from './PointChart';
import { formatWithGoalUnit, isNullish, SpkMath } from '@spoke/common';
import {
  ImprovementGoalUnit,
  ImprovementGoalTypeTargetType,
} from '@spoke/graphql';

export const NEXT_TO_TARGET_THRESHOLD = 30;

type TargetLabelPosition = {
  line1Start: number;
  line1End: number;
  line2Start: number;
  line2End: number;
  labelStart: number;
};

const getTargetLabelPosition = (
  innerWidth: number,
  labelLength: number,
  offset: number = 0
): TargetLabelPosition => {
  if (offset === 0) {
    return {
      line1Start: 0,
      line1End: 0,
      line2Start: labelLength + 6,
      line2End: innerWidth,
      labelStart: 10,
    };
  }

  if (innerWidth - offset <= labelLength) {
    return {
      line1Start: 0,
      line1End: innerWidth - labelLength - 6,
      line2Start: 0,
      line2End: 0,
      labelStart: innerWidth - labelLength,
    };
  }

  return {
    line1Start: 0,
    line1End: offset,
    line2Start: labelLength + 6 + offset - labelLength / 2,
    line2End: innerWidth,
    labelStart: 14 + offset - labelLength / 2,
  };
};

type PointChartTargetLineProps = {
  unit?: ImprovementGoalUnit;
  target: number;
  innerWidth: number;
  innerHeight: number;
  targetDirection: ImprovementGoalTypeTargetType;
  yScale: (value: number) => number;
  xScale: (value: string) => number | undefined;
  getXValue: (d: PointChartData) => string;
  getYValue: (d: PointChartData) => number;
  data: PointChartData[];
};
export const PointChartTargetLine: FC<PointChartTargetLineProps> = ({
  targetDirection,
  unit,
  target,
  yScale,
  xScale,
  getXValue,
  getYValue,
  innerWidth,
  innerHeight,
  data,
}) => {
  const [primary500] = useToken('colors', ['primary.500']);

  const targetLabel = `Target: ${
    targetDirection === ImprovementGoalTypeTargetType.Above ? '>' : '<'
  } ${formatWithGoalUnit(target, unit ?? ImprovementGoalUnit.Abstract)}`;

  const labelLength = targetLabel.length * 6.5;

  const targetLabelPosition: TargetLabelPosition = useMemo(() => {
    if (!target) return getTargetLabelPosition(innerWidth, labelLength);

    const dataVerticallyCloseToTarget = data.filter(
      (d) =>
        Math.abs(yScale(getYValue(d)) - yScale(target)) <
        NEXT_TO_TARGET_THRESHOLD
    );

    if (
      !dataVerticallyCloseToTarget.length ||
      dataVerticallyCloseToTarget.length < 1
    ) {
      return getTargetLabelPosition(innerWidth, labelLength);
    }

    const xPositions = dataVerticallyCloseToTarget
      .map(getXValue)
      .map(xScale)
      .filter((pos) => !isNullish(pos)) as number[];

    const wouldOverlapInLeft = xPositions.some((xPos) => xPos <= labelLength);

    if (!wouldOverlapInLeft) {
      return getTargetLabelPosition(innerWidth, labelLength);
    }

    const wouldOverlapInRight = xPositions.some(
      (xPos) => xPos >= innerWidth - labelLength - 10
    );

    if (!wouldOverlapInRight) {
      return getTargetLabelPosition(innerWidth, labelLength, innerWidth);
    }

    const targetLabelOffset = SpkMath.findMiddleOfLargestGap([
      0,
      ...xPositions,
      innerWidth,
    ]);

    return getTargetLabelPosition(innerWidth, labelLength, targetLabelOffset);
  }, [
    data,
    getXValue,
    getYValue,
    labelLength,
    target,
    xScale,
    yScale,
    innerWidth,
  ]);

  return (
    <Group>
      <motion.line
        initial={{ opacity: 0, translateY: 10 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut', delay: 0 }}
        pointerEvents="none"
        width={innerWidth}
        height={innerHeight - 2}
        y={2}
        x1={targetLabelPosition.line1Start}
        x2={targetLabelPosition.line1End}
        y1={yScale(target)}
        y2={yScale(target)}
        rx={4}
        stroke={primary500}
      />
      <motion.rect
        initial={{ opacity: 0, translateY: 10 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut', delay: 0 }}
        x={targetLabelPosition.labelStart - 2}
        y={yScale(target) - 9}
        height={16}
        width={6 * targetLabel.length}
        fill="white"
        rx={2}
      />
      <motion.text
        initial={{ opacity: 0, translateY: 10 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut', delay: 0 }}
        pointerEvents="none"
        fill={primary500}
        fontSize={12}
        fontWeight={500}
        x={targetLabelPosition.labelStart}
        y={yScale(target) + 3}
        style={{ userSelect: 'none' }}
      >
        {targetLabel}
      </motion.text>
      <motion.line
        initial={{ opacity: 0, translateY: 10 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut', delay: 0 }}
        pointerEvents="none"
        width={innerWidth}
        height={innerHeight - 2}
        y={2}
        x1={targetLabelPosition.line2Start}
        x2={targetLabelPosition.line2End}
        y1={yScale(target)}
        y2={yScale(target)}
        rx={4}
        stroke={primary500}
      />
    </Group>
  );
};
