import {
  ComponentStyleConfig,
  Badge as ChakraBadge,
  BadgeProps as ChakraBadgeProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export const BadgeComponentStyle: ComponentStyleConfig = {
  variants: {
    outlineRounded: {
      fontFamily: 'proxima-nova-md',
      fontWeight: 'normal',
      colorScheme: 'blackAlpha',
      textTransform: 'none',
      borderRadius: 'xl',
      px: 2,
      pt: '2px',
      color: 'gray.500',
      borderColor: 'gray.300',
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    lightGray: {
      color: 'gray.500',
      alignItems: 'center',
      px: 2,
      pt: 1,
      pb: '2px',
      borderRadius: 'sm',
      bg: 'gray.50',
      fontSize: 14,
      fontWeight: 500,
      gap: 1,
      textTransform: 'none',
      flexDir: 'row',
      flexWrap: 'nowrap',
      display: 'flex',
    },
  },
};

type Props = ChakraBadgeProps;

export const Badge = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ChakraBadge ref={ref} {...props} />
));
