import {
  ComponentStyleConfig,
  Menu as ChakraMenu,
  MenuProps as ChakraMenuProps,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Box } from '../Box';

export const MenuComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    item: {
      fontFamily: 'proxima-nova-md',
      color: 'gray.600',
      fontSize: 14,
      py: 2,
      transition: 'background 0.1s ease-out, color 0.1s ease-out',
      borderRadius: 'sm',
      _hover: {
        bg: 'gray.100',
        color: 'gray.900',
      },
    },
    list: {
      px: 2,
    },
  },
};

export type MenuProps = ChakraMenuProps;

export const Menu: FC<MenuProps> = (props) => (
  <Box>
    <ChakraMenu {...props} />
  </Box>
);
