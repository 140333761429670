import { makeVar, useApolloClient, useReactiveVar } from '@apollo/client';
import type { Subscription } from 'zen-observable-ts';
import { useRef, useCallback, useEffect } from 'react';
import {
  TEN_SECONDS_MS,
  useNetworkContext,
  useCurrentUser,
  useCurrentBoardId,
  log,
} from '@spoke/common';
import {
  BoardParticipation,
  BoardParticipationStatus,
  usePingBoardParticipationFacilitatorMutation,
  ParticipationChangesSubscription,
  ParticipationChangesDocument,
} from '@spoke/graphql';

type ParticipationReducerFn = (
  prevAllParticipations: BoardParticipation[]
) => BoardParticipation[];

const BOARD_PARTICIPATIONS_POLLING_INTERVAL_MS = TEN_SECONDS_MS;

export type ParticipationsManualUpdater = (cb: ParticipationReducerFn) => void;

type UseParticipationsResult = {
  participations: BoardParticipation[];
  loading: boolean;
};

const LOADING_RESPONSE: UseParticipationsResult = {
  loading: true,
  participations: [],
};

const onlineOverOffline = (
  a: BoardParticipation,
  b: BoardParticipation
): 0 | 1 | -1 => {
  if (
    a.status === BoardParticipationStatus.Online &&
    b.status !== BoardParticipationStatus.Online
  ) {
    return -1;
  }
  if (
    a.status !== BoardParticipationStatus.Online &&
    b.status === BoardParticipationStatus.Online
  ) {
    return 1;
  }
  return 0;
};

const facilitatorOverNonFacilitator = (
  a: BoardParticipation,
  b: BoardParticipation
): 0 | 1 | -1 => {
  if (a.isFacilitator && !b.isFacilitator) return -1;
  return 0;
};

const clientOverNonClient =
  (currentUserId: string) =>
  (a: BoardParticipation, b: BoardParticipation): 0 | 1 | -1 => {
    if (a.userId === currentUserId) return -1;
    if (b.userId === currentUserId) return 1;
    return 0;
  };

const facilitatorSubscribedVar = makeVar<boolean>(false);

// This is a reactive var instead of a state so we can reduce useCallback depedencies
// by calling the value inside the callbacks instead of redefining callbacks on changes
// But this all is a hack to get around the bad design pattern in backend commented below
const participationsResultVar = makeVar(LOADING_RESPONSE);

type UseParticipationsArgs = {
  isFacilitator: boolean;
};
export const useParticipations = ({
  isFacilitator,
}: UseParticipationsArgs): [
  UseParticipationsResult,
  ParticipationsManualUpdater
] => {
  const { shouldPoll } = useNetworkContext();
  const participationsResult = useReactiveVar(participationsResultVar);

  const [user] = useCurrentUser();
  const [boardId] = useCurrentBoardId();

  const [facilitatorInitialPing] =
    usePingBoardParticipationFacilitatorMutation();

  const client = useApolloClient();
  const participationSubscriptionRef = useRef<Subscription>();

  const manuallyUpdateParticipations = useCallback(
    (reducer: ParticipationReducerFn) => {
      const newParticipations = reducer(
        participationsResultVar().participations
      )
        .sort(onlineOverOffline)
        .sort(facilitatorOverNonFacilitator)
        .sort(clientOverNonClient(user?.id as string));

      participationsResultVar({
        ...participationsResultVar(),
        participations: newParticipations,
      });
    },
    [user?.id]
  );

  const processParticipationsUpdate = useCallback(
    (allNewParticipations: BoardParticipation[]) => {
      const previousParticipations = participationsResultVar().participations;

      const participationsToMerge = allNewParticipations.filter(
        (newParticipation) =>
          previousParticipations.some(
            (existing) =>
              existing.participant.id === newParticipation.participant.id
          )
      );

      const participationsToAdd = allNewParticipations.filter(
        (newParticipation) =>
          !previousParticipations.some(
            (existing) =>
              existing.participant.id === newParticipation.participant.id
          )
      );

      const mergedParticipations = previousParticipations
        .map(
          (previous) =>
            participationsToMerge.find(
              (newParticipation) =>
                previous.participant.id === newParticipation.participant.id
            ) || previous
        )
        .concat(participationsToAdd)
        .sort(onlineOverOffline)
        .sort(facilitatorOverNonFacilitator)
        .sort(clientOverNonClient(user?.id as string));

      participationsResultVar({
        ...previousParticipations,
        participations: mergedParticipations,
        loading: false,
      });
    },
    [user?.id]
  );

  const fetchParticipations = useCallback(async () => {
    if (!boardId) return;
    const { data } = await facilitatorInitialPing({
      variables: { input: { boardId } },
    });
    const participations =
      data?.pingBoardParticipationAsAuthenticatedUser?.participations;
    const initialParticipations = participations?.filter(Boolean) || [];
    processParticipationsUpdate(initialParticipations as BoardParticipation[]);
  }, [boardId, facilitatorInitialPing, processParticipationsUpdate]);

  useEffect(() => {
    if (shouldPoll) {
      log.info('Falling back to polling for participation changes');
      facilitatorSubscribedVar(false);
    }

    if (isFacilitator && !facilitatorSubscribedVar() && boardId) {
      if (!shouldPoll) {
        // This needs some server side refactoring..
        // We should set this up as a query and an incremental subscription, not a mutation
        // So we can use the pattern documented in Apollo hooks instead of core client with manual subscription
        // Plus this pattern hinders us from using apollo cache to store these results as query.
        // That is why "manuallyUpdateParticipations" exist here. And also the reason why storing results in a useState hook
        log.info('Subscribing to participation changes', { boardId });
        const observer = client.subscribe<ParticipationChangesSubscription>({
          query: ParticipationChangesDocument,
          variables: { input: { boardId } },
        });

        participationSubscriptionRef.current = observer.subscribe(
          ({ data }) => {
            const newParticipations =
              data?.participationChanged?.participationChanges;
            processParticipationsUpdate(
              newParticipations as BoardParticipation[]
            );
          }
        );

        facilitatorSubscribedVar(true);
      }

      fetchParticipations();
    }

    if (!isFacilitator && facilitatorSubscribedVar()) {
      log.info('Unsubscribing from participation changes');
      participationSubscriptionRef.current?.unsubscribe();
      facilitatorSubscribedVar(false);
    }
  }, [
    boardId,
    client,
    fetchParticipations,
    isFacilitator,
    processParticipationsUpdate,
    shouldPoll,
  ]);

  useEffect(() => {
    // Hacky polling support for this mutation pattern
    const interval = setInterval(async () => {
      const willPoll =
        shouldPoll && !facilitatorSubscribedVar() && isFacilitator;
      if (!willPoll) return;
      fetchParticipations();
    }, BOARD_PARTICIPATIONS_POLLING_INTERVAL_MS);

    return () => {
      clearInterval(interval);
    };
  }, [fetchParticipations, isFacilitator, shouldPoll]);

  useEffect(
    () => () => {
      participationSubscriptionRef.current?.unsubscribe();
      participationsResultVar(LOADING_RESPONSE);
      facilitatorSubscribedVar(false);
    },
    []
  );

  return [participationsResult, manuallyUpdateParticipations];
};
