import {
  Box as ChakraBox,
  BoxProps as ChakraBoxProps,
  forwardRef,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FC, RefAttributes } from 'react';

export type BoxProps = ChakraBoxProps;

export const Box = forwardRef<BoxProps, 'div'>((props, ref) => (
  <ChakraBox ref={ref} {...props} />
));

export const MotionBox = motion(Box) as FC<
  Omit<BoxProps, 'transition'> & BoxProps & RefAttributes<HTMLDivElement>
>;
