import { useImprovementGoalTypesQuery } from '@spoke/graphql';

type QueryRef = ReturnType<typeof useImprovementGoalTypesQuery>;
type QueryData = NonNullable<QueryRef['data']>['improvementGoalTypes'];

export const useImprovementGoalTypes = (): [QueryData | null, QueryRef] => {
  const typesQuery = useImprovementGoalTypesQuery();
  const types = typesQuery.data?.improvementGoalTypes ?? null;
  return [types, typesQuery];
};
