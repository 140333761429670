import { motion } from 'framer-motion';
import { FC, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useForgotPasswordMutation } from '@spoke/graphql';
import {
  SpokeFormRules,
  EMAIL_PATTERN,
  useToast,
  GENERIC_ERROR_MESSAGE,
  SpkHead,
  Card,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  log,
  Input,
  Link,
  Image,
  Text,
  useAssets,
} from '@spoke/common';

export type ResetPasswordFormSchema = {
  email: string;
};

const rules: SpokeFormRules<ResetPasswordFormSchema> = {
  email: {
    required: 'Email is required',
    pattern: { value: EMAIL_PATTERN, message: 'Please enter a valid email' },
  },
};

type AuthResetPasswordprops = {
  onContinueWithEmailPassword?: () => void;
  onSuccess: (email: string) => void;
};
export const AuthRequestResetPassowrd: FC<AuthResetPasswordprops> = ({
  onSuccess,
  onContinueWithEmailPassword,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors, isSubmitting, isValid },
  } = useForm<ResetPasswordFormSchema>();

  const [resetPassword] = useForgotPasswordMutation();

  const [toast] = useToast();

  const [error, setError] = useState('');

  const { illustrationHello } = useAssets();

  const handleResetPassword = useCallback(
    async (formData: ResetPasswordFormSchema) => {
      log.info('Submitting forgot password form', formData);
      const { email } = formData;

      const { errors } = await resetPassword({
        variables: { email, isChangingProviders: false },
      });

      if (errors) {
        log.error('Forgot password mutation responded with error', { error });
        setError(GENERIC_ERROR_MESSAGE);
        return;
      }

      onSuccess(email);

      toast({
        title: 'Email sent',
        description: `An email has been sent to ${email} with a code to reset your password.`,
      });
    },
    [error, onSuccess, resetPassword, toast]
  );

  return (
    <motion.div
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{ duration: 0.15, ease: 'easeOut' }}
    >
      <SpkHead title="Reset Password" />
      <Card w={460} p={10}>
        <Image src={illustrationHello} alt="Hello from ScatterSpoke" />
        <VStack>
          <Heading size="md" fontWeight={500} color="gray.700">
            Reset your password
          </Heading>
          <Text fontSize="sm" color="gray.500" textAlign="center" px={5}>
            Enter the email address associated with your account and we&apos;ll
            send you a link to reset your password.
          </Text>
        </VStack>
        <VStack
          spacing={4}
          w="full"
          as="form"
          onSubmit={handleSubmit(handleResetPassword)}
        >
          <FormControl isInvalid={!isValid}>
            <VStack spacing={4}>
              <FormControl isInvalid={Boolean(formErrors.email)}>
                <FormLabel htmlFor="email">Email address</FormLabel>
                <Input id="email" {...register('email', rules.email)} />
                <FormErrorMessage>
                  {formErrors?.email?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </FormControl>
          {error && (
            <FormControl isInvalid={Boolean(error)} m="0">
              <FormErrorMessage textAlign="center">{error}</FormErrorMessage>
            </FormControl>
          )}
          <Button w="full" isLoading={isSubmitting} type="submit">
            Reset password
          </Button>
          {onContinueWithEmailPassword && (
            <Text fontSize="xs" color="gray.500" w="full" textAlign="left">
              Remember your password?{' '}
              <Link onClick={() => onContinueWithEmailPassword()}>Sign in</Link>
            </Text>
          )}
        </VStack>
      </Card>
    </motion.div>
  );
};
