import {
  ComponentStyleConfig,
  Center as ChakraCenter,
  CenterProps as ChakraCenterProps,
  forwardRef,
} from '@chakra-ui/react';
import { FC } from 'react';

export const CenterComponentStyle: ComponentStyleConfig = {};

export type CenterProps = ChakraCenterProps;

export const Center: FC<CenterProps> = forwardRef<CenterProps, 'div'>(
  (props, ref) => <ChakraCenter ref={ref} {...props} />
);
