import { IconProps, Icon } from '../Icon';

export const ProgramIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g clipPath="url(#clip0_8128_60342)">
      <rect width="24" height="24" fill="#228BE6" opacity="0.1" rx="4" />
      <path
        stroke="#228BE6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16 9.5H8a1 1 0 00-1 1v5a1 1 0 001 1h8M7 5.5v5"
      />
      <rect width="5" height="5" x="13" y="7" fill="#228BE6" rx="1" />
      <rect width="5" height="5" x="13" y="14" fill="#228BE6" rx="1" />
    </g>
    <defs>
      <clipPath id="clip0_8128_60342">
        <path fill="#fff" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </Icon>
);
