import { FC } from 'react';
import {
  VStack,
  Button,
  Icon,
  JiraIcon,
  useRouter,
  useRoutes,
} from '@spoke/common';

/**
 * Other provider buttons and the logic to hide/display them will come here
 */
export const FlowMetricsOrgInstallButtons: FC<{
  onInstallClick?: () => void;
}> = ({ onInstallClick }) => {
  const router = useRouter();
  const routes = useRoutes();

  const _onInstallClick =
    onInstallClick ?? (() => router.push(routes.Integrations));

  return (
    <VStack>
      <Button
        variant="outlineGray"
        size="md"
        fontSize={15}
        h={9}
        w="fit-content"
        mx="auto"
        bg="white"
        onClick={_onInstallClick}
        leftIcon={<Icon as={JiraIcon} mb="2px" />}
      >
        Connect Jira
      </Button>
    </VStack>
  );
};
