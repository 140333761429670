import {
  TabPanel as ChakraTabPanel,
  TabPanelProps as ChakraTabPanelProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = ChakraTabPanelProps;

export const TabPanel = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ChakraTabPanel {...props} ref={ref} />
));
