import { useToken } from '@chakra-ui/react';
import { isNullish } from '@apollo/client/cache/inmemory/helpers';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { scaleBand, scaleLinear } from '@visx/scale';
import { LinePath } from '@visx/shape';
import { motion } from 'framer-motion';
import { FC, useMemo, useState, useRef } from 'react';
import useMeasure from 'react-use-measure';
import { PointChartPoint } from './PointChartPoint';
import { PointChartTargetLine } from './PointChartTargetLine';
import {
  ImprovementGoalUnit,
  ImprovementGoalTypeTargetType,
} from '@spoke/graphql';
import {
  SpkTime,
  FlexProps,
  useLagState,
  useIsFirstRender,
  useDebounceRef,
  Flex,
  Popover,
  dataPointIsOnTarget,
  formatWithGoalUnit,
  truncateDecimals,
  PopoverContent,
  PopoverArrow,
} from '@spoke/common';

const getYValue = (d: PointChartData) => d.value;

const getXValue = (d: PointChartData) =>
  SpkTime.getStartOf('day', d.date).toString();

export type PointChartPopoverProps<T = Record<string, unknown>> = {
  data: PointChartData<T>;
};

const BOTTOM_AXIS_HEIGHT = 32;
const INNER_PADDING_HORIZONTAL = 24;
const INNER_PADDING_TOP = 29;
const INNER_PADDING_BOTTOM = 10;
const NEXT_TO_TARGET_THRESHOLD = 20;

export type PointChartData<T = Record<string, unknown>> = T & {
  id: string;
  date: string;
  value: number;
  children?: PointChartData[];
};

type PointChartProps<T = Record<string, unknown>> = FlexProps & {
  data: PointChartData<T>[];
  unit?: ImprovementGoalUnit;
  target?: number;
  selectedPoint?: PointChartData<T> | null;
  onSelectPoint?: (point: PointChartData<T> | null) => void;
  popover?: FC<PointChartPopoverProps<T>>;
  targetDirection: ImprovementGoalTypeTargetType;
};
export const PointChart: FC<PointChartProps> = ({
  data: _data,
  targetDirection,
  target,
  unit,
  selectedPoint: propSelectedPoint,
  onSelectPoint: propSetSelectedPoint,
  popover,
  ...rest
}: PointChartProps) => {
  const [ref, bounds] = useMeasure();
  const [gray500, gray200, gray300, gray400, green600, red500] = useToken(
    'colors',
    ['gray.500', 'gray.200', 'gray.300', 'gray.400', 'green.600', 'red.500']
  );

  const data = _data.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  const width = bounds.width || 100;
  const height = bounds.height || 100;

  const innerWidth = width;
  const innerHeight = height - BOTTOM_AXIS_HEIGHT;

  /**
   * Makes sure x domain is spaced by 1 day. Even if dataset is uneven
   * @update I just found that d3 has a `scaleTime` for this.
   * This is working just fine now, but if we need to change
   * anything in here should probably use scaleTime instead.
   */
  const allDaysBetweenMinAndMax: string[] = useMemo(() => {
    const dates = data.map(getXValue);
    const minDate = SpkTime.getEarliest(dates);
    const maxDate = SpkTime.getLatest(dates);
    if (!minDate || !maxDate) return [];
    const allDates = SpkTime.fillMissingDaysBetween(minDate, maxDate);
    return allDates.map((d) => d.toString());
  }, [data]);

  const xScale = useMemo(() => {
    const minDate = allDaysBetweenMinAndMax[0] ?? new Date();
    const maxDate =
      allDaysBetweenMinAndMax[allDaysBetweenMinAndMax.length - 1] ?? new Date();
    const diff = new Date(maxDate).getTime() - new Date(minDate).getTime();
    const padding = diff * 0.0000000004;
    return scaleBand<string>({
      range: [0, innerWidth - 25],
      domain: allDaysBetweenMinAndMax,
      paddingInner: padding,
      paddingOuter: padding,
    });
  }, [allDaysBetweenMinAndMax, innerWidth]);

  const yScale = useMemo(() => {
    const minData = Math.min(...data.map(getYValue), target ?? Infinity);
    const maxData = Math.max(...data.map(getYValue), target ?? -Infinity);
    const fallbackToShowOnlyTargetLineOnCenter =
      (!Number.isFinite(minData) || !Number.isFinite(maxData)) && target;
    const domain = fallbackToShowOnlyTargetLineOnCenter
      ? [target - 140, target + 100]
      : [minData, maxData];
    return scaleLinear<number>({
      range: [innerHeight - INNER_PADDING_BOTTOM, INNER_PADDING_TOP],
      domain,
    });
  }, [target, data, innerHeight]);

  const [internalSelectedPoint, internalSetSelectedPoint] =
    useState<PointChartData | null>(null);

  // Opt-in state elevation
  const [selectedPoint, setSelectedPoint] = propSetSelectedPoint
    ? [propSelectedPoint, propSetSelectedPoint]
    : [internalSelectedPoint, internalSetSelectedPoint];

  // Gives time for animation to finish after closing popover
  const [selectedPointLag] = useLagState(selectedPoint, 200);

  // Triggers data point enter animations
  const isFirstRender = useIsFirstRender();
  const [isEntering] = useLagState(isFirstRender, 300);

  const cursorLineRef = useRef<SVGLineElement>(null);
  const cursorCircleRef = useRef<SVGCircleElement>(null);
  const [onUpdateCursor, shouldUpdateCursor] = useDebounceRef(5);

  return (
    <Flex
      width="full"
      height="full"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      <Flex
        ref={ref}
        position="relative"
        width="full"
        height="full"
        minWidth={300}
        sx={{
          '.point-chart-cursor': {
            opacity: 0,
            transition: 'opacity 0.1s ease-out',
          },
          '&:hover .point-chart-cursor': { opacity: 1 },
        }}
      >
        <Popover
          isOpen={!isNullish(selectedPoint)}
          onClose={() => setSelectedPoint(null)}
          placement="top"
        >
          <svg
            onMouseMove={(e) => {
              if (!shouldUpdateCursor()) return;
              const point = localPoint(e) || { x: 0, y: 0 };
              const x = point.x.toString();
              const y = point.y.toString();
              onUpdateCursor();
              if (cursorLineRef.current) {
                cursorLineRef.current.setAttribute('x1', x);
                cursorLineRef.current.setAttribute('x2', x);
              }
              if (cursorCircleRef.current) {
                cursorCircleRef.current.setAttribute('cx', x);
                cursorCircleRef.current.setAttribute('cy', y);
              }
            }}
            cursor="none"
            width="100%"
            height="100%"
            viewBox={`0 0 ${width} ${height}`}
          >
            <line
              className="point-chart-cursor"
              ref={cursorLineRef}
              y1={2}
              y2={innerHeight}
              stroke={gray300}
            />

            <LinePath<PointChartData>
              data={data}
              x={(d) => (xScale(getXValue(d)) ?? 0) + INNER_PADDING_HORIZONTAL}
              y={(d) => yScale(getYValue(d)) ?? 0}
            >
              {({ path }) => (
                <motion.path
                  initial={{ opacity: 0, translateY: 10 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.5, ease: 'easeOut', delay: 0 }}
                  d={path(data) || ''}
                  stroke={gray400}
                  strokeWidth={1}
                  strokeOpacity={1}
                  shapeRendering="geometricPrecision"
                  fill="transparent"
                />
              )}
            </LinePath>

            {target && (
              <PointChartTargetLine
                targetDirection={targetDirection}
                target={target}
                yScale={yScale}
                xScale={xScale}
                unit={unit}
                getXValue={getXValue}
                getYValue={getYValue}
                innerWidth={innerWidth}
                innerHeight={innerHeight}
                data={data}
              />
            )}
            <Group>
              {data.map((point) => {
                const isGood = dataPointIsOnTarget(
                  point.value,
                  target ?? 0,
                  targetDirection
                );
                const color = !target ? gray500 : isGood ? green600 : red500;
                const label = formatWithGoalUnit(
                  truncateDecimals(point.value, 2),
                  unit ?? ImprovementGoalUnit.Abstract,
                  'short'
                );
                const x =
                  (xScale(getXValue(point)) ?? 0) + INNER_PADDING_HORIZONTAL;
                const y = yScale(getYValue(point)) ?? 0;

                const isSelected = point.id === selectedPoint?.id;
                const isNotSelected = !isSelected && selectedPoint;

                const isNextToTargetLine = Boolean(
                  target &&
                    Math.abs(y - yScale(target)) < NEXT_TO_TARGET_THRESHOLD
                );

                return (
                  <PointChartPoint
                    key={point.id}
                    label={label}
                    onClick={() => setSelectedPoint(point)}
                    isInteractive={Boolean(popover)}
                    color={color}
                    opacity={isNotSelected ? 0.3 : 1}
                    selected={isSelected}
                    protectLabel={isNextToTargetLine}
                    isEntering={isEntering}
                    x={x}
                    y={y}
                  />
                );
              })}
            </Group>
            <circle
              r={3}
              className="point-chart-cursor"
              ref={cursorCircleRef}
              y1={0}
              y2={innerHeight}
              fill="white"
              stroke={gray300}
              strokeWidth={1}
              pointerEvents="none"
            />

            <Group>
              {data.map((point, idx, arr) => {
                const ms = new Date(point.date || 0).getTime();
                const x =
                  (xScale(getXValue(point)) ?? 0) + INNER_PADDING_HORIZONTAL;
                const y = innerHeight + 20;

                const prevPoint = arr[idx - 1];
                const COLLISION_THRESHOLD = 60;

                // This could be improved.. just a hack to avoid label collision
                const prevPointX = prevPoint ? xScale(getXValue(prevPoint)) : 0;
                const isColliding = prevPointX
                  ? x - prevPointX < COLLISION_THRESHOLD
                  : false;
                if (isColliding) return null;

                return (
                  <text
                    key={point.id}
                    textAnchor="middle"
                    style={{ fill: gray500 }}
                    fontWeight={600}
                    fontSize={12}
                    x={x}
                    y={y}
                  >
                    {SpkTime.format(ms, 'MMM dd')}
                  </text>
                );
              })}
            </Group>
            <rect
              width={innerWidth}
              height={innerHeight - 2}
              stroke={gray200}
              y={2}
              x={0}
              rx={4}
              fill="transparent"
              pointerEvents="none"
            />
          </svg>
          {popover && (
            <PopoverContent w="fit-content" p={2}>
              <PopoverArrow />
              {Boolean(selectedPoint || selectedPointLag) &&
                popover({
                  data: (selectedPoint || selectedPointLag) as PointChartData,
                })}
            </PopoverContent>
          )}
        </Popover>
      </Flex>
    </Flex>
  );
};
