import {
  ComponentStyleConfig,
  Radio as ChakraRadio,
  RadioProps as ChakraRadioProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const RadioComponentStyle: ComponentStyleConfig = {};

type Props = ChakraRadioProps;

export const Radio: FC<Props> = (props) => <ChakraRadio {...props} />;
