import { yupResolver } from '@hookform/resolvers/yup';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { MdOutlineContentCopy } from 'react-icons/md';
import * as yup from 'yup';
import {
  ModalProps,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Icon,
  Heading,
  ModalBody,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  Input,
  Box,
} from '@spoke/common';

export type CloneBoardFormSchema = {
  boardName: string;
};

const cloneBoardSchema = yup
  .object({
    boardName: yup.string().required('This field is required'),
  })
  .required();

type CloneBoardModalProps = Omit<ModalProps, 'children'> & {
  initialBoardName?: string;
  onClose: () => unknown;
  onSubmit: (name: string) => unknown;
};

export const CloneBoardModalContent: FC<CloneBoardModalProps> = ({
  onClose,
  onSubmit,
  initialBoardName,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors, isValid },
  } = useForm<CloneBoardFormSchema>({
    resolver: yupResolver(cloneBoardSchema),
    mode: 'onChange',
    defaultValues: {
      boardName: initialBoardName,
    },
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitForm = async (data: CloneBoardFormSchema) => {
    setIsLoading(true);
    await onSubmit(data.boardName);
    setIsLoading(false);
    onClose();
  };

  return (
    <ModalContent maxW={600} p={8}>
      <ModalCloseButton top={3} />
      <ModalHeader display="flex" alignItems="center" p={0} mb={4}>
        <Icon mr={1} mb="2px" w={5} h={5} as={MdOutlineContentCopy} />
        <Heading fontSize={20}>Clone retrospective</Heading>
      </ModalHeader>
      <ModalBody p={0}>
        <Box as="form" onSubmit={handleSubmit(onSubmitForm)}>
          <FormControl isInvalid={Boolean(formErrors.boardName)}>
            <FormLabel fontSize={15} htmlFor="name">
              Retrospective name
            </FormLabel>
            <Input
              placeholder="Name your retrospective"
              id="boardName"
              minH={10}
              autoFocus
              {...register('boardName')}
            />
            <FormErrorMessage>
              {formErrors?.boardName?.message}
            </FormErrorMessage>
          </FormControl>

          <HStack justifyContent="flex-end" mt={4} w="full">
            <Button
              size="lg"
              fontSize={16}
              variant="outlineGray"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              disabled={!isValid || isLoading}
              size="lg"
              type="submit"
              fontSize={16}
            >
              Clone
            </Button>
          </HStack>
        </Box>
      </ModalBody>
    </ModalContent>
  );
};

export const CloneBoardModal: FC<CloneBoardModalProps> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <CloneBoardModalContent {...props} />
  </Modal>
);
