import {
  ComponentWithAs,
  forwardRef,
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
} from '@chakra-ui/react';

export type IconButtonProps = ChakraIconButtonProps;

export const IconButton: ComponentWithAs<'svg', IconButtonProps> = forwardRef(
  (props, ref) => (
    <ChakraIconButton
      h="unset"
      minW="unset"
      display="flex"
      alignItems="center"
      justifyContent="center"
      variant="unstyled"
      {...props}
      ref={ref}
    />
  )
);
