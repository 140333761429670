import { FC, useState } from 'react';
import { MdOutlineAutoAwesome } from 'react-icons/md';
import { useRateTeamInsightHandler } from '../handlers';
import { TeamInsightQueryData } from '../queries';
import { ExportInsightToConfluence } from './Confluence';
import { TeamInsight } from './TeamInsight';
import { TeamInsightRating } from './TeamInsightRating';
import { QualityName } from '@spoke/graphql';
import {
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  plural,
  ModalBody,
  ModalFooter,
  Spacer,
  PaginationControlsCondensed,
  ModalProps,
  Modal,
  ModalOverlay,
  log,
} from '@spoke/common';

type Props = {
  insights: TeamInsightQueryData[];
  skipAnimations?: boolean;
};
export const TeamInsightsModalContent: FC<Props> = ({
  insights,
  skipAnimations = false,
}) => {
  const [handleRate] = useRateTeamInsightHandler();
  const [ratingSubmitLoading, setRatingSubmitLoading] =
    useState<QualityName | null>(null);
  const [selectedIdx, setSelectedIdx] = useState(0);

  const selectedInsight = insights?.[selectedIdx];

  const selectedInsightTeamName = selectedInsight?.team?.name ?? '';

  const onRate = async (rating: QualityName) => {
    const teamInsightId = insights?.[selectedIdx].id;
    if (!teamInsightId) {
      log.error(
        'Attempted to rate a teamInsight, but no TeamInsight is selected',
        { insights, selectedIdx }
      );
      return;
    }
    setRatingSubmitLoading(rating);
    await handleRate({ teamInsightId, rating });
    setRatingSubmitLoading(null);
  };

  return (
    <ModalContent ml={3} p={8} pb={5} maxW={650}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Icon as={MdOutlineAutoAwesome} color="primary.500" w={6} h={6} />
          <Heading mb={1} fontSize={24} color="gray.900">
            {plural('Insight', insights.length)}{' '}
            {selectedInsightTeamName ? `for ${selectedInsightTeamName}` : ''}
          </Heading>
        </HStack>
      </ModalHeader>
      <ModalBody p={0}>
        {Boolean(selectedInsight) && (
          <TeamInsight
            skipAnimations={skipAnimations}
            insight={selectedInsight}
          />
        )}
      </ModalBody>
      <ModalFooter p={0}>
        <HStack w="full" justifyContent="space-between" mt={5} mb={2}>
          <ExportInsightToConfluence teamInsightId={selectedInsight.id} />
          <Spacer />
          <TeamInsightRating
            onRate={onRate}
            loading={ratingSubmitLoading}
            rated={Boolean(selectedInsight?.rated)}
          />

          {Boolean(insights?.length && insights.length > 1) && (
            <PaginationControlsCondensed
              pageCount={insights?.length ?? 1}
              currentPage={selectedIdx + 1}
              onChangePage={(newPage) => setSelectedIdx(newPage - 1)}
            />
          )}
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

type TeamInsightsModalProps = Omit<ModalProps, 'children'> & Props;
export const TeamInsightsModal: FC<TeamInsightsModalProps> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <TeamInsightsModalContent {...props} />
  </Modal>
);
