import { ChakraProps, StyleProps } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import { MdBarChart } from 'react-icons/md';
import { Flex } from './Flex';
import { Icon } from './Icon';
import { Text } from './Text';

type EmptyStateProps = ChakraProps & {
  icon?: ReactElement;
  title?: string;
  titleStyle?: StyleProps;
  description?: string;
};
export const EmptyState: FC<EmptyStateProps> = ({
  icon = <Icon w={7} h={7} as={MdBarChart} color="primary.500" />,
  titleStyle = {},
  title,
  description,
  maxW,
  ...rest
}) => (
  <Flex
    direction="column"
    alignItems="center"
    gap={1}
    maxW={maxW || 400}
    mx="auto"
    textAlign="center"
    {...rest}
  >
    {icon}
    {title && (
      <Text
        mt={1}
        fontSize={16}
        color="gray.700"
        fontWeight={600}
        {...titleStyle}
      >
        {title}
      </Text>
    )}
    {description && (
      <Text w={300} maxW={maxW} fontSize={14} color="gray.500" fontWeight={500}>
        {description}
      </Text>
    )}
  </Flex>
);
