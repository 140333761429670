import {
  Team,
  Organization,
  ImprovementGoalTeam,
  ImprovementGoalTeamType,
} from '@spoke/graphql';
import { UseTeamTypeResult } from '@spoke/user';
import { useCurrentUser } from '@spoke/common';

type ImprovementGoalTeamInformationForQuery = {
  reportingDataTeamIds: ImprovementGoalTeam[];
  improvementGoalTeamIds: string[];
};

type TeamQueryData = NonNullable<ReturnType<typeof useCurrentUser>[0]>['team'];
type OrganizationQueryData = NonNullable<
  ReturnType<typeof useCurrentUser>[0]
>['organization'];

export const getImprovementGoalTeamInformationForQuery = (
  teamType: UseTeamTypeResult,
  currentTeam: TeamQueryData,
  currentOrg: OrganizationQueryData
): ImprovementGoalTeamInformationForQuery => {
  const { isTeam, isOrg, isProgram } = teamType;
  const reportingDataTeamIds: ImprovementGoalTeam[] = [];
  const improvementGoalTeamIds: string[] = currentTeam?.id
    ? [currentTeam?.id]
    : [];

  if (isTeam && currentOrg?.team?.id) {
    // add org team to pull in org goals
    improvementGoalTeamIds.push(currentOrg?.team?.id);

    // check if programs, add those
    const programs = currentTeam?.parents;
    if (programs?.length) {
      programs.forEach((program) => {
        improvementGoalTeamIds.push(program.id);
      });
    }
  } else if (isProgram && currentOrg?.team?.id) {
    // add org team to pull in org goals
    improvementGoalTeamIds.push(currentOrg?.team?.id);
  }

  if (isTeam) {
    reportingDataTeamIds.push({
      teamId: currentTeam?.id ?? 'incorrectCCIDWillError',
      teamType: ImprovementGoalTeamType.Team,
    });
  } else if (isProgram) {
    reportingDataTeamIds.push({
      teamId: currentTeam?.id ?? 'incorrectCCIDWillError',
      teamType: ImprovementGoalTeamType.Program,
    });
  } else if (isOrg) {
    reportingDataTeamIds.push({
      teamId: currentOrg?.team?.id ?? '',
      teamType: ImprovementGoalTeamType.Organization,
    });
  }

  return {
    reportingDataTeamIds,
    improvementGoalTeamIds,
  };
};
