import { FC } from 'react';
import { AuthGuard } from './AuthGuard';
import { BoardGuard } from './BoardGuard';
import { VerifyEmailGuard } from './VerifyEmailGuard';
import { OnboardingGuard } from './OnboardingGuard';
import { OrgAdminGuard } from './OrgAdminGuard';
import { useCurrentBoardId } from '@spoke/common';

export const AppGuards: FC = ({ children }) => {
  const [boardId] = useCurrentBoardId();

  const guards = [AuthGuard, VerifyEmailGuard, OnboardingGuard, OrgAdminGuard];

  if (boardId) guards.push(BoardGuard);

  /*
   * This renders all guards in "guards" array with one as
   * child of another, followed by children. We use this to
   * unmount guards that cannot me mounted without critical
   * data, as is the case with BoardGuard and boardId.
   */
  return guards.reduce((acc, Guard) => <Guard>{acc}</Guard>, <>{children}</>);
};
