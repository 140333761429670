import { ApolloCache } from '@apollo/client';
import { log } from '../../../SpkLog';
import {
  BoardFragmentFragment,
  BoardFragmentFragmentDoc,
} from '@spoke/graphql';

type GetBoardFromCacheArgs = {
  boardId: string;
  cache: ApolloCache<object>;
};
export function getBoardFromCache({
  boardId,
  cache,
}: GetBoardFromCacheArgs): BoardFragmentFragment | null {
  const cachedData = cache.readFragment({
    fragment: BoardFragmentFragmentDoc,
    fragmentName: 'BoardFragment',
    id: `Board:${boardId}`,
    returnPartialData: true,
  }) as BoardFragmentFragment | null;
  if (!cachedData) {
    log.error('Unsuccessful attempt to read a BoardFragment from cache', {
      boardId,
      cachedData,
    });
    return null;
  }
  return cachedData;
}
