import { useCallback } from 'react';
import { useActionItemsAnalytics } from '../queries';
import {
  useNow,
  ONE_DAY_MS,
  optimisticCreateActionItem,
  createActionItemUpdateFunction,
  NO_OP,
  TOAST_ERROR_GENERIC,
  useToast,
  log,
  useCurrentUser,
} from '@spoke/common';
import {
  useCreateActionItemMutation,
  ActionItem,
  CreateActionItemMutation,
} from '@spoke/graphql';

export const useCreateActionItemHandler = () => {
  const [createActionItem] = useCreateActionItemMutation();
  const [currentUser] = useCurrentUser();
  const [toast] = useToast();

  const userId = currentUser?.id;

  const now = useNow({ roundTo: 'ten-minutes' });

  const [__, actionItemAnalyticsQuery] = useActionItemsAnalytics({
    endDate: now,
    timeWindowLength: 90 * ONE_DAY_MS,
  });

  const handleCreateActionItem = useCallback(
    async (
      data: Pick<
        ActionItem,
        'title' | 'description' | 'dueDate' | 'teamId' | 'boardId'
      > & {
        improvementGoalIds?: string[];
        assigneeIds?: string[];
        jiraProjectKey?: string;
        originalCardId?: string;
      }
    ): Promise<CreateActionItemMutation['createActionItem'] | null> => {
      log.info('Creating action item', {
        data,
      });

      const expected = optimisticCreateActionItem({
        ...data,
        authorId: userId || '',
      });

      const {
        title,
        description,
        dueDate,
        improvementGoalIds,
        assigneeIds,
        jiraProjectKey,
        teamId,
        boardId,
        originalCardId,
      } = data;

      const { errors, data: responseData } = await createActionItem({
        variables: {
          teamId,
          title,
          assigneeIds,
          boardId,
          description,
          dueDate,
          improvementGoals: improvementGoalIds,
          jiraProjectKey,
          originalCardId,
        },
        optimisticResponse: expected,
        update: createActionItemUpdateFunction,
      });

      actionItemAnalyticsQuery.refetch().catch(NO_OP);

      if (errors || !responseData) {
        log.error('createActionItem call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return null;
      }

      return responseData.createActionItem;
    },
    [actionItemAnalyticsQuery, createActionItem, toast, userId]
  );

  return [handleCreateActionItem];
};
