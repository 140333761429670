import {
  forwardRef,
  Text as ChakraText,
  TextProps as ChakraTextProps,
} from '@chakra-ui/react';

type Props = ChakraTextProps;

export const Text = forwardRef<Props, 'p'>((props, ref) => (
  <ChakraText ref={ref} {...props} />
));
