import { useState, useEffect, FC } from 'react';
import { MdOutlineBookmarkBorder } from 'react-icons/md';
import { useActionItems } from '../queries';
import { ActionItemsModalBody } from './ActionItemsModalBody';
import { CreateActionItemModal } from './CreateActionItemModal';
import { EditActionItemModal } from './EditActionItemModal';
import { Tab } from './ActionItemTabs';
import { ImprovementGoal, User } from '@spoke/graphql';
import {
  SpkTime,
  SelectOption,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  Badge,
  ModalBody,
  ModalProps,
  Modal,
  ModalOverlay,
  DateRange,
} from '@spoke/common';

export const dateRangeValues: Record<string, DateRange> = {
  allTime: [null, null],
  thisMonth: SpkTime.getCurrent('month'),
  thisWeek: SpkTime.getCurrent('week'),
};

export const DATE_RANGE_OPTIONS: SelectOption<DateRange>[] = [
  { label: 'All time', value: dateRangeValues.allTime },
  { label: 'This month', value: dateRangeValues.thisMonth },
  { label: 'This week', value: dateRangeValues.thisWeek },
];

export const ActionItemsModalContent = () => {
  const [selectedTab, setSelectedTab] = useState(Tab.Unresolved);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [dateRange, setDateRange] = useState(dateRangeValues.allTime);
  const [selectedAssignees, setSelectedAssignees] = useState<User[]>([]);
  const [selectedGoals, setSelectedGoals] = useState<ImprovementGoal[]>([]);

  const [editingActionItemId, setEditingActionItemId] = useState<string | null>(
    null
  );

  const [actionItems] = useActionItems({
    variables: {
      dueDate: [dateRange[0]?.getTime(), dateRange[1]?.getTime()],
      assigneeIds: selectedAssignees.map((user) => user.id),
      improvementGoals: selectedGoals.map((goal) => goal.id),
    },
  });

  const createActionItemModal = useDisclosure();

  useEffect(() => {
    if (!filtersOpen) {
      setDateRange(dateRangeValues.allTime);
      setSelectedAssignees([]);
      setSelectedGoals([]);
    }
  }, [filtersOpen]);

  const { resolved, unresolved } = actionItems;

  const currentActionItems =
    selectedTab === Tab.Resolved ? resolved : unresolved;

  const currentActionItemsCount = currentActionItems.length;

  return (
    <ModalContent p={8} pb={4} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0}>
        <HStack>
          <Icon w={6} h={6} as={MdOutlineBookmarkBorder} />
          <Heading mb={1} fontSize={24} color="gray.900">
            {selectedTab === Tab.Resolved ? 'Resolved' : 'Unresolved'} Action
            Items
          </Heading>
          <Badge colorScheme="gray" mb="-2px">
            {currentActionItemsCount}
          </Badge>
        </HStack>
      </ModalHeader>
      <ModalBody p={0}>
        <ActionItemsModalBody
          {...{
            setEditingActionItemId,
            selectedTab,
            setSelectedTab,
            filtersOpen,
            setFiltersOpen,
            filters: {
              dateRange,
              setDateRange,
              selectedAssignees,
              setSelectedAssignees,
              selectedGoals,
              setSelectedGoals,
            },
          }}
        />
      </ModalBody>
      <CreateActionItemModal
        isOpen={createActionItemModal.isOpen}
        onClose={createActionItemModal.close}
      />
      <EditActionItemModal
        actionItemId={editingActionItemId}
        isOpen={Boolean(editingActionItemId)}
        onClose={() => setEditingActionItemId(null)}
      />
    </ModalContent>
  );
};

type ActionItemsInnerModalProps = Omit<ModalProps, 'children'>;
export const ActionItemsModal: FC<ActionItemsInnerModalProps> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <ActionItemsModalContent />
  </Modal>
);
