import {
  ApolloClient,
  ApolloProvider as ActualApolloProvider,
  useReactiveVar,
} from '@apollo/client';
import { createContext, FC, useContext, useMemo } from 'react';
import { cache } from './cache';
import { getApolloClientLink, wsBlockedVar } from './link';
import { log, useUniqueToast } from '@spoke/common';

const connectToDevTools =
  process.env.NEXT_PUBLIC_APP_ENVIRONMENT !== 'production';

type NetworkContextData = {
  shouldPoll: boolean;
};

const NetworkContext = createContext({} as NetworkContextData);

export const useNetworkContext = () => useContext(NetworkContext);

export const ApolloProvider: FC = ({ children }) => {
  const toast = useUniqueToast({
    duration: null,
  });

  const client = useMemo(
    () => {
      log.info('Apollo Client is starting');
      const link = getApolloClientLink({ toast });
      return new ApolloClient({
        cache,
        link,
        connectToDevTools,
        defaultOptions: {
          mutate: {
            errorPolicy: 'all',
          },
          query: {
            errorPolicy: 'all',
          },
          watchQuery: {
            errorPolicy: 'all',
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const wsConnectionFailed = useReactiveVar(wsBlockedVar);

  const networkContext: NetworkContextData = {
    shouldPoll: wsConnectionFailed ? true : false,
  };

  return (
    <ActualApolloProvider client={client}>
      <NetworkContext.Provider value={networkContext}>
        {children}
      </NetworkContext.Provider>
    </ActualApolloProvider>
  );
};
