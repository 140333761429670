import { GOAL_UNIT_MAP } from '../../constants';
import { pluralState } from './plural';
import { ImprovementGoalUnit } from '@spoke/graphql';

export function formatWithGoalUnit(
  value: number | string,
  unit: ImprovementGoalUnit = ImprovementGoalUnit.Abstract,
  mode: 'short' | 'plural' | 'singular' = typeof value === 'number'
    ? pluralState(value)
    : 'short'
): string {
  const unitDisplay = GOAL_UNIT_MAP[unit];
  const display = unitDisplay[mode];
  const space = mode === 'short' ? '' : ' ';
  return unitDisplay.position === 'prefix' && mode === 'short'
    ? `${display}${space}${value}`
    : `${value}${space}${display}`;
}
