import { FC, useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import { CgUndo } from 'react-icons/cg';
import { FiThumbsUp, FiChevronRight } from 'react-icons/fi';
import { MdOutlineStickyNote2, MdOutlineChat } from 'react-icons/md';
import { Card } from '@spoke/graphql';
import {
  parseCardText,
  isImage,
  Flex,
  handleCardImageError,
  HStack,
  Badge,
  Icon,
  AddCommentBox,
  Button,
  Divider,
  VStack,
  convertCardToActionItem,
  Text,
  Image,
  Comment,
  ColorBadge,
} from '@spoke/common';
import { CreateActionItemModal } from '@spoke/action-item';

type DiscussBoardSpotlightProps = {
  card: Card;
  onAddComment: (text: string) => Promise<boolean>;
  onDeleteComment: (commentId: string) => Promise<boolean>;
  onReadyChange: (newReady: boolean) => void;
  onMoveBackToDiscussion: () => void;
  onSelectNextCard: (() => void) | null;
  onEndRetro: (() => void) | null;
  isLast: boolean;
  isReady: boolean;
  onlineUserCount: number;
  readyUserCount: number;
  currentUserId: string;
};
export const DiscussBoardSpotlight: FC<DiscussBoardSpotlightProps> = ({
  card,
  onAddComment,
  onDeleteComment,
  onlineUserCount,
  readyUserCount,
  isReady,
  isLast,
  onEndRetro,
  onSelectNextCard,
  onReadyChange,
  onMoveBackToDiscussion,
  currentUserId,
}) => {
  const {
    text,
    originalListBadge,
    votes,
    comments,
    children,
    wasDiscussed,
    id: cardId,
  } = card;

  const [savingAsActionItem, setSavingAsActionItem] = useState(false);

  const imageUrl = parseCardText(text).find(isImage);

  const isGroup = children && children.length > 0;

  return (
    <Flex h="full" alignSelf="flex-start" flexDir="column" w={450} mt={4}>
      {!isGroup && (
        <Flex>
          <ColorBadge color={originalListBadge?.color} />
          <Text ml={2} fontWeight={500} color="gray.700">
            {originalListBadge?.text}
          </Text>
        </Flex>
      )}
      {!imageUrl && (
        <Text
          w="full"
          overflowWrap="break-word"
          fontWeight={600}
          fontSize={22}
          lineHeight="28px"
        >
          {text}
        </Text>
      )}
      {imageUrl && (
        <Image
          maxW={300}
          mt={4}
          mb={3}
          src={imageUrl}
          alt=""
          onError={handleCardImageError}
        />
      )}
      <HStack mb={1}>
        <Text fontSize={16} fontWeight={500} color="gray.600" mb={1}>
          Votes
        </Text>
        <Badge bg="gray.200" fontSize={14}>
          {votes?.length}
        </Badge>
      </HStack>
      {Boolean(children?.length) && (
        <Flex
          layerStyle="outlineGray"
          flexDir="column"
          px={3}
          py={4}
          gap={2}
          mb={2}
        >
          <HStack>
            <Icon as={MdOutlineStickyNote2} color="gray.500" w={5} h={5} />
            <Text fontSize={14} fontWeight={500} color="gray.600" mb={1}>
              Grouped cards
            </Text>
            <Badge bg="gray.200" fontSize={14}>
              {children?.length}
            </Badge>
          </HStack>
          {children?.map((child) =>
            !child ? null : (
              <Flex
                key={child.id}
                layerStyle="outlineGray"
                bg="white"
                alignItems="flex-start"
                gap={1}
                flexDir="column"
                w="full"
              >
                <HStack spacing={1} w="full">
                  <ColorBadge
                    minH="19px"
                    minW="2px"
                    color={child.originalListBadge?.color}
                  />
                  <Text
                    w="full"
                    lineHeight={0}
                    pt="2px"
                    color="gray.700"
                    fontSize={14}
                  >
                    {child.originalListBadge?.text}
                  </Text>
                </HStack>

                {parseCardText(child.text).find(isImage) ? (
                  <Image
                    maxW={300}
                    mt={4}
                    mb={3}
                    src={parseCardText(child.text).find(isImage)}
                    alt=""
                    onError={handleCardImageError}
                  />
                ) : (
                  <Text
                    textAlign="left"
                    w="full"
                    fontWeight={500}
                    fontSize={16}
                  >
                    {child.text}
                  </Text>
                )}
              </Flex>
            )
          )}
        </Flex>
      )}
      <Flex
        flexDir="column"
        p={3}
        borderWidth="1px"
        borderColor="gray.200"
        borderStyle="solid"
        borderRadius="md"
        cursor="default"
      >
        <HStack mb={2} spacing={1}>
          <Icon as={MdOutlineChat} color="gray.500" w={5} h={5} />
          <Text fontSize={14} fontWeight="500" color="gray.600">
            Comments
          </Text>
        </HStack>
        <AddCommentBox onAdd={onAddComment} />
        {comments?.map((comment) =>
          comment ? (
            <Comment
              key={comment.id}
              id={comment.id}
              authorName={comment.author?.name}
              canDelete={comment.author?.id === currentUserId}
              createdAt={comment.createdAt}
              text={comment.text}
              onDelete={onDeleteComment}
              mt={3}
            />
          ) : null
        )}
      </Flex>
      <Button
        bg="white"
        _hover={{ bg: 'gray.50' }}
        variant="outlineGray"
        mt={5}
        onClick={() => setSavingAsActionItem(true)}
      >
        Save card as action item
      </Button>
      <Divider my={5} />
      {!wasDiscussed && (
        <HStack alignItems="flex-start" spacing={1.5}>
          <VStack>
            <Button
              onClick={() => onReadyChange(!isReady)}
              leftIcon={<Icon as={FiThumbsUp} />}
              variant="outlineGray"
              color={isReady ? 'white' : 'gray.800'}
              bg={isReady ? 'green.600' : 'white'}
              transition="none"
              _hover={{
                bg: isReady ? 'green.600' : 'gray.50',
                transition: 'background 0.2s ease-out',
              }}
            >
              I&apos;m ready to move on
            </Button>
            <Text fontSize={14} color="gray.500">
              {readyUserCount} of {onlineUserCount} participants ready to move
              on
            </Text>
          </VStack>
          {!isLast && onSelectNextCard && (
            <Button
              onClick={onSelectNextCard}
              rightIcon={<Icon as={FiChevronRight} w={6} h={6} />}
              flex="1"
            >
              Next card
            </Button>
          )}
          {isLast && onEndRetro && (
            <Button
              onClick={onEndRetro}
              leftIcon={<Icon as={BsCheck} w={6} h={6} mr="-5px" />}
              flex="1"
            >
              End retro
            </Button>
          )}
        </HStack>
      )}
      {wasDiscussed && (
        <Button
          onClick={onMoveBackToDiscussion}
          leftIcon={<Icon as={CgUndo} w={5} h={5} />}
        >
          Move back to discussion
        </Button>
      )}
      <CreateActionItemModal
        isOpen={Boolean(savingAsActionItem)}
        onClose={() => setSavingAsActionItem(false)}
        originalCardId={cardId}
        initialValues={
          !savingAsActionItem ? null : convertCardToActionItem(card)
        }
      />
    </Flex>
  );
};
