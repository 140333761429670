import { FC, ReactNode, useState } from 'react';
import { useCreateParkingLotItemHandler } from '../handlers';
import {
  Flex,
  InputGroup,
  InputLeftElement,
  KeyboardKey,
  Button,
  Input,
  Text,
} from '@spoke/common';

type ParkingLotQuickAddProps = {
  confirmButtonLabel: ReactNode;
  disabled?: boolean;
};

export const ParkingLotQuickAdd: FC<ParkingLotQuickAddProps> = ({
  confirmButtonLabel,
  disabled,
}) => {
  const [handleCreateParkingLotItem] = useCreateParkingLotItemHandler();
  const [quickAddText, setQuickAddText] = useState('');

  const onQuickAdd = async () => {
    if (!quickAddText) return;
    setQuickAddText('');
    await handleCreateParkingLotItem(quickAddText);
  };

  return (
    <Flex w="full" alignItems="center" gap={2}>
      <InputGroup>
        {!quickAddText.length && (
          <InputLeftElement w="320px" pt="13px" pointerEvents="none" ml={3}>
            <Text whiteSpace="nowrap" color="gray.400" fontSize={14} mr={2}>
              Type here and hit
            </Text>
            <KeyboardKey size="sm">Enter</KeyboardKey>{' '}
            <Text whiteSpace="nowrap" color="gray.400" fontSize={14} ml={2}>
              to add parking lot items
            </Text>
          </InputLeftElement>
        )}
        <Input
          pl={3}
          minH="45px"
          value={quickAddText}
          onChange={(e) => setQuickAddText(e.target.value)}
          onKeyDown={(e) => (e.key === 'Enter' ? onQuickAdd() : null)}
          disabled={disabled}
        />
      </InputGroup>
      <Button
        disabled={!quickAddText}
        onClick={onQuickAdd}
        paddingX={typeof confirmButtonLabel === 'string' ? 8 : 0}
      >
        {confirmButtonLabel}
      </Button>
    </Flex>
  );
};
