import { FC, useMemo, useState } from 'react';
import { BiSearchAlt2 } from 'react-icons/bi';
import { useUpdateProgramTeamsHandler } from '../handlers';
import { useCurrentTeam, useTeamTree } from '../queries';
import { flattenTeamTree } from './TeamSwitcher';
import { TeamType } from '@spoke/graphql';
import {
  ModalProps,
  sleep,
  capitalizeFirstLetter,
  plural,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Heading,
  ModalBody,
  InputGroup,
  InputLeftElement,
  Icon,
  Flex,
  Checkbox,
  TeamIcon,
  ModalFooter,
  Button,
  Modal,
  ModalOverlay,
  log,
  Input,
  Text,
} from '@spoke/common';

type ManageProgramTeamsModalProps = Omit<ModalProps, 'children'>;
export const ManageProgramTeamsModalContent: FC<
  ManageProgramTeamsModalProps
> = (props) => {
  const [currentProgram] = useCurrentTeam();
  const [handleUpdateProgramTeams] = useUpdateProgramTeamsHandler();
  const [{ teamTree }] = useTeamTree();

  const teamsCurrentUserIsIn = flattenTeamTree(teamTree || [])
    .map((node) => node.team)
    .filter((team) => team.type === TeamType.Team);

  const currentProgramChildrenIds = useMemo(
    () =>
      (currentProgram?.children
        ?.map((child) => child?.id)
        .filter(Boolean) as string[]) || [],
    [currentProgram?.children]
  );

  const [selectedTeams, setSelectedTeams] = useState<string[]>(
    currentProgramChildrenIds
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredOrgTeams = useMemo(() => {
    if (!searchTerm) return teamsCurrentUserIsIn;
    return teamsCurrentUserIsIn?.filter((team) =>
      team?.name?.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
  }, [teamsCurrentUserIsIn, searchTerm]);

  const onSelectTeam = (teamId: string, selecting: boolean) => {
    if (selecting) setSelectedTeams((teams) => [...teams, teamId]);
    else setSelectedTeams((teams) => teams.filter((id) => id !== teamId));
  };

  const teamsIdsToAdd = selectedTeams.filter(
    (teamId) => !currentProgramChildrenIds.includes(teamId)
  );

  const teamIdsToRemove = currentProgramChildrenIds.filter(
    (teamId) => !selectedTeams.includes(teamId)
  );

  const onSubmit = async () => {
    const programId = currentProgram?.id;

    if (!programId) {
      log.error('No-op: cannot update program teams without loaded program id');
      return;
    }

    setIsSubmitting(true);

    await handleUpdateProgramTeams({
      programId,
      teamsIdsToAdd,
      teamIdsToRemove,
    });

    // Too many components rerender at once when "currentUser" query updates...
    // so adding this sleep here to reduce perceived lag on modal close animation
    await sleep(700);
    setIsSubmitting(false);
    props.onClose?.();
  };

  const submitButtonLabel = capitalizeFirstLetter(
    `${
      teamsIdsToAdd.length
        ? `add ${teamsIdsToAdd.length} ${plural('team', teamsIdsToAdd.length)}`
        : ''
    }${teamsIdsToAdd.length && teamIdsToRemove.length ? ', ' : ''}${
      teamIdsToRemove.length
        ? `remove ${teamIdsToRemove.length} ${plural(
            'team',
            teamIdsToRemove.length
          )}`
        : ''
    }` || 'Save'
  );

  return (
    <ModalContent p={8} maxW={500}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={0}>
        <HStack>
          <Heading mb={1} fontSize={24} color="gray.900">
            Manage program teams
          </Heading>
        </HStack>
      </ModalHeader>
      <ModalBody p={0}>
        <InputGroup mt={2} mb={1}>
          <InputLeftElement pointerEvents="none">
            <Icon as={BiSearchAlt2} color="gray.500" w={5} h={5} />
          </InputLeftElement>
          <Input
            placeholder="Search teams..."
            mb={4}
            pl={8}
            onChange={(e) => setSearchTerm(e.target.value)}
            value={searchTerm}
            variant="flushed"
          />
        </InputGroup>
        <Flex
          gap={4}
          flexDir="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          overflowY="auto"
          maxH={500}
        >
          {filteredOrgTeams?.map((team) => {
            if (!team?.id) return null;
            const isChecked = selectedTeams.includes(team.id);
            return (
              <Flex
                onClick={() => onSelectTeam(team.id as string, !isChecked)}
                onKeyDown={(e) =>
                  e.key === 'Enter'
                    ? onSelectTeam(team.id as string, !isChecked)
                    : null
                }
                key={team.id}
                cursor="pointer"
                w="full"
                alignItems="flex-start"
                pl={1}
                pr={3}
              >
                <Checkbox
                  size="lg"
                  isChecked={isChecked}
                  mt="2px"
                  pointerEvents="none"
                />
                <TeamIcon h={6} w={6} mx={2} mb="3px" />
                <Text color="gray.700" fontSize={14} mt="2px" fontWeight={500}>
                  {team.name}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </ModalBody>
      <ModalFooter p={0} mt={4}>
        <HStack justifyContent="flex-end">
          <Button
            size="lg"
            disabled={isSubmitting}
            variant="outlineGray"
            type="submit"
            w="fit-content"
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            w="fit-content"
            isLoading={isSubmitting}
            isDisabled={!teamsIdsToAdd.length && !teamIdsToRemove.length}
            onClick={onSubmit}
            type="submit"
          >
            {submitButtonLabel}
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

export const ManageProgramTeamsModal: FC<ManageProgramTeamsModalProps> = (
  props
) => (
  <Modal {...props}>
    <ModalOverlay />
    <ManageProgramTeamsModalContent {...props} />
  </Modal>
);
