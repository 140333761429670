const FORMAT_SHOW_SIGN = new Intl.NumberFormat(undefined, {
  signDisplay: 'exceptZero',
});

export class SpkFormat {
  static showSign(value: number): string {
    return FORMAT_SHOW_SIGN.format(value);
  }

  static padZeroes(value: number, length: number): string {
    return value.toString().padStart(length, '0');
  }
}
