import {
  createContext,
  FC,
  KeyboardEventHandler,
  ReactNode,
  useContext,
  useRef,
  useState,
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useAsyncDebounce } from '../../hooks';
import { SetState } from '../../types';
import { Box } from '../Box';
import { Button, ButtonProps } from '../Button';
import { Flex, FlexProps } from '../Flex';
import { FormControl } from '../Form/FormControl';
import { FormErrorMessage } from '../Form/FormErrorMessage';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import { Text } from '../Text';
import { Input } from './Input';
import { InputGroup } from './InputGroup';
import { InputLeftElement } from './InputLeftElement';
import { InputRightElement } from './InputRightElement';

export type MultiTypeAheadSuggestion = { value: string; label: string };

type MultiTypeAheadContextData = {
  values: string[];
  validate: (text: string) => string | null;
  addField: () => void;
  handleChange: (text: string, idx: number) => void;
  canAddField: boolean;
  removeField: (id: number) => void;
  touchedIdxs: Set<number>;
  icon: ReactNode;
  handleBlur: (idx: number) => void;
  placeholder?: string;
  suggestions: MultiTypeAheadSuggestion[] | null;
  suggestionsLoading: boolean;
};

const INITIAL_CONTEXT_VALUES: MultiTypeAheadContextData = {
  addField: () => {},
  handleBlur: () => {},
  handleChange: () => {},
  removeField: () => {},
  validate: () => '',
  values: [],
  touchedIdxs: new Set(),
  suggestionsLoading: false,
  canAddField: false,
  suggestions: null,
  placeholder: '',
  icon: <></>,
};

const MultiTypeAheadContext = createContext<MultiTypeAheadContextData>(
  INITIAL_CONTEXT_VALUES
);

export const MultiTypeAheadFields: FC<FlexProps> = (props) => {
  const {
    values,
    validate,
    handleChange,
    placeholder,
    icon,
    handleBlur,
    touchedIdxs,
    removeField,
    suggestions,
    suggestionsLoading,
  } = useContext(MultiTypeAheadContext);

  const [focusedFieldIdx, setFocusedFieldIdx] = useState<number | null>(null);
  const [focusedSuggestionIdx, setFocusedSuggestionIdx] = useState<number>(0);
  const suggestionDropdownRef = useRef<HTMLDivElement | null>(null);
  const fieldRefs = useRef<HTMLInputElement[]>([]);

  const keyDownActions: Record<string, () => void> = {
    ArrowDown: () => {
      if (!suggestions) return;
      setFocusedSuggestionIdx(
        Math.min(focusedSuggestionIdx + 1, suggestions.length - 1)
      );
    },
    ArrowUp: () =>
      setFocusedSuggestionIdx((prev) => (prev ? Math.max(prev - 1, 0) : 0)),
    Enter: () => {
      if (
        focusedSuggestionIdx === null ||
        focusedFieldIdx === null ||
        !suggestions?.[focusedSuggestionIdx]
      ) {
        return;
      }
      setFocusedSuggestionIdx(0);
      handleChange(suggestions[focusedSuggestionIdx].value, focusedFieldIdx);
    },
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (keyDownActions[e.key]) keyDownActions[e.key]();
  };

  // TODO make this change placement if doesn't fit screen
  const DROPDOWN_ITEM_HEIGHT_PX = 40;
  const SCROLLABLE_DROPDOWN_ITEMS = 5;
  const DROPDOWN_MAX_HEIGHT_PX =
    DROPDOWN_ITEM_HEIGHT_PX * SCROLLABLE_DROPDOWN_ITEMS;

  return (
    <Flex flexDir="column" gap={2} {...props}>
      {values.map((value, idx) => {
        const shouldShowDropdown = Boolean(
          focusedFieldIdx === idx &&
            validate(values[idx]) &&
            !suggestionsLoading &&
            suggestions?.length
        );
        return (
          <FormControl
            key={idx}
            isInvalid={Boolean(touchedIdxs.has(idx) && validate(value))}
          >
            <InputGroup position="relative">
              <InputLeftElement ml={1} mt="6px" mr={2} pointerEvents="none">
                {icon}
              </InputLeftElement>
              <Input
                pl={10}
                size="lg"
                fontSize={17}
                value={value}
                color="gray.600"
                placeholder={placeholder}
                onChange={(e) => handleChange(e.target.value, idx)}
                onKeyDown={handleKeyDown}
                autoFocus={
                  idx === values.length - 1 && values.length > 1 && value === ''
                }
                onFocus={() => {
                  setFocusedFieldIdx(idx);
                  const isANewlyAddedField = Boolean(
                    idx === values.length - 1 && value === ''
                  );
                  if (isANewlyAddedField) handleChange('', idx); // Resets suggestions
                }}
                onBlur={() => {
                  setFocusedFieldIdx(null);
                  handleBlur(idx);
                }}
                ref={(ref) => (ref ? (fieldRefs.current[idx] = ref) : null)}
              />
              {suggestionsLoading &&
                validate(value) &&
                focusedFieldIdx === idx && (
                  <InputRightElement mt="6px" mr={idx === 0 ? 2 : 8}>
                    <Spinner
                      h="18px"
                      w="18px"
                      speed="0.8s"
                      thickness="3px"
                      mx="auto"
                      my={2}
                    />
                  </InputRightElement>
                )}
              {idx > 0 && (
                <InputRightElement mt="6px" mr={2}>
                  <Icon
                    color="gray.600"
                    w={5}
                    h={5}
                    as={AiOutlineClose}
                    onClick={() => removeField(idx)}
                    cursor="pointer"
                  />
                </InputRightElement>
              )}
              {shouldShowDropdown && (
                <Flex
                  flexDir="column"
                  alignItems="stretch"
                  h="fit-content"
                  boxShadow="lg"
                  padding={4}
                  gap={2}
                  textAlign="left"
                  bg="#fff"
                  ref={suggestionDropdownRef}
                  position="absolute"
                  top="100%"
                  borderBottomRadius="lg"
                  left={0}
                  zIndex={3}
                  w="full"
                  maxH={DROPDOWN_MAX_HEIGHT_PX}
                  overflowY="scroll"
                >
                  {!suggestionsLoading &&
                    suggestions?.map((suggestion, suggestionIdx) => (
                      <Box
                        className="multitypeahead-suggestion"
                        display="flex"
                        alignItems="center"
                        key={suggestion.value}
                        cursor="pointer"
                        transition="background 0.1s ease-out"
                        p={2}
                        _hover={{ bg: 'gray.100' }}
                        h={`${DROPDOWN_ITEM_HEIGHT_PX}px`}
                        onMouseDown={() => {
                          handleChange(suggestion.value, idx);
                          fieldRefs.current[idx]?.focus();
                        }}
                        bg={
                          focusedSuggestionIdx === suggestionIdx
                            ? 'gray.100'
                            : '#fff'
                        }
                      >
                        <Text fontSize={14} color="gray.700">
                          {suggestion.label}
                        </Text>
                      </Box>
                    ))}
                </Flex>
              )}
            </InputGroup>
            <FormErrorMessage>{validate(value)}</FormErrorMessage>
          </FormControl>
        );
      })}
    </Flex>
  );
};

type MultiTypeAheadProps = {
  values: string[];
  setValues?: SetState<string[]>;
  getSuggestions?: (text: string) => Promise<MultiTypeAheadSuggestion[]>;
  validateField?: (text: string) => string | null;
  icon?: ReactNode;
  placeholder?: string;
  maxFields?: number;
};
export const MultiTypeAhead: FC<MultiTypeAheadProps> = ({
  values,
  setValues,
  placeholder,
  icon,
  validateField,
  maxFields,
  children,
  getSuggestions = () => [],
}) => {
  const [debouncedGetSuggestions, suggestions, suggestionsLoading] =
    useAsyncDebounce(getSuggestions, 300);

  const [touchedIdxs, setTouchedIdxs] = useState<Set<number>>(new Set());

  const handleChange = (text: string, idx: number) => {
    if (!setValues) return;
    setValues((prevValues) =>
      prevValues.map((value, _idx) => (_idx === idx ? text : value))
    );
    debouncedGetSuggestions(text);
  };

  const removeField = (idx: number) => {
    if (!setValues) return;
    setValues((prevValues) =>
      prevValues.filter((_value, _idx) => _idx !== idx)
    );
    setTouchedIdxs((touched) => {
      touched.delete(idx);
      return touched;
    });
  };

  const addField = () => {
    if (!setValues) return;
    setValues((prevValues) => [...prevValues, '']);
  };

  const validate = (text: string): string | null => {
    const validationError = validateField ? validateField(text) : null;
    if (validationError) return validationError;
    return null;
  };

  const handleBlur = (idx: number) => {
    setTouchedIdxs((touched) => {
      touched.add(idx);
      return touched;
    });
  };

  const canAddField =
    values.some(validate) && (!maxFields || values.length < maxFields);

  const data: MultiTypeAheadContextData = {
    addField,
    canAddField,
    handleBlur,
    handleChange,
    icon,
    placeholder,
    removeField,
    touchedIdxs,
    validate,
    values,
    suggestions,
    suggestionsLoading,
  };

  return (
    <MultiTypeAheadContext.Provider value={data}>
      {children}
    </MultiTypeAheadContext.Provider>
  );
};

export const MultiTypeAheadAddField: FC<ButtonProps> = (props) => {
  const { addField, canAddField } = useContext(MultiTypeAheadContext);
  return (
    <Button
      variant="outlineGray"
      size="lg"
      {...props}
      onClick={addField}
      disabled={canAddField}
    />
  );
};
