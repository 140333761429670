import { FC } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import {
  Alert,
  Icon,
  SpkLink,
  Link,
  Box,
  Text,
  useRoutes,
} from '@spoke/common';

type Props = {
  metricsName: string;
};
export const EmptyMetricsAlert: FC<Props> = ({ metricsName }) => {
  const routes = useRoutes();

  return (
    <Alert bg="orange.100" color="orange.800" status="info" mb={2}>
      <Icon
        as={AiOutlineWarning}
        mr={2}
        color="orange.800"
        w={5}
        h={5}
        borderRadius="sm"
      />
      <Box as="span">
        Not enough{' '}
        <Text as="span" fontWeight={500}>
          {metricsName}
        </Text>{' '}
        data to show right now. Try changing your linked boards in your{' '}
        <SpkLink href={routes.TeamSettings} passHref>
          <Link textDecor="underline" color="orange.800">
            Team Settings
          </Link>
        </SpkLink>
        .
      </Box>
    </Alert>
  );
};
