import { FC, useEffect, useMemo, useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import {
  useSetGoalRemindersHandler,
  useClearGoalRemindersHandler,
} from '../../handlers';
import { useImprovementGoal } from '../../queries';
import { GoalReminderForm } from '../GoalReminderForm';
import {
  BoxProps,
  useSpringState,
  Flex,
  HStack,
  Button,
  NO_OP,
  Icon,
  Skeleton,
  isNullish,
  Box,
} from '@spoke/common';

export type GoalDrilldownReminderProps = BoxProps & {
  goal: ReturnType<typeof useImprovementGoal>[0];
  isDisabled?: boolean
};

export const GoalDrilldownReminder: FC<GoalDrilldownReminderProps> = ({
  goal,
  isDisabled,
  ...rest
}) => {
  const [handleSetReminders] = useSetGoalRemindersHandler();
  const [handleClearGoalReminders] = useClearGoalRemindersHandler();

  // In practice people can only have one frequency and time
  // for all reminders in a goal, so first one is fine to populate form
  const initialFrequency = goal?.userReminders?.[0]?.frequency ?? null;
  const initialTimeMs = goal?.userReminders?.[0]?.notificationTime ?? null;

  const initialUserIds = useMemo(
    () => goal?.userReminders?.map((r) => r.userId) ?? [],
    [goal?.userReminders]
  );

  const [frequency, setFrequency] = useState(initialFrequency);
  const [timeMs, setTimeMs] = useState(initialTimeMs);
  const [userIds, setUserIds] = useState(initialUserIds);

  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isClearLoading, setIsClearLoading] = useState(false);
  const [showSaveSuccess, setShowSaveSuccess] = useSpringState(false);
  const [showClearSuccess, setShowClearSuccess] = useSpringState(false);

  const isSettingReminderUp = Boolean(userIds.length);
  const isReminderEnabled = initialUserIds.length > 0;
  const hasMadeChanges =
    userIds.join() !== initialUserIds.join() ||
    frequency !== initialFrequency ||
    timeMs !== initialTimeMs;
  const isValid = !isNullish(frequency) && !isNullish(timeMs) && userIds.length;

  useEffect(() => {
    setFrequency(initialFrequency);
  }, [initialFrequency]);

  useEffect(() => {
    setUserIds(initialUserIds);
  }, [initialUserIds]);

  useEffect(() => {
    setTimeMs(initialTimeMs);
  }, [initialTimeMs]);

  const onSave = async () => {
    setTriedToSubmit(true);
    if (!userIds.length) return;
    if (!goal?.id) return;
    if (!frequency || isNullish(timeMs)) return;

    setIsSaveLoading(true);

    await handleSetReminders({
      frequency,
      improvementGoalId: goal.id,
      notificationTimeMs: timeMs as number,
      userIds,
    });

    setShowSaveSuccess(true);
    setIsSaveLoading(false);
  };

  const onClear = async () => {
    if (!goal?.id) return;

    setIsClearLoading(true);

    await handleClearGoalReminders({
      improvementGoalId: goal.id,
    });

    setShowClearSuccess(true);
    setIsClearLoading(false);
    setTriedToSubmit(false);
  };

  return (
    <Box {...rest}>
      {goal ? (
        <Flex w="full" gap={4} alignItems="flex-start">
          <GoalReminderForm
            variant="condensed"
            frequency={frequency}
            timeMs={timeMs}
            userIds={userIds}
            onFrequencyChange={setFrequency}
            onTimeChange={setTimeMs}
            onUserIdsChange={setUserIds}
            showValidationErrors={isSettingReminderUp && triedToSubmit}
            flex={1}
          />
          <HStack mt="37px" spacing={2}>
            <Button
              isLoading={isSaveLoading}
              isDisabled={Boolean(
                (!hasMadeChanges ||
                  (isSettingReminderUp && !isValid && triedToSubmit)) &&
                  !showSaveSuccess
              )}
              w={70}
              size="md"
              fontSize={16}
              onClick={showSaveSuccess ? NO_OP : onSave}
            >
              {showSaveSuccess ? (
                <Icon color="white" w={8} h={8} as={BsCheck} />
              ) : (
                'Save'
              )}
            </Button>
            <Button
              isDisabled={
                (!isReminderEnabled || isSaveLoading) && !showClearSuccess
              }
              isLoading={isClearLoading}
              size="md"
              w={70}
              fontSize={16}
              colorScheme="gray"
              onClick={showClearSuccess ? NO_OP : onClear}
            >
              {showClearSuccess ? (
                <Icon color="gray.700" w={8} h={8} as={BsCheck} />
              ) : (
                'Clear'
              )}
            </Button>
          </HStack>
        </Flex>
      ) : (
        <Skeleton h="14px" w="full" />
      )}
    </Box>
  );
};
