import { FC, useState } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import { useActionItems } from '../queries';
import { ActionItemList } from './ActionItemList';
import { Tab, ActionItemTabs } from './ActionItemTabs';
import { CreateActionItemModal } from './CreateActionItemModal';
import { EditActionItemModal } from './EditActionItemModal';
import { ImprovementGoalQueryData } from '@spoke/improvement-goals';
import {
  MotionFlexProps,
  useDisclosure,
  MotionFlex,
  SpkHead,
  Flex,
  Spacer,
  Button,
  Icon,
  Center,
  Tooltip,
  Box,
  Image,
  Text,
  useAssets,
} from '@spoke/common';

export type GoalDrilldownActionItemsProps = MotionFlexProps & {
  goal: ImprovementGoalQueryData;
  actionItems: NonNullable<ReturnType<typeof useActionItems>[0]>;
  refetch: () => void;
};

export const GoalDrilldownActionItems: FC<GoalDrilldownActionItemsProps> = ({
  goal,
  actionItems: { resolved, unresolved },
  refetch,
  ...rest
}) => {
  const [selectedTab, setSelectedTab] = useState(Tab.Unresolved);

  const createActionItemDisclosure = useDisclosure();
  const [editingActionItemId, setEditingActionItemId] = useState<string | null>(
    null
  );

  const { illustrationCollaboration } = useAssets();

  const currentActionItems =
    selectedTab === Tab.Resolved ? resolved : unresolved;

  return (
    <MotionFlex
      flexDir="column"
      initial={{ opacity: 0, transform: 'translateY(10px)' }}
      animate={{ opacity: 1, transform: 'translateY(0px)' }}
      exit={{ opacity: 0, transform: 'translateY(10px)' }}
      transition={{ bounce: false }}
      {...rest}
    >
      <SpkHead title="Action Items" />
      <EditActionItemModal
        actionItemId={editingActionItemId}
        isOpen={Boolean(editingActionItemId)}
        onClose={() => setEditingActionItemId(null)}
        onEdited={refetch}
      />
      <CreateActionItemModal
        isOpen={createActionItemDisclosure.isOpen}
        onClose={createActionItemDisclosure.close}
        initiallySelectedGoalIds={[goal.id]}
        onCreated={refetch}
      />

      <Flex>
        <ActionItemTabs
          tab={selectedTab}
          setTab={setSelectedTab}
          resolvedLength={resolved.length}
          unresolvedLength={unresolved.length}
          size="sm"
          w={250}
        />
        <Spacer />
        <Tooltip
          variant="white"
          placement="left"
          hasArrow
          label={goal.paused ? 'This goal is currently paused' : ''}
        >
          <Box tabIndex={-1}>
            <Button
              variant="outlineGray"
              onClick={createActionItemDisclosure.open}
              leftIcon={<Icon as={BsPlusLg} />}
              disabled={goal.paused}
            >
              Add action item
            </Button>
          </Box>
        </Tooltip>
      </Flex>
      {currentActionItems.length ? (
        <ActionItemList
          actionItems={currentActionItems}
          setEditingActionItemId={setEditingActionItemId}
        />
      ) : (
        <Center>
          <Flex alignItems="center" flexDir="column">
            <Image
              src={illustrationCollaboration}
              alt={`No action items flagged with ${goal.type.name} yet`}
              width="24em"
              mt={16}
              mb={2}
            />
            <Text fontWeight={700} fontSize="20px" color="grey.900">
              No action items flagged with this goal yet
            </Text>
            <Text
              fontSize={16}
              color="gray.500"
              maxW={350}
              mb={4}
              textAlign="center"
            >
              Create your first action item with &quot;{goal.type.name}&quot; to
              start driving improvement.
            </Text>
          </Flex>
        </Center>
      )}
    </MotionFlex>
  );
};
