import { MutationUpdaterFn } from '@apollo/client';
import { deepMerge } from '../../../etc';
import { log } from '../../../../SpkLog';
import { CacheModifiers, DeepPartial } from '../../../../types';
import { Card, List, UpdateCardMutation } from '@spoke/graphql';

type OptimisticUpdateCardArgs = {
  newFields: DeepPartial<Card>;
  oldFields?: Card | null;
};
export const optimisticUpdateCard = ({
  newFields,
  oldFields,
}: OptimisticUpdateCardArgs): UpdateCardMutation => {
  if (!oldFields?.id) {
    log.error('Missing critical data for updateCard optimistic response', {
      id: oldFields?.id,
    });

    return {
      __typename: 'Mutation',
      updateCard: oldFields,
    };
  }

  const merged = deepMerge(oldFields, newFields) as Card;

  // Currently we're not fetching these for children
  // This is backfilling so Apollo won't complain
  merged.wasDiscussed = merged.wasDiscussed ?? false;

  return {
    __typename: 'Mutation',
    updateCard: {
      ...merged,
      __typename: 'Card',
      id: oldFields.id,
    },
  };
};

export const updateCardUpdateFunction: MutationUpdaterFn<UpdateCardMutation> = (
  cache,
  { data }
) => {
  const editedCard = data?.updateCard;

  if (!editedCard) {
    log.error('No card received in updateCardUpdateFunction', {
      deletedComment: editedCard,
    });
    return;
  }

  const listCacheId = 'List:' + editedCard?.listId;

  const updateFields: CacheModifiers<DeepPartial<List>> = {
    cards: (prev = []) => {
      if (Array.isArray(prev)) {
        return prev.map((card) =>
          card?.id === editedCard.id ? editedCard : card
        );
      }
      return [prev];
    },
  };

  cache.modify({
    id: listCacheId,
    fields: updateFields,
  });
};

export const archiveCardUpdateFunction: MutationUpdaterFn<
  UpdateCardMutation
> = (cache, { data }) => {
  const deletedCard = data?.updateCard;

  if (!deletedCard) {
    log.error('No card received in archiveCardUpdateFunction', {
      deletedComment: deletedCard,
    });
    return;
  }

  const listCacheId = 'List:' + deletedCard?.listId;

  const updateFields: CacheModifiers<DeepPartial<List>> = {
    cards: (prev = []) => {
      if (Array.isArray(prev)) {
        return prev.filter((card) => card?.id !== deletedCard.id);
      }
      return [prev];
    },
  };

  cache.modify({
    id: listCacheId,
    fields: updateFields,
  });
};
