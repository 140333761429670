export function truncateText(
  text: string,
  limit: number,
  wordSeparator = ' '
): string {
  if (!text || text.length <= limit) return text;
  const truncatedWords = text.substring(0, limit - 3).split(wordSeparator);
  truncatedWords.pop();
  const truncatedMinusOneWord = truncatedWords.join(wordSeparator) + '...';
  return truncatedMinusOneWord;
}
