import { Icon, IconProps } from '@chakra-ui/react';

export const BitbucketIcon = (props: IconProps) => (
  <Icon
    viewBox="0 0 128 128"
    version="1.1"
    id="svg247"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>BitBucket Logo</title>
    <defs id="defs244">
      <linearGradient
        id="A"
        x1="64.010002"
        y1="30.27"
        x2="32.990002"
        y2="54.48"
        gradientUnits="userSpaceOnUse"
        gradientTransform="matrix(2.0480554,0,0,2.0480554,0.05900357,-5.5088363)"
      >
        <stop offset=".18" stopColor="#0052cc" id="stop2" />
        <stop offset="1" stopColor="#2684ff" id="stop4" />
      </linearGradient>
    </defs>
    <g id="layer1">
      <path
        d="M 4.155112,7.3120051 A 4.0961107,4.0961107 0 0 0 0.05900718,12.063482 L 17.467482,117.62026 a 5.5707104,5.5707104 0 0 0 5.447822,4.64908 h 83.437766 a 4.0961107,4.0961107 0 0 0 4.09611,-3.44072 L 127.85766,12.104443 A 4.0961107,4.0961107 0 0 0 123.76154,7.3529665 Z M 77.3731,83.581575 H 50.748374 l -7.20915,-37.684214 h 40.285249 z"
        fill="#2684ff"
        id="path11"
        style={{ strokeWidth: 2.04808 }}
      />
      <path
        d="M 122.26646,45.938322 H 83.824473 L 77.3731,83.622538 H 50.748374 L 19.310721,120.91763 a 5.55023,5.55023 0 0 0 3.584096,1.35171 h 83.458253 a 4.0961107,4.0961107 0 0 0 4.09611,-3.44072 z"
        fill="url(#A)"
        id="path13"
        style={{ fill: 'url(#A)', strokeWidth: 2.04808 }}
      />
    </g>
  </Icon>
);
