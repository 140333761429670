import { useCallback } from 'react';
import { useRateTeamInsightMutation, QualityName } from '@spoke/graphql';
import { log } from '@spoke/common';

export const useRateTeamInsightHandler = () => {
  const [rateTeamInsight] = useRateTeamInsightMutation();

  const handleRateTeamInsight = useCallback(
    async ({
      teamInsightId,
      rating,
    }: {
      teamInsightId: string;
      rating: QualityName;
    }): Promise<boolean> => {
      if (!teamInsightId) {
        log.error('Missing critical data to rate team insight', {
          teamInsightId,
        });
        return false;
      }

      log.info(`Rating team insight as ${rating}}`, { teamInsightId, rating });

      const { errors } = await rateTeamInsight({
        variables: { teamInsightId, rating },
      });

      if (errors) {
        log.error('rateTeamInsight call responded with errors', {
          errors,
        });
        return false;
      }

      return true;
    },
    [rateTeamInsight]
  );

  return [handleRateTeamInsight];
};
