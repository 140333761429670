import {
  GridItem as ChakraGridItem,
  GridItemProps as ChakraGridItemProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = ChakraGridItemProps;

export const GridItem = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ChakraGridItem {...props} ref={ref} />
));
