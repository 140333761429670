import { useCallback } from 'react';
import {
  useBitbucketRateLimitLock,
  isExternalRateLimitError,
  log,
} from '@spoke/common';
import { useLinkableBitbucketRepositoriesLazyQuery } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type QueryRef = ReturnType<typeof useLinkableBitbucketRepositoriesLazyQuery>[1];
type QueryData = NonNullable<
  NonNullable<
    NonNullable<NonNullable<QueryRef['data']>['team']>
  >['searchLinkableBitbucketRepositories']
>;

type Params = {
  search: string;
};

export const useFetchLinkableBitbucketRepositories = (): [
  (params: Params) => Promise<QueryData>,
  QueryRef
] => {
  const [_, fireBitbucketRateLimitLock] = useBitbucketRateLimitLock();

  const [linkableBitbucketRepositories, queryRef] =
    useLinkableBitbucketRepositoriesLazyQuery({
      // https://scatterspoke.atlassian.net/browse/SC-594
      errorPolicy: 'none',
    });
  const [currentTeam] = useCurrentTeam();

  const fetchLinkableBitbucketRepositories = useCallback(
    async ({ search }: Params) => {
      const teamId = currentTeam?.id;

      if (!teamId) {
        log.error(
          'Missing critical data to fetch linkable Bitbucket repositories',
          {
            teamId,
            search,
          }
        );
        return [];
      }

      log.info(`Fetching linkable Bitbucket repositories`, {
        teamId,
        search,
      });

      const { error, data } = await linkableBitbucketRepositories({
        variables: { teamId, search: search.trim().slice(0, 64) },
      });

      const linkableRepos = data?.team?.searchLinkableBitbucketRepositories;

      if (error || !linkableRepos) {
        log.error('linkableBitbucketRepositories query responded with errors', {
          error,
          data,
          teamId,
          search,
        });

        if (error?.graphQLErrors.some(isExternalRateLimitError)) {
          log.error(
            'Bitbucket linkableRepositories responded with rate limited',
            { error, data }
          );
          fireBitbucketRateLimitLock();
        }

        return [];
      }

      return linkableRepos;
    },
    [currentTeam?.id, fireBitbucketRateLimitLock, linkableBitbucketRepositories]
  );

  return [fetchLinkableBitbucketRepositories, queryRef];
};
