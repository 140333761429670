import axios from 'axios';
import {
  ConfluenceError,
  ConfluenceSpace,
} from './types/integrations/confluence';
import { JiraProject } from './types/integrations/jira';
import {
  TrelloBoard,
  TrelloCard,
  TrelloList,
} from './types/integrations/trello';

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  withCredentials: true,
  validateStatus: () => true, // Prevent axios from throwing on 4xx and 5xx
});

const isOkStatus = (status: number) => status < 300 && status >= 200;

export class SpkHttp {
  static BASE_URL = process.env.NEXT_PUBLIC_API_URL;

  static async logout(): Promise<void> {
    const API_ROUTE_LOGOUT = '/auth/logout';
    await client.post(API_ROUTE_LOGOUT);
  }

  static async getSsoRedirectUrl(email: string): Promise<string> {
    const API_ROUTE_GET_SSO_REDIRECT = '/auth/email';
    return client
      .post(API_ROUTE_GET_SSO_REDIRECT, { email })
      .then((response) => (response.data !== 'OK' ? response.data : undefined));
  }

  static async exportBoardPdf(boardId: string): Promise<string> {
    const API_ROUTE_EXPORT_BOARD_PDF = '/html-export/retrospectivePdf';
    return client
      .get(API_ROUTE_EXPORT_BOARD_PDF + `/${boardId}`, { responseType: 'blob' })
      .then((response) => response.data);
  }

  static async exportBoardEmail(boardId: string, to: string): Promise<boolean> {
    const API_ROUTE_EXPORT_BOARD_EMAIL = '/html-export/retrospectiveEmail';
    return client
      .post(API_ROUTE_EXPORT_BOARD_EMAIL, { boardId, to })
      .then((response) => isOkStatus(response.status));
  }

  static async getJiraProjects(): Promise<{ projects: JiraProject[] }> {
    const API_ROUTE_GET_JIRA_PROJECTS = '/integrations/jira/projects';
    return client
      .get(API_ROUTE_GET_JIRA_PROJECTS)
      .then((response) => response.data);
  }

  static async deauthorizeJira(): Promise<void> {
    const API_ROUTE_DEAUTH_JIRA = '/integrations/jira/auth';
    await client
      .delete(API_ROUTE_DEAUTH_JIRA)
      .then((response) => response.data);
  }

  static async deauthorizeTrello(): Promise<void> {
    const API_ROUTE_DEAUTH_TRELLO = '/integrations/trello/auth';
    await client
      .delete(API_ROUTE_DEAUTH_TRELLO)
      .then((response) => response.data);
  }

  static async deauthorizeConfluence(): Promise<void> {
    const API_ROUTE_DEAUTH_CONFLUENCE = '/integrations/confluence/auth';
    await client
      .delete(API_ROUTE_DEAUTH_CONFLUENCE)
      .then((response) => response.data);
  }

  static async getTrelloBoards(): Promise<TrelloBoard[]> {
    const API_ROUTE_GET_TRELLO_BOARDS = '/integrations/trello/boards';
    return client
      .get(API_ROUTE_GET_TRELLO_BOARDS)
      .then((response) => response.data);
  }

  static async getTrelloBoardLists(
    trelloBoardId: string
  ): Promise<TrelloList[]> {
    const API_ROUTE_GET_TRELLO_BOARD_LISTS = `/integrations/trello/boards/${trelloBoardId}/lists`;
    return client
      .get(API_ROUTE_GET_TRELLO_BOARD_LISTS)
      .then((response) => response.data);
  }

  static async createTrelloCard(card: TrelloCard): Promise<boolean> {
    const API_ROUTE_CREATE_TRELLO_CARD = '/integrations/trello/cards';
    return client
      .post(API_ROUTE_CREATE_TRELLO_CARD, card)
      .then((response) => isOkStatus(response.status));
  }

  static async getConfluenceSpaces(): Promise<ConfluenceSpace[] | null> {
    const API_ROUTE_GET_CONFLUENCE_SPACES = '/integrations/confluence/space';
    return client
      .get(API_ROUTE_GET_CONFLUENCE_SPACES)
      .then((response) => response.data?.results ?? null);
  }

  static async exportBoardConfluence(
    boardId: string,
    confluenceSpaceKey: string
  ): Promise<ConfluenceError | null> {
    const API_ROUTE_EXPORT_BOARD_CONFLUENCE =
      '/integrations/confluence/content';
    const body = { board: { id: boardId }, spaceKey: confluenceSpaceKey };
    return client
      .post(API_ROUTE_EXPORT_BOARD_CONFLUENCE, body)
      .then((response) =>
        !isOkStatus(response.status) ? response.data?.message : null
      );
  }
}
