import { makeVar, useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useInstanceCount, log, useNetworkContext } from '@spoke/common';
import {
  OrgIntegrationsChangedSubscription,
  OrgIntegrationsChangedDocument,
  InstalledOrgIntegrationsQuery,
  InstalledOrgIntegrationsDocument,
} from '@spoke/graphql';

export type UseOrganizationIntegrationsChangedSubscriptionConfig = {
  organizationId: string;
};

/**
 * Keeps references for all unsubscription functions for active subs, keyed by organizationId
 */
const unsubscribeFunctionsVar = makeVar<Record<string, (() => void)[]>>({});
const isSubscribed = (subscriptionKey: string) =>
  Object.keys(unsubscribeFunctionsVar()).includes(subscriptionKey);

/**
 * This hook manages the lifecycle of a subscription for OrganizationIntegrationsChanged events
 * and updates queries that need to react to that.
 */
export const useOrganizationIntegrationsChangedSubscription = ({
  organizationId,
}: UseOrganizationIntegrationsChangedSubscriptionConfig) => {
  const { shouldPoll } = useNetworkContext();
  const apollo = useApolloClient();

  const [getInstanceCount] = useInstanceCount(
    `useOrganizationIntegrationsChangedSubscription-${organizationId}`
  );

  useEffect(() => {
    const subscriptionKey = organizationId;
    const isAlreadySubscribed = isSubscribed(subscriptionKey);

    const shouldSubscribe =
      organizationId &&
      !shouldPoll &&
      getInstanceCount() > 0 &&
      !isAlreadySubscribed;

    if (shouldSubscribe) {
      log.info(`Starting OrganizationIntegrationsChanged subscription`, {
        organizationId,
      });

      const vars = { organizationId };
      const observable = apollo.subscribe<OrgIntegrationsChangedSubscription>({
        query: OrgIntegrationsChangedDocument,
        variables: vars,
      });

      const subscription = observable.subscribe((subscriptionData) => {
        if (subscriptionData.data?.installedOrganizationIntegrationsUpdate) {
          // Compiler was acting weird and did not understand that this type could not be nullable inside the if, so I had to manually assign it
          const data =
            subscriptionData.data.installedOrganizationIntegrationsUpdate;

          apollo.cache.updateQuery<InstalledOrgIntegrationsQuery>(
            { query: InstalledOrgIntegrationsDocument, variables: vars },
            () => ({
              __typename: 'Query',
              installedOrganizationIntegrations: data,
            })
          );
        }
      });

      unsubscribeFunctionsVar({
        ...unsubscribeFunctionsVar(),
        [subscriptionKey]: [subscription.unsubscribe.bind(subscription)],
      });
    }

    return () => {
      const isStillSubscribed = isSubscribed(subscriptionKey);
      const isLastInstance = getInstanceCount() === 0;
      const shouldUnsubscribe = isStillSubscribed && isLastInstance;
      if (shouldUnsubscribe) {
        // No instances with matching variables are mounted, so unsubscribe
        log.info(`Stopping OrganizationIntegrationsChanged subscription`, {
          organizationId,
        });
        const functions = unsubscribeFunctionsVar();
        for (const unsubscribe of functions[subscriptionKey]) unsubscribe();
        delete functions[subscriptionKey];
        unsubscribeFunctionsVar(functions);
      }
    };
  }, [organizationId, getInstanceCount, shouldPoll, apollo]);
};
