import { useCallback, useEffect, useState } from 'react';
import { useRouter } from '../../hooks/etc';
import { getCurrentPathname } from '../../util';

export function useRouteState<T extends string>(
  initialState: T | null,
  routeQueryKey: string
): [T | null, (newState: T | null) => void] {
  const [state, setState] = useState<T | null>(initialState);
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;
    const routeState = router.query[routeQueryKey] as T;
    setState((currentState) =>
      routeState !== currentState
        ? !routeState
          ? null
          : routeState
        : currentState
    );
  }, [routeQueryKey, router.isReady, router.query]);

  const queryHashExcludingSyncedKey = () => {
    const queryWithoutCurrentKey = { ...router.query };
    delete queryWithoutCurrentKey[routeQueryKey];
    return JSON.stringify(queryWithoutCurrentKey);
  };

  const setRouterQuery = useCallback(
    (newState: T | null) => {
      if (newState) {
        router.replace({
          query: { ...router.query, [routeQueryKey]: newState as string },
        });
      } else router.replace(getCurrentPathname());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [routeQueryKey, queryHashExcludingSyncedKey()]
  );

  return [state, setRouterQuery];
}
