import {
  ComponentStyleConfig,
  Popover as ChakraPopover,
  PopoverProps as ChakraPopoverProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const PopoverComponentStyle: ComponentStyleConfig = {};

export type PopoverProps = ChakraPopoverProps;

export const Popover: FC<PopoverProps> = (props) => (
  <ChakraPopover {...props} />
);
