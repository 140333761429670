import { ApolloCache, MutationUpdaterFn } from '@apollo/client';
import { deepMerge } from '../../../etc';
import { log } from '../../../../SpkLog';
import { CacheModifiers, DeepPartial } from '../../../../types';
import { getActionItemCommentFromCache } from '../../getters';
import {
  DeleteActionItemCommentMutation,
  ActionItem,
  ActionItemComment,
} from '@spoke/graphql';

type OptimisticArchiveActionItemCommentArgs = {
  cache: ApolloCache<object>;
  commentId: string;
};
export const optimisticArchiveActionItemComment = ({
  cache,
  commentId,
}: OptimisticArchiveActionItemCommentArgs):
  | DeleteActionItemCommentMutation
  | undefined => {
  const oldFields = getActionItemCommentFromCache({ cache, commentId });
  const newFields: DeepPartial<ActionItemComment> = { archived: true };

  if (!oldFields?.id) {
    log.error(
      'Missing critical data for archiveActionItemComment optimistic response',
      {
        id: oldFields?.id,
      }
    );

    return;
  }

  const merged = deepMerge(oldFields, newFields);

  const { actionItemId, text, author, archived, createdAt } =
    merged as ActionItemComment;

  return {
    __typename: 'Mutation',
    archiveActionItemComment: {
      __typename: 'ActionItemComment',
      id: oldFields.id,
      actionItemId,
      text,
      archived,
      createdAt,
      author,
    },
  };
};

export const archiveActionItemCommentUpdateFunction: MutationUpdaterFn<
  DeleteActionItemCommentMutation
> = (cache, { data }) => {
  const deletedComment = data?.archiveActionItemComment;

  if (!deletedComment) {
    log.error('No comment received in archiveCommentUpdateFunction', {
      deletedComment,
    });
    return;
  }

  const actionItemCacheId = 'ActionItem:' + deletedComment?.actionItemId;

  const updateFields: CacheModifiers<DeepPartial<ActionItem>> = {
    comments: (prev, { readField }) => {
      if (Array.isArray(prev)) {
        return prev.filter(
          (comment) => readField('id', comment) !== deletedComment.id
        );
      }
      return [prev];
    },
  };

  cache.modify({
    id: actionItemCacheId,
    fields: updateFields,
  });
};
