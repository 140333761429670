export const LIST_COLORS = [
  '#FA5252',
  '#F4467C',
  '#5546B8',
  '#228BE6',
  '#4AC8E1',
  '#12B886',
  '#84D247',
  '#D5E34C',
  '#FFCC3E',
  '#FD7E13',
] as const;
