import { FC, memo } from 'react';
import { MemoizedDiscussBoardListCard } from './DiscussBoardListCard';
import { MemoizedDiscussBoardListMenu } from './DiscussBoardListMenu';
import {
  Card,
  BoardDiscussionCardsSortingKey,
  CardDiscussionMovement,
} from '@spoke/graphql';
import { Flex, Divider, callIfExists, Text } from '@spoke/common';

type DiscussBoardListProps = {
  cards: Card[];
  selectedCard: Card;
  onSortKeyChange: ((key: BoardDiscussionCardsSortingKey) => void) | null;
  onSelectDiscussCard:
    | ((cardId: string, moveCurrentTo: CardDiscussionMovement) => void)
    | null;
  sortKey: BoardDiscussionCardsSortingKey;
  sortByTags: boolean;
};
export const DiscussBoardList: FC<DiscussBoardListProps> = ({
  cards,
  onSortKeyChange,
  sortKey,
  selectedCard,
  onSelectDiscussCard,
  sortByTags,
}) => {
  const selectedCardId = selectedCard?.id;
  const toDiscuss = cards.filter((card) => !card.wasDiscussed);
  const dicussed = cards.filter((card) => card.wasDiscussed);

  return (
    <Flex
      flexDir="column"
      justifyContent="flex-start"
      w={350}
      bg="white"
      boxShadow="extralight"
      borderBottomRightRadius="lg"
      ml="-16px"
      pl={3}
      pb={150}
    >
      <Flex justifyContent="space-between" alignItems="center" py={3} px={4}>
        <Text fontSize={14} fontWeight={500} pt="4px">
          {toDiscuss?.length} cards to review
        </Text>
        {onSortKeyChange && (
          <MemoizedDiscussBoardListMenu
            onSortKeyChange={onSortKeyChange}
            sortKey={sortKey}
            sortByTags={sortByTags}
          />
        )}
      </Flex>

      <Divider />
      <Flex flexDir="column" p={2}>
        {toDiscuss?.map((card) => (
          <MemoizedDiscussBoardListCard
            key={card.id}
            card={card}
            isSelected={selectedCardId === card.id}
            onSelect={() =>
              callIfExists(
                onSelectDiscussCard,
                card.id,
                selectedCard.wasDiscussed
                  ? CardDiscussionMovement.ToDiscussed
                  : CardDiscussionMovement.ToDiscussion
              )
            }
          />
        ))}
      </Flex>

      {Boolean(dicussed?.length) && (
        <>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            py={3}
            px={4}
          >
            <Text fontSize={14} fontWeight={500} pt="4px">
              {dicussed?.length} cards reviewed
            </Text>
          </Flex>
          <Divider />
          <Flex flexDir="column" p={2}>
            {dicussed?.map((card) => (
              <MemoizedDiscussBoardListCard
                key={card.id}
                card={card}
                isSelected={selectedCardId === card.id}
                onSelect={() =>
                  callIfExists(
                    onSelectDiscussCard,
                    card.id,
                    selectedCard.wasDiscussed
                      ? CardDiscussionMovement.ToDiscussed
                      : CardDiscussionMovement.ToDiscussion
                  )
                }
              />
            ))}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export const MemoizedDiscussBoardList = memo(DiscussBoardList);
