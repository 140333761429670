import { useToken } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useState, useEffect } from 'react';
import { InvitationBackground } from './InvitationBackground';
import { MemoizedBoardNav } from './Navs/BoardNav';
import { BoardStage } from '@spoke/graphql';
import {
  useIsFirstRender,
  MotionFlex,
  Button,
  useCurrentBoard,
  useTimeout,
  enableSlowBrowserDetection,
  disableSlowBrowserDetection,
  VStack,
  Flex,
  NAV_BAR_HEIGHT,
  log,
  Image,
  Text,
  useAssets,
  useRouter,
  WEB_ROUTES,
  SpkRouteKey,
} from '@spoke/common';

type LoadingSplashProps = {
  isLoading: boolean;
  timedOut: boolean;
  backgroundOnly: boolean;
};
export const LoadingSplash: FC<LoadingSplashProps> = ({
  isLoading,
  timedOut,
  backgroundOnly,
}) => {
  const [gray50] = useToken('colors', ['gray.50']);
  const isFirstRender = useIsFirstRender();
  const { logoFull } = useAssets();

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          exit={{ opacity: 0 }}
          transition={{ ease: 'easeOut', duration: 0.2, delay: 0.3 }}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: gray50,
            zIndex: 11,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <InvitationBackground />
          {!backgroundOnly && (
            <MotionFlex
              initial={{ transform: 'scale(1)' }}
              animate={{ transform: 'scale(1.4)' }}
              exit={{ transform: 'scale(0)' }}
              transition={{
                duration: isFirstRender ? 5 : 0.2,
                type: 'spring',
                delay: 0.1,
              }}
              style={{ zIndex: 12 }}
              flexDir="column"
              alignItems="center"
              position="relative"
            >
              <Image mr={3} src={logoFull} alt="ScatterSpoke Logo" w={170} />
              {timedOut && (
                <MotionFlex
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  position="absolute"
                  bottom={-8}
                  maxW={200}
                >
                  <Text fontSize={8} textAlign="center">
                    This is taking longer than usual. You can try{' '}
                    <Button
                      fontSize={8}
                      as="span"
                      variant="link"
                      onClick={() => location.reload()}
                    >
                      reloading your page
                    </Button>
                    .
                  </Text>
                </MotionFlex>
              )}
            </MotionFlex>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export const BoardLayout: FC = ({ children }) => {
  const [overlayVisible, setOverlayVisible] = useState(true);
  const [board] = useCurrentBoard({ initializeSubscription: false });
  const router = useRouter();

  /**
   * This is a workaround the fact we have /board/[boardId] AND /board/create.
   * Nextjs can handle this, but our route comparing function used in LayoutProvider does not have
   * awareness of all routes like next does. This means this will wrap both /board/create
   * and /board components. That is why we need this to change logic between them.
   */
  const isBoard = router.route === WEB_ROUTES[SpkRouteKey.Board]();

  const isFactsStatsPhase = board?.state.stage === BoardStage.FactsStats;

  const { disableTimeout, enableTimeout, timedOut } = useTimeout(10000);

  const [startRender, setStartRender] = useState(false);

  useEffect(() => {
    const timeout1 = setTimeout(() => {
      enableSlowBrowserDetection();
      setOverlayVisible(false);
    }, 3000);

    const timeout2 = setTimeout(() => {
      disableSlowBrowserDetection();
      setStartRender(true);
    }, 500);

    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
    };
  }, []);

  useEffect(() => {
    if (!isBoard) return;
    if (!board) enableTimeout();
    else disableTimeout();
  }, [board, enableTimeout, disableTimeout, isBoard]);

  useEffect(() => {
    if (!timedOut) return;
    log.error('A user just got stuck in board loading screen');
  }, [timedOut]);

  const loading = overlayVisible || !board;

  return (
    <VStack alignItems="flex-start" spacing={0}>
      <LoadingSplash
        isLoading={loading}
        timedOut={timedOut}
        backgroundOnly={!isBoard}
      />
      {startRender && (
        <>
          <MemoizedBoardNav isLoading={loading} />
          <Flex
            h={`calc(100vh - ${NAV_BAR_HEIGHT})`}
            bg={isFactsStatsPhase ? 'white' : 'gray.50'}
            w="full"
            maxW="100vw"
            alignItems="stretch"
          >
            {children}
          </Flex>
        </>
      )}
    </VStack>
  );
};
