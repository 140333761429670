import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import { useBulkInviteToBoardHandler } from '../../handlers';
import { BoardSetPassword } from './BoardSetPassword';
import { BoardShareLink } from './BoardShareLink';
import { BoardShareNotifyTeam } from './BoardShareNotifyTeam';
import { useCurrentOrg, useFetchOrgMemberships } from '@spoke/user';
import {
  ModalProps,
  useDisclosure,
  EMAIL_PATTERN,
  INVALID_EMAIL_ADDRESS,
  INVALID_EMAIL_DOMAIN,
  MultiTypeAheadSuggestion,
  ModalContent,
  Card,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  VStack,
  Flex,
  Spacer,
  Icon,
  MotionFlex,
  MultiTypeAhead,
  MultiTypeAheadFields,
  HStack,
  MultiTypeAheadAddField,
  Button,
  Modal,
  ModalOverlay,
  log,
  Box,
} from '@spoke/common';

type BoardShareModalProps = Omit<ModalProps, 'children'> & {
  teamNotified: boolean;
  setTeamNotified: (value: boolean) => void;
};

export const BoardShareModalContent: FC<BoardShareModalProps> = (props) => {
  const [currentOrg] = useCurrentOrg();
  const [emailsToInvite, setEmailsToInvite] = useState<string[]>(['']);
  const [invitesLoading, setInvitesLoading] = useState(false);
  const emailDisclosure = useDisclosure(false);

  const [fetchMemberships] = useFetchOrgMemberships();

  const [handleBulkInviteToBoard] = useBulkInviteToBoardHandler();

  const onBulkInviteToBoard = async () => {
    setInvitesLoading(true);
    await handleBulkInviteToBoard(emailsToInvite);
    setInvitesLoading(false);
    setEmailsToInvite(['']);
    props.onClose();
  };

  const validateInviteEmail = (text: string) => {
    const sentryPingWord = process.env.NEXT_PUBLIC_SENTRY_PING_WORD as string;
    if (sentryPingWord && text === sentryPingWord) {
      throw new Error("Toto, I have a feeling we're not in Kansas anymore...");
    }

    const isValid = text.match(EMAIL_PATTERN);
    if (!isValid) return INVALID_EMAIL_ADDRESS;
    const orgHasDomainAccess = Boolean(currentOrg?.domains?.length);
    const emailIsInDomain = currentOrg?.domains
      ?.map((d) => d.toLowerCase())
      ?.includes(text.split('@')[1]?.toLowerCase());
    if (orgHasDomainAccess && !emailIsInDomain && currentOrg?.hasSSO) {
      return INVALID_EMAIL_DOMAIN;
    }

    return null;
  };

  const getEmailSuggestions = async (
    email: string
  ): Promise<MultiTypeAheadSuggestion[]> => {
    const organizationId = currentOrg?.id;

    if (!organizationId) {
      log.error('Cannot fetch email suggestions without loaded org id', {
        organizationId,
      });
      return [];
    }

    const memberships = await fetchMemberships({ email, organizationId });

    const suggestions: MultiTypeAheadSuggestion[] = memberships.map(
      (membership) => ({
        label: `"${membership.user.name}" <${membership.user.email}>`,
        value: membership.user.email,
      })
    );

    return suggestions.filter(
      (suggestion) => !emailsToInvite.includes(suggestion.value)
    );
  };

  return (
    <ModalContent maxW={600}>
      <Card variant="modal" mb={2}>
        <ModalHeader p={0}>
          <Heading fontSize="20px" color="gray.900" fontWeight={700} mb={4}>
            Share your retrospective
          </Heading>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={0}>
          <VStack w="full" alignItems="stretch">
            <Box layerStyle="outlineGray" p={4} py={4}>
              <BoardShareLink />
            </Box>
            <Box layerStyle="outlineGray" padding={4}>
              <BoardShareNotifyTeam
                teamNotified={props.teamNotified}
                setTeamNotified={props.setTeamNotified}
              />
            </Box>
            <Box layerStyle="outlineGray" padding={4}>
              <BoardSetPassword />
            </Box>
          </VStack>
        </ModalBody>
      </Card>
      <Card
        variant="modal"
        maxHeight={emailDisclosure.isOpen ? '1024px' : '92px'}
        transition="max-height 0.2s ease-out"
        pb={3}
      >
        <ModalHeader p={0} mb={4}>
          <Flex cursor="pointer" gap={1} onClick={emailDisclosure.toggle}>
            <Heading mb={1} color="gray.900" fontSize={18} fontWeight={500}>
              Invite new users to the team
            </Heading>
            <Spacer />
            <Icon
              as={BsChevronDown}
              transform={emailDisclosure.isOpen ? 'rotate(180deg)' : undefined}
            />
          </Flex>
        </ModalHeader>
        <AnimatePresence>
          {emailDisclosure.isOpen && (
            <MotionFlex
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
              display="block"
              mb={6}
            >
              <Box>
                <MultiTypeAhead
                  values={emailsToInvite}
                  setValues={setEmailsToInvite}
                  validateField={validateInviteEmail}
                  getSuggestions={getEmailSuggestions}
                  icon={
                    <Icon color="gray.500" w={6} h={6} as={MdOutlineEmail} />
                  }
                  placeholder="Enter new member's email"
                >
                  <MultiTypeAheadFields mb={3} />
                  <HStack justifyContent="space-between">
                    <MultiTypeAheadAddField>
                      + Add next person
                    </MultiTypeAheadAddField>
                    <HStack>
                      <Button
                        size="lg"
                        variant="outlineGray"
                        onClick={props.onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onBulkInviteToBoard}
                        isLoading={invitesLoading}
                        disabled={emailsToInvite.some(validateInviteEmail)}
                        size="lg"
                      >
                        Invite
                      </Button>
                    </HStack>
                  </HStack>
                </MultiTypeAhead>
              </Box>
            </MotionFlex>
          )}
        </AnimatePresence>
      </Card>
    </ModalContent>
  );
};

export const BoardShareModal: FC<BoardShareModalProps> = (props) => (
  <Modal variant="unstyled" {...props}>
    <ModalOverlay />
    <BoardShareModalContent {...props} />
  </Modal>
);
