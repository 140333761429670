import { ImprovementGoalTypeTargetType } from '@spoke/graphql';

export const dataPointIsOnTarget = (
  value: number,
  target: number | undefined,
  direction: ImprovementGoalTypeTargetType
) =>
  target === undefined
    ? true
    : direction === ImprovementGoalTypeTargetType.Above
    ? value >= target
    : value <= target;
