import { useCallback } from 'react';
import {
  useCurrentUser,
  useToast,
  TOAST_ERROR_GENERIC,
  log,
  EMAIL_VERIFICATION_TOKEN_RESENT,
} from '@spoke/common';
import { useResendVerificationCodeMutation } from '@spoke/graphql';

export const useResendVerificationCodeHandler = () => {
  const [currentUser] = useCurrentUser();
  const [toast] = useToast();
  const email = currentUser?.email;
  const [resendVerificationCodeMutation] = useResendVerificationCodeMutation();

  const handleResendVerificationCode = useCallback(async () => {
    if (!email) {
      log.error(
        'Cannot request the resend of verification code for an empty email',
        { currentUser }
      );
      return;
    }

    log.info('Resending the email verification code', { currentUser });
    const { errors } = await resendVerificationCodeMutation({
      variables: { email },
    });

    if (errors) {
      log.error('Could not resend the verification code', errors);
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    toast({
      status: 'success',
      title: 'Success',
      description: EMAIL_VERIFICATION_TOKEN_RESENT,
    });
  }, [email, currentUser, resendVerificationCodeMutation, toast]);

  return [handleResendVerificationCode];
};
