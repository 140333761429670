import { FC } from 'react';
import { Box, BoxProps } from './Box';
import { Heading } from './Heading';
import { Skeleton } from './Skeleton';
import { HStack } from './Stack';
import { Text } from './Text';

export type SimpleCardProps = BoxProps & {
  label?: string;
  value: string;
  labelColor?: string;
  valueColor?: string;
  loading?: boolean;
};

export const SimpleCard: FC<SimpleCardProps> = ({
  label,
  value,
  labelColor,
  valueColor,
  loading,
  ...props
}) => (
  <Box
    backgroundColor="white"
    border="solid 1px"
    borderColor="gray.200"
    borderRadius={8}
    p={4}
    {...props}
  >
    <HStack marginBottom={2}>
      {loading && label === undefined ? (
        <Skeleton height={4} w="75%" />
      ) : (
        <Heading
          size="xs"
          color={labelColor ?? 'gray.600'}
          fontWeight={500}
          fontSize={14}
          noOfLines={1}
        >
          {label}
        </Heading>
      )}
    </HStack>
    {loading ? (
      <Skeleton w="full" h={4} />
    ) : (
      <Text
        pb="4px"
        color={valueColor ?? 'gray.900'}
        borderRadius={4}
        fontSize={16}
        fontWeight={500}
        as="span"
      >
        {value}
      </Text>
    )}
  </Box>
);
