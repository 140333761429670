import { Global } from '@emotion/react';

// TODO add remaining weights here
export const NeueMachinaFont = () => (
  <Global
    styles={`
      @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

      @font-face {
        font-family: 'neuemachina-ultrabold';
        font-style: normal;
        font-weight: normal;
        src: url('/assets/next/fonts/neuemachina/neuemachina-ultrabold.otf');
      }

      `}
  />
);
