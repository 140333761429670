import { FC } from 'react';
import useMeasure from 'react-use-measure';
import {
  formatWithGoalUnit,
  Tooltip,
  getIdealContrastForBackground,
  Flex,
  Text,
} from '@spoke/common';
import { ImprovementGoalUnit } from '@spoke/graphql';

type Props = {
  label: string;
  value: number;
  formattedPercentage: string;
  unit: ImprovementGoalUnit;
  color: string;
  isEmpty?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
};
export const DistributionBarsBar: FC<Props> = ({
  label,
  formattedPercentage,
  unit,
  value,
  isFirst,
  isLast,
  color: _color,
  isEmpty,
}) => {
  const [ref, measure] = useMeasure();
  const formattedValue = formatWithGoalUnit(value, unit);
  const color = isEmpty ? 'gray.200' : _color;
  const flex = value;

  return (
    <Tooltip
      key={label}
      label={
        isEmpty ? '' : `${label} | ${formattedValue} | ${formattedPercentage}`
      }
      variant="white"
      bg={color}
      color={getIdealContrastForBackground(color)}
      placement="top-start"
      hasArrow={false}
    >
      <Flex
        ref={ref}
        bg={color}
        flex={flex}
        borderRightRadius={isLast ? '4px' : 0}
        borderLeftRadius={isFirst ? '4px' : 0}
        p={2}
        px={3}
        h={9}
        role="group"
        alignItems="center"
        minW={3}
        overflow="hidden"
      >
        {measure.width >= 50 && (
          <Text
            fontSize={14}
            lineHeight="11px"
            cursor="default"
            fontWeight={500}
            color={isEmpty ? 'gray.700' : getIdealContrastForBackground(color)}
            textAlign="start"
            w="full"
            whiteSpace="nowrap"
            transition="opacity 0.2s ease-out"
          >
            {isEmpty ? 'No data' : formattedPercentage}
          </Text>
        )}
      </Flex>
    </Tooltip>
  );
};
