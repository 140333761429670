import { useCallback } from 'react';
import {
  TrelloCard,
  useToast,
  TOAST_ERROR_GENERIC,
  getCurrentOrigin,
  SpkHttp,
  plural,
  pluralSwitch,
  log,
} from '@spoke/common';
import { Card } from '@spoke/graphql';

const cardToTrelloCard = ({
  card,
  boardUrl,
  trelloListId,
}: {
  card: Pick<Card, 'text'>;
  boardUrl: string;
  trelloListId: string;
}): TrelloCard => ({
  name: card.text,
  idList: trelloListId,
  desc: `Exported from [ScatterSpoke](https://www.scatterspoke.com). Click [here](${boardUrl}) to see original retrospective.`,
  pos: 'top',
});

export const useCreateTrelloCardsFromCardsHandler = () => {
  const [toast] = useToast();

  const handleCreateTrelloCardsFromCards = useCallback(
    async ({
      cards,
      spokeBoardId,
      trelloListId,
    }: {
      cards: Array<Pick<Card, 'text'>>;
      spokeBoardId: string;
      trelloListId: string;
    }): Promise<boolean> => {
      if (!cards.length) {
        log.warn('No-op: no cards to create Trello cards from', { cards });
        return false;
      }

      if (!trelloListId) {
        log.error(
          'Cannot bulk create Trello issues from cards without Trello board id',
          { trelloListId }
        );
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      if (!spokeBoardId) {
        log.error('Cannot export to Trello without a ScatterSpoke board id', {
          spokeBoardId,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      const boardUrlObj = new URL(getCurrentOrigin());
      boardUrlObj.pathname = `/board/${spokeBoardId}`;
      const boardUrl = boardUrlObj.toString();

      const trelloCards: TrelloCard[] = cards.map((card) =>
        cardToTrelloCard({ card, trelloListId, boardUrl })
      );

      log.info(
        `Bulk creating ${trelloCards.length} Trello cards from ScatterSpoke cards`,
        { trelloCards, spokeBoardId, trelloListId }
      );

      // TODO we should add a bulk call instead of this
      // Would also allow us to properly handle errors
      const results = await Promise.all(
        trelloCards.map((trelloCard) => SpkHttp.createTrelloCard(trelloCard))
      );

      const hasError = results.reduce((acc, cur) => acc || !cur, false);

      if (hasError) {
        log.error('Bulk create Jira issues from cards responded with error', {
          trelloCards,
          hasError,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      toast({
        status: 'success',
        title: 'Success',
        description: `${trelloCards.length} ${plural(
          'card',
          trelloCards.length
        )} ${pluralSwitch(
          'has',
          'have',
          trelloCards.length
        )} been successfully exported to Trello.`,
      });

      return true;
    },
    [toast]
  );

  return [handleCreateTrelloCardsFromCards];
};
