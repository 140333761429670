import React, { createContext, FC, useContext } from 'react';

type SpkAppName = 'web' | 'forgelite';

export type SpkApp = {
  name: SpkAppName;
};

const DEFAULT_APP: SpkApp = { name: 'web' };

const AppContext = createContext<SpkApp>(DEFAULT_APP);

/**
 * Gives components context of what app are they're in.
 * @deprecated this is a last resort, ideally components should be agnostic of this.
 */
export const useAppContext = () => useContext(AppContext);

export const AppContextProvider: FC<{ app: SpkApp }> = ({ children, app }) => (
  <AppContext.Provider value={app}>{children}</AppContext.Provider>
);
