import { useEffect } from 'react';
import { useInstalledOrgIntegrations } from './useInstalledOrgIntegrations';
import {
  useJiraRateLimitLock,
  isExternalRateLimitError,
  log,
} from '@spoke/common';
import { useLinkedJiraBoardsQuery } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type QueryRef = ReturnType<typeof useLinkedJiraBoardsQuery>;
type QueryData = NonNullable<
  NonNullable<QueryRef['data']>['team']
>['linkedJiraBoards'];

export const useCurrentTeamJiraBoards = (): [QueryData | null, QueryRef] => {
  const [_, fireJiraRateLimitLock] = useJiraRateLimitLock();
  const { jira: installedOnOrg } = useInstalledOrgIntegrations();
  const [team] = useCurrentTeam();

  const jiraBoardsQuery = useLinkedJiraBoardsQuery({
    skip: !team?.id || !installedOnOrg,
    variables: { teamId: team?.id || '' },
    // https://scatterspoke.atlassian.net/browse/SC-594
    errorPolicy: 'none',
  });

  useEffect(() => {
    if (!jiraBoardsQuery.error) return;
    const hasRateLimitError = jiraBoardsQuery.error?.graphQLErrors.some(
      isExternalRateLimitError
    );
    if (hasRateLimitError) {
      log.error('Jira linkedBoards responded with rate limited', {
        error: jiraBoardsQuery.error,
      });
      fireJiraRateLimitLock();
    }
  }, [fireJiraRateLimitLock, jiraBoardsQuery.error]);

  const boards = jiraBoardsQuery.data?.team?.linkedJiraBoards ?? null;

  return [boards, jiraBoardsQuery];
};
