const hexToRgb = (hex: string): [number, number, number] | null => {
  // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16),
      ]
    : null;
};

export const getIdealContrastForBackground = (
  hexBgColor: string,
  brightnessThreshold = 125
): 'black' | 'white' => {
  const rgb = hexToRgb(hexBgColor);

  if (!rgb) {
    return 'black';
  }

  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(
    (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
  );

  if (brightness > brightnessThreshold) {
    return 'black';
  } else {
    return 'white';
  }
};
