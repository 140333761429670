import { As } from '@chakra-ui/react';
import { FC } from 'react';
import {
  VStack,
  Flex,
  Icon,
  HStack,
  Button,
  Text,
  useRouter,
} from '@spoke/common';

export type IntegrationLinkSelectorEmptyStateProps = {
  icon: As;
  title: string;
  description: string;
};

export const IntegrationLinkSelectorEmptyState: FC<
  IntegrationLinkSelectorEmptyStateProps
> = ({ icon, title, description }) => {
  const router = useRouter();

  return (
    <VStack h={117} alignItems="stretch" layerStyle="outlineGray" spacing={4}>
      <Flex flexDir="column" flex="1">
        <Text fontSize={15} fontWeight={500} color="gray.700">
          <Icon as={icon} mr={1} mb="3px" />
          {title}
        </Text>
        <Text mb={2} fontSize={14} color="gray.500">
          {description}.
        </Text>
        <HStack flex={1}>
          <Text color="gray.500" fontSize={14}>
            Your organization has not set up {title} integration yet. You can do
            that within your{' '}
            <Button
              variant="link"
              fontSize={14}
              textDecor="underline"
              fontWeight={400}
              onClick={() => router.push(`/integrations`)}
            >
              organization settings
            </Button>
            .
          </Text>
        </HStack>
      </Flex>
    </VStack>
  );
};
