import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import {
  useSetGoalRemindersMutation,
  ImprovementGoalUserReminderFrequency,
} from '@spoke/graphql';

export const useClearGoalRemindersHandler = () => {
  const [setGoalReminders] = useSetGoalRemindersMutation();
  const [toast] = useToast();

  const handleClearGoalReminders = useCallback(
    async (data: { improvementGoalId: string }): Promise<boolean> => {
      log.info('Clearing improvement goal reminders', { data });

      const { errors } = await setGoalReminders({
        variables: {
          frequency: ImprovementGoalUserReminderFrequency.Daily,
          improvementGoalId: data.improvementGoalId,
          notificationTime: 0,
          userIds: [],
        },
      });

      if (errors) {
        log.error('clearGoalReminders call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [setGoalReminders, toast]
  );

  return [handleClearGoalReminders];
};
