import { FC, useState } from 'react';
import { BiLinkAlt } from 'react-icons/bi';
import { useExportBoardToConfluenceHandler } from '../../handlers';
import {
  useInstalledUserIntegrations,
  useConfluenceIntegration,
} from '@spoke/user';
import {
  ModalProps,
  ConfluenceSpace,
  useCurrentBoardId,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  ConfluenceIcon,
  Heading,
  ModalBody,
  VStack,
  Flex,
  Spinner,
  Button,
  Modal,
  ModalOverlay,
  log,
  Text,
  Image,
  useAssets,
} from '@spoke/common';

type ExportBoardConfluenceModalProps = Omit<ModalProps, 'children'>;

export const ExportBoardConfluenceModalContent: FC<
  ExportBoardConfluenceModalProps
> = (props) => {
  const { confluence: isConfluenceAuthorized } = useInstalledUserIntegrations();
  const { authenticateConfluence, confluenceLoading, confluenceSpaces } =
    useConfluenceIntegration();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedSpace, setSelectedSpace] = useState<ConfluenceSpace | null>(
    null
  );

  const { illustrationPlans } = useAssets();

  const [exportBoardToConfluence] = useExportBoardToConfluenceHandler();
  const [boardId] = useCurrentBoardId();

  const isSpaceSelected = (space: ConfluenceSpace) =>
    selectedSpace?.id === space.id;

  const selectSpace = (space: ConfluenceSpace) =>
    isSpaceSelected(space) ? setSelectedSpace(null) : setSelectedSpace(space);

  const onSubmit = async () => {
    const confluenceSpaceKey = selectedSpace?.key;
    if (!confluenceSpaceKey) {
      log.error(
        'No-op: cannot export to Confluence without a selected space key',
        { confluenceSpaceKey }
      );
      return;
    }

    if (!boardId) {
      log.error('Cannot export to Confluence without loaded boardId', {
        boardId,
      });
      return;
    }

    setIsSubmitting(true);
    const success = await exportBoardToConfluence({
      boardId,
      confluenceSpaceKey,
    });
    setIsSubmitting(false);
    if (success) props.onClose();
  };

  return (
    <ModalContent p={8} maxW={600}>
      <ModalCloseButton />
      <ModalHeader p={0}>
        <HStack>
          <Icon as={ConfluenceIcon} />
          <Heading mb={1} fontSize={24} color="gray.900">
            Export to Confluence
          </Heading>
        </HStack>
        <Text mb={3} fontSize={16} fontWeight={400} color="gray.500">
          Upload a summary of your retro to a Confluence workspace
        </Text>
      </ModalHeader>
      <ModalBody p={0}>
        <VStack alignItems="flex-start">
          {isConfluenceAuthorized ? (
            confluenceLoading ? (
              <Flex
                layerStyle="outlineGray"
                flexDir="column"
                alignItems="center"
                justifyContent="center"
                w="full"
                my={4}
                py={10}
                minH={180}
              >
                <Spinner size="xl" thickness="5px" />
              </Flex>
            ) : (
              <>
                <Text fontWeight={400} fontSize={18} as="span">
                  Select a Confluence space to export to
                </Text>
                <Flex flexWrap="wrap" gap={2} maxH={400} overflowY="auto">
                  {confluenceSpaces?.map((space) => (
                    <Text
                      as="button"
                      key={space.id}
                      borderWidth={2}
                      borderStyle="solid"
                      onClick={() => selectSpace(space)}
                      borderRadius="lg"
                      justifyContent="space-between"
                      p={2}
                      borderColor={
                        isSpaceSelected(space) ? 'primary.500' : 'gray.100'
                      }
                    >
                      {space.name}
                    </Text>
                  ))}
                </Flex>
              </>
            )
          ) : null}
          {!isConfluenceAuthorized && (
            <Flex
              layerStyle="outlineGray"
              flexDir="column"
              alignItems="center"
              w="full"
              my={4}
              py={10}
            >
              <Image
                src={illustrationPlans}
                alt="Connect to Confluence"
                w={250}
                mb={3}
              />
              <Text fontWeight={500} fontSize={16}>
                Nothing to show here...
              </Text>
              <Text fontSize={14} mb={2}>
                You need to connect to Confluence first.
              </Text>
              <Button
                variant="outlineGray"
                onClick={authenticateConfluence}
                isLoading={confluenceLoading}
                leftIcon={<Icon as={BiLinkAlt} />}
              >
                Connect
              </Button>
            </Flex>
          )}
        </VStack>
        <HStack w="full" justifyContent="flex-end" mt={4}>
          <Button
            size="lg"
            isDisabled={isSubmitting}
            variant="outlineGray"
            type="submit"
            w="fit-content"
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            w="fit-content"
            isLoading={isSubmitting}
            isDisabled={!selectedSpace}
            onClick={onSubmit}
          >
            Export to Confluence
          </Button>
        </HStack>
      </ModalBody>
    </ModalContent>
  );
};

export const ExportBoardConfluenceModal: FC<ExportBoardConfluenceModalProps> = (
  props
) => (
  <Modal {...props}>
    <ModalOverlay />
    <ExportBoardConfluenceModalContent {...props} />
  </Modal>
);
