import { MutableRefObject, useEffect } from 'react';

export const useOutsideClick = (
  handler: (e: MouseEvent) => void,
  ...refs: MutableRefObject<HTMLElement | null>[]
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (
        !refs?.every((r) => r.current) ||
        refs.some((r) => r.current?.contains(event.target as Node))
      ) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [refs, handler]);
};
