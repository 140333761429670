import { FC, useState, FormEvent } from 'react';
import { BsArrowUpCircle, BsChevronDown } from 'react-icons/bs';
import { usePromoteCardToProgramHandler } from '../handlers';
import { useCurrentBoardTeam } from '../queries';
import { Card } from '@spoke/graphql';
import {
  truncateWithCount,
  plural,
  MultiSelect,
  ModalProps,
  useToast,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  ModalBody,
  Flex,
  FormControl,
  VStack,
  FormLabel,
  Spacer,
  Modal,
  ModalOverlay,
  ProgramIcon,
  Text,
  Button,
} from '@spoke/common';

type PromoteCardToProgramModalProps = Omit<ModalProps, 'children'> & {
  card?: Card | null;
};

export const PromoteCardToProgramModalContent: FC<
  PromoteCardToProgramModalProps
> = ({ card, onClose }) => {
  const [toast] = useToast();
  const [handlePromoteCard] = usePromoteCardToProgramHandler();

  const [boardTeam] = useCurrentBoardTeam();
  const programs = boardTeam?.parents;
  const programsAlreadyPromotedTo = card?.promotedToPrograms;

  const programOptions: Array<{ id: string; name: string; disabled: boolean }> =
    programs?.map((program) => {
      const disabled =
        programsAlreadyPromotedTo?.some((p) => p.id === program.id) ?? false;
      return {
        id: program.id,
        name: `${program.name}${disabled ? ' (Already promoted)' : ''}`,
        disabled,
      };
    }) ?? [];

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPrograms, setSelectedPrograms] = useState<
    Array<{ id: string; name: string }>
  >([]);

  const programsDropdown = useDisclosure();

  const programsLabel = selectedPrograms.length
    ? truncateWithCount(selectedPrograms, (p) => p.name, 40)
    : 'Select programs';

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const cardId = card?.id;
    const programIds = selectedPrograms.map((p) => p.id);
    if (!cardId) return;

    setIsSubmitting(true);
    const success = await handlePromoteCard(cardId, programIds);
    setIsSubmitting(false);
    if (!success) return;

    onClose();
    toast({
      status: 'success',
      title: 'Success',
      description: `Card promoted to ${plural(
        'program',
        selectedPrograms.length
      )} ${selectedPrograms.map((p) => p.name).join(', ')}.`,
    });
  };

  const isInvalid = !selectedPrograms.length;

  return (
    <ModalContent p={8} maxW={600}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Icon as={BsArrowUpCircle} w={5} h={5} />
          <Heading mb={1} fontSize={24} color="gray.900">
            Promote card to program
          </Heading>
        </HStack>
        <Text fontSize={14} fontWeight={400} mt={1}>
          This will export this card as a Parking Lot item in the programs you
          select.
        </Text>
      </ModalHeader>
      <ModalBody p={0}>
        <Flex
          flexDir="column"
          alignItems="flex-start"
          justifyContent="center"
          as="form"
          onSubmit={onSubmit}
        >
          <FormControl mb={6} mt={1}>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel fontSize={15} htmlFor="title">
                  Select programs to promote to
                </FormLabel>
                <MultiSelect
                  values={selectedPrograms}
                  onChange={setSelectedPrograms}
                  onClose={programsDropdown.close}
                  isOpen={programsDropdown.isOpen}
                  getOptions={() => programOptions}
                  idKey="id"
                  labelKeyOrFn="name"
                >
                  <Button
                    justifyContent="start"
                    onClick={programsDropdown.toggle}
                    variant="outlineGray"
                    leftIcon={<Icon as={ProgramIcon} w={5} h={5} mr={1} />}
                    w="full"
                    fontSize={15}
                    color={!selectedPrograms.length ? 'gray.500' : 'gray.900'}
                    fontWeight={!selectedPrograms.length ? '400' : '500'}
                  >
                    {programsLabel}
                    <Spacer />
                    <Icon as={BsChevronDown} />
                  </Button>
                </MultiSelect>
              </FormControl>
            </VStack>
          </FormControl>

          <HStack ml="auto">
            <Button
              size="lg"
              isDisabled={isSubmitting}
              variant="outlineGray"
              type="button"
              w="fit-content"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="lg"
              w="fit-content"
              isLoading={isSubmitting}
              isDisabled={isInvalid}
              type="submit"
            >
              Promote
            </Button>
          </HStack>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

export const PromoteCardToProgramModal: FC<PromoteCardToProgramModalProps> = (
  props
) => (
  <Modal {...props}>
    <ModalOverlay />
    <PromoteCardToProgramModalContent {...props} />
  </Modal>
);
