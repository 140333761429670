import { BadgeProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Badge } from '../../ui';

type ColorBadgeProps = Omit<BadgeProps, 'color'> & {
  color?: string | null;
};

export const ColorBadge: FC<ColorBadgeProps> = ({ color, ...rest }) => (
  <Badge
    bg={color || 'white'}
    minW="5px"
    minH="20px"
    borderRadius={3}
    boxShadow="0px 0px 0px 1px rgba(0, 0, 0, 0.2) inset"
    {...rest}
  />
);
