import { useCallback } from 'react';
import {
  useCurrentUser,
  useToast,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useChangeUserCurrentTeamAndAddIfNotMemberMutation } from '@spoke/graphql';

export const useSwitchTeamAndAddIfNotMemberHandler = () => {
  const [currentUser] = useCurrentUser();
  const [changeUserCurrentTeamAndAddIfNotMemberMutation] =
    useChangeUserCurrentTeamAndAddIfNotMemberMutation();
  const [toast] = useToast();
  const userId = currentUser?.id;

  const handleSwitchTeam = useCallback(
    async (teamId: string) => {
      if (!userId) {
        log.error('Cannot switch/add to team without loaded current userId', {
          userId,
        });
        return;
      }
      log.info(`Changing/adding currentTeam to [${teamId}]`);
      const { errors } = await changeUserCurrentTeamAndAddIfNotMemberMutation({
        variables: { teamId, userId },
      });
      if (errors) {
        log.warn('Handled currentTeam change/add GraphQL error', errors);
        toast(TOAST_ERROR_GENERIC);
      }
    },
    [toast, changeUserCurrentTeamAndAddIfNotMemberMutation, userId]
  );

  return [handleSwitchTeam];
};
