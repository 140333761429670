import { truncateText } from './truncateText';

export const truncateWithCount = <T>(
  entries: T[],
  labelKeyOrFn: keyof T | ((entry: T) => string),
  maxLength: number = 20
): string => {
  const getLabel = (entry: T): string =>
    typeof labelKeyOrFn === 'function'
      ? labelKeyOrFn(entry)
      : ((entry?.[labelKeyOrFn] ?? '') as unknown as string);

  const entryLabels = entries.map((e) => getLabel(e) as unknown as string);

  const nonTruncatedLabels = [] as string[];

  for (const entryLabel of entryLabels) {
    const totalLength = nonTruncatedLabels
      .concat([entryLabel.split(' ')[0]])
      .join(', ').length;
    if (totalLength >= maxLength - 6) break;
    nonTruncatedLabels.push(entryLabel);
  }

  const prefix = nonTruncatedLabels.join(', ');

  const suffix =
    nonTruncatedLabels.length === entryLabels.length
      ? ''
      : ` +${entryLabels.length - nonTruncatedLabels.length}`;

  return `${truncateText(prefix, maxLength - suffix.length - 3)}${suffix}`;
};
