import { FC } from 'react';
import { BiTrashAlt } from 'react-icons/bi';
import {
  useDisclosure,
  Flex,
  isNullish,
  Circle,
  dataPointIsOnTarget,
  truncateDecimals,
  Spacer,
  Button,
  Icon,
  Box,
  ConfirmationModalCondensed,
  Text,
} from '@spoke/common';
import {
  LivePollUserStatus,
  ImprovementGoalTypeTargetType,
} from '@spoke/graphql';

type LivePollGoalCardEndedProps = {
  onDiscard: () => void;
  status: LivePollUserStatus.Discarded | LivePollUserStatus.Finished;
  isFacilitator: boolean;
  goalTypeName: string;
  goalTypeColor: string;
  target: number;
  result: number | null;
  skippedCount: number;
  answeredCount: number;
  teamMemberCount: number;
  targetType: ImprovementGoalTypeTargetType;
};
export const LivePollGoalCardEnded: FC<LivePollGoalCardEndedProps> = ({
  answeredCount,
  goalTypeColor,
  goalTypeName,
  isFacilitator,
  onDiscard,
  teamMemberCount,
  result,
  skippedCount,
  targetType,
  target,
  status,
}) => {
  const confirmDiscard = useDisclosure();

  return (
    <Flex layerStyle="outlineGray" flexDir="column" gap={2} w="full">
      <Flex gap={2} alignItems="flex-start">
        <Circle mt="5px" bg={goalTypeColor} size={2} />
        <Text cursor="pointer" fontSize={14} color="gray.700" fontWeight={400}>
          {goalTypeName}
        </Text>
      </Flex>
      <Flex gap={6} w="full">
        <Box>
          <Text fontSize={14} color="gray.600" fontWeight={400}>
            Answers
          </Text>
          <Text fontSize={14} color="gray.900" fontWeight={500}>
            {answeredCount} / {teamMemberCount}
          </Text>
        </Box>
        <Box>
          <Text fontSize={14} color="gray.600" fontWeight={400}>
            Skips
          </Text>
          <Text fontSize={14} fontWeight={500}>
            {skippedCount}
          </Text>
        </Box>
        <Box>
          <Text fontSize={14} color="gray.600" fontWeight={400}>
            Target
          </Text>
          <Text fontSize={14} fontWeight={500}>
            {targetType === ImprovementGoalTypeTargetType.Above ? '>' : '<'}{' '}
            {target}
          </Text>
        </Box>
        <Box>
          <Text fontSize={14} color="gray.600" fontWeight={400}>
            Poll result
          </Text>
          <Text fontSize={14} color="red.500" fontWeight={400}>
            <Text
              as="span"
              textDecor={
                status === LivePollUserStatus.Discarded
                  ? 'line-through'
                  : 'none'
              }
              fontSize={14}
              fontWeight={500}
              mr={2}
              color={
                isNullish(result)
                  ? 'gray.600'
                  : dataPointIsOnTarget(result!, target, targetType)
                  ? 'green.700'
                  : 'negativeRed'
              }
            >
              {truncateDecimals(result ?? 0, 2) ?? 'N/A'}
            </Text>

            {status === LivePollUserStatus.Discarded &&
              'Result discarded by facilitator'}
          </Text>
        </Box>
        <Spacer />
        {Boolean(
          isFacilitator &&
            !isNullish(result) &&
            status !== LivePollUserStatus.Discarded
        ) && (
          <Button
            variant="outlineGray"
            leftIcon={<Icon as={BiTrashAlt} color="gray.600" w={5} h={5} />}
            onClick={confirmDiscard.open}
          >
            Discard result
          </Button>
        )}
      </Flex>
      <ConfirmationModalCondensed
        icon={<Icon as={BiTrashAlt} mr={1} />}
        title="Discard poll result"
        close={confirmDiscard.close}
        isOpen={confirmDiscard.isOpen}
        text={`Are you sure you want to discard result <b>${result}</b>?<br>The corresponding data point will be removed from <b>${goalTypeName}</b> Goal.`}
        confirmText="Discard"
        onConfirm={() => {
          onDiscard();
          confirmDiscard.close();
        }}
      />
    </Flex>
  );
};
