import { FC, memo } from 'react';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import {
  parseCardText,
  isImage,
  Flex,
  callIfExists,
  Tooltip,
  getIdealContrastForBackground,
  handleCardImageError,
  Icon,
  Spacer,
  Badge,
  shallowEqualsExceptFor,
  hashCard,
  Box,
  Image,
  Text,
  ColorBadge,
} from '@spoke/common';
import { Card } from '@spoke/graphql';

type DiscussBoardListCardProps = {
  card: Card;
  isSelected: boolean;
  onSelect: ((cardId: string) => void) | null;
};
export const DiscussBoardListCard: FC<DiscussBoardListCardProps> = ({
  card,
  isSelected,
  onSelect,
}) => {
  const { originalListBadge, text, children, votes, id: cardId } = card;
  const { color: badgeColor, text: badgeText } = originalListBadge || {};
  const childrenCount = children?.length || 0;
  const voteCount = votes?.length || 0;

  const imageUrl = parseCardText(text).find(isImage);

  return (
    <Flex
      w="full"
      alignItems="center"
      layerStyle="outlineGray"
      py="9px"
      px={2}
      mb={2}
      bg="white"
      borderColor={isSelected ? 'primary.500' : 'gray.200'}
      cursor={onSelect ? 'pointer' : 'default'}
      _hover={onSelect ? { bg: 'primary.25' } : undefined}
      onClick={() => callIfExists(onSelect, cardId)}
    >
      <Box mr={2} alignSelf="flex-start">
        <Tooltip
          placement="right"
          bg={badgeColor || ''}
          color={getIdealContrastForBackground(badgeColor || '')}
          label={badgeText}
          hasArrow
          arrowSize={6}
          shouldWrapChildren
        >
          <ColorBadge color={badgeColor} />
        </Tooltip>
      </Box>

      {imageUrl && (
        <Image
          maxW={200}
          mt={1}
          src={imageUrl}
          alt=""
          onError={handleCardImageError}
        />
      )}
      {!imageUrl && (
        <Text
          overflowWrap="break-word"
          w={`calc(100% - ${childrenCount ? '70px' : '40px'})`}
          textAlign="left"
          fontSize={14}
          lineHeight={1}
          color="gray.600"
        >
          {text}
        </Text>
      )}
      {Boolean(childrenCount) && (
        <Tooltip
          variant="white"
          label={`${childrenCount} card${
            childrenCount !== 1 ? 's' : ''
          } grouped`}
          placement="top"
          fontSize={13}
        >
          <Flex ml={2} alignItems="center" alignSelf="flex-start">
            <Icon as={MdOutlineStickyNote2} color="gray.500" />
            <Text color="gray.500">{childrenCount}</Text>
          </Flex>
        </Tooltip>
      )}
      <Spacer />
      <Badge
        alignSelf="flex-start"
        colorScheme="gray"
        fontSize={14}
        bg={isSelected ? 'primary.50' : 'gray.100'}
        color={isSelected ? 'primary.500' : 'gray.600'}
        fontWeight={500}
      >
        {voteCount}
      </Badge>
    </Flex>
  );
};

export const MemoizedDiscussBoardListCard = memo(
  DiscussBoardListCard,
  (prev, next) => {
    if (!shallowEqualsExceptFor(prev, next, ['card'])) return false;

    const cardHasChanged = hashCard(prev.card) !== hashCard(next.card);
    if (cardHasChanged) return false;
    return true;
  }
);
