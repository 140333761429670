import { useCurrentTeamBitbucketRepositories } from './useCurrentTeamBitbucketRepositories';
import { useCurrentTeamGithubRepositories } from './useCurrentTeamGithubRepositories';
import { useInstalledOrgIntegrations } from './useInstalledOrgIntegrations';
import {
  useTeamMetricsFeatureState,
  TeamMetricsState,
} from './useTeamMetricsFeatureState';

export const useTeamHasSourceControlMetricsInstalled = (): {
  loading: boolean;
  setUpOnOrg: boolean;
  setUpOnTeam: boolean;
  has: boolean;
} => {
  const metricsState = useTeamMetricsFeatureState();
  const allowGithub = !metricsState.githubMetrics.hidden;
  const allowBitbucket = !metricsState.bitbucketMetrics.hidden;

  const {
    github: githubSetUpOnOrg,
    bitbucket: bitbucketSetUpOnOrg,
    loading: orgIntegrationsLoading,
  } = useInstalledOrgIntegrations();

  const setUpOnOrg =
    (githubSetUpOnOrg && allowGithub) ||
    (bitbucketSetUpOnOrg && allowBitbucket);

  const [
    linkedGithubRepos,
    { loading: linkedGithubReposLoading, called: linkedGithubReposCalled },
  ] = useCurrentTeamGithubRepositories();

  const [
    linkedBitbucketRepos,
    {
      loading: linkedBitbucketReposLoading,
      called: linkedBitbucketReposCalled,
    },
  ] = useCurrentTeamBitbucketRepositories();

  const linkedReposLoading =
    linkedGithubReposLoading || linkedBitbucketReposLoading;

  const linkedReposCalled =
    linkedGithubReposCalled || linkedBitbucketReposCalled;

  const loading =
    metricsState.loading ||
    linkedReposLoading ||
    orgIntegrationsLoading ||
    (setUpOnOrg && !linkedReposCalled);

  const setUpOnTeam = Boolean(
    setUpOnOrg &&
      ((linkedGithubRepos?.length && allowGithub) ||
        (linkedBitbucketRepos?.length && allowBitbucket))
  );

  const has =
    setUpOnOrg && setUpOnTeam && !loading && (allowGithub || allowBitbucket);

  return {
    loading,
    setUpOnOrg,
    setUpOnTeam: metricsState.vcs.state === TeamMetricsState.Ready,
    has,
  };
};
