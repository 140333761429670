import { FC } from 'react';
import { useAnnouncementsModalState, useTeamType } from '../queries';
import { TeamFeatureSetModal } from './TeamFeatureSetModal';
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  HStack,
  Heading,
  ModalCloseButton,
  ModalBody,
  UnorderedList,
  ListItem,
  Button,
  Spacer,
  Link,
  Image,
  Text,
  useRouter,
  useAssets,
  useRoutes,
} from '@spoke/common';

export const AnnouncementsModal: FC = () => {
  const { seen, setSeen } = useAnnouncementsModalState();
  const { isTeam } = useTeamType();
  const router = useRouter();
  const routes = useRoutes();
  const featureSetModal = useDisclosure();
  const { imageAnnouncementModalBanner } = useAssets();

  const onAwesome = async () => {
    await setSeen();
    if (isTeam) router.push(routes.Metrics);
    else router.push(routes.Integrations);
  };

  return (
    <Modal
      isOpen={seen === false}
      closeOnOverlayClick={false}
      onClose={setSeen}
    >
      <ModalOverlay />
      <ModalContent p={6} px={10} maxW={700}>
        <ModalHeader p={0}>
          <HStack>
            <Heading fontSize={20} fontWeight={700} color="gray.900">
              🎉 ScatterSpoke Major Update: Metrics and Insights{' '}
            </Heading>
          </HStack>
        </ModalHeader>
        <ModalCloseButton mt={2} />
        <ModalBody p={0} mt={4} fontSize={16} d="flex" flexDir="column" gap={4}>
          <Image
            src={imageAnnouncementModalBanner}
            maxH={230}
            mx={5}
            alt="Announcement banner"
          />
          <Text>
            Introducing the new{' '}
            <Text as="span" fontWeight={500}>
              ScatterSpoke Metrics & Insights
            </Text>
            ! This considerable improvement is designed to help teams
            consistently deliver value on time and with greater efficiency.
          </Text>
          <Text>
            Here&apos;s a brief rundown of the benefits you&apos;ll enjoy after
            connecting your tools:
          </Text>
          <UnorderedList>
            <ListItem>
              <Text as="span" fontWeight={500}>
                Establish a baseline
              </Text>{' '}
              for your team&apos;s most critical Jira and Git metrics to gain
              and understanding of your current performance.
            </ListItem>
            <ListItem>
              <Text as="span" fontWeight={500}>
                Monitor productivity metrics
              </Text>{' '}
              to ensure ongoing work stays on track, while also reviewing
              historical performance trends for future planning.
            </ListItem>
            <ListItem>
              <Text as="span" fontWeight={500}>
                Receive actionable AI-driven insights{' '}
              </Text>{' '}
              derived from both productivity metrics or retrospective data,
              empowering you to make data-driven decisions for continuous
              improvement.
            </ListItem>
          </UnorderedList>
          <Text>
            We expect this update to enable your team to{' '}
            <Text as="span" fontWeight={500}>
              obtain actionable insights
            </Text>{' '}
            by cross referencing engineering metrics with retrospective data,
            and{' '}
            <Text as="span" fontWeight={500}>
              measure your progress
            </Text>{' '}
            on tackling identified issues.
          </Text>
          <Text>
            It&apos;s time to unlock your team&apos;s potential - streamline
            workflows, boost efficiency, and supercharge your performance!
          </Text>
          <Text fontSize={10} mb="-8px">
            If you&apos;re not interested in setting this up for your team, or
            some of your teams don&apos;t use our currently supported tools, you
            can hide these features from view by clicking{' '}
            <Button
              mx={0}
              p={0}
              w="fit-content"
              d="inline"
              minW={0}
              fontWeight="inherit"
              variant="link"
              fontSize="inherit"
              textDecor="underline"
              onClick={featureSetModal.open}
            >
              here
            </Button>{' '}
            or within your Team Settings.
          </Text>
          <HStack>
            <Text fontSize={14}>
              Questions, suggestions or just want to say hi?{' '}
              <Link
                textDecor="underline"
                href="mailto:support@scatterspoke.com"
              >
                talk to us
              </Link>{' '}
            </Text>
            <Spacer />
            <Button onClick={onAwesome}>Awesome!</Button>
          </HStack>
        </ModalBody>
      </ModalContent>
      <TeamFeatureSetModal
        isOpen={featureSetModal.isOpen}
        onClose={featureSetModal.close}
      />
    </Modal>
  );
};
