import { useCurrentUser } from '@spoke/common';

type QueryData = NonNullable<
  NonNullable<ReturnType<typeof useCurrentUser>[0]>['team']
>['members'];

/**
 * This is just to isolate logic of fetching team members, for when we
 * want to split this out into its own graphql query
 */
export const useCurrentTeamMembers = (): [QueryData | null] => {
  const [currentUser] = useCurrentUser();
  const currentTeamMembers = currentUser?.team?.members ?? null;
  return [currentTeamMembers];
};
