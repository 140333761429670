import { ChakraProps } from '@chakra-ui/react';
import { AnimatePresence } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { HiOutlineClipboardCheck } from 'react-icons/hi';
import { MdOutlineChat } from 'react-icons/md';
import { useCurrentBoardTeam } from '../queries';
import { useCurrentBoardRules } from '../queries/useCurrentBoardRules';
import { BoardTimer } from './BoardTimer/BoardTimer';
import { BoardStage } from '@spoke/graphql';
import {
  BOARD_STAGES_ORDER,
  useCurrentBoard,
  Flex,
  MotionFlex,
  Divider,
  Tooltip,
  Button,
  Icon,
  Popover,
  PopoverAnchor,
  BOARD_STAGE_DISPLAY_NAMES,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Text,
} from '@spoke/common';

const STAGE_BUTTONS: BoardStage[] = BOARD_STAGES_ORDER.filter(
  (stage) => stage !== BoardStage.Finished
);

const PARTICIPANT_STAGE_TOOLTIP_HINT_TEXT: Record<BoardStage, string> = {
  [BoardStage.Reflect]:
    "Provide feedback by adding cards to each column.\nThe facilitator will determine when to reveal everyone's feedback.\n",
  [BoardStage.Group]:
    'Group similar items together and give them a name.\nThe facilitator will determine when to allow voting.\n',
  [BoardStage.Vote]:
    "Vote on the most important issues you'd like to discuss.\nThe facilitator can see when all votes are in.\n",
  [BoardStage.Discuss]:
    'Discuss each item and decide what actions may be required.\nThe facilitator can capture these as action items.\n',
  [BoardStage.FactsStats]: '',
  [BoardStage.Finished]: '',
};

const PURPLE_HIGHLIGT: ChakraProps = {
  borderColor: 'primary.600',
  color: 'primary.600',
  borderWidth: '2px',
};

const GREEN_HIGHLIGHT: ChakraProps = {
  borderColor: 'green.700',
  color: 'green.700',
  borderWidth: '2px',
};

export const ParticipantBoardControls: FC = () => {
  const [board] = useCurrentBoard();
  const [boardTeam] = useCurrentBoardTeam();

  const { currentStage, isGuided, privateCards, privateVotes } =
    useCurrentBoardRules();

  const is = (stage: BoardStage) => currentStage === stage;
  const isPast = (stage: BoardStage) =>
    currentStage &&
    BOARD_STAGES_ORDER.indexOf(currentStage) >
      BOARD_STAGES_ORDER.indexOf(stage);

  const getStyles = (
    to: BoardStage | 'privateCards' | 'privateVotes'
  ): ChakraProps => {
    if (to === 'privateCards') return privateCards ? PURPLE_HIGHLIGT : {};
    if (to === 'privateVotes') return privateVotes ? PURPLE_HIGHLIGT : {};
    if (isPast(to)) return GREEN_HIGHLIGHT;
    if (is(to)) return PURPLE_HIGHLIGT;
    return {};
  };

  const [showTooltipHint, setShowTooltipHint] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (boardTeam?.settings?.tooltipHintsEnabled) setShowTooltipHint(true);
    }, 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, [boardTeam?.settings?.tooltipHintsEnabled]);

  const timerStartMs = board?.state?.timerStartTimeMiliseconds;
  const timerStopMs = board?.state?.timerStopTimeMiliseconds;

  return (
    <Flex
      bg="white"
      boxShadow="md"
      borderRadius="50px"
      px={3}
      py={2}
      w="fit-content"
      position="fixed"
      bottom={7}
      gap={2}
      alignItems="center"
      left="calc(100vw / 2)"
      transform="translateX(-50%)"
      transition="left 0.2s ease-out"
      minH="64px"
    >
      <AnimatePresence>
        {Boolean(timerStartMs && timerStopMs) && (
          <MotionFlex
            initial={{ maxWidth: '0px' }}
            animate={{ maxWidth: '200px' }}
            exit={{ maxWidth: '0px' }}
            overflow="hidden"
          >
            <BoardTimer
              startTimeMs={timerStartMs || null}
              stopTimeMs={timerStopMs || null}
              readOnly
            />
            <Divider orientation="vertical" />
          </MotionFlex>
        )}
      </AnimatePresence>
      {!isGuided && (
        <>
          <Tooltip
            variant="white"
            textAlign="center"
            whiteSpace="pre-wrap"
            label={
              privateCards
                ? 'Private Feedback is enabled.\n Only you can see your cards.'
                : 'Private Feedback is disabled.\n Everyone can see your cards.'
            }
          >
            <Button variant="outlineRoundedIcon" {...getStyles('privateCards')}>
              <Icon as={MdOutlineChat} w={5} h={5} />
            </Button>
          </Tooltip>
          <Tooltip
            variant="white"
            textAlign="center"
            whiteSpace="pre-wrap"
            label={
              privateVotes
                ? 'Private Voting is enabled.\n Only you can see your votes.'
                : 'Private Voting is disabled.\n Everyone can see your votes.'
            }
          >
            <Button variant="outlineRoundedIcon" {...getStyles('privateVotes')}>
              <Icon as={HiOutlineClipboardCheck} w="22px" h={8} pb="1px" />
            </Button>
          </Tooltip>
        </>
      )}
      {isGuided &&
        STAGE_BUTTONS.map((stage, idx) => (
          <Popover
            key={stage}
            isOpen={showTooltipHint && is(stage)}
            onClose={() => setShowTooltipHint(false)}
            autoFocus={false}
            closeOnBlur={false}
            placement="top"
            offset={[0, 10]}
          >
            <PopoverAnchor>
              <Button
                variant="outlineRounded"
                cursor="default"
                _focus={{ boxShadow: 'none' }}
                {...getStyles(stage)}
              >
                {is(stage) && `${idx + 1}.`}{' '}
                {isPast(stage) && (
                  <Icon as={BsCheckCircle} w={5} h={5} mr={2} />
                )}
                {BOARD_STAGE_DISPLAY_NAMES[stage]}
              </Button>
            </PopoverAnchor>

            <PopoverContent
              bg="primary.600"
              border="none"
              boxShadow="spread"
              py={2}
              pl={4}
              pr={6}
            >
              <PopoverArrow bg="primary.600" />
              <PopoverCloseButton color="white" />
              <Text color="white" fontSize={14}>
                {PARTICIPANT_STAGE_TOOLTIP_HINT_TEXT[stage]}
              </Text>
            </PopoverContent>
          </Popover>
        ))}
    </Flex>
  );
};
