import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { FC, useState, useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useResetPasswordMutation } from '@spoke/graphql';
import {
  AT_LEAST_ONE_DIGIT_AND_ONE_UPPERCASE,
  useToast,
  TOAST_ERROR_GENERIC,
  SpkHead,
  Card,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  log,
  Input,
  Image,
  Text,
  useAssets,
} from '@spoke/common';

export type CreateNewPasswordFormSchema = {
  newPassword: string;
  newPasswordConfirmation: string;
};

const createNewPasswordSchema = yup
  .object({
    newPassword: yup
      .string()
      .required('This field is required')
      .matches(
        AT_LEAST_ONE_DIGIT_AND_ONE_UPPERCASE,
        'The password must contain at least one digit and one uppercase letter'
      )
      .min(8, 'Your password needs to be min. 8 characters long'),
    newPasswordConfirmation: yup
      .string()
      .required('This field is required')
      .oneOf([yup.ref('newPassword'), null], 'The passwords do not match'),
  })
  .required();

type AuthCreateNewPasswordProps = {
  onSuccess: () => void;
  code: string;
};
export const AuthCreateNewPassword: FC<AuthCreateNewPasswordProps> = ({
  onSuccess,
  code,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors, isSubmitting, isValid },
  } = useForm<CreateNewPasswordFormSchema>({
    resolver: yupResolver(createNewPasswordSchema),
  });

  const [resetPassword] = useResetPasswordMutation();

  const [toast] = useToast();

  const [error] = useState('');

  const { illustrationHello } = useAssets();

  const handleResetPassword = useCallback(
    async (formData: CreateNewPasswordFormSchema) => {
      log.info('Submitting create new password form', formData);
      const { newPassword } = formData;

      const { errors } = await resetPassword({
        variables: { newPassword, resetToken: code },
      });

      if (errors) {
        log.error('resetPassword mutation responded with errors', { errors });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      toast({
        title: 'Success',
        description: 'Your password has been successfully reset.',
      });

      onSuccess();
    },
    [code, onSuccess, resetPassword, toast]
  );

  return (
    <motion.div
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{ duration: 0.15, ease: 'easeOut' }}
    >
      <SpkHead title="Reset Password" />
      <Card w={460} p={10}>
        <Image src={illustrationHello} alt="Hello from ScatterSpoke" />
        <VStack>
          <Heading size="md" fontWeight={500} color="gray.700">
            Create a new password
          </Heading>
          <Text fontSize="sm" color="gray.500" textAlign="center" px={5}>
            Your password needs to be min. 8 characters long and include at
            least one number and a capital letter
          </Text>
        </VStack>
        <VStack
          spacing={4}
          w="full"
          as="form"
          onSubmit={handleSubmit(handleResetPassword)}
        >
          <FormControl isInvalid={!isValid}>
            <VStack spacing={4}>
              <FormControl isInvalid={Boolean(formErrors.newPassword)}>
                <FormLabel htmlFor="newPassword">Password</FormLabel>
                <Input
                  id="newPassword"
                  type="password"
                  {...register('newPassword')}
                />
                <FormErrorMessage>
                  {formErrors?.newPassword?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={Boolean(formErrors.newPasswordConfirmation)}
              >
                <FormLabel htmlFor="newPasswordConfirmation">
                  Confirm password
                </FormLabel>
                <Input
                  id="newPasswordConfirmation"
                  type="password"
                  {...register('newPasswordConfirmation')}
                />
                <FormErrorMessage>
                  {formErrors?.newPasswordConfirmation?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </FormControl>
          {error && (
            <FormControl isInvalid={Boolean(error)} m="0">
              <FormErrorMessage textAlign="center">{error}</FormErrorMessage>
            </FormControl>
          )}
          <Button w="full" isLoading={isSubmitting} type="submit">
            Continue
          </Button>
        </VStack>
      </Card>
    </motion.div>
  );
};
