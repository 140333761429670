import {
  ComponentStyleConfig,
  Textarea as ChakraTextarea,
  TextareaProps as ChakraTextareaProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export const TextareaComponentStyle: ComponentStyleConfig = {
  sizes: {
    md: {
      field: {
        fontSize: 'md',
        // height: 9,
        px: 2,
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'primary.500',
  },
};

export type TextAreaProps = ChakraTextareaProps;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => <ChakraTextarea {...props} ref={ref} />
);
