import { FC, memo } from 'react';
import { FiInfo } from 'react-icons/fi';
import { MemoizedFactsStatsImprovementGoalDistributionBars } from './FactsStatsImprovementGoalDistributionBars';
import { ImprovementGoalDistribution } from '@spoke/graphql';
import {
  HStack,
  Card,
  Tooltip,
  Icon,
  truncateDecimals,
  Text,
} from '@spoke/common';

type FactsStatsActionItemsReviewAnalyticsProps = {
  averageTimeToResolve: number | null;
  improvementGoalDistributions: ImprovementGoalDistribution[] | null;
};
export const FactsStatsActionItemsReviewAnalytics: FC<
  FactsStatsActionItemsReviewAnalyticsProps
> = ({ averageTimeToResolve, improvementGoalDistributions }) => (
  <HStack align="stretch">
    <Card
      p={4}
      alignItems="start"
      justifyContent="space-between"
      boxShadow="light"
      flex={0.4}
    >
      <HStack justify="space-between" w="full">
        <Text>Average time to resolve action items</Text>
        <Tooltip
          placement="top"
          variant="white"
          shouldWrapChildren
          label="This measures how fast you are getting better. We recommend to keep resolving action items before your next retrospective to be continuously improving."
        >
          <Icon as={FiInfo} color="gray.500" />
        </Tooltip>
      </HStack>
      {!averageTimeToResolve && (
        <Text fontSize={14} color="gray.500">
          Not enough data yet
        </Text>
      )}
      {Boolean(averageTimeToResolve) && (
        <Text fontSize={22} fontWeight={500} mb={1} color="gray.700">
          {truncateDecimals(averageTimeToResolve || 0, 1)} days
        </Text>
      )}
    </Card>
    <Card
      boxShadow="light"
      p={4}
      alignItems="start"
      justifyContent="space-between"
      flex={1}
    >
      <HStack justify="space-between" w="full">
        <Text>Action item distribution per goal</Text>
        <Tooltip
          variant="white"
          placement="left"
          shouldWrapChildren
          label={`Tagging your actions items will allow you to track progress towards your team's improvement goals.`}
        >
          <Icon as={FiInfo} color="gray.500" />
        </Tooltip>
      </HStack>
      {!improvementGoalDistributions?.length && (
        <Text fontSize={14} color="gray.500">
          No goals created yet. You can create goals in your team dashboard.
        </Text>
      )}
      {Boolean(improvementGoalDistributions?.length) && (
        <MemoizedFactsStatsImprovementGoalDistributionBars
          improvementGoalsDistributions={
            improvementGoalDistributions as ImprovementGoalDistribution[]
          }
        />
      )}
    </Card>
  </HStack>
);

export const MemoizedFactsStatsActionItemsReviewAnalytics = memo(
  FactsStatsActionItemsReviewAnalytics
);
