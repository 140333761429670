import { useApolloClient } from '@apollo/client';
import {
  useToast,
  useCurrentBoardId,
  TOAST_ERROR_GENERIC,
  DeepPartial,
  optimisticUpdateBoard,
  log,
} from '@spoke/common';
import { useUpdateBoardMutation, Board } from '@spoke/graphql';

export const useBoardPasswordChangeHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [boardId] = useCurrentBoardId();
  const [updateBoard] = useUpdateBoardMutation();

  const handleChangeBoardPassword = async (newPassword: string | null) => {
    if (!boardId) {
      log.error('Cannot change board password without loaded boardId', {
        boardId,
        newPassword,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const updateBoardFields: DeepPartial<Board> = {
      state: { password: newPassword },
    };

    const expected = optimisticUpdateBoard({
      cache,
      boardId,
      fields: updateBoardFields,
    });

    const { data, errors } = await updateBoard({
      variables: { boardId, fields: updateBoardFields },
      optimisticResponse: {
        __typename: 'Mutation',
        updateBoard: expected,
      },
    });

    if (errors) {
      log.error('updateBoard (changing board password) responded with errors', {
        data,
        errors,
        newPassword,
        boardId,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }
  };

  return [handleChangeBoardPassword];
};
