import { UserAvatarMenu } from './UserAvatarMenu';
import {
  Flex,
  NAV_BAR_HEIGHT,
  SpkLink,
  Spacer,
  useCurrentUser,
  Image,
  Link,
  useAssets,
  useRoutes,
  SpkRouteKey,
} from '@spoke/common';

export const SettingsNav = () => {
  const [currentUser] = useCurrentUser();
  const { logoFull } = useAssets();
  const routes = useRoutes();

  return (
    <Flex
      alignItems="center"
      w="full"
      h={NAV_BAR_HEIGHT}
      px={6}
      bg="white"
      borderBottomWidth={1}
      borderColor="gray.100"
    >
      <SpkLink href={routes[SpkRouteKey.Root]} passHref>
        <Link>
          <Image src={logoFull} alt="ScatterSpoke Logo" w={192} />
        </Link>
      </SpkLink>
      <Spacer />
      <UserAvatarMenu
        email={currentUser?.email || ''}
        name={currentUser?.name || ''}
      />
    </Flex>
  );
};
