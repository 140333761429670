import { FC } from 'react';
import {
  useTeamMetricsFeatureState,
  useInstalledOrgIntegrations,
} from '../queries';
import {
  IconProps,
  Icon,
  BitbucketIcon,
  GithubIcon,
  JiraIcon,
} from '@spoke/common';
import { MetricsProviderCategory } from '@spoke/graphql';

type Props = IconProps & {
  providerCategory?: MetricsProviderCategory | null;
  show: 'installed-on-org' | 'visible-to-org';
};
export const MetricProviderIcons: FC<Props> = ({
  providerCategory,
  show = 'installed-on-org',
  ...rest
}) => {
  const featureState = useTeamMetricsFeatureState();
  const featureStateLoading = featureState.loading;
  const allowGithub = !featureState.githubMetrics.hidden;
  const allowBitbucket = !featureState.bitbucketMetrics.hidden;
  const allowJira = !featureState.jiraMetrics.hidden;

  const {
    github: orgHasGithub,
    bitbucket: orgHasBitbucket,
    jira: orgHasJira,
    loading: orgIntegrationsLoading,
  } = useInstalledOrgIntegrations();

  const loading = featureStateLoading || orgIntegrationsLoading;
  if (!providerCategory) return null;

  const isSourceControl =
    providerCategory === MetricsProviderCategory.SourceControl;
  const isProjectManagement =
    providerCategory === MetricsProviderCategory.ProjectManagement;

  const showOnlyInstalled = show === 'installed-on-org';

  const SHOW_TABLE: Record<'bitbucket' | 'github' | 'jira', boolean> = {
    bitbucket:
      isSourceControl &&
      allowBitbucket &&
      (orgHasBitbucket || !showOnlyInstalled),
    github:
      isSourceControl && allowGithub && (orgHasGithub || !showOnlyInstalled),
    jira:
      isProjectManagement && allowJira && (orgHasJira || !showOnlyInstalled),
  };

  if (loading) return null;

  return (
    <>
      {SHOW_TABLE.bitbucket && <Icon mx="2px" as={BitbucketIcon} {...rest} />}
      {SHOW_TABLE.github && <Icon mx="2px" as={GithubIcon} {...rest} />}
      {SHOW_TABLE.jira && <Icon mx="2px" as={JiraIcon} {...rest} />}
    </>
  );
};
