import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import { useArchiveGoalDataMutation } from '@spoke/graphql';

export const useArchiveImprovementGoalDataHandler = () => {
  const [archiveGoalData] = useArchiveGoalDataMutation();
  const [toast] = useToast();

  const handleArchiveGoalData = useCallback(
    async (data: { goalDataId: string }): Promise<boolean> => {
      const { goalDataId } = data;

      if (!goalDataId) {
        log.error('Missing critical data to archive improvement goal data', {
          goalDataId,
        });
        return false;
      }

      log.info('Archiving improvement goal data', { data });

      const { errors } = await archiveGoalData({
        variables: { goalDataId },
      });

      if (errors) {
        log.error('archiveImprovementGoalData call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [archiveGoalData, toast]
  );

  return [handleArchiveGoalData];
};
