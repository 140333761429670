import { TableProps } from '@chakra-ui/react';
import { ReactElement, useState } from 'react';
import { Box } from '../Box';
import { Flex } from '../Flex';
import { PaginationControls } from '../PaginationControls';
import { Skeleton } from '../Skeleton';
import { SpokeTableCell } from './SpokeTableCell';
import { SpokeTableHeader } from './SpokeTableHeader';
import { Table } from './Table';
import { Tbody } from './Tbody';
import { Td, TdProps } from './Td';
import { Th, ThProps } from './Th';
import { Thead } from './Thead';
import { Tr } from './Tr';

const DEFAULT_KEY_EXTRACTOR = (item: any) => item.id;

export type SpkColumn<T = object> = {
  colKey: string;
  headerProps?: ThProps;
  cellContainerProps?: TdProps;
  width?: number | string;
  cell: (item: T) => ReactElement | string | number | null | undefined;
  header:
    | (() => ReactElement | string | number | null | undefined)
    | string
    | number;
};

type SpokeTableProps<T> = Omit<TableProps, 'variant'> & {
  data: T[];
  columns: SpkColumn<T>[];
  headerProps?: ThProps;
  keyExtractor?: (item: T) => string;
  isHighlighted?: (item: T) => boolean;
  spacing?: number | string;
  loading?: boolean;
  loadingCellHeight?: number | string;
  variant?: 'default' | 'no-border';
  /**
   * Op-in client side pagination
   */
  pageSize?: number | null;
};
export const SpokeTable = <T,>({
  columns,
  data,
  headerProps,
  keyExtractor = DEFAULT_KEY_EXTRACTOR,
  loading,
  isHighlighted,
  variant = 'default',
  spacing = '12px',
  loadingCellHeight = '20px',
  pageSize = null,
  ...rest
}: SpokeTableProps<T>) => {
  const noBorder = variant === 'no-border';
  const [page, setPage] = useState(0);
  const amountOfPages = pageSize ? Math.ceil(data.length / pageSize) : 0;
  const offset = pageSize ? page * pageSize : 0;
  const pageData = pageSize ? data.slice(offset, offset + pageSize) : data;

  return (
    <Flex flexDir="column">
      <Box
        borderRadius="lg"
        overflow="hidden"
        borderStyle="solid"
        borderColor={noBorder ? 'transparent' : 'gray.100'}
        borderWidth="2px"
        bg="white"
      >
        <Table {...rest}>
          <Thead border={noBorder ? 'none' : undefined}>
            <Tr>
              {columns.map(
                ({
                  colKey,
                  header,
                  width,
                  headerProps: localHeaderProps = {},
                }) => {
                  const render =
                    typeof header === 'function' ? header() : header;
                  return (
                    <Th
                      key={colKey}
                      w={width}
                      role="group"
                      textTransform="none"
                      fontWeight={400}
                      fontSize={14}
                      {...headerProps}
                      {...localHeaderProps}
                    >
                      {typeof render === 'object' ? (
                        render
                      ) : (
                        <SpokeTableHeader title={`${render}`} />
                      )}
                    </Th>
                  );
                }
              )}
            </Tr>
          </Thead>
          <Tbody>
            {Boolean(pageData.length) &&
              pageData.map((entry, idx, arr) => {
                const isLast = idx === arr.length - 1;
                const highlighted = isHighlighted?.(entry) ?? false;
                return (
                  <Tr
                    borderBottomStyle="solid"
                    borderBottomColor={noBorder ? 'transparent' : 'gray.100'}
                    borderBottomWidth={isLast ? 0 : 1}
                    bg={highlighted ? 'primary.50' : 'white'}
                    role="group"
                    key={keyExtractor(entry)}
                  >
                    {columns.map(
                      ({ colKey, cell, width, cellContainerProps = {} }) => {
                        const render = cell(entry);
                        return (
                          <Td
                            key={colKey}
                            w={width}
                            alignItems="center"
                            {...cellContainerProps}
                          >
                            <Skeleton
                              isLoaded={!loading}
                              height={loading ? loadingCellHeight : undefined}
                              my={1}
                              mr={4}
                            >
                              {typeof render === 'object' ? (
                                render
                              ) : (
                                <SpokeTableCell
                                  pl={noBorder ? 0 : 2}
                                  py={spacing ?? undefined}
                                >
                                  {render}
                                </SpokeTableCell>
                              )}
                            </Skeleton>
                          </Td>
                        );
                      }
                    )}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </Box>
      {Boolean(pageSize && amountOfPages > 1) && (
        <PaginationControls
          mt={4}
          currentPage={page + 1}
          onChangePage={(p) => setPage(p - 1)}
          pageCount={amountOfPages}
        />
      )}
    </Flex>
  );
};
