import { VStack } from '@chakra-ui/react';
import { CustomDndPlaceholderProps } from '@spoke/common';
import { FC } from 'react';
import { Text } from './Text';

export const CustomDndPlaceholder: FC<{
  placeholderProps?: CustomDndPlaceholderProps | null;
}> = ({ placeholderProps }) =>
  !placeholderProps ? null : (
    <VStack
      alignItems="center"
      justifyContent="center"
      bg="gray.200"
      borderColor="gray.300"
      borderWidth={2}
      borderStyle="dashed"
      borderRadius="lg"
      mb={2}
      style={{
        position: 'absolute',
        top: placeholderProps.clientY,
        left: placeholderProps.clientX,
        height: placeholderProps.clientHeight,
        width: placeholderProps.clientWidth,
      }}
    >
      <Text fontSize={14} color="gray.500">
        Move card here
      </Text>
    </VStack>
  );
