import { FC } from 'react';
import { WorkItemHeatmapSquare } from './WorkItemHeatmapSquare';
import { Flex, plural, Box, Text } from '@spoke/common';
import { InFlightWorkItemFragmentFragment } from '@spoke/graphql';

type Props = {
  name: string;
  items: InFlightWorkItemFragmentFragment[];
  truncateOn: number;
};
export const WorkItemHeatmapStatus: FC<Props> = ({
  items,
  name,
  truncateOn,
}) => (
  <Flex flexDir="column" gap={1}>
    <Box>
      <Text color="gray.600" fontSize={14} whiteSpace="nowrap">
        <Text as="span" fontWeight={500}>
          {name}
        </Text>{' '}
        ({items.length} {plural('item', items.length)})
      </Text>
    </Box>
    <Flex flexWrap="wrap" gap={1} maxW={120}>
      {items.slice(0, truncateOn).map((workItem) => (
        <WorkItemHeatmapSquare key={workItem.slug} workItem={workItem} />
      ))}
      {items.length > truncateOn && (
        <Text d="block" mt={1} fontWeight={500} color="gray.500" fontSize={14}>
          +{items.length - truncateOn}{' '}
          {plural('item', items.length - truncateOn)}
        </Text>
      )}
    </Flex>
  </Flex>
);
