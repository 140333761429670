import { useCallback, useEffect, useRef } from 'react';
import { isNullish } from '../../util';

export const useDebounceRef = (
  delayMs: number
): [() => void, () => boolean] => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isDebouncingRef = useRef(false);

  const clearTimeoutIfExists = useCallback(() => {
    if (!isNullish(timeoutRef.current)) clearTimeout(timeoutRef.current!);
  }, []);

  const fire = useCallback(() => {
    const isFirstRun = isNullish(timeoutRef.current);
    clearTimeoutIfExists();
    isDebouncingRef.current = true;
    timeoutRef.current = setTimeout(
      () => (isDebouncingRef.current = false),
      isFirstRun ? 0 : delayMs
    );
  }, [clearTimeoutIfExists, delayMs]);

  useEffect(
    () => clearTimeoutIfExists, // Cleaning up on component unmount
    [clearTimeoutIfExists]
  );

  const shouldFire = () => !isDebouncingRef.current;

  return [fire, shouldFire];
};
