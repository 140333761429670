import { useCallback } from 'react';
import { useUsersByIdsLazyQuery } from '@spoke/graphql';
import { log } from '@spoke/common';

type QueryRef = ReturnType<typeof useUsersByIdsLazyQuery>[1];
type QueryData = NonNullable<QueryRef['data']>['usersByIds'];
type FetchArgs = { userIds: string[] };

export const useFetchUsersByIds = (): [
  (args: FetchArgs) => Promise<QueryData>,
  QueryRef
] => {
  const [findUsersByIds, queryRef] = useUsersByIdsLazyQuery();

  const fetchUsersByIds = useCallback(
    async ({ userIds }: FetchArgs) => {
      const { error, data } = await findUsersByIds({
        variables: { userIds },
      });

      const members = data?.usersByIds;

      if (error || !members) {
        log.error('findUsersByIds query responded with errors', {
          error,
          data,
          userIds,
        });
        return [];
      }

      return members;
    },
    [findUsersByIds]
  );

  return [fetchUsersByIds, queryRef];
};
