import { ChakraProps, PropsOf } from '@chakra-ui/react';
import {
  NO_OP,
  useAsyncDebounce,
  useOnce,
  useOutsideClick,
} from '@spoke/common';
import { ImprovementGoal } from '@spoke/graphql';
import { AnimatePresence } from 'framer-motion';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoSearch } from 'react-icons/io5';
import { Box } from './Box';
import { Circle } from './Circle';
import { Flex, MotionFlex } from './Flex';
import { Icon } from './Icon';
import { Input } from './Input/Input';
import { InputGroup } from './Input/InputGroup';
import { InputRightElement } from './Input/InputRightElement';
import { Spinner } from './Spinner';
import { HStack } from './Stack/HStack';
import { Text } from './Text';
import { Tooltip } from './Tooltip';

type RenderOption<T> = FC<{
  option: T;
  idKey: keyof T;
  label: string;
  onClick: () => void;
  isSelected: boolean;
  limitReached: boolean;
}>;

export const ImprovementGoalOption: RenderOption<ImprovementGoal> = ({
  isSelected,
  onClick,
  option,
  label,
}: PropsWithChildren<{
  option: ImprovementGoal;
  label: string;
  onClick: () => void;
  isSelected: boolean;
}>) => (
  <Flex
    onClick={onClick}
    key={option.id}
    cursor="pointer"
    py={2}
    px={2}
    w="full"
    borderRadius="md"
    justifyContent="space-between"
    _hover={{ bg: 'gray.100' }}
  >
    <HStack>
      <Circle boxShadow="inner" mb="3px" size="9px" bg={option.type.color} />
      <Text fontSize={14} color="gray.600">
        {label}
      </Text>
    </HStack>
    {isSelected && <Icon color="gray.600" mr={2} as={FaCheck} />}
  </Flex>
);

const DefaultOption = <
  T extends { disabled?: boolean; disabledReason?: string }
>({
  onClick,
  option,
  idKey,
  label,
  isSelected,
  limitReached,
}: PropsOf<RenderOption<T>>) => {
  const { disabled: _disabled, disabledReason } = option;

  const disabled = _disabled || (limitReached && !isSelected);

  const render = (
    <Flex
      onClick={disabled ? NO_OP : onClick}
      key={option[idKey] as unknown as string}
      cursor={disabled ? 'default' : 'pointer'}
      py={2}
      px={2}
      w="full"
      borderRadius="md"
      justifyContent="space-between"
      bg={disabled ? 'gray.50' : 'white'}
      _hover={disabled ? {} : { bg: 'gray.100' }}
    >
      <Text fontSize={14} color={disabled ? 'gray.400' : 'gray.600'}>
        {label}
      </Text>
      {isSelected && <Icon color="gray.600" mr={2} as={FaCheck} />}
    </Flex>
  );

  return disabledReason && disabled ? (
    <Tooltip
      variant="white"
      placement="auto"
      shouldWrapChildren
      openDelay={200}
      hasArrow
      label={disabledReason}
    >
      {render}
    </Tooltip>
  ) : (
    render
  );
};

type MultiSelectProps<OptionType> = Omit<ChakraProps, 'onClick'> & {
  values: OptionType[];
  limit?: number;
  searchable?: boolean;
  isOpen?: boolean;
  placeholder?: string;
  renderOption?: RenderOption<OptionType>;
  idKey: keyof OptionType;
  labelKeyOrFn: keyof OptionType | ((option: OptionType) => string);
  onClose?: () => void;
  onChange: (newValues: OptionType[]) => void;
  /** Whether to refetch automatically whenever fetching function gets redefined (useful for when options depend on external state) */
  autoRefetch?: boolean;
  /** @note if using autoRefetch, remember to useCallback on this, otherwise component goes into an infinite fetching loop */
  getOptions?: (text: string) => OptionType[] | Promise<OptionType[]>;
};
export const MultiSelect = <OptionType,>({
  children,
  values,
  onChange,
  searchable,
  isOpen,
  onClose,
  placeholder,
  idKey,
  labelKeyOrFn,
  limit = 0,
  autoRefetch = false,
  getOptions = async () => [],
  renderOption = DefaultOption as RenderOption<OptionType>,
  ...props
}: PropsWithChildren<MultiSelectProps<OptionType>>) => {
  const selectPopoverRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [debouncedGetOptions, options, optionsLoading] = useAsyncDebounce(
    getOptions,
    300
  );

  const [term, setTerm] = useState('');

  useOutsideClick(onClose ?? NO_OP, selectPopoverRef, containerRef);

  const openedOnce = useOnce(isOpen);
  const searchedOnce = useRef(false);

  useEffect(() => {
    if (!openedOnce || searchedOnce.current) return;
    setTerm('');
    debouncedGetOptions('');
    searchedOnce.current = true;
  }, [isOpen, debouncedGetOptions, openedOnce]);

  const handleTermChange = (newTerm: string) => {
    setTerm(newTerm);
  };

  // This has its own effect so that when getOptions changes (external state dependency on the fetching function,
  // i.e. options depend on extenal state), this will refresh the options. But that will only
  // happen if using the autoRefetch prop, otherwise it only refetches when the term changes.
  // This prop exists so that we don't need to useCallback on getOptions for simple use cases
  useEffect(() => {
    debouncedGetOptions(term);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term, ...(autoRefetch ? [debouncedGetOptions] : [])]);

  const isSelected = (option: OptionType) =>
    values.findIndex((selected) => selected[idKey] === option[idKey]) !== -1;

  const toggleValue = (toToggle: OptionType) => {
    const newValues = Array.from(values);
    const existingIdx = newValues.findIndex(
      (selected) => selected[idKey] === toToggle[idKey]
    );
    const alreadyExists = existingIdx !== -1;
    if (alreadyExists) newValues.splice(existingIdx, 1);
    else newValues.push(toToggle);
    onChange(newValues);
  };

  const getLabel = (option: OptionType): string =>
    typeof labelKeyOrFn === 'function'
      ? labelKeyOrFn(option)
      : (option[labelKeyOrFn as keyof OptionType] as unknown as string);

  // TODO make this change placement if doesn't fit screen
  // TODO add keyboard navigation and WAI-ARIA compliance

  return (
    <Box {...props} ref={containerRef} position="relative">
      {children}
      <AnimatePresence>
        {isOpen && (
          <MotionFlex
            initial={{
              opacity: 0,
              transform: 'scale(0.98)',
              transformOrigin: 'top',
            }}
            exit={{
              opacity: 0,
              transform: 'scale(0.98)',
              transformOrigin: 'top',
            }}
            animate={{
              opacity: 1,
              transform: 'scale(1)',
              transformOrigin: 'top',
            }}
            transition={{ duration: 0.15, ease: 'easeOut' }}
            bg="white"
            position="absolute"
            top="105%"
            right={0}
            zIndex={3}
            w="fit-content"
            p={2}
            px={3}
            minW={containerRef.current?.clientWidth ?? 240}
            maxH={250}
            gap={2}
            flexDir="column"
            borderBottomRadius="lg"
            overflow="hidden"
            boxShadow="lg"
            ref={selectPopoverRef}
          >
            {searchable && (
              <InputGroup>
                <Input
                  value={term}
                  onChange={(e) => handleTermChange(e.target.value)}
                  placeholder={placeholder ?? 'Search'}
                  px={1}
                  pt="2px"
                  fontSize="15px"
                  variant="unstyled"
                  minH="35px"
                  borderBottomColor="gray.500"
                  borderBottomWidth="1px"
                  borderRadius={0}
                  autoFocus
                />
                <InputRightElement>
                  {!optionsLoading && <Icon as={IoSearch} />}
                  {optionsLoading && (
                    <Spinner
                      h="15px"
                      w="15px"
                      speed="0.8s"
                      thickness="2px"
                      mx="auto"
                      color="gray.700"
                      my={2}
                    />
                  )}
                </InputRightElement>
              </InputGroup>
            )}
            <Flex overflowY="auto" maxH={300} w="full" flexDir="column">
              {!searchable && optionsLoading && (
                <Spinner
                  h="15px"
                  w="15px"
                  speed="0.8s"
                  thickness="2px"
                  mx="auto"
                  my={2}
                />
              )}
              {!optionsLoading &&
                options?.map((option) =>
                  renderOption({
                    option,
                    idKey,
                    label: getLabel(option),
                    onClick: () => toggleValue(option),
                    limitReached: Boolean(limit && values.length >= limit),
                    isSelected: isSelected(option),
                  })
                )}
              {!optionsLoading && options?.length === 0 && (
                <Text mx="auto" my={1} fontSize={12} color="gray.500">
                  No results
                </Text>
              )}
            </Flex>
          </MotionFlex>
        )}
      </AnimatePresence>
    </Box>
  );
};
