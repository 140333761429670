import { useCallback } from 'react';
import {
  useToast,
  TOAST_ERROR_GENERIC,
  log,
  useRouter,
  useRoutes,
} from '@spoke/common';
import { useDeleteOwnAccountMutation } from '@spoke/graphql';

export const useDeleteOwnAccountHandler = () => {
  const [toast] = useToast();
  const [deleteOwnAccount] = useDeleteOwnAccountMutation();
  const router = useRouter();
  const routes = useRoutes();

  const handleDeleteOwnAccount = useCallback(async () => {
    log.info('Deleting own account');

    const { errors } = await deleteOwnAccount();

    if (errors) {
      log.error('Delete own account call responded with errors', errors);
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    router.push(routes.Logout);

    toast({
      status: 'success',
      title: 'Account deleted',
      description:
        'Your account has been successfully deleted. You can undo this in up to 5 days by contacting support.',
    });
  }, [deleteOwnAccount, router, routes.Logout, toast]);

  return [handleDeleteOwnAccount];
};
