import { FC, useState, useEffect, useCallback } from 'react';
import { IoLinkSharp } from 'react-icons/io5';
import { MdOutlineEmail } from 'react-icons/md';
import {
  useBulkInviteToTeamHandler,
  BulkInviteToTeamResult,
} from '../handlers';
import { useCurrentTeam, useCurrentOrg } from '../queries';
import {
  SpkButtonGroupElement,
  ModalProps,
  useCurrentUser,
  useToast,
  EMAIL_PATTERN,
  INVALID_EMAIL_ADDRESS,
  INVALID_EMAIL_DOMAIN,
  ModalContent,
  Card,
  ModalHeader,
  Heading,
  ModalCloseButton,
  ModalBody,
  Flex,
  SpkButtonGroup,
  FormLabel,
  MultiTypeAhead,
  Icon,
  MultiTypeAheadFields,
  MultiTypeAheadAddField,
  SpokeTextArea,
  HStack,
  Button,
  FormControl,
  Switch,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Modal,
  Box,
  ModalOverlay,
  Input,
  log,
  Link,
  Text,
} from '@spoke/common';

enum TeamInviteMethod {
  Individual = 'INDIVIDUAL',
  Group = 'GROUP',
}

const TEAM_INVITE_METHOD_ELEMENTS: SpkButtonGroupElement[] = [
  { key: TeamInviteMethod.Individual, label: 'Individual invites' },
  { key: TeamInviteMethod.Group, label: 'Group invites' },
];

type InviteTeamMemberModalContentProps = Pick<ModalProps, 'onClose'>;
export const InviteTeamMemberModalContent: FC<
  InviteTeamMemberModalContentProps
> = (props) => {
  const [currentUser] = useCurrentUser();
  const [currentTeam] = useCurrentTeam();
  const [currentOrg] = useCurrentOrg();
  const [bulkInviteToTeam] = useBulkInviteToTeamHandler();

  const [toast] = useToast();

  const [selectedInviteMethod, setSelectedInviteMethod] =
    useState<TeamInviteMethod>(TeamInviteMethod.Individual);

  const [emailsToInvite, setEmailsToInvite] = useState<string[]>(['']);

  const [isInviting, setIsInviting] = useState(false);

  const [inviteLink, setInviteLink] = useState<string>('');
  const [enableInvite, setEnableInvite] = useState<boolean>(true);
  const [orgFull, setOrgFull] = useState(false);

  useEffect(() => {
    const origin = window.location.origin;
    const pathname = `/invitation/${currentTeam?.inviteToken}`;
    const url = new URL(pathname, origin);
    if (currentUser?.id) url.searchParams.append('inviter', currentUser?.id);
    setInviteLink(url.toString());
  }, [currentTeam?.inviteToken, currentUser?.id]);

  const handleTabChange = (key: string) => {
    setSelectedInviteMethod(key as TeamInviteMethod);
  };

  const toggleEnableInvite = () => {
    setEnableInvite(!enableInvite);
  };

  const validateInviteEmail = useCallback(
    (text: string) => {
      const isValid = text.match(EMAIL_PATTERN);
      if (!isValid) return INVALID_EMAIL_ADDRESS;
      const orgHasDomainAccess = Boolean(currentOrg?.domains?.length);
      const emailIsInDomain = currentOrg?.domains
        ?.map((d) => d.toLowerCase())
        ?.includes(text.split('@')[1]?.toLowerCase());
      if (orgHasDomainAccess && !emailIsInDomain && currentOrg?.hasSSO) {
        return INVALID_EMAIL_DOMAIN;
      }
      return null;
    },
    [currentOrg?.domains, currentOrg?.hasSSO]
  );

  const handleCopyBoardLink = async () => {
    await navigator.clipboard.writeText(inviteLink);

    toast({
      status: 'info',
      title: 'Link copied',
      description: 'The invitation link has been copied to your clipboard.',
    });
  };

  const handleUsersInvitation = useCallback(async () => {
    setIsInviting(true);
    log.info('Inviting users to team', {
      teamId: currentTeam?.id,
      emailsToInvite,
    });

    const validEmailInvites = emailsToInvite.filter(
      (email) => !validateInviteEmail(email)
    );

    if (currentTeam?.id && validEmailInvites.length) {
      const result = await bulkInviteToTeam(
        currentTeam?.id as string,
        validEmailInvites
      );
      if (result === BulkInviteToTeamResult.Success) props.onClose();
      if (result === BulkInviteToTeamResult.OrgFull) {
        setOrgFull(true);
      }
    }

    setIsInviting(false);
  }, [
    bulkInviteToTeam,
    currentTeam?.id,
    emailsToInvite,
    props,
    validateInviteEmail,
  ]);

  return (
    <ModalContent maxW={600}>
      <Card variant="modal" mb={2} pb={7}>
        <ModalHeader p={0}>
          <Box>
            <Heading mb={1} fontSize={24} color="gray.900">
              Invite Team Member
            </Heading>

            <Text fontSize={17} fontStyle="normal" fontWeight="normal">
              An email with the invitation will be sent to the following list
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody p={0}>
          <Flex direction="column" alignItems="start">
            <SpkButtonGroup
              elements={TEAM_INVITE_METHOD_ELEMENTS}
              onSelect={handleTabChange}
              selectedElementKey={selectedInviteMethod}
              size="sm"
              w="50%"
              my={4}
            />

            <FormLabel fontSize={14} htmlFor="teamName">
              Email addresses
            </FormLabel>
            {selectedInviteMethod === TeamInviteMethod.Individual ? (
              <MultiTypeAhead
                values={emailsToInvite}
                setValues={setEmailsToInvite}
                validateField={validateInviteEmail}
                icon={<Icon color="gray.500" w={6} h={6} as={MdOutlineEmail} />}
                placeholder="Enter new member's email"
              >
                <MultiTypeAheadFields w="full" mb={3} />
                <MultiTypeAheadAddField size="md">
                  + Add next person
                </MultiTypeAheadAddField>
              </MultiTypeAhead>
            ) : (
              <SpokeTextArea
                placeholder="Paste newline-separated email addresses"
                textAlign="left"
                borderStyle="solid"
                borderColor="gray.300"
                borderWidth="1px"
                minH={100}
                fontSize={16}
                overflowY="auto"
                value={emailsToInvite.join('\n')}
                onChange={(v) => setEmailsToInvite(v.target.value.split('\n'))}
              />
            )}
            {orgFull && (
              <Text fontSize="xs" color="red.500">
                The organization has hit the limit of seats. Please visit{' '}
                <Link href="/organization/billing">billing</Link> for more info.
              </Text>
            )}
          </Flex>
          <Flex justifyContent="end" mt={4}>
            <HStack>
              <Button
                h="full"
                size="lg"
                w="full"
                isDisabled={isInviting}
                variant="outlineGray"
                onClick={props.onClose}
              >
                Cancel
              </Button>
              <Button
                size="lg"
                minH="full"
                w="full"
                whiteSpace="pre-wrap"
                isLoading={isInviting}
                disabled={emailsToInvite.some(validateInviteEmail)}
                onClick={handleUsersInvitation}
              >
                Invite
              </Button>
            </HStack>
          </Flex>
        </ModalBody>
      </Card>

      <Card variant="modal" py={7}>
        <FormControl display="flex" alignItems="center" mb={1}>
          <Switch
            onChange={toggleEnableInvite}
            isChecked={enableInvite}
            id="switch-invite-link"
            mr={2}
          />
          <FormLabel
            htmlFor="switch-invite-link"
            mb="0"
            fontWeight={500}
            color="gray.700"
            fontSize={15}
            cursor="pointer"
          >
            Enable team invite link
          </FormLabel>
        </FormControl>

        {enableInvite && (
          <>
            <InputGroup mt={2}>
              <Input
                value={inviteLink}
                bg="transparent"
                placeholder="Search by user name..."
                readOnly
                minH="50px"
              />
              <InputLeftElement top="6px">
                <Icon as={IoLinkSharp} color="gray.400" w={5} h={5} />
              </InputLeftElement>
              <InputRightElement>
                <Button
                  onClick={handleCopyBoardLink}
                  px={8}
                  h={10}
                  top="7px"
                  left={-5}
                >
                  Copy
                </Button>
              </InputRightElement>
            </InputGroup>
            <Text color="gray.500" fontSize={14} mt={2}>
              Anyone who signs up with this link will be automatically connected
              with your team
            </Text>
          </>
        )}
      </Card>
    </ModalContent>
  );
};

type InviteTeamMemberInnerModalProps = Omit<ModalProps, 'children'>;
export const InviteTeamMemberModal: FC<InviteTeamMemberInnerModalProps> = (
  props
) => (
  <Modal {...props} variant="unstyled">
    <ModalOverlay />
    <InviteTeamMemberModalContent onClose={props.onClose} />
  </Modal>
);
