import { FC } from 'react';
import { useActionItemsAnalytics } from '../queries';
import { useNow, ONE_DAY_MS, HStack, TrendCard } from '@spoke/common';

type Props = {
  recursive?: boolean;
};
export const ActionItemAverages: FC<Props> = ({ recursive }) => {
  const now = useNow({ roundTo: 'ten-minutes' });

  const [analyticsData, { loading: analyticsLoading }] =
    useActionItemsAnalytics({
      endDate: now,
      timeWindowLength: 90 * ONE_DAY_MS,
      recursive,
    });

  return (
    <HStack>
      <TrendCard
        title="Action items created"
        info="This quarter"
        loading={analyticsLoading}
        primaryValue={analyticsData?.current?.createdCount ?? 0}
        currentValue={analyticsData?.current?.createdCount ?? 0}
        previousValue={analyticsData?.past?.createdCount ?? 0}
        secondaryValue={analyticsData?.organization?.averageCreatedCount ?? 0}
        prefix={{
          secondaryValue: 'Organization avg. ',
        }}
        suffix={{
          differenceValue: ' compared to last quarter',
        }}
        w="full"
      />
      <TrendCard
        title="Action items resolved"
        info="This quarter"
        loading={analyticsLoading}
        primaryValue={analyticsData?.current?.resolvedCount ?? 0}
        currentValue={analyticsData?.current?.resolvedCount ?? 0}
        previousValue={analyticsData?.past?.resolvedCount ?? 0}
        secondaryValue={analyticsData?.organization?.averageResolvedCount ?? 0}
        prefix={{
          secondaryValue: 'Organization avg. ',
        }}
        suffix={{
          differenceValue: ' compared to last quarter',
        }}
        w="full"
      />
      <TrendCard
        title="Average action item completion time"
        info="This quarter"
        loading={analyticsLoading}
        primaryValue={analyticsData?.current?.averageTimeToResolve ?? 0}
        currentValue={analyticsData?.current?.averageTimeToResolve ?? 0}
        previousValue={analyticsData?.past?.averageTimeToResolve ?? 0}
        secondaryValue={analyticsData?.organization?.averageTimeToResolve ?? 0}
        prefix={{
          secondaryValue: 'Organization avg. ',
        }}
        suffix={{
          primaryValue: 'd',
          differenceValue: 'd compared to last quarter',
          secondaryValue: 'd',
        }}
        w="full"
      />
    </HStack>
  );
};
