import {
  MenuList as ChakraMenuList,
  MenuListProps as ChakraMenuListProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export type MenuListProps = ChakraMenuListProps;

export const MenuList: FC<MenuListProps> = (props) => (
  <ChakraMenuList {...props} />
);
