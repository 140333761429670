import { IconProps, Icon } from '../Icon';

export const JiraIcon = (props: IconProps) => (
  <Icon viewBox="0 0 74 74" {...props}>
    <title>Jira Logo</title>
    <defs>
      <linearGradient
        x1="91.8666124%"
        y1="40.3276956%"
        x2="28.2635218%"
        y2="81.6596195%"
        id="linearGradient-1"
      >
        <stop stopColor="#0052CC" offset="18%" />
        <stop stopColor="#2684FF" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="8.70980871%"
        y1="59.1664468%"
        x2="72.2425722%"
        y2="17.9899763%"
        id="linearGradient-2"
      >
        <stop stopColor="#0052CC" offset="18%" />
        <stop stopColor="#2684FF" offset="100%" />
      </linearGradient>
    </defs>
    <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
      <g id="Jira-Software-blue" fillRule="nonzero">
        <path
          d="M72.4,35.76 L39.8,3.16 L36.64,0 L12.1,24.54 L0.88,35.76 C-0.289813512,36.9312702 -0.289813512,38.8287298 0.88,40 L23.3,62.42 L36.64,75.76 L61.18,51.22 L61.56,50.84 L72.4,40 C73.5698135,38.8287298 73.5698135,36.9312702 72.4,35.76 Z M36.64,49.08 L25.44,37.88 L36.64,26.68 L47.84,37.88 L36.64,49.08 Z"
          id="Shape"
          fill="#2684FF"
        />
        <path
          d="M36.64,26.68 C29.3070783,19.346212 29.2713402,7.46777926 36.56,0.09 L12.05,24.59 L25.39,37.93 L36.64,26.68 Z"
          id="Shape"
          fill="url(#linearGradient-1)"
        />
        <path
          d="M47.87,37.85 L36.64,49.08 C40.179363,52.6172581 42.1679334,57.4160731 42.1679334,62.42 C42.1679334,67.4239269 40.179363,72.2227419 36.64,75.76 L61.21,51.19 L47.87,37.85 Z"
          id="Shape"
          fill="url(#linearGradient-2)"
        />
      </g>
    </g>
  </Icon>
);
