import { motion } from 'framer-motion';
import { FC, useState, useCallback, useEffect } from 'react';
import {
  INVALID_VERIFICATION_CODE,
  useToast,
  GENERIC_ERROR_MESSAGE,
  SpkHead,
  Card,
  VStack,
  Heading,
  Center,
  CodeInput,
  Button,
  Link,
  log,
  Image,
  Text,
  useAssets,
} from '@spoke/common';
import {
  useUserEmailByResetPasswordTokenLazyQuery,
  useForgotPasswordMutation,
} from '@spoke/graphql';

const CODE_LENGTH = 5;

const messages = {
  required: 'Confirmation code is required',
  badFormat: 'Please enter a valid code.',
  rejected: INVALID_VERIFICATION_CODE,
};

type AuthConfirmResetPasswordProps = {
  email: string;
  onSuccess: (code: string) => void;
  onContinueWithEmailPassword?: () => void;
};
export const AuthConfirmResetPassword: FC<AuthConfirmResetPasswordProps> = ({
  email,
  onSuccess,
  onContinueWithEmailPassword,
}) => {
  const [toast] = useToast();

  const [userEmailByResetPasswordToken, { loading: isSubmitting }] =
    useUserEmailByResetPasswordTokenLazyQuery();

  const [resetPassword] = useForgotPasswordMutation();

  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const { illustrationHello } = useAssets();

  const handleResetPassword = useCallback(async () => {
    log.info('Submitting forgot password ', { email });

    const { errors } = await resetPassword({
      variables: { email, isChangingProviders: false },
    });

    if (errors) {
      log.error('Forgot password mutation responded with error', { error });
      setError(GENERIC_ERROR_MESSAGE);
      return;
    }

    toast({
      title: 'Email sent',
      description: `An email has been sent to ${email} with a code to reset your password.`,
    });
  }, [email, error, resetPassword, toast]);

  const handleSubmit = useCallback(async () => {
    if (code.length !== CODE_LENGTH) {
      setError(messages.badFormat);
      return;
    }

    log.info('Submitting reset password token form', { email, code });
    const { data, error: queryError } = await userEmailByResetPasswordToken({
      variables: { resetPasswordToken: code },
    });

    if (queryError) {
      log.warn('userEmailByResetPasswordToken mutation responded with error', {
        error: queryError,
      });
      setError(INVALID_VERIFICATION_CODE);
      return;
    }

    const emailLinkedToCode = data?.userEmailByResetPasswordToken;

    if (emailLinkedToCode !== email) {
      log.warn('User submitted a code that is linked to another email', {
        email,
        emailLinkedToCode,
      });
      setError(INVALID_VERIFICATION_CODE);
      return;
    }

    onSuccess(code);
  }, [code, email, userEmailByResetPasswordToken, onSuccess]);

  const onCodeChange = (value: string) => {
    setCode(value);
    setError('');
  };

  useEffect(() => {
    if (code.length !== CODE_LENGTH) return;
    handleSubmit();
  }, [code, handleSubmit]);

  return (
    <motion.div
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{ duration: 0.15, ease: 'easeOut' }}
    >
      <SpkHead title="Reset Password" />
      <Card gap={0} w={460} p={10}>
        <Image src={illustrationHello} alt="Hello from ScatterSpoke" />
        <VStack mb={3}>
          <Heading size="md" fontWeight={500} color="gray.700">
            Reset your password
          </Heading>
          <Text fontSize="sm" color="gray.500" textAlign="center" px={5}>
            Enter the code that was sent to {email} below.
          </Text>
        </VStack>
        <VStack spacing={4} w="full">
          <Center>
            <CodeInput
              value={code}
              onChange={onCodeChange}
              isInvalid={!!error}
              size="lg"
            />
          </Center>
          {error && (
            <Text
              textAlign="center"
              display="block"
              fontSize="xs"
              color="red.500"
            >
              {error}
            </Text>
          )}
          <Button w="full" isLoading={isSubmitting} onClick={handleSubmit}>
            Choose a new password
          </Button>
          {onContinueWithEmailPassword && (
            <Text fontSize="xs" color="gray.500" w="full" textAlign="left">
              Remember your password?{' '}
              <Link onClick={() => onContinueWithEmailPassword()}>Sign in</Link>
            </Text>
          )}
          <Text fontSize="xs" color="gray.500" w="full" textAlign="left">
            Didn&apos;t get the email? Check your spam folder or{' '}
            <Link onClick={handleResetPassword}>resend the code</Link>
          </Text>
        </VStack>
      </Card>
    </motion.div>
  );
};
