import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  optimisticUpdateParkingLotItem,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import {
  useArchiveParkingLotItemMutation,
  ParkingLotItem,
} from '@spoke/graphql';

export const useDeleteParkingLotItemHandler = () => {
  const [archiveParkingLotItem] = useArchiveParkingLotItemMutation();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  const handleDeleteParkingLotItem = useCallback(
    async (parkingLotItemId: string) => {
      const updateFields: Partial<ParkingLotItem> = { archived: true };

      const expected = optimisticUpdateParkingLotItem({
        cache,
        parkingLotItemId,
        updateFields,
      });

      const { errors } = await archiveParkingLotItem({
        variables: {
          itemId: parkingLotItemId,
        },
        optimisticResponse: !expected
          ? undefined
          : {
              __typename: 'Mutation',
              archiveParkingLotItem: expected,
            },
      });

      if (errors) {
        log.error('ArchiveParkingLotItem call responded with errors', {
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [cache, toast, archiveParkingLotItem]
  );

  return [handleDeleteParkingLotItem];
};
