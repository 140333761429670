import { useCallback } from 'react';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BiUser } from 'react-icons/bi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { MdOutlinedFlag } from 'react-icons/md';
import { DATE_RANGE_OPTIONS } from './ActionItemsModal';
import { useFetchTeamMembers } from '@spoke/user';
import { useImprovementGoals } from '@spoke/improvement-goals';
import { ImprovementGoal, User } from '@spoke/graphql';
import {
  truncateWithCount,
  MultiSelect,
  ImprovementGoalOption,
  useDisclosure,
  HStack,
  Menu,
  log,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
  DateRange,
  Button,
  useCurrentUser,
} from '@spoke/common';

export type ActionItemFilterProps = {
  dateRange: DateRange;
  setDateRange: (value: DateRange) => void;

  selectedAssignees: User[];
  setSelectedAssignees: (users: User[]) => void;

  selectedGoals: ImprovementGoal[];
  setSelectedGoals: (goals: ImprovementGoal[]) => void;
};

export const ActionItemFilter = ({
  dateRange,
  setDateRange,
  selectedAssignees,
  setSelectedAssignees,
  selectedGoals,
  setSelectedGoals,
}: ActionItemFilterProps) => {
  const [currentUser] = useCurrentUser();
  const [{ activeGoals: teamGoals }] = useImprovementGoals();
  const [fetchTeamMembers] = useFetchTeamMembers();
  const assigneesDropdown = useDisclosure();
  const goalsDropdown = useDisclosure();

  const selectedDateRangeLabel = DATE_RANGE_OPTIONS.find(
    (opt) => opt.value === dateRange
  )?.label;

  const improvementGoalsLabel =
    truncateWithCount(selectedGoals, (goal) => goal.type.name) || 'Select goal';
  const assigneesLabel =
    truncateWithCount(selectedAssignees, 'name') || 'Anyone';

  const getAssigneeSuggestions = useCallback(
    async (term: string): Promise<User[]> => {
      const teamId = currentUser?.team?.id;

      if (!teamId) {
        log.error('Cannot fetch user suggestions without loaded teamId', {
          teamId,
        });
        return [];
      }

      const users = await fetchTeamMembers({ term, teamId });

      return (users?.filter(Boolean) as User[]) || [];
    },
    [currentUser?.team?.id, fetchTeamMembers]
  );

  const getteamGoalsuggestions = useCallback(
    () => (teamGoals as ImprovementGoal[]) || [],
    [teamGoals]
  );

  return (
    <HStack>
      <Menu offset={[40, 2]} placement="bottom">
        <MenuButton>
          <Button
            leftIcon={<Icon as={AiTwotoneCalendar} />}
            rightIcon={<Icon as={BsChevronDown} />}
            as="span"
            variant="outlineGray"
          >
            {selectedDateRangeLabel}
          </Button>
        </MenuButton>
        <MenuList>
          {DATE_RANGE_OPTIONS.map((o) => (
            <MenuItem key={o.label} onClick={() => setDateRange(o.value)}>
              {o.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      <MultiSelect
        searchable
        values={selectedAssignees}
        onChange={setSelectedAssignees}
        onClose={assigneesDropdown.close}
        isOpen={assigneesDropdown.isOpen}
        idKey="id"
        labelKeyOrFn="name"
        getOptions={getAssigneeSuggestions}
      >
        <Button
          onClick={assigneesDropdown.toggle}
          variant="outlineGray"
          leftIcon={<Icon as={BiUser} />}
          rightIcon={
            <Icon as={assigneesDropdown.isOpen ? BsChevronDown : BsChevronUp} />
          }
        >
          {assigneesLabel}
        </Button>
      </MultiSelect>
      <MultiSelect
        values={selectedGoals}
        onChange={setSelectedGoals}
        idKey="id"
        labelKeyOrFn={(goal) => goal.type.name}
        onClose={goalsDropdown.close}
        isOpen={goalsDropdown.isOpen}
        renderOption={ImprovementGoalOption}
        getOptions={getteamGoalsuggestions}
      >
        <Button
          onClick={goalsDropdown.toggle}
          variant="outlineGray"
          leftIcon={<Icon as={MdOutlinedFlag} />}
          rightIcon={
            <Icon as={assigneesDropdown.isOpen ? BsChevronDown : BsChevronUp} />
          }
        >
          {improvementGoalsLabel}
        </Button>
      </MultiSelect>
    </HStack>
  );
};
