import { FC, memo } from 'react';
import {
  Flex,
  HStack,
  Tooltip,
  randomString,
  getIdealContrastForBackground,
  Circle,
  Text,
} from '@spoke/common';
import { ImprovementGoalDistribution } from '@spoke/graphql';

type FactsStatsImprovementGoalDistributionProps = {
  improvementGoalsDistributions: ImprovementGoalDistribution[];
};
export const FactsStatsImprovementGoalDistributionBars: FC<
  FactsStatsImprovementGoalDistributionProps
> = ({ improvementGoalsDistributions }) => (
  <Flex flexDirection="column" gap={2} w="full">
    <HStack spacing="3px" w="full">
      {improvementGoalsDistributions.map((distribution, idx, arr) => (
        <Tooltip
          key={distribution.goal?.id || randomString()}
          label={`${
            distribution.goal?.type.name || 'Unassigned'
          } | ${distribution.percentage.toFixed(0)}%`}
          variant="white"
          placement="top"
        >
          <Flex
            borderRightRadius={idx === arr.length - 1 ? '4px' : 0}
            bg={distribution.goal?.type.color || 'gray.200'}
            borderLeftRadius={idx === 0 ? '4px' : 0}
            flex={distribution.percentage}
            p={2}
            h="full"
          >
            {Boolean(distribution.percentage) && (
              <Text
                fontWeight={500}
                color={getIdealContrastForBackground(
                  distribution.goal?.type?.color || 'black'
                )}
              >
                {distribution.percentage.toFixed(0)}%
              </Text>
            )}
          </Flex>
        </Tooltip>
      ))}
    </HStack>
    <Flex flexWrap="wrap" gap={2}>
      {improvementGoalsDistributions.map((distribution) => (
        <Flex
          p={0}
          px={2}
          alignItems="center"
          layerStyle="outlineGray"
          borderColor="gray.300"
          whiteSpace="nowrap"
          key={distribution.goal?.id || randomString()}
        >
          <Circle
            bg={distribution.goal?.type?.color || 'gray.300'}
            boxShadow="inner"
            size="8px"
            mr={1}
          />
          <Text pt={1} fontSize={14} color="gray.500" fontWeight={500}>
            {distribution.goal?.type.name || 'Unassigned'}
          </Text>
        </Flex>
      ))}
    </Flex>
  </Flex>
);

export const MemoizedFactsStatsImprovementGoalDistributionBars = memo(
  FactsStatsImprovementGoalDistributionBars
);
