import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useCurrentBoardId,
  useToast,
  TOAST_ERROR_GENERIC,
  getBoardFromCache,
  SpkHttp,
  downloadBlob,
  log,
  MimeType,
} from '@spoke/common';

export const useExportBoardPdfHandler = () => {
  const [boardId] = useCurrentBoardId();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  const handleExportBoardPdf = useCallback(async () => {
    if (!boardId) {
      log.error('Cannot export board to PDF without loaded board id', {
        boardId,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const board = getBoardFromCache({ boardId, cache });

    if (!board) {
      log.error('Cannot export board to PDF: cached board not found', {
        boardId,
        board,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const pdf = await SpkHttp.exportBoardPdf(boardId);

    if (!pdf) {
      log.error('Export board to PDF responded with invalid data', {
        pdf,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    downloadBlob(pdf, MimeType.Pdf, `${board.name}.pdf`);
  }, [boardId, cache, toast]);

  return [handleExportBoardPdf];
};
