import { makeVar } from '@apollo/client';
import { useEffect } from 'react';
import { useCurrentUser } from '@spoke/common';
import {
  InstalledUserIntegrationsUpdateDocument,
  InstalledUserIntegrationsUpdateSubscription,
  useInstalledUserIntegrationsQuery,
} from '@spoke/graphql';

type QueryRef = ReturnType<typeof useInstalledUserIntegrationsQuery>;
type QueryData = NonNullable<QueryRef['data']>['installedUserIntegrations'];

const subscribedToChanges = makeVar<boolean>(false);

export const useInstalledUserIntegrations = (): Omit<
  QueryData,
  '__typename'
> & {
  loading: boolean;
  refetch: () => Promise<unknown>;
} => {
  const [user] = useCurrentUser();
  const installedIntegrations = useInstalledUserIntegrationsQuery();
  const userId = user?.id;

  useEffect(() => {
    if (!userId || subscribedToChanges()) return;

    subscribedToChanges(true);

    installedIntegrations.subscribeToMore<InstalledUserIntegrationsUpdateSubscription>(
      {
        document: InstalledUserIntegrationsUpdateDocument,
        variables: { userId } as any, // Why is typescript complaining here?
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          return {
            installedUserIntegrations:
              subscriptionData?.data?.installedUserIntegrationsUpdate ||
              prev.installedUserIntegrations,
          };
        },
      }
    );
  }, [installedIntegrations, userId]);

  const data = installedIntegrations.data?.installedUserIntegrations;

  const result = {
    confluence: data?.confluence || false,
    jira: data?.jira || false,
    miro: data?.miro || false,
    trello: data?.trello || false,
    loading: installedIntegrations.loading,
    refetch: installedIntegrations.refetch,
  };

  return result;
};
