import { BoardDiscussionCardsSortingKey } from '@spoke/graphql';

type RequiredCardDataForSorting = {
  votes?: unknown[];
  comments?: unknown[];
  createdAt?: number | null;
  originalListBadge?: { originalListId?: string | null } | null;
};

const sortByVotes =
  (sortByTags: boolean) =>
  <T extends Pick<RequiredCardDataForSorting, 'votes' | 'originalListBadge'>>(
    cardA?: T | null,
    cardB?: T | null
  ): number => {
    const cardAOriginalList = cardA?.originalListBadge?.originalListId ?? '';
    const cardBOriginalList = cardB?.originalListBadge?.originalListId ?? '';

    if (sortByTags && cardAOriginalList !== cardBOriginalList) {
      return cardAOriginalList.localeCompare(cardBOriginalList);
    }

    const cardAVotes = cardA?.votes?.length ?? 0;
    const cardBVotes = cardB?.votes?.length ?? 0;
    return cardBVotes - cardAVotes;
  };

const sortByComments =
  (sortByTags: boolean) =>
  <
    T extends Pick<RequiredCardDataForSorting, 'comments' | 'originalListBadge'>
  >(
    cardA?: T | null,
    cardB?: T | null
  ): number => {
    const cardAOriginalList = cardA?.originalListBadge?.originalListId ?? '';
    const cardBOriginalList = cardB?.originalListBadge?.originalListId ?? '';

    if (sortByTags && cardAOriginalList !== cardBOriginalList) {
      return cardAOriginalList.localeCompare(cardBOriginalList);
    }

    const cardAComments = cardA?.comments?.length ?? 0;
    const cardBComments = cardB?.comments?.length ?? 0;
    return cardBComments - cardAComments;
  };

const sortByDateAsc =
  (sortByTags: boolean) =>
  <
    T extends Pick<
      RequiredCardDataForSorting,
      'createdAt' | 'originalListBadge'
    >
  >(
    cardA?: T | null,
    cardB?: T | null
  ): number => {
    const cardAOriginalList = cardA?.originalListBadge?.originalListId ?? '';
    const cardBOriginalList = cardB?.originalListBadge?.originalListId ?? '';

    if (sortByTags && cardAOriginalList !== cardBOriginalList) {
      return cardAOriginalList.localeCompare(cardBOriginalList);
    }

    const cardADate = new Date(cardA?.createdAt ?? 0);
    const cardBDate = new Date(cardB?.createdAt ?? 0);
    return cardADate.getTime() - cardBDate.getTime();
  };

export const sortDiscussCards = <
  T extends RequiredCardDataForSorting | null | undefined
>(
  _cards: Array<T> | null | undefined,
  key: BoardDiscussionCardsSortingKey,
  alsoSortByTags: boolean = false
): T[] => {
  const { Comments, DateAsc } = BoardDiscussionCardsSortingKey;
  if (!_cards) return [];
  const cards = [..._cards];
  if (key === Comments) return cards.sort(sortByComments(alsoSortByTags));
  if (key === DateAsc) return cards.sort(sortByDateAsc(alsoSortByTags));
  const a = cards.sort(sortByVotes(alsoSortByTags));
  return a;
};
