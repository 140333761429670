import { useCurrentOrg } from './useCurrentOrg';
import { useCurrentTeam } from './useCurrentTeam';
import { useCurrentUser } from '@spoke/common';
import { BetaFeature } from '@spoke/graphql';

type UseBetaFeatures = {
  jiraMetrics: boolean;
  githubMetrics: boolean;
  teamInsights: boolean;
  bitbucketMetrics: boolean;
  loading: boolean;
};
export const useBetaFeatures = (): UseBetaFeatures => {
  const [org, { loading: orgLoading }] = useCurrentOrg();
  const [team, { loading: teamLoading }] = useCurrentTeam();
  const [user, { loading: userLoading }] = useCurrentUser();

  const orgBetaFeatures: UseBetaFeatures = {
    jiraMetrics: org?.betaFeatures.includes(BetaFeature.JiraMetrics) ?? false,
    githubMetrics:
      org?.betaFeatures.includes(BetaFeature.GithubMetrics) ?? false,
    teamInsights: org?.betaFeatures.includes(BetaFeature.TeamInsights) ?? false,
    bitbucketMetrics: !!org?.betaFeatures?.includes(
      BetaFeature.BitbucketMetrics
    ),
    loading: orgLoading,
  };

  const teamBetaFeatures: UseBetaFeatures = {
    jiraMetrics: team?.betaFeatures.includes(BetaFeature.JiraMetrics) ?? false,
    githubMetrics:
      team?.betaFeatures.includes(BetaFeature.GithubMetrics) ?? false,
    teamInsights:
      team?.betaFeatures.includes(BetaFeature.TeamInsights) ?? false,
    bitbucketMetrics: !!team?.betaFeatures?.includes(
      BetaFeature.BitbucketMetrics
    ),
    loading: teamLoading,
  };

  const userBetaFeatures: UseBetaFeatures = {
    jiraMetrics: user?.betaFeatures.includes(BetaFeature.JiraMetrics) ?? false,
    githubMetrics:
      user?.betaFeatures.includes(BetaFeature.GithubMetrics) ?? false,
    teamInsights:
      user?.betaFeatures.includes(BetaFeature.TeamInsights) ?? false,
    bitbucketMetrics: !!user?.betaFeatures?.includes(
      BetaFeature.BitbucketMetrics
    ),
    loading: userLoading,
  };

  const features: UseBetaFeatures = {
    jiraMetrics:
      orgBetaFeatures.jiraMetrics ||
      teamBetaFeatures.jiraMetrics ||
      userBetaFeatures.jiraMetrics,
    githubMetrics:
      orgBetaFeatures.githubMetrics ||
      teamBetaFeatures.githubMetrics ||
      userBetaFeatures.githubMetrics,
    teamInsights:
      orgBetaFeatures.teamInsights ||
      teamBetaFeatures.teamInsights ||
      userBetaFeatures.teamInsights,
    bitbucketMetrics:
      orgBetaFeatures.bitbucketMetrics ||
      teamBetaFeatures.bitbucketMetrics ||
      userBetaFeatures.bitbucketMetrics,
    loading: orgLoading || teamLoading || userLoading,
  };

  return features;
};
