import { useCallback } from 'react';
import {
  useJiraRateLimitLock,
  isExternalRateLimitError,
  log,
} from '@spoke/common';
import {
  useLinkableJiraBoardsLazyQuery,
  LinkableJiraBoardsQuery,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type QueryRef = ReturnType<typeof useLinkableJiraBoardsLazyQuery>[1];
type QueryData = NonNullable<
  NonNullable<NonNullable<QueryRef['data']>['team']>['linkableJiraBoards']
>;
type FetchArgs = { search: string };

export const useFetchLinkableJiraBoards = (): [
  (args: FetchArgs) => Promise<QueryData>,
  QueryRef
] => {
  const [_, fireJiraRateLimitLock] = useJiraRateLimitLock();
  const [currentTeam] = useCurrentTeam();
  const [linkableJiraBoards, queryRef] = useLinkableJiraBoardsLazyQuery({
    // https://scatterspoke.atlassian.net/browse/SC-594
    errorPolicy: 'none',
  });

  const fetchLinkableJiraBoards = useCallback(
    async ({
      search,
    }: FetchArgs): Promise<
      NonNullable<LinkableJiraBoardsQuery['team']>['linkableJiraBoards']
    > => {
      const teamId = currentTeam?.id;

      if (!teamId) {
        log.error('Missing critical data to fetch linkable Jira boards', {
          teamId,
          search,
        });
        return [];
      }

      log.info(`Fetching linkable Jira boards`, {
        teamId,
      });

      const { error, data } = await linkableJiraBoards({
        variables: { search, teamId },
      });

      const linkableBoards = data?.team?.linkableJiraBoards;

      if (error || !linkableBoards) {
        log.error('linkableJiraBoards query responded with errors', {
          error,
          data,
          teamId,
          search,
        });

        if (error?.graphQLErrors.some(isExternalRateLimitError)) {
          log.error('Jira linkableBoards responded with rate limited', {
            error,
            data,
          });
          fireJiraRateLimitLock();
        }

        return [];
      }

      return linkableBoards;
    },
    [currentTeam?.id, fireJiraRateLimitLock, linkableJiraBoards]
  );

  return [fetchLinkableJiraBoards, queryRef];
};
