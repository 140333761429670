import {
  ComponentStyleConfig,
  Kbd as ChakraKbd,
  KbdProps as ChakraKbdProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export const KbdComponentStyle: ComponentStyleConfig = {};

type Props = ChakraKbdProps;

export const KeyboardKey = forwardRef<HTMLAnchorElement, Props>(
  (props, ref) => <ChakraKbd ref={ref} {...props} />
);
