import { makeVar } from '@apollo/client';
import { useEffect } from 'react';
import {
  QueryConfig,
  log,
  FIVE_SECONDS_MS,
  DeepPartial,
  useNetworkContext,
  useCurrentBoardId,
  useCurrentUser,
  useToast,
  useInstanceCount,
  deepMerge,
  uniqueBy,
  TWENTY_SECONDS_MS,
} from '@spoke/common';
import {
  useLivePollsByBoardIdLazyQuery,
  LivePollUpdatesByBoardIdSubscription,
  LivePollUpdatesByBoardIdDocument,
  LivePollUpdatesByBoardIdSubscriptionVariables,
  LivePollUserStatus,
} from '@spoke/graphql';

// This state persists app-wide, not component-specific. So we don't create unecessary susbcriptions
const subscribedToChangesVar = makeVar<boolean>(false);
const unsubscriptionsVar = makeVar<(() => void)[]>([]);
const isPollingVar = makeVar<boolean>(false);

/**
 * Makes sure we only trigger one Poll Finished toast regardless of amount of hook instances
 */
const lastPollFinishedToastMsVar = makeVar<number>(0);

type Config = QueryConfig<typeof useLivePollsByBoardIdLazyQuery>;
type QueryRef = ReturnType<typeof useLivePollsByBoardIdLazyQuery>[1];
type QueryData = NonNullable<QueryRef['data']>['livePollsByBoardId'];

const LIVE_POLLS_POLLING_INTERVAL_MS = FIVE_SECONDS_MS;

type UseLivePollsHookConfig = {
  pollIfNeeded?: boolean;
};

const DEFAULT_HOOK_CONFIG: UseLivePollsHookConfig = {
  pollIfNeeded: false,
};

export const useLivePolls = (
  config?: DeepPartial<Config>,
  hookConfig: UseLivePollsHookConfig = DEFAULT_HOOK_CONFIG
): [QueryData, QueryRef] => {
  const { pollIfNeeded } = hookConfig;
  const { shouldPoll } = useNetworkContext();
  const [currentBoardId] = useCurrentBoardId();
  const [currentUser] = useCurrentUser();
  const [toast] = useToast();

  const userId = currentUser?.id;

  const [getInstanceCount] = useInstanceCount('useLivePolls');

  const baseConfig: DeepPartial<Config> = {
    variables: {
      boardId: currentBoardId ?? '',
    },
  };

  const finalConfig = (
    config ? deepMerge(baseConfig, config) : baseConfig
  ) as Config;

  const [loadLivePolls, livePollsQuery] =
    useLivePollsByBoardIdLazyQuery(finalConfig);

  useEffect(() => {
    const shouldInit =
      Boolean(baseConfig.variables?.boardId) && !livePollsQuery.called;
    if (shouldInit) loadLivePolls();
  }, [baseConfig.variables?.boardId, livePollsQuery.called, loadLivePolls]);

  useEffect(() => {
    const boardId = baseConfig.variables?.boardId;
    const shouldSubscribe = Boolean(
      !subscribedToChangesVar() &&
        !shouldPoll &&
        livePollsQuery.called &&
        userId &&
        boardId
    );

    if (!shouldSubscribe) return;

    subscribedToChangesVar(true);
    log.info('Starting LivePoll updates subscription');

    const unsubscribe =
      livePollsQuery.subscribeToMore<LivePollUpdatesByBoardIdSubscription>({
        document: LivePollUpdatesByBoardIdDocument,
        variables: {
          boardId,
          userId,
        } as LivePollUpdatesByBoardIdSubscriptionVariables, // Why is this cast needed?
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const updatedLivePolls =
            subscriptionData.data.livePollUpdatesByBoardId;

          const mergedLivePolls = [
            ...updatedLivePolls,
            ...(prev?.livePollsByBoardId || []),
          ].filter(Boolean) as Array<
            NonNullable<NonNullable<typeof updatedLivePolls>[0]>
          >;

          const uniqueMergedLivePolls = uniqueBy(mergedLivePolls, 'id').sort(
            (a, b) => {
              // Alphabetical sort by id just to keep order consistent
              if (a.id > b.id) return -1;
              if (a.id < b.id) return 1;
              return 0;
            }
          );

          return {
            __typename: 'Query',
            livePollsByBoardId: uniqueMergedLivePolls,
          };
        },
      });

    unsubscriptionsVar([unsubscribe]);
  }, [baseConfig.variables?.boardId, livePollsQuery, shouldPoll, userId]);

  useEffect(() => {
    if (!userId || (!shouldPoll && isPollingVar())) {
      log.info('Stopping LivePoll polling');
      livePollsQuery.stopPolling();
      isPollingVar(false);
      return;
    }

    if (shouldPoll && !isPollingVar() && pollIfNeeded) {
      log.info('Starting LivePoll polling');
      livePollsQuery.startPolling(LIVE_POLLS_POLLING_INTERVAL_MS);
      isPollingVar(true);
      return;
    }
  }, [shouldPoll, livePollsQuery, userId, pollIfNeeded]);

  useEffect(
    () => () => {
      // This allows us to only unsubscribe when all useLivePolls hooks are unmounted
      const isLastInstance = getInstanceCount() === 0;
      if (!isLastInstance) return;
      log.info('Stopping LivePoll updates subscription');
      for (const unsubscribe of unsubscriptionsVar()) unsubscribe();
      subscribedToChangesVar(false);
    },
    [getInstanceCount, livePollsQuery]
  );

  useEffect(
    () => () => {
      // This stops polling for this query instance, regardless of whether this is the
      // last instance of the hook or not (other instances might have polling disabled)
      if (!isPollingVar() || !pollIfNeeded) return;
      log.info('Stopping LivePoll polling');
      livePollsQuery.stopPolling();
      isPollingVar(false);
    },
    [pollIfNeeded, livePollsQuery]
  );

  useEffect(() => {
    const previousData = livePollsQuery.previousData?.livePollsByBoardId;
    const data = livePollsQuery.data?.livePollsByBoardId;
    if (!data || !previousData) return;
    const previousFinished = previousData.filter(
      (poll) => poll.status === LivePollUserStatus.Finished
    );
    const finished = data.filter(
      (poll) => poll.status === LivePollUserStatus.Finished
    );
    const pollsHaveJustFinished = previousFinished.length < finished.length;
    const shouldToast =
      lastPollFinishedToastMsVar() + TWENTY_SECONDS_MS < Date.now();
    if (pollsHaveJustFinished && shouldToast) {
      lastPollFinishedToastMsVar(Date.now());
      toast({
        status: 'success',
        title: 'Poll finished',
        description:
          'Poll finished! Results have been reported to your Team Goals.',
      });
    }
  }, [
    livePollsQuery.data?.livePollsByBoardId,
    livePollsQuery.previousData?.livePollsByBoardId,
    toast,
  ]);

  const queryData =
    livePollsQuery.data?.livePollsByBoardId ??
    livePollsQuery.previousData?.livePollsByBoardId ??
    [];

  return [queryData, livePollsQuery];
};
