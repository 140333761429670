import { StyleProps, useToken } from '@chakra-ui/react';
import { isServer, clearDocumentSelection, callIfExists } from '@spoke/common';
import { FC, useEffect, useMemo, useRef } from 'react';
import { Box } from './Box';
import { Divider } from './Divider';
import { Flex, FlexProps } from './Flex';
import { Text } from './Text';

const RESIZABLE_PEEK_TRANSITION = 'width 0.2s ease';
const RESIZABLE_COLLAPSED_WIDTH = '40px';

// We can work on this to add ability to resize in other directions too. But only right needed for now
type SpokeResizableProps = Omit<FlexProps, 'direction'> & {
  direction?: 'right';
  onResizeEnd?: (newSize: number) => void;
  onCollapseClick?: () => void;
  isCollapsed?: boolean;
  size?: number;
  minSize?: number;
  maxSize?: number;
  containerProps?: FlexProps;
};
export const SpokeResizable: FC<SpokeResizableProps> = ({
  direction: _direction = 'right',
  size = 300,
  isCollapsed = false,
  onCollapseClick,
  containerProps = {},
  onResizeEnd,
  minSize,
  maxSize,
  children,
  ...rest
}) => {
  const [primary500, gray200] = useToken('colors', ['primary.500', 'gray.200']);

  const resizableRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const handleRef = useRef<HTMLHRElement>(null);
  const handleContainerRef = useRef<HTMLDivElement>(null);

  const activeHandleStyle: StyleProps = useMemo(
    () => ({
      borderLeftWidth: '2px',
      borderColor: primary500,
    }),
    [primary500]
  );

  const inactiveHandleStyle: StyleProps = useMemo(
    () => ({
      borderLeftWidth: '1px',
      borderColor: gray200,
    }),
    [gray200]
  );

  useEffect(() => {
    if (isServer()) return;

    const resizableEle = resizableRef.current;
    const handleEle = handleRef.current;
    const contentEle = contentRef.current;

    if (!resizableEle || !handleEle || !contentEle) return;

    const styles = window.getComputedStyle(resizableEle);
    let width = parseInt(styles.width);
    let mouseX = 0;
    let resizing = false;

    const onMouseMove = (event: MouseEvent) => {
      if (!resizing) resizing = true;
      clearDocumentSelection();
      const xDiff = event.clientX - mouseX;
      const newWidth = width + xDiff;
      if (maxSize && newWidth > maxSize) return;
      if (minSize && newWidth < minSize) return;
      mouseX = event.clientX;
      width = newWidth;
      resizableEle.style.width = `${width}px`;
    };

    const onMouseUp = () => {
      resizing = false;
      resizableEle.style.transition = RESIZABLE_PEEK_TRANSITION;
      if (maxSize && width > maxSize) width = maxSize;
      if (minSize && width < minSize) width = minSize;
      callIfExists(onResizeEnd, width);

      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    const onMouseDown = (event: MouseEvent) => {
      resizableEle.style.transition = 'none';
      mouseX = event.clientX;
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    };

    handleEle.addEventListener('mousedown', onMouseDown);

    const peek = () => {
      if (!isCollapsed || resizing) return;
      resizableEle.style.transition = RESIZABLE_PEEK_TRANSITION;
      resizableEle.style.width = `${size}px`;
      handleEle.style.pointerEvents = 'all';
      handleEle.style.cursor = 'ew-resize';
    };

    const unpeek = () => {
      if (!isCollapsed || resizing) {
        handleEle.style.pointerEvents = 'all';
        handleEle.style.cursor = 'ew-resize';
        return;
      }
      resizableEle.style.transition = RESIZABLE_PEEK_TRANSITION;
      resizableEle.style.width = RESIZABLE_COLLAPSED_WIDTH;
      handleEle.style.pointerEvents = 'none';
      handleEle.style.cursor = 'default';
    };

    contentEle.addEventListener('mouseenter', peek);
    resizableEle.addEventListener('mouseleave', unpeek);

    return () => {
      handleEle.removeEventListener('mousedown', onMouseDown);
      contentEle.removeEventListener('mouseenter', peek);
      resizableEle.removeEventListener('mouseleave', unpeek);
    };
  }, [
    activeHandleStyle,
    inactiveHandleStyle,
    maxSize,
    minSize,
    onResizeEnd,
    isCollapsed,
    onCollapseClick,
    size,
  ]);

  useEffect(() => {
    const resizableEle = resizableRef.current;
    const handleEle = handleRef.current;
    if (!resizableEle || !handleEle) return;
    if (isCollapsed) {
      resizableEle.style.width = RESIZABLE_COLLAPSED_WIDTH;
      handleEle.style.pointerEvents = 'none';
    } else resizableEle.style.width = `${size}px`;
  }, [isCollapsed, size]);

  return (
    <Flex ref={resizableRef} w={size} {...containerProps}>
      <Flex ref={contentRef} overflow="hidden" {...rest} w="full">
        {children}
      </Flex>
      <Flex
        cursor="ew-resize"
        position="relative"
        role="group"
        ref={handleContainerRef}
      >
        <Divider
          className="handle-divider"
          orientation="vertical"
          transition="border-color 0.05s ease-out"
          ref={handleRef}
          px="12px"
          _hover={{
            ...activeHandleStyle,
            pl: '11px',
          }}
          {...inactiveHandleStyle}
        />
        <Box
          position="absolute"
          top={10}
          left="-10px"
          borderRadius={20}
          boxShadow="md"
          bg="white"
          width={6}
          height={6}
          cursor="pointer"
          color="gray.600"
          transition="background 0.1s ease-out, color 0.1s ease-out"
          onClick={onCollapseClick}
          _hover={{
            bg: 'primary.500',
            color: 'white',
          }}
        >
          <Text
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            fontWeight={600}
          >
            {isCollapsed ? '>' : '<'}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
};
