import { useCallback } from 'react';
import { ParticipationsManualUpdater } from '../../queries';
import {
  useCurrentBoardId,
  useCurrentUser,
  useToast,
  TOAST_ERROR_GENERIC,
  FIVE_SECONDS_MS,
  sleep,
  log,
} from '@spoke/common';
import {
  useBroadcastBoardMessageMutation,
  useResetBoardVotesMutation,
  BoardParticipation,
} from '@spoke/graphql';

export type UseResetAllVotesHandlerArgs = {
  manuallyUpdateParticipations: ParticipationsManualUpdater;
};

export const useResetAllVotesHandler = ({
  manuallyUpdateParticipations,
}: UseResetAllVotesHandlerArgs) => {
  const [boardId] = useCurrentBoardId();
  const [currentUser] = useCurrentUser();
  const [broadcastBoardMessage] = useBroadcastBoardMessageMutation();
  const [resetBoardVotes] = useResetBoardVotesMutation();

  const [toast] = useToast();

  const handleResetAllVotes = useCallback(async () => {
    const userId = currentUser?.id;

    if (!boardId || !userId) {
      log.error('Missing critical data to reset board votes', {
        boardId,
        userId,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    log.info('Resetting board vote count', { boardId, userId });

    await broadcastBoardMessage({
      variables: {
        boardMessageBroadcastInput: {
          boardId,
          title: 'Reset votes',
          timeOut: FIVE_SECONDS_MS,
          message:
            'The facilitator has reset the votes. All vote counts will be set to 0 in 5 seconds.',
        },
      },
    });

    await sleep(FIVE_SECONDS_MS);

    const { errors } = await resetBoardVotes({
      variables: { boardId },
    });

    if (errors) {
      log.error('Reset board vote count responded with errors', {
        errors,
        boardId,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    manuallyUpdateParticipations((prev: BoardParticipation[]) =>
      prev.map((part) => ({ ...part, numberOfVotes: 0 }))
    );
  }, [
    boardId,
    broadcastBoardMessage,
    currentUser?.id,
    manuallyUpdateParticipations,
    resetBoardVotes,
    toast,
  ]);

  return [handleResetAllVotes];
};
