import {
  UnorderedList as ChakraUnorderedList,
  ListProps as ChakraUnorderedListProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = ChakraUnorderedListProps;

export const UnorderedList = forwardRef<HTMLUListElement, Props>(
  (props, ref) => <ChakraUnorderedList {...props} ref={ref} />
);
