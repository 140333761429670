import { Button } from '@chakra-ui/react';
import { SpkTime, isNullish, NUMBER_ONLY } from '@spoke/common';
import { FC, useEffect, useRef, useState } from 'react';
import { BsGlobe } from 'react-icons/bs';
import { Flex } from '../Flex';
import { Icon } from '../Icon';
import { HStack } from '../Stack/HStack';
import { Text } from '../Text';
import { Input, InputProps } from './Input';
import { InputGroup } from './InputGroup';
import { InputRightElement } from './InputRightElement';

const DAY_IN_MS = 86400000;
const NOON_IN_MS = 43200000;
const HOUR_IN_MS = 60 * 60 * 1000;
const MINUTE_IN_MS = 60 * 1000;

type TimeInputProps = Omit<InputProps, 'value' | 'onChange'> & {
  value: number | null;
  onChange: (value: number | null) => void;
};

const coerceToValidDisplayString = (str: string) => {
  let coerced = `${str}`;
  let hours = coerced.split(':')[0];
  let minutes = coerced.split(':')[1];

  // First digit can only be either 0 or 1, also can't go higher than 12
  if (
    hours?.length &&
    (parseInt(hours) > 12 || (hours[0] !== '0' && hours[0] !== '1'))
  ) {
    hours = '12';
    coerced = hours + coerced.slice(2);
  }

  // 00:xx is not valid, we coerce to 01:xx
  if (hours?.length && hours[0] === '0' && hours[1] === '0') {
    hours = '01';
    coerced = hours + coerced.slice(2);
  }

  // Minutes can't go higher than 59
  if (minutes?.length && (parseInt(minutes) > 59 || minutes[0] > '5')) {
    minutes = '59';
    coerced = coerced.slice(0, 3) + minutes;
  }

  return coerced;
};

const getMsValueFromStr = (str: string, amPm: 'am' | 'pm') => {
  const hours = parseInt(str?.split(':')[0]?.padStart(2, '0') || '0');
  const minutes = parseInt(str?.split(':')[1]?.padEnd(2, '0') || '0');
  // 12 hours actually means 0 hours in this bullshit standard
  // https://en.wikipedia.org/wiki/12-hour_clock#Confusion_at_noon_and_midnight
  const amountOfHours = (hours === 12 ? 0 : hours) + (amPm === 'pm' ? 12 : 0);
  const ms =
    amountOfHours * HOUR_IN_MS +
    minutes * MINUTE_IN_MS -
    SpkTime.getUtcOffsetMs();
  return ms;
};

const utcTimeMsToLocalTimeMs = (utcTimeMs: number) => {
  const localTimeMs = utcTimeMs + SpkTime.getUtcOffsetMs();
  const rolledOverLocalTimeMs =
    localTimeMs > 0 ? localTimeMs : DAY_IN_MS + localTimeMs;

  return rolledOverLocalTimeMs;
};

const getStrFromMsValue = (utcMs: number) => {
  const rolledOverLocalTimeMs = utcTimeMsToLocalTimeMs(utcMs);
  const hours = Math.floor(rolledOverLocalTimeMs / (60 * 60 * 1000)) % 12;
  const minutes = Math.floor((rolledOverLocalTimeMs / (60 * 1000)) % 60);
  const actualHours = hours === 0 ? 12 : hours;
  const paddedHours = actualHours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padEnd(2, '0');
  const newStringValue = `${paddedHours}:${paddedMinutes}`;
  const validStringValue = coerceToValidDisplayString(newStringValue);
  return validStringValue;
};

/**
 * Output is miliseconds from midnight
 */
export const TimeInput: FC<TimeInputProps> = ({ value, onChange, ...rest }) => {
  const [amPm, setAmPm] = useState<'am' | 'pm'>('am');

  const [stringValue, setStringValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newStringValue = e.target.value;

    const newCharacter = newStringValue.replace(/:/g, '').split('').pop();
    if (newCharacter && !newCharacter.match(NUMBER_ONLY)) return;

    if (newStringValue[1] === ':') return; // Deleting text backwards

    if (newStringValue.length > 5) return;

    // Adding colon
    if (newStringValue.length >= 3 && newStringValue[2] !== ':') {
      newStringValue =
        newStringValue.slice(0, 2) + ':' + newStringValue.slice(2);
    }

    // Removing colon
    if (newStringValue[newStringValue.length - 1] === ':') {
      newStringValue = newStringValue.slice(0, newStringValue.length - 1);
    }

    const validStringValue = coerceToValidDisplayString(newStringValue);

    setStringValue(validStringValue);
  };

  // Pushing ms value upwards (keeping parent state up to date)
  useEffect(() => {
    if (!stringValue) {
      onChange(null);
      return;
    }

    const msValue = getMsValueFromStr(stringValue, amPm);

    onChange(msValue);
  }, [stringValue, amPm, onChange]);

  // Pulling parent state downwards (keeping component state up to date)
  useEffect(() => {
    if (isNullish(value)) {
      setStringValue('');
      setAmPm('am');
      return;
    }

    const inputValue = inputRef.current?.value || '';

    const internalMsValue = getMsValueFromStr(inputValue, amPm);

    if (Number.isNaN(internalMsValue)) return;
    if (internalMsValue === value) return;

    const newStringValue = getStrFromMsValue(value!);
    const localMs = utcTimeMsToLocalTimeMs(value!);

    setStringValue(newStringValue);
    setAmPm(localMs > NOON_IN_MS ? 'pm' : 'am');

    // We don't mind for the updated amPm value (since amPm changes are upwards always)
    // and we don't want to trigger this effect
    // otherwise both push and pull would be triggered at once and we get an infinite loop
    // Feel free to refactor if you find a better solution than pushing/pulling a separate state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const fillMissingZeroes = () => {
    if (!stringValue) return;
    const hours = stringValue.split(':')[0]?.padEnd(2, '0') || '01';
    const minutes = stringValue.split(':')[1]?.padEnd(2, '0') || '00';
    const newSringValue = `${hours}:${minutes}`;
    setStringValue(newSringValue);
  };

  return (
    <Flex flexDir="column">
      <InputGroup>
        <Input
          placeholder={rest.placeholder ?? '09:00'}
          textAlign="left"
          value={stringValue}
          onChange={handleInputChange}
          ref={inputRef}
          onBlur={fillMissingZeroes}
          {...rest}
        />
        <InputRightElement mr={0} pr={8} pt={1} gap={1} pointerEvents="all">
          <Button
            fontSize={10}
            px={1}
            bg={amPm === 'am' ? 'primary.400' : 'gray.100'}
            color={amPm === 'am' ? 'white' : 'gray.500'}
            borderRadius={2}
            size="xs"
            variant="unstyled"
            onClick={() => setAmPm('am')}
          >
            AM
          </Button>
          <Button
            fontSize={10}
            px={1}
            bg={amPm === 'pm' ? 'primary.400' : 'gray.100'}
            borderRadius={2}
            color={amPm === 'pm' ? 'white' : 'gray.500'}
            size="xs"
            variant="unstyled"
            onClick={() => setAmPm('pm')}
          >
            PM
          </Button>
        </InputRightElement>
      </InputGroup>
      <HStack mt={1} spacing={1}>
        <Icon as={BsGlobe} color="gray.500" w="15px" h="15px" />
        <Text
          textAlign="left"
          fontSize={12}
          color="gray.500"
          fontWeight={500}
          pt="3px"
          ml={1}
        >
          {SpkTime.getUserTimezoneString()}
        </Text>
      </HStack>
    </Flex>
  );
};
