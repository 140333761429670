import { useToken } from '@chakra-ui/react';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import React, { MouseEventHandler, useMemo, useState } from 'react';
import useMeasure from 'react-use-measure';
import {
  defaultStyles as defaultTooltipStyle,
  useTooltip,
  useTooltipInPortal,
} from '@visx/tooltip';
import { FlexProps, Flex } from '@spoke/common';

type HistogramData = {
  value: number;
  from: number;
  to: number;
};

type TooltipData = HistogramData;

type Props = FlexProps & {
  data: HistogramData[];
  yLabel?: string;
  xLabel?: string;
  tip?: string;
  fontSize?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  getTooltipText?: (data: TooltipData) => string;
};

const tooltipStyles = {
  ...defaultTooltipStyle,
  color: '#222',
  // minWidth: 60,
  // backgroundColor: 'white'
  // color: 'white',
};

let tooltipTimeout: number;

export const Histogram: React.FC<Props> = ({
  data,
  xLabel,
  yLabel,
  tip,
  fontSize = 13,
  getTooltipText,
  ...rest
}) => {
  const [ref, bounds] = useMeasure();

  const [primary25, primary50, primary75, primary200, gray400, gray500] =
    useToken('colors', [
      'primary.25',
      'primary.50',
      'primary.75',
      'primary.200',
      'gray.400',
      'gray.500',
    ]);

  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    hideTooltip,
    showTooltip,
  } = useTooltip<TooltipData>();

  const { containerRef: tooltipContainerRef, TooltipInPortal } =
    useTooltipInPortal({
      // TooltipInPortal is rendered in a separate child of <body /> and positioned
      // with page coordinates which should be updated on scroll. consider using
      // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
      scroll: true,
    });

  const width = bounds.width || 100;
  const height = bounds.height || 100;

  const MARGIN = {
    top: 0,
    bottom: 0.18 * height,
    right: 0.0 * width,
    left: 0.03 * width,
  };
  const BAR_TIP_HEIGHT = 0.015 * height;
  const BAR_TIP_GAP = BAR_TIP_HEIGHT * 0.7;
  const BAR_GAP = 0.002 * width;
  const FONT_SIZE = fontSize;

  const xScale = useMemo(
    () =>
      scaleLinear({
        domain: [
          Math.min(...data.map((d) => d.from)),
          Math.max(...data.map((d) => d.to)),
        ],
        range: [MARGIN.left, width - MARGIN.right],
      }),
    [MARGIN.left, MARGIN.right, data, width]
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [0, Math.max(...data.map((d) => d.value))],
        range: [height - MARGIN.bottom, MARGIN.top],
      }),
    [MARGIN.bottom, MARGIN.top, data, height]
  );

  const bars = data.map(({ from, to, value }) => ({
    x: xScale(from),
    y: yScale(value),
    width: xScale(to) - xScale(from) - BAR_GAP,
    height: yScale(0) - yScale(value),
    from,
    to,
    value,
  }));

  const yLabelX = MARGIN.left * 0.7;
  const yLabelY = height - MARGIN.bottom * 1;

  const xLabelY = height - MARGIN.bottom + FONT_SIZE * 1.7;
  const [focusedBarIdx, setFocusedBarIdx] = useState<number | null>(null);

  const onMouseLeave = () => {
    tooltipTimeout = window.setTimeout(() => {
      setFocusedBarIdx(null);
      hideTooltip();
    }, 20);
  };

  const onMouseMove: MouseEventHandler<SVGElement> = (event) => {
    if (tooltipTimeout) clearTimeout(tooltipTimeout);
    const eventSvgCoords = localPoint(event);
    if (!eventSvgCoords) return;
    const bar = bars.find(
      (b) => b.x <= eventSvgCoords.x && b.x + b.width >= eventSvgCoords.x
    );
    if (!bar) return onMouseLeave();
    const barIdx = bars.indexOf(bar);
    setFocusedBarIdx(barIdx);
    const TOOLTIP_HEIGHT = 40;
    showTooltip({
      tooltipData: bar,
      tooltipTop: eventSvgCoords.y - TOOLTIP_HEIGHT,
      tooltipLeft: bar.x,
    });
  };

  return (
    <Flex
      width="full"
      height="full"
      alignItems="center"
      justifyContent="center"
      ref={ref}
      {...rest}
    >
      <svg
        ref={tooltipContainerRef}
        width={width}
        height={height}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
      >
        <Group>
          {bars.map((bar, i) => {
            const noneIsFocused = focusedBarIdx === null;
            const isFocused = focusedBarIdx === i;
            const color = noneIsFocused
              ? primary75
              : isFocused
              ? primary200
              : primary50;

            const tipColor = noneIsFocused
              ? primary200
              : isFocused
              ? primary200
              : primary50;

            return (
              <Group key={`bar-${i}`}>
                {isFocused && (
                  <Bar
                    x={bar.x}
                    y={0}
                    width={bar.width}
                    height={height - MARGIN.bottom}
                    fill={primary25}
                  />
                )}
                <Bar
                  x={bar.x}
                  y={bar.y + BAR_TIP_HEIGHT + BAR_TIP_GAP}
                  width={bar.width}
                  height={bar.height}
                  fill={color}
                  style={{ transition: 'fill 0.1s ease-out' }}
                />
                <Bar
                  x={bar.x}
                  y={bar.y + (bar.value === 0 ? BAR_TIP_GAP : 0)}
                  width={bar.width}
                  height={BAR_TIP_HEIGHT}
                  fill={tipColor}
                  style={{ transition: 'fill 0.1s ease-out' }}
                />
              </Group>
            );
          })}
        </Group>
        {yLabel && (
          <text
            textAnchor="left"
            fill={gray400}
            fontWeight={500}
            fontSize={FONT_SIZE}
            transform={`rotate(-90, ${yLabelX}, ${yLabelY})`}
            x={yLabelX}
            y={yLabelY}
          >
            {yLabel}
          </text>
        )}
        {xLabel && (
          <text
            textAnchor="start"
            fill={gray400}
            fontWeight={500}
            fontSize={FONT_SIZE}
            x={MARGIN.left}
            y={xLabelY}
          >
            {xLabel}
          </text>
        )}
        {tip && (
          <text
            textAnchor="end"
            fill={gray400}
            fontWeight={500}
            fontSize={FONT_SIZE}
            x={width - MARGIN.right * 0.7}
            y={xLabelY}
          >
            <tspan fontWeight={600} fill={gray500}>
              Tip:{' '}
            </tspan>
            {tip}
          </text>
        )}
      </svg>
      {tooltipOpen && tooltipData && getTooltipText && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
        >
          {getTooltipText(tooltipData)}
        </TooltipInPortal>
      )}
    </Flex>
  );
};
