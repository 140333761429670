import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  optimisticArchiveActionItemComment,
  archiveActionItemCommentUpdateFunction,
  TOAST_ERROR_GENERIC,
  log,
  useToast,
} from '@spoke/common';
import { useDeleteActionItemCommentMutation } from '@spoke/graphql';

export type DeleteActionItemCommendHandler = (
  commentId: string
) => Promise<void>;

export const useDeleteActionItemCommentHandler = () => {
  const [archiveComment] = useDeleteActionItemCommentMutation();
  const [toast] = useToast();
  const { cache } = useApolloClient();

  const handleDeleteActionItemComment = useCallback(
    async (commentId: string): Promise<boolean> => {
      const { errors } = await archiveComment({
        variables: { commentId },
        optimisticResponse: optimisticArchiveActionItemComment({
          commentId,
          cache,
        }),
        update: archiveActionItemCommentUpdateFunction,
      });

      if (errors) {
        log.error(
          'UpdateActionItemComment (archive) call responded with error',
          {
            errors,
          }
        );
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return false;
    },
    [archiveComment, cache, toast]
  );

  return [handleDeleteActionItemComment];
};
