import { FC } from 'react';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { MdOutlineCropFree, MdOutlineViewColumn } from 'react-icons/md';
import { SpkTime } from '../../SpkTime';
import { BoardType } from '../../types';
import {
  FlexProps,
  Flex,
  Tooltip,
  VStack,
  HStack,
  Badge,
  Icon,
  Image,
  Text,
} from '../../ui';

export type BoardInfoCardProps = FlexProps & {
  iconUrl?: string | null;
  formatName?: string | null;
  boardName?: string;
  boardType?: string;
  createdAt?: number | null;
};

export const BoardInfoCard: FC<BoardInfoCardProps> = ({
  iconUrl,
  formatName,
  boardName,
  createdAt,
  boardType,
  ...rest
}) => (
  <Flex layerStyle="outlineGray" {...rest}>
    {Boolean(iconUrl && formatName) && (
      <Tooltip label={formatName} placement="top" variant="white">
        <Image
          src={iconUrl ?? ''}
          alt={formatName ?? ''}
          w={14}
          h={14}
          mr={3}
        />
      </Tooltip>
    )}
    <VStack spacing={1} alignItems="start">
      <Text fontWeight={500} color="gray.700">
        {boardName}
      </Text>
      <HStack>
        <Badge variant="lightGray">
          <Icon mb="3px" as={AiTwotoneCalendar} />
          <Text>
            {SpkTime.format(new Date(createdAt || 0), 'MMM dd, yyyy')}
          </Text>
        </Badge>
        <Badge variant="lightGray">
          <Icon
            mb="3px"
            as={
              boardType === BoardType.Column
                ? MdOutlineViewColumn
                : MdOutlineCropFree
            }
          />
          {boardType === BoardType.Column ? 'Column Retro' : 'Canvas Retro'}
        </Badge>
      </HStack>
    </VStack>
  </Flex>
);
