import { AnimatePresence } from 'framer-motion';
import { FC, MutableRefObject, useCallback, useRef, useState } from 'react';
import {
  useTeamTree,
  useSwitchTeamHandler,
  useCurrentOrgTeam,
  useCurrentTeam,
} from '../../queries';
import { MemoizedTeamBreadcrumb } from './TeamBreadcrumb';
import { TeamSwitcher } from './TeamSwitcher';
import { findTeamPath, TeamSwitcherTreeNodeType } from './teamSwitcherUtil';
import { MinimalTeamFragmentFragment, TeamType } from '@spoke/graphql';
import {
  useDisclosure,
  useOutsideClick,
  Popover,
  Skeleton,
  PopoverContent,
  PopoverArrow,
  Box,
} from '@spoke/common';

export const TeamBreadcrumbs: FC = () => {
  const [{ teamTree, watchedTeamTree }] = useTeamTree();
  const [handleSwitchTeam] = useSwitchTeamHandler();
  const [currentOrgTeam] = useCurrentOrgTeam();
  const [currentTeam] = useCurrentTeam();

  const [loadingTeamId, setLoadingTeamId] = useState<string | null>(null);

  const breadcrumbRefs = useRef<Record<string, HTMLDivElement>>({});
  const teamSwitcherRef = useRef<HTMLDivElement>(null);
  const teamSwitcherPopover = useDisclosure();

  const selectedBreadcrumbRef: MutableRefObject<HTMLDivElement>[] =
    currentTeam?.id && breadcrumbRefs?.current?.[currentTeam.id]
      ? [{ current: breadcrumbRefs?.current?.[currentTeam.id as string] }]
      : [];

  useOutsideClick(
    teamSwitcherPopover.close,
    teamSwitcherRef,
    ...selectedBreadcrumbRef
  );

  const onSwitchTeam = useCallback(
    async (teamId: string) => {
      setLoadingTeamId(teamId);
      await handleSwitchTeam({ teamId });
      setLoadingTeamId(null);
    },
    [handleSwitchTeam]
  );

  const currentTeamId = currentTeam?.id;

  const fullTeamTree: TeamSwitcherTreeNodeType | null =
    teamTree && watchedTeamTree && currentOrgTeam
      ? ({
          team: currentOrgTeam as MinimalTeamFragmentFragment,
          children: teamTree?.concat(watchedTeamTree),
          isWatcher: false,
        } as TeamSwitcherTreeNodeType)
      : null;

  const teamPath =
    fullTeamTree && currentTeamId
      ? findTeamPath([fullTeamTree], currentTeamId)
      : [];

  const isLoading = Boolean(!fullTeamTree || !currentTeamId);

  return (
    <Popover
      isOpen={teamSwitcherPopover.isOpen}
      placement="bottom"
      offset={[0, 8]}
    >
      <Box id="team-breadcrumbs">
        <Skeleton
          display="flex"
          alignItems="center"
          isLoaded={!isLoading}
          minW={isLoading ? 250 : 0}
          h={8}
        >
          <AnimatePresence>
            {teamPath.map((team, idx, arr) => (
              <MemoizedTeamBreadcrumb
                key={team.id}
                isLast={idx === arr.length - 1}
                isLoading={loadingTeamId === team.id}
                isCurrent={currentTeamId === team.id}
                onToggleTeamTree={teamSwitcherPopover.toggle}
                onSwitchTeam={onSwitchTeam}
                breadcrumbRefs={breadcrumbRefs}
                teamId={team.id as string}
                teamName={team.name as string}
                teamType={team.type as TeamType}
              />
            ))}
          </AnimatePresence>
          <PopoverContent>
            <PopoverArrow />
            <Box ref={teamSwitcherRef}>
              <TeamSwitcher
                afterTeamSwitch={teamSwitcherPopover.close}
                treeMaxHeight={400}
              />
            </Box>
          </PopoverContent>
        </Skeleton>
      </Box>
    </Popover>
  );
};
