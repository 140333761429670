import { DEFAULT_META_DESCRIPTION } from '@spoke/common';
import Head from 'next/head';
import { FC, PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  title?: string;
  metaDescription?: string;
}>;

export const SpkHead: FC<Props> = ({
  children,
  title,
  metaDescription,
}: Props) => (
  <Head>
    <title>{title ? `${title} | ` : null}ScatterSpoke</title>
    <link rel="icon" href="/favicon.ico" />
    <meta
      name="description"
      content={metaDescription ?? DEFAULT_META_DESCRIPTION}
    />
    {children}
  </Head>
);
