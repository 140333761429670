import { useCallback, useState } from 'react';

/**
 * @deprecated This is an anti-pattern and must be used only as last resort.
 */
export const useForceRender = () => {
  const [, dummyState] = useState({});
  const render = useCallback(() => dummyState({}), []);
  return render;
};
