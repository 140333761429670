import { useToken } from '@chakra-ui/react';
import { callIfExists, ONE_WEEK_MS, SpkTime } from '@spoke/common';
import { FC, useCallback, useMemo } from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

export type WeekPickerProps = {
  value?: Date | null;
  onChange?: (newDate: Date | null) => void;
};
export const WeekPicker: FC<WeekPickerProps> = ({ onChange, value }) => {
  const onSelect = useCallback(
    (newValue: Date) => {
      const startOfWeek = newValue
        ? SpkTime.getStartOf('week', newValue)
        : null;
      callIfExists(onChange, startOfWeek);
    },
    [onChange]
  );

  const [primary400, gray75] = useToken('colors', ['primary.400', 'gray.75']);

  const css = useMemo(
    () => `
      :root {
        --rdp-cell-size: 40px;
        --rdp-accent-color: ${primary400};
        --rdp-background-color: #e7edff;
        /* Switch to dark colors for dark themes */
        --rdp-accent-color-dark: ${primary400};
        --rdp-background-color-dark: ${primary400};
        /* Outline border for focused elements */
        --rdp-outline: 2px solid var(--rdp-accent-color);
        /* Outline border for focused and selected elements */
        --rdp-outline-selected: 2px solid ${primary400};
      }

      .today {
        color: ${primary400};
        font-weight: 600;
      }

      .rdp-row:hover > td > button:not(.rdp-day_selected) {
        cursor: pointer;
        background: ${gray75};
        border-radius: 0px;
      }

      .rdp-row:hover > td > button:focus, .rdp-row:hover > td > button:active {
        border: none;
      }

      .rdp-row:hover > td:first-child > button:not(.rdp-day_selected) {
        border-top-left-radius: 100%;
        border-bottom-left-radius: 100%;
      }

      .rdp-row:hover > td:last-child > button:not(.rdp-day_selected) {
        border-top-right-radius: 100%;
        border-bottom-right-radius: 100%;
      }
    
    `,
    [primary400, gray75]
  );

  const valueFrom = value ?? undefined;
  const valueTo = value
    ? new Date(value.getTime() + ONE_WEEK_MS - 1)
    : undefined;
  const calendarValue = valueFrom
    ? { from: valueFrom, to: valueTo }
    : undefined;

  return (
    <>
      <style>{css}</style>
      <DayPicker
        fromYear={2015}
        toYear={2025}
        mode="range"
        weekStartsOn={1}
        selected={calendarValue}
        onDayClick={onSelect}
        modifiersClassNames={{
          today: 'today',
        }}
      />
    </>
  );
};
