import { FC, ReactElement } from 'react';
import { callIfExists } from '../util';
import { Button } from './Button';
import { Image } from './Image';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from './Modal';
import { Text } from './Text';

/**
 * Confirmation modal abstraction for the most common use cases.
 * For more specific use cases, use @components/ui/Modal/Modal.tsx instead.
 */
type ConfirmationModalProps = {
  image?: string | ReactElement;
  title?: string | ReactElement;
  text?: string | ReactElement;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => unknown;
  onCancel?: () => unknown;
  isOpen: boolean;
  close: () => unknown;
  closeOnCancel?: boolean;
  imageSize?: number;
};
export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  closeOnCancel = true,
  imageSize = 180,
  close,
  isOpen,
  cancelText,
  confirmText,
  image,
  onCancel,
  onConfirm,
  text,
  title,
  children,
}) => {
  const handleCancel = () => {
    callIfExists(onCancel);
    if (closeOnCancel) close();
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent maxW={550}>
        <ModalCloseButton />
        <ModalBody
          alignItems="center"
          textAlign="center"
          display="flex"
          flexDir="column"
          pt={10}
          px={16}
        >
          {typeof image === 'object' ? (
            image
          ) : (
            <Image src={image} alt="" w={imageSize} />
          )}
          {typeof title === 'object' ? (
            title
          ) : (
            <Text color="gray.800" fontWeight={600} fontSize={18} mt={4} mb={2}>
              {title}
            </Text>
          )}
          {typeof text === 'object' ? (
            text
          ) : (
            <Text color="gray.600" fontSize={16} mb={2}>
              {text}
            </Text>
          )}
          {children}
        </ModalBody>
        <ModalFooter justifyContent="center" gap={3} pt={0} pb={8}>
          <Button
            size="lg"
            fontSize={16}
            variant="outlineGray"
            onClick={handleCancel}
          >
            {cancelText || 'Cancel'}
          </Button>
          <Button size="lg" fontSize={16} onClick={onConfirm}>
            {confirmText || 'Confirm'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
