import { MutationUpdaterFn } from '@apollo/client';
import { randomString, uniqueBy } from '../../../etc';
import { log } from '../../../../SpkLog';
import {
  CreateParkingLotItemFromCardIdMutation,
  CreateParkingLotItemMutation,
  ParkingLotByTeamIdAndTypeDocument,
  ParkingLotByTeamIdAndTypeQuery,
  ParkingLotByTeamIdAndTypeQueryVariables,
  ParkingLotItem,
  Team,
  User,
} from '@spoke/graphql';

type OptimisticCreateParkingLotItemArgs = { text: string; teamId: string };
export const optimisticCreateParkingLotItem = ({
  text,
  teamId,
}: OptimisticCreateParkingLotItemArgs): ParkingLotItem => ({
  __typename: 'ParkingLotItem',
  id: randomString(),
  archived: false,
  author: { id: randomString() } as User,
  children: [],
  createdAt: Date.now(),
  team: { id: teamId } as Team,
  text,
  votes: 0,
});

export const createParkingLotItemUpdateFunction: MutationUpdaterFn<
  CreateParkingLotItemMutation | CreateParkingLotItemFromCardIdMutation
> = (cache, { data }) => {
  const newParkingLotItem =
    data && 'createParkingLotCard' in data
      ? data.createParkingLotCard
      : data?.createParkingLotItemFromCardId;

  if (!newParkingLotItem) {
    log.error(
      'No parkingLotItem received in createParkingLotItemUpdateFunction',
      {
        newCard: newParkingLotItem,
      }
    );
    return;
  }

  const variables: ParkingLotByTeamIdAndTypeQueryVariables = {
    teamId: newParkingLotItem.team.id || '',
  };

  cache.updateQuery<ParkingLotByTeamIdAndTypeQuery>(
    { query: ParkingLotByTeamIdAndTypeDocument, variables },
    (cacheData) => ({
      ...(cacheData || {}),
      parkingLotByTeamId: uniqueBy(
        [newParkingLotItem, ...(cacheData?.parkingLotByTeamId || [])],
        'id'
      ),
    })
  );
};
