import { Grid, SearchBar, SearchContext } from '@giphy/react-components';
import type { IGif } from '@giphy/js-types';
import { FC, SyntheticEvent, useContext } from 'react';
import { Box } from './Box';
import { CSSObject } from '@chakra-ui/react';

function getAtualGiphyDirectLink(gifId: string): string {
  return 'https://i.giphy.com/media/' + gifId + '/giphy.gif';
}

const HIDE_GIF_OVERLAYS: CSSObject = {
  '& div[style="opacity: 0;"]': {
    display: 'none',
  },
  '& div[style="opacity: 1;"]': {
    display: 'none',
  },
};

type GiphySearchProps = {
  onSelectGif: (gifUrl: string) => void;
};
export const GiphySearch: FC<GiphySearchProps> = ({ onSelectGif }) => {
  const { fetchGifs, channelSearch, term, activeChannel } =
    useContext(SearchContext);

  const handleGifClick = (gif: IGif, event: SyntheticEvent) => {
    event.preventDefault();
    const url = getAtualGiphyDirectLink(gif.id as string);
    onSelectGif(url);
  };

  return (
    <Box
      maxW={500}
      gap={2}
      display="flex"
      flexDir="column"
      borderRadius="lg"
      overflow="hiidden"
      sx={HIDE_GIF_OVERLAYS}
    >
      <SearchBar />
      <Box maxH={400} overflowY="scroll">
        <Grid
          key={`${channelSearch} ${term} ${activeChannel?.user.username}`}
          columns={3}
          width={300}
          fetchGifs={fetchGifs}
          onGifClick={handleGifClick}
        />
      </Box>
    </Box>
  );
};
