import { cloneDeep } from '@apollo/client/utilities';
import { SortBoardType } from '../../../../types';
import { List, SortBoardMutation } from '@spoke/graphql';

type OptimisitcSortBoardArgs = {
  lists: List[];
  type: SortBoardType;
};

export const optimisticSortBoard = ({
  lists: _lists,
  type,
}: OptimisitcSortBoardArgs): SortBoardMutation => {
  const lists = cloneDeep(_lists) as List[];

  const { Newest, Oldest, Votes } = SortBoardType;

  for (const list of lists) {
    if (type === Votes) {
      list.cards?.sort(
        (card1, card2) =>
          Number(card2?.votes?.length) - Number(card1?.votes?.length)
      );
    } else if (type === Newest) {
      list.cards?.sort(
        (card1, card2) =>
          new Date(card2?.createdAt || 0).getTime() -
          new Date(card1?.createdAt || 0).getTime()
      );
    } else if (type === Oldest) {
      list.cards?.sort(
        (card1, card2) =>
          new Date(card1?.createdAt || 0).getTime() -
          new Date(card2?.createdAt || 0).getTime()
      );
    }
  }

  return {
    __typename: 'Mutation',
    sortBoard: lists,
  };
};
