import { BoxProps } from '@chakra-ui/react';
import { FC } from 'react';
import { Box } from '@spoke/common';

export const OnboardingLayout: FC<BoxProps> = ({ children, ...props }) => (
  <Box
    background="gray.50"
    minH="100vh"
    maxW="100vw"
    overflowX="hidden"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    padding="40px"
    {...props}
  >
    {children}
  </Box>
);
