import { IconProps, Icon } from '../Icon';

export const ProgramsIcon = (props: IconProps) => (
  <Icon viewBox="0 0 25 25" {...props}>
    <title>9945F704-26FB-4A30-9FD7-9771B9B3E870</title>
    <desc>Created with sketchtool.</desc>
    <g
      id="Dashboard"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Dashboard_team"
        transform="translate(-145.000000, -299.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <g id="Group-12" transform="translate(100.000000, 176.000000)">
          <g id="Group-35" transform="translate(0.000000, 87.000000)">
            <g id="Group-34" transform="translate(0.000000, 36.000000)">
              <g id="Group-33" transform="translate(45.000000, 0.000000)">
                <g id="Group-40">
                  <g id="Group-6" transform="translate(2.000000, 4.000000)">
                    <path
                      d="M10.5,4 C11.0128358,4 11.4355072,4.38604019 11.4932723,4.88337887 L11.5,5 L11.5,7.5119876 C11.5,8.06427235 11.0522847,8.5119876 10.5,8.5119876 C9.98716416,8.5119876 9.56449284,8.12594741 9.50672773,7.62860873 L9.5,7.5119876 L9.5,5 C9.5,4.44771525 9.94771525,4 10.5,4 Z"
                      id="Path-4"
                    />
                    <path
                      d="M10.5,7.2440062 C11.0128358,7.2440062 11.4355072,7.63004639 11.4932723,8.12738507 L11.5,8.2440062 L11.5,11.5119876 C11.5,12.0642724 11.0522847,12.5119876 10.5,12.5119876 C9.98716416,12.5119876 9.56449284,12.1259474 9.50672773,11.6286087 L9.5,11.5119876 L9.5,8.2440062 C9.5,7.69172145 9.94771525,7.2440062 10.5,7.2440062 Z"
                      id="Path-4-Copy"
                    />
                    <path
                      d="M16.5,7 C17.5543618,7 18.4181651,7.81587779 18.4945143,8.85073766 L18.5,9 L18.5,12 C18.5,12.5522847 18.0522847,13 17.5,13 C16.9871642,13 16.5644928,12.6139598 16.5067277,12.1166211 L16.5,12 L16.5,9 L4.5,9 L4.5,12 C4.5,12.5522847 4.05228475,13 3.5,13 C2.98716416,13 2.56449284,12.6139598 2.50672773,12.1166211 L2.5,12 L2.5,9 C2.5,7.9456382 3.31587779,7.08183488 4.35073766,7.00548574 L4.5,7 L16.5,7 Z"
                      id="Path"
                    />
                    <path
                      d="M10.5,0 C8.84314575,0 7.5,1.34314575 7.5,3 C7.5,4.65685425 8.84314575,6 10.5,6 C12.1568542,6 13.5,4.65685425 13.5,3 C13.5,1.34314575 12.1568542,0 10.5,0 Z M10.5,2 C11.0522847,2 11.5,2.44771525 11.5,3 C11.5,3.55228475 11.0522847,4 10.5,4 C9.94771525,4 9.5,3.55228475 9.5,3 C9.5,2.44771525 9.94771525,2 10.5,2 Z"
                      id="Oval"
                    />
                    <path
                      d="M3.5,11 C1.84314575,11 0.5,12.3431458 0.5,14 C0.5,15.6568542 1.84314575,17 3.5,17 C5.15685425,17 6.5,15.6568542 6.5,14 C6.5,12.3431458 5.15685425,11 3.5,11 Z M3.5,13 C4.05228475,13 4.5,13.4477153 4.5,14 C4.5,14.5522847 4.05228475,15 3.5,15 C2.94771525,15 2.5,14.5522847 2.5,14 C2.5,13.4477153 2.94771525,13 3.5,13 Z"
                      id="Oval"
                    />
                    <path
                      d="M10.5,11 C8.84314575,11 7.5,12.3431458 7.5,14 C7.5,15.6568542 8.84314575,17 10.5,17 C12.1568542,17 13.5,15.6568542 13.5,14 C13.5,12.3431458 12.1568542,11 10.5,11 Z M10.5,13 C11.0522847,13 11.5,13.4477153 11.5,14 C11.5,14.5522847 11.0522847,15 10.5,15 C9.94771525,15 9.5,14.5522847 9.5,14 C9.5,13.4477153 9.94771525,13 10.5,13 Z"
                      id="Oval"
                    />
                    <path
                      d="M17.5,11 C15.8431458,11 14.5,12.3431458 14.5,14 C14.5,15.6568542 15.8431458,17 17.5,17 C19.1568542,17 20.5,15.6568542 20.5,14 C20.5,12.3431458 19.1568542,11 17.5,11 Z M17.5,13 C18.0522847,13 18.5,13.4477153 18.5,14 C18.5,14.5522847 18.0522847,15 17.5,15 C16.9477153,15 16.5,14.5522847 16.5,14 C16.5,13.4477153 16.9477153,13 17.5,13 Z"
                      id="Oval"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
