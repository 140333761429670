import { NextPage } from 'next';
import { useState, useEffect, useLayoutEffect } from 'react';
import {
  SpokeAuthView,
  useCurrentUser,
  compareRoutes,
  isServer,
  log,
  useRouter,
  useRoutes,
  SpkRouteKey,
  SpkPath,
} from '@spoke/common';

export const VerifyEmailGuard: NextPage = ({ children }) => {
  const router = useRouter();
  const routes = useRoutes();

  const [guardPassed, setGuardPassed] = useState(false);

  const [currentUser, currentUserQuery] = useCurrentUser();

  const APPLY_ON_ROUTES: string[] = [
    routes[SpkRouteKey.Root],
    routes.Dashboard,
    routes.Retrospectives,
    routes.ImprovementGoals,
    routes.ActionItems,
    routes.ParkingLot,
    routes.TeamMembers,
    routes.ProgramTeams,
    routes.TeamSettings,
    routes.CreateBoard,
    routes.Board(),
    routes.Whiteboard(),
    routes.Onboarding,
    routes.OnboardingCreateTeam,
    routes.OnboardingSurvey,
    routes.Users,
    routes.OrgSettings,
    routes.UserSettings,
    routes.Integrations,
    routes.AuthView(SpokeAuthView.VerifyEmail),
    routes.SetupGithub,
    routes.Metrics,
    routes.SetupBitbucket,
    routes.AuthView(SpokeAuthView.EmailPassword),
    routes.AuthView(SpokeAuthView.Register),
    routes.AuthView(SpokeAuthView.MethodSelector),
    routes.AuthView(SpokeAuthView.Sso),
  ];

  const shouldApply = APPLY_ON_ROUTES.some((r) =>
    compareRoutes(r, router.route, router.asPath)
  );

  (isServer() ? useEffect : useLayoutEffect)(() => {
    const { asPath, route, isReady } = router;

    if (!isReady || currentUserQuery.loading) return;
    if (!currentUser) {
      setGuardPassed(true);
      return;
    }

    const isVerified = currentUser?.verified;
    const confirmEmailRoute = routes.AuthView(SpokeAuthView.VerifyEmail);

    if (compareRoutes(asPath, confirmEmailRoute)) {
      if (isVerified) {
        if (!isServer()) {
          const currentUserHasNoOrg = !currentUser?.organization?.id;
          const currentUserHasNoTeam = !currentUser?.team?.id;

          const redirectTo = currentUserHasNoOrg
            ? routes.GetStarted
            : currentUserHasNoTeam
            ? routes.OnboardingCreateTeam
            : routes.Dashboard;

          router.replace({ pathname: redirectTo });
        }
        setGuardPassed(false);
        return;
      }
    }

    if (
      isVerified ||
      !shouldApply ||
      compareRoutes(confirmEmailRoute, asPath, route)
    ) {
      setGuardPassed(true);
      return;
    }

    log.info(`VerifyEmailGuard redirecting to ${confirmEmailRoute}`, {
      isVerified: currentUser?.verified,
      redirectFrom: asPath,
      redirectTo: confirmEmailRoute,
    });

    const redirectCfg: SpkPath = {
      pathname: confirmEmailRoute,
    };

    setGuardPassed(false);
    if (!isServer()) router.replace(redirectCfg);
  }, [currentUser?.verified, router, shouldApply, currentUserQuery.loading]);

  if (shouldApply && !guardPassed) return null;
  return <>{children}</>;
};
