import { makeVar, useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import {
  useNetworkContext,
  useInstanceCount,
  SpkTime,
  uniqueBy,
  sortByCreatedAt,
  AscDesc,
  log,
  QueryConfig,
} from '@spoke/common';
import {
  ImprovementGoalDataUpdatesByTeamIdsSubscription,
  ImprovementGoalDataUpdatesByTeamIdsDocument,
  ImprovementGoalsByTeamIdsDocument,
  ImprovementGoalsByTeamIdsQuery,
  useImprovementGoalsByTeamIdsQuery,
} from '@spoke/graphql';

type Config = QueryConfig<typeof useImprovementGoalsByTeamIdsQuery>;
export type UseImprovementGoalsByTeamIdsSubscriptionConfig = {
  queryVars?: any;
  /**
   * Whether or not to start a GraphQL subscription, defaults to false
   */
  subscribeToChanges?: boolean;
  /**
   * Whether or not to unsubscribe on unmount if subscribed, defaults to false
   * Note that if true, regardless of whether has initialized a sub or not, will still kill
   * subscriptions made by other instances of this hook if subscription variables match
   */
  keepSubscribed?: boolean;
};

const DEFAULT_HOOK_CONFIG: UseImprovementGoalsByTeamIdsSubscriptionConfig = {
  subscribeToChanges: false,
  keepSubscribed: false,
};

/**
 * Keeps references for all unsubscription functions for active subs, keyed by `${teamId}-${takeLastNPeriodsWithData}`
 */
const unsubscribeFunctionsVar = makeVar<Record<string, (() => void)[]>>({});
const isSubscribed = (subscriptionKey: string) =>
  Object.keys(unsubscribeFunctionsVar()).includes(subscriptionKey);

/**
 * This hook manages the lifecycle of a subscription for ImprovementGoalData,
 * and updating queries that need to react to that (when manual updating is needed)
 * @note - this file ended up pretty confusing but it's a simple idea behind it.
 */
export const useImprovementGoalsByTeamIdsSubscription = (
  hookConfig: Partial<UseImprovementGoalsByTeamIdsSubscriptionConfig> = DEFAULT_HOOK_CONFIG
) => {
  const { subscribeToChanges, keepSubscribed, queryVars } = Object.assign(
    {},
    DEFAULT_HOOK_CONFIG,
    hookConfig
  );

  const { shouldPoll } = useNetworkContext();
  const apollo = useApolloClient();

  const [getInstanceCount] = useInstanceCount(
    `useImprovementGoals-${queryVars.variables.improvementGoalTeamIds}-${queryVars.variables.takeLastNPeriodsWithData}`
  );

  useEffect(() => {
    const subscriptionKey = `${queryVars.variables.improvementGoalTeamIds}-${queryVars.variables.takeLastNPeriodsWithData}`;
    const isAlreadySubscribed = isSubscribed(subscriptionKey);

    const shouldSubscribe =
      subscribeToChanges &&
      queryVars.variables.improvementGoalTeamIds &&
      !shouldPoll &&
      getInstanceCount() > 0 &&
      !isAlreadySubscribed;

    if (shouldSubscribe) {
      log.info(
        `Starting ImprovementGoalData by teamIds subscription taking last ${queryVars.variables.takeLastNPeriodsWithData} periods`
      );
      const vars = {
        improvementGoalTeamIds: queryVars.variables.improvementGoalTeamIds,
        reportingDataTeamIds: queryVars.variables.reportingDataTeamIds,
        limit: 20,
        takeLastNPeriodsWithData: queryVars.variables.takeLastNPeriodsWithData,
        offset: 0,
        utcOffsetMs: SpkTime.getUtcOffsetMs(),
      };

      const observable =
        apollo.subscribe<ImprovementGoalDataUpdatesByTeamIdsSubscription>({
          query: ImprovementGoalDataUpdatesByTeamIdsDocument,
          variables: vars,
        });

      const subscription = observable.subscribe((subscriptionData) => {
        const prevData = apollo.cache.readQuery<ImprovementGoalsByTeamIdsQuery>(
          {
            query: ImprovementGoalsByTeamIdsDocument,
            variables: queryVars.variables,
          }
        );

        const updatedGoals =
          subscriptionData.data?.improvementGoalDataUpdatesByTeamIds;
        if (!updatedGoals?.length) return;
        const prevGoals =
          prevData?.improvementGoalsByTeamIds?.improvementGoals ?? [];
        const newGoals = uniqueBy([...prevGoals, ...updatedGoals], 'id').sort(
          sortByCreatedAt(AscDesc.Desc)
        );
        const newTotalSize = newGoals.length;
        // Updating queries manually because we're merging lists. This is kinda fragile in
        // the sense we're counting all queries need the same data. Could refactor eventually
        // to merge previous and new data for each individually
        apollo.cache.updateQuery<ImprovementGoalsByTeamIdsQuery>(
          {
            query: ImprovementGoalsByTeamIdsDocument,
            variables: queryVars.variables,
          },
          () => ({
            __typename: 'Query',
            improvementGoalsByTeamIds: {
              __typename: 'TeamImprovementGoalResults',
              improvementGoals: newGoals,
              totalSize: newTotalSize,
            },
          })
        );
        // apollo.cache.updateQuery<ImprovementGoalSummaryQuery>(
        //   {
        //     query: ImprovementGoalSummaryDocument,
        //     variables: vars,
        //   },
        //   (prevSummary) => ({
        //     __typename: 'Query',
        //     improvementGoalsByTeamIds: {
        //       __typename: 'TeamImprovementGoalResults',
        //       improvementGoals: newGoals,
        //       totalSize: newTotalSize,
        //     },
        //   })
        // );
      });

      unsubscribeFunctionsVar({
        ...unsubscribeFunctionsVar(),
        [subscriptionKey]: [subscription.unsubscribe.bind(subscription)],
      });
    }

    return () => {
      const isStillSubscribed = isSubscribed(subscriptionKey);
      const isLastInstance = getInstanceCount() === 0;
      const shouldUnsubscribe =
        isStillSubscribed && isLastInstance && !keepSubscribed;
      if (shouldUnsubscribe) {
        // No instances with matching variables are mounted, so unsubscribe
        log.info(
          `Stopping ImprovementGoalData by teamIds subscription taking last ${queryVars.variables.takeLastNPeriodsWithData} periods`
        );
        const functions = unsubscribeFunctionsVar();
        for (const unsubscribe of functions[subscriptionKey]) unsubscribe();
        delete functions[subscriptionKey];
        unsubscribeFunctionsVar(functions);
      }
    };
  }, [
    queryVars,
    getInstanceCount,
    shouldPoll,
    subscribeToChanges,
    keepSubscribed,
    apollo,
  ]);
};
