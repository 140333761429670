import { FetchResult } from '@apollo/client';
import { deepMerge, deepClone } from '../../../etc';
import { log } from '../../../../SpkLog';
import { DeepPartial } from '../../../../types';
import {
  Card,
  List,
  UpdateListMutation,
  Board,
  BoardByIdQuery,
} from '@spoke/graphql';

type OptimisticUpdateListArgs = {
  list: List;
  updateFields: DeepPartial<List>;
};
export const optimisticUpdateList = ({
  list,
  updateFields,
}: OptimisticUpdateListArgs): UpdateListMutation => {
  const merged = deepMerge(list, updateFields) as List;

  return {
    __typename: 'Mutation',
    updateList: merged,
  };
};

type UpdateListUpdateFnForBoardById = (
  prevData: BoardByIdQuery,
  incomingData: {
    mutationResult: FetchResult<
      UpdateListMutation,
      Record<string, any>,
      Record<string, any>
    >;
  }
) => BoardByIdQuery;

/**
 * This is responsible for updating all card's original list badges after a list update
 */
export const getUpdateListUpdateFunctionForBoardById =
  (): UpdateListUpdateFnForBoardById =>
  (prev, { mutationResult }) => {
    const updatedList = mutationResult.data?.updateList;

    if (!updatedList) {
      log.warn(
        'Failed to optimistic updateList. Invalid mutation result received',
        { updatedList }
      );
      return prev;
    }

    const listId = updatedList.id;
    const boardId = updatedList.boardId;
    const boardFromCache = prev.board;

    if (!boardFromCache) {
      log.warn('Failed to optimistic updateList. Could not find cached board', {
        boardFromCache,
        boardId,
      });
      return prev;
    }

    const newBoard = deepClone(boardFromCache) as Board;

    const listIdx = newBoard.lists?.findIndex((l) => l?.id === listId);

    if (!newBoard.lists || typeof listIdx !== 'number' || listIdx < 0) {
      log.warn(
        'Failed to optimistic updateList. Could not find list in board',
        {
          boardId,
          listId,
          boardFromCache,
          listIdx,
        }
      );
      return prev;
    }

    newBoard.lists = newBoard.lists.map((list) => {
      if (!list) return list;
      if (list.id === listId) list = updatedList as List;

      const updateCardOriginalList = (card: Partial<Card>): Card =>
        ({
          ...card,
          originalListBadge: {
            ...card.originalListBadge,
            originalListId: updatedList.id,
            color: updatedList.cardColor,
            text: updatedList.name,
          },
        } as Card);

      list.cards = list.cards?.map((card) => {
        if (!card || card.originalListBadge?.originalListId !== listId) {
          return card;
        }

        return {
          ...updateCardOriginalList(card),
          children: card.children
            ?.filter(Boolean)
            ?.map((child) =>
              child?.originalListBadge?.originalListId === listId
                ? updateCardOriginalList(child as Card)
                : child
            ),
        };
      });
      return list;
    });

    return {
      __typename: 'Query',
      board: newBoard,
    };
  };
