import { useState, useCallback } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import {
  useChangeBoardStageHandler,
  useGenerateBoardTakeaways,
} from '../../handlers';
import { useCurrentBoardRules } from '../../queries/useCurrentBoardRules';
import { ReopenRetroConfirmationModal } from '../ReopenRetroConfirmationModal';
import { useCurrentTeam } from '@spoke/user';
import {
  ActionItem,
  BoardStage,
  TakeawayStatus,
  TakeawayType,
} from '@spoke/graphql';
import {
  useDisclosure,
  VStack,
  Flex,
  HStack,
  Icon,
  Heading,
  Image,
  useAssets,
  Link,
  Text,
  Divider,
  Box,
  useCurrentBoard,
  SpkTime,
  useRoutes,
  useToast,
  EmptyState,
  Button,
  Badge,
  Spinner,
} from '@spoke/common';
import {
  useDeleteActionItemHandler,
  useActionItems,
  MemoizedActionItemCard,
  EditActionItemModal,
  CreateActionItemModal,
} from '@spoke/action-item';
import { useParticipations } from '../../queries';
import { RetroSummaryTakeawayList } from './RetroSummaryTakeawayList';
import { useRouter } from 'next/router';
import { BsClipboard } from 'react-icons/bs';
import { MdOutlineBookmarkBorder } from 'react-icons/md';
import { FiAlertTriangle } from 'react-icons/fi';

export const RetroSummary = () => {
  const [toast] = useToast();
  const router = useRouter();
  const routes = useRoutes();

  const [board] = useCurrentBoard();
  const [team] = useCurrentTeam();

  const { isFacilitator } = useCurrentBoardRules();
  const [{ participations }] = useParticipations({
    isFacilitator: true,  // in this case we also just want the number of participations and doesn't matter if Faciliator or not
  });

  const {
    illustrationCollaboration,
    illustrationIncrease,
    illustrationTakeaways,
  } = useAssets();

  const [handleDeleteActionItem] = useDeleteActionItemHandler();
  const [handleChangeBoardStage] = useChangeBoardStageHandler();
  const [handleGenerateBoardTakeaways] = useGenerateBoardTakeaways();

  const reopenRetroConfirmModal = useDisclosure();
  const createActionItemModalDisclosure = useDisclosure();

  const [editingActionItemId, setEditingActionItemId] = useState<string | null>(
    null
  );
  const onEditActionItemClick = useCallback((actionItemId: string) => {
    setEditingActionItemId(actionItemId);
  }, []);

  const [{ unresolved: actionItemsDuringThisRetro }] = useActionItems({
    variables: { boardId: board?.id },
  });

  const copyTakeaways = () => {
    navigator.clipboard
      .writeText(
        `On ${SpkTime.format(
          board?.createdAt ?? 0,
          'MMM dd, yyyy'
        )}, our team ${
          team?.name
        } conducted a retrospective in ScatterSpoke. The retrospective had ${
          participations?.length ?? 0
        } participants in it. These were the main takeaways:\n${board?.takeaways
          ?.filter(({ type }) => type === TakeawayType.Team)
          ?.map(({ text }, index) => `${index + 1}. ${text}`)
          .join(
            '\n'
          )}\n\nAs for our management, we'd like to bring up the following:\n${board?.takeaways
          ?.filter(({ type }) => type === TakeawayType.Manager)
          ?.map(({ text }, index) => `${index + 1}. ${text}`)
          .join('\n')}
                `
      )
      .then(() => {
        toast({
          description: 'Summary copied to clipboard',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          description: 'Failed to copy summary to clipboard',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const teamTakeAways =
    board?.takeaways?.filter(
      (takeaway) => takeaway?.type === TakeawayType.Team
    ) ?? [];
  const managerTakeaways =
    board?.takeaways?.filter(
      (takeaway) => takeaway?.type === TakeawayType.Manager
    ) ?? [];
  return (
    <Flex
      direction="column"
      mx="auto"
      maxW={1000}
      p={4}
      spacing={12}
      justifyContent="center"
    >
      <Flex justifyContent="space-between">
        <Image
          mt={1}
          w={206}
          src={illustrationCollaboration}
          alt="Retrospective finished"
        />
        <VStack align="center" mb={20}>
          <HStack fontWeight={500} color="gray.600">
            <Icon w={5} h={5} as={AiOutlineCheck} />
            <Text> Ended retrospective</Text>
          </HStack>
          <Text
            color="primary.500"
            fontSize={32}
            fontWeight={600}
            textAlign="center"
          >
            🎉 All done team, great retrospective!
          </Text>
          <HStack>
            {isFacilitator && (
              <Link
                onClick={reopenRetroConfirmModal.open}
                fontSize={14}
                fontWeight={500}
                textDecoration="underline"
                pt="3px"
                color="gray.500"
              >
                Reopen retrospective
              </Link>
            )}
          </HStack>
        </VStack>
        <Image
          mt={1}
          w={206}
          src={illustrationIncrease}
          transform="rotate(8.5deg)"
          alt="Retrospective finished"
        />
      </Flex>

      <Divider />

      <VStack flex="1" align="center" w="full">
        {!board?.state?.takeAwayStatus ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            w="full"
            my={10}
          >
            <HStack>
              <Text fontSize={20} fontWeight={500}>
                Retrospective Summary
              </Text>{' '}
              <Badge
                pt="1px"
                fontSize={11}
                ml="1px"
                mb="1px"
                bg="primary.400"
                color="white"
                borderRadius="sm"
              >
                NEW
              </Badge>
            </HStack>

            <Text fontSize={16} fontWeight={500} color="gray.500" mb={5}>
              Transform your retrospective into shareable, actionable takeaways
              for your team and management.
            </Text>

            <Image
              src={illustrationTakeaways}
              alt="No action items yet"
              mt={8}
              mb={2}
            />

            <Button size="lg" onClick={handleGenerateBoardTakeaways}>
              Create Summary
            </Button>
          </Flex>
        ) : board?.state?.takeAwayStatus === TakeawayStatus.Pending ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            w="full"
            my={10}
          >
            <Spinner speed="2s" size="md" />

            <Text fontSize={16} fontWeight={500} mt={5}>
              We are loading the summary for your retro...
            </Text>
            <Text fontSize={12} fontWeight={500} color="gray.500">
              This usually takes a few seconds.
            </Text>
          </Flex>
        ) : board?.state?.takeAwayStatus === TakeawayStatus.Error ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            w="full"
            my={10}
          >
            <Icon w={7} h={7} as={FiAlertTriangle} color="primary.500" />

            <Text fontSize={16} fontWeight={500} mt={5}>
              Our servers are overloaded at this moment.
            </Text>
            <Text fontSize={12} fontWeight={500} color="gray.500">
              We will try again in a few moments and let you know once finished.
            </Text>
          </Flex>
        ) : (
          board?.state?.takeAwayStatus === TakeawayStatus.Done && (
            <>
              <Box
                bg="white"
                w="full"
                p={8}
                my={8}
                style={{ marginTop: 0 }}
                shadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              >
                <Heading fontWeight={600} fontSize={19} mb={4}>
                  Retrospective Summary
                </Heading>
                <Text mb={6}>
                  On {SpkTime.format(board?.createdAt ?? 0, 'MMM dd, yyyy')},
                  our team{' '}
                  <Text fontWeight="bold" as="span">
                    {team?.name}
                  </Text>{' '}
                  conducted a retrospective in ScatterSpoke. The retrospective
                  had {participations?.length ?? 0} participants in it.
                  {board?.takeaways?.length
                    ? ' Here are the key takeaways for the team:'
                    : 'The retrospective did not have enough feedback to generate a summary.'}
                </Text>
                {Boolean(teamTakeAways.length) && (
                  <RetroSummaryTakeawayList takeaways={teamTakeAways} />
                )}
                {Boolean(teamTakeAways.length) && (
                  <>
                    <Text my={6}>
                      As for our{' '}
                      <Text fontWeight="bold" as="span">
                        management
                      </Text>
                      , we&apos;d like to bring up the following:
                    </Text>
                    <RetroSummaryTakeawayList
                      takeaways={managerTakeaways}
                      mb={6}
                    />
                  </>
                )}
                {Boolean(board?.takeaways?.length) && (
                  <Flex justifyContent="flex-end" align="center" gap={2}>
                    <Icon as={BsClipboard} color="primary.500" w={5} h={5} />

                    <Link
                      onClick={copyTakeaways}
                      fontSize={16}
                      fontWeight={500}
                      textDecoration="underline"
                      color="primary.500"
                    >
                      Copy summary
                    </Link>
                  </Flex>
                )}
              </Box>
            </>
          )
        )}
        {/* {actionItemsDuringThisRetro?.length > 0 ? (
          <>
            <Heading
              fontWeight={500}
              fontSize={20}
              style={{ marginTop: 32, marginBottom: 12 }}
              alignItems="center"
              display="flex"
              gap={1}
            >
              Action Items created during this retro{' '}
              <Text as="span" color="primary.500" fontSize={16}>
                {actionItemsDuringThisRetro.length}
              </Text>
            </Heading>

            {actionItemsDuringThisRetro.map((item) => (
              <Flex w="full" key={item?.id}>
                <MemoizedActionItemCard
                  actionItem={item as ActionItem}
                  onDelete={handleDeleteActionItem}
                  onEditClick={onEditActionItemClick}
                  variant="solid"
                  w="full"
                />
              </Flex>
            ))}
          </>
        ) : (
          <Flex
            minH={100}
            layerStyle="outlineGray"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            w="full"
            borderWidth={1}
            borderColor="gray.200"
          >
            <EmptyState
              icon={
                <Icon
                  w={7}
                  h={7}
                  as={MdOutlineBookmarkBorder}
                  color="primary.500"
                />
              }
              title="No action items were created during this retro."
              titleStyle={{ fontWeight: 500 }}
              mb={2}
            />
          </Flex>
        )} */}

        <Divider style={{ marginTop: 40, marginBottom: 40 }} />

        <Text as="span" color="gray.500" fontSize={16} textAlign="center">
          Create your next retrospective now and encourage your team to
          <br /> to add their feedback as they think of it.
        </Text>

        <Link
          onClick={() => router.push(routes.Dashboard)}
          fontSize={16}
          fontWeight={500}
          textDecoration="underline"
          pt="3px"
          color="primary.500"
        >
          Return to dashboard
        </Link>
      </VStack>
      <EditActionItemModal
        actionItemId={editingActionItemId}
        isOpen={Boolean(editingActionItemId)}
        onClose={() => setEditingActionItemId(null)}
      />
      <CreateActionItemModal
        isOpen={createActionItemModalDisclosure.isOpen}
        onClose={createActionItemModalDisclosure.close}
      />
      <ReopenRetroConfirmationModal
        isOpen={reopenRetroConfirmModal.isOpen}
        onClose={reopenRetroConfirmModal.close}
        onConfirm={() => handleChangeBoardStage(BoardStage.Discuss)}
      />
    </Flex>
  );
};
