import { FC, useEffect, useState } from 'react';
import { BiLockOpen, BiLockAlt } from 'react-icons/bi';
import { useBoardPasswordChangeHandler } from '../../handlers';
import {
  useSpringState,
  Flex,
  HStack,
  Icon,
  Button,
  randomString,
  InputGroup,
  InputLeftElement,
  Input,
  Text,
  useCurrentBoard,
} from '@spoke/common';

export const BoardSetPassword: FC = () => {
  const [board] = useCurrentBoard();

  const [handleChangeBoardPassword] = useBoardPasswordChangeHandler();

  useEffect(() => {
    setPasswordInputValue(board?.state.password ?? '');
  }, [board?.state.password]);

  const hasPassword = board?.state.password;

  const [showBoardPasswordSuccess, setShowBoardPasswordSuccess] =
    useSpringState(false, 2000);

  const [boardPasswordLoading, setBoardPasswordLoading] = useState(false);
  const [passwordInputValue, setPasswordInputValue] = useState(
    board?.state.password ?? ''
  );

  const onChangeBoardPassword = async (newPassword: string) => {
    setBoardPasswordLoading(true);
    await handleChangeBoardPassword(newPassword);
    setBoardPasswordLoading(false);
    setShowBoardPasswordSuccess(true);
  };

  return (
    <Flex gap={2} h={10} justifyContent="space-between">
      <HStack>
        <Icon as={BiLockOpen} color="gray.600" w={5} h={5} />
        <Text fontWeight={500} pt={1} color="gray.700">
          Retro password
        </Text>
      </HStack>
      {!hasPassword && (
        <Button
          variant="outlineGray"
          pt="1px"
          onClick={() => onChangeBoardPassword(randomString().substring(0, 8))}
        >
          Set a password
        </Button>
      )}
      {hasPassword && (
        <HStack>
          <InputGroup w={40} minH="full">
            <InputLeftElement minH={10}>
              <Icon as={BiLockAlt} color="gray.500" w="18px" h="18px" />
            </InputLeftElement>
            <Input
              value={passwordInputValue}
              onChange={(e) => setPasswordInputValue(e.target.value)}
              minH={10}
              onKeyDown={(e) =>
                e.key === 'Enter'
                  ? onChangeBoardPassword(passwordInputValue)
                  : null
              }
            />
          </InputGroup>
          <Button
            onClick={() => onChangeBoardPassword(passwordInputValue)}
            isLoading={boardPasswordLoading}
            fontSize={14}
            variant="outlineGray"
            isDisabled={
              !showBoardPasswordSuccess &&
              passwordInputValue === board?.state?.password
            }
            pt={1}
          >
            {showBoardPasswordSuccess ? 'Saved!' : 'Save'}
          </Button>
          <Button
            onClick={() => handleChangeBoardPassword('')}
            fontSize={14}
            variant="outlineGray"
            pt={1}
          >
            Remove
          </Button>
        </HStack>
      )}
    </Flex>
  );
};
