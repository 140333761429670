import { makeVar } from '@apollo/client';
import { useEffect } from 'react';
import {
  TEN_SECONDS_MS,
  QueryConfig,
  useInstanceCount,
  deepMerge,
  uniqueBy,
  log,
  filterArchived,
  useNetworkContext,
  DeepPartial,
  useCurrentUser,
} from '@spoke/common';
import {
  ActionItemStatus,
  useActionItemsByFiltersLazyQuery,
  ActionItemsByFiltersSubscriptionDocument,
} from '@spoke/graphql';

const filterResolved = (actionItem: { status?: ActionItemStatus } | null) =>
  actionItem?.status === ActionItemStatus.Resolved;

const filterUnresolved = (actionItem: { status?: ActionItemStatus } | null) =>
  actionItem?.status === ActionItemStatus.Unresolved;

const ACTION_ITEM_POLLING_INTERVAL_MS = TEN_SECONDS_MS;

// This state persists app-wide, not component-specific. So we don't create unecessary susbcriptions
const subscribedToChangesVar = makeVar<boolean>(false);
const unsubscriptionsVar = makeVar<(() => void)[]>([]);
const isPollingVar = makeVar<boolean>(false);

type Config = QueryConfig<typeof useActionItemsByFiltersLazyQuery>;
type QueryRef = ReturnType<typeof useActionItemsByFiltersLazyQuery>[1];
type QueryData = NonNullable<QueryRef['data']>['actionItemsByFilters'];
type FormattedResult = {
  resolved: NonNullable<QueryData>;
  unresolved: NonNullable<QueryData>;
};

type UseActionItemsHookConfig = {
  pollIfNeeded?: boolean;
};

const DEFAULT_HOOK_CONFIG: UseActionItemsHookConfig = {
  pollIfNeeded: false,
};

export const useActionItems = (
  queryConfig?: DeepPartial<Config>,
  hookConfig: UseActionItemsHookConfig = DEFAULT_HOOK_CONFIG
): [FormattedResult, QueryRef] => {
  const { pollIfNeeded } = hookConfig;
  const { shouldPoll } = useNetworkContext();
  const [user] = useCurrentUser();
  const userId = user?.id;
  const teamId = user?.team?.id;

  const [getInstanceCount] = useInstanceCount('useActionItems');

  const baseConfig: DeepPartial<Config> = {
    fetchPolicy: 'cache-and-network',
    variables: {
      teamId: queryConfig?.variables?.teamId ?? teamId ?? '',
      status: ActionItemStatus.All,
      assigneeIds: [],
      dueDate: Array.isArray(queryConfig?.variables?.dueDate)
        ? null
        : [null, null],
      startDate: null,
      endDate: null,
      improvementGoals: [],
    },
  };

  const finalConfig = (
    queryConfig ? deepMerge(baseConfig, queryConfig) : baseConfig
  ) as Config;

  const [loadActionItems, actionItemsQuery] =
    useActionItemsByFiltersLazyQuery(finalConfig);

  useEffect(() => {
    const shouldInit =
      Boolean(baseConfig.variables?.teamId) && !actionItemsQuery.called;
    if (shouldInit) loadActionItems();
  }, [actionItemsQuery.called, baseConfig.variables?.teamId, loadActionItems]);

  // This effect must be called before the next one so we don't create
  // a race condition on cleaning/creating subscriptions
  useEffect(() => {
    if (!subscribedToChangesVar()) return;
    log.info('User team changed. Cleaning up Action Item subscriptions');
    for (const unsubscribe of unsubscriptionsVar()) unsubscribe();
    unsubscriptionsVar([]);
    subscribedToChangesVar(false);
  }, [teamId]);

  useEffect(() => {
    const shouldSubscribe = Boolean(
      !subscribedToChangesVar() &&
        !shouldPoll &&
        actionItemsQuery.called &&
        userId
    );

    if (!shouldSubscribe) return;

    subscribedToChangesVar(true);
    log.info('Starting Action Items subscriptions');

    const unsubscribe = actionItemsQuery.subscribeToMore({
      document: ActionItemsByFiltersSubscriptionDocument,
      variables: {
        teamId: finalConfig.variables?.teamId,
        assigneeIds: finalConfig.variables?.assigneeIds,
        boardId: finalConfig.variables?.boardId,
        dueDate: finalConfig.variables?.dueDate,
        endDate: finalConfig.variables?.endDate,
        improvementGoals: finalConfig.variables?.improvementGoals,
        startDate: finalConfig.variables?.startDate,
        status: finalConfig.variables?.status,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const updatedActionItem = subscriptionData.data.actionItemsByFilters;

        const mergedActionItems = [
          updatedActionItem,
          ...(prev?.actionItemsByFilters || []),
        ].filter(Boolean) as Array<
          NonNullable<NonNullable<typeof updatedActionItem>[0]>
        >;

        const uniqueMergedActionItems = uniqueBy(
          mergedActionItems,
          'id'
        ).filter(filterArchived);

        return {
          __typename: 'Query',
          actionItemsByFilters: uniqueMergedActionItems,
        };
      },
    });

    unsubscriptionsVar([unsubscribe]);
  }, [
    actionItemsQuery,
    shouldPoll,
    teamId,
    userId,
    finalConfig.variables?.teamId,
    finalConfig.variables?.assigneeIds,
    finalConfig.variables?.boardId,
    finalConfig.variables?.dueDate,
    finalConfig.variables?.endDate,
    finalConfig.variables?.improvementGoals,
    finalConfig.variables?.startDate,
    finalConfig.variables?.status,
  ]);

  useEffect(() => {
    if (!userId || (!shouldPoll && isPollingVar())) {
      log.info('Stopping Action Item polling');
      actionItemsQuery.stopPolling();
      isPollingVar(false);
      return;
    }

    if (shouldPoll && !isPollingVar() && pollIfNeeded) {
      log.info('Starting Action Item polling');
      actionItemsQuery.startPolling(ACTION_ITEM_POLLING_INTERVAL_MS);
      isPollingVar(true);
      return;
    }
  }, [actionItemsQuery, pollIfNeeded, shouldPoll, userId]);

  const queryData =
    actionItemsQuery.data?.actionItemsByFilters ??
    actionItemsQuery.previousData?.actionItemsByFilters ??
    [];

  const resolved = queryData.filter(filterResolved).filter(filterArchived);
  const unresolved = queryData.filter(filterUnresolved).filter(filterArchived);

  useEffect(
    () => () => {
      // This allows us to only unsubscribe when all useActionItems hooks are unmounted
      const isLastInstance = getInstanceCount() === 0;
      if (!isLastInstance) return;
      log.info('Stopping Action Item subscriptions');
      for (const unsubscribe of unsubscriptionsVar()) unsubscribe();
      subscribedToChangesVar(false);
      if (!isPollingVar()) return;
      log.info('Stopping Action Item polling');
      actionItemsQuery.stopPolling();
      isPollingVar(false);
    },
    [actionItemsQuery, getInstanceCount]
  );

  return [{ resolved, unresolved }, actionItemsQuery];
};
