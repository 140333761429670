import { FC } from 'react';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import { Icon } from './Icon';
import { IconButton } from './IconButton';
import { HStack } from './Stack/HStack';
import { Text } from './Text';

type Props = {
  pageCount: number;
  currentPage: number;
  onChangePage: (page: number) => void;
};
export const PaginationControlsCondensed: FC<Props> = ({
  currentPage,
  onChangePage,
  pageCount,
}) => (
  <HStack spacing={0}>
    <IconButton
      aria-label="Previous page"
      onClick={() => onChangePage(currentPage - 1)}
      icon={<Icon as={BiChevronLeft} w={6} h={6} />}
      color={currentPage === 1 ? 'gray.400' : 'gray.800'}
      isDisabled={currentPage === 1}
    />
    <Text fontSize={16} color="gray.600" fontWeight={500} pt="2px">
      {currentPage} of {pageCount}
    </Text>
    <IconButton
      aria-label="Next page"
      onClick={() => onChangePage(currentPage + 1)}
      icon={<Icon as={BiChevronRight} w={6} h={6} />}
      color={currentPage === pageCount ? 'gray.400' : 'gray.800'}
      isDisabled={currentPage === pageCount}
    />
  </HStack>
);
