import { FC } from 'react';
import { SpkTime, HStack, LifecycleBar } from '@spoke/common';

type Props = {
  pickupTimeMs: number;
  approvalTimeMs: number;
  overrallCycleTimeMs: number;
  mergeTimeMs: number;
  loading?: boolean;
};
export const SourceControlMetricsStatuses: FC<Props> = ({
  approvalTimeMs,
  loading,
  mergeTimeMs,
  pickupTimeMs,
  overrallCycleTimeMs,
}) => {
  const longestLifecyclePart = Math.max(
    pickupTimeMs || 0,
    approvalTimeMs || 0,
    mergeTimeMs || 0
  );

  const sum = approvalTimeMs + mergeTimeMs + pickupTimeMs;

  const getMadeUpPercentage = (timeSpentMs: number): string =>
    !overrallCycleTimeMs
      ? 'N/A'
      : SpkTime.formatDuration(
          (timeSpentMs / sum) * overrallCycleTimeMs,
          'hours'
        );

  return (
    <HStack spacing={0} mt="2px" justifyContent="stretch" w="full">
      <LifecycleBar
        loading={loading}
        label="Pickup"
        value={getMadeUpPercentage(pickupTimeMs)}
        infoTooltip="Time it takes to pickup a pull request and review it."
        flex={pickupTimeMs}
        isFirst
        highlight={
          longestLifecyclePart === 0 || longestLifecyclePart === pickupTimeMs
        }
      />
      <LifecycleBar
        loading={loading}
        label="Approval"
        value={getMadeUpPercentage(approvalTimeMs)}
        infoTooltip="Time it takes from first review to approval"
        flex={approvalTimeMs}
        highlight={
          longestLifecyclePart === 0 || longestLifecyclePart === approvalTimeMs
        }
      />
      <LifecycleBar
        loading={loading}
        label="Merge"
        value={getMadeUpPercentage(mergeTimeMs)}
        infoTooltip="Time it takes from approval to merge"
        flex={mergeTimeMs}
        highlight={
          longestLifecyclePart === 0 || longestLifecyclePart === mergeTimeMs
        }
        isLast
      />
    </HStack>
  );
};
