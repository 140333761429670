import { FC, memo } from 'react';
import { MemoizedParticipant } from './Participant';
import { Flex, Text, useCurrentUser } from '@spoke/common';
import { BoardParticipation, BoardStage } from '@spoke/graphql';

type BoardSidebarParticipantsProps = {
  teamMembers: BoardParticipation[];
  currentStage?: BoardStage | null;
  onGrantFacilitator: (userId: string) => void;
  onRevokeFacilitator: (userId: string) => void;
};
const BoardSidebarParticipants: FC<BoardSidebarParticipantsProps> = ({
  teamMembers,
  currentStage,
  onGrantFacilitator,
  onRevokeFacilitator,
}) => {
  const [currentUser] = useCurrentUser();

  const teamMemberCount = teamMembers.length ?? 0;

  return (
    <Flex flex="1" flexDir="column" gap={4} px={4} overflowY="auto">
      {Boolean(teamMemberCount) && (
        <Text fontSize={14} color="gray.400" fontWeight={500}>
          Team Participants ({teamMemberCount})
        </Text>
      )}
      {teamMembers.map((member) => (
        <MemoizedParticipant
          key={member.participant.id}
          data={member}
          onGrantFacilitator={onGrantFacilitator}
          onRevokeFacilitator={onRevokeFacilitator}
          currentStage={currentStage}
          isClient={member.userId === currentUser?.id}
          isFacilitator={member.isFacilitator}
        />
      ))}
    </Flex>
  );
};

export const MemoizedBoardSidebarParticipants = memo(BoardSidebarParticipants);
