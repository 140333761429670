import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import { useImportBoardsMutation, BoardsInput } from '@spoke/graphql';

export const useImportBoardsHandler = () => {
  const [toast] = useToast();
  const [importBoards] = useImportBoardsMutation();

  const handleImportBoards = useCallback(
    async (data: BoardsInput[]) => {
      if (!data?.length) {
        log.error('No-op: tried importing boards but no data provided', {
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      log.info(`Importing ${data.length} boards`, { data });

      const { errors } = await importBoards({
        variables: { boardInputs: data },
        awaitRefetchQueries: true,
        // Need to refresh to see new boards in retro list
        refetchQueries: ['PaginatedTeamBoards'],
      });

      if (errors) {
        log.error('ImportBoards call responded with errors', { errors, data });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      log.info('Imported boards successfully', { data });
      toast({
        status: 'success',
        title: 'Success',
        description: `Imported ${data.length} boards successfully`,
      });
    },
    [importBoards, toast]
  );

  return [handleImportBoards];
};
