import { makeVar } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useId } from './useId';

const instanceMapVar = makeVar<Map<string, Set<string>>>(new Map());

/**
 * Provides a function to get the amount of mounted instances of current component
 * @param name Name of component - instances will be grouped by this key
 */
export const useInstanceCount = (name: string): [() => number] => {
  const [id] = useId();

  useEffect(() => {
    const map = instanceMapVar();
    const instances = map.get(name) ?? new Set<string>();
    instances.add(id);
    map.set(name, instances);
    instanceMapVar(map);
    return () => {
      const _map = instanceMapVar();
      const _instances = _map.get(name) ?? new Set<string>();
      _instances.delete(id);
      _map.set(name, _instances);
      instanceMapVar(_map);
    };
  }, [name, id]);

  const getInstanceCount = useCallback(
    () => instanceMapVar().get(name)?.size ?? 0,
    [name]
  );

  return [getInstanceCount];
};
