import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentBoardId,
  TOAST_ERROR_GENERIC,
  getBoardFromCache,
  sortDiscussCards,
  DeepPartial,
  optimisticUpdateBoard,
  log,
} from '@spoke/common';
import {
  useUpdateBoardMutation,
  BoardDiscussionCardsSortingKey,
  Board,
} from '@spoke/graphql';

export const useChangeBoardDiscussSortingKeyHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [boardId] = useCurrentBoardId();
  const [updateBoard] = useUpdateBoardMutation();

  const handleChangeBoardDiscussSortingKey = useCallback(
    async (
      newKey: BoardDiscussionCardsSortingKey,
      sortByTags: boolean = false
    ) => {
      if (!boardId) {
        log.error(
          'Cannot update board discuss sorting key without loaded boardId',
          {
            newKey,
            boardId,
          }
        );
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const board = getBoardFromCache({ boardId, cache });

      if (!board?.cardsOnDiscussion) {
        log.error(
          'Cannot update board discuss sorting key: cached board not found',
          {
            newKey,
            board,
          }
        );
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const oldDiscussCards = board.cardsOnDiscussion;

      const newDiscussCards = sortDiscussCards(
        oldDiscussCards,
        newKey,
        sortByTags
      );

      const updateBoardFields: DeepPartial<Board> = {
        state: {
          discussionCardsSortingKey: newKey,
          discussSortByTags: sortByTags,
        },
        cardsOnDiscussion: newDiscussCards,
      };

      const expected = optimisticUpdateBoard({
        boardId: board.id,
        fields: updateBoardFields,
        cache,
      });

      await updateBoard({
        variables: { boardId: board?.id, fields: updateBoardFields },
        optimisticResponse: {
          __typename: 'Mutation',
          updateBoard: expected,
        },
      });
    },
    [boardId, cache, toast, updateBoard]
  );

  return [handleChangeBoardDiscussSortingKey];
};
