import { log } from '../../SpkLog';

/**
 * This is looking solid so far but haven't stressed it too
 * much apart from the tests in compareRoutes.test.ts.
 */
export function compareRoutes(
  routeOrRouteFn: string | (() => string),
  ..._toCompare: string[]
): boolean {
  const _route =
    typeof routeOrRouteFn === 'string' ? routeOrRouteFn : routeOrRouteFn();

  const route = _route.split('?')[0];
  const toCompare = _toCompare.map((c) => c.split('?')[0]);

  // Try exact match (faster)
  if (toCompare.includes(route)) return true;

  // Validate route format
  const nextJsValidPattern =
    /^\/?(?:\[[^\]]+\]|[\w-]+)?(?:\/(?:\[[^\]]+\]|[\w-]+))*\/?(?!\/)$/;

  const reactRouterValidPattern =
    /^\/?(?:(?:\[\w+\])|(?:\:\w+)|(?:[\w-]+))(?:\/(?:\[(?:\w|-)+\]|\:(?:\w+)|[\w-]+))*\/?(?!\/)$/;

  if (!nextJsValidPattern.test(route) && !reactRouterValidPattern.test(route)) {
    log.error(`Invalid route format: ${route}`);
    return false;
  }

  // Fallback to wildcard matching Nextjs format /hi/[wildcard]
  const nextJsMatchPattern = new RegExp(
    '^' + route.replace(/\[([^\]]+)\]/g, '([^/]+)') + '$'
  );

  const nextJsMatch = toCompare.some((compareRoute) =>
    nextJsMatchPattern.test(compareRoute)
  );

  if (nextJsMatch) return true;

  // Fallback to wildcard matching React Router format /hi/:wildcard/
  const reactRouterMatchPattern = new RegExp(
    '^' + route.replace(/:([^/]+)/g, '([^/]+)') + '$'
  );

  const reactRouterMatch = toCompare.some((compareRoute) =>
    reactRouterMatchPattern.test(compareRoute)
  );

  return reactRouterMatch;
}
