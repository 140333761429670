import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentUser,
  getLivePollFromCache,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import {
  useSubmitLivePollSkipAnswerMutation,
  LivePollSkipReason,
  SubmitLivePollSkipAnswerMutation,
  LivePollUserStatus,
} from '@spoke/graphql';

export const useSkipLivePollHandler = () => {
  const [skipLivePoll] = useSubmitLivePollSkipAnswerMutation();
  const { cache } = useApolloClient();
  const [toast] = useToast();
  const [currentUser] = useCurrentUser();
  const userId = currentUser?.id;

  const handleSkipLivePoll = useCallback(
    async ({
      pollId,
      skipReason,
    }: {
      pollId: string;
      skipReason: LivePollSkipReason;
    }): Promise<boolean> => {
      if (!pollId || !skipReason || !userId) {
        log.error('Missing critical data to skipLivePoll', {
          pollId,
          skipReason,
          userId,
        });
        return false;
      }

      log.info('Submitting LivePoll answer', { pollId, skipReason });

      const pollFromCache = getLivePollFromCache({ cache, pollId });

      const newSkippedUserIds = [
        ...(pollFromCache?.skippedUserIds || []),
        userId,
      ];

      const expected: SubmitLivePollSkipAnswerMutation = {
        __typename: 'Mutation',
        submitLivePollSkipAnswer: {
          __typename: 'LivePoll',
          id: pollId,
          status: LivePollUserStatus.Skipped,
          skippedUserIds: newSkippedUserIds,
        },
      };

      const { errors } = await skipLivePoll({
        variables: { reason: skipReason, livePollId: pollId },
        optimisticResponse: expected,
      });

      if (errors) {
        log.error('skipLivePollAnswer call responded with errors', {
          pollId,
          skipReason,
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [cache, skipLivePoll, toast, userId]
  );

  return [handleSkipLivePoll];
};
