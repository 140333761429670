import { FC } from 'react';
import { DashboardSidebar } from '../DashboardSidebar/DashboardSidebar';
import { DashboardNav } from './Navs/DashboardNav';
import { VStack, NAV_BAR_HEIGHT, Box } from '@spoke/common';

export const DashboardLayout: FC = ({ children }) => (
  <VStack minH="100vh" alignItems="flex-start" spacing={0}>
    <DashboardNav />
    <Box display="flex" flex="1" w="full">
      <DashboardSidebar />
      <Box
        h={`calc(100vh - ${NAV_BAR_HEIGHT})`}
        overflowY="scroll"
        bg="white"
        flex="1"
        py={9}
        pt={4}
        px={5}
      >
        {children}
      </Box>
    </Box>
  </VStack>
);
