import { makeVar, useReactiveVar } from '@apollo/client';
import { createContext, useContext, FC } from 'react';

type NetworkContextData = {
  shouldPoll: boolean;
};

export const wsBlockedVar = makeVar<boolean>(false);
export const wsConnectedOnceVar = makeVar<boolean>(false);

export const appMustUpdateVar = makeVar<boolean>(false);
export const lastUserKickMsVar = makeVar<number>(0);

const NetworkContext = createContext({} as NetworkContextData);

export const useNetworkContext = () => useContext(NetworkContext);

export const NetworkContextProvider: FC = ({ children }) => {
  const wsConnectionFailed = useReactiveVar(wsBlockedVar);

  const networkContext: NetworkContextData = {
    shouldPoll: wsConnectionFailed ? true : false,
  };

  return (
    <NetworkContext.Provider value={networkContext}>
      {children}
    </NetworkContext.Provider>
  );
};
