import { useCallback } from 'react';
import { useMarkTeamInsightAsSeenMutation } from '@spoke/graphql';
import { log } from '@spoke/common';

export const useMarkTeamInsightAsSeenHandler = () => {
  const [markTeamInsightAsSeen] = useMarkTeamInsightAsSeenMutation();

  const handleMarkTeamInsightAsSeen = useCallback(
    async ({ teamInsightId }: { teamInsightId: string }): Promise<boolean> => {
      if (!teamInsightId) {
        log.error('Missing critical data to mark team insight as seen', {
          teamInsightId,
        });
        return false;
      }

      log.info('Marking team insight as seen', { teamInsightId });

      const { errors } = await markTeamInsightAsSeen({
        variables: { teamInsightId },
      });

      if (errors) {
        log.error('markTeamInsightAsSeen call responded with errors', {
          errors,
        });
        return false;
      }

      return true;
    },
    [markTeamInsightAsSeen]
  );

  return [handleMarkTeamInsightAsSeen];
};
