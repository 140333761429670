import { ImprovementGoalDataResult } from '../queries';
import { PointChartData } from '@spoke/charts';

export const improvementGoalDataToPointChartData = (
  improvementGoalData: ImprovementGoalDataResult
): PointChartData[] =>
  improvementGoalData.map((data) => ({
    id: data.id,
    date: new Date(data.date || 0).toISOString(),
    value: data.value,
    children: data.children
      .map((child) =>
        !child
          ? null
          : {
              id: child.id,
              date: new Date(child.date || 0).toISOString(),
              value: child.value,
            }
      )
      .filter(Boolean) as PointChartData[],
  }));
