import { forwardRef } from '@chakra-ui/react';
import { useState } from 'react';
import { NO_OP, PopoverAnchor } from '@spoke/common';

type PointChartPointProps = {
  label: string;
  color: string;
  x: number;
  y: number;
  selected: boolean;
  isInteractive: boolean;
  protectLabel: boolean;
  isEntering: boolean;
  opacity: number;
  onClick?: () => void;
};

export const PointChartPoint = forwardRef<PointChartPointProps, 'g'>(
  (
    {
      color,
      label,
      x,
      y,
      onClick,
      selected,
      opacity,
      isEntering,
      isInteractive,
      protectLabel,
    },
    ref
  ) => {
    const [hovering, setHovering] = useState(false);
    const labelWidth = label.length * 8;

    const Point = (
      <g ref={ref}>
        <rect
          transform="translate(-18, -24)"
          x={x}
          y={y}
          onClick={!isInteractive ? NO_OP : onClick}
          height={36}
          width={36}
          fill="transparent"
          opacity={isEntering ? 0 : opacity}
          onMouseMove={() => (!isInteractive ? null : setHovering(true))}
          onMouseLeave={() => (!isInteractive ? null : setHovering(false))}
        />
        <rect
          transform={`translate(-${labelWidth / 2}, -19)`}
          x={x}
          y={y}
          onClick={!isInteractive ? NO_OP : onClick}
          height={11}
          width={labelWidth}
          fill={protectLabel ? 'white' : 'transparent'}
          opacity={isEntering ? 0 : opacity}
          onMouseMove={() => (!isInteractive ? null : setHovering(true))}
          onMouseLeave={() => (!isInteractive ? null : setHovering(false))}
        />
        <text
          pointerEvents="none"
          x={x}
          y={y}
          transform={`translate(0, ${hovering || selected ? -12 : -10})`}
          textAnchor="middle"
          style={{
            transition:
              'font-size 0.2s ease-out, opacity 0.2s ease-out, x 0.2s ease-out, y 0.2s ease-out',
          }}
          fontSize={hovering || selected ? 13 : 12}
          opacity={isEntering ? 0 : opacity}
          fontWeight={500}
          fill={color}
        >
          {label}
        </text>
        <circle
          opacity={isEntering ? 0 : opacity}
          pointerEvents="none"
          r={3}
          cx={x}
          cy={y}
          style={{ transition: 'x 0.2s ease-out, y 0.2s ease-out' }}
          fill={color}
        />
        <circle
          pointerEvents="none"
          opacity={isEntering ? 0 : (opacity || 1) * 0.3}
          r={hovering || selected ? 6 : 3}
          cx={x}
          cy={y}
          style={{
            transition:
              'stroke 0.2s ease-out, r 0.1s ease-out, opacity 0.2s ease-out, x 0.2s ease-out, y 0.2s ease-out',
          }}
          fill={color}
        />
      </g>
    );

    return selected ? <PopoverAnchor>{Point}</PopoverAnchor> : Point;
  }
);
