import { FC, useCallback } from 'react';
import { AuthLayout } from './AuthLayout';
import { BoardLayout } from './BoardLayout';
import { DashboardLayout } from './DashboardLayout';
import { OnboardingLayout } from './OnboardingLayout';
import { OrgLayout } from './OrgLayout';
import { SettingsLayout } from './SettingsLayout';
import {
  compareRoutes,
  SpkRouteKey,
  useRouter,
  useRoutes,
} from '@spoke/common';

export const ROUTES_BY_LAYOUT: Record<string, readonly SpkRouteKey[]> = {
  auth: [SpkRouteKey.Auth, SpkRouteKey.AuthView],
  onboarding: [
    SpkRouteKey.Onboarding,
    SpkRouteKey.OnboardingCreateTeam,
    SpkRouteKey.OnboardingSurvey,
  ],
  dashboard: [
    SpkRouteKey.Dashboard,
    SpkRouteKey.Retrospectives,
    SpkRouteKey.ImprovementGoals,
    SpkRouteKey.ActionItems,
    SpkRouteKey.ParkingLot,
    SpkRouteKey.TeamMembers,
    SpkRouteKey.ProgramTeams,
    SpkRouteKey.TeamSettings,
    SpkRouteKey.Integrations,
    SpkRouteKey.Metrics,
    SpkRouteKey.Billing,
    SpkRouteKey.ImprovementGoalDrilldown,
  ],
  organization: [SpkRouteKey.Users],
  board: [SpkRouteKey.Board],
  settings: [SpkRouteKey.UserSettings],
} as const;

export const LayoutProvider: FC = ({ children }) => {
  const router = useRouter();
  const routes = useRoutes();

  const isRoutePartOf = useCallback(
    (module: keyof typeof ROUTES_BY_LAYOUT, route: string) => {
      const moduleRoutes = ROUTES_BY_LAYOUT[module];

      if (!moduleRoutes) {
        throw new Error(
          `Unknown routing module ${module}. Please specify one of ${Object.keys(
            ROUTES_BY_LAYOUT
          )}`
        );
      }

      return moduleRoutes.some((routeKey: string) =>
        compareRoutes(
          typeof routes[routeKey] === 'function'
            ? routes[routeKey]()
            : routes[routeKey],
          route
        )
      );
    },
    [routes]
  );

  const isAuthRoute = isRoutePartOf('auth', router.route);
  const isOnboardingRoute = isRoutePartOf('onboarding', router.route);
  const isDashboardRoute = isRoutePartOf('dashboard', router.route);
  const isOrganizationRoute = isRoutePartOf('organization', router.route);
  const isBoardRoute = isRoutePartOf('board', router.route);
  const isSettingsRoute = isRoutePartOf('settings', router.route);

  if (!router.isReady) return null;
  if (isAuthRoute) return <AuthLayout>{children}</AuthLayout>;
  if (isOnboardingRoute) return <OnboardingLayout>{children}</OnboardingLayout>;
  if (isDashboardRoute) return <DashboardLayout>{children}</DashboardLayout>;
  if (isOrganizationRoute) return <OrgLayout>{children}</OrgLayout>;
  if (isBoardRoute) return <BoardLayout>{children}</BoardLayout>;
  if (isSettingsRoute) return <SettingsLayout>{children}</SettingsLayout>;
  return <>{children}</>;
};
