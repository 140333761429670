import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useCurrentBoardRules } from '../../queries';
import { isDraggingVar, isDraggingAGroupCardVar } from '../../util';
import {
  useMoveCardIntoListMutation,
  useCreateGroupCardMutation,
  useAddCardToGroupMutation,
  Board,
  List,
  Card,
} from '@spoke/graphql';
import {
  useToast,
  useCurrentBoardId,
  useCurrentUser,
  TOAST_ERROR_GENERIC,
  getBoardFromCache,
  deepClone,
  privateCardsFilter,
  noneAreNullish,
  isGroup,
  turnCardsIntoGroup,
  optimisticCreateGroupCard,
  optimisticAddCardToGroup,
  optimisticMoveCardIntoList,
  log,
} from '@spoke/common';

export const useBoardDragEndHandler = () => {
  // This could use some refactoring.
  // These are the possible cases:
  // 1. Moving card to the same list
  // 2. Moving card to other list
  // 3. Moving group to same list
  // 4. Moving group to other list
  // 5. Adding card to a group in same list
  // 6. Adding card to a group in other list
  // 7. Removing a card from a group to the same list
  // 8. Removing a card from a group to other list
  // 9. Creating a group from cards in same lsit
  // 10. Creating a group from cards in different lists
  // 11. Moving card inside group to inside of same group
  // 12. Moving card inside group to another group
  // 13. Moving card inside group to creating another group in same list
  // 14. Moving card inside group to creating another group in different list

  const [toast] = useToast();
  const [moveCardIntoList] = useMoveCardIntoListMutation();
  const [createGroupCard] = useCreateGroupCardMutation();
  const [addCardToGroup] = useAddCardToGroupMutation();
  const [boardId] = useCurrentBoardId();
  const { cache } = useApolloClient();
  const { privateCards } = useCurrentBoardRules();

  const [currentUser] = useCurrentUser();

  const handleDragEnd = useCallback(
    async (result: DropResult) => {
      isDraggingVar(false);
      setTimeout(() => isDraggingAGroupCardVar(false), 100);

      const { draggableId, source, destination, combine: grouping } = result;

      if (!boardId) {
        log.error('Cannot move cards without loaded boardId', { boardId });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      let board = getBoardFromCache({ boardId, cache });
      const originalBoard = deepClone(board) as Board;

      if (!board) {
        log.error('Failed to move cards: could not find cached board', {
          boardId,
          board,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      if (privateCards && currentUser?.id !== null) {
        const _board = deepClone(board) as Board;
        if (_board.lists) {
          _board.lists?.map((list) => {
            if (list?.cards !== undefined && list.cards !== null) {
              list.cards = list.cards.filter(
                privateCardsFilter(currentUser?.id as string)
              );
            }

            return list;
          });
        }

        board = _board;
      }

      if (grouping) {
        const dragCardAlreadyInAGroup = draggableId.includes('childOf');

        if (dragCardAlreadyInAGroup) {
          const dragCardId = draggableId.split(':')[0];
          const dragGroupListId = source.droppableId.split(':')[0];
          const dragGroupId = draggableId.split(':')[2];

          const dropListId = grouping.droppableId.split(':')[0];
          const dropCardId = grouping?.draggableId.split(':')[0];

          const dragList = board?.lists?.find(
            (list) => list?.id === dragGroupListId
          ) as List;
          const dragGroup = dragList?.cards?.find(
            (card) => card?.id === dragGroupId
          ) as Card;
          const dragCard = dragGroup.children?.find(
            (card) => card?.id === dragCardId
          ) as Card;

          const dropList = board?.lists?.find(
            (list) => list?.id === dropListId
          ) as List;
          const dropCard = dropList?.cards?.find(
            (card) => card?.id === dropCardId
          ) as Card;

          if (
            !noneAreNullish(
              dragList,
              dragCard,
              dragGroup,
              dropList,
              dropCard,
              board
            )
          ) {
            log.error('Missing critical data to operate from group cards', {
              dragList,
              dragCard,
              dropList,
              dragGroup,
              dropCard,
              board,
            });
            toast(TOAST_ERROR_GENERIC);
            return;
          }

          if (!isGroup(dropCard)) {
            // dropCard is not a group. Make it one
            const groupCard = turnCardsIntoGroup({
              dragCard,
              dropCard,
            });

            const { errors } = await createGroupCard({
              variables: {
                dragCardId: dragCard.id,
                dropCardId: dropCard.id,
              },
              optimisticResponse: {
                __typename: 'Mutation',
                createGroupCard: optimisticCreateGroupCard({
                  board: board as Board,
                  groupCard,
                  dragCard,
                  dropCard,
                }),
              },
            });

            if (errors) {
              log.error('CreateGroupCard call responded with errors', {
                errors,
              });
              toast(TOAST_ERROR_GENERIC);
              return;
            }
          } else {
            // dropCard is a group. Add dragCard to it
            const newCardIdsOrder = [
              ...(dropCard.children ?? []),
              dragCard,
            ]?.map((_card) => _card?.id) as string[];

            const { errors } = await addCardToGroup({
              variables: {
                cardToAddId: dragCard.id,
                groupId: dropCard.id,
                newChildrenIdsOrder: newCardIdsOrder,
              },
              optimisticResponse: {
                __typename: 'Mutation',
                addCardToGroup: optimisticAddCardToGroup({
                  board: board as Board,
                  dragCard,
                  dropCard,
                }),
              },
            });

            if (errors) {
              log.error(
                'UpdateGroupCard (add to group) call responded with errors',
                {
                  errors,
                }
              );
              toast(TOAST_ERROR_GENERIC);
              return;
            }
          }

          return;
        }

        if (!dragCardAlreadyInAGroup) {
          const dragCardId = draggableId.split(':')[0];
          const dragListId = source.droppableId.split(':')[0];

          const dropListId = grouping.droppableId.split(':')[0];
          const dropCardId = grouping?.draggableId.split(':')[0];

          const dragList = board?.lists?.find(
            (list) => list?.id === dragListId
          ) as List;
          const dragCard = dragList?.cards?.find(
            (card) => card?.id === dragCardId
          ) as Card;

          const dropList = board?.lists?.find(
            (list) => list?.id === dropListId
          ) as List;

          const dropCard = dropList?.cards?.find(
            (card) => card?.id === dropCardId
          ) as Card;

          if (!noneAreNullish(dragList, dragCard, dropList, dropCard, board)) {
            log.error('Missing critical data to operate on group cards', {
              dragList,
              dragCard,
              dropList,
              dropCard,
              board,
            });
            toast(TOAST_ERROR_GENERIC);
            return;
          }

          if (!isGroup(dropCard)) {
            // dropCard is not a group. Make it one
            const groupCard = turnCardsIntoGroup({
              dragCard,
              dropCard,
            });

            const { errors } = await createGroupCard({
              variables: {
                dragCardId: dragCard.id,
                dropCardId: dropCard.id,
              },
              optimisticResponse: {
                __typename: 'Mutation',
                createGroupCard: optimisticCreateGroupCard({
                  board: board as Board,
                  groupCard,
                  dragCard,
                  dropCard,
                }),
              },
            });

            if (errors) {
              log.error('CreateGroupCard call responded with errors', {
                errors,
              });
              // Silenced for now. This is very common in busy retros and not a big deal
              // toast(TOAST_ERROR_GENERIC);
              return;
            }
          } else {
            // dropCard is a group. Add dragCard to it
            const newCardIdsOrder = [
              ...(dropCard.children ?? []),
              dragCard,
            ]?.map((_card) => _card?.id) as string[];

            const { errors } = await addCardToGroup({
              variables: {
                cardToAddId: dragCard.id,
                groupId: dropCard.id,
                newChildrenIdsOrder: newCardIdsOrder,
              },
              optimisticResponse: {
                __typename: 'Mutation',
                addCardToGroup: optimisticAddCardToGroup({
                  board: board as Board,
                  dragCard,
                  dropCard,
                }),
              },
            });

            if (errors) {
              log.error(
                'UpdateGroupCard (add to group) call responded with errors',
                {
                  errors,
                }
              );
              toast(TOAST_ERROR_GENERIC);
              return;
            }
          }
        }
      }

      if (!grouping) {
        const dragCardId = draggableId.split(':')[0];
        const dragListId = source.droppableId;

        const didNotMove =
          dragListId === destination?.droppableId &&
          source.index === destination?.index;

        if (!destination || didNotMove) return;

        const expected = optimisticMoveCardIntoList({
          board,
          cardId: dragCardId,
          fromId: dragListId,
          toId: destination.droppableId,
          toIndex: destination.index,
        });

        const newCardIdsOrder = expected.lists
          ?.find((list) => list?.id === destination.droppableId)
          ?.cards?.map((card) => card?.id) as string[];

        if (!newCardIdsOrder || !noneAreNullish(...newCardIdsOrder)) {
          log.error(
            'Mising critical data while performing MoveCardIntoList operation',
            { newCardIdsOrder }
          );
          toast(TOAST_ERROR_GENERIC);
          return;
        }

        if (privateCards && currentUser?.id !== null) {
          // this hack is for when private cards are enabled so that we trick
          // the optimistic response into thinking that theirs the correct
          // number of cards in the list for the list card number badge
          if (expected.lists && originalBoard.lists) {
            for (const list of expected.lists) {
              const listIndex = expected.lists.indexOf(list);
              if (
                expected.lists[listIndex]?.cards &&
                originalBoard.lists[listIndex]?.cards
              ) {
                const originalSize =
                  originalBoard.lists[listIndex]?.cards?.length || 0;
                const newSize = expected.lists[listIndex]?.cards?.length || 0;
                const numberOfShadowCarsToAdd = originalSize - newSize;

                if (newSize < originalSize) {
                  for (let i = 0; i < numberOfShadowCarsToAdd; i++) {
                    expected?.lists[listIndex]?.cards?.push({
                      archived: false,
                      authorId: '',
                      children: [],
                      comments: [],
                      createdAt: new Date().getMilliseconds(),
                      index: 0,
                      listId: '',
                      originalListBadge: null,
                      parentId: '',
                      promotedToPrograms: [],
                      text: '',
                      updatedAt: new Date().getMilliseconds(),
                      votes: [],
                      wasDiscussed: false,
                      __typename: 'Card',
                      id: 'shadow',
                    });
                  }
                }
              }
            }
          }
        }

        const { errors } = await moveCardIntoList({
          variables: {
            listId: destination.droppableId,
            newCardIdsOrder,
            cardId: dragCardId,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            moveCardIntoList: expected,
          },
        });

        if (errors) {
          log.error('MoveCardIntoList call responded with errors', { errors });
          toast(TOAST_ERROR_GENERIC);
        }
      }
    },
    [
      boardId,
      cache,
      createGroupCard,
      moveCardIntoList,
      toast,
      addCardToGroup,
      privateCards,
      currentUser,
    ]
  );

  return [handleDragEnd];
};
