import {
  ComponentStyleConfig,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export const LinkComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    color: 'primary.500',
  },
};

type Props = ChakraLinkProps;

export const Link = forwardRef<HTMLAnchorElement, Props>((props, ref) => (
  <ChakraLink ref={ref} {...props} />
));
