import { useCallback, useEffect, useState } from 'react';

/**
 * This is to help us catch when users get stuck in some flow
 */
export const useTimeout = (ms = 10000) => {
  const [timedOut, setTimedOut] = useState(false);
  const [counting, setCounting] = useState(false);
  const enableTimeout = useCallback(() => setCounting(true), []);
  const disableTimeout = useCallback(() => setCounting(false), []);

  useEffect(() => {
    if (!counting) return;
    const timeout = setTimeout(() => {
      setTimedOut(true);
    }, ms);
    return () => clearTimeout(timeout);
  }, [counting, ms]);

  return { timedOut, enableTimeout, disableTimeout };
};
