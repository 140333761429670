import { ChakraProps, Thead as ChakraThead } from '@chakra-ui/react';
import { FC, forwardRef } from 'react';

type Props = ChakraProps;

export const Thead: FC<Props> = forwardRef<HTMLTableSectionElement, Props>(
  (props, ref) => (
    <ChakraThead
      borderBottomStyle="solid"
      borderBottomColor="gray.100"
      borderBottomWidth="1px"
      ref={ref}
      {...props}
    />
  )
);
