import { useEffect, useRef } from 'react';
import { useCurrentUser } from '@spoke/common';
import { useTeamTreeQuery, useTeamTreeLazyQuery } from '@spoke/graphql';

type QueryRef = ReturnType<typeof useTeamTreeQuery>;
type QueryData = {
  teamTree: NonNullable<QueryRef['data']>['topLevelTeams'] | null;
  watchedTeamTree: NonNullable<QueryRef['data']>['topLevelWatchedTeams'] | null;
};

export const useTeamTree = (): [QueryData, QueryRef] => {
  const [currentUser] = useCurrentUser();
  const loadedUserId = useRef<string | undefined>();
  const [loadTreeQuery, treeQuery] = useTeamTreeLazyQuery();

  useEffect(() => {
    if (!currentUser?.id) return;
    if (loadedUserId.current === currentUser.id) return;
    loadedUserId.current = currentUser?.id;
    if (treeQuery.called) treeQuery.refetch();
    else loadTreeQuery();
  }, [currentUser?.id, treeQuery, loadTreeQuery]);

  const teamTree =
    treeQuery.data?.topLevelTeams ??
    treeQuery.previousData?.topLevelTeams ??
    null;

  const watchedTeamTree =
    treeQuery.data?.topLevelWatchedTeams ??
    treeQuery.previousData?.topLevelWatchedTeams ??
    null;

  return [{ teamTree, watchedTeamTree }, treeQuery];
};
