import { As } from '@chakra-ui/react';
import { FC } from 'react';
import { GridItem, Flex, Icon, Box, Text } from '@spoke/common';

type TeamIntegrationsGridItemProps = {
  empty: boolean;
  title: string;
  icon: As;
  description: string;
  linkSelectorComponent: () => JSX.Element;
  emptyStateComponent: () => JSX.Element;
};

export const TeamSettingsIntegrationsGridItem: FC<
  TeamIntegrationsGridItemProps
> = ({
  empty,
  title,
  icon,
  description,
  linkSelectorComponent,
  emptyStateComponent,
}) => (
  <GridItem w="full">
    {empty ? (
      <Box
        alignItems="stretch"
        alignSelf="stretch"
        layerStyle="outlineGray"
        w="100%"
      >
        <Flex flexDir="column" flex="1" flexGrow={1}>
          <Text fontSize={15} fontWeight={500} color="gray.700">
            <Icon as={icon} mr={1} mb="3px" />
            {title}
          </Text>
          <Text mb={2} fontSize={14} color="gray.500">
            {description}
          </Text>
          {linkSelectorComponent()}
        </Flex>
      </Box>
    ) : (
      emptyStateComponent()
    )}
  </GridItem>
);
