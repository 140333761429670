import { FC } from 'react';
import { MenuList, Flex, MenuItem, Text } from '@spoke/common';
import { Organization } from '@spoke/graphql';

type OrgDropdownProps = {
  orgs: Organization[];
  currentOrgId?: string | null;
  onSwitchOrg: (id: string) => unknown;
};

export const OrgDropdown: FC<OrgDropdownProps> = ({
  orgs,
  onSwitchOrg,
  currentOrgId,
}) => (
  <MenuList minW={260}>
    <Text mx={3} mt={2} fontSize={13} color="gray.500" fontWeight={500} mb={2}>
      Switch to another organization
    </Text>
    <Flex
      px={3}
      pb={2}
      pt={1}
      maxH={500}
      overflowY="auto"
      direction="column"
      w="full"
    >
      {orgs?.map(
        (org) =>
          org && (
            <MenuItem
              key={org.id}
              py={2}
              pl={2}
              color={currentOrgId === org.id ? 'primary.500' : 'gray.600'}
              onClick={() => onSwitchOrg(org.id as string)}
            >
              {org?.name}
            </MenuItem>
          )
      )}
    </Flex>
  </MenuList>
);
