import { FC, useCallback, useState } from 'react';
import { useCurrentTeamBoards } from '../../queries';
import { FactsStatsActionItemReviewColumn } from './FactsStatsActionItemReviewColumn';
import { MemoizedFactsStatsActionItemsReviewAnalytics } from './FactsStatsActionItemsReviewAnalytics';
import { ImprovementGoalDistribution, ActionItem } from '@spoke/graphql';
import { Skeleton, HStack, isNullish } from '@spoke/common';
import {
  useActionItemsReview,
  useActionItems,
  useDeleteActionItemHandler,
  useToggleActionItemStatusHandler,
  EditActionItemModal,
} from '@spoke/action-item';

export const FactsStatsActionItemsReview: FC = () => {
  const [{ teamBoards }] = useCurrentTeamBoards({
    variables: { limit: 1 },
  });

  const [
    actionItemReviewData,
    { loading: actionItemReviewLoading, called: actionItemReviewCalled },
  ] = useActionItemsReview();

  const [actionItems, actionItemsQuery] = useActionItems();
  const [handleDeleteActionItem] = useDeleteActionItemHandler();
  const [handleToggleActionItemStatus] = useToggleActionItemStatusHandler();

  const [editingActionItemId, setEditingActionItemId] = useState<string | null>(
    null
  );

  const onEditActionItemClick = useCallback((actionItemId: string) => {
    setEditingActionItemId(actionItemId);
  }, []);

  const lastRetroDate = !isNullish(teamBoards?.[0]?.createdAt)
    ? (new Date(teamBoards?.[0]?.createdAt || 0) as Date)
    : null;

  const resolvedSinceLastRetro = actionItems.resolved
    .filter(
      (item) =>
        lastRetroDate &&
        item?.resolvedAt &&
        new Date(item?.resolvedAt) >= lastRetroDate
    )
    .sort((a, b) => (b?.resolvedAt || 0) - (a?.resolvedAt || 0))
    .filter(Boolean);

  const unresolved = actionItems.unresolved.filter(Boolean);

  const averageTimeToResolve =
    actionItemReviewData?.averageTimeToResolveActionItemsInDays ?? null;

  const improvementGoalDistributions =
    actionItemReviewData?.improvementGoalDistribution ?? null;

  const loading = Boolean(
    !lastRetroDate ||
      actionItemsQuery.loading ||
      actionItemReviewLoading ||
      !actionItemReviewCalled
  );

  return (
    <Skeleton
      w="full"
      alignItems="stretch"
      pr={4}
      mt={4}
      mx="auto"
      maxW={1400}
      gap={6}
      display="flex"
      flexDir="column"
      isLoaded={!loading}
    >
      <MemoizedFactsStatsActionItemsReviewAnalytics
        averageTimeToResolve={averageTimeToResolve}
        improvementGoalDistributions={
          improvementGoalDistributions as ImprovementGoalDistribution[]
        }
      />
      <HStack w="full" align="start" pb={10}>
        <FactsStatsActionItemReviewColumn
          actionItems={resolvedSinceLastRetro as ActionItem[]}
          title="Resolved Action Items since last retro"
          emptyStateTitle="You haven't resolved any action items since last retro."
          emptyStateDescription="Resolved action items from the last retro you ran will be shown here."
          isLoading={loading}
          onDelete={handleDeleteActionItem}
          onEditClick={onEditActionItemClick}
          onResolveToggle={handleToggleActionItemStatus}
        />
        <FactsStatsActionItemReviewColumn
          actionItems={unresolved as ActionItem[]}
          title="Unresolved Action Items"
          emptyStateTitle="You have no Unresolved Action Items to show."
          emptyStateDescription="Unresolved Action Items will be shown here."
          isLoading={loading}
          onDelete={handleDeleteActionItem}
          onEditClick={onEditActionItemClick}
          onResolveToggle={handleToggleActionItemStatus}
        />
      </HStack>
      <EditActionItemModal
        actionItemId={editingActionItemId}
        isOpen={Boolean(editingActionItemId)}
        onClose={() => setEditingActionItemId(null)}
      />
    </Skeleton>
  );
};
