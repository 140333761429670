import { List, UpdateListMutation } from '@spoke/graphql';

type OptimisticDeleteListArgs = {
  list: List;
};
export const optimisticDeleteList = ({
  list,
}: OptimisticDeleteListArgs): UpdateListMutation => ({
  __typename: 'Mutation',
  updateList: {
    ...(list || {}),
    archived: true,
  },
});
