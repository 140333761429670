import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import { useFinishLivePollsByBoardIdMutation } from '@spoke/graphql';

export const useFinishLivePollsByBoardIdHandler = () => {
  const [finishLivePolls] = useFinishLivePollsByBoardIdMutation();
  const [toast] = useToast();

  const handleEndLivePoll = useCallback(
    async ({ boardId }: { boardId: string }): Promise<boolean> => {
      if (!boardId) {
        log.error('Missing critical data to finishLivePollByBoardId', {
          boardId,
        });
        return false;
      }

      log.info('Manually finishing LivePolls by board id', { boardId });

      const { errors } = await finishLivePolls({
        variables: { boardId },
      });

      if (errors) {
        log.error('finishLivePollByBoardId call responded with errors', {
          boardId,
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [finishLivePolls, toast]
  );

  return [handleEndLivePoll];
};
