import { TooltipProps } from '@chakra-ui/react';
import { FC } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { Box } from './Box';
import { Icon, IconProps } from './Icon';
import { Tooltip } from './Tooltip';

type Props = IconProps & {
  text?: string;
  maxW?: number;
  size?: number;
  placement?: TooltipProps['placement'];
  tooltip?: Omit<TooltipProps, 'children'>;
};
export const InfoTooltip: FC<Props> = ({
  text,
  maxW,
  size = 13,
  tooltip,
  placement,
  ...rest
}) => (
  <Tooltip
    label={text}
    openDelay={100}
    placement={placement ?? 'top'}
    fontSize={12}
    maxW={maxW ?? 190}
    variant="white"
    hasArrow
    {...tooltip}
  >
    <Box tabIndex={-1} pt="2px">
      <Icon
        as={BiInfoCircle}
        color="gray.500"
        w={`${size}px`}
        h={`${size}px`}
        {...rest}
      />
    </Box>
  </Tooltip>
);
