import { FC } from 'react';
import { BsArrowUpCircle, BsArrowUpCircleFill } from 'react-icons/bs';
import {
  NO_OP,
  truncateWithCount,
  MenuItemProps,
  MenuItem,
  Icon,
  Text,
} from '@spoke/common';

type Props = MenuItemProps & {
  promotedToPrograms?: Array<{ id: string; name: string }>;
};
export const BoardCardMenuPromoteButton: FC<Props> = ({
  promotedToPrograms,
  onClick,
  ...rest
}) => {
  const isPromoted = Boolean(promotedToPrograms?.length);
  return (
    <MenuItem
      onClick={onClick ? onClick : NO_OP}
      border={isPromoted ? '2px solid' : undefined}
      borderColor={isPromoted ? 'primary.500' : undefined}
      bg={isPromoted ? 'primary.50' : undefined}
      borderRadius={isPromoted ? 'md' : undefined}
      boxShadow={isPromoted ? 'md' : undefined}
      transition="box-shadow 0.2s ease-out"
      _hover={{
        bg: isPromoted ? 'primary.50' : undefined,
        boxShadow: isPromoted
          ? '3px 3px 3px rgba(0, 0, 0, 0.1) !important'
          : undefined,
      }}
      {...rest}
    >
      <Icon
        mr={1}
        mb="2px"
        w={4}
        h={4}
        color={isPromoted ? 'primary.500' : 'gray.700'}
        as={isPromoted ? BsArrowUpCircleFill : BsArrowUpCircle}
      />
      <Text pt="1px">
        {!isPromoted
          ? 'Promote to program'
          : `Promoted to ${truncateWithCount(
              promotedToPrograms || [],
              (program) => program.name,
              30
            )}`}
      </Text>
    </MenuItem>
  );
};
