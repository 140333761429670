import { FC, useState, useRef } from 'react';
import { useTeamMetricsFeatureState, TeamMetricsState } from '../../queries';
import {
  TeamGithubRepoLinkSelectorRefAttributes,
  TeamBitbucketRepoLinkSelectorRefAttributes,
  TeamGithubRepoLinkSelector,
  TeamBitbucketRepoLinkSelector,
} from '../LinkSelector';
import { MetricProviderIcons } from '../MetricProviderIcons';
import { MetricsProviderCategory } from '@spoke/graphql';
import {
  FlexProps,
  useSourceControlRateLimitLock,
  Flex,
  HStack,
  Button,
  Text,
} from '@spoke/common';

export const SourceControlMetricsTeamInstallBox: FC<FlexProps> = ({
  ...rest
}) => {
  const [sourceControlRateLimited] = useSourceControlRateLimitLock();

  const featureState = useTeamMetricsFeatureState();
  const allowGithub =
    !featureState.githubMetrics.hidden &&
    featureState.githubMetrics.state === TeamMetricsState.NotSetupInTeam;

  const allowBitbucket =
    !featureState.bitbucketMetrics.hidden &&
    featureState.bitbucketMetrics.state === TeamMetricsState.NotSetupInTeam;

  const [linkedGithubReposChanged, setLinkedGithubReposChanged] =
    useState(false);
  const [linkedBitbucketReposChanged, setLinkedBitbucketReposChanged] =
    useState(false);

  const linkedReposChanged =
    linkedGithubReposChanged || linkedBitbucketReposChanged;

  const [linkedGithubReposLoading, setLinkedGithubReposLoading] =
    useState(false);
  const [linkedBitbucketReposLoading, setLinkedBitbucketReposLoading] =
    useState(false);

  const linkedReposLoading =
    linkedGithubReposLoading || linkedBitbucketReposLoading;

  const githubRef = useRef<TeamGithubRepoLinkSelectorRefAttributes>();
  const bitbucketRef = useRef<TeamBitbucketRepoLinkSelectorRefAttributes>();

  const onSave = async () => {
    if (linkedGithubReposChanged) {
      githubRef.current?.onSaveLinkedGithubRepos?.();
    }

    if (linkedBitbucketReposChanged) {
      bitbucketRef.current?.onSaveLinkedBitbucketRepos?.();
    }
  };

  if (sourceControlRateLimited) return null;

  return (
    <Flex
      flex={1}
      flexDir="column"
      layerStyle="outlineGray"
      px={5}
      py={4}
      {...rest}
    >
      <Flex justifyContent="space-between">
        <Text fontWeight={500}>Source Control</Text>
        <HStack gap={0.5} mt={1}>
          <MetricProviderIcons
            providerCategory={MetricsProviderCategory.SourceControl}
            show="installed-on-org"
          />
        </HStack>
      </Flex>
      <Text fontSize={14} color="gray.600" mb={1}>
        Choose which {allowGithub && 'GitHub'}
        {allowGithub && allowBitbucket && ' or '}
        {allowBitbucket && 'BitBucket'} repositories are linked to this team to
        get insights on your work.
      </Text>
      <Flex flex={1} gap={2} flexDir="column" {...rest}>
        {allowGithub && (
          <TeamGithubRepoLinkSelector
            flexDir="column"
            justifyContent="flex-start"
            showSaveButton={false}
            ref={(ref) => (githubRef.current = ref as any)}
            onChange={setLinkedGithubReposChanged}
            onLoadingChange={setLinkedGithubReposLoading}
            showProductIcon
          />
        )}
        {allowBitbucket && (
          <TeamBitbucketRepoLinkSelector
            flexDir="column"
            justifyContent="flex-start"
            showSaveButton={false}
            ref={(ref) => (bitbucketRef.current = ref as any)}
            onChange={setLinkedBitbucketReposChanged}
            onLoadingChange={setLinkedBitbucketReposLoading}
          />
        )}
        <Button
          isDisabled={!linkedReposChanged}
          isLoading={linkedReposLoading}
          height={10}
          onClick={onSave}
          alignSelf="flex-end"
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};
