import {
  ComponentStyleConfig,
  Switch as ChakraSwitch,
  SwitchProps as ChakraSwitchProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export const SwitchComponentStyle: ComponentStyleConfig = {};

type Props = ChakraSwitchProps;

export const Switch = forwardRef<HTMLInputElement, Props>((props, ref) => (
  <ChakraSwitch ref={ref} {...props} />
));
