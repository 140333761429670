import { AnimatePresence } from 'framer-motion';
import { FC, useState } from 'react';
import { MdOutlineBookmarkBorder } from 'react-icons/md';
import { ActionPlanActionItemCard } from './ActionPlanActionItemCard';
import { ActionPlanCreateActionItem } from './ActionPlanCreateActionItem';
import {
  MotionFlex,
  useDisclosure,
  HStack,
  Square,
  Icon,
  Badge,
  Flex,
  Button,
  Text,
  useCurrentBoardId,
} from '@spoke/common';
import {
  CreateActionItemModal,
  EditActionItemModal,
  useActionItems,
  useDeleteActionItemHandler,
} from '@spoke/action-item';

type ActionPlanProps = {
  isOpen: boolean;
};
export const ActionPlan: FC<ActionPlanProps> = ({ isOpen }) => {
  const [boardId] = useCurrentBoardId();
  const createActionItem = useDisclosure();
  const [handleDeleteActionItem] = useDeleteActionItemHandler();

  const [editingActionItemId, setEditingActionItemId] = useState<string | null>(
    null
  );

  const [{ unresolved: actionItemsDuringThisRetro }] = useActionItems({
    variables: { boardId },
  });

  const isEmpty = !actionItemsDuringThisRetro.length;

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <MotionFlex
            bg="white"
            flexDir="column"
            boxShadow="lgInverse"
            exit={{ right: '-250px' }}
            initial={{ right: '-250px' }}
            animate={{ opacity: 1, right: 0 }}
            transition={{ ease: 'easeOut', duration: 0.2 }}
            position="fixed"
            h="calc(100vh - 80px)"
            overflowX="auto"
            maxW="254px"
            zIndex={1}
          >
            <HStack position="sticky" top="0" bg="white" p={3}>
              <Square bg="primary.25" borderRadius="md" p={1}>
                <Icon
                  as={MdOutlineBookmarkBorder}
                  color="primary.500"
                  w={5}
                  h={5}
                />
              </Square>
              <Text fontWeight={500} color="gray.700">
                Action Plan
              </Text>
              {actionItemsDuringThisRetro.length && (
                <Badge bg="primary.25" color="primary.500">
                  {actionItemsDuringThisRetro.length}
                </Badge>
              )}
            </HStack>
            <Flex
              flex="1"
              alignItems="center"
              flexDir="column"
              gap={2}
              p={5}
              pt={1}
            >
              {isEmpty && (
                <Flex direction="column" mt={12}>
                  <Text textAlign="center" fontSize={14} fontWeight={500}>
                    No action items created during this retro yet.
                  </Text>
                  <Text
                    textAlign="center"
                    fontSize={14}
                    fontWeight={500}
                    color="gray.500"
                    mb={1}
                  >
                    Action items created during this retro can also be viewed in
                    Team action items.
                  </Text>
                  <Button w="full" size="lg" onClick={createActionItem.open}>
                    Create Action Item
                  </Button>
                </Flex>
              )}
              {!isEmpty && (
                <>
                  {actionItemsDuringThisRetro
                    .sort((a, b) => (a?.createdAt || 0) - (b?.createdAt || 0))
                    .map((item) =>
                      !item ? null : (
                        <ActionPlanActionItemCard
                          key={item.id}
                          actionItem={item}
                          onEditClick={setEditingActionItemId}
                          onDelete={handleDeleteActionItem}
                        />
                      )
                    )}
                  <ActionPlanCreateActionItem onClick={createActionItem.open} />
                </>
              )}
            </Flex>
          </MotionFlex>
        )}
      </AnimatePresence>
      <CreateActionItemModal
        isOpen={createActionItem.isOpen}
        onClose={createActionItem.close}
      />
      <EditActionItemModal
        actionItemId={editingActionItemId}
        isOpen={Boolean(editingActionItemId)}
        onClose={() => setEditingActionItemId(null)}
      />
    </>
  );
};
