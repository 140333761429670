import { FC } from 'react';
import { useUpdateTeamSettingsHandler } from '../handlers';
import { useCurrentTeam } from '../queries';
import { TeamFeatureSetModalItem } from './TeamFeatureSetModalItem';
import { TeamSettings } from '@spoke/graphql';
import {
  ModalProps,
  VStack,
  JiraIcon,
  BitbucketIcon,
  GithubIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  HStack,
  Heading,
  ModalCloseButton,
  ModalBody,
  Text,
} from '@spoke/common';

export type TeamFeatureSetModalProps = Omit<ModalProps, 'children'>;

export type DisableFeatureChange = Partial<
  Pick<
    TeamSettings,
    'jiraMetricsEnabled' | 'bitbucketMetricsEnabled' | 'githubMetricsEnabled'
  >
>;

const TeamFeatureSetModalBody: FC = () => {
  const [currentTeam] = useCurrentTeam();
  const [updateTeamSettingsHandler] = useUpdateTeamSettingsHandler();

  const onChange = (changes: DisableFeatureChange) => {
    if (!currentTeam?.settings) return;
    updateTeamSettingsHandler({
      teamId: currentTeam.id,
      newSettings: changes,
    });
  };

  if (!currentTeam?.settings) return null;

  return (
    <VStack>
      <TeamFeatureSetModalItem
        name="Jira Metrics"
        icon={JiraIcon}
        value={currentTeam.settings.jiraMetricsEnabled}
        onChange={(value) => onChange({ jiraMetricsEnabled: value })}
      />
      <TeamFeatureSetModalItem
        name="BitBucket Metrics"
        icon={BitbucketIcon}
        value={currentTeam.settings.bitbucketMetricsEnabled}
        onChange={(value) => onChange({ bitbucketMetricsEnabled: value })}
      />
      <TeamFeatureSetModalItem
        name="GitHub Metrics"
        icon={GithubIcon}
        value={currentTeam.settings.githubMetricsEnabled}
        onChange={(value) => onChange({ githubMetricsEnabled: value })}
      />
    </VStack>
  );
};

export const TeamFeatureSetModal: FC<TeamFeatureSetModalProps> = (props) => {
  const [currentTeam] = useCurrentTeam();
  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent p={6} maxW={600}>
        <ModalHeader p={0}>
          <HStack>
            <Heading fontSize={20} fontWeight={700} color="gray.900">
              Feature set for {currentTeam?.name}
            </Heading>
          </HStack>
          <Text mt={1} fontSize={14} fontWeight={400} color="gray.600">
            Choose which features are visible for this team
          </Text>
        </ModalHeader>
        <ModalCloseButton mt={2} />
        <ModalBody p={0} mt={3}>
          <TeamFeatureSetModalBody />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
