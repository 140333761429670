import { IconProps, Icon } from '../Icon';

export const AtlassianIcon = (props: IconProps) => (
  <Icon viewBox="0 -0.03 145.1 145.03" {...props}>
    <linearGradient
      id="a"
      gradientTransform="matrix(1 0 0 -1 0 228)"
      gradientUnits="userSpaceOnUse"
      x1={62.57}
      x2={25.03}
      y1={150.13}
      y2={85.11}
    >
      <stop offset={0} stopColor="#0052cc" />
      <stop offset={0.92} stopColor="#2684ff" />
    </linearGradient>
    <path
      d="M43 67a4.14 4.14 0 0 0-5.79-.78A4.29 4.29 0 0 0 36 67.73L.45 138.85a4.25 4.25 0 0 0 1.9 5.7 4.18 4.18 0 0 0 1.9.45h49.53a4.08 4.08 0 0 0 3.8-2.35C68.27 120.57 61.79 87 43 67z"
      fill="url(#a)"
    />
    <path
      d="M69.13 2.28a93.82 93.82 0 0 0-5.48 92.61l23.88 47.76a4.25 4.25 0 0 0 3.8 2.35h49.52a4.24 4.24 0 0 0 4.25-4.25 4.31 4.31 0 0 0-.44-1.9L76.36 2.26a4 4 0 0 0-7.23 0z"
      fill="#2684ff"
    />
  </Icon>
);
