import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import Script from 'next/script';
import { isServer } from '../../util';

// Fix for 'hj is not defined'
if (!isServer()) (window as any).hj = () => {};

const googleTagManagerId = process.env
  .NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID as string;

export const GoogleTagManager = () => {
  useEffect(() => {
    if (!isServer()) {
      TagManager.initialize({ gtmId: googleTagManagerId });
    }
  }, []);

  return (
    <Script id="google-tag-manager" strategy="afterInteractive">
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${googleTagManagerId}');
      `}
    </Script>
  );
};
