import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log, isNullish } from '@spoke/common';
import {
  useSetGoalRemindersMutation,
  ImprovementGoalUserReminderFrequency,
} from '@spoke/graphql';

export const useSetGoalRemindersHandler = () => {
  const [setGoalReminders] = useSetGoalRemindersMutation();
  const [toast] = useToast();

  const handleAddGoalData = useCallback(
    async (data: {
      improvementGoalId: string;
      userIds: string[];
      notificationTimeMs: number;
      frequency: ImprovementGoalUserReminderFrequency;
    }): Promise<boolean> => {
      const { frequency, improvementGoalId, notificationTimeMs, userIds } =
        data;

      if (
        !improvementGoalId ||
        !userIds ||
        isNullish(notificationTimeMs) ||
        !frequency
      ) {
        log.error('Missing critical data to set goal reminders', {
          frequency,
          improvementGoalId,
          notificationTimeMs,
          userIds,
        });
        return false;
      }

      log.info('Setting improvement goal reminders', { data });

      const { errors } = await setGoalReminders({
        variables: {
          frequency,
          improvementGoalId,
          notificationTime: notificationTimeMs,
          userIds,
        },
      });

      if (errors) {
        log.error('setGoalReminders call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [setGoalReminders, toast]
  );

  return [handleAddGoalData];
};
