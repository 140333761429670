import { PopoverTrigger } from '@chakra-ui/react';
import { FC } from 'react';
import { useDisclosure } from '../hooks';
import { Button } from './Button';
import { Flex } from './Flex';
import { Heading } from './Heading';
import { Popover } from './Popover/Popover';
import { PopoverArrow } from './Popover/PopoverArrow';
import { PopoverContent } from './Popover/PopoverContent';
import { Text } from './Text';
import { Tooltip } from './Tooltip';

type PopoverConfirmationProps = {
  title: string;
  text: string;
  buttonText: string;
  tooltip?: string;
  onConfirm: () => void;
};
export const PopoverConfirmation: FC<PopoverConfirmationProps> = ({
  title,
  text,
  buttonText,
  tooltip,
  onConfirm,
  children,
}) => {
  const { open, isOpen, close } = useDisclosure();

  const handleConfirm = () => {
    close();
    onConfirm();
  };

  return (
    <Popover onClose={close} isOpen={isOpen} onOpen={open} offset={[0, 20]}>
      <Tooltip
        variant="white"
        isDisabled={!tooltip || isOpen}
        label={tooltip}
        shouldWrapChildren
      >
        <PopoverTrigger>
          <Flex>{children}</Flex>
        </PopoverTrigger>
      </Tooltip>
      <PopoverContent flexDir="column" p={4} alignItems="stretch" maxW={270}>
        <PopoverArrow />
        <Heading fontSize={16} fontWeight={500} textAlign="center" mb={1}>
          {title}
        </Heading>
        <Text fontSize={14} color="gray.600" textAlign="center" mb={4}>
          {text}
        </Text>
        <Button onClick={handleConfirm}>{buttonText}</Button>
      </PopoverContent>
    </Popover>
  );
};
