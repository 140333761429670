import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentBoardId,
  getBoardFromCache,
  optimisticUpdateBoard,
  TOAST_ERROR_GENERIC,
  log,
  DeepPartial,
} from '@spoke/common';
import { useUpdateBoardMutation, Board } from '@spoke/graphql';

export const useToggleActionPlanHandler = () => {
  const [toast] = useToast();
  const [boardId] = useCurrentBoardId();
  const [updateBoard] = useUpdateBoardMutation();
  const { cache } = useApolloClient();

  const toggleActionPlan = useCallback(async () => {
    if (!boardId) {
      log.error('Missing critical data to toggle board action plan', {
        boardId,
      });
      return;
    }

    const boardFromCache = getBoardFromCache({ cache, boardId });

    const isActionPlanOpen = Boolean(boardFromCache?.state?.showActionPlan);

    const newShowActionPlan = !isActionPlanOpen;
    const updateBoardFields: DeepPartial<Board> = {
      state: { showActionPlan: newShowActionPlan },
    };

    const expected = optimisticUpdateBoard({
      cache,
      boardId,
      fields: updateBoardFields,
    });

    const updateBoardResult = await updateBoard({
      variables: { boardId, fields: updateBoardFields },
      optimisticResponse: { __typename: 'Mutation', updateBoard: expected },
    });

    if (updateBoardResult.errors) {
      log.error('Toggle board action plan responded with error', {
        boardId,
        updateBoardFields,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }
  }, [boardId, cache, toast, updateBoard]);

  return [toggleActionPlan];
};
