import { FC, memo } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import {
  Menu,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
  callIfExists,
} from '@spoke/common';

type ParticipantMenuProps = {
  onGrantFacilitator?: (participantId: string) => void;
  onRevokeFacilitator?: (participantId: string) => void;
  isFacilitator?: boolean;
  participantId: string;
};
export const ParticipantMenu: FC<ParticipantMenuProps> = ({
  isFacilitator,
  onGrantFacilitator,
  onRevokeFacilitator,
  participantId,
}) => (
  <Menu>
    <MenuButton>
      <Icon as={BsThreeDots} />
    </MenuButton>
    <MenuList>
      {!isFacilitator && (
        <MenuItem
          onClick={() => callIfExists(onGrantFacilitator, participantId)}
        >
          Grant facilitator
        </MenuItem>
      )}
      {isFacilitator && (
        <MenuItem
          onClick={() => callIfExists(onRevokeFacilitator, participantId)}
        >
          Remove facilitator
        </MenuItem>
      )}
    </MenuList>
  </Menu>
);

export const MemoizedParticipantMenu = memo(ParticipantMenu);
