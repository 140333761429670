import { useImprovementGoal } from '../queries';
import { ImprovementGoalTypeTargetType } from '@spoke/graphql';

type GoalInput = NonNullable<ReturnType<typeof useImprovementGoal>[0]>;
type GoalTypeInput = NonNullable<
  ReturnType<typeof useImprovementGoal>[0]
>['type'];

export const getArrowByGoal = (
  input: GoalInput | GoalTypeInput | ImprovementGoalTypeTargetType
): '<' | '>' => {
  if (input === ImprovementGoalTypeTargetType.Above) return '>';
  if (input === ImprovementGoalTypeTargetType.Below) return '<';

  if ('type' in input) return getArrowByGoal(input.type);
  return getArrowByGoal(input.targetType);
};
