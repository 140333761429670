import { FC } from 'react';
import { BiUserCircle } from 'react-icons/bi';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { FiLogOut } from 'react-icons/fi';
import {
  Menu,
  MenuButton,
  Avatar,
  MenuList,
  Flex,
  Divider,
  MenuItem,
  Icon,
  Text,
  useRouter,
  useRoutes,
} from '@spoke/common';

type UserAvatarMenuProps = {
  name: string;
  email: string;
};
export const UserAvatarMenu: FC<UserAvatarMenuProps> = ({ email, name }) => {
  const router = useRouter();
  const routes = useRoutes();

  return (
    <Menu>
      <MenuButton as="div" style={{ cursor: 'pointer' }}>
        <Avatar name={name || ''} />
      </MenuButton>
      <MenuList minW="180px" p={3} pl={4} pr={4}>
        <Flex mt={1} alignItems="center">
          <Avatar mr={3} name={name || ''} />
          <Flex flexDir="column">
            <Text fontWeight={500} fontSize={13} color="gray.700">
              {name}
            </Text>
            <Text fontWeight={500} fontSize={13} color="gray.500">
              {email}
            </Text>
          </Flex>
        </Flex>
        <Divider my={2} />
        <MenuItem onClick={() => router.push(routes.UserSettings)}>
          <Icon as={BiUserCircle} mr={2} w={4} h={4} />
          <Text fontWeight={500}>User Settings</Text>
        </MenuItem>
        <MenuItem
          onClick={() =>
            (window.location.href = 'mailto:support@scatterspoke.com')
          }
        >
          <Icon as={MdOutlineSupportAgent} mr={2} w={4} h={4} />
          <Text fontWeight={500}>Support</Text>
        </MenuItem>
        <MenuItem onClick={() => router.push(routes.Logout)}>
          <Icon as={FiLogOut} mr={2} w={4} h={4} />
          <Text fontWeight={500}>Logout</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
