import { useEffect } from 'react';
import { useCurrentTeamBitbucketRepositories } from './useCurrentTeamBitbucketRepositories';
import { useCurrentTeamGithubRepositories } from './useCurrentTeamGithubRepositories';
import { useInstalledOrgIntegrations } from './useInstalledOrgIntegrations';
import { useCurrentTeam } from '@spoke/user';
import { useSourceControlMetricsQuery } from '@spoke/graphql';
import {
  QueryConfig,
  DeepPartial,
  log,
  useSourceControlRateLimitLock,
  useNow,
  SpkTime,
  ONE_MONTH_MS,
  deepMerge,
  isExternalRateLimitError,
} from '@spoke/common';

type Config = QueryConfig<typeof useSourceControlMetricsQuery>;
type QueryRef = ReturnType<typeof useSourceControlMetricsQuery>;
export type SourceControlMetricsQueryData = NonNullable<
  NonNullable<QueryRef>['data']
>['sourceControlMetrics'];

export const useSourceControlMetrics = (
  config?: DeepPartial<Config>
): [SourceControlMetricsQueryData | null, QueryRef] => {
  const [_, fireSourceControlRateLimitLock] = useSourceControlRateLimitLock();
  const { github: orgHasGithub, bitbucket: orgHasBitbucket } =
    useInstalledOrgIntegrations();
  const [linkedGithubRepos] = useCurrentTeamGithubRepositories();
  const [linkedBitbucketRepos] = useCurrentTeamBitbucketRepositories();

  const now = useNow({ roundTo: 'ten-minutes' });

  const installedOnOrg = Boolean(orgHasGithub || orgHasBitbucket);
  const installedOnTeam = Boolean(
    linkedGithubRepos?.length || linkedBitbucketRepos?.length
  );

  const [currentTeam] = useCurrentTeam();

  const baseConfig: DeepPartial<Config> = {
    variables: {
      teamId: currentTeam?.id ?? '',
      utcOffsetMs: SpkTime.getUtcOffsetMs(),
      startDate: now.getTime() - ONE_MONTH_MS * 3,
      endDate: now.getTime(),
    },
    skip: !currentTeam?.id || !installedOnOrg || !installedOnTeam,
  };

  const finalConfig = (
    config ? deepMerge(baseConfig, config) : baseConfig
  ) as Config;

  const query = useSourceControlMetricsQuery(finalConfig);

  const data =
    query.data?.sourceControlMetrics ??
    query.previousData?.sourceControlMetrics ??
    null;

  useEffect(() => {
    if (!query.error) return;
    const hasRateLimitError = query.error?.graphQLErrors.some(
      isExternalRateLimitError
    );
    if (hasRateLimitError) {
      log.error('SourceControlMetrics responded with rate limited', {
        error: query.error,
      });
      fireSourceControlRateLimitLock();
    }
  }, [fireSourceControlRateLimitLock, query.error]);

  return [data, query];
};
