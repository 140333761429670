import { useCallback } from 'react';
import { TOAST_ERROR_GENERIC, log, useToast } from '@spoke/common';
import { usePromoteCardToProgramsMutation } from '@spoke/graphql';

export const usePromoteCardToProgramHandler = () => {
  const [promoteCard] = usePromoteCardToProgramsMutation();
  const [toast] = useToast();

  const handlePromoteCardToProgram = useCallback(
    async (cardId: string, programIds: string[]): Promise<boolean> => {
      if (!cardId || !programIds.length) {
        log.error('Missing critical data to promote card to program', {
          cardId,
          programIds,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      const { errors } = await promoteCard({
        variables: {
          cardId,
          programIds,
        },
      });

      if (errors) {
        log.error('PromoteCardToProgram call responded with errors', {
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [promoteCard, toast]
  );

  return [handlePromoteCardToProgram];
};
