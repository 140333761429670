import { useCallback, useMemo, useState } from 'react';
import { uniqueBy } from '../../util';

type UseMultiSelectResult<T> = [
  T[],
  (item: T) => void,
  (item: T) => boolean,
  () => void
];

/**
 * Utils for managing multi-select states
 * @param idKeyOrFn Either a key to use as the unique id or a function that returns it
 * @returns [selectedItems, selectItem, isSelected, clearSelection]
 */
export const useMultiSelect = <T,>(
  idKeyOrFn: ((entry: T) => string | number) | keyof T
): UseMultiSelectResult<T> => {
  const keyFn = useMemo(
    () =>
      typeof idKeyOrFn === 'function' ? idKeyOrFn : (e: T) => e[idKeyOrFn],
    [idKeyOrFn]
  );

  const [selected, setSelected] = useState<T[]>([]);

  const clear = useCallback(() => setSelected([]), []);

  const add = useCallback(
    (entry: T) => setSelected((prev) => uniqueBy([...prev, entry], idKeyOrFn)),
    [idKeyOrFn]
  );
  const remove = useCallback(
    (entry: T) =>
      setSelected((prev) => prev.filter((e) => keyFn(e) !== keyFn(entry))),
    [keyFn]
  );

  const toggle = useCallback(
    (entry: T) => {
      const isSelected = selected.some((e) => keyFn(e) === keyFn(entry));
      if (isSelected) remove(entry);
      else add(entry);
    },
    [add, keyFn, remove, selected]
  );

  const isSelected = useCallback(
    (entry: T) => selected.some((e) => keyFn(e) === keyFn(entry)),
    [keyFn, selected]
  );

  return [selected, toggle, isSelected, clear];
};
