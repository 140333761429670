import { FC, useState } from 'react';
import { useCurrentBoardRules } from '../../queries/useCurrentBoardRules';
import {
  useCurrentBoard,
  useDisclosure,
  isLivePollRunning,
  Flex,
  Skeleton,
  GridItem,
  HStack,
  Divider,
  Spacer,
  Button,
  Text,
} from '@spoke/common';
import {
  CreateGoalWizardModal,
  ImportDataWizardModal,
  ImprovementGoalCard,
  ReportGoalDataModal,
  TeamGoalsEmptyState,
  useImprovementGoals,
} from '@spoke/improvement-goals';
import {
  LivePollGoalCards,
  LivePollGoalSelectorModal,
  useLivePolls,
} from '@spoke/live-polls';
import { useCurrentTeam } from '@spoke/user';

export const FactsStatsGoals: FC = () => {
  const [{ activeGoals: goals }, { loading }] = useImprovementGoals(
    { variables: { takeLastNPeriodsWithData: 5 } },
    { subscribeToChanges: true }
  );

  const [polls] = useLivePolls({}, { pollIfNeeded: true });
  const [team] = useCurrentTeam();
  const [board] = useCurrentBoard();
  const { isFacilitator } = useCurrentBoardRules();

  const [reportingGoalId, setReportingGoalId] = useState<string | null>(null);
  const reportDisclosure = useDisclosure();
  const importDisclosure = useDisclosure();

  const pollableGoals = goals?.filter((goal) => goal.type.isPollable) ?? [];
  const nonPollableGoals = goals?.filter((goal) => !goal.type.isPollable) ?? [];

  const hasPollableGoals = pollableGoals?.length > 0;
  const hasNonPollableGoals = nonPollableGoals?.length > 0;

  const pollModalDisclosure = useDisclosure();
  const createGoalModal = useDisclosure();

  const hasRunningPolls = polls.filter(isLivePollRunning).length > 0;

  const activeGoalLimitReached =
    goals?.length === team?.maxActiveImprovementGoals;

  if (loading) {
    return (
      <Flex flexDir="column" gap={4}>
        {Array.from({ length: 3 }).map((_, id) => (
          <Skeleton key={`loading-improvement-goal-${id}`}>
            <GridItem
              h={284}
              borderStyle="solid"
              borderWidth={1}
              borderColor="gray.200"
              borderRadius={8}
              padding={4}
            />
          </Skeleton>
        ))}
      </Flex>
    );
  }

  if (!goals?.length) return <TeamGoalsEmptyState withTutorial />;

  return (
    <Flex flexDir="column" gap={4}>
      <LivePollGoalCards
        isFacilitator={isFacilitator}
        teamMemberCount={team?.members?.length ?? 0}
        boardId={board?.id ?? ''}
        polls={polls}
      />
      <Flex
        flexDir="column"
        gap={4}
        opacity={hasRunningPolls ? 0.3 : 1}
        pointerEvents={hasRunningPolls ? 'none' : 'all'}
      >
        {hasNonPollableGoals && (
          <>
            <HStack>
              <Flex flexDir="column">
                <Text color="gray.700" fontWeight={500}>
                  Report based goals
                </Text>
                <Text color="gray.600" fontSize={15} fontWeight={400}>
                  Manually report data to update your goals
                </Text>
              </Flex>
            </HStack>
            {nonPollableGoals.map((goal) => (
              <ImprovementGoalCard
                key={goal.id}
                goal={goal}
                reportOnly
                onReportClick={() => {
                  setReportingGoalId(goal.id);
                  reportDisclosure.open();
                }}
              />
            ))}
          </>
        )}
        {hasPollableGoals && hasNonPollableGoals && <Divider my={2} />}
        {hasPollableGoals && (
          <>
            <HStack>
              <Flex flexDir="column">
                <Text color="gray.700" fontWeight={500}>
                  Poll based goals
                </Text>
                <Text color="gray.600" fontSize={15} fontWeight={400}>
                  Run a poll to collect data on your goals
                </Text>
              </Flex>
              <Spacer />
              {isFacilitator && (
                <Button onClick={pollModalDisclosure.open}>Run poll</Button>
              )}
            </HStack>
            {pollableGoals.map((goal) => (
              <ImprovementGoalCard
                key={goal.id}
                goal={goal}
                reportOnly
                onReportClick={() => {
                  setReportingGoalId(goal.id);
                  reportDisclosure.open();
                }}
              />
            ))}
          </>
        )}
        {Boolean(!activeGoalLimitReached && isFacilitator && goals?.length) && (
          <Button
            size="sm"
            color="primary.400"
            my={5}
            onClick={createGoalModal.open}
            variant="link"
          >
            + Add Team Goal
          </Button>
        )}
        <ImportDataWizardModal
          isOpen={importDisclosure.isOpen}
          onClose={importDisclosure.close}
          improvementGoalId={reportingGoalId ?? ''}
        />
        <CreateGoalWizardModal
          isOpen={createGoalModal.isOpen}
          onClose={createGoalModal.close}
        />
        <ReportGoalDataModal
          isOpen={reportDisclosure.isOpen}
          onClose={() => {
            setReportingGoalId(null);
            reportDisclosure.close();
          }}
          improvementGoalId={reportingGoalId as string}
          onImportCSVClicked={() => {
            importDisclosure.open();
            reportDisclosure.close();
          }}
        />
        <LivePollGoalSelectorModal
          isOpen={pollModalDisclosure.isOpen}
          onClose={pollModalDisclosure.close}
        />
      </Flex>
    </Flex>
  );
};
