import { KeyboardEventHandler, forwardRef, useState, memo } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import {
  SpkTime,
  SpkFormat,
  Portal,
  PopoverContent,
  Flex,
  Button,
  Icon,
  NumberInput,
  SpkMath,
  SpkStr,
  NumberInputField,
  Text,
} from '@spoke/common';

type BoardTimerPopoverProps = {
  isActive: boolean;
  timeIsUp: boolean;
  minutes: number;
  seconds: number;
  handleInputKeydown: KeyboardEventHandler<HTMLDivElement>;
  handleAddOneMin: () => void;
  handleStart: () => void;
  handleReset: () => void;
  handleInputChanged: (timeInSeconds: number) => void;
  setSeconds: (newSeconds: number) => void;
  setMinutes: (newMinutes: number) => void;
};

export const BoardTimerPopover = forwardRef<
  HTMLDivElement,
  BoardTimerPopoverProps
>(
  (
    {
      handleAddOneMin,
      handleInputKeydown,
      handleReset,
      handleStart,
      handleInputChanged,
      isActive,
      minutes,
      seconds,
      setMinutes,
      setSeconds,
      timeIsUp,
    },
    ref
  ) => {
    const [editingSeconds, setEditingSeconds] = useState(false);
    const [editingMinutes, setEditingMinutes] = useState(false);

    const onIncrement = (incrementInSeconds: number) => {
      const timeSeconds =
        SpkTime.summarizedTimeToSecondsValue({
          minutes,
          seconds,
        }) + incrementInSeconds;
      if (timeSeconds < 0) return;

      const newTime = SpkTime.summarizeTimeInSeconds(timeSeconds);
      handleInputChanged(timeSeconds);
      setMinutes(newTime.minutes);
      setSeconds(newTime.seconds);
    };

    const onInputBlur = () => {
      const timeSeconds = SpkTime.summarizedTimeToSecondsValue({
        minutes,
        seconds,
      });
      handleInputChanged(timeSeconds);
    };

    const formatValue = (value: string | number, isEditing: boolean) => {
      if (isEditing) return value;
      return typeof value === 'number'
        ? SpkFormat.padZeroes(value, 2)
        : SpkFormat.padZeroes(parseInt(value), 2);
    };

    return (
      <Portal>
        <PopoverContent maxW="250px">
          <Flex p={4} alignItems="stretch" flexDir="column" ref={ref}>
            <Text fontSize={16} fontWeight={500} textAlign="center" mb={0}>
              {isActive
                ? timeIsUp
                  ? 'Time is up.'
                  : 'Timer is running...'
                : 'Turn on timer'}
            </Text>
            <Text fontSize={14} color="gray.600" textAlign="center" mb={4}>
              {isActive
                ? 'Type and hit enter to set timer'
                : 'Set a duration to start the timer'}
            </Text>
            <Flex
              justifyContent="center"
              alignItems="center"
              w="full"
              mb={6}
              borderRadius="lg"
              borderColor="gray.200"
              borderWidth={1}
              py={2}
              px={6}
            >
              <Button
                variant="outlineRoundedIcon"
                onClick={() => onIncrement(-60)}
                transition="width 0.1s ease-out"
                overflow="hidden"
                alignItems="center"
                h="20px"
                px="6px"
                size="xsm"
              >
                <Icon as={BiChevronDown} w={4} h={4} />
              </Button>

              <NumberInput
                onChange={(newValue) =>
                  setMinutes(
                    SpkMath.coerce(
                      parseInt(SpkStr.takeLast(newValue, 2)) || 0,
                      0,
                      99
                    )
                  )
                }
                format={(value) => formatValue(value, editingMinutes)}
                onKeyDown={handleInputKeydown}
                onClick={() => setEditingMinutes(true)}
                onBlur={() => {
                  onInputBlur();
                  setEditingMinutes(false);
                }}
                min={0}
                max={99}
                value={minutes}
                variant="unstyled"
                flexGrow={0}
                overflow="hidden"
                bg={editingMinutes ? 'gray.100' : 'transparent'}
                _hover={{ bg: 'gray.100' }}
                borderRadius="md"
                mr={1}
                ml={5}
                pr={2}
              >
                <Flex alignItems="center">
                  <NumberInputField
                    textAlign={editingMinutes ? 'left' : 'right'}
                    paddingEnd="unset"
                    w="unset"
                    fontSize="20px"
                    flexGrow={1}
                    pl={2}
                  />
                </Flex>
              </NumberInput>
              <Text fontSize="20px" color="gray.600">
                :
              </Text>
              <NumberInput
                onChange={(newValue) =>
                  setSeconds(
                    SpkMath.coerce(
                      parseInt(SpkStr.takeLast(newValue, 2)) || 0,
                      0,
                      59
                    )
                  )
                }
                format={(value) => formatValue(value, editingSeconds)}
                onKeyDown={handleInputKeydown}
                onClick={() => setEditingSeconds(true)}
                onBlur={() => {
                  onInputBlur();
                  setEditingSeconds(false);
                }}
                min={0}
                max={59}
                value={seconds}
                variant="unstyled"
                flexGrow={0}
                overflow="hidden"
                bg={editingSeconds ? 'gray.100' : 'transparent'}
                _hover={{ bg: 'gray.100' }}
                borderRadius="md"
                ml={1}
                mr={5}
                pl={2}
              >
                <Flex alignItems="center">
                  <NumberInputField
                    textAlign="left"
                    paddingEnd="unset"
                    w="unset"
                    fontSize="20px"
                    flexGrow={1}
                  />
                </Flex>
              </NumberInput>
              <Button
                variant="outlineRoundedIcon"
                onClick={() => onIncrement(60)}
                transition="width 0.1s ease-out"
                overflow="hidden"
                alignItems="center"
                h="20px"
                px="6px"
                size="xsm"
              >
                <Icon as={BiChevronUp} w={4} h={4} />
              </Button>
            </Flex>
            {!isActive && (
              <Button
                disabled={minutes === 0 && seconds === 0}
                onClick={handleStart}
              >
                Start Timer
              </Button>
            )}
            {isActive && (
              <Flex gap={2}>
                <Button
                  flex={1}
                  variant="outlineGray"
                  onClick={handleAddOneMin}
                >
                  +1 min
                </Button>
                <Button flex={1} onClick={handleReset}>
                  Reset
                </Button>
              </Flex>
            )}
          </Flex>
        </PopoverContent>
      </Portal>
    );
  }
);

export const MemoizedBoardTimerPopover = memo(BoardTimerPopover);
