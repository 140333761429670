import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useCurrentBoardTeam } from '../../queries';
import {
  useCurrentBoardId,
  useToast,
  TOAST_ERROR_GENERIC,
  getBoardFromCache,
  generateBoardCsv,
  downloadBlob,
  log,
  MimeType,
} from '@spoke/common';
import { Team } from '@spoke/graphql';

export const useExportBoardCsvHandler = () => {
  const [boardId] = useCurrentBoardId();
  const [boardTeam] = useCurrentBoardTeam();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  const handleExportBoardCsv = useCallback(async () => {
    if (!boardId || !boardTeam) {
      log.error('Missing critical data to export board to CSV', {
        boardId,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const board = getBoardFromCache({ boardId, cache });

    if (!board) {
      log.error('Cannot export board to CSV: cached board not found', {
        boardId,
        board,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const csv = await generateBoardCsv(boardTeam as Team, board);

    downloadBlob(csv, MimeType.Csv, `${board.name}.csv`);
  }, [boardId, boardTeam, cache, toast]);

  return [handleExportBoardCsv];
};
