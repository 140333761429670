import { useCallback } from 'react';
import { useToast, SpkHttp, log } from '@spoke/common';

export enum ConfluenceError {
  DuplicatedName = 'CONFLUENCE_DUPLICATED_NAME',
  Unknown = 'CONFLUENCE_UNKNOWN',
}

const CONFLUENCE_ERROR_MESSAGES: Record<ConfluenceError, string> = {
  [ConfluenceError.DuplicatedName]:
    'Could not export your board. A page with the same name already exists in this space.',
  [ConfluenceError.Unknown]: 'Something went wrong. Your page was not created.',
};

export const useExportBoardToConfluenceHandler = () => {
  const [toast] = useToast();

  const handleExportBoardToConfluence = useCallback(
    async ({
      boardId,
      confluenceSpaceKey,
    }: {
      confluenceSpaceKey: string;
      boardId: string;
    }): Promise<boolean> => {
      if (!boardId || !confluenceSpaceKey) {
        log.warn('Missing critical data to export board to Confluence', {
          confluenceSpaceKey,
          boardId,
        });
        return false;
      }

      log.info(
        `Export board ${boardId} to Confluence space ${confluenceSpaceKey}`,
        { confluenceSpaceKey, boardId }
      );

      const error = await SpkHttp.exportBoardConfluence(
        boardId,
        confluenceSpaceKey
      );

      if (error) {
        log.error('Export board to confluence call responded with errors', {
          boardId,
          confluenceSpaceKey,
          error,
        });
        const message =
          CONFLUENCE_ERROR_MESSAGES[error] ||
          CONFLUENCE_ERROR_MESSAGES[ConfluenceError.Unknown];
        toast({
          status: 'error',
          title: 'Error',
          description: message,
        });
        return false;
      }

      toast({
        status: 'success',
        title: 'Success',
        description: 'Board successfully exported to Confluence.',
      });

      return true;
    },
    [toast]
  );

  return [handleExportBoardToConfluence];
};
