import { FC, memo } from 'react';
import { BiTrashAlt } from 'react-icons/bi';
import { BsThreeDots } from 'react-icons/bs';
import { MdOutlineBookmarkAdd, MdOutlineContentCopy } from 'react-icons/md';
import { FiInbox } from 'react-icons/fi';
import { BoardCardMenuPromoteButton } from './BoardCardMenuPromoteButton';
import {
  NO_OP,
  Menu,
  MenuButton,
  Icon,
  Portal,
  MenuList,
  MenuItem,
} from '@spoke/common';

type BoardCardMenuProps = {
  onSaveAsActionItem?: () => void;
  onSaveAsParkingLot?: () => void;
  onDuplicate?: () => void;
  onDeleteCard?: () => void;
  onPromote?: () => void;
  promotedToPrograms?: Array<{ id: string; name: string }>;
  canPromote: boolean;
  canDelete: boolean;
};
export const BoardCardMenu: FC<BoardCardMenuProps> = ({
  onDeleteCard,
  onDuplicate,
  onSaveAsActionItem,
  onSaveAsParkingLot,
  onPromote,
  promotedToPrograms,
  canPromote,
  canDelete,
}) => (
  <Menu>
    <MenuButton
      className="card-options"
      opacity={0}
      transition="opacity 0.2s ease-out"
      top={2}
      right={3}
      cursor="pointer"
      position="absolute"
      tabIndex={-1}
      _hover={{
        '& .dots-icon': {
          color: 'gray.900',
        },
      }}
    >
      <Icon
        w={4}
        h={4}
        as={BsThreeDots}
        transition="color 0.1s ease-out"
        color="gray.400"
        className="dots-icon"
      />
    </MenuButton>
    <Portal>
      <MenuList>
        <MenuItem onClick={onSaveAsActionItem ? onSaveAsActionItem : NO_OP}>
          <Icon mr={1} mb="2px" w={4} h={4} as={MdOutlineBookmarkAdd} />
          Save card as action item
        </MenuItem>
        <MenuItem onClick={onSaveAsParkingLot ? onSaveAsParkingLot : NO_OP}>
          <Icon mr={1} mb="2px" w={4} h={4} as={FiInbox} />
          Save card as parking lot
        </MenuItem>
        {canPromote && (
          <BoardCardMenuPromoteButton
            onClick={onPromote}
            promotedToPrograms={promotedToPrograms}
          />
        )}
        <MenuItem onClick={onDuplicate ? onDuplicate : NO_OP}>
          <Icon mr={1} mb="2px" w={4} h={4} as={MdOutlineContentCopy} />
          Duplicate card
        </MenuItem>
        {canDelete && (
          <MenuItem onClick={onDeleteCard ? onDeleteCard : NO_OP}>
            <Icon mr={1} mb="2px" w={4} h={4} as={BiTrashAlt} />
            Delete card
          </MenuItem>
        )}
      </MenuList>
    </Portal>
  </Menu>
);

export const MemoizedBoardCardMenu = memo(BoardCardMenu);
