import { ReactElement } from 'react';
import {
  uniqueBy,
  TeamIcon,
  ProgramIcon,
  OrganizationIcon,
} from '@spoke/common';
import { TeamType, MinimalTeamFragmentFragment } from '@spoke/graphql';

const programsOverTeams = (
  a: TeamSwitcherTreeNodeType,
  b: TeamSwitcherTreeNodeType
): 0 | 1 | -1 => {
  const { Program } = TeamType;
  const aType = a.team.type;
  const bType = b.team.type;
  if (aType === Program && bType !== Program) return -1;
  if (aType !== Program && bType === Program) return 1;
  return 0;
};

/**
 * Returns an array of teams representing team hierarchy
 * all the way down to searched teamId
 */
export const findTeamPath = (
  nodes: TeamSwitcherTreeNodeType[],
  teamId: string
): MinimalTeamFragmentFragment[] => {
  /**
   * This is to work around the fact that now we return program children
   * duplicated in the "Your teams" section... and this confuses this function
   * (a program that should be included in the path is not, because team is in root level)
   * So we first check all programs before proceeding to root teams
   */
  const nodesProgramsFirst = nodes.sort(programsOverTeams);

  for (const node of nodesProgramsFirst) {
    if (node.team.id === teamId) return [node.team];
    const childContainingSearchedNode = !node.children
      ? null
      : [...node.children]
          .sort(programsOverTeams)
          .filter((c) => findAllNodeTeamIds(c).includes(teamId))[0];
    if (!childContainingSearchedNode) continue;
    return [node.team, ...findTeamPath([childContainingSearchedNode], teamId)];
  }

  return [];
};

export const flattenTeamTree = (
  nodes: TeamSwitcherTreeNodeType[]
): TeamSwitcherTreeNodeType[] => {
  const allNodes: TeamSwitcherTreeNodeType[] = [];

  for (const node of nodes) {
    allNodes.push(node);
    if (node.children) {
      allNodes.push(...flattenTeamTree(node.children));
    }
  }

  const uniqueNodes = uniqueBy(allNodes, (n) => n.team.id || '');

  return uniqueNodes;
};

export const findAllNodeTeamIds = (
  node: TeamSwitcherTreeNodeType
): string[] => {
  if (!node.children) return [];
  const childrenIds = [node.team.id].concat(
    node.children.map((c) => c.team.id)
  ) as string[];
  return node.children.reduce(
    (acc, child) => [...acc, ...findAllNodeTeamIds(child)],
    childrenIds
  );
};

export type TeamSwitcherTreeNodeType = {
  team: MinimalTeamFragmentFragment;
  children?: TeamSwitcherTreeNodeType[];
  isWatcher?: boolean | null;
};

export const getIconDataURLByTeamType = (teamType: TeamType): string => {
  const iconByTeamType: Record<TeamType, string> = {
    [TeamType.Team]:
      'https://scatterspoke-static-assets.s3.amazonaws.com/team-icon.png',
    [TeamType.Program]:
      'https://scatterspoke-static-assets.s3.amazonaws.com/program-icon.png',
    [TeamType.Organization]:
      'https://scatterspoke-static-assets.s3.amazonaws.com/org-icon.png',
    [TeamType.Portfolio]: `not support yet`,
  };

  return iconByTeamType[teamType];
};

/**
 * This returns JSX elements instead of just the components because
 * each icon has a different scale in SVG.. so we need to apply different
 * sizings to each
 * This couldf improve by fixing the SVGs themselves
 */
export const getIconByTeamType = (
  teamType: TeamType,
  scale: number = 1
): ReactElement => {
  const iconByTeamType: Record<TeamType, ReactElement> = {
    [TeamType.Team]: (
      <TeamIcon
        w={`${24 * scale}px`}
        h={`${24 * scale}px`}
        cursor="pointer"
        pointerEvents="none"
      />
    ),
    [TeamType.Program]: (
      <ProgramIcon
        w={`${22 * scale}px`}
        h={`${22 * scale}px`}
        cursor="pointer"
        pointerEvents="none"
      />
    ),
    [TeamType.Organization]: (
      <OrganizationIcon
        w={`${22 * scale}px`}
        h={`${22 * scale}px`}
        pointerEvents="none"
      />
    ),
    [TeamType.Portfolio]: (
      <ProgramIcon
        w={`${22 * scale}px`}
        h={`${22 * scale}px`}
        cursor="pointer"
        pointerEvents="none"
      />
    ),
  };

  return iconByTeamType[teamType];
};
