import { FC } from 'react';
import { BsCheck } from 'react-icons/bs';
import {
  Box,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  truncateText,
  VStack,
} from '@spoke/common';
import { RetrospectiveFormat } from '@spoke/graphql';

type RetroFormatCardProps = {
  format: RetrospectiveFormat;
  isSelected: boolean;
  onReadMore: () => unknown;
  onSelect: () => unknown;
};
export const RetroFormatCard: FC<RetroFormatCardProps> = ({
  format,
  isSelected,
  onReadMore,
  onSelect,
}) => {
  const hasReadMore = Boolean(format.detailsMarkup);

  return (
    <Flex
      onClick={() => onSelect()}
      position="relative"
      boxShadow="light"
      alignItems="flex-start"
      borderRadius="lg"
      w="234px"
      h="124px"
      p={4}
      borderColor={isSelected ? 'primary.500' : 'gray.100'}
      borderWidth={isSelected ? '2px' : '1px'}
      cursor="pointer"
      as="button"
    >
      {isSelected ? (
        <Box
          position="absolute"
          h={7}
          w={7}
          top="-1px"
          right="0"
          bg="primary.500"
          display="flex"
          borderBottomLeftRadius="md"
          justifyContent="center"
          borderTopRightRadius="md"
        >
          <Icon as={BsCheck} color="white" w={6} h={6} />
        </Box>
      ) : null}
      <Image
        src={format.iconImageUrl ?? ''}
        alt={format.displayName ?? ''}
        w={16}
        h={16}
        mr={4}
      />
      <VStack alignItems="flex-start" spacing="1px" textAlign="left">
        <Text fontSize={14} fontWeight={500}>
          {truncateText(format.displayName || '', 17)}
        </Text>
        <Text fontSize={12} color="gray.500">
          {truncateText(format.description || '', hasReadMore ? 55 : 64)}
        </Text>

        {hasReadMore && (
          <Link
            as="a"
            fontSize={12}
            fontWeight={500}
            color="gray.900"
            onClick={(e) => {
              e.stopPropagation();
              onReadMore();
            }}
          >
            Read more &gt;
          </Link>
        )}
      </VStack>
    </Flex>
  );
};
