import {
  CloseButton,
  ToastId,
  UseToastOptions,
  useToast as useChakraToast,
  UseToastOptions as ChakraUseToastOptions,
} from '@chakra-ui/react';
import { PropsWithChildren, ReactNode, useCallback, useRef } from 'react';
import { AiFillInfoCircle, AiFillWarning } from 'react-icons/ai';
import { BsCheckCircleFill } from 'react-icons/bs';
import { IoCloseCircleSharp } from 'react-icons/io5';
import { Flex, FlexProps } from './Flex';
import { Icon } from './Icon';
import { Spinner } from './Spinner';
import { Text } from './Text';

export type ToastStatus = 'info' | 'error' | 'warning' | 'success' | 'loading';

export const TOAST_ERROR_GENERIC: ToastOptions = {
  status: 'error',
  title: 'Something went wrong',
  description:
    'Something went wrong. Please contact support if this problem persists.',
};

const USE_TOAST_DEFAULT_OPTIONS: ToastOptions = {
  isClosable: true,
  position: 'bottom-left',
  containerStyle: {
    paddingBottom: '14px',
    paddingLeft: '10px',
    marginTop: '-12px',
  },
};
const USE_UNIQUE_TOAST_DEFAULT_OPTIONS: UseToastOptions = {
  position: 'bottom-left',
  duration: null,
};

type ToastProps = {
  onClose: () => void;
  title: string;
  description: string;
  status?: ToastStatus;
  isClosable?: boolean;
};

const ICON_BY_STATUS: Record<ToastStatus, ReactNode> = {
  error: <Icon as={IoCloseCircleSharp} w="24px" h="24px" color="red.500" />,
  success: <Icon as={BsCheckCircleFill} w="24px" h="24px" color="green.600" />,
  info: <Icon as={AiFillInfoCircle} w="24px" h="24px" color="primary.400" />,
  warning: <Icon as={AiFillWarning} w="24px" h="24px" color="orange.400" />,
  loading: <Spinner w={4} h={4} thickness="3px" />,
};

export const Toast = ({
  // This isn't being used anymore... but let's keep it in case we change minds.
  title: _title,
  onClose,
  description,
  isClosable,
  status = 'info',
  children,
  ...rest
}: PropsWithChildren<ToastProps & FlexProps>) => {
  const icon: ReactNode = ICON_BY_STATUS[status];
  return (
    <Flex
      position="relative"
      flexDir="column"
      bg="white"
      borderRadius="md"
      boxShadow="light"
      maxW={400}
      p={4}
      pt={3}
      pr={8}
      {...rest}
    >
      <Flex alignItems="center" gap={2}>
        {icon}
        <Text
          pl={1}
          mr={isClosable ? 2 : 0}
          mt="3px"
          fontSize={15}
          fontWeight={500}
          color="gray.700"
          flex="1"
        >
          {description}
          {children}
        </Text>
      </Flex>
      {isClosable && (
        <CloseButton
          position="absolute"
          top={2}
          right={2}
          onClick={onClose}
          color="gray.700"
          _hover={{ bg: 'transparent' }}
        />
      )}
    </Flex>
  );
};

export type ToastOptions = Omit<ChakraUseToastOptions, 'status'> & {
  status?: ToastStatus;
};

export const useToast = (
  baseOptions?: ToastOptions
): [(options?: ToastOptions) => () => void] => {
  const chakraToast = useChakraToast();

  const toast = useCallback(
    (options: ToastOptions = {}) => {
      const _options: ToastOptions = {
        ...USE_TOAST_DEFAULT_OPTIONS,
        ...(baseOptions || {}),
        ...options,
        render: ({ onClose }) => (
          <Toast
            description={_options.description as string}
            title={_options.title as string}
            isClosable={_options.isClosable}
            onClose={onClose}
            status={_options.status}
          />
        ),
      };

      const toastId = chakraToast(_options as ChakraUseToastOptions);

      return () => {
        if (toastId) chakraToast.close(toastId);
      };
    },
    [baseOptions, chakraToast]
  );

  return [toast];
};

/**
 * Guarantees only one toast instance is going to be open at a time.
 * Subsequent attempts to open a toast will update the existing one.
 */
export const useUniqueToast = (baseOptions: UseToastOptions = {}) => {
  // const chakraToast = useChakraToast();
  // const toastId = useRef<ToastId>();

  const uniqueToastImpl = (options: UseToastOptions = {}) => {
    const _options = {
      ...USE_UNIQUE_TOAST_DEFAULT_OPTIONS,
      ...baseOptions,
      ...options,
    };

    const render = ({ onClose }: { onClose: () => void }) => null;

    _options.render = render;

    // if (toastId.current && chakraToast.isActive(toastId.current)) {
    //   chakraToast.update(toastId.current, _options);
    // } else toastId.current = chakraToast(_options);
  };

  uniqueToastImpl.close = () => {
    // if (toastId.current && chakraToast.isActive(toastId.current)) {
    //   chakraToast.close(toastId.current);
    // }
  };

  return uniqueToastImpl;
};
