import {
  Tabs as ChakraTabs,
  TabsProps as ChakraTabsProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = ChakraTabsProps;

export const Tabs = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ChakraTabs {...props} ref={ref} />
));
