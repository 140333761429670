import { FC, useMemo, useState } from 'react';
import { useCreateJiraIssuesFromCardsHandler } from '../../handlers';
import { BoardCardSummary } from '../BoardCardReadOnly';
import { ExportBoardEmptyState } from './ExportBoardEmptyState';
import { CardFragmentFragment, List } from '@spoke/graphql';
import {
  ModalProps,
  JiraProject,
  useCurrentBoard,
  flattenListsCards,
  parseCardText,
  isImage,
  uniqueBy,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  JiraIcon,
  Heading,
  ModalBody,
  Divider,
  VStack,
  Flex,
  Button,
  plural,
  Modal,
  ModalOverlay,
  log,
  Text,
} from '@spoke/common';
import { JiraOAuthProjectSelector } from '@spoke/action-item';

type ExportBoardJiraModalProps = Omit<ModalProps, 'children'>;

export const ExportBoardJiraModalContent: FC<ExportBoardJiraModalProps> = (
  props
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [handleCreateJiraIssuesFromCards] =
    useCreateJiraIssuesFromCardsHandler();
  const [selectedCards, setSelectedCards] = useState<CardFragmentFragment[]>(
    []
  );
  const [selectedProject, setSelectedProject] = useState<JiraProject | null>(
    null
  );

  const [board] = useCurrentBoard();

  const allBoardCardsMinusImages = useMemo(
    () =>
      flattenListsCards((board?.lists?.filter(Boolean) as List[]) || [])
        .filter((card) => !parseCardText(card.text).find(isImage))
        .map((card) => ({
          ...card,
          children: card.children?.filter(
            (child) => !parseCardText(child?.text || '').find(isImage)
          ),
        })),
    [board?.lists]
  );

  const isCardSelected = (card: CardFragmentFragment) =>
    selectedCards.some((c) => c.id === card.id);

  const toggleCard = (card: CardFragmentFragment) => {
    if (isCardSelected(card)) {
      setSelectedCards((prev) => prev.filter((c) => c.id !== card.id));
    } else {
      setSelectedCards((prev) => uniqueBy([...prev, card], 'id'));
    }
  };

  const onSubmit = async () => {
    const cardIds = selectedCards.map((card) => card.id);
    const projectKey = selectedProject?.key;
    if (!projectKey) {
      log.error('No-op: cannot export to Jira without a selected project key', {
        projectKey,
        cardIds,
      });
      return;
    }

    setIsSubmitting(true);
    await handleCreateJiraIssuesFromCards({ cardIds, projectKey });
    setIsSubmitting(false);
    props.onClose();
  };

  return (
    <ModalContent p={8} maxW={825}>
      <ModalCloseButton />
      <ModalHeader p={0}>
        <HStack>
          <Icon as={JiraIcon} />
          <Heading mb={1} fontSize={24} color="gray.900">
            Export to Jira
          </Heading>
        </HStack>
        <Text mb={3} fontSize={16} fontWeight={400} color="gray.500">
          Send cards directly to your Jira backlog
        </Text>
      </ModalHeader>
      <ModalBody p={0}>
        <HStack justifyContent="space-between">
          <Heading size="md">
            1.{' '}
            <Text fontWeight={400} fontSize={18} as="span">
              Select a Jira project
            </Text>
          </Heading>
          <JiraOAuthProjectSelector
            selectedProject={selectedProject}
            onSelectProject={setSelectedProject}
          />
        </HStack>
        <Divider my={4} />
        <VStack
          opacity={selectedProject ? 1 : 0.3}
          cursor={selectedProject ? 'unset' : 'default'}
          pointerEvents={selectedProject ? 'unset' : 'none'}
          alignItems="stretch"
        >
          <HStack>
            <Heading size="md">
              2.{' '}
              <Text fontWeight={400} fontSize={18} as="span">
                Select cards to export
              </Text>
            </Heading>
          </HStack>
          <Flex
            flexWrap="wrap"
            py={4}
            pl={1}
            gap={1}
            maxH={400}
            overflowY="auto"
          >
            {allBoardCardsMinusImages.length ? (
              allBoardCardsMinusImages.map((card) => (
                <Flex
                  as="button"
                  key={card.id}
                  borderWidth={2}
                  borderColor={
                    isCardSelected(card) ? 'primary.500' : 'transparent'
                  }
                  borderStyle="solid"
                  onClick={() => toggleCard(card)}
                  borderRadius="lg"
                  h="fit-content"
                >
                  <BoardCardSummary card={card} />
                </Flex>
              ))
            ) : (
              <ExportBoardEmptyState />
            )}
          </Flex>
        </VStack>
        <HStack w="full" justifyContent="flex-end" mt={4}>
          <Button
            size="lg"
            isDisabled={isSubmitting}
            variant="outlineGray"
            type="submit"
            w="fit-content"
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            w="fit-content"
            isLoading={isSubmitting}
            isDisabled={!selectedCards.length || !selectedProject}
            onClick={onSubmit}
          >
            Export {selectedCards.length} {plural('card', selectedCards.length)}{' '}
            to Jira
          </Button>
        </HStack>
      </ModalBody>
    </ModalContent>
  );
};

export const ExportBoardJiraModal: FC<ExportBoardJiraModalProps> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <ExportBoardJiraModalContent {...props} />
  </Modal>
);
