import { FC, useMemo, useState } from 'react';
import { useCreateTrelloCardsFromCardsHandler } from '../../handlers';
import { BoardCardSummary } from '../BoardCardReadOnly';
import { ExportBoardEmptyState } from './ExportBoardEmptyState';
import { TrelloOAuthBoardAndListSelector } from './TrelloOAuthBoardAndListSelector';
import { CardFragmentFragment, List } from '@spoke/graphql';
import {
  ModalProps,
  log,
  TrelloBoard,
  TrelloList,
  useToast,
  useCurrentBoard,
  flattenListsCards,
  parseCardText,
  isImage,
  uniqueBy,
  TOAST_ERROR_GENERIC,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  TrelloIcon,
  Heading,
  ModalBody,
  Divider,
  VStack,
  Flex,
  Button,
  plural,
  Modal,
  ModalOverlay,
  Text,
} from '@spoke/common';

type ExportBoardTrelloModalProps = Omit<ModalProps, 'children'>;

export const ExportBoardTrelloModalContent: FC<ExportBoardTrelloModalProps> = (
  props
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [handleCreateTrelloCardsFromCards] =
    useCreateTrelloCardsFromCardsHandler();

  const [selectedCards, setSelectedCards] = useState<CardFragmentFragment[]>(
    []
  );
  const [selectedBoard, setSelectedBoard] = useState<TrelloBoard | null>(null);
  const [selectedList, setSelectedList] = useState<TrelloList | null>(null);

  const [toast] = useToast();
  const [board] = useCurrentBoard();

  const boardId = board?.id;

  const allBoardCardsMinusImages = useMemo(
    () =>
      flattenListsCards((board?.lists?.filter(Boolean) as List[]) || [])
        .filter((card) => !parseCardText(card.text).find(isImage))
        .map((card) => ({
          ...card,
          children: card.children?.filter(
            (child) => !parseCardText(child?.text || '').find(isImage)
          ),
        })),
    [board?.lists]
  );

  const isCardSelected = (card: CardFragmentFragment) =>
    selectedCards.some((c) => c.id === card.id);

  const toggleCard = (card: CardFragmentFragment) => {
    if (isCardSelected(card)) {
      setSelectedCards((prev) => prev.filter((c) => c.id !== card.id));
    } else {
      setSelectedCards((prev) => uniqueBy([...prev, card], 'id'));
    }
  };

  const onSubmit = async () => {
    const trelloListId = selectedList?.id;
    if (!trelloListId) {
      log.error(
        'No-op: cannot export to Trello without a selected Trello list id',
        { trelloListId, selectedCards, boardId }
      );
      return;
    }

    if (!boardId) {
      log.error(
        'No-op: cannot export to Trello without a loaded ScatterSpoke board id',
        { boardId, trelloListId, selectedCards }
      );
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    setIsSubmitting(true);
    const success = await handleCreateTrelloCardsFromCards({
      cards: selectedCards,
      trelloListId: selectedList.id,
      spokeBoardId: boardId,
    });
    setIsSubmitting(false);
    if (success) props.onClose();
  };

  const boardAndListSelected = Boolean(selectedBoard && selectedList);

  return (
    <ModalContent p={8} maxW={825}>
      <ModalCloseButton />
      <ModalHeader p={0}>
        <HStack>
          <Icon as={TrelloIcon} />
          <Heading mb={1} fontSize={24} color="gray.900">
            Export to Trello
          </Heading>
        </HStack>
        <Text mb={3} fontSize={16} fontWeight={400} color="gray.500">
          Send cards directly to your Trello board
        </Text>
      </ModalHeader>
      <ModalBody p={0}>
        <HStack justifyContent="space-between">
          <Heading size="md">
            1.{' '}
            <Text fontWeight={400} fontSize={18} as="span">
              Select a Trello board and list
            </Text>
          </Heading>
          <TrelloOAuthBoardAndListSelector
            onSelectBoard={setSelectedBoard}
            onSelectList={setSelectedList}
            selectedBoard={selectedBoard}
            selectedList={selectedList}
          />
        </HStack>
        <Divider my={4} />
        <VStack
          opacity={boardAndListSelected ? 1 : 0.3}
          cursor={boardAndListSelected ? 'unset' : 'default'}
          pointerEvents={boardAndListSelected ? 'unset' : 'none'}
          alignItems="stretch"
        >
          <HStack>
            <Heading size="md">
              2.{' '}
              <Text fontWeight={400} fontSize={18} as="span">
                Select cards to export
              </Text>
            </Heading>
          </HStack>
          <Flex
            flexWrap="wrap"
            py={4}
            pl={1}
            gap={1}
            maxH={400}
            overflowY="auto"
          >
            {allBoardCardsMinusImages.length ? (
              allBoardCardsMinusImages.map((card) => (
                <Flex
                  as="button"
                  key={card.id}
                  borderWidth={2}
                  borderColor={
                    isCardSelected(card) ? 'primary.500' : 'transparent'
                  }
                  borderStyle="solid"
                  onClick={() => toggleCard(card)}
                  borderRadius="lg"
                  h="fit-content"
                >
                  <BoardCardSummary card={card} />
                </Flex>
              ))
            ) : (
              <ExportBoardEmptyState />
            )}
          </Flex>
        </VStack>
        <HStack w="full" justifyContent="flex-end" mt={4}>
          <Button
            size="lg"
            isDisabled={isSubmitting}
            variant="outlineGray"
            type="submit"
            w="fit-content"
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            w="fit-content"
            isLoading={isSubmitting}
            isDisabled={!selectedCards.length || !boardAndListSelected}
            onClick={onSubmit}
          >
            Export {selectedCards.length} {plural('card', selectedCards.length)}{' '}
            to Trello
          </Button>
        </HStack>
      </ModalBody>
    </ModalContent>
  );
};

export const ExportBoardTrelloModal: FC<ExportBoardTrelloModalProps> = (
  props
) => (
  <Modal {...props}>
    <ModalOverlay />
    <ExportBoardTrelloModalContent {...props} />
  </Modal>
);
