import {
  PopoverCloseButton as ChakraPopoverCloseButton,
  PopoverCloseButtonProps as ChakraPopoverCloseButtonProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export type PopoverCloseButtonProps = ChakraPopoverCloseButtonProps;

export const PopoverCloseButton: FC<PopoverCloseButtonProps> = (props) => (
  <ChakraPopoverCloseButton {...props} />
);
