import { FC, memo } from 'react';
import { LivePollGoalCardAnswered } from './LivePollGoalCardAnswered';
import { LivePollGoalCardEnded } from './LivePollGoalCardEnded';
import { LivePollGoalCardRunning } from './LivePollGoalCardRunning';
import {
  LivePollUserStatus,
  ImprovementGoalTypeTargetType,
  LivePollSkipReason,
} from '@spoke/graphql';
import { isNullish } from '@spoke/common';

const { Running, Answered, Skipped, Discarded, Finished } = LivePollUserStatus;

type LivePollGoalCardProps = {
  pollId: string;
  title: string;
  status: LivePollUserStatus;
  skippedCount: number;
  answeredCount: number;
  teamMemberCount: number;
  isFacilitator: boolean;
  goalTypeColor: string;
  result: number | null;
  goalTypeName: string;
  goalTypeTarget: number;
  goalTypeTargetType: ImprovementGoalTypeTargetType;
  onSubmit: (data: { pollId: string; answer: number }) => void;
  onSkip: (data: { pollId: string; skipReason: LivePollSkipReason }) => void;
  onUndo: (data: { pollId: string }) => void;
  onDiscard: (data: { pollId: string }) => void;
};
export const LivePollGoalCard: FC<LivePollGoalCardProps> = ({
  pollId,
  title,
  status,
  answeredCount,
  skippedCount,
  teamMemberCount,
  isFacilitator,
  goalTypeColor,
  goalTypeName,
  goalTypeTarget,
  goalTypeTargetType,
  result,
  onDiscard,
  onSkip,
  onSubmit,
  onUndo,
}) => {
  const onSubmitClick = (answer: number) => {
    if (isNullish(answer)) return;
    onSubmit({ pollId, answer: answer! });
  };

  const onSkipClick = (skipReason: LivePollSkipReason) => {
    if (!skipReason) return;
    onSkip({ pollId, skipReason });
  };

  const onUndoClick = () => {
    onUndo({ pollId });
  };

  const onDiscardClick = () => {
    onDiscard({ pollId });
  };

  if (status === Running) {
    return (
      <LivePollGoalCardRunning
        title={title}
        onSubmit={onSubmitClick}
        onSkip={onSkipClick}
      />
    );
  }

  if (status === Answered || status === Skipped) {
    return (
      <LivePollGoalCardAnswered
        title={title}
        status={status}
        onUndo={onUndoClick}
        skippedCount={skippedCount}
        answeredCount={answeredCount}
        teamMemberCount={teamMemberCount}
      />
    );
  }

  if (status === Finished || status === Discarded) {
    return (
      <LivePollGoalCardEnded
        answeredCount={answeredCount}
        teamMemberCount={teamMemberCount}
        skippedCount={skippedCount}
        isFacilitator={isFacilitator}
        goalTypeColor={goalTypeColor}
        goalTypeName={goalTypeName}
        onDiscard={onDiscardClick}
        result={result}
        targetType={goalTypeTargetType}
        target={goalTypeTarget}
        status={status}
      />
    );
  }

  return null;
};

export const MemoizedLivePollGoalCard = memo(LivePollGoalCard);
