import { useState, useEffect, useCallback } from 'react';
import { useInstalledUserIntegrations } from './useInstalledUserIntegrations';
import { SpkHttp, isServer } from '@spoke/common';

export type ConfluenceSpace = {
  id: string;
  key: string;
  name: string;
};

type UseConfluenceIntegrationResult = {
  confluenceSpaces: ConfluenceSpace[] | null;
  confluenceLoading: boolean;
  confluenceInstalled: boolean;
  authenticateConfluence: () => void;
};
export const useConfluenceIntegration = (): UseConfluenceIntegrationResult => {
  const { confluence, loading: integrationInstalledLoading } =
    useInstalledUserIntegrations();

  const [confluenceSpaces, setConfluenceSpaces] =
    useState<UseConfluenceIntegrationResult['confluenceSpaces']>(null);
  const [confluenceSpacesLoading, setConfluenceSpacesLoading] = useState(false);

  useEffect(() => {
    if (!confluence) return;
    (async () => {
      setConfluenceSpacesLoading(true);
      setConfluenceSpaces(null);
      const listsRes = await SpkHttp.getConfluenceSpaces();
      setConfluenceSpacesLoading(false);
      setConfluenceSpaces(listsRes);
    })();
  }, [confluence]);

  const authenticateConfluence = useCallback(() => {
    if (isServer()) return;
    const frontendRedirectAfterAuth = new URL(window.location.origin);
    frontendRedirectAfterAuth.pathname = '/integrations/confluence/callback';
    const confluenceAuthUrl = new URL(process.env.NEXT_PUBLIC_API_URL || '');
    confluenceAuthUrl.pathname = '/integrations/confluence/auth';
    const stateJson = JSON.stringify({
      redirectUri: frontendRedirectAfterAuth.toString(),
    });
    confluenceAuthUrl.searchParams.append('state', stateJson);
    window?.open(confluenceAuthUrl.toString(), '_blank')?.focus();
  }, []);

  const confluenceLoading =
    integrationInstalledLoading || confluenceSpacesLoading;

  return {
    authenticateConfluence,
    confluenceLoading,
    confluenceSpaces,
    confluenceInstalled: confluence,
  };
};
