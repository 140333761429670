import { callIfExists } from '@spoke/common';
import {
  forwardRef,
  KeyboardEventHandler,
  MutableRefObject,
  useEffect,
  useRef,
} from 'react';
import { TextArea, TextAreaProps } from './TextArea';

type SpokeTextAreaProps = Omit<TextAreaProps, 'onSubmit'> & {
  onSubmit?: () => void;
  onEscape?: () => void;
};

export const SpokeTextArea = forwardRef<
  HTMLTextAreaElement,
  SpokeTextAreaProps
>(({ value, onSubmit, onEscape, ...props }, forwardedRef) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      callIfExists(onSubmit);
      e.preventDefault();
      return;
    }
    if (e.key === 'Escape') callIfExists(onEscape);
  };

  useEffect(() => {
    const textAreaEle = textAreaRef.current;
    if (!textAreaEle) return;
    textAreaEle.style.height = '0px';
    const scrollHeight = textAreaEle.scrollHeight;
    textAreaEle.style.height = scrollHeight + 'px';
  }, [value]);

  return (
    <TextArea
      variant="unstyled"
      boxSizing="border-box"
      _placeholder={{ color: 'gray.300' }}
      _focus={{
        color: 'gray.600',
        borderStyle: 'solid',
        borderColor: 'primary.500',
        borderWidth: '2px',
        pt: '9px',
      }}
      overflow="hidden"
      resize="none"
      w="full"
      bg="white"
      textAlign="center"
      pb="8px"
      pt="10px"
      px="10px"
      fontSize={14}
      color="gray.400"
      minH={10}
      maxH={32}
      spellCheck={false}
      {...props}
      ref={(r) => {
        if (!r) return;
        (textAreaRef as MutableRefObject<HTMLTextAreaElement>).current = r;
        if (forwardedRef) {
          (forwardedRef as MutableRefObject<HTMLTextAreaElement>).current = r;
        }
      }}
      onKeyDown={handleKeyDown}
      value={value}
    />
  );
});
