export enum ConfluenceError {
  DuplicatedName = 'CONFLUENCE_DUPLICATED_NAME',
  Unknown = 'CONFLUENCE_UNKNOWN',
}

export type ConfluenceSpace = {
  id: string;
  key: string;
  name: string;
};
