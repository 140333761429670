import { FC } from 'react';
import { OrgNav } from './Navs/OrgNav';
import { OrgSidebar } from './Navs/OrgSidebar';
import { VStack, NAV_BAR_HEIGHT, Box } from '@spoke/common';

export const OrgLayout: FC = ({ children }) => (
  <VStack minH="100vh" alignItems="flex-start" spacing={0}>
    <OrgNav />
    <Box display="flex" flex="1" w="full">
      <OrgSidebar />
      <Box
        h={`calc(100vh - ${NAV_BAR_HEIGHT})`}
        overflowY="scroll"
        bg="gray.50"
        flex="1"
        py={9}
        px={5}
      >
        {children}
      </Box>
    </Box>
  </VStack>
);
