import { useApolloClient } from '@apollo/client';
import {
  useToast,
  useCurrentBoardId,
  TOAST_ERROR_GENERIC,
  getBoardFromCache,
  log,
  optimisticUpdateBoard,
} from '@spoke/common';
import {
  Board,
  TakeawayStatus,
  useGenerateBoardTakeawaysMutation,
} from '@spoke/graphql';

export const useGenerateBoardTakeaways = () => {
  const [toast] = useToast();
  const [boardId] = useCurrentBoardId();
  const [generateBoardTakeaways] = useGenerateBoardTakeawaysMutation();
  const { cache } = useApolloClient();

  const handleGenerateBoardTakeaways = async () => {
    if (!boardId) {
      log.error('Missing critial data to generate board takeaways', {
        boardId,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const boardFromCache = getBoardFromCache({ cache, boardId });

    const currentState = boardFromCache?.state;

    const expected = optimisticUpdateBoard({
      cache,
      boardId,
      fields: {
        state: { ...currentState, takeAwayStatus: TakeawayStatus.Pending },
      },
    });

    const { data, errors } = await generateBoardTakeaways({
      variables: { boardId },
      optimisticResponse: {
        generateBoardTakeaways: expected as Board,
      },
    });

    if (errors) {
      log.error('generateBoardTakeaways call responded with errors', {
        boardId,
        errors,
        data,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }
  };

  return [handleGenerateBoardTakeaways];
};
