import { FC, MouseEvent, useState } from 'react';
import { RiChatPollLine } from 'react-icons/ri';
import { useStartGoalsLivePollHandler } from '../handlers';
import {
  useMultiSelect,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  Flex,
  VStack,
  Skeleton,
  Checkbox,
  Circle,
  Spacer,
  SpkTime,
  ModalFooter,
  Button,
  ModalProps,
  Modal,
  ModalOverlay,
  Text,
} from '@spoke/common';
import { ImprovementGoalFragmentFragment } from '@spoke/graphql';
import { useImprovementGoals } from '@spoke/improvement-goals';

type LivePollGoalSelectorModalProps = {
  onClose: () => void;
};
export const LivePollGoalSelectorModalContent: FC<
  LivePollGoalSelectorModalProps
> = ({ onClose }) => {
  // we could get only the last period (takeLastNPeriodsWithData: 1) but
  // to help apollo cache we get 5 periods because the pages that use
  // this modal already fetch the last 5 periods
  const [{ activeGoals: goals }, { loading: goalsLoading }] =
    useImprovementGoals({ variables: { takeLastNPeriodsWithData: 5 } });
  const [selectedGoals, toggleGoal, isGoalSelected] =
    useMultiSelect<ImprovementGoalFragmentFragment>('id');
  const [handleStartGoalsLivePoll] = useStartGoalsLivePollHandler();
  const [isLoading, setIsLoading] = useState(false);

  const pollableGoals = goals?.filter((g) => g.type.isPollable);

  const isValid = selectedGoals.length > 0;

  const onSubmit = async () => {
    if (!isValid) return;
    setIsLoading(true);
    const goalIds = selectedGoals.map((g) => g.id);
    const success = await handleStartGoalsLivePoll({ goalIds });
    setIsLoading(false);
    if (success) onClose();
  };

  const onPollableGoalClicked = (
    e: MouseEvent,
    goal: ImprovementGoalFragmentFragment
  ) => {
    e.preventDefault();
    toggleGoal(goal);
  };

  return (
    <ModalContent p={8} pb={4} maxW={600}>
      <ModalCloseButton />
      <ModalBody p={0}>
        <ModalHeader p={0}>
          <HStack>
            <Icon w={6} h={6} as={RiChatPollLine} />
            <Heading mb={1} fontSize={24} color="gray.900">
              Run poll
            </Heading>
          </HStack>
        </ModalHeader>
        <Flex flexDir="column" flex={1} my={3}>
          <Flex flexDir="column" mb={1}>
            <Text fontSize={15} fontWeight={500} color="gray.700">
              Select the goals you want to poll
            </Text>
            <Text fontSize={14} color="gray.500">
              Participants will rate from 1 to 10 the goals you select to poll
              them in.
            </Text>
          </Flex>

          {goalsLoading && (
            <VStack>
              <Skeleton w="full" h={50} />
              <Skeleton w="full" h={50} />
              <Skeleton w="full" h={50} />
            </VStack>
          )}

          {!goalsLoading &&
            pollableGoals?.map((goal) => {
              const label = goal.type.name;
              const lastDataPointDate = goal.data[0]?.date ?? null;
              return (
                <HStack
                  key={goal.id}
                  my={1}
                  layerStyle="outlineGray"
                  cursor="pointer"
                  onClick={(e) => onPollableGoalClicked(e, goal)}
                >
                  <Checkbox
                    isChecked={isGoalSelected(goal)}
                    onChange={() => toggleGoal(goal)}
                  />
                  <Circle
                    boxShadow="inner"
                    mb="3px"
                    size="9px"
                    bg={goal.type.color}
                  />
                  <Text pt="3px" fontSize={14} color="gray.600">
                    {label}
                  </Text>
                  <Spacer />
                  <Text
                    pt="3px"
                    fontSize={12}
                    color="gray.600"
                    textAlign="right"
                  >
                    {lastDataPointDate
                      ? `Last reported ${SpkTime.format(
                          lastDataPointDate,
                          'MMM dd, yyyy'
                        )}`
                      : 'Never reported'}
                  </Text>
                </HStack>
              );
            })}
        </Flex>
        <ModalFooter justifyContent="flex-end" gap={3} px={0} pb={2} pt={1}>
          <Button
            size="lg"
            fontSize={16}
            variant="outlineGray"
            disabled={isLoading}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            fontSize={16}
            isLoading={isLoading}
            isDisabled={!isValid}
            onClick={onSubmit}
          >
            Start Poll
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalContent>
  );
};

type LivePollGoalSelectorInnerModalProps = Omit<ModalProps, 'children'>;
export const LivePollGoalSelectorModal: FC<
  LivePollGoalSelectorInnerModalProps
> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <LivePollGoalSelectorModalContent onClose={props.onClose} />
  </Modal>
);
