import { FC } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { MdLinkOff } from 'react-icons/md';
import {
  IntegrationMoreInfoData,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  VStack,
  Button,
  Tooltip,
  ModalProps,
  Modal,
  ModalOverlay,
  Box,
  Text,
  Image,
  useAssets,
} from '@spoke/common';

type IntegrationMoreInfoModalProps = IntegrationMoreInfoData & {
  icon: FC;
  onInstall: () => void;
  onUninstall: () => void;
  isInstalled: boolean;
  isAdmin: boolean;
  onClose: () => void;
  installDisabledReason?: string;
};

export const IntegrationMoreInfoModalContent: FC<
  IntegrationMoreInfoModalProps
> = ({
  title,
  about,
  access,
  icon,
  image,
  isInstalled,
  isAdmin,
  usefulFor,
  installDisabledReason,
  onClose,
  onInstall,
  onUninstall,
}) => {
  const assets = useAssets();

  return (
    <ModalContent p={8} pb={4} maxW={600}>
      <ModalCloseButton />
      <ModalBody p={0}>
        <ModalHeader p={0}>
          <HStack>
            <Icon w={6} h={6} as={icon} />
            <Heading mb={1} fontSize={24} color="gray.900">
              {title}
            </Heading>
          </HStack>
        </ModalHeader>
        <VStack
          alignItems="flex-start"
          pt={4}
          spacing={3}
          sx={{
            '& b': {
              color: 'primary.600',
              fontWeight: 500,
            },
          }}
        >
          {image && <Image src={assets[image]} alt={title} w="100%" h={200} />}{' '}
          <VStack alignItems="flex-start" spacing={0}>
            <Text fontWeight={500} color="gray.700">
              About
            </Text>
            <Text
              fontWeight={400}
              color="gray.600"
              dangerouslySetInnerHTML={{ __html: about }}
            />
          </VStack>
          <VStack alignItems="flex-start" spacing={0}>
            <Text fontWeight={500} color="gray.700" mb={1}>
              Useful for
            </Text>
            {usefulFor.map((point) => (
              <Text key={point} ml={2} fontWeight={400} color="gray.600">
                • {point}
              </Text>
            ))}
          </VStack>
          <VStack alignItems="flex-start" spacing={1}>
            <Text fontWeight={500} color="gray.700" mb={1}>
              Access and usage
            </Text>
            {access.map((point) => (
              <HStack key={point.description} alignItems="flex-start">
                <Icon color="gray.600" mt={0} w={6} h={6} as={point.icon} />
                <Text
                  fontWeight={400}
                  color="gray.600"
                  dangerouslySetInnerHTML={{ __html: point.description }}
                />
              </HStack>
            ))}
          </VStack>
        </VStack>
        <HStack w="full" justifyContent="flex-end" mt={4} mb={2}>
          <Button
            size="lg"
            variant="outlineGray"
            type="submit"
            w="fit-content"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Tooltip
            label={
              isAdmin === true
                ? ''
                : 'You need to be an organization admin to manage integrations'
            }
            maxW={200}
            variant="white"
            placement="top"
            hasArrow
          >
            <Box tabIndex={-1}>
              {Boolean(isInstalled && onUninstall) && (
                <Button
                  onClick={onUninstall}
                  size="lg"
                  alignSelf="center"
                  ml={2}
                  isDisabled={!isAdmin}
                  leftIcon={<Icon mr="-3px" as={MdLinkOff} w={5} h={5} />}
                >
                  <Text pt="2px">Uninstall</Text>
                </Button>
              )}
              {Boolean(!isInstalled && onInstall) && (
                <Tooltip
                  label={installDisabledReason}
                  placement="top"
                  hasArrow
                  maxW={200}
                  variant="white"
                >
                  <Box tabIndex={-1}>
                    <Button
                      onClick={onInstall}
                      size="lg"
                      alignSelf="center"
                      ml={2}
                      isDisabled={Boolean(!isAdmin || installDisabledReason)}
                      leftIcon={
                        <Icon mr="-3px" as={AiOutlineDownload} w={5} h={5} />
                      }
                    >
                      <Text pt="2px">Install</Text>
                    </Button>
                  </Box>
                </Tooltip>
              )}
            </Box>
          </Tooltip>
        </HStack>
      </ModalBody>
    </ModalContent>
  );
};

type CreateTeamInnerModalProps = Omit<ModalProps, 'children' | 'onClose'> &
  IntegrationMoreInfoModalProps;

export const IntegrationMoreInfoModal: FC<CreateTeamInnerModalProps> = (
  props
) => (
  <Modal {...props}>
    <ModalOverlay />
    <IntegrationMoreInfoModalContent {...props} />
  </Modal>
);
