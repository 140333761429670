import { useCurrentUser, log } from '@spoke/common';
import { useUpdateUserMutation } from '@spoke/graphql';

export const useAnnouncementsModalState = () => {
  const [currentUser, { loading: currentUserLoading }] = useCurrentUser();
  const [updateUser, { loading: updateUserLoading }] = useUpdateUserMutation();

  async function setSeen() {
    log.info(`Setting state of announcements modal to seen`, { currentUser });
    if (!currentUser) {
      log.error(`No current user found`, { currentUser });
      return;
    }

    if (currentUser.announcementsModalSeen !== false) {
      log.warn(`Announcements modal already seen`, { currentUser });
      return;
    }

    const { errors } = await updateUser({
      variables: {
        userInput: {
          announcementsModalSeen: true,
        },
      },
      optimisticResponse: {
        updateUser: {
          ...currentUser,
          __typename: 'User',
          announcementsModalSeen: true,
        },
      },
    });

    if (errors?.length) {
      log.error(`Error setting announcements modal state to seen`, errors);
      return;
    }
  }

  return {
    seen: currentUser?.announcementsModalSeen,
    loading: updateUserLoading || currentUserLoading,
    setSeen,
  };
};
