import { QueryConfig } from '@spoke/common';
import { useDiscussionAnalyticsQuery } from '@spoke/graphql';
import { useCurrentTeam, useCurrentOrgTeam } from '@spoke/user';

type Config = QueryConfig<typeof useDiscussionAnalyticsQuery>;
type QueryRef = ReturnType<typeof useDiscussionAnalyticsQuery>;
type QueryData = NonNullable<QueryRef['data']>;

type Input = {
  endDate: Date;
  timeWindowLength: number;
  recursive?: boolean;
};

export const useDiscussionAnalytics = ({
  endDate,
  timeWindowLength,
  recursive = true,
}: Input): [QueryData | null, QueryRef] => {
  const [currentTeam] = useCurrentTeam();
  const [currentOrgTeam] = useCurrentOrgTeam();

  const config: Config = {
    variables: {
      teamId: currentTeam?.id as string,
      organizationTeamId: currentOrgTeam?.id as string,
      endDate: endDate.getTime(),
      middleDate: new Date(endDate.getTime() - timeWindowLength).getTime(),
      startDate: new Date(endDate.getTime() - timeWindowLength * 2).getTime(),
      recursive,
    },
  };

  const discussionAnalyticsQuery = useDiscussionAnalyticsQuery(config);

  const data: QueryData | null =
    discussionAnalyticsQuery.data ??
    discussionAnalyticsQuery.previousData ??
    null;

  return [data, discussionAnalyticsQuery];
};
