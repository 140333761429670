import { MdOutlinedFlag } from 'react-icons/md';
import { isNullish } from '@apollo/client/cache/inmemory/helpers';
import { FC, useState } from 'react';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import { ImprovementGoalValueInput } from '../ImprovementGoalValueInput';
import { GoalReminderForm } from '../GoalReminderForm';
import {
  ImprovementGoalUserReminderFrequency,
  ImprovementGoalTypeFragmentFragment,
} from '@spoke/graphql';
import {
  StepsTutorialProps,
  useDisclosure,
  IMPROVEMENT_GOAL_DATA_MAX_VALUE,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  ModalBody,
  SpkLink,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Badge,
  StepsTutorial,
  Spacer,
  Button,
  IconButton,
  Text,
  Link,
  SpkAssetKey,
} from '@spoke/common';

const POLLABLE_GOAL_TUTORIAL: StepsTutorialProps = {
  title: 'How to report this goal',
  subtitle:
    'This goal is poll based, meaning it needs your team inputs to be able to report it.',
  steps: [
    {
      image: SpkAssetKey.ImageGoalTutorial1,
      text: 'Before you retro, you can find a "Run poll" button next to your poll based goals.',
    },
    {
      image: SpkAssetKey.ImageGoalTutorial2,
      text: 'By running a poll your retro participants can rate the goal from 1 to 10.',
    },
    {
      image: SpkAssetKey.ImageGoalTutorial3,
      text: 'Once everyone answered the poll results will be added to the goal.',
    },
  ],
};

const MANUAL_GOAL_TUTORIAL: StepsTutorialProps = {
  title: 'How to report this goal',
  subtitle:
    'This goal is manually reported, meaning it needs your help to stay up to date.',
  steps: [
    {
      image: SpkAssetKey.ImageGoalTutorial1,
      text: 'To manually report a goal click the + button.',
    },
    {
      image: SpkAssetKey.ImageGoalTutorial2,
      text: 'Add the value you want to report that specific goal and click Report.',
    },
    {
      image: SpkAssetKey.ImageGoalTutorial3,
      text: 'The data point will be added and you can edit or delete it at anytime.',
    },
  ],
};

export type CreateGoalFormSchema = {
  initial?: number;
  target: number;
  reminderUserIds: string[];
  reminderFrequency?: ImprovementGoalUserReminderFrequency | null;
  reminderTime?: number | null;
};

type CreateGoalWizardFormProps = {
  goalType: ImprovementGoalTypeFragmentFragment;
  onSubmit: (data: CreateGoalFormSchema) => boolean | Promise<boolean>;
  onGoBack: () => void;
};

export const CreateGoalWizardForm: FC<CreateGoalWizardFormProps> = ({
  goalType,
  onGoBack,
  onSubmit,
}) => {
  const [selectedFrequency, setSelectedFrequency] =
    useState<ImprovementGoalUserReminderFrequency | null>(null);
  const [selectedTimeOfDayMs, setSelectedTimeOfDayMs] = useState<null | number>(
    null
  );
  const [reminderUserIds, setReminderUserIds] = useState<string[]>([]);

  const tutorialDisclosure = useDisclosure();

  const [target, setTarget] = useState('');
  const [initial, setInitial] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  // This is usually taken care of by the form library, but we're not using it here..
  // Couldn't figure out how to achieve the same validation results with it
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  const min = goalType.minValue;
  const max = goalType.maxValue;

  const isGoalDataValueInvalid = (toValidate: string) => {
    if (!toValidate.length) return true;
    const castValue = Number(toValidate);
    if (Number.isNaN(castValue)) return true;
    if (castValue > IMPROVEMENT_GOAL_DATA_MAX_VALUE) return true;
    if (!isNullish(min) && castValue < min!) return true;
    if (!isNullish(max) && castValue > max!) return true;
    return false;
  };

  const onFormSubmit = async () => {
    setTriedToSubmit(true);
    if (isFormInvalid) return;
    setIsSubmitting(true);
    const castTarget = Number(target);
    const castInitial = Number(initial);
    const success = await onSubmit({
      target: castTarget,
      initial: castInitial,
      reminderUserIds,
      reminderFrequency: selectedFrequency,
      reminderTime: selectedTimeOfDayMs ?? null,
    });
    if (!success) setIsSubmitting(false);
  };

  const isSettingReminderUp = Boolean(reminderUserIds.length);

  const isReminderValid =
    !isSettingReminderUp ||
    (reminderUserIds.length && selectedFrequency && selectedTimeOfDayMs);

  const isFormInvalid =
    (initial && isGoalDataValueInvalid(initial)) ||
    isGoalDataValueInvalid(target) ||
    !isReminderValid;

  const tutorialProps = goalType.isPollable
    ? { ...POLLABLE_GOAL_TUTORIAL }
    : { ...MANUAL_GOAL_TUTORIAL };

  if (!tutorialDisclosure.isOpen) tutorialProps.steps = [];

  return (
    <ModalContent p={8} maxW={800}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Icon as={MdOutlinedFlag} w={6} h={6} />
          <Heading mb={1} fontSize={24} color="gray.900">
            {goalType.name}
          </Heading>
        </HStack>
      </ModalHeader>
      <ModalBody p={0} display="flex" flexDirection="column">
        <Text fontSize={16} fontWeight={500} color="gray.900" mb={1}>
          About
        </Text>
        <Text fontSize={14} fontWeight={400} color="gray.500">
          {goalType.description}
        </Text>
        {goalType.learnMoreUrl && (
          <SpkLink href={goalType.learnMoreUrl} passHref>
            <Link textDecor="underline" w="fit-content" fontSize={14}>
              Learn more
            </Link>
          </SpkLink>
        )}

        <Divider my={4} />

        <Flex gap={10}>
          <FormControl>
            <FormLabel
              htmlFor="initial"
              fontWeight={400}
              color="gray.600"
              fontSize={16}
            >
              Target value
            </FormLabel>
            <ImprovementGoalValueInput
              isInvalid={triedToSubmit && isGoalDataValueInvalid(target)}
              targetType={goalType.targetType}
              unit={goalType.unit}
              onChange={setTarget}
              value={target}
              max={max}
              min={min}
              aggregationPeriod={goalType.aggregationPeriod}
              onSubmit={onFormSubmit}
            />
          </FormControl>
          <FormControl>
            <FormLabel
              htmlFor="initial"
              fontWeight={400}
              color="gray.600"
              fontSize={16}
            >
              Initial value{' '}
              <Badge
                colorScheme="gray"
                color="gray.500"
                fontSize={11}
                textTransform="none"
              >
                Optional
              </Badge>
            </FormLabel>
            <ImprovementGoalValueInput
              isInvalid={Boolean(
                initial && triedToSubmit && isGoalDataValueInvalid(initial)
              )}
              unit={goalType.unit}
              onChange={setInitial}
              value={initial}
              max={max}
              min={min}
              aggregationPeriod={goalType.aggregationPeriod}
              onSubmit={onFormSubmit}
            />
          </FormControl>
        </Flex>

        {!goalType.isPollable && (
          <>
            <Divider my={4} />
            <HStack>
              <Text fontSize={16} fontWeight={500} color="gray.700">
                Report reminder
              </Text>
              <Badge
                colorScheme="gray"
                color="gray.500"
                fontSize={11}
                textTransform="none"
              >
                Optional
              </Badge>
            </HStack>
            <Text fontSize={15} color="gray.500">
              Schedule a reminder to remember to update this goal.
            </Text>

            <GoalReminderForm
              frequency={selectedFrequency}
              timeMs={selectedTimeOfDayMs}
              userIds={reminderUserIds}
              onFrequencyChange={setSelectedFrequency}
              onTimeChange={setSelectedTimeOfDayMs}
              onUserIdsChange={setReminderUserIds}
              showValidationErrors={isSettingReminderUp && triedToSubmit}
            />
          </>
        )}

        <Divider my={4} />

        <Flex layerStyle="outlineGray" position="relative">
          <IconButton
            aria-label="Toggle tutorial"
            icon={
              <Icon
                as={tutorialDisclosure.isOpen ? BsChevronUp : BsChevronDown}
              />
            }
            position="absolute"
            top={3}
            right={3}
            onClick={tutorialDisclosure.toggle}
          />
          <StepsTutorial
            onHeaderClick={tutorialDisclosure.toggle}
            fontSize={15}
            gap={4}
            {...tutorialProps}
          />
        </Flex>

        <Spacer />
        <Flex ml="auto" mt={6} justifyContent="end" gap={2}>
          <Button
            size="lg"
            variant="outlineGray"
            type="submit"
            w="fit-content"
            onClick={onGoBack}
            isDisabled={isSubmitting}
          >
            Back
          </Button>
          <Button
            size="lg"
            w="fit-content"
            type="submit"
            isDisabled={triedToSubmit && isFormInvalid}
            isLoading={isSubmitting}
            onClick={onFormSubmit}
          >
            Set goal
          </Button>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};
