import { StyleProps } from '@chakra-ui/react';
import { FC, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from './Box';
import { Center, CenterProps } from './Center';
import { HStack } from './Stack/HStack';
import { Text } from './Text';

const ACCEPT_STYLE: StyleProps = {
  bg: 'primary.50',
  borderColor: 'primary.200',
};

const REJECT_STYLE: StyleProps = {
  bg: 'red.50',
  borderColor: 'red.200',
};

/**
 * Add more as needed
 */
export type DropzoneFileFormats = 'csv';

type FileDropzoneProps = CenterProps & {
  onUpload: (file: File) => void;
  label?: string;
  manualUploadLabel?: string;
  acceptFiles: readonly DropzoneFileFormats[];
};
export const FileDropzone: FC<FileDropzoneProps> = ({
  onUpload,
  label,
  manualUploadLabel,
  acceptFiles,
  ...rest
}) => {
  const accept = useMemo(() => {
    const formats = {} as Record<string, string[]>;
    if (acceptFiles.includes('csv')) formats['text/csv'] = ['.csv'];
    return formats;
  }, [acceptFiles]);

  const onDropAccepted = useCallback(
    (files: File[]) => {
      onUpload(files[0]);
    },
    [onUpload]
  );

  const {
    getRootProps,
    getInputProps,
    open: openFileSelector,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept,
    onDropAccepted,
  });

  return (
    <Center
      borderWidth={2}
      borderStyle="dashed"
      borderColor="gray.200"
      borderRadius="lg"
      minH={350}
      bg="gray.50"
      w="full"
      {...(isDragAccept ? ACCEPT_STYLE : {})}
      {...(isDragReject ? REJECT_STYLE : {})}
      {...getRootProps()}
      {...rest}
    >
      <Box as="input" {...getInputProps()} />
      {Boolean(!isDragAccept && !isDragReject) && (
        <HStack spacing={1}>
          {label && (
            <Text fontSize={14} color="gray.500">
              {label}
            </Text>
          )}
          {manualUploadLabel && (
            <Text
              fontSize={14}
              as="button"
              color="primary.500"
              textDecor="underline"
              onClick={openFileSelector}
            >
              {manualUploadLabel}
            </Text>
          )}
        </HStack>
      )}
      {isDragAccept && (
        <Text fontSize={14} color="primary.500">
          Drop to upload
        </Text>
      )}

      {isDragReject && (
        <Text fontSize={14} color="red.500">
          Please drop a valid file
        </Text>
      )}
    </Center>
  );
};
