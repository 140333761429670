import { ApolloCache } from '@apollo/client';
import {
  CardComment,
  CardCommentFragmentFragment,
  CardCommentFragmentFragmentDoc,
} from '@spoke/graphql';

type GetCommentFromCacheArgs = {
  commentId: string;
  cache: ApolloCache<object>;
};
export function getCardCommentFromCache({
  commentId,
  cache,
}: GetCommentFromCacheArgs): CardComment | null {
  return (
    (cache.readFragment({
      fragment: CardCommentFragmentFragmentDoc,
      fragmentName: 'CardCommentFragment',
      id: `CardComment:${commentId}`,
    }) as CardCommentFragmentFragment as CardComment) || null
  );
}
