import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentUser,
  getLivePollFromCache,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import {
  useUndoLivePollAnswerMutation,
  UndoLivePollAnswerMutation,
  LivePollUserStatus,
} from '@spoke/graphql';

export const useUndoLivePollAnswerHandler = () => {
  const [undoLivePoll] = useUndoLivePollAnswerMutation();
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [currentUser] = useCurrentUser();
  const userId = currentUser?.id;

  const handleUndoLivePollAnswer = useCallback(
    async ({ pollId }: { pollId: string }): Promise<boolean> => {
      if (!pollId || !userId) {
        log.error('Missing critical data to undoLivePollAnswer', {
          pollId,
          userId,
        });
        return false;
      }

      log.info('Undoing LivePoll answer', { pollId });

      const pollFromCache = getLivePollFromCache({ cache, pollId });

      const newSkippedUserIds = (pollFromCache?.skippedUserIds || []).filter(
        (id) => id !== userId
      );
      const newAnsweredUserIds = (pollFromCache?.answeredUserIds || []).filter(
        (id) => id !== userId
      );

      const expected: UndoLivePollAnswerMutation = {
        __typename: 'Mutation',
        removeLivePollAnswer: {
          __typename: 'LivePoll',
          id: pollId,
          status: LivePollUserStatus.Running,
          answeredUserIds: newAnsweredUserIds,
          skippedUserIds: newSkippedUserIds,
        },
      };

      const { errors } = await undoLivePoll({
        variables: { livePollId: pollId },
        optimisticResponse: expected,
      });

      if (errors) {
        log.error('undoLivePollAnswer call responded with errors', {
          pollId,
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [cache, toast, undoLivePoll, userId]
  );

  return [handleUndoLivePollAnswer];
};
