import { useCurrentUserQuery } from '@spoke/graphql';
import { useMemo } from 'react';

type QueryRef = ReturnType<typeof useCurrentUserQuery>;
type QueryData = NonNullable<QueryRef['data']>['user'];

export const useCurrentUser = (): [QueryData | null, QueryRef] => {
  const currentUserQuery = useCurrentUserQuery();
  const currentUser = useMemo(() => {
    const user = currentUserQuery.data?.user ?? null;
    return user;
  }, [currentUserQuery.data?.user]);
  return [currentUser, currentUserQuery];
};
