export const uniqueBy = <T>(
  arr: T[],
  keyOrKeyFn: ((entry: T) => string | number) | keyof T
): T[] => {
  const map: Record<string | number | symbol, T> = {};

  const keyFn =
    typeof keyOrKeyFn === 'function'
      ? keyOrKeyFn
      : (entry: T) => entry[keyOrKeyFn] as unknown as string;

  for (const entry of arr) {
    const key = keyFn(entry);
    if (map.key) continue;
    map[key] = entry;
  }

  return Object.values(map);
};
