import { TextProps } from '@chakra-ui/react';
import React, {
  useState,
  useEffect,
  ReactElement,
  useMemo,
  useRef,
} from 'react';
import { Box } from './Box';
import { Text } from './Text';

export type TypewriterTextSection =
  | {
      content: string;
      type: 'bold' | 'normal';
    }
  | {
      content: ReactElement | null;
      type: 'component';
    }
  | {
      type: 'lineBreak';
    };

const countLen = (content: TypewriterTextSection[]) =>
  content.reduce((acc, section) => {
    if (section.type === 'component') return acc + 1;
    if (section.type === 'lineBreak') return acc + 1;
    return acc + section.content.length;
  }, 0);

type Props = TextProps & {
  typeDelayMs?: number;
  startDelayMs?: number;
  caretFadeDelayMs?: number;
  content?: TypewriterTextSection[];
  children?: string;
  skipAnimation?: boolean;
};

/**
 * Can either pass complex formatted content as a prop, or a simple string as children
 */
export const Typewriter: React.FC<Props> = ({
  content: _content,
  typeDelayMs = 20,
  startDelayMs = 0,
  caretFadeDelayMs = 0,
  children,
  skipAnimation = false,
  ...rest
}) => {
  const content: TypewriterTextSection[] = useMemo(
    () => _content || [{ content: (children as string) ?? '', type: 'normal' }],
    [_content, children]
  );

  const textLen = useMemo(() => countLen(content), [content]);

  const [currentCharIdx, setCurrentCharIdx] = useState(
    skipAnimation ? textLen : 0
  );
  const [isTyping, setIsTyping] = useState(false);
  const [showCaret, setShowCaret] = useState(false);
  const startDelayCompleted = useRef(false);

  useEffect(() => {
    if (!skipAnimation) return;
    setCurrentCharIdx(textLen);
  }, [skipAnimation, textLen]);

  useEffect(() => {
    if (skipAnimation) return;
    setCurrentCharIdx(0);
    let index = 0;

    let typeTimeoutId: NodeJS.Timeout | null = null;
    let typeEndTimeoutId: NodeJS.Timeout | null = null;
    let typeStartTimeoutId: NodeJS.Timeout | null = null;

    const type = () => {
      setCurrentCharIdx(index);
      index++;
      if (index <= textLen) {
        typeTimeoutId = setTimeout(type, typeDelayMs);
      } else {
        typeEndTimeoutId = setTimeout(() => {
          setIsTyping(false);
        }, caretFadeDelayMs);
      }
    };

    typeStartTimeoutId = setTimeout(
      () => {
        startDelayCompleted.current = true;
        setIsTyping(true);
        type();
      },
      !startDelayCompleted.current ? startDelayMs : 0
    );

    return () => {
      if (typeTimeoutId) clearTimeout(typeTimeoutId);
      if (typeEndTimeoutId) clearTimeout(typeEndTimeoutId);
      if (typeStartTimeoutId) clearTimeout(typeStartTimeoutId);
    };
  }, [
    caretFadeDelayMs,
    content,
    startDelayMs,
    typeDelayMs,
    textLen,
    skipAnimation,
  ]);

  useEffect(() => {
    if (!isTyping) return;
    let timeoutId = setTimeout(() => setShowCaret(!showCaret), 500);
    return () => clearTimeout(timeoutId);
  }, [showCaret, isTyping]);

  let renderedCharCount = 0;
  const invisbleChars: string[] = [];

  return (
    <Text d="inline" {...rest}>
      {content.map((section, sectionIdx) => {
        if (currentCharIdx === 0) return null;
        if (section.type === 'component') {
          renderedCharCount++;
          const invisible = renderedCharCount > currentCharIdx;
          if (invisible) {
            // TODO this could improve. Not necessarily an inline component has this layout size. But works for now
            invisbleChars.push('    ');
            return null;
          }
          return (
            <Box key={sectionIdx} as="span" d="inline">
              {section.content}
            </Box>
          );
        }

        if (section.type === 'lineBreak') {
          renderedCharCount++;
          return <br key={sectionIdx} />;
        }

        return (
          <Text
            key={sectionIdx}
            as="span"
            fontWeight={section.type === 'bold' ? '500' : '400'}
          >
            {section.content.split('').map((char) => {
              if (renderedCharCount > currentCharIdx) {
                invisbleChars.push(char);
                return null;
              }
              renderedCharCount++;
              return char;
            })}
          </Text>
        );
      })}
      {isTyping && (
        <Text
          as="span"
          d="inline"
          color="gray.700"
          visibility={showCaret ? 'visible' : 'hidden'}
        >
          ▎
        </Text>
      )}
      {invisbleChars.length > 0 && (
        <Text as="span" d="inline" visibility="hidden" whiteSpace="pre-wrap">
          {invisbleChars.join('')}
        </Text>
      )}
    </Text>
  );
};
