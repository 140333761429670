import { LinkProps } from '@chakra-ui/react';
import { FC } from 'react';
import { IconProps, HStack, Icon, JiraIcon, Link } from '../../ui';

type Props = Omit<LinkProps, 'href' | 'as'> & {
  text: string;
  url: string;
  hideIcon?: boolean;
  iconProps?: IconProps;
};
export const IssueLink: FC<Props> = ({
  text,
  url,
  hideIcon,
  iconProps = {},
  ...rest
}) => (
  <HStack spacing={1}>
    {hideIcon ? <></> : <Icon as={JiraIcon} {...iconProps} />}
    <Link
      target="_blank"
      href={url}
      fontSize={14}
      fontWeight={500}
      textDecoration="underline"
      pt="3px"
      {...rest}
    >
      {text}
    </Link>
  </HStack>
);
