import {
  ComponentStyleConfig,
  FormLabel as ChakraFormLabel,
  FormLabelProps as ChakraFormLabelProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const FormLabelComponentStyle: ComponentStyleConfig = {
  sizes: {
    sm: {
      fontWeight: 500,
      fontSize: 'xs',
    },
  },
  defaultProps: {
    size: 'sm',
  },
};

type Props = ChakraFormLabelProps;

export const FormLabel: FC<Props> = (props) => <ChakraFormLabel {...props} />;
