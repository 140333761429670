import { FC, memo, RefObject, useEffect, useRef, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { BiTrashAlt } from 'react-icons/bi';
import { BsArrowUpCircle, BsThreeDots } from 'react-icons/bs';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { useCurrentTeamParentPrograms } from '@spoke/user';
import {
  useOutsideClick,
  HStack,
  Checkbox,
  Flex,
  Spacer,
  Square,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  Text,
  Input,
} from '@spoke/common';

type ParkingLotItemProps = {
  onEditText: (parkingLotId: string, newText: string) => void;
  onDelete: (parkingLotId: string) => void;
  onSelect: (parkingLotId: string, newSelected: boolean) => void;
  onPromoteToProgram: (parkingLotId: string) => void;
  id: string;
  text: string;
  isSelected: boolean;
};
export const ParkingLotItem: FC<ParkingLotItemProps> = ({
  isSelected,
  onPromoteToProgram,
  onDelete,
  onEditText,
  onSelect,
  text,
  id,
}) => {
  const [parents] = useCurrentTeamParentPrograms();
  const [isEditing, setIsEditing] = useState(false);
  const [editText, setEditText] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);
  const confirmButtonRef = useRef<HTMLDivElement>(null);

  const handleEditCommit = () => {
    onEditText(id, editText);
    setIsEditing(false);
  };

  const handleEditCancel = () => {
    setEditText(text);
    setIsEditing(false);
  };

  useOutsideClick(handleEditCancel, inputRef, confirmButtonRef);

  useEffect(() => {
    setEditText(text);
  }, [text]);

  return (
    <>
      <HStack>
        <Checkbox
          isChecked={isSelected}
          onChange={(e) => onSelect(id, e.target.checked)}
          size="lg"
        />
        {!isEditing && (
          <Flex
            cursor="pointer"
            h="full"
            w="full"
            onClick={() => onSelect(id, !isSelected)}
          >
            <Text fontWeight={400}>{editText}</Text>
          </Flex>
        )}
        {isEditing && (
          <Input
            variant="unstyled"
            value={editText}
            onChange={(e) => setEditText(e.target.value)}
            onKeyDown={(e) => (e.key === 'Enter' ? handleEditCommit() : null)}
            borderBottom="2px solid"
            borderColor="primary.500"
            borderRadius={0}
            autoFocus
            ref={inputRef}
          />
        )}
        <Spacer />
        {isEditing && (
          <Square
            as="button"
            borderRadius="lg"
            boxShadow="extralight"
            layerStyle="outlineGray"
            bg="primary.500"
            p={2}
            onClick={handleEditCommit}
            ref={confirmButtonRef as RefObject<any>} // Chakra ref forwarding typedefs acting weird
          >
            <Icon color="white" as={AiOutlineCheck} w={5} h={5} />
          </Square>
        )}
        <Menu>
          {!isEditing && (
            <MenuButton>
              <Square
                borderRadius="lg"
                boxShadow="extralight"
                layerStyle="outlineGray"
                p={2}
              >
                <Icon color="gray.600" as={BsThreeDots} w={5} h={5} />
              </Square>
            </MenuButton>
          )}
          <MenuList>
            <MenuItem
              alignItems="center"
              gap={2}
              onClick={() => setIsEditing(true)}
            >
              <Icon color="gray.600" as={MdOutlineModeEditOutline} />
              <Text>Edit</Text>
            </MenuItem>
            {!!parents?.length && (
              <MenuItem
                alignItems="center"
                gap={2}
                onClick={() => onPromoteToProgram(id)}
              >
                <Icon color="gray.600" as={BsArrowUpCircle} />
                <Text>Promote to Program</Text>
              </MenuItem>
            )}
            <MenuItem alignItems="center" gap={2} onClick={() => onDelete(id)}>
              <Icon color="gray.600" as={BiTrashAlt} />
              <Text>Delete</Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
      <Divider />
    </>
  );
};

export const MemoizedParkingLotItem = memo(ParkingLotItem);
