import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useCurrentUser,
  useToast,
  TOAST_ERROR_GENERIC,
  createCardUpdateFunction,
  optimisticCreateCard,
  log,
} from '@spoke/common';
import { useCreateCardMutation } from '@spoke/graphql';

type UseAddCardHandlerArgs = {
  boardId: string;
  teamId: string;
  listId: string;
};
export const useAddCardHandler = ({
  boardId,
  teamId,
  listId,
}: UseAddCardHandlerArgs) => {
  const [currentUser] = useCurrentUser();
  const [createCard] = useCreateCardMutation();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  const handleAddCard = useCallback(
    async (text: string): Promise<boolean> => {
      if (!text || !currentUser?.id || !boardId || !teamId) {
        log.error('Missing critical data to add a card', {
          text,
          currentUserId: currentUser?.id,
          boardId,
          teamId,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      const authorId = currentUser.id;

      const { errors } = await createCard({
        variables: {
          listId,
          text,
        },
        update: createCardUpdateFunction,
        optimisticResponse: optimisticCreateCard({
          cache,
          listId,
          authorId,
          text,
        }),
      });

      if (errors) {
        log.error('CreateCard call responded with errors', { errors });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [boardId, cache, createCard, currentUser?.id, listId, teamId, toast]
  );

  return [handleAddCard];
};
