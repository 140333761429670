import {
  MenuItem as ChakraMenuItem,
  MenuItemProps as ChakraMenuItemProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export type MenuItemProps = ChakraMenuItemProps;

export const MenuItem: FC<MenuItemProps> = (props) => (
  <ChakraMenuItem {...props} />
);
