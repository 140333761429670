// False positive
// eslint-disable-next-line import/no-unresolved
import { parse } from 'csv-parse/sync';

export const parseCsv = async (
  csv: string
): Promise<Record<string, string>[]> => {
  const records = parse(csv, {
    columns: true,
    skipEmptyLines: true,
    relaxColumnCount: true,
  }) as Record<string, string>[];

  const parsedRecords: Record<string, string>[] = [];

  for (const record of records) {
    const parsedRecord = {} as Record<string, string>;
    for (const key in record) {
      // For some reason empty key/values getting in here
      if (!key) continue;
      const value = record[key];
      parsedRecord[key] = value;
    }
    parsedRecords.push(parsedRecord);
  }

  return parsedRecords;
};
