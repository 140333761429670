import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useTeamTree } from '../queries';
import {
  useToast,
  TOAST_ERROR_GENERIC,
  optimisticRemoveTeamFromProgram,
  log,
} from '@spoke/common';
import {
  useRemoveTeamFromProgramMutation,
  RemoveTeamFromProgramMutation,
} from '@spoke/graphql';

export const useRemoveTeamFromProgramHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [_, teamTreeQuery] = useTeamTree();
  const [removeTeamFromProgram] = useRemoveTeamFromProgramMutation();

  const handleRemoveTeamFromProgram = useCallback(
    async ({
      teamId,
      programId,
    }: {
      teamId: string;
      programId: string;
    }): Promise<RemoveTeamFromProgramMutation['leaveProgram'] | null> => {
      if (!teamId || !programId) {
        log.error('Missing critical data to remove team from program', {
          teamId,
          programId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const expected = optimisticRemoveTeamFromProgram({
        cache,
        teamId,
        programId,
      });

      const { errors, data } = await removeTeamFromProgram({
        variables: {
          teamId,
          programId,
        },
        optimisticResponse: expected,
      });

      if (errors) {
        log.warn('removeTeamFromProgram mutation responded with error', {
          error: errors,
        });
        return null;
      }

      await teamTreeQuery.refetch();

      return data?.leaveProgram;
    },
    [cache, removeTeamFromProgram, toast, teamTreeQuery]
  );

  return [handleRemoveTeamFromProgram];
};
