import {
  ComponentStyleConfig,
  Spinner as ChakraSpinner,
  SpinnerProps as ChakraSpinnerProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export const SpinnerComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    color: 'primary.500',
  },
};

type Props = ChakraSpinnerProps;

export const Spinner = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ChakraSpinner ref={ref} {...props} />
));
