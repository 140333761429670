import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  optimisticArchiveCardComment,
  archiveCardCommentUpdateFunction,
  TOAST_ERROR_GENERIC,
  useToast,
  log,
} from '@spoke/common';
import { useArchiveCardCommentMutation } from '@spoke/graphql';

export type DeleteCardCommendHandler = (commentId: string) => Promise<void>;

export const useDeleteCardCommentHandler = () => {
  const [archiveComment] = useArchiveCardCommentMutation();
  const [toast] = useToast();
  const { cache } = useApolloClient();

  const handleDeleteCardComment = useCallback(
    async (commentId: string): Promise<boolean> => {
      const { errors } = await archiveComment({
        variables: { commentId },
        optimisticResponse: optimisticArchiveCardComment({
          commentId,
          cache,
        }),
        update: archiveCardCommentUpdateFunction,
      });

      if (errors) {
        log.error('UpdateCardComment (archive) call responded with error', {
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return false;
    },
    [archiveComment, cache, toast]
  );

  return [handleDeleteCardComment];
};
