import { ChakraProps, useMultiStyleConfig, useTab } from '@chakra-ui/react';
import { FC } from 'react';
import { IconType } from 'react-icons';
import { Button } from '../Button';
import { Icon as UIIcon } from '../Icon';
import { Text } from '../Text';

export type SpkTabProps = ChakraProps & {
  fontSize: number;
  Icon?: IconType;
  iconW?: number;
  iconH?: number;
};

export const SpkTab: FC<SpkTabProps> = ({
  fontSize,
  Icon,
  iconH,
  iconW,
  ...rest
}) => {
  const tabProps = useTab({ ...rest });
  const isSelected = !!tabProps['aria-selected'];

  const styles = useMultiStyleConfig('Tabs', tabProps);

  return (
    <Button
      __css={styles.tab}
      {...tabProps}
      w="full"
      leftIcon={
        <UIIcon
          as={Icon}
          fontSize={fontSize}
          color={isSelected ? 'primary.600' : 'inherit'}
          w={iconW}
          h={iconH}
        />
      }
      alignItems="center"
      justifyContent="center"
      flexDir="row"
      display="flex"
      _active={{}}
    >
      <Text fontSize={fontSize}> {tabProps.children} </Text>
    </Button>
  );
};
