import { makeVar } from '@apollo/client';
import { useEffect } from 'react';
import {
  QueryConfig,
  useNetworkContext,
  useInstanceCount,
  log,
  deepMerge,
  DeepPartial,
  useCurrentBoard,
} from '@spoke/common';
import {
  useActionItemReviewAnalyticsLazyQuery,
  ActionItemReviewAnalyticsUpdatesSubscription,
  ActionItemReviewAnalyticsUpdatesDocument,
} from '@spoke/graphql';

type Config = QueryConfig<typeof useActionItemReviewAnalyticsLazyQuery>;
type QueryRef = ReturnType<typeof useActionItemReviewAnalyticsLazyQuery>[1];
type QueryData =
  | NonNullable<QueryRef['data']>['actionItemReviewAnalytics']
  | null;

// This state persists app-wide, not component-specific. So we don't create unecessary susbcriptions
const subscribedToChangesVar = makeVar<boolean>(false);
const unsubscriptionsVar = makeVar<(() => void)[]>([]);

export const useActionItemsReview = (
  config?: DeepPartial<Config>
): [QueryData, QueryRef] => {
  const { shouldPoll } = useNetworkContext();
  const [board] = useCurrentBoard();
  const [getInstanceCount] = useInstanceCount('useActionItemsReview');

  const baseConfig: DeepPartial<Config> = {
    variables: {
      teamId: board?.teamId ?? '',
    },
  };

  const finalConfig = (
    config ? deepMerge(baseConfig, config) : baseConfig
  ) as Config;

  const [load, actionItemReviewQuery] = useActionItemReviewAnalyticsLazyQuery();

  useEffect(() => {
    if (board?.teamId && !actionItemReviewQuery.called) {
      load({ variables: { teamId: board.teamId } });
    }
  }, [actionItemReviewQuery.called, board?.teamId, load]);

  useEffect(() => {
    const shouldSubscribe = Boolean(
      !subscribedToChangesVar() &&
        !shouldPoll &&
        actionItemReviewQuery.called &&
        finalConfig.variables?.teamId
    );

    if (!shouldSubscribe) return;

    subscribedToChangesVar(true);
    log.info('Starting ActionItemsReviewAnalytics subscription');

    const unsubscribe =
      actionItemReviewQuery.subscribeToMore<ActionItemReviewAnalyticsUpdatesSubscription>(
        {
          document: ActionItemReviewAnalyticsUpdatesDocument,
          variables: {
            teamId: finalConfig.variables?.teamId ?? '',
          },
        }
      );

    unsubscriptionsVar([unsubscribe]);
  }, [actionItemReviewQuery, finalConfig.variables?.teamId, shouldPoll]);

  useEffect(
    () => () => {
      // This allows us to only unsubscribe when all useActionItemsReview hooks are unmounted
      const isLastInstance = getInstanceCount() === 0;
      if (!isLastInstance) return;
      log.info('Stopping ActionItemReviewAnalytics subscription');
      for (const unsubscribe of unsubscriptionsVar()) unsubscribe();
      subscribedToChangesVar(false);
    },
    [getInstanceCount]
  );

  const data =
    actionItemReviewQuery.data?.actionItemReviewAnalytics ??
    actionItemReviewQuery.previousData?.actionItemReviewAnalytics ??
    null;

  return [data, actionItemReviewQuery];
};
