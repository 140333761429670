import { FC, useState, useCallback, Fragment } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import {
  useReplaceImprovementGoalDataHandler,
  useArchiveImprovementGoalDataHandler,
} from '../../handlers';
import { useImprovementGoal } from '../../queries';
import { improvementGoalDataToPointChartData } from '../../util';
import { ImportDataWizardModal } from '../ImportDataWizard';
import { ReportGoalDataModal } from '../ReportGoalDataModal';
import { AnalyticsRollupFilterBar } from '../AnalyticsRollupFiltersBar';
import { ImprovementGoalData, ImprovementGoalTeamType } from '@spoke/graphql';
import {
  BoxProps,
  useToast,
  useDisclosure,
  Flex,
  Circle,
  Spacer,
  Icon,
  Tooltip,
  Button,
  Skeleton,
  SpkTime,
  Box,
  Text,
} from '@spoke/common';
import { BiInfoCircle } from 'react-icons/bi';
import {
  getImprovementGoalDataPopover,
  PointChart,
  PointChartData,
} from '@spoke/charts';
import { useTeamType } from '@spoke/user';
import { DateRange } from 'react-day-picker';

export type GoalDrilldownChartProps = BoxProps & {
  goal: ReturnType<typeof useImprovementGoal>[0];
  onDateRangeChange: (dateRange: DateRange) => void;
  onReportingDataTeamIdsChange: (
    reportingDataTeamIds: string[],
    improvementGoalTeamType: ImprovementGoalTeamType
  ) => void;
  refetchData: () => void;
};

export const GoalDrilldownChart: FC<GoalDrilldownChartProps> = ({
  goal,
  onDateRangeChange,
  onReportingDataTeamIdsChange,
  refetchData,
  ...rest
}) => {
  const [toast] = useToast();
  const lastEntry = goal && goal.data[goal.data.length - 1];
  const [selectedPoint, setSelectedPoint] = useState<PointChartData | null>(
    null
  );
  const [isReporting, setIsReporting] = useState(false);
  const importDisclosure = useDisclosure();

  const [replaceGoalData] = useReplaceImprovementGoalDataHandler();
  const [archiveGoalData] = useArchiveImprovementGoalDataHandler();
  const { isTeam } = useTeamType();

  const onReplace = useCallback(
    async (goalDataId: string, newValue: number) => {
      setSelectedPoint(null);
      await replaceGoalData({ goalDataId, newValue });
      await refetchData();
      toast({
        status: 'success',
        title: 'Success',
        description: `Successfully updated data point.`,
      });
    },
    [refetchData, replaceGoalData, toast]
  );

  const onArchive = useCallback(
    async (goalDataId: string) => {
      setSelectedPoint(null);
      await archiveGoalData({ goalDataId });
      await refetchData();
      toast({
        status: 'success',
        title: 'Success',
        description: `Successfully deleted data point.`,
      });
    },
    [archiveGoalData, refetchData, toast]
  );

  const onTeamChanges = (teamIds: string[]) => {
    onReportingDataTeamIdsChange(teamIds, ImprovementGoalTeamType.Team);
  };

  const onProgramChanges = (programIds: string[]) => {
    onReportingDataTeamIdsChange(programIds, ImprovementGoalTeamType.Program);
  };

  return (
    <Box {...rest}>
      {goal ? (
        <Flex alignItems="center" gap={2} mb={2}>
          <Circle mb={1} bg={goal.type.color} size={2} />
          <Text fontSize={15} color="gray.700" fontWeight={500}>
            {goal.type.name}
          </Text>
          <Tooltip
            label={`${goal.type.description}`}
            openDelay={350}
            placement="top"
            fontSize={12}
            maxW={250}
            variant="white"
            hasArrow
          >
            <Box tabIndex={-1} pt="3px">
              <Icon as={BiInfoCircle} color="gray.500" />
            </Box>
          </Tooltip>
          <Spacer />
          <Flex alignItems="baseline" gap={1} mr={2}>
            <AnalyticsRollupFilterBar
              onProgramsChange={onProgramChanges}
              onTeamsChange={onTeamChanges}
              onDateRangeChange={onDateRangeChange}
            />
          </Flex>
          {isTeam && (
            <Tooltip
              variant="white"
              placement="left"
              hasArrow
              label={goal.paused ? 'This goal is currently paused' : ''}
            >
              <Box tabIndex={-1}>
                <Button
                  variant="outlineGray"
                  leftIcon={<Icon as={BsPlusLg} />}
                  onClick={() => setIsReporting(true)}
                  disabled={goal.paused}
                >
                  Report
                </Button>
              </Box>
            </Tooltip>
          )}
        </Flex>
      ) : (
        <Flex alignItems="center" gap={2} mb={2}>
          <Skeleton h="14px" width={12} />
          <Spacer />
          <Skeleton h="14px" width={20} />
          {/* I measured the button to make sure that the layout reflows as little as possible. Not sure on how consistent this is */}
          <Skeleton h="40px" width="106px" />
        </Flex>
      )}
      {goal ? (
        <PointChart
          data={improvementGoalDataToPointChartData(
            goal.data as ImprovementGoalData[]
          )}
          selectedPoint={selectedPoint}
          onSelectPoint={setSelectedPoint}
          targetDirection={goal.type.targetType}
          unit={goal?.type?.unit}
          popover={getImprovementGoalDataPopover({
            goalName: goal.type.name,
            onDelete: onArchive,
            onEdit: onReplace,
            target: goal.target,
            max: goal.type.maxValue,
            min: goal.type.minValue,
            unit: goal.type.unit,
            targetType: goal.type.targetType,
            aggregationPeriod: goal.type.aggregationPeriod,
          })}
          target={goal.target}
          h={400}
        />
      ) : (
        <Skeleton w="full" h={200} mb="24px" />
      )}
      {goal ? (
        <Text mt={1} color="gray.600" fontSize={12} fontWeight={500}>
          Last entry:{' '}
          {!lastEntry
            ? 'N/A'
            : SpkTime.format(new Date(lastEntry.date), 'MMMM dd, yyyy')}
        </Text>
      ) : (
        <Skeleton w="128px" h="14px" />
      )}
      {goal && (
        <Fragment>
          <ImportDataWizardModal
            isOpen={importDisclosure.isOpen}
            onClose={importDisclosure.close}
            improvementGoalId={goal.id}
          />
          <ReportGoalDataModal
            isOpen={isReporting}
            onClose={() => setIsReporting(false)}
            improvementGoalId={goal.id}
            onImportCSVClicked={() => {
              importDisclosure.open();
              setIsReporting(false);
            }}
          />
        </Fragment>
      )}
    </Box>
  );
};
