import { ImprovementGoalUnit } from '@spoke/graphql';

type UnitDisplay = {
  singular: string;
  plural: string;
  short: string;
  position: 'prefix' | 'suffix';
};

export const GOAL_UNIT_MAP: Record<ImprovementGoalUnit, UnitDisplay> = {
  [ImprovementGoalUnit.Abstract]: {
    singular: '',
    plural: '',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.BugsByChanges]: {
    singular: 'change',
    plural: 'changes',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.BugsInProd]: {
    singular: 'bug',
    plural: 'bugs',
    short: '',
  },
  [ImprovementGoalUnit.Days]: {
    singular: 'day',
    plural: 'days',
    short: 'd',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Deployments]: {
    singular: 'deployment',
    plural: 'deployments',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Documents]: {
    singular: 'document',
    plural: 'documents',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.BugsInProd]: {
    singular: 'bug',
    plural: 'bugs',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.BugsByChanges]: {
    singular: 'bug',
    plural: 'bugs',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Dollars]: {
    singular: 'dollar',
    plural: 'dollars',
    short: '$',
    position: 'prefix',
  },
  [ImprovementGoalUnit.Handoffs]: {
    singular: 'handoff',
    plural: 'handoffs',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Hours]: {
    singular: 'hour',
    plural: 'hours',
    short: 'h',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Incidents]: {
    singular: 'incident',
    plural: 'incidents',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Interruptions]: {
    singular: 'interruption',
    plural: 'interruptions',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Meetings]: {
    singular: 'meeting',
    plural: 'meetings',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Minutes]: {
    singular: 'minute',
    plural: 'minutes',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.NewCostumers]: {
    singular: 'new customer',
    plural: 'new customers',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.NpsScore]: {
    plural: 'NPS score',
    singular: 'NPS score',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.People]: {
    plural: 'people',
    singular: 'person',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Percent]: {
    plural: '%',
    singular: '%',
    short: '%',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Pages]: {
    plural: 'pages',
    singular: 'page',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Points]: {
    plural: 'points',
    singular: 'point',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.Projects]: {
    plural: 'projects',
    singular: 'project',
    short: '',
    position: 'suffix',
  },
  [ImprovementGoalUnit.PullRequests]: {
    plural: 'PRs',
    singular: 'PR',
    short: '',
    position: 'suffix',
  },
};
