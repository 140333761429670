import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  getCardFromCache,
  TOAST_ERROR_GENERIC,
  optimisticUpdateCard,
  updateCardUpdateFunction,
  log,
  useToast,
} from '@spoke/common';
import { useUpdateCardMutation, Card } from '@spoke/graphql';

export type EditCardTextHandler = (
  baseCardId: string,
  editedCardId: string,
  newText: string
) => Promise<string>;

export const useEditCardTextHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [updateCard] = useUpdateCardMutation();

  const handleEditCardText = useCallback(
    async (baseCardId: string, editedCardId: string, newText: string) => {
      const baseCard = getCardFromCache({ cardId: baseCardId, cache });

      if (!baseCard) {
        log.error('Could not edit card text: cached base card not found', {
          baseCardId,
          editedCardId,
          newText,
          baseCard,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const fields: Partial<Card> = { text: newText };

      const cardBeingEdited =
        baseCard.id === editedCardId
          ? baseCard
          : baseCard.children?.find(
              (groupCard) => groupCard?.id === editedCardId
            );

      if (!cardBeingEdited) {
        log.error('Could not find specified card to update text', {
          cardId: editedCardId,
          newText,
          card: baseCard,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const { errors } = await updateCard({
        variables: {
          cardId: editedCardId,
          fields,
        },
        optimisticResponse: optimisticUpdateCard({
          newFields: fields,
          oldFields: cardBeingEdited,
        }),
        update: updateCardUpdateFunction,
      });

      if (errors) {
        log.error('UpdateCard (edit text) call responded with errors', {
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [cache, toast, updateCard]
  );

  return [handleEditCardText];
};
