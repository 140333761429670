import { EMPTY_ASSETS, SpkAssets } from '@spoke/common';

const PREFIX = '/assets/next';

export const WEB_ASSETS: SpkAssets = {
  ...EMPTY_ASSETS,
  illustration404: `${PREFIX}/images/404.svg`,
  illustrationCalendar: `${PREFIX}/images/calendar.svg`,
  illustrationCanvas: `${PREFIX}/images/canvas.svg`,
  illustrationCards: `${PREFIX}/images/cards.svg`,
  illustrationCollaboration: `${PREFIX}/images/collaboration.svg`,
  illustrationTakeaways: `${PREFIX}/images/takeaways.svg`,
  illustrationHandoff: `${PREFIX}/images/handoff.svg`,
  illustrationHello: `${PREFIX}/images/hello.svg`,
  illustrationIncrease: `${PREFIX}/images/increase.svg`,
  illustrationInvestigate: `${PREFIX}/images/investigate.svg`,
  illustrationLook: `${PREFIX}/images/look.png`,
  illustrationPerpetrator: `${PREFIX}/images/perpetrator.svg`,
  illustrationPlans: `${PREFIX}/images/plans.svg`,
  illustrationStopwatch: `${PREFIX}/images/stopwatch.svg`,
  illustrationTable: `${PREFIX}/images/table.svg`,
  illustrationTeammates: `${PREFIX}/images/teammates.svg`,
  illustrationWriting: `${PREFIX}/images/writing.svg`,
  illustrationYellowPage: `${PREFIX}/images/yellow-page.svg`,
  imageAnnouncementModalBanner: `${PREFIX}/images/onboarding/announcement-modal-banner.svg`,
  imageConnectMetricsCallToAction: `${PREFIX}/images/integrations/connect-metrics-call-to-action.svg`,
  imageGetStarted: `${PREFIX}/images/onboarding/dashboard-purple-background.svg`,
  imageGetStartedQuestions: `${PREFIX}/images/onboarding/issues-dashboard.svg`,
  imageGetStartedQuestionsIntegrations: `${PREFIX}/images/onboarding/integrations.svg`,
  imageGetStartedIntegrations: `${PREFIX}/images/onboarding/onboarding-integrations.svg`,
  imageGetStartedRetroBoard: `${PREFIX}/images/onboarding/onboarding-retro.svg`,
  imageGoalTutorial1: `${PREFIX}/images/goal-tutorial/goal-tutorial-1.svg`,
  imageGoalTutorial2: `${PREFIX}/images/goal-tutorial/goal-tutorial-2.svg`,
  imageGoalTutorial3: `${PREFIX}/images/goal-tutorial/goal-tutorial-3.svg`,
  imageGoalTutorialManual1: `${PREFIX}/images/goal-tutorial/goal-tutorial-manual-1.svg`,
  imageGoalTutorialManual2: `${PREFIX}/images/goal-tutorial/goal-tutorial-manual-2.svg`,
  imageGoalTutorialManual3: `${PREFIX}/images/goal-tutorial/goal-tutorial-manual-3.svg`,
  imageGoalTutorialPoll1: `${PREFIX}/images/goal-tutorial/goal-tutorial-poll-1.svg`,
  imageGoalTutorialPoll2: `${PREFIX}/images/goal-tutorial/goal-tutorial-poll-2.svg`,
  imageGoalTutorialPoll3: `${PREFIX}/images/goal-tutorial/goal-tutorial-poll-3.svg`,
  imageIntegrationsJiraMetrics: `${PREFIX}/images/integrations/jira-metrics.svg`,
  imagePlanWaveBackground: `${PREFIX}/images/plan-wave-background.svg`,
  imagePoweredByStripe: `${PREFIX}/images/integrations/powered-by-stripe.svg`,
  logoFull: `${PREFIX}/logo/logo-purple.svg`,
  logoIcon: `${PREFIX}/logo/logo-icon.svg`,
  placeholderCodeActivity: `${PREFIX}/images/integrations/code-activity-sample.svg`,
  placeholderFlowMetrics: `${PREFIX}/images/integrations/flow-metrics-sample.svg`,
  placeholderFullFlowMetrics: `${PREFIX}/images/integrations/full-flow-metrics-sample.svg`,
  placeholderFullSourceControlMetrics: `${PREFIX}/images/integrations/full-source-control-metrics-sample.svg`,
  placeholderSourceControlMetrics: `${PREFIX}/images/integrations/source-control-metrics-sample.svg`,
  placeholderWorkItemAgeTable: `${PREFIX}/images/integrations/work-item-age-table-sample.svg`,
  placeholderWorkItemHeatmap: `${PREFIX}/images/integrations/work-item-heatmap-sample.svg`,
};
