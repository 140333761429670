import { FC, memo } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import {
  Menu,
  MenuButton,
  HStack,
  Icon,
  MenuList,
  MenuItem,
  callIfExists,
  Divider,
  Switch,
  Text,
} from '@spoke/common';
import { BoardDiscussionCardsSortingKey } from '@spoke/graphql';

const SORT_KEY_DISPLAY_NAME: Record<BoardDiscussionCardsSortingKey, string> = {
  [BoardDiscussionCardsSortingKey.Comments]: 'Comments',
  [BoardDiscussionCardsSortingKey.DateAsc]: 'Date',
  [BoardDiscussionCardsSortingKey.Votes]: 'Votes',
};

type DiscussBoardListMenuProps = {
  onSortKeyChange:
    | ((key: BoardDiscussionCardsSortingKey, sortByTags?: boolean) => void)
    | null;
  sortKey: BoardDiscussionCardsSortingKey;
  sortByTags: boolean;
};
export const DiscussBoardListMenu: FC<DiscussBoardListMenuProps> = ({
  onSortKeyChange,
  sortKey,
  sortByTags,
}) => (
  <Menu isOpen={!onSortKeyChange ? false : undefined}>
    <MenuButton>
      <HStack>
        {sortKey && (
          <Text fontSize={14} fontWeight={500} color="gray.500">
            {SORT_KEY_DISPLAY_NAME[sortKey]}
          </Text>
        )}
        {onSortKeyChange && (
          <Icon pb="1px" as={FiChevronDown} color="gray.500" />
        )}
      </HStack>
    </MenuButton>
    <MenuList>
      <MenuItem
        onClick={() =>
          callIfExists(onSortKeyChange, BoardDiscussionCardsSortingKey.Votes)
        }
      >
        Highest number of votes
      </MenuItem>
      <MenuItem
        onClick={() =>
          callIfExists(onSortKeyChange, BoardDiscussionCardsSortingKey.Comments)
        }
      >
        Highest number of comments
      </MenuItem>
      <MenuItem
        onClick={() =>
          callIfExists(onSortKeyChange, BoardDiscussionCardsSortingKey.DateAsc)
        }
      >
        Oldest to newest
      </MenuItem>
      <Divider mt={1} my={2} />
      <HStack
        onClick={() => callIfExists(onSortKeyChange, sortKey, !sortByTags)}
        cursor="pointer"
        w="full"
        p={2}
        transition="background 0.2s ease-out"
        _hover={{
          bg: 'gray.100',
        }}
      >
        <Switch pointerEvents="none" isChecked={sortByTags} />
        <Text
          fontSize={14}
          color="gray.600"
          fontWeight={500}
          fontFamily={`'proxima-nova-md'`}
          pt="2px"
        >
          Also sort by tags
        </Text>
      </HStack>
    </MenuList>
  </Menu>
);

export const MemoizedDiscussBoardListMenu = memo(DiscussBoardListMenu);
