import { useActionItemByIdQuery } from '@spoke/graphql';

type QueryData = NonNullable<
  NonNullable<ReturnType<typeof useActionItemByIdQuery>>['data']
>['actionItem'];

export const useActionItemById = (id: string): [QueryData | null] => {
  const actionItemQuery = useActionItemByIdQuery({ variables: { id } });
  return [actionItemQuery.data?.actionItem ?? null];
};
