import { NextPage } from 'next';
import { useState, useEffect, useLayoutEffect } from 'react';
import {
  useBoardPasswordPassed,
  BoardPassword,
  boardPasswordPassedVar,
} from '@spoke/board';
import {
  useCurrentUser,
  useCurrentBoardId,
  useCurrentBoard,
  isServer,
  hardRedirect,
  log,
  useRouter,
  useRoutes,
} from '@spoke/common';
import { useIsUserAuthorizedForBoardLazyQuery } from '@spoke/graphql';

export const BoardGuard: NextPage = ({ children }) => {
  const router = useRouter();
  const routes = useRoutes();
  const shouldApply = router.route === '/board/[boardId]';

  const [currentUser, { loading: userLoading }] = useCurrentUser();

  const [boardId] = useCurrentBoardId();
  const [board] = useCurrentBoard({ initializeSubscription: false });
  const passwordPassed = useBoardPasswordPassed();

  const [guardPassed, setGuardPassed] = useState(false);

  const [loadIsUserAuthorizedForBoardQuery] =
    useIsUserAuthorizedForBoardLazyQuery();

  const userId = currentUser?.id;
  const isLoggedIn = Boolean(userId);

  (isServer() ? useEffect : useLayoutEffect)(() => {
    (async () => {
      if (!shouldApply || userLoading || !boardId) {
        return;
      }

      const isAuthorized = await loadIsUserAuthorizedForBoardQuery({
        variables: { boardId },
        fetchPolicy: 'network-only',
      }).then((query) => query.data?.isUserAuthorizedForBoard);

      if (!isLoggedIn || !isAuthorized) {
        const redirectTo = routes.InvitationBoard(boardId);
        log.info(`BoardGuard redirecting to ${redirectTo}`, {
          redirectFrom: router.asPath,
          redirectTo,
          boardId,
          isLoggedIn,
          isAuthorizedForBoard: isAuthorized,
        });
        if (!isServer()) router.replace(redirectTo);
        return;
      }

      setGuardPassed(true);
    })();
  }, [currentUser, isLoggedIn, userLoading, router, shouldApply, userId]);

  (isServer() ? useEffect : useLayoutEffect)(() => {
    if (!shouldApply || !boardId || !board) {
      return;
    }

    if (board.type === 'CANVAS') {
      hardRedirect(routes.Whiteboard(boardId));
    }
  }, [board, boardId, shouldApply]);

  const passwordBlocked =
    board?.state.password &&
    currentUser?.id &&
    !passwordPassed &&
    !board?.facilitators?.includes(currentUser?.id);

  if (passwordBlocked) {
    return <BoardPassword onSuccess={() => boardPasswordPassedVar(true)} />;
  }

  if (shouldApply && !guardPassed) return null;
  else return <>{children}</>;
};
