import { useCallback } from 'react';
import { useSearchTeamMembersLazyQuery } from '@spoke/graphql';
import { log } from '@spoke/common';

type QueryRef = ReturnType<typeof useSearchTeamMembersLazyQuery>[1];
type QueryData = NonNullable<QueryRef['data']>['searchTeamMembers'];
type FetchArgs = { term: string; teamId: string };

export const useFetchTeamMembers = (): [
  (args: FetchArgs) => Promise<QueryData>,
  QueryRef
] => {
  const [searchTeamMembers, queryRef] = useSearchTeamMembersLazyQuery();

  const fetchTeamMembers = useCallback(
    async ({ term, teamId }: FetchArgs) => {
      const { error, data } = await searchTeamMembers({
        variables: { teamId, term },
      });

      const members = data?.searchTeamMembers;

      if (error || !members) {
        log.error('searchTeamMembers query responded with errors', {
          teamId,
          term,
          error,
          data,
        });
        return [];
      }

      return members;
    },
    [searchTeamMembers]
  );

  return [fetchTeamMembers, queryRef];
};
