import { ApolloCache } from '@apollo/client';
import {
  Card,
  CardFragmentFragment,
  CardFragmentFragmentDoc,
} from '@spoke/graphql';

type GetCardFromCacheArgs = {
  cardId: string;
  cache: ApolloCache<object>;
};
export function getCardFromCache({
  cardId,
  cache,
}: GetCardFromCacheArgs): Card | null {
  return (
    (cache.readFragment({
      fragment: CardFragmentFragmentDoc,
      fragmentName: 'CardFragment',
      id: `Card:${cardId}`,
    }) as CardFragmentFragment as Card) || null
  );
}
