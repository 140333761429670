import { FC, useEffect } from 'react';
import { useMarkTeamInsightAsSeenHandler } from '../handlers';
import { TeamInsightFactCauseSolution } from './FactCauseSolution/TeamInsightFactCauseSolution';
import { TeamInsightsFragmentFragment as TeamInsightsType } from '@spoke/graphql';

type Props = {
  insight?: TeamInsightsType['insights'][0] | null;
  skipAnimations?: boolean;
};
export const TeamInsight: FC<Props> = ({ insight, skipAnimations }) => {
  const [markAsSeen] = useMarkTeamInsightAsSeenHandler();

  useEffect(() => {
    if (insight?.seen !== false) return;
    markAsSeen({ teamInsightId: insight.id });
  }, [insight?.id, insight?.seen, markAsSeen]);

  if (insight?.__typename === 'TeamInsightFactCauseSolution') {
    return (
      <TeamInsightFactCauseSolution
        skipAnimations={skipAnimations}
        insight={insight}
      />
    );
  }

  return null;
};
