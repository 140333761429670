import { FC, useMemo, useEffect, memo } from 'react';
import { BiLinkAlt, BiUserCircle } from 'react-icons/bi';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { BsThreeDots } from 'react-icons/bs';
import { FaChevronLeft } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { GiSettingsKnobs } from 'react-icons/gi';
import { MdOutlineFileDownload, MdOutlineBookmarkBorder } from 'react-icons/md';
import { ParkingLotNavbarItem } from '@spoke/parking-lot';
import { useLivePolls } from '@spoke/live-polls';
import { BoardStage } from '@spoke/graphql';
import {
  useCurrentBoard,
  isLivePollRunning,
  Button,
  useCurrentUser,
  useCurrentBoardId,
  useRouteState,
  useDisclosure,
  useSpringState,
  HStack,
  NAV_BAR_HEIGHT,
  Flex,
  Skeleton,
  Icon,
  SpkLink,
  Spinner,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  Divider,
  MenuItem,
  hardRedirect,
  Box,
  Link,
  Tooltip,
  Text,
  Image,
  useRouter,
  useAssets,
  useRoutes,
} from '@spoke/common';
import {
  useChangeBoardStageHandler,
  useCurrentBoardRules,
  BoardShareModal,
  ExportBoardModal,
} from '@spoke/board';
import { useActionItems, ActionItemsModal } from '@spoke/action-item';

// using useCurrentBoard in separated components
// to not cause a full navbar render every card change
const BoardName = () => {
  const [board] = useCurrentBoard();
  return <>{board?.name}</>;
};

const StartRetroButton = () => {
  const [handleChangeBoardStage] = useChangeBoardStageHandler();
  const { currentStage, isFacilitator } = useCurrentBoardRules();
  const [polls] = useLivePolls();

  const runningPolls = polls.filter(isLivePollRunning);
  const hasPollsRunning = runningPolls.length > 0;
  const isFactsStatsPhase = currentStage === BoardStage.FactsStats;

  return (
    <>
      {Boolean(isFactsStatsPhase && isFacilitator) && (
        <Tooltip
          isDisabled={!hasPollsRunning}
          variant="white"
          placement="left"
          hasArrow
          label="End the poll before starting your retro."
        >
          <Box tabIndex={-1}>
            <Button
              isDisabled={hasPollsRunning}
              onClick={() => handleChangeBoardStage(BoardStage.Reflect)}
            >
              Start retrospective
            </Button>
          </Box>
        </Tooltip>
      )}
    </>
  );
};

type Props = {
  isLoading: boolean;
};

export const BoardNav: FC<Props> = ({ isLoading }) => {
  const router = useRouter();
  const routes = useRoutes();
  const [currentUser] = useCurrentUser();
  const [actionItems, actionItemsQuery] = useActionItems(
    {},
    { pollIfNeeded: true }
  );
  const [boardId] = useCurrentBoardId();

  const [shareQueryParam, setShareQueryParam] = useRouteState<string>(
    '',
    'share'
  );

  const { logoIcon } = useAssets();

  const shareModal = useDisclosure();
  const exportModal = useDisclosure();
  const actionItemModal = useDisclosure();

  const [teamNotified, setTeamNotified] = useSpringState(false, 60_000);

  const teamId = useMemo(() => currentUser?.team?.id, [currentUser]);

  useEffect(() => {
    if (isLoading) return;
    if (shareQueryParam !== '1') return;
    shareModal.open();
    setShareQueryParam('');
  }, [shareQueryParam, shareModal, isLoading, setShareQueryParam]);

  const actionItemCount = actionItems.unresolved.length || null;

  const loading =
    !currentUser || !teamId || !boardId || actionItemsQuery.loading;

  return (
    <HStack
      w="full"
      h={NAV_BAR_HEIGHT}
      px={6}
      bg="white"
      justifyContent="space-between"
      borderBottomWidth={1}
      borderColor="gray.100"
      zIndex={2}
    >
      <Flex alignItems="center">
        <Skeleton
          isLoaded={!loading}
          startColor="transparent"
          endColor="transparent"
          display="flex"
          alignItems="center"
        >
          <Icon
            color="primary.500"
            as={FaChevronLeft}
            h={5}
            w={5}
            ml={2}
            cursor="pointer"
            onClick={() => router.push(routes.Dashboard)}
          />

          <SpkLink href={routes.Dashboard} passHref>
            <Link>
              <Image src={logoIcon} alt="ScatterSpoke Logo" w={10} />
            </Link>
          </SpkLink>
          <Text
            ml={3}
            color="gray.900"
            fontFamily="museo-sans-rd-700"
            fontSize={16}
          >
            <BoardName />
          </Text>
        </Skeleton>
      </Flex>
      <HStack>
        <Skeleton
          isLoaded={!loading}
          startColor="transparent"
          endColor="transparent"
        >
          <HStack>
            <Button
              onClick={shareModal.toggle}
              leftIcon={<Icon as={BiLinkAlt} />}
            >
              Share
            </Button>
            <Button
              variant="outlineGray"
              leftIcon={<Icon as={MdOutlineFileDownload} />}
              onClick={exportModal.toggle}
            >
              Export
            </Button>
          </HStack>
        </Skeleton>

        <Button
          variant="outlineGray"
          onClick={actionItemModal.toggle}
          leftIcon={<MdOutlineBookmarkBorder size={20} />}
          rightIcon={
            loading ? (
              <Spinner w={4} h={4} />
            ) : (
              <Badge colorScheme="gray" mb="-2px">
                {actionItemCount || 0}
              </Badge>
            )
          }
        >
          Action items
        </Button>
        <Menu>
          <MenuButton as="div" style={{ cursor: 'pointer' }}>
            <Button px={0} variant="outlineGray">
              <Icon as={BsThreeDots} />
            </Button>
          </MenuButton>
          <MenuList>
            <ParkingLotNavbarItem />
            <Divider my={1} />
            <MenuItem onClick={() => hardRedirect(routes.OrgSettings)}>
              <Icon color="gray.500" as={GiSettingsKnobs} mr={2} w={4} h={4} />
              <Text fontWeight={500}>Organization Settings</Text>
            </MenuItem>
            <MenuItem onClick={() => router.push(routes.UserSettings)}>
              <Icon color="gray.500" as={BiUserCircle} mr={2} w={4} h={4} />
              <Text fontWeight={500}>User Settings</Text>
            </MenuItem>
            <Divider my={1} />
            <MenuItem
              onClick={() =>
                (window.location.href = 'mailto:support@scatterspoke.com')
              }
            >
              <Icon as={MdOutlineSupportAgent} mr={2} w={4} h={4} />
              <Text fontWeight={500}>Support</Text>
            </MenuItem>
            <MenuItem onClick={() => router.push(routes.Logout)}>
              <Icon color="gray.500" as={FiLogOut} mr={2} w={4} h={4} />
              <Text fontWeight={500}>Logout</Text>
            </MenuItem>
          </MenuList>
        </Menu>
        <StartRetroButton />
      </HStack>
      <BoardShareModal
        isOpen={shareModal.isOpen}
        onClose={shareModal.close}
        teamNotified={teamNotified}
        setTeamNotified={setTeamNotified}
      />
      <ExportBoardModal
        isOpen={exportModal.isOpen}
        onClose={exportModal.close}
      />
      <ActionItemsModal
        isOpen={actionItemModal.isOpen}
        onClose={actionItemModal.close}
      />
    </HStack>
  );
};

export const MemoizedBoardNav = memo(BoardNav);
