import { FetchResult } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { log } from '../../../../SpkLog';
import { generateTempId } from '../../generateTempId';
import {
  Board,
  BoardByIdQuery,
  CreateListMutation,
  List,
} from '@spoke/graphql';

type OptimisticCreateListArgs = {
  board: Board;
  newListName: string;
};
export const optimisticCreateList = ({
  board: _board,
  newListName,
}: OptimisticCreateListArgs): CreateListMutation => {
  const board = cloneDeep(_board);

  const newList: Required<List> = {
    __typename: 'List',
    id: generateTempId(),
    ownerId: generateTempId(),
    teamId: board.teamId,
    boardId: board.id,
    name: newListName,
    index: board?.lists?.length || 0,
    archived: false,
    cardColor: '#fff',
    cards: [],
    backgroundColor: '#fff',
    description: '',
  };

  board.lists?.push(newList);

  return {
    __typename: 'Mutation',
    createList: newList,
  };
};

type CreateListUpdateFnForBoardById = (
  prevData: BoardByIdQuery,
  incomingData: {
    mutationResult: FetchResult<
      CreateListMutation,
      Record<string, any>,
      Record<string, any>
    >;
  }
) => BoardByIdQuery;

type GetCreateListUpdateFunctionArgs = {
  optimisticList: List;
};
export const getCreateListUpdateFunctionForBoardById =
  ({
    optimisticList,
  }: GetCreateListUpdateFunctionArgs): CreateListUpdateFnForBoardById =>
  (prev, { mutationResult }) => {
    const board = prev?.board;

    if (!board) {
      log.error('Missing board in create list mutation update function', {
        prev,
        board,
      });
      return prev;
    }

    const newLists = [...(board?.lists || [])] as List[];

    const optimisticListIdx = newLists.findIndex(
      (l) => l.id === optimisticList.id
    );

    const actualListIdx = newLists.findIndex(
      (l) => l.id === mutationResult?.data?.createList?.id
    );

    if (optimisticListIdx > 0) newLists.splice(optimisticListIdx, 1);
    if (actualListIdx > 0) newLists.splice(actualListIdx, 1);

    const newList = mutationResult.data?.createList as List;

    if (!newList) {
      log.error('Missing list in createList mutation update function', {
        prev,
        board,
        mutationResult,
        newList,
      });
      return prev;
    }

    newLists.push(newList);

    return {
      __typename: 'Query',
      board: {
        ...(board || {}),
        lists: newLists,
      },
    };
  };
