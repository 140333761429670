import {
  ComponentStyleConfig,
  Divider as ChakraDivider,
  DividerProps as ChakraDividerProps,
  forwardRef,
} from '@chakra-ui/react';

export const DividerComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    borderColor: 'gray.200',
    opacity: 1,
  },
};

type Props = ChakraDividerProps;

export const Divider = forwardRef<Props, 'hr'>((props, ref) => (
  <ChakraDivider ref={ref} {...props} />
));
