import { useRouter } from '../../../hooks/etc';

/**
 * Performs better than useCurrentBoard for when we only need its id
 */
export const useCurrentBoardId = (): [string | null] => {
  const router = useRouter();

  const { boardId } = router.query;

  // This shouldn't be necessary but for some reason Next router is not splitting the query string
  return [
    typeof boardId === 'string' && boardId?.split('?')[0]
      ? boardId?.split('?')[0]
      : null,
  ];
};
