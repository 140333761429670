import { FC, ReactElement, useCallback, useState } from 'react';
import { AuthEmailPassword } from './EmailPassword';
import { AuthRequestResetPassowrd } from './RequestResetPassword';
import { AuthMethodSelector } from './MethodSelector';
import { AuthSso } from './Sso';
import { AuthConfirmResetPassword } from './ConfirmResetPassword';
import { AuthCreateNewPassword } from './CreateNewPassword';
import { AuthRegister } from './Register';
import { AuthVerifyEmail } from './VerifyEmail';
import { AuthChangeAccountEmail } from './ChangeAccountEmail';
import { useCurrentUserQuery, User } from '@spoke/graphql';
import {
  OAuthProvider,
  useRouteState,
  OAUTH_URLS_BY_PROVIDER,
  clientSideRedirect,
  log,
  SpokeAuthView,
  useRouter,
  useRoutes,
} from '@spoke/common';

type SpokeAuthProps = {
  onSuccess: (user: User) => void;
  view: SpokeAuthView;
  greeting?: string;
  onViewChange: (newView: SpokeAuthView) => void;
  preferredLoginOrRegisterView?: 'login' | 'register';
  oAuthRedirectOverride?: string;
};

const {
  ConfirmResetPassword,
  CreateNewPassword,
  EmailPassword,
  MethodSelector,
  ResetPassword,
  Sso,
  Register,
  VerifyEmail,
  ChangeAccountEmail,
} = SpokeAuthView;

const { Atlassian, Google, Microsoft } = OAuthProvider;

export const SpokeAuth: FC<SpokeAuthProps> = ({
  onSuccess,
  onViewChange,
  greeting,
  view = EmailPassword,
  preferredLoginOrRegisterView = 'login',
  oAuthRedirectOverride,
}) => {
  const router = useRouter();
  const routes = useRoutes();
  const [error] = useRouteState<string>('', 'error');

  // This is to get context from board invites
  const [boardId] = useRouteState(null, 'boardId');
  const [inviteToken] = useRouteState(null, 'invitation');
  const [teamByInviteToken] = useRouteState(null, 'teamInviteToken');
  const [inviter] = useRouteState(null, 'inviter');

  const currentUserQuery = useCurrentUserQuery({
    // This prevents user from getting stuck in "User already logged in" error
    // in auth screens in case the frontend and backend differ in state
    // whether user is logged in or not
    fetchPolicy: 'cache-and-network',
  });

  const currentUser = currentUserQuery.data?.user ?? null;

  const handleOAuthClick = useCallback(
    (provider: OAuthProvider) => {
      log.info('OAuth provider clicked', { provider });
      const oAuthState = JSON.stringify({
        boardId,
        inviteToken: teamByInviteToken ?? inviteToken,
        inviterId: inviter,
        redirectTo: oAuthRedirectOverride || '',
      });

      const oAuthProviderUrl = new URL(OAUTH_URLS_BY_PROVIDER[provider]);
      oAuthProviderUrl.searchParams.append('state', oAuthState);
      clientSideRedirect(oAuthProviderUrl.toString());
    },
    [boardId, inviteToken, inviter, teamByInviteToken, oAuthRedirectOverride]
  );

  const handleRegisterSuccess = (user: User) => {
    currentUserQuery.updateQuery(() => ({ user }));
    onViewChange(VerifyEmail);
  };

  const [emailBeingReset, setEmailBeingReset] = useState('');

  const handlePasswordResetSent = (email: string) => {
    setEmailBeingReset(email);
    onViewChange(ConfirmResetPassword);
  };

  const [resetPasswordCode, setResetPasswordCode] = useState('');

  const handleResetPasswordCodeSuccess = (code: string) => {
    setResetPasswordCode(code);
    onViewChange(CreateNewPassword);
  };

  const handleConfirmEmailSuccess = () => {
    const user = currentUser as User;
    currentUserQuery.updateQuery(() => ({
      user: { ...user, verified: true },
    }));
  };

  const handleInvalidView = () => {
    onViewChange(MethodSelector);
    return null;
  };

  const handleLogout = () => {
    router.push(routes.Logout);
  };

  const componentByView: Record<SpokeAuthView, ReactElement> = {
    [MethodSelector]: (
      <AuthMethodSelector
        error={error}
        greeting={greeting}
        onContinueWithGoogle={() => handleOAuthClick(Google)}
        onContinueWithMicrosoft={() => handleOAuthClick(Microsoft)}
        onContinueWithAtlassian={() => handleOAuthClick(Atlassian)}
        onContinueWithSso={() => onViewChange(Sso)}
        onContinueWithEmailPassword={() =>
          onViewChange(
            preferredLoginOrRegisterView === 'login' ? EmailPassword : Register
          )
        }
      />
    ),
    [EmailPassword]: (
      <AuthEmailPassword
        onSuccess={onSuccess}
        greeting={greeting}
        onRegister={() => onViewChange(Register)}
        onResetPassword={() => onViewChange(ResetPassword)}
        onSelectMethod={() => onViewChange(MethodSelector)}
      />
    ),
    [Sso]: <AuthSso onSelectMethod={() => onViewChange(MethodSelector)} />,
    [ResetPassword]: (
      <AuthRequestResetPassowrd
        onSuccess={(email) => handlePasswordResetSent(email)}
        onContinueWithEmailPassword={() => onViewChange(EmailPassword)}
      />
    ),
    [ConfirmResetPassword]: (
      <AuthConfirmResetPassword
        onContinueWithEmailPassword={() => onViewChange(EmailPassword)}
        onSuccess={(code) => handleResetPasswordCodeSuccess(code)}
        email={emailBeingReset || currentUser?.email || ''}
      />
    ),
    [CreateNewPassword]: (
      <AuthCreateNewPassword
        onSuccess={() => onViewChange(EmailPassword)}
        code={resetPasswordCode}
      />
    ),
    [Register]: (
      <AuthRegister
        greeting={greeting}
        onContinueWithEmailPassword={() => onViewChange(EmailPassword)}
        onSuccess={(user) => handleRegisterSuccess(user)}
        onSsoUserDetected={() => onViewChange(Sso)}
        onSelectMethod={
          preferredLoginOrRegisterView === 'register'
            ? () => onViewChange(MethodSelector)
            : undefined
        }
      />
    ),
    [VerifyEmail]: (
      <AuthVerifyEmail
        onChangeAccountEmail={() => onViewChange(ChangeAccountEmail)}
        email={currentUser?.email as string}
        onSuccess={() => handleConfirmEmailSuccess()}
        onLogout={() => handleLogout()}
      />
    ),
    [ChangeAccountEmail]: (
      <AuthChangeAccountEmail
        currentEmail={currentUser?.email as string}
        onSuccess={(newUser) => handleRegisterSuccess(newUser)}
        onCancel={() => onViewChange(VerifyEmail)}
      />
    ),
  };

  return view in componentByView ? componentByView[view] : handleInvalidView();
};
