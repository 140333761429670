import { FC } from 'react';
import {
  ColorBadge,
  Flex,
  MenuItem,
  Text,
  useCurrentBoard,
} from '@spoke/common';

type OriginalListSelectorProps = {
  onSelect: (listId: string) => void;
};
export const OriginalListSelector: FC<OriginalListSelectorProps> = ({
  onSelect,
}) => {
  const [board] = useCurrentBoard();

  const lists = board?.lists;

  return (
    <Flex flexDir="column" maxW={200}>
      <Text fontSize={14} pl={1} pr={6} mb={1}>
        Choose one of the available column tags:
      </Text>
      {lists?.map((list) =>
        list ? (
          <MenuItem
            alignItems="center"
            key={list.id}
            onClick={() => onSelect(list.id)}
          >
            <ColorBadge color={list.cardColor ?? ''} mr={2} />
            <Text fontSize={14} color="gray.700">
              {list.name}
            </Text>
          </MenuItem>
        ) : null
      )}
    </Flex>
  );
};
