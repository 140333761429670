import { makeVar } from '@apollo/client';
import { useEffect } from 'react';
import {
  QueryConfig,
  TEN_SECONDS_MS,
  useCurrentUser,
  useNetworkContext,
  useInstanceCount,
  deepMerge,
  filterArchived,
  uniqueBy,
  sortByCreatedAt,
  AscDesc,
  log,
} from '@spoke/common';
import {
  useParkingLotByTeamIdAndTypeLazyQuery,
  ParkingLotCardUpdatedSubscription,
  ParkingLotCardUpdatedDocument,
  ParkingLotCardCreatedSubscription,
  ParkingLotCardCreatedDocument,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type Config = QueryConfig<typeof useParkingLotByTeamIdAndTypeLazyQuery>;
type QueryRef = ReturnType<typeof useParkingLotByTeamIdAndTypeLazyQuery>[1];
type QueryData = NonNullable<QueryRef['data']>['parkingLotByTeamId'];

const PARKING_LOT_POLLING_INTERVAL_MS = TEN_SECONDS_MS;

// This state persists app-wide, not component-specific. So we don't create unecessary susbcriptions
const subscribedToChangesVar = makeVar<boolean>(false);
const unsubscriptionsVar = makeVar<(() => void)[]>([]);
const isPollingVar = makeVar<boolean>(false);

type UseParkingLotHookConfig = {
  pollIfNeeded?: boolean;
};

const DEFAULT_HOOK_CONFIG: UseParkingLotHookConfig = {
  pollIfNeeded: false,
};

export const useParkingLot = (
  queryConfig?: Config,
  hookConfig: UseParkingLotHookConfig = DEFAULT_HOOK_CONFIG
): [QueryData | null, QueryRef] => {
  const { pollIfNeeded } = hookConfig;
  const [currentUser] = useCurrentUser();
  const [currentTeam] = useCurrentTeam();
  const teamId = currentTeam?.id || '';
  const userId = currentUser?.id || '';

  const { shouldPoll } = useNetworkContext();
  const [getInstanceCount] = useInstanceCount('useParkingLot');

  const baseConfig: Config = {
    variables: {
      teamId,
    },
  };

  const finalConfig = (
    queryConfig ? deepMerge(baseConfig, queryConfig) : baseConfig
  ) as Config;

  const [loadParkingLot, parkingLotQuery] =
    useParkingLotByTeamIdAndTypeLazyQuery(finalConfig);

  useEffect(() => {
    if (!teamId || parkingLotQuery.called) return;
    loadParkingLot();
  }, [loadParkingLot, parkingLotQuery.called, teamId]);

  // This effect must be called before the next one so we don't create
  // a race condition on cleaning/creating subscriptions
  useEffect(() => {
    if (!subscribedToChangesVar()) return;
    log.info('User team changed. Cleaning up Parking Lot subscriptions');
    for (const unsubscribe of unsubscriptionsVar()) unsubscribe();
    unsubscriptionsVar([]);
    subscribedToChangesVar(false);
  }, [teamId]);

  useEffect(() => {
    const shouldSubscribe = Boolean(
      !subscribedToChangesVar() &&
        !shouldPoll &&
        parkingLotQuery.called &&
        finalConfig?.variables?.teamId
    );

    if (!shouldSubscribe) return;

    log.info('Starting Parking Lot subscriptions');

    subscribedToChangesVar(true);

    const unsubscribeToUpdated =
      parkingLotQuery.subscribeToMore<ParkingLotCardUpdatedSubscription>({
        document: ParkingLotCardUpdatedDocument,
        variables: {
          teamId: finalConfig.variables?.teamId || '',
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const updatedParkingLotItem =
            subscriptionData.data.parkingLotCardUpdated;

          const mergedParkingLotItems = [
            updatedParkingLotItem,
            ...(prev?.parkingLotByTeamId || []),
          ]
            .filter(Boolean)
            .filter(filterArchived) as Array<
            NonNullable<NonNullable<typeof updatedParkingLotItem>>
          >;

          const uniqueMergedParkingLotItems = uniqueBy(
            mergedParkingLotItems,
            'id'
          );

          return {
            __typename: 'Query',
            parkingLotByTeamId: uniqueMergedParkingLotItems,
          };
        },
      });

    const unsubscribeToCreated =
      parkingLotQuery.subscribeToMore<ParkingLotCardCreatedSubscription>({
        document: ParkingLotCardCreatedDocument,
        variables: {
          teamId: finalConfig.variables?.teamId || '',
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newParkingLotItem = subscriptionData.data.parkingLotCardCreated;

          const mergedParkingLotItems = [
            newParkingLotItem,
            ...(prev?.parkingLotByTeamId || []),
          ]
            .filter(Boolean)
            .filter(filterArchived) as Array<
            NonNullable<NonNullable<typeof newParkingLotItem>>
          >;

          const mergedUniqueParkingLotItems = uniqueBy(
            mergedParkingLotItems,
            'id'
          );

          return {
            __typename: 'Query',
            parkingLotByTeamId: mergedUniqueParkingLotItems,
          };
        },
      });

    unsubscriptionsVar([unsubscribeToCreated, unsubscribeToUpdated]);
  }, [finalConfig.variables?.teamId, parkingLotQuery, shouldPoll]);

  useEffect(() => {
    if (!userId || (!shouldPoll && isPollingVar())) {
      log.info('Stopping Parking Lot polling');
      parkingLotQuery.stopPolling();
      isPollingVar(false);
      return;
    }

    if (shouldPoll && !isPollingVar() && pollIfNeeded) {
      log.info('Starting Parking Lot polling');
      parkingLotQuery.startPolling(PARKING_LOT_POLLING_INTERVAL_MS);
      isPollingVar(true);
      return;
    }
  }, [shouldPoll, parkingLotQuery, userId, pollIfNeeded]);

  const parkingLot =
    parkingLotQuery.data?.parkingLotByTeamId
      .filter(filterArchived)
      .sort(sortByCreatedAt(AscDesc.Desc)) ??
    parkingLotQuery.previousData?.parkingLotByTeamId
      .filter(filterArchived)
      .sort(sortByCreatedAt(AscDesc.Desc)) ??
    null;

  useEffect(
    () => () => {
      // This allows us to only unsubscribe when all useParkingLot hooks are unmounted
      const isLastInstance = getInstanceCount() === 0;
      if (!isLastInstance) return;
      log.info('Stopping Parking Lot subscriptions');
      for (const unsubscribe of unsubscriptionsVar()) unsubscribe();
      subscribedToChangesVar(false);
      if (!isPollingVar()) return;
      log.info('Stopping Parking Lot polling');
      parkingLotQuery.stopPolling();
      isPollingVar(false);
    },
    [getInstanceCount, parkingLotQuery]
  );

  return [parkingLot, parkingLotQuery];
};
