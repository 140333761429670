import { ChangeEvent, FC, useMemo, useState } from 'react';
import {
  ModalProps,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  BoardInfoCard,
  Divider,
  Select,
  HStack,
  Button,
  Modal,
  ModalOverlay,
  Text,
  Option,
} from '@spoke/common';
import { Board } from '@spoke/graphql';
import { useTeamTree, useCurrentTeam } from '@spoke/user';

type SubmitProps = {
  boardId: string;
  teamId: string;
};

type MoveBoardModalProps = Omit<ModalProps, 'children'> & {
  onClose: () => unknown;
  onSubmit: ({ boardId, teamId }: SubmitProps) => unknown;
  board?: Board | null;
};

export const MoveBoardModalContent: FC<MoveBoardModalProps> = ({
  onClose,
  onSubmit,
  board,
}) => {
  const [{ teamTree }] = useTeamTree();
  const [currentTeam] = useCurrentTeam();
  const [selectedTeamId, setSelectedTeamId] = useState<string>();

  const [isLoading, setIsLoading] = useState(false);

  const teams = useMemo(
    () => teamTree?.filter(({ team }) => team.id !== currentTeam?.id),
    [currentTeam?.id, teamTree]
  );

  const onFormSubmit = async () => {
    setIsLoading(true);
    await onSubmit({
      boardId: board?.id as string,
      teamId: selectedTeamId as string,
    });
    setIsLoading(false);
  };

  const onSelectTeam = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTeamId(e.target.value);
  };

  return (
    <ModalContent maxW={600} p={8}>
      <ModalHeader fontSize={24} p={0}>
        <Text fontWeight={500} fontSize={20} color="gray.900">
          Move retro to another team
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody p={0}>
        <Flex flexDir="column" gap={2} alignItems="start" w="full">
          <Text mb={2} color="gray.600">
            You must be a member of the destination team to move the
            retrospective.
          </Text>
          <Text fontSize={15} color="gray.900" fontWeight={500} m={0}>
            You are moving
          </Text>
          {board && (
            <BoardInfoCard
              iconUrl={board.format.iconImageUrl}
              formatName={board.format.displayName}
              boardName={board.name}
              createdAt={board.createdAt}
              boardType={board.type}
              w="full"
            />
          )}
          <Divider my={4} />
          <Text fontSize={15} color="gray.900" fontWeight={500}>
            Select destination team
          </Text>

          <Select
            placeholder="Select team"
            value={selectedTeamId as string}
            h={10}
            color="gray.600"
            onChange={onSelectTeam}
          >
            {teams?.map(({ team }) => (
              <Option key={`team-option-${team.id}`} value={team.id as string}>
                {team.name}
              </Option>
            ))}
          </Select>

          <HStack justifyContent="flex-end" pt={2} w="full">
            <Button
              size="lg"
              fontSize={16}
              variant="outlineGray"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="lg"
              isLoading={isLoading}
              disabled={!selectedTeamId || isLoading}
              onClick={onFormSubmit}
              fontSize={16}
            >
              Move retrospective
            </Button>
          </HStack>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

export const MoveBoardModal: FC<MoveBoardModalProps> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <MoveBoardModalContent {...props} />
  </Modal>
);
