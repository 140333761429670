import { Center, useToken } from '@chakra-ui/react';
import { LogoSpinner } from './LogoSpinner';

export const FullScreenLoading = () => {
  const [gray50, gray75] = useToken('colors', ['gray.50', 'gray.75']);

  return (
    <Center
      background={`linear-gradient(0deg, ${gray75} 0%, ${gray50} 100%)`}
      flexDir="column"
      minH="100vh"
      gap={5}
    >
      <LogoSpinner />
    </Center>
  );
};
