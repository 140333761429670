import { FC } from 'react';
import { Flex, Button, HStack, Badge, Text } from '@spoke/common';
import { LivePollUserStatus } from '@spoke/graphql';

type LivePollGoalCardAnsweredProps = {
  title: string;
  status: LivePollUserStatus.Answered | LivePollUserStatus.Skipped;
  onUndo: () => void;
  skippedCount: number;
  answeredCount: number;
  teamMemberCount: number;
};
export const LivePollGoalCardAnswered: FC<LivePollGoalCardAnsweredProps> = ({
  title,
  answeredCount,
  onUndo,
  skippedCount,
  teamMemberCount,
  status,
}) => {
  const label =
    status === LivePollUserStatus.Answered
      ? 'Thanks for your answer.'
      : 'You skipped this one.';

  const everyoneHasAnswered = answeredCount + skippedCount >= teamMemberCount;

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      layerStyle="outlineGray"
      gap={3}
      py={6}
    >
      <Text fontSize={15}>{title}</Text>
      <Text fontSize={14} color="gray.600">
        {label}{' '}
        <Button
          fontSize={14}
          textDecor="underline"
          variant="link"
          onClick={onUndo}
        >
          Undo
        </Button>
      </Text>
      <HStack>
        <Badge
          colorScheme="gray"
          color={everyoneHasAnswered ? 'white' : 'gray.500'}
          bg={everyoneHasAnswered ? 'green.600' : 'gray.100'}
          borderRadius="md"
          textTransform="none"
          fontSize={13}
          fontWeight={450}
          px={2}
          py="2px"
        >
          {answeredCount}/{teamMemberCount} answered
        </Badge>
        <Badge
          colorScheme="gray"
          color="gray.500"
          borderRadius="md"
          textTransform="none"
          fontSize={13}
          fontWeight={450}
          px={2}
          py="2px"
        >
          {skippedCount} skipped
        </Badge>
      </HStack>
    </Flex>
  );
};
