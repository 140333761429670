import { useToken } from '@chakra-ui/react';
import { callIfExists } from '@spoke/common';
import { FC, useCallback, useMemo } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

export type DatePickerProps = {
  value?: Date | DateRange | null;
  mode?: 'single' | 'range';
  pastOnly?: boolean;
  onChange?: (newDate: Date | DateRange | null) => void;
};
export const DatePicker: FC<DatePickerProps> = ({
  onChange,
  mode,
  value,
  pastOnly,
}) => {
  const onSelect = useCallback(
    (newValue: Date | undefined) => {
      callIfExists(onChange, newValue ?? null);
    },
    [onChange]
  );

  const [primary400] = useToken('colors', ['primary.400']);

  const css = useMemo(
    () => `
    :root {
      --rdp-cell-size: 40px;
      --rdp-accent-color: ${primary400};
      --rdp-background-color: #e7edff;
      /* Switch to dark colors for dark themes */
      --rdp-accent-color-dark: ${primary400};
      --rdp-background-color-dark: ${primary400};
      /* Outline border for focused elements */
      --rdp-outline: 2px solid var(--rdp-accent-color);
      /* Outline border for focused and selected elements */
      --rdp-outline-selected: 2px solid ${primary400};
    }

    .today {
      color: ${primary400};
      font-weight: 600;
    }
`,
    [primary400]
  );

  return (
    <>
      <style>{css}</style>
      {/*
// @ts-ignore */}
      <DayPicker
        fromYear={2015}
        toYear={2025}
        mode={mode ?? 'single'}
        weekStartsOn={1}
        selected={value ?? undefined}
        onSelect={onSelect as any}
        disabled={pastOnly ? { after: new Date() } : undefined}
        modifiersClassNames={{}}
      />
    </>
  );
};
