export const NAV_BAR_HEIGHT = '80px';

/**
 * LayerStyles do not support sx props
 */
export const SX_HORIZONTAL_SCROLL_ON_HOVER = {
  '&::-webkit-scrollbar': {
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
    borderRadius: '3px',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: 'gray.300',
  },
};
