export const GENERIC_ERROR_MESSAGE =
  'Something went wrong. Please contact support if this issue persists.';

export const INVALID_VERIFICATION_CODE =
  'This verification code is invalid. Please try again with another one.';

export const INVALID_EMAIL_ADDRESS = 'Please enter a valid email address.';

export const INVALID_EMAIL_DOMAIN =
  "Only can invite users belonging to this organization's connected domains.";

export const EMAIL_VERIFICATION_TOKEN_RESENT =
  'We have sent you an email with the new verification token.';
