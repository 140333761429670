import { FC, useState } from 'react';
import { BiLockAlt } from 'react-icons/bi';
import {
  useCurrentBoard,
  Flex,
  Card,
  Heading,
  FormControl,
  InputGroup,
  InputLeftElement,
  Icon,
  FormErrorMessage,
  HStack,
  Button,
  Input,
  Image,
  Text,
  useRouter,
  useAssets,
} from '@spoke/common';

type BoardPasswordProps = {
  onSuccess: () => void;
};
export const BoardPassword: FC<BoardPasswordProps> = ({ onSuccess }) => {
  const router = useRouter();
  const [board] = useCurrentBoard();
  const [passwordInput, setPasswordInput] = useState('');
  const [passwordSubmitted, setPasswordSubmitted] = useState(false);
  const { logoFull, illustrationPerpetrator } = useAssets();

  const handlePasswordSubmit = () => {
    // This is absolutely not secure
    setPasswordSubmitted(true);
    if (passwordInput === board?.state.password) onSuccess();
  };

  return (
    <Flex
      bg="gray.50"
      minH="100vh"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
    >
      <Image src={logoFull} mb={4} w={170} alt="ScatterSpoke Logo" />
      <Card gap={0} p={10} maxW={400}>
        <Image src={illustrationPerpetrator} alt="Protected board" mb={3} />
        <Heading fontSize={20} color="gray.700" m={0}>
          Password required
        </Heading>
        <Text
          mt={1}
          mb={3}
          px={3}
          fontSize={14}
          color="gray.600"
          textAlign="center"
        >
          To join this board you need to enter the password
        </Text>
        <FormControl mb={3} isInvalid={passwordSubmitted}>
          <InputGroup>
            <InputLeftElement pointerEvents="none" mt="4px" ml={1}>
              <Icon as={BiLockAlt} color="gray.500" w="20px" h="20px" />
            </InputLeftElement>
            <Input
              pl={10}
              value={passwordInput}
              minH="45px"
              onChange={(e) => {
                setPasswordInput(e.target.value);
                setPasswordSubmitted(false);
              }}
              autoFocus
              onKeyDown={(e) =>
                e.key === 'Enter' ? handlePasswordSubmit() : null
              }
            />
          </InputGroup>
          <FormErrorMessage>Wrong password</FormErrorMessage>
        </FormControl>
        <HStack w="full">
          <Button
            onClick={() => router.back()}
            flex="1"
            size="lg"
            variant="outlineGray"
          >
            Go back
          </Button>
          <Button
            disabled={!passwordInput}
            flex="1"
            size="lg"
            onClick={handlePasswordSubmit}
          >
            Continue
          </Button>
        </HStack>
      </Card>
    </Flex>
  );
};
