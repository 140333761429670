import {
  ComponentStyleConfig,
  Table as ChakraTable,
  TableProps as ChakraTableProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export const TableComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    table: {
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
    },
    th: {
      p: 0,
    },
  },
};

type Props = ChakraTableProps;

export const Table = forwardRef<HTMLTableElement, Props>((props, ref) => (
  <ChakraTable ref={ref} {...props} />
));
