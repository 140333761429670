import { makeVar, useApolloClient, useReactiveVar } from '@apollo/client';
import { useEffect, useRef } from 'react';
import { log } from '../../SpkLog';

export const appIsInactiveVar = makeVar<boolean>(false);

const INACTIVITY_THRESHOLD_MS = 10000;

export const InactivityDetection = () => {
  const client = useApolloClient();
  const tabFocusTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const onTabBlur = () => {
      tabFocusTimeoutRef.current = setTimeout(() => {
        log.info('App is now inactive');
        appIsInactiveVar(true);
      }, INACTIVITY_THRESHOLD_MS);
    };

    const onTabFocus = () => {
      if (!tabFocusTimeoutRef.current) return;
      clearTimeout(tabFocusTimeoutRef.current);
      if (appIsInactiveVar()) log.info('App is active again');
      appIsInactiveVar(false);
    };

    window.addEventListener('blur', onTabBlur);
    window.addEventListener('focus', onTabFocus);

    return () => {
      window.removeEventListener('blur', onTabBlur);
      window.removeEventListener('focus', onTabFocus);
    };
  }, [client]);

  return null;
};

export const useInactivityDetection = () => {
  const isInactive = useReactiveVar(appIsInactiveVar);
  return [isInactive];
};
