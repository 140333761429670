import NextLink, { LinkProps } from 'next/link';
import { FC } from 'react';
import { useAppContext, useRouter } from '../../hooks';
import { Box } from '../../ui';

export const SpkLink: FC<Omit<LinkProps, 'href'> & { href: string }> = (
  props
) => {
  const { name: appName } = useAppContext();
  const router = useRouter();

  if (appName === 'web') return <NextLink {...props} />;

  return (
    <Box onClick={() => router.push(props.href)} cursor="pointer">
      {props.children}
    </Box>
  );
};
