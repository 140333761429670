import { QualityName } from '@spoke/graphql';

export const COLOR_BY_QUALITY_NAME: Record<QualityName, string> = {
  [QualityName.Good]: 'green.600',
  [QualityName.Poor]: 'negativeRed',
  [QualityName.Regular]: 'neutralYellow',
};

export const QUALITY_DISPLAY_NAME: Record<QualityName, string> = {
  [QualityName.Good]: 'Good',
  [QualityName.Poor]: 'Poor',
  [QualityName.Regular]: 'Regular',
};
