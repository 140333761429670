import {
  ComponentStyleConfig,
  Progress as ChakraProgress,
  ProgressProps as ChakraProgressProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const ProgressComponentStyle: ComponentStyleConfig = {};

type Props = ChakraProgressProps;

export const Progress: FC<Props> = (props) => <ChakraProgress {...props} />;
