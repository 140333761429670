import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import {
  useArchiveTeamMutation,
  ArchiveTeamMutation,
  CurrentUserDocument,
  TeamTreeDocument,
} from '@spoke/graphql';

export const useArchiveTeamHandler = () => {
  const [toast] = useToast();
  const [archiveTeam] = useArchiveTeamMutation();

  const handleArchiveTeam = useCallback(
    async ({
      teamId,
    }: {
      teamId: string;
    }): Promise<ArchiveTeamMutation['archiveTeam'] | null> => {
      if (!teamId) {
        log.error('Missing critical data to archive team', {
          teamId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const { errors, data } = await archiveTeam({
        variables: { teamId },
        refetchQueries: [
          { query: CurrentUserDocument }, // User's current team changes so need to refetch
          { query: TeamTreeDocument },
        ],
        awaitRefetchQueries: true,
      });

      if (errors) {
        log.warn('archiveTeam mutation responded with error', {
          error: errors,
        });
        return null;
      }

      return data?.archiveTeam;
    },
    [archiveTeam, toast]
  );

  return [handleArchiveTeam];
};
