import { TeamInsightQueryData } from '../queries';
import { SpkTime } from '@spoke/common';
import {
  TeamInsightFactCauseSolution,
  TeamInsightFactCauseSolutionFactMetricInadequacy,
  Trend,
  TeamInsightFactCauseSolutionFactMetricDeterioration,
  TeamInsightFactCauseSolutionFactOutlierWorkItemTopic,
  TeamInsightFactCauseSolutionFact,
  TeamInsightFactCauseSolutionFactMetricImprovement,
} from '@spoke/graphql';

/**
 * Returns a string summarizing the insight fact.
 */
export function getTeamInsightSummary(insight: TeamInsightQueryData) {
  const HANDLERS: Record<
    NonNullable<TeamInsightQueryData['__typename']>,
    /**
     * @todo Figure out this typing, goal here is that typescript complains if we
     * forget to add summaries for new goal types
     */
    (insight: any) => string
  > = {
    TeamInsightFactCauseSolution: getFactCauseSolutionSummary,
  };

  const typename = insight.__typename;

  if (!typename || !HANDLERS[typename]) {
    return fallbackSummary(insight.date);
  }

  return HANDLERS[typename](insight);
}

const getFactCauseSolutionSummary = (
  insight: TeamInsightFactCauseSolution
): string => {
  const getMetricInadequacySummary = (
    fact: TeamInsightFactCauseSolutionFactMetricInadequacy
  ) =>
    `${fact.type.name} ${
      fact.type.ideal === Trend.Up ? 'below' : 'above'
    } recommended`;

  const getMetricDeteriorationSummary = (
    fact: TeamInsightFactCauseSolutionFactMetricDeterioration
  ) =>
    `${fact.type.name} trending ${
      fact.type.ideal === Trend.Up ? 'down' : 'up'
    }`;

  const getMetricImprovementSummary = (
    fact: TeamInsightFactCauseSolutionFactMetricImprovement
  ) =>
    `${fact.type.name} trending ${
      fact.type.ideal === Trend.Up ? 'down' : 'up'
    }`;

  const getOutlierWorkItemTopicSummary = (
    fact: TeamInsightFactCauseSolutionFactOutlierWorkItemTopic
  ) => `Work items about "${fact.topic}" taking long`;

  const HANDLERS: Record<
    NonNullable<TeamInsightFactCauseSolutionFact['__typename']>,
    /**
     * @todo Figure out this typing, goal here is that typescript complains if we
     * forget to add summaries for new goal types
     */
    (insight: any) => string
  > = {
    TeamInsightFactCauseSolutionFactMetricInadequacy:
      getMetricInadequacySummary,
    TeamInsightFactCauseSolutionFactMetricDeterioration:
      getMetricDeteriorationSummary,
    TeamInsightFactCauseSolutionFactOutlierWorkItemTopic:
      getOutlierWorkItemTopicSummary,
    // this is a deprecated type. should be removed from graphql api soon
    TeamInsightFactCauseSolutionFactMetricBelowRecommended:
      getMetricInadequacySummary,
    TeamInsightFactCauseSolutionFactMetricImprovement:
      getMetricImprovementSummary,
  };

  const factTypename = insight.fact.__typename ?? '';

  if (!factTypename || !HANDLERS[factTypename]) {
    return fallbackSummary(insight.date);
  }

  return HANDLERS[factTypename](insight.fact);
};

const fallbackSummary = (date: number) =>
  !date ? '' : `${SpkTime.format(date, 'MMM dd')} Insight`;
