import { FiFrown, FiMeh, FiSmile } from 'react-icons/fi';
import { FC } from 'react';
import {
  Flex,
  InfoTooltip,
  HStack,
  IconButton,
  Icon,
  Text,
} from '@spoke/common';
import { QualityName } from '@spoke/graphql';

type Props = {
  onRate: (quality: QualityName) => void;
  rated: boolean;
  loading: QualityName | null;
};
export const TeamInsightRating: FC<Props> = ({ loading, onRate, rated }) => {
  if (rated) {
    return (
      <Text fontSize={14} color="gray.600">
        Thank you for your feedback!
      </Text>
    );
  }

  return (
    <Flex gap={1} alignItems="center">
      <InfoTooltip
        color="gray.700"
        text="Your feedback helps us improve our A.I. powered insights."
      />
      <Text fontSize={14} pt="3px" color="gray.700">
        Rate this insight
      </Text>
      <HStack pb="1px" ml={1}>
        <IconButton
          onClick={() => onRate(QualityName.Poor)}
          isLoading={loading === QualityName.Poor}
          isDisabled={loading !== null}
          aria-label="Poor"
          icon={<Icon w={4} h={4} as={FiFrown} />}
        />
        <IconButton
          onClick={() => onRate(QualityName.Regular)}
          isLoading={loading === QualityName.Regular}
          isDisabled={loading !== null}
          aria-label="Regular"
          icon={<Icon w={4} h={4} as={FiMeh} />}
        />
        <IconButton
          onClick={() => onRate(QualityName.Good)}
          isDisabled={loading !== null}
          isLoading={loading === QualityName.Good}
          aria-label="Good"
          icon={<Icon w={4} h={4} as={FiSmile} />}
        />
      </HStack>
    </Flex>
  );
};
