import {
  SlideFade as ChakraSlideFade,
  SlideFadeProps as ChakraSlideFadeProps,
  forwardRef,
} from '@chakra-ui/react';

type SlideFadeProps = ChakraSlideFadeProps;

export const SlideFade = forwardRef<SlideFadeProps, 'div'>((props, ref) => (
  <ChakraSlideFade ref={ref} {...props} />
));
