import { DeepPartial } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useBoardAnalytics } from '../../queries';
import {
  useToast,
  useNow,
  ONE_DAY_MS,
  TOAST_ERROR_GENERIC,
  NO_OP,
  log,
} from '@spoke/common';
import {
  useUpdateBoardMutation,
  Board,
  PaginatedTeamBoardsDocument,
} from '@spoke/graphql';

export const useUpdateBoardTeamHandler = () => {
  const [updateBoard] = useUpdateBoardMutation();
  const [toast] = useToast();

  const now = useNow({ roundTo: 'ten-minutes' });

  const [_, boardAnalyticsQuery] = useBoardAnalytics({
    endDate: now,
    timeWindowLength: 90 * ONE_DAY_MS,
    recursive: false,
  });

  const handleUpdateBoardTeam = useCallback(
    async (boardId: string, teamId: string) => {
      log.info(`Updating board team to '${teamId}'`, {
        teamId,
        boardId,
      });

      const updateBoardFields: DeepPartial<Board> = { teamId };
      const { errors } = await updateBoard({
        variables: { boardId, fields: updateBoardFields },
        refetchQueries: [
          {
            query: PaginatedTeamBoardsDocument,
            variables: {
              teamId,
              limit: 9,
              archived: false,
              offset: 0,
            },
          },
        ],
        awaitRefetchQueries: true,
      });

      if (errors) {
        log.error('Board move responded with errors', { errors });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      boardAnalyticsQuery.refetch().catch(NO_OP);

      toast({
        status: 'success',
        title: 'Sucess',
        description: 'Retrospective successfully moved.',
      });
    },
    [updateBoard, toast, boardAnalyticsQuery]
  );

  return [handleUpdateBoardTeam];
};
