import { Icon, IconProps } from '../Icon';

export const MicrosoftIcon = (props: IconProps) => (
  <Icon viewBox="0 0 25 24" {...props}>
    <g clipPath="url(#clip0_4076_39248)">
      <path d="M0.5 11H11.5V0H0.5V11Z" fill="#F25022" />
      <path d="M13.5 11H24.5V0H13.5V11Z" fill="#7FBA00" />
      <path d="M0.5 24H11.5V13H0.5V24Z" fill="#00A4EF" />
      <path d="M13.5 24H24.5V13H13.5V24Z" fill="#FFB900" />
    </g>
    <defs>
      <clipPath id="clip0_4076_39248">
        <rect width={24} height={24} fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </Icon>
);
