import { ChakraProps } from '@chakra-ui/react';
import { FC, useState, useEffect, memo } from 'react';
import { BsCheckCircle } from 'react-icons/bs';
import { FaRegCompass } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';
import { HiOutlineClipboardCheck } from 'react-icons/hi';
import { MdSort, MdOutlineChat, MdOutlineBookmarkBorder } from 'react-icons/md';
import {
  useChangeBoardStageHandler,
  useFinishBoardHandler,
  useToggleActionPlanHandler,
  useTogglePrivateCardsHandler,
  useTogglePrivateVotesHandler,
  useBoardTimerChangeHandler,
  useSortBoardHandler,
  useToggleGuidedProcessHandler,
} from '../handlers';
import { useBoardSidebarState } from '../util';
import { BoardTimer } from './BoardTimer/BoardTimer';
import { EndRetroConfirmationModal } from './EndRetroConfirmationModal';
import { useUpdateTeamSettingsHandler } from '@spoke/user';
import { BoardStage } from '@spoke/graphql';
import {
  BOARD_STAGES_ORDER,
  useDisclosure,
  SortBoardType,
  Flex,
  Menu,
  Tooltip,
  MenuButton,
  Button,
  Icon,
  MenuList,
  MenuItem,
  PopoverConfirmation,
  Divider,
  Popover,
  PopoverAnchor,
  BOARD_STAGE_DISPLAY_NAMES,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  ConfirmationModal,
  FormControl,
  Switch,
  FormLabel,
  Text,
  useAssets,
} from '@spoke/common';

const STAGE_BUTTONS: BoardStage[] = BOARD_STAGES_ORDER.filter(
  (stage) => stage !== BoardStage.Finished
);

const FACILITATOR_STAGE_TOOLTIP_HINT_TEXT: Record<BoardStage, string> = {
  [BoardStage.Reflect]:
    'Invite the team to provide feedback in each column.\nYou will decide when to move to the grouping phase.\n',
  [BoardStage.Group]:
    'Invite the team to group similar issues together.\nYou will decide when to move to the voting phase.\n',
  [BoardStage.Vote]:
    'Invite the team to vote on the most important items.\nYou will decide when to move to the discussion phase.\n',
  [BoardStage.Discuss]:
    'Invite the team to discuss top voted items and identify any needed actions.\n',
  [BoardStage.FactsStats]: '',
  [BoardStage.Finished]: '',
};

const PURPLE_HIGHLIGT: ChakraProps = {
  borderColor: 'primary.600',
  color: 'primary.600',
  borderWidth: '2px',
};

const GREEN_HIGHLIGHT: ChakraProps = {
  borderColor: 'green.700',
  color: 'green.700',
  borderWidth: '2px',
};

type FacilitatorBoardControlsProps = {
  tooltipHintsEnabled: boolean;
  timerStartMs: number;
  timerStopMs: number;
  boardTeamId: string;
  currentStage: BoardStage | null;
  privateCards: boolean;
  privateVotes: boolean;
  isActionPlanOpen: boolean;
  isFacilitator: boolean;
  isGuided: boolean;
};
export const FacilitatorBoardControls: FC<FacilitatorBoardControlsProps> = ({
  timerStartMs,
  timerStopMs,
  tooltipHintsEnabled,
  boardTeamId,
  currentStage,
  privateCards,
  privateVotes,
  isActionPlanOpen,
  isFacilitator,
  isGuided,
}: FacilitatorBoardControlsProps) => {
  const [showTooltipHint, setShowTooltipHint] = useState(false);

  const { sidebarIsCollapsed, sidebarWidth } = useBoardSidebarState();

  const guidedConfirmationModal = useDisclosure();
  const endRetroConfirmationModal = useDisclosure();
  const [rememberGuidedChoice, setRememberGuidedChoice] = useState(false);

  const [handleChangeBoardStage] = useChangeBoardStageHandler();
  const [handleFinishBoard] = useFinishBoardHandler();
  const [handleToggleActionPlan] = useToggleActionPlanHandler();
  const [handleTogglePrivateCards] = useTogglePrivateCardsHandler();
  const [handleTogglePrivateVotes] = useTogglePrivateVotesHandler();
  const [handleBoardTimerChange] = useBoardTimerChangeHandler();
  const [handleSortBoard] = useSortBoardHandler();
  const [handleUpdateTeamSettings] = useUpdateTeamSettingsHandler();
  const [handleToggleGuidedProcess] = useToggleGuidedProcessHandler({
    boardTeamId,
    isGuided,
  });

  const { illustrationCanvas } = useAssets();

  const onToggleGuidedProcess = async () => {
    guidedConfirmationModal.close();
    await handleToggleGuidedProcess();
    if (!rememberGuidedChoice) return;
    await handleUpdateTeamSettings({
      teamId: boardTeamId,
      newSettings: {
        defaultGuidedRetrospectives: !isGuided,
      },
    });
  };

  const { Discuss, Finished } = BoardStage;

  const is = (stage: BoardStage) => currentStage === stage;
  const isPast = (stage: BoardStage) =>
    currentStage &&
    BOARD_STAGES_ORDER.indexOf(currentStage) >
      BOARD_STAGES_ORDER.indexOf(stage);

  const getStyles = (
    to: BoardStage | 'guided' | 'actionPlan' | 'privateCards' | 'privateVotes'
  ): ChakraProps => {
    if (to === 'guided') return isGuided ? PURPLE_HIGHLIGT : {};
    if (to === 'privateCards') return privateCards ? PURPLE_HIGHLIGT : {};
    if (to === 'privateVotes') return privateVotes ? PURPLE_HIGHLIGT : {};
    if (to === 'actionPlan') return isActionPlanOpen ? PURPLE_HIGHLIGT : {};
    if (isPast(to)) return GREEN_HIGHLIGHT;
    if (is(to)) return PURPLE_HIGHLIGT;
    return {};
  };

  const sortCardsTooltip = useDisclosure();

  const [showSwitchToUnguided, _setShowSwitchToUnguided] = useState(false);
  // TODO Tooltip hints are not positioning well when switch to unguided shows; Disabling for now
  // Maybe a cleaner solution is to just show the "Guided Process" tooltip instead of a text that shifts everything
  // useEffect(() => {
  //   const timeout1 = setTimeout(() => {
  //     if (tooltipHintsEnabled) setShowSwitchToUnguided(true);
  //   }, 6000);

  //   const timeout2 = setTimeout(() => {
  //     setShowSwitchToUnguided(false);
  //   }, 12000);

  //   return () => {
  //     clearTimeout(timeout1);
  //     clearTimeout(timeout2);
  //   };
  // }, [tooltipHintsEnabled]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (tooltipHintsEnabled) setShowTooltipHint(true);
    }, 4000);

    return () => {
      clearTimeout(timeout);
    };
  }, [tooltipHintsEnabled]);

  const { Newest, Oldest, Votes } = SortBoardType;

  const positionOffsetLeft = isFacilitator
    ? sidebarIsCollapsed
      ? 40
      : sidebarWidth
    : 0;

  return (
    <Flex
      bg="white"
      boxShadow="md"
      borderRadius="50px"
      px={3}
      py={2}
      w="fit-content"
      position="fixed"
      bottom={7}
      gap={2}
      alignItems="center"
      left={`calc((100vw + ${positionOffsetLeft}px) / 2)`}
      transform="translateX(-50%)"
      transition="left 0.2s ease-out"
    >
      <BoardTimer
        startTimeMs={timerStartMs || null}
        stopTimeMs={timerStopMs || null}
        onTimerChange={handleBoardTimerChange}
      />
      {!isGuided && (
        <>
          <Menu placement="top" onClose={sortCardsTooltip.close}>
            <Tooltip
              variant="white"
              label="Sort Cards"
              placement="top"
              offset={[0, 21]}
              isOpen={sortCardsTooltip.isOpen}
              onClose={sortCardsTooltip.close}
              shouldWrapChildren
            >
              <MenuButton onMouseEnter={sortCardsTooltip.open}>
                <Button as="span" variant="outlineRoundedIcon">
                  <Icon as={MdSort} w={5} h={5} />
                </Button>
              </MenuButton>
            </Tooltip>
            <MenuList flexDir="column" alignItems="flex-start">
              <Text
                fontSize={14}
                ml={2}
                my={2}
                color="gray.700"
                fontWeight={500}
              >
                Sort by
              </Text>
              <MenuItem onClick={() => handleSortBoard(Votes)}>
                Most votes
              </MenuItem>
              <MenuItem onClick={() => handleSortBoard(Newest)}>
                Newest first
              </MenuItem>
              <MenuItem onClick={() => handleSortBoard(Oldest)}>
                Oldest first
              </MenuItem>
            </MenuList>
          </Menu>
          <PopoverConfirmation
            title="Private Feedback"
            tooltip="Private Feedback"
            text={
              privateCards
                ? "This will reveal all feedback, so everyone's cards will be visible after 5 seconds."
                : `This will turn on private feedback, so participants will only see their own cards.`
            }
            buttonText={
              privateCards ? 'Reveal feedback' : 'Turn on private feedback'
            }
            onConfirm={handleTogglePrivateCards}
          >
            <Button variant="outlineRoundedIcon" {...getStyles('privateCards')}>
              <Icon as={MdOutlineChat} w={5} h={5} />
            </Button>
          </PopoverConfirmation>
          <PopoverConfirmation
            title="Private Voting"
            tooltip="Private Voting"
            text={
              privateVotes
                ? "This will reveal all votes, so everyone's votes will be visible after 5 seconds."
                : `This will turn on private voting, so participants can only see their own votes.`
            }
            buttonText={
              privateVotes ? 'Reveal votes' : 'Turn on private voting'
            }
            onConfirm={handleTogglePrivateVotes}
          >
            <Button variant="outlineRoundedIcon" {...getStyles('privateVotes')}>
              <Icon as={HiOutlineClipboardCheck} w="22px" h={8} pb="1px" />
            </Button>
          </PopoverConfirmation>
          <PopoverConfirmation
            title="Action Plan"
            tooltip="Action Plan"
            text="Show a list of Action Items created in this retro for everyone"
            buttonText={isActionPlanOpen ? 'Hide' : 'Show'}
            onConfirm={handleToggleActionPlan}
          >
            <Button variant="outlineRoundedIcon" {...getStyles('actionPlan')}>
              <Icon as={MdOutlineBookmarkBorder} w={5} h={5} />
            </Button>
          </PopoverConfirmation>
        </>
      )}
      <Tooltip variant="white" label="Guided Process">
        <Button
          variant="outlineRoundedIcon"
          onClick={() => guidedConfirmationModal.open()}
          w={showSwitchToUnguided ? '170px' : '48px'}
          transition="width 0.1s ease-out"
          overflow="hidden"
          alignItems="center"
          {...getStyles('guided')}
        >
          <Icon as={FaRegCompass} w={5} h={5} />
          {showSwitchToUnguided && (
            <Text ml={1} mt="2px">
              Switch to unguided?
            </Text>
          )}
        </Button>
      </Tooltip>
      {isGuided && <Divider orientation="vertical" minH={10} />}
      {isGuided &&
        STAGE_BUTTONS.map((stage, idx) => (
          <Popover
            key={stage}
            isOpen={showTooltipHint && is(stage)}
            onClose={() => setShowTooltipHint(false)}
            autoFocus={false}
            closeOnBlur={false}
            placement="top"
            offset={[0, 20]}
          >
            <Button
              onClick={() => handleChangeBoardStage(stage)}
              variant="outlineRounded"
              {...getStyles(stage)}
            >
              <PopoverAnchor>
                <Flex align="center">
                  {is(stage) && `${idx + 1}.`}{' '}
                  {isPast(stage) && (
                    <Icon as={BsCheckCircle} w={5} h={5} mr={2} />
                  )}
                  {BOARD_STAGE_DISPLAY_NAMES[stage]}
                </Flex>
              </PopoverAnchor>
            </Button>

            <PopoverContent
              bg="primary.600"
              border="none"
              boxShadow="spread"
              py={2}
              pl={4}
              pr={6}
            >
              <PopoverArrow bg="primary.600" />
              <PopoverCloseButton color="white" />
              <Text color="white" fontSize={14}>
                {FACILITATOR_STAGE_TOOLTIP_HINT_TEXT[stage]}
              </Text>
            </PopoverContent>
          </Popover>
        ))}
      {isGuided && !is(Finished) && (
        <Button
          onClick={() =>
            is(Discuss)
              ? endRetroConfirmationModal.open()
              : handleChangeBoardStage('NEXT')
          }
          variant={is(Discuss) ? 'outlineRounded' : 'outlineRoundedIcon'}
          bg="primary.600"
          color="white"
        >
          {is(Discuss) && 'End Retro'} <Icon as={FiArrowRight} w={5} h={5} />
        </Button>
      )}

      <EndRetroConfirmationModal
        isOpen={endRetroConfirmationModal.isOpen}
        onClose={endRetroConfirmationModal.close}
        onConfirm={(sendSummary: boolean) => {
          endRetroConfirmationModal.close();
          handleFinishBoard(sendSummary);
        }}
      />

      <ConfirmationModal
        close={guidedConfirmationModal.close}
        isOpen={guidedConfirmationModal.isOpen}
        image={illustrationCanvas}
        title={`${isGuided ? 'Exit' : 'Enable'} Guided Process`}
        onConfirm={onToggleGuidedProcess}
        text={
          isGuided
            ? ' Are you sure you want to exit this guided retrospective? This will enable grouping, voting, and visibility of all actions on the board.'
            : 'Are you sure you want to enable a guided retrospective? This will control the visibility of cards and votes depending on each selected phase of the process.'
        }
      >
        <FormControl display="flex" alignItems="center" justifyContent="center">
          <Switch
            onChange={() => setRememberGuidedChoice((remember) => !remember)}
            isChecked={rememberGuidedChoice}
            id="set-default"
            mr={2}
          />
          <FormLabel
            htmlFor="set-default"
            mb="0"
            fontWeight={500}
            color="gray.700"
            fontSize={16}
          >
            Remember this for future retros with this team
          </FormLabel>
        </FormControl>
      </ConfirmationModal>
    </Flex>
  );
};

export const MemoizedFacilitatorBoardControls = memo(FacilitatorBoardControls);
