import { NextPage } from 'next';
import { useState, useEffect, useLayoutEffect } from 'react';
import {
  SpokeAuthView,
  useCurrentUser,
  compareRoutes,
  isServer,
  log,
  useRouter,
  useRoutes,
  SpkPath,
} from '@spoke/common';

export const OnboardingGuard: NextPage = ({ children }) => {
  const router = useRouter();
  const routes = useRoutes();
  const [guardPassed, setGuardPassed] = useState(false);

  const [currentUser] = useCurrentUser();

  const IGNORE_ROUTES: string[] = [
    routes.AuthView(SpokeAuthView.VerifyEmail),
    routes.AuthView(SpokeAuthView.ChangeAccountEmail),
    routes.InvitationBoard(),
    routes.InvitationBoardView(),
    routes.InvitationTeam(),
    routes.InvitationTeamView(),
    routes.LegacyInvitationTeam(),
    routes.GetStartedRetro,
    routes.GetStartedIntegrations,
  ];

  const shouldApply =
    !IGNORE_ROUTES.some((r) => compareRoutes(r, router.route, router.asPath)) &&
    currentUser?.verified;

  const currentUserHasNoOrg = !currentUser?.organization?.id;
  const currentUserHasNoTeam = !currentUser?.team?.id;

  const shouldBeOnOnboarding =
    currentUser && (currentUserHasNoOrg || currentUserHasNoTeam);

  const redirectTo = currentUserHasNoOrg
    ? routes.GetStarted
    : routes.OnboardingCreateTeam;

  (isServer() ? useEffect : useLayoutEffect)(() => {
    const { asPath, route, isReady } = router;

    if (!isReady) return;

    if (
      !shouldApply ||
      !shouldBeOnOnboarding ||
      compareRoutes(redirectTo, asPath, route)
    ) {
      setGuardPassed(true);
      return;
    }

    setGuardPassed(false);

    log.info(`OnboardingGuard redirecting to ${redirectTo}`, {
      currentUser,
      shouldBeRedirected: shouldBeOnOnboarding,
      redirectFrom: asPath,
      redirectTo,
    });

    const redirectCfg: SpkPath = { pathname: redirectTo };

    if (!isServer()) router.replace(redirectCfg);
  }, [currentUser, redirectTo, router, shouldApply, shouldBeOnOnboarding]);

  if (shouldApply && !guardPassed) return null;
  return <>{children}</>;
};
