import { useCallback } from 'react';
import { useCurrentTeam, useTeamTree } from '../queries';
import {
  useAddTeamToProgramMutation,
  useRemoveTeamFromProgramMutation,
} from '@spoke/graphql';
import { log } from '@spoke/common';

export const useUpdateProgramTeamsHandler = () => {
  const [addTeamToProgram] = useAddTeamToProgramMutation();
  const [_, currentTeamQuery] = useCurrentTeam();
  const [__, teamTreeQuery] = useTeamTree();
  const [removeTeamFromProgram] = useRemoveTeamFromProgramMutation();

  const handleUpdateProgramTeams = useCallback(
    async ({
      programId,
      teamIdsToRemove,
      teamsIdsToAdd,
    }: {
      programId: string;
      teamsIdsToAdd: string[];
      teamIdsToRemove: string[];
    }) => {
      if (!programId) {
        log.error('Missing critical data to update program teams');
        return;
      }

      log.info(
        `Updating program ${programId} teams. Removing ${teamIdsToRemove.length} teams and adding ${teamsIdsToAdd.length} teams`,
        { programId, teamIdsToRemove, teamsIdsToAdd }
      );

      const promises = [] as Promise<unknown>[];

      if (teamsIdsToAdd?.length) {
        for (const teamId of teamsIdsToAdd) {
          promises.push(
            addTeamToProgram({
              variables: {
                programId,
                teamId,
              },
            })
          );
        }
      }

      if (teamIdsToRemove?.length) {
        for (const teamId of teamIdsToRemove) {
          promises.push(
            removeTeamFromProgram({
              variables: {
                programId,
                teamId,
              },
            })
          );
        }
      }

      await Promise.all(promises);
      await Promise.all([teamTreeQuery.refetch(), currentTeamQuery.refetch()]);
    },
    [addTeamToProgram, removeTeamFromProgram, teamTreeQuery, currentTeamQuery]
  );

  return [handleUpdateProgramTeams];
};
