import { NextPage } from 'next';
import { useState, useEffect, useLayoutEffect } from 'react';
import {
  SpokeAuthView,
  compareRoutes,
  isServer,
  getContinueUrlQuery,
  FullScreenLoading,
  log,
  useRouter,
  useRoutes,
  SpkRouteKey,
  SpkPath,
} from '@spoke/common';
import { useCurrentUserQuery } from '@spoke/graphql';

export const AuthGuard: NextPage = ({ children }) => {
  const currentUserQuery = useCurrentUserQuery();
  const router = useRouter();
  const routes = useRoutes();

  const [guardPassed, setGuardPassed] = useState(false);

  const currentUser = currentUserQuery.data?.user ?? null;
  const isLoggedIn = Boolean(currentUser);

  const IGNORE_ROUTES: string[] = [
    routes.Logout,
    routes.Board(),
    routes.InvitationBoard(),
    routes.InvitationTeam(),
    routes.AuthView(SpokeAuthView.ResetPassword),
    routes.AuthView(SpokeAuthView.ConfirmResetPassword),
    routes.AuthView(SpokeAuthView.CreateNewPassword),
    routes.AuthView(SpokeAuthView.ChangeAccountEmail),
    routes.AuthBridge(),
    routes.GetStarted,
  ];

  const LOGGED_OUT_ONLY_ROUTES: string[] = [
    routes[SpkRouteKey.Root],
    routes.Auth,
    routes.AuthView(SpokeAuthView.EmailPassword),
    routes.AuthView(SpokeAuthView.Register),
    routes.AuthView(SpokeAuthView.MethodSelector),
    routes.AuthView(SpokeAuthView.Sso),
  ];

  const LOGGED_IN_ONLY_ROUTES: string[] = [
    routes[SpkRouteKey.Root],
    routes.Dashboard,
    routes.Retrospectives,
    routes.ImprovementGoals,
    routes.ActionItems,
    routes.ParkingLot,
    routes.TeamMembers,
    routes.ProgramTeams,
    routes.TeamSettings,
    routes.CreateBoard,
    routes.Board(),
    routes.Whiteboard(),
    routes.Onboarding,
    routes.OnboardingCreateTeam,
    routes.OnboardingSurvey,
    routes.Users,
    routes.OrgSettings,
    routes.UserSettings,
    routes.Integrations,
    routes.AuthView(SpokeAuthView.VerifyEmail),
    routes.SetupGithub,
    routes.Metrics,
    routes.SetupBitbucket,
  ];

  /**
   * Routes that we should not add ?continue=... to when redirecting to the login page
   */
  const IGNORE_CONTINUE_ROUTES: string[] = [
    routes.Logout,
    routes.AuthView(SpokeAuthView.VerifyEmail),
  ];

  const shouldApply =
    !IGNORE_ROUTES.some((r) => compareRoutes(r, router.route, router.asPath)) &&
    (!currentUser || currentUser.verified);

  (isServer() ? useEffect : useLayoutEffect)(() => {
    const { route, asPath, query, isReady } = router;

    if (currentUserQuery.loading || !currentUserQuery.called || !isReady) {
      return;
    }

    if (!shouldApply) {
      setGuardPassed(true);
      return;
    }

    const routeIsLoggedOutOnly = LOGGED_OUT_ONLY_ROUTES.some((r) =>
      compareRoutes(r, route, asPath)
    );
    const routeIsLoggedInOnly = LOGGED_IN_ONLY_ROUTES.some((r) =>
      compareRoutes(r, route, asPath)
    );
    const shouldRedirect =
      (isLoggedIn && routeIsLoggedOutOnly) ||
      (!isLoggedIn && routeIsLoggedInOnly);

    if (!shouldRedirect) {
      setGuardPassed(true);
      return;
    }

    setGuardPassed(false);

    if (isLoggedIn) {
      const queryContinue = query.continue as string;
      const redirectTo = queryContinue?.split('?')[0] ?? routes.Dashboard;
      const redirectWith = queryContinue?.split('?')[1] ?? null;

      const redirectCfg: SpkPath = { pathname: redirectTo };
      if (redirectWith) redirectCfg.query = redirectWith;

      if (redirectTo === asPath) {
        setGuardPassed(true);
        return;
      }

      log.info(`AuthGuard redirecting logged in user to ${redirectTo}`, {
        currentUser,
        routeIsLoggedOutOnly,
        routeIsLoggedInOnly,
        shouldRedirect,
        redirectFrom: route,
        redirectFromQuery: query,
        redirectTo: redirectCfg,
      });

      if (!isServer()) router.replace(redirectCfg);
    }

    if (!isLoggedIn) {
      const redirectTo = routes.AuthView(SpokeAuthView.MethodSelector);
      const redirectCfg: SpkPath = { pathname: redirectTo };
      if (!IGNORE_CONTINUE_ROUTES.includes(asPath)) {
        redirectCfg.query = getContinueUrlQuery(asPath);
      }

      if (redirectTo === asPath) {
        setGuardPassed(true);
        return;
      }

      log.info(`AuthGuard redirecting unauthenticated user to ${redirectTo}`, {
        currentUser,
        routeIsLoggedOutOnly,
        routeIsLoggedInOnly,
        shouldBeRedirected: shouldRedirect,
        redirectFrom: route,
        redirectFromQuery: query,
        redirectTo: redirectCfg,
      });

      if (!isServer()) router.replace(redirectCfg);
    }
  }, [currentUserQuery.loading, currentUserQuery.called, isLoggedIn, router]);

  if (shouldApply && !guardPassed) return <FullScreenLoading />;

  return <>{children}</>;
};
