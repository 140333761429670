import { FC } from 'react';
import { FlowMetricsQueryData } from '../../queries';
import {
  SpkTime,
  HStack,
  SX_HORIZONTAL_SCROLL_ON_HOVER,
  LifecycleBar,
} from '@spoke/common';

type Props = {
  statuses: FlowMetricsQueryData['statuses'] | null;
  overrallCycleTimeMs: number;
  loading?: boolean;
};
export const FlowMetricsStatuses: FC<Props> = ({
  statuses,
  overrallCycleTimeMs,
  loading = false,
}) => {
  const longestLifecyclePart = Math.max(
    ...(statuses?.length ? statuses.map((s) => s.timeSpentMs) : [0])
  );

  const sum =
    statuses?.reduce((acc, curr) => acc + curr.timeSpentMs, 0) ?? (0 as number);

  const getMadeUpPercentage = (timeSpentMs: number): string =>
    !overrallCycleTimeMs
      ? 'N/A'
      : SpkTime.formatDuration(
          (timeSpentMs / sum) * overrallCycleTimeMs,
          'hours'
        );

  const placeholderStatuses = [
    { name: 'Todo' },
    { name: 'In Progress' },
    { name: 'Review' },
  ] as NonNullable<typeof statuses>;

  return (
    <HStack
      spacing={0}
      mt="4px"
      w="full"
      overflowY="hidden"
      sx={SX_HORIZONTAL_SCROLL_ON_HOVER}
    >
      {(loading || !statuses?.length
        ? placeholderStatuses
        : statuses ?? []
      ).map((status, idx, arr) => (
        <LifecycleBar
          key={status.name ?? idx}
          loading={loading}
          label={status.name ?? 'Unknown'}
          value={getMadeUpPercentage(status.timeSpentMs)}
          flex={status.timeSpentMs}
          isFirst={idx === 0}
          isLast={idx === arr.length - 1}
          highlight={
            longestLifecyclePart === 0 ||
            longestLifecyclePart === status.timeSpentMs
          }
        />
      ))}
    </HStack>
  );
};
