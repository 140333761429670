import { AscDesc } from '@spoke/common';
import { FC } from 'react';
import { BsArrowDownCircleFill, BsArrowUpCircleFill } from 'react-icons/bs';
import { Flex, FlexProps } from '../Flex';
import { Icon } from '../Icon';
import { Text } from '../Text';
import { SlideFade } from '../Transitions/SlideFade';

type SpokeTableHeaderProps = FlexProps & {
  title?: string;
  sortDirection?: AscDesc | null;
  onClick?: () => void;
};
export const SpokeTableHeader: FC<SpokeTableHeaderProps> = ({
  title,
  sortDirection,
  onClick,
  ...rest
}) => (
  <Flex
    w="full"
    minH={10}
    pl={2}
    onClick={onClick}
    _hover={{ bg: onClick ? 'gray.100' : 'white' }}
    cursor={onClick ? 'pointer' : 'default'}
    {...rest}
  >
    <Flex alignItems="center">
      <Text
        textTransform="uppercase"
        color={sortDirection ? 'gray.500' : 'gray.500'}
        userSelect={onClick ? 'none' : 'unset'}
        fontWeight={600}
        fontSize={13}
        mr={1}
        mt="2px"
        display="inline"
      >
        {title}
      </Text>

      {sortDirection === AscDesc.Desc && (
        <SlideFade
          in
          offsetY={-5}
          style={{ maxHeight: '15px', display: 'inline' }}
        >
          <Icon
            as={BsArrowDownCircleFill}
            color="gray.500"
            onClick={onClick}
            cursor="pointer"
            w={5}
            h={4}
          />
        </SlideFade>
      )}
      <SlideFade
        in={sortDirection === AscDesc.Asc}
        style={{ maxHeight: '15px', display: 'inline' }}
        unmountOnExit
      >
        <Icon
          as={BsArrowUpCircleFill}
          color="gray.500"
          onClick={onClick}
          cursor="pointer"
          w={5}
          h={4}
        />
      </SlideFade>
    </Flex>
  </Flex>
);
