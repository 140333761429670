import {
  ChakraProps,
  extendTheme,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import {
  CardComponentStyle,
  LinkComponentStyle,
  FormLabelComponentStyle,
  FormErrorMessageComponentStyle,
  ButtonComponentStyle,
  DividerComponentStyle,
  InputComponentStyle,
  BadgeComponentStyle,
  SkeletonComponentStyle,
  MenuComponentStyle,
  SpinnerComponentStyle,
  ModalComponentStyle,
  SpkButtonGroupComponentStyle,
  TableComponentStyle,
  TooltipComponentStyle,
  SwitchComponentStyle,
  ProgressComponentStyle,
  SelectComponentStyle,
  PopoverComponentStyle,
  NumberInputComponentStyle,
  AlertComponentStyle,
  CheckboxComponentStyle,
  AvatarComponentStyle,
} from '@spoke/common';

/**
 * Similar to variants, but usable in all components
 */
const layerStyles: Record<string, ChakraProps> = {
  outlineGray: {
    borderColor: 'gray.200',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 'md',
    p: 3,
  },
  horizontalScrollOnHover: {
    overflowY: 'hidden',
    sx: {
      '&::-webkit-scrollbar': {
        height: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
        borderRadius: '3px',
      },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray.300',
      },
    },
  },
};

const globalStyles = {};

export const theme = {
  // https://smart-swatch.netlify.app/
  colors: {
    primary: {
      25: '#f3f1fb',
      50: '#efeaff',
      75: '#DED7F9',
      100: '#cdc3f3',
      200: '#ac9ce5',
      300: '#8b74d8',
      400: '#6a4dcc',
      500: '#5133b3',
      600: '#3f288c',
      700: '#2d1b65',
      800: '#1a103f',
      900: '#0a041b',
    },
    green: {
      50: '#dcfff6',
      100: '#afffe8',
      200: '#80ffd8',
      300: '#51ffc9',
      400: '#2affba',
      500: '#17D695',
      600: '#0cb37c',
      700: '#008059',
      800: '#004d34',
      900: '#001c10',
    },
    gray: {
      25: '#FBFDFE',
      50: '#F7FAFC',
      75: '#F2F6FA',
      100: '#EDF2F7',
      200: '#E2E8F0',
      300: '#CBD5E0',
      400: '#A0AEC0',
      500: '#718096',
      600: '#4A5568',
      700: '#2D3748',
      800: '#1A202C',
      900: '#171923',
    },
    white: '#fff',
    neutralYellow: 'rgb(255, 204, 62)',
    negativeRed: 'rgb(250, 82, 82)',
  },
  fonts: {
    heading: 'Roboto, sans-serif',
    body: 'Roboto, sans-serif',
  },
  shadows: {
    outline: '0 0 0 1px #6a4dcc',
    spread: '1px 1px 7px rgba(0, 0, 0, 0.11)',
    spreadDark: '1px 1px 7px rgba(0, 0, 0, 0.15)',
    spreadExtraDark: '1px 1px 7px rgba(0, 0, 0, 0.25)',
    extraSpreadExtraDark: '1px 1px 10px rgba(0, 0, 0, 0.30)',
    light: '2px 2px 4px rgba(0, 0, 0, 0.06)',
    extralight: '2px 2px 4px rgba(0, 0, 0, 0.04)',
    lgInverse: '0 -10px 5px 3px rgba(0, 0, 0, 0.03)',
    inner: '0px 0px 0px 1px rgba(0, 0, 0, 0.2) inset',
  },
  layerStyles,
  styles: { global: globalStyles },
  components: {
    Card: CardComponentStyle,
    Link: LinkComponentStyle,
    FormLabel: FormLabelComponentStyle,
    FormError: FormErrorMessageComponentStyle,
    Button: ButtonComponentStyle,
    Divider: DividerComponentStyle,
    Input: InputComponentStyle,
    Badge: BadgeComponentStyle,
    Skeleton: SkeletonComponentStyle,
    Menu: MenuComponentStyle,
    Spinner: SpinnerComponentStyle,
    Modal: ModalComponentStyle,
    SpkButtonGroup: SpkButtonGroupComponentStyle,
    Table: TableComponentStyle,
    Tooltip: TooltipComponentStyle,
    Switch: SwitchComponentStyle,
    Progress: ProgressComponentStyle,
    Select: SelectComponentStyle,
    Popover: PopoverComponentStyle,
    NumberInput: NumberInputComponentStyle,
    Alert: AlertComponentStyle,
    Checkbox: CheckboxComponentStyle,
    Avatar: AvatarComponentStyle,
  },
};

export const spokeTheme = extendTheme(
  theme,
  withDefaultColorScheme({ colorScheme: 'primary' })
);
