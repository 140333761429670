import {
  PopoverContent as ChakraPopoverContent,
  PopoverContentProps as ChakraPopoverContentProps,
} from '@chakra-ui/react';
import { FC, forwardRef } from 'react';

export type PopoverContentProps = ChakraPopoverContentProps;

export const PopoverContent: FC<PopoverContentProps> = forwardRef<HTMLElement>(
  (props, ref) => <ChakraPopoverContent ref={ref} {...props} />
);
