import { FC, useState, useCallback } from 'react';
import { FiDownload } from 'react-icons/fi';
import { MdOutlinedFlag } from 'react-icons/md';
import { useAddImprovementGoalDataHandler } from '../../handlers';
import { useImprovementGoal } from '../../queries';
import { getImprovementGoalTeamInformationForQuery } from '../../util';
import { useCurrentTeam, useCurrentOrg, useTeamType } from '@spoke/user';
import { MemoizedReportGoalModalCrudChart } from './ReportGoalDataModalCrudChart';
import { MemoizedReportGoalDataModalForm } from './ReportGoalDataModalForm';
import { ReportGoalDataModalLoadingState } from './ReportGoalDataModalLoadingState';
import {
  ImprovementGoalTypeTargetType,
  ImprovementGoalUnit,
  ImprovementGoalAggregationPeriod,
} from '@spoke/graphql';
import {
  ModalProps,
  IMPROVEMENT_GOAL_DATA_MAX_VALUE,
  dataPointIsOnTarget,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  ModalBody,
  Flex,
  Divider,
  formatWithGoalUnit,
  truncateDecimals,
  Button,
  Modal,
  ModalOverlay,
  isNullish,
  Text,
  Circle,
} from '@spoke/common';

type ReportGoalDataModalProps = Omit<ModalProps, 'children'> & {
  improvementGoalId: string;
  onImportCSVClicked: () => void;
};
export const ReportGoalDataModalContent: FC<ReportGoalDataModalProps> = ({
  onClose,
  improvementGoalId,
  onImportCSVClicked,
}) => {
  const [currentTeam] = useCurrentTeam();
  const [currentOrg] = useCurrentOrg();
  const teamType = useTeamType();

  const { reportingDataTeamIds } = getImprovementGoalTeamInformationForQuery(
    teamType,
    currentTeam,
    currentOrg
  );

  const [goal, { loading, refetch }] = useImprovementGoal({
    id: improvementGoalId,
    takeLastNPeriodsWithData: 5,
    reportingDataTeamIds,
  });

  const [addGoalData] = useAddImprovementGoalDataHandler();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [date, setDate] = useState<Date | null>(null);
  const [value, setValue] = useState<string>('');

  const isLoading = loading && !goal;

  const goalType = goal?.type;

  const max = goalType?.maxValue;
  const min = goalType?.minValue;

  const isInvalid = (toValidate: string) => {
    if (!toValidate.length) return true;
    const castValue = Number(toValidate);
    if (Number.isNaN(castValue)) return true;
    if (castValue > IMPROVEMENT_GOAL_DATA_MAX_VALUE) return true;
    if (!isNullish(min) && castValue < min!) return true;
    if (!isNullish(max) && castValue > max!) return true;
    return false;
  };

  const targetPrefix =
    goalType?.targetType === ImprovementGoalTypeTargetType.Above ? '>' : '<';

  const currentValue = goal?.data?.[goal?.data?.length - 1]?.value ?? null;
  const onTarget: boolean = dataPointIsOnTarget(
    currentValue ?? goal?.target ?? 0,
    goal?.target ?? 0,
    goalType?.targetType || ImprovementGoalTypeTargetType.Above
  );

  const submitDisabled = !date || isSubmitting || isInvalid(value);

  const onSubmit = useCallback(async () => {
    if (submitDisabled && !currentTeam?.id) return;
    setIsSubmitting(true);
    await addGoalData({
      date: date!,
      improvementGoalId,
      reportingTeamId: currentTeam?.id ?? 'incorrectCCIDWillError',
      value: Number(value),
    });
    await refetch({ id: improvementGoalId });
    setValue('');
    setIsSubmitting(false);
  }, [
    addGoalData,
    date,
    improvementGoalId,
    refetch,
    submitDisabled,
    value,
    currentTeam?.id,
  ]);

  const onRefetch = useCallback(async () => {
    await refetch({ id: improvementGoalId });
  }, [improvementGoalId, refetch]);

  const unit = goalType?.unit ?? ImprovementGoalUnit.Abstract;

  return (
    <ModalContent p={8} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Icon as={MdOutlinedFlag} w={6} h={6} />
          <Heading mb={1} fontSize={24} color="gray.900">
            Report goal
          </Heading>
        </HStack>
      </ModalHeader>
      <ModalBody p={0}>
        {isLoading && <ReportGoalDataModalLoadingState />}
        {!isLoading && (
          <Flex flexDir="column">
            <HStack spacing={1}>
              <Circle boxShadow="inner" size="9px" bg={goalType?.color} />
              <Text fontSize={16} color="gray.700" fontWeight={500}>
                {goalType?.name}
              </Text>
            </HStack>
            <Text fontSize={14} color="gray.600">
              {goalType?.description}
            </Text>
            <Divider my={3} />
            <HStack mb={3}>
              <Flex flexDir="column" gap="2px" flex={1}>
                <Text color="gray.600">Target</Text>
                <Text fontSize={16} color="gray.700" fontWeight={500}>
                  {targetPrefix}{' '}
                  {formatWithGoalUnit(goal?.target ?? '', unit, 'short')}
                </Text>
              </Flex>
              <Flex flexDir="column" gap="2px" flex={1}>
                <Text color="gray.600">Current</Text>
                <Text
                  fontSize={16}
                  color={
                    isNullish(currentValue)
                      ? 'gray.500'
                      : onTarget
                      ? 'green.600'
                      : 'red.500'
                  }
                  fontWeight={500}
                >
                  {typeof currentValue === 'number'
                    ? formatWithGoalUnit(
                        truncateDecimals(currentValue, 1),
                        unit,
                        'short'
                      )
                    : 'N/A'}
                </Text>
              </Flex>
            </HStack>
            <MemoizedReportGoalModalCrudChart
              data={goal?.data ?? []}
              unit={unit}
              targetType={
                goalType?.targetType ?? ImprovementGoalTypeTargetType.Above
              }
              typeName={goalType?.name ?? ''}
              typeMin={goalType?.minValue ?? null}
              typeMax={goalType?.maxValue ?? null}
              aggregationPeriod={
                goalType?.aggregationPeriod ??
                ImprovementGoalAggregationPeriod.Day
              }
              refetchData={onRefetch}
              target={goal?.target}
            />
            <MemoizedReportGoalDataModalForm
              date={date}
              onDateChange={setDate}
              value={value}
              onValueChange={setValue}
              onSubmit={onSubmit}
              unit={unit}
              aggregationPeriod={
                goalType?.aggregationPeriod ??
                ImprovementGoalAggregationPeriod.Day
              }
              max={max}
              min={min}
              isInvalid={Boolean(value.length && isInvalid(value))}
            />
          </Flex>
        )}
        <Flex gap={4} alignItems="center" justifyContent="center">
          <Button
            variant="outlineGray"
            onClick={onImportCSVClicked}
            leftIcon={<FiDownload />}
          >
            Import CSV
          </Button>
          <HStack ml="auto">
            <Button
              size="lg"
              disabled={isSubmitting}
              variant="outlineGray"
              w="fit-content"
              isDisabled={isSubmitting}
              onClick={onClose}
            >
              Close
            </Button>
            <Button
              isDisabled={submitDisabled}
              isLoading={isSubmitting}
              onClick={onSubmit}
              size="lg"
              w="fit-content"
            >
              Report
            </Button>
          </HStack>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

export const ReportGoalDataModal: FC<ReportGoalDataModalProps> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <ReportGoalDataModalContent {...props} />
  </Modal>
);
