import {
  ListItem as ChakraListItem,
  ListItemProps as ChakraListItemProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = ChakraListItemProps;

export const ListItem = forwardRef<HTMLLIElement, Props>((props, ref) => (
  <ChakraListItem {...props} ref={ref} />
));
