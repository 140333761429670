import { GraphQLError } from 'graphql';
import { useCallback } from 'react';
import {
  useToast,
  TOAST_ERROR_GENERIC,
  plural,
  pluralSwitch,
  log,
} from '@spoke/common';
import { useCreateJiraIssueFromCardMutation } from '@spoke/graphql';

export const useCreateJiraIssuesFromCardsHandler = () => {
  const [createJiraIssueFromCard] = useCreateJiraIssueFromCardMutation();
  const [toast] = useToast();

  const handleCreateJiraIssuesFromCards = useCallback(
    async ({
      cardIds,
      projectKey,
    }: {
      projectKey: string;
      cardIds: string[];
    }) => {
      if (!cardIds.length) {
        log.warn('No-op: no cards to create Jira issues from', { cardIds });
        return;
      }

      if (!projectKey) {
        log.error(
          'Cannot bulk create Jira issues from cards without Jira project key',
          { projectKey }
        );
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      log.info(`Bulk creating ${cardIds.length} Jira issues from cards`, {
        projectKey,
        cardIds,
      });

      // TODO we should add a bulk mutation instead of this
      const results = await Promise.all(
        cardIds.map((cardId) =>
          createJiraIssueFromCard({ variables: { projectKey, cardId } })
        )
      );

      const errors = results.reduce(
        (acc, cur) => [...acc, ...(cur.errors || [])],
        [] as GraphQLError[]
      );

      if (errors.length) {
        log.error('Bulk create Jira issues from cards responded with errors', {
          projectKey,
          cardIds,
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      toast({
        status: 'success',
        title: 'Success',
        description: `${cardIds.length} ${plural(
          'card',
          cardIds.length
        )} ${pluralSwitch(
          'has',
          'have',
          cardIds.length
        )} been successfully exported to Jira.`,
      });
    },
    [createJiraIssueFromCard, toast]
  );

  return [handleCreateJiraIssuesFromCards];
};
