import { useCallback } from 'react';
import { useCurrentUser, useCurrentBoard } from '@spoke/common';
import { BoardStage } from '@spoke/graphql';

type BoardRules = {
  canComment: boolean;
  canAddCards: boolean;
  canAddCardToActionItems: boolean;
  canEditCards: boolean;
  canDragCards: boolean;
  canGroupCards: boolean;
  canUseCardDropdown: boolean;
  canDragLists: boolean;
  canUseListDropdown: boolean;
  canVote: boolean;
  boardVoteCount: number;
  isFacilitator: boolean;
  isLoading: boolean;
  isGuided: boolean;
  currentStage: BoardStage | null;
  isStage: (stage: BoardStage) => boolean;
  isActionPlanOpen: boolean;
  showColumns: boolean;
  privateCards: boolean;
  privateVotes: boolean;
  timerStartMs: number;
  timerStopMs: number;
};

export const useCurrentBoardRules = (): BoardRules => {
  const [currentUser] = useCurrentUser();
  const [board] = useCurrentBoard();

  const currentStage: BoardStage | null = board?.state?.stage ?? null;

  const isStage = useCallback(
    (stage: BoardStage) => currentStage === stage,
    [currentStage]
  );

  const isGuided = Boolean(board?.state?.isGuided);

  const isFacilitator = Boolean(
    board?.facilitators?.includes(currentUser?.id ?? null)
  );

  const canComment = !isGuided || currentStage !== BoardStage.Finished;

  const canAddCards = !isGuided || currentStage === BoardStage.Reflect;

  const canAddCardToActionItems =
    !isGuided || currentStage !== BoardStage.Finished;

  const canEditCards = !isGuided || currentStage !== BoardStage.Finished;

  const canDragCards = !isGuided || currentStage !== BoardStage.Finished;

  const canGroupCards = !isGuided || currentStage === BoardStage.Group;

  const canUseCardDropdown = !isGuided || currentStage !== BoardStage.Finished;

  const canDragLists = !isGuided || currentStage !== BoardStage.Finished;

  const canUseListDropdown = !isGuided || currentStage !== BoardStage.Finished;

  const canVote = !isGuided || currentStage === BoardStage.Vote;

  const isLoading = !board;

  const showColumns = !isGuided || currentStage === BoardStage.Reflect;

  const isActionPlanOpen = Boolean(
    (!isGuided && board?.state?.showActionPlan) ||
      (isGuided && currentStage === BoardStage.Discuss)
  );

  const privateCards = Boolean(
    (isGuided && currentStage === BoardStage.Reflect) ||
      (!isGuided && board?.state?.privateCards)
  );

  const privateVotes = Boolean(
    (isGuided && currentStage === BoardStage.Vote) ||
      (!isGuided && board?.state?.privateVotes)
  );

  const boardVoteCount = board?.state.voteCount || 0;

  const timerStartMs = board?.state?.timerStartTimeMiliseconds || 0;

  const timerStopMs = board?.state?.timerStopTimeMiliseconds || 0;

  return {
    canComment,
    canAddCards,
    canAddCardToActionItems,
    canEditCards,
    canDragCards,
    canGroupCards,
    canUseCardDropdown,
    canDragLists,
    canUseListDropdown,
    canVote,
    isFacilitator,
    isLoading,
    isGuided,
    isStage,
    isActionPlanOpen,
    currentStage,
    showColumns,
    privateCards,
    privateVotes,
    boardVoteCount,
    timerStartMs,
    timerStopMs,
  };
};
