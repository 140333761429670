import {
  TabPanels as ChakraTabPanels,
  TabPanelsProps as ChakraTabPanelsProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = ChakraTabPanelsProps;

export const TabPanels = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ChakraTabPanels {...props} ref={ref} />
));
