import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log, isNullish } from '@spoke/common';
import {
  useCreateImprovementGoalMutation,
  ImprovementGoalFragmentFragment,
} from '@spoke/graphql';

export const useCreateImprovementGoalHandler = () => {
  const [createGoal] = useCreateImprovementGoalMutation();
  const [toast] = useToast();

  const handleCreateGoal = useCallback(
    async (data: {
      typeId: string;
      target: number;
    }): Promise<ImprovementGoalFragmentFragment | null> => {
      const { target, typeId } = data;

      if (!typeId || isNullish(target)) {
        log.error('Missing critical data to create improvement goal', {
          typeId,
          target,
        });
        return null;
      }

      log.info('Creating improvement goal', { data });

      const { errors, data: result } = await createGoal({
        variables: { input: { paused: false, target, typeId } },
      });

      if (errors) {
        log.error('createImprovementGoal call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return null;
      }

      return result?.createImprovementGoal ?? null;
    },
    [createGoal, toast]
  );

  return [handleCreateGoal];
};
