import { makeVar, useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';
import { isServer } from '../util';

const localStorage = isServer() ? null : window.localStorage;

const IS_LIGHWEIGHT_MODE = 'IsLightweightMode';

export const isLightweightModeVar = makeVar<boolean>(
  localStorage?.getItem(IS_LIGHWEIGHT_MODE) === 'true'
);

export const setIsLightweightMode = (newLightweight: boolean) => {
  localStorage?.setItem(IS_LIGHWEIGHT_MODE, `${newLightweight}`);
  isLightweightModeVar(newLightweight);
};

export const useIsLightweightMode = () => useReactiveVar(isLightweightModeVar);

export const useTransitioningFromLightweightMode = () => {
  const isLightweightMode = useIsLightweightMode();
  const [
    transitioningFromLightweightMode,
    setTransitioningFromLightweightMode,
  ] = useState(false);

  useEffect(() => {
    setTransitioningFromLightweightMode(true);
    const timeout = setTimeout(
      () => setTransitioningFromLightweightMode(false),
      500
    );
    return () => {
      clearTimeout(timeout);
    };
  }, [isLightweightMode]);

  return [transitioningFromLightweightMode];
};
