import { ReactNode, FC, useState } from 'react';
import { useCreateActionItemHandler } from '../handlers';
import {
  TOAST_ERROR_GENERIC,
  KeyboardKey,
  FlexProps,
  useToast,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  log,
  Button,
  Text,
  useCurrentBoardId,
  useCurrentUser,
} from '@spoke/common';

type ActionItemQuickAddProps = FlexProps & {
  onAdded?: () => void;
  improvementGoalIds?: string[];
  confirmButtonLabel: ReactNode;
};

export const ActionItemQuickAdd: FC<ActionItemQuickAddProps> = ({
  onAdded,
  confirmButtonLabel,
  improvementGoalIds,
  ...rest
}) => {
  const [currentUser] = useCurrentUser();
  const [boardId] = useCurrentBoardId();
  const [toast] = useToast();

  const [handleCreateActionItem] = useCreateActionItemHandler();

  const [quickAddActionItemText, setQuickAddActionItemText] = useState('');

  const handleQuickAdd = async () => {
    if (!quickAddActionItemText) return;
    const teamId = currentUser?.team?.id;

    if (!teamId) {
      log.error('Cannot quick add action item without current team id', {
        teamId,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const createActionItemData: Parameters<typeof handleCreateActionItem>[0] = {
      title: quickAddActionItemText,
      improvementGoalIds,
      teamId,
      boardId,
    };

    log.info('Handling action item quick add', { createActionItemData });

    const success = await handleCreateActionItem(createActionItemData);

    if (success) {
      setQuickAddActionItemText('');
      onAdded?.();
    }
  };

  return (
    <Flex w="full" alignItems="center" gap={2} {...rest}>
      <InputGroup overflow="hidden">
        {!quickAddActionItemText.length && (
          <InputLeftElement w="320px" pt="13px" pointerEvents="none">
            <Text whiteSpace="nowrap" color="gray.400" fontSize={14} mr={2}>
              Type here and hit
            </Text>
            <KeyboardKey size="sm">Enter</KeyboardKey>{' '}
            <Text whiteSpace="nowrap" color="gray.400" fontSize={14} ml={2}>
              to add action items
            </Text>
          </InputLeftElement>
        )}
        <Input
          pl={3}
          minH="45px"
          value={quickAddActionItemText}
          onChange={(e) => setQuickAddActionItemText(e.target.value)}
          onKeyDown={(e) => (e.key === 'Enter' ? handleQuickAdd() : null)}
        />
      </InputGroup>
      <Button
        disabled={!quickAddActionItemText}
        onClick={handleQuickAdd}
        paddingX={typeof confirmButtonLabel === 'string' ? 8 : 0}
      >
        {confirmButtonLabel}
      </Button>
    </Flex>
  );
};
