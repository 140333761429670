import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  optimisticUpdateImprovementGoal,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useUpdateImprovementGoalMutation } from '@spoke/graphql';

export const useUpdateImprovementGoalHandler = () => {
  const [updateImprovementGoal] = useUpdateImprovementGoalMutation();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  const handleUpdateImprovementGoal = useCallback(
    async (data: {
      improvementGoalId: string;
      paused: boolean;
      target: number;
    }): Promise<boolean> => {
      const { improvementGoalId, paused, target } = data;

      if (!improvementGoalId || target === undefined) {
        log.error('Missing critical data to update improvement goal', data);
        return false;
      }

      log.info('Updating improvement goal', {
        data,
      });

      const expected = optimisticUpdateImprovementGoal({
        cache,
        fields: { paused, target },
        improvementGoalId,
      });

      const { errors } = await updateImprovementGoal({
        variables: { id: improvementGoalId, input: { paused, target } },
        optimisticResponse: !expected
          ? undefined
          : {
              __typename: 'Mutation',
              updateImprovementGoal: expected,
            },
      });

      if (errors) {
        log.error('updateImprovementGoal call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [cache, updateImprovementGoal, toast]
  );

  return [handleUpdateImprovementGoal];
};
