import { FC, useMemo } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import { FlowMetricsOrgInstallButtons } from './FlowMetrics';
import { MetricProviderIcons } from './MetricProviderIcons';
import { SourceControllMetricsOrgInstallButtons } from './SourceControlMetrics';
import { MetricsProviderCategory } from '@spoke/graphql';
import {
  FlexProps,
  Button,
  NO_OP,
  Icon,
  Spinner,
  Flex,
  HoverPlaceholder,
  Text,
} from '@spoke/common';

const LINKED_RESOURCE: Record<MetricsProviderCategory, string> = {
  [MetricsProviderCategory.ProjectManagement]: 'boards',
  [MetricsProviderCategory.SourceControl]: 'repositories',
  [MetricsProviderCategory.Scatterspoke]: '',
};

export type PendingMetricsPlaceholderProps = FlexProps & {
  providerCategory: MetricsProviderCategory;
  hoverVisible?: boolean;
  isInstalledOnOrg?: boolean;
  isInstalledOnTeam?: boolean;
  bgSrc: string;
  bgMaxW?: number;
  isEmpty?: boolean;
  isRateLimited?: boolean;
  isProcessing?: boolean;
  onPendingActionClick?: () => void;
};
export const PendingMetricsPlaceholder: FC<PendingMetricsPlaceholderProps> = ({
  isInstalledOnOrg,
  isInstalledOnTeam,
  hoverVisible,
  isEmpty,
  isProcessing,
  onPendingActionClick,
  bgSrc,
  providerCategory,
  isRateLimited,
  bgMaxW = 800,
  ...rest
}) => {
  const content = useMemo(() => {
    const isSourceControl =
      providerCategory === MetricsProviderCategory.SourceControl;
    const isProjectManagement =
      providerCategory === MetricsProviderCategory.ProjectManagement;

    if (!isInstalledOnOrg && isSourceControl) {
      return (
        <SourceControllMetricsOrgInstallButtons
          onInstallClick={onPendingActionClick}
        />
      );
    }

    if (!isInstalledOnOrg && isProjectManagement) {
      return (
        <FlowMetricsOrgInstallButtons onInstallClick={onPendingActionClick} />
      );
    }

    if (isRateLimited) {
      return (
        <Button
          bg="white"
          variant="outlineGray"
          size="md"
          onClick={onPendingActionClick || NO_OP}
        >
          <Icon
            as={AiOutlineWarning}
            mr={2}
            color="gray.700"
            w={5}
            h={5}
            borderRadius="sm"
          />
          Service unavailable{' '}
        </Button>
      );
    }

    if (!isInstalledOnTeam) {
      return (
        <Button
          bg="white"
          variant="outlineGray"
          size="md"
          onClick={onPendingActionClick || NO_OP}
        >
          Choose your {LINKED_RESOURCE[providerCategory]} to continue
        </Button>
      );
    }

    if (isProcessing) {
      return (
        <Button
          bg="white"
          variant="outlineGray"
          size="md"
          onClick={onPendingActionClick || NO_OP}
        >
          <Spinner
            size="xs"
            color="gray.700"
            thickness="2px"
            mr={2}
            speed="1.5s"
          />
          <Text as="span" pt="1px">
            Your data is being processed.
          </Text>
        </Button>
      );
    }

    if (isEmpty) {
      return (
        <Button
          bg="white"
          variant="outlineGray"
          size="md"
          d="block"
          h="60px"
          px={6}
          onClick={onPendingActionClick || NO_OP}
        >
          <Text as="span" d="block" mb="1px">
            Not enough data to show.
          </Text>
          <Text as="span" d="block">
            Try changing your{' '}
            <MetricProviderIcons
              providerCategory={providerCategory}
              show="installed-on-org"
            />{' '}
            linked {LINKED_RESOURCE[providerCategory]}.
          </Text>
        </Button>
      );
    }

    return null;
  }, [
    isEmpty,
    isInstalledOnOrg,
    isInstalledOnTeam,
    isProcessing,
    isRateLimited,
    onPendingActionClick,
    providerCategory,
  ]);

  return (
    <Flex flexDir="column" {...rest}>
      <HoverPlaceholder
        background={{
          src: bgSrc,
          maxW: bgMaxW,
          mb: 0,
          mt: 0,
        }}
        flex={1}
        pb={0}
        py={4}
        hoverVisible={hoverVisible}
      >
        {content}
      </HoverPlaceholder>
    </Flex>
  );
};
