import { FC } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import {
  findAllNodeTeamIds,
  getIconByTeamType,
  TeamSwitcherTreeNodeType,
} from './teamSwitcherUtil';
import { TeamType } from '@spoke/graphql';
import {
  VStack,
  HStack,
  ChevronRightIcon,
  ChevronDownIcon,
  DotIcon,
  NO_OP,
  Spinner,
  Spacer,
  Icon,
  elementsInCommon,
  Text,
} from '@spoke/common';

export type TeamSwitcherTreeNodeProps = {
  node: TeamSwitcherTreeNodeType;
  expandedTeamIds: string[];
  onExpandedChange: (teamId: string, expanding: boolean) => unknown;
  onSelect: (id: string) => unknown;
  loadingTeamId: string | null;
  selectedTeamId?: string | null;
  isTopLevel?: boolean;
  isPartOfWatchedTeamTree?: boolean;
};
export const TeamSwitcherTreeNode: FC<TeamSwitcherTreeNodeProps> = ({
  node,
  expandedTeamIds,
  onExpandedChange,
  selectedTeamId,
  onSelect,
  loadingTeamId,
  isTopLevel,
  isPartOfWatchedTeamTree,
}) => {
  const teamId = node.team.id as string;
  const isExpanded = expandedTeamIds.includes(teamId);
  const isSelected = selectedTeamId === teamId;
  const isLoading = loadingTeamId === teamId;
  const hasChildren = node.children && node.children.length > 0;

  const isNeitherWatcherOrMember = isPartOfWatchedTeamTree && !node.isWatcher;

  return (
    <VStack alignItems="flex-start" w="full" spacing={0}>
      <HStack
        spacing={1}
        w="full"
        borderRadius="sm"
        bg={isSelected ? 'primary.25' : 'transparent'}
        _hover={{ bg: isSelected ? 'primary.50' : 'gray.100' }}
        py={1}
        my="2px"
        pr={2}
      >
        {hasChildren &&
          (!isExpanded ? (
            <ChevronRightIcon
              onClick={() => onExpandedChange(teamId, true)}
              cursor="pointer"
              color="gray.500"
              w={6}
              h={6}
            />
          ) : (
            <ChevronDownIcon
              onClick={() => onExpandedChange(teamId, false)}
              cursor="pointer"
              color="gray.500"
              w={6}
              h={6}
            />
          ))}
        {!hasChildren && !isTopLevel && (
          <DotIcon color="gray.500" w={6} h={6} />
        )}

        <HStack
          cursor={isNeitherWatcherOrMember ? 'default' : 'pointer'}
          onClick={() =>
            isNeitherWatcherOrMember ? NO_OP : onSelect(teamId as string)
          }
          w="full"
        >
          {isLoading ? (
            <Spinner size="xs" style={{ marginRight: 12 }} />
          ) : (
            getIconByTeamType(node.team.type as TeamType)
          )}

          <Text
            fontSize={14}
            color={isSelected ? 'primary.500' : 'gray.600'}
            fontWeight={500}
            flex="8"
            pt="2px"
          >
            {node.team.name}
          </Text>

          {node.isWatcher && (
            <>
              <Spacer />
              <Icon as={AiOutlineEye} />
            </>
          )}
        </HStack>
      </HStack>
      {isExpanded && hasChildren ? (
        <VStack alignItems="stretch" w="full" pl={3} spacing={0}>
          {node.children?.map((childNode) => {
            /**
             * This only passes along the ids that the node cares about.
             * Helps with unecessary renders
             */
            const filteredExpandedIds = elementsInCommon(
              findAllNodeTeamIds(childNode),
              expandedTeamIds
            );

            return (
              childNode && (
                <TeamSwitcherTreeNode
                  key={childNode.team.id}
                  node={childNode}
                  expandedTeamIds={filteredExpandedIds}
                  onExpandedChange={onExpandedChange}
                  selectedTeamId={selectedTeamId}
                  onSelect={onSelect}
                  loadingTeamId={loadingTeamId}
                  isPartOfWatchedTeamTree={isPartOfWatchedTeamTree}
                />
              )
            );
          })}
        </VStack>
      ) : null}
    </VStack>
  );
};
