import {
  ComponentStyleConfig,
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const ModalComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      borderRadius: '2xl',
    },
  },
  variants: {
    unstyled: {
      dialog: {
        bg: 'transparent',
        boxShadow: 'none',
      },
    },
  },
};

export type ModalProps = ChakraModalProps;

export const Modal: FC<ModalProps> = (props) => <ChakraModal {...props} />;
