import { useEffect, useRef } from 'react';

/**
 * This is a helper to prevent doing things like
 * setting state on an unmounted component
 */
export const useIsMounted = (): [boolean] => {
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  return [mounted.current];
};
