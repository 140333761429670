import { FC, memo } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import {
  PopoverContent,
  PopoverArrow,
  Flex,
  Icon,
  Spacer,
  Divider,
  Button,
  Text,
} from '@spoke/common';

type BoardVoteCountPopoverProps = {
  isOpen: boolean;
  boardVoteCount: number;
  votedPercentage: string;
  resetBoardVotesLoading: boolean;
  handleResetAllVotes: () => void;
  handleUpdateBoardVoteCount: (newVoteCount: number) => void;
};

export const BoardVoteCountPopover: FC<BoardVoteCountPopoverProps> = ({
  boardVoteCount,
  handleResetAllVotes,
  handleUpdateBoardVoteCount,
  resetBoardVotesLoading,
  votedPercentage,
}) => (
  <PopoverContent maxW={270}>
    <PopoverArrow />
    <Flex direction="column" alignItems="flex-start" p={6} spacing={4}>
      <Text fontWeight={500} color="gray.700" fontSize={14}>
        Set vote count
      </Text>
      <Text color="gray.500" fontSize={14} mb={1}>
        Amount of votes per person
      </Text>
      <Flex layerStyle="outlineGray" py="10px" w="full" align="center">
        <Icon
          as={AiOutlineMinus}
          w={6}
          h={6}
          p={1}
          borderRadius="sm"
          transition="background-color 0.2s ease-out"
          cursor="pointer"
          _hover={{ bg: 'gray.200' }}
          onClick={() =>
            handleUpdateBoardVoteCount(Math.max(boardVoteCount - 1, 0))
          }
        />
        <Spacer />
        {boardVoteCount}
        <Spacer />
        <Icon
          as={AiOutlinePlus}
          w={6}
          h={6}
          p={1}
          borderRadius="sm"
          transition="background-color 0.2s ease-out"
          cursor="pointer"
          _hover={{ bg: 'gray.200' }}
          onClick={() => handleUpdateBoardVoteCount(boardVoteCount + 1)}
        />
      </Flex>
      <Divider my={4} />
      <Text mb={3} fontSize={14} color="gray.600">
        {votedPercentage}% voted
      </Text>
      <Button
        isLoading={resetBoardVotesLoading}
        onClick={handleResetAllVotes}
        mb={2}
        w="full"
      >
        Reset
      </Button>
      <Text fontSize={12} color="gray.500" whiteSpace="pre-wrap">
        This will permanently erase current vote data and set all vote counts
        back to 0.
      </Text>
    </Flex>
  </PopoverContent>
);

export const MemoizedBoardVoteCountPopover = memo(
  BoardVoteCountPopover,
  (prevProps, nextProps) => {
    // Saves resources when the popover is closed. It's not a great solution but it's working.
    // Previously this was rendering whenever someone voted because of votedPercentage prop
    const isClosedAndWillRemainClosed =
      prevProps.isOpen === false && nextProps.isOpen === false;
    return isClosedAndWillRemainClosed;
  }
);
