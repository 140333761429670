import { FC } from 'react';
import { PinInputProps } from '@chakra-ui/react';
import { HStack } from '../Stack';
import { PinInput } from '../PinInput';
import { PinInputField } from '../PinInputField';

type Props = Omit<PinInputProps, 'children'>;
export const CodeInput: FC<Props> = (props) => (
  <HStack>
    <PinInput placeholder="0" {...props}>
      <PinInputField />
      <PinInputField />
      <PinInputField />
      <PinInputField />
      <PinInputField />
    </PinInput>
  </HStack>
);
