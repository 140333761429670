import { FC } from 'react';
import { DistributionBarsBar } from './DistributionBarsBar';
import {
  FlexProps,
  Flex,
  HStack,
  Spacer,
  randomString,
  Circle,
  Text,
} from '@spoke/common';
import { ImprovementGoalUnit } from '@spoke/graphql';

// TODO add more
const COLORS = ['#5546B8', '#F4467C', '#FD7E13', '#4AC8E1'];

export type DistributionBarItem = {
  label: string;
  value: number;
};

const EMPTY_DATA: DistributionBarItem[] = [{ label: 'Unknown', value: 100 }];

type Props = FlexProps & {
  data: DistributionBarItem[];
  title?: string;
  description?: string;
  periodLabel?: string;
  hideLabels?: boolean;
  unit: ImprovementGoalUnit;
};
export const DistributionBars: FC<Props> = ({
  data,
  unit,
  title,
  description,
  periodLabel,
  hideLabels = false,
  ...rest
}) => {
  const total = data.reduce((acc, curr) => acc + curr.value, 0);
  const getPercentage = (value: number) =>
    `${((value / total) * 100).toFixed(0)}%`;

  const isEmpty = data.length === 0;

  return (
    <Flex flexDirection="column" gap={1} w="full" {...rest}>
      <HStack>
        <Flex flexDir="column">
          {title && (
            <Text fontSize={16} fontWeight={500} color="gray.700">
              {title}
            </Text>
          )}
          {description && (
            <Text fontSize={14} color="gray.500">
              {description}
            </Text>
          )}
        </Flex>
        <Spacer />
        {periodLabel && (
          <Text mr={2} fontSize={12} color="gray.500">
            {periodLabel}
          </Text>
        )}
      </HStack>
      <HStack spacing="3px" w="full">
        {(isEmpty ? EMPTY_DATA : data).map((dataPoint, idx, arr) => (
          <DistributionBarsBar
            key={dataPoint.label}
            label={dataPoint.label}
            value={dataPoint.value}
            isLast={idx === arr.length - 1}
            isFirst={idx === 0}
            unit={unit}
            isEmpty={isEmpty}
            color={COLORS[idx % COLORS.length]}
            formattedPercentage={getPercentage(dataPoint.value)}
          />
        ))}
      </HStack>
      {!hideLabels && (
        <Flex flexWrap="wrap" gap={1}>
          {data.map((dataPoint, idx) => {
            const label = dataPoint.label;
            const color = COLORS[idx % COLORS.length];

            return (
              <Flex
                key={dataPoint.label || randomString()}
                p={0}
                px={1}
                alignItems="center"
                borderColor="gray.300"
                whiteSpace="nowrap"
              >
                <Circle bg={color} size="8px" mr={1} mt="1px" />
                <Text pt="2px" fontSize={13} color="gray.500" fontWeight={500}>
                  {label}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      )}
    </Flex>
  );
};
