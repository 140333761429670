import { FC } from 'react';
import { Flex, FlexProps, MotionFlex } from './Flex';

type BlinkingDotProps = FlexProps & { size?: number; speed?: number };
export const BlinkingDot: FC<BlinkingDotProps> = ({
  color = 'green.500',
  size = 7,
  speed = 2,
  ...rest
}) => {
  const innerSize = `${size}px`;

  const BASE_STYLE = {
    minW: innerSize,
    minH: innerSize,
    maxW: innerSize,
    maxH: innerSize,
    borderRadius: '2xl',
    alignItems: 'center',
    justifyContent: 'center',
    bg: color,
  };

  return (
    <Flex {...BASE_STYLE} opacity={0.9} {...rest} position="relative">
      <MotionFlex
        animate={{
          scale: [1, 1.8, 1],
          translateY: ['-50%', '-50%', '-50%'],
          translateX: ['-50%', '-50%', '-50%'],
        }}
        transition={{
          repeat: Infinity,
          duration: speed,
          times: [0, 0.1, 1],
          ease: 'linear',
        }}
        // transform="translate(-50%, -50%) scale(2)"
        opacity={0.3}
        position="absolute"
        top="50%"
        left="50%"
        {...BASE_STYLE}
      />
    </Flex>
  );
};
