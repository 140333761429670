import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useActionItemsAnalytics } from '../queries';
import {
  useNow,
  ONE_DAY_MS,
  getActionItemFromCache,
  TOAST_ERROR_GENERIC,
  optimisticUpdateActionItem,
  NO_OP,
  log,
  useToast,
  DeepPartial,
} from '@spoke/common';
import {
  useUpdateActionItemMutation,
  ActionItemStatus,
  ActionItem,
} from '@spoke/graphql';

export const useToggleActionItemStatusHandler = () => {
  const [updateActionItem] = useUpdateActionItemMutation();
  const [toast] = useToast();
  const { cache } = useApolloClient();

  const now = useNow({ roundTo: 'ten-minutes' });

  const [__, actionItemAnalyticsQuery] = useActionItemsAnalytics({
    endDate: now,
    timeWindowLength: 90 * ONE_DAY_MS,
  });

  const handleDeleteActionItem = useCallback(
    async (actionItemId: string): Promise<boolean> => {
      const actionItemFromCache = getActionItemFromCache({
        actionItemId,
        cache,
      });

      if (!actionItemFromCache) {
        log.error(
          'Could not toggle action item status: Action item not found in cache',
          { actionItemId }
        );
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      log.info('Toggling action item status', {
        actionItemId,
      });

      const newStatus =
        actionItemFromCache.status === ActionItemStatus.Resolved
          ? ActionItemStatus.Unresolved
          : ActionItemStatus.Resolved;

      const newResolvedAt =
        newStatus === ActionItemStatus.Resolved ? Date.now() : null;

      const updateFields: DeepPartial<ActionItem> = {
        status: newStatus,
        resolvedAt: newResolvedAt,
      };

      const expected = optimisticUpdateActionItem({
        actionItemId,
        cache,
        fields: updateFields,
      });

      const { errors } = await updateActionItem({
        variables: {
          actionItemId,
          fields: updateFields,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateActionItem: expected,
        },
      });

      if (errors) {
        log.error(
          'updateActionItem (toggle status) call responded with errors',
          { errors, updateFields, actionItemId }
        );
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      actionItemAnalyticsQuery.refetch().catch(NO_OP);

      return true;
    },
    [actionItemAnalyticsQuery, cache, toast, updateActionItem]
  );

  return [handleDeleteActionItem];
};
