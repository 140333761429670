import { ChakraProps } from '@chakra-ui/react';
import {
  forwardRef,
  useState,
  useRef,
  useCallback,
  useEffect,
  ChangeEventHandler,
  useMemo,
  memo,
} from 'react';
import { MemoizedOriginalListBadge } from './OriginalListBadge';
import {
  clearDocumentFocus,
  callIfExists,
  parseCardText,
  isImage,
  handleCardImageError,
  SpokeTextArea,
  MotionFlex,
  useDisclosure,
  VStack,
  Box,
  Image,
  Text,
} from '@spoke/common';

type BoardCardBodyProps = ChakraProps & {
  cardId: string;
  text: string;
  badgeColor: string;
  badgeText: string;
  isParent?: boolean;
  isChild?: boolean;
  draggable?: boolean;
  votingEnabled?: boolean;
  onEdit: (cardId: string, newText: string) => void;
  onUpdateOriginalList?: (cardId: string, listId: string) => void;
};
export const CardBody = forwardRef<HTMLDivElement, BoardCardBodyProps>(
  (
    {
      cardId,
      text,
      badgeColor,
      badgeText,
      isParent,
      isChild,
      onEdit,
      onUpdateOriginalList,
      votingEnabled,
      ...rest
    },
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedText, setEditedText] = useState(text);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const ignoreNextBlur = useRef(false);

    const handleEditStart = useCallback(() => {
      if (isEditing) return;
      setIsEditing(true);
      const length = text.length;
      textAreaRef?.current?.setSelectionRange(length, length);
      textAreaRef?.current?.focus();
    }, [text, isEditing]);

    const handleEditCancel = useCallback(() => {
      if (ignoreNextBlur.current) {
        ignoreNextBlur.current = false;
        return;
      }
      setIsEditing(false);
      setEditedText(text);
      clearDocumentFocus();
    }, [text]);

    const handleEditEnd = useCallback(async () => {
      if (editedText === '') {
        handleEditCancel();
        return;
      }
      setIsEditing(false);
      onEdit(cardId, editedText);
      ignoreNextBlur.current = true;
      clearDocumentFocus();
    }, [cardId, editedText, onEdit, handleEditCancel]);

    useEffect(() => setEditedText(text), [text]);

    const handleInputChange: ChangeEventHandler<HTMLTextAreaElement> =
      useCallback((e) => {
        setEditedText(e.target.value);
      }, []);

    const handleUpdateOriginalList = useCallback(
      (listId: string) => {
        callIfExists(onUpdateOriginalList, cardId, listId);
      },
      [cardId, onUpdateOriginalList]
    );

    const imageUrl = parseCardText(text).find(isImage);

    const originalListMenu = useDisclosure();

    const isSingle = !isParent && !isChild;

    const paddingBottom = useMemo(() => {
      if (!isEditing && isSingle && !votingEnabled) return 4;
      if (!isEditing && isSingle && votingEnabled) return 2;
      if (!isEditing && !isSingle) return '15px';
      if (isEditing && isSingle) return 8;
      if (isEditing && !isSingle) return 8;
      return 0;
    }, [isEditing, isSingle, votingEnabled]);

    return (
      <Box
        w="full"
        bg="white"
        textAlign="center"
        pt={4}
        px={8}
        pb={paddingBottom}
        fontSize={13}
        color="gray.600"
        borderTopWidth="1px"
        borderColor="gray.200"
        borderStyle="solid"
        position="relative"
        ref={ref}
        transition="all 0.1s ease-out"
        display="flex"
        alignItems="center"
        minHeight="0px"
        {...rest}
      >
        <Box
          tabIndex={-1}
          top={2}
          left={2}
          position="absolute"
          cursor="pointer"
        >
          <MemoizedOriginalListBadge
            badgeColor={badgeColor}
            badgeText={badgeText}
            close={originalListMenu.close}
            isOpen={originalListMenu.isOpen}
            open={originalListMenu.open}
            handleUpdateOriginalList={handleUpdateOriginalList}
          />
        </Box>
        <VStack spacing={0} w="full">
          {imageUrl && (
            <Image src={imageUrl} alt="" onError={handleCardImageError} />
          )}
          {!imageUrl && (
            <Box onMouseUp={handleEditStart} w="full">
              <SpokeTextArea
                pointerEvents={isEditing ? 'all' : 'none'}
                autoFocus={isEditing}
                userSelect="none"
                cursor={isEditing ? 'text' : 'grab'}
                fontWeight={isParent ? 600 : 400}
                px={0}
                width="fit-content"
                mx="auto"
                value={editedText} // editedText instead of text to avoid UI flicker due to minor optimistic response delay
                maxH="unset"
                color="gray.600"
                ref={textAreaRef}
                onChange={handleInputChange}
                onSubmit={handleEditEnd}
                onBlur={handleEditCancel}
                onEscape={handleEditCancel}
                tabIndex={-1}
                outline="none"
                _focus={{ color: 'gray.600' }}
              />
            </Box>
          )}
          {isEditing && (
            <MotionFlex
              initial={{ bottom: 14, opacity: 0 }}
              animate={{ bottom: 16, opacity: 1 }}
              transition={{
                bounce: null,
                duration: 0.1,
                delay: 0.05,
              }}
              position="absolute"
              mx="auto"
            >
              <Text pt={2} fontSize={12} color="gray.500">
                <b>Enter</b> to commit changes
              </Text>
            </MotionFlex>
          )}
        </VStack>
      </Box>
    );
  }
);

CardBody.displayName = 'CardBody';
export const MemoizedCardBody = memo(CardBody);
