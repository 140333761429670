import { ChakraProps } from '@chakra-ui/react';
import { MotionProps, AnimatePresence, motion } from 'framer-motion';
import { FC, useState, useCallback, useRef, memo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  hasCommentsOpen,
  addToCardsWithOpenComments,
  removeFromCardsWithOpenComments,
  isDraggingVar,
} from '../../util';
import { MemoizedCardBody } from './BoardCardBody';
import { MemoizedBoardCardChildren } from './BoardCardChildren';
import { MemoizedBoardCardComments } from './BoardCardComments';
import { MemoizedBoardCardCommentsButton } from './BoardCardCommentsButton';
import { MemoizedBoardCardMenu } from './BoardCardMenu';
import { MemoizedBoardCardVoteControls } from './BoardCardVoteControls';
import { Card, Comment as CommentType } from '@spoke/graphql';
import {
  UpDown,
  callIfExists,
  useIsFirstRender,
  shallowEqualsExceptFor,
  hashCard,
  Flex,
  Divider,
  Center,
  Box,
  useIsLightweightMode,
  Text,
  isGroup as isGroupFn,
} from '@spoke/common';

const CREATE_GROUP_PROPS: MotionProps = {
  initial: { height: '0px', opacity: 0 },
  animate: { height: '70px', opacity: 1 },
  exit: { height: '0px', opacity: 0 },
  transition: {
    duration: 0.1,
    ease: 'easeOut',
    delay: 0.1,
  },
  style: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const CREATE_GROUP_LIGHTWEIGHT_PROPS: MotionProps = {
  style: {
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

type BoardCardProps = ChakraProps & {
  card: Card;
  boardId: string;
  currentUserId: string;
  index?: number;
  groupingEnabled: boolean;
  votingEnabled: boolean;
  hasVotesToSpend: boolean;
  isFacilitator: boolean;
  canPromote: boolean;
  onEdit: (baseCardId: string, editedCardId: string, newText: string) => void;
  onVote: (cardId: string, upOrDown: UpDown) => void;
  onAddComment: (cardId: string, text: string) => boolean | Promise<boolean>;
  onDeleteComment: (commentId: string) => boolean | Promise<boolean>;
  onDeleteCard: (cardId: string) => void;
  onDuplicate: (cardId: string) => void;
  onSaveAsActionItem: (cardId: string) => void;
  onSaveAsParkingLot: (cardId: string) => void;
  onUpdateOriginalList: (cardId: string, listId: string) => void;
  onPromote: (cardId: string) => void;
};
export const BoardCard: FC<BoardCardProps> = ({
  card,
  index = 0,
  onDuplicate,
  onEdit,
  groupingEnabled,
  votingEnabled,
  onAddComment,
  onVote,
  onDeleteComment,
  boardId,
  currentUserId,
  onDeleteCard,
  onUpdateOriginalList,
  isFacilitator,
  onSaveAsActionItem,
  hasVotesToSpend,
  onSaveAsParkingLot,
  onPromote,
  canPromote,
  ...rest
}) => {
  const [commentsOpen, _setCommentsOpen] = useState<boolean>(
    hasCommentsOpen(card.id)
  );

  const setCommentsOpen = useCallback(
    (newOpen) => {
      if (newOpen) addToCardsWithOpenComments(card.id);
      else removeFromCardsWithOpenComments(card.id);
      _setCommentsOpen(newOpen);
    },
    [card.id]
  );

  const toggleCommentsOpen = useCallback(
    () => setCommentsOpen(!commentsOpen),
    [setCommentsOpen, commentsOpen]
  );

  const onEditRootCard = useCallback(
    (cardId: string, text: string) => onEdit(cardId, cardId, text),
    [onEdit]
  );

  const onEditChildCard = useCallback(
    (childCardId: string, text: string) => onEdit(card.id, childCardId, text),
    [card.id, onEdit]
  );

  const handleSaveAsActionItem = useCallback(() => {
    callIfExists(onSaveAsActionItem, card.id);
  }, [card.id, onSaveAsActionItem]);

  const handleSaveAsParkingLot = useCallback(() => {
    callIfExists(onSaveAsParkingLot, card.id);
  }, [card.id, onSaveAsParkingLot]);

  const handleDuplicate = useCallback(() => {
    callIfExists(onDuplicate, card.id);
  }, [card.id, onDuplicate]);

  const handleDelete = useCallback(() => {
    callIfExists(onDeleteCard, card.id);
  }, [card.id, onDeleteCard]);

  const handlePromote = useCallback(() => {
    callIfExists(onPromote, card.id);
  }, [card.id, onPromote]);

  const isGroup: boolean = isGroupFn(card);

  const isGroupingTarget = useRef(false);

  const isLightweightMode = useIsLightweightMode();

  const isFirstRender = useIsFirstRender();

  return (
    <Draggable
      draggableId={card.id + `${isGroup ? ':group' : ''}`}
      key={card.id + `${isGroup ? ':group' : ''}`}
      index={index}
    >
      {(draggable, snapshot) => (
        <Flex
          flexDir="column"
          ref={draggable.innerRef}
          {...rest}
          {...draggable.draggableProps}
          {...draggable.dragHandleProps}
          draggable={isLightweightMode}
          borderRadius="lg"
          boxShadow="md"
          bg="gray.50"
          _hover={{
            '& .card-options': {
              opacity: 1,
            },
          }}
        >
          {/* This is to apply logic to this render-props info without repeating it all over */}
          {
            (isGroupingTarget.current = Boolean(
              groupingEnabled &&
                snapshot.combineTargetFor &&
                !snapshot.combineTargetFor.includes('group')
            ))
          }
          <Box minHeight={10} position={votingEnabled ? 'relative' : 'static'}>
            <Flex role="group" position={votingEnabled ? 'static' : 'relative'}>
              <MemoizedCardBody
                cardId={card.id}
                text={card.text}
                badgeColor={card.originalListBadge?.color || ''}
                badgeText={card.originalListBadge?.text || ''}
                isParent={isGroup}
                isChild={Boolean(card.parentId)}
                onEdit={onEditRootCard}
                onUpdateOriginalList={onUpdateOriginalList}
                votingEnabled={votingEnabled}
                borderRadius="lg"
              />
              <MemoizedBoardCardMenu
                onDeleteCard={handleDelete}
                onDuplicate={handleDuplicate}
                onSaveAsActionItem={handleSaveAsActionItem}
                onSaveAsParkingLot={handleSaveAsParkingLot}
                onPromote={handlePromote}
                promotedToPrograms={card?.promotedToPrograms}
                canPromote={canPromote}
                canDelete={card.authorId === currentUserId || isFacilitator}
              />

              <MemoizedBoardCardCommentsButton
                commentCount={card.comments?.length || 0}
                toggleCommentsOpen={toggleCommentsOpen}
                isGroup={isGroup}
                votingEnabled={votingEnabled}
              />
            </Flex>

            {isGroup && (
              <MemoizedBoardCardChildren
                listId={card.listId || ''}
                parentId={card.id}
                groupingEnabled={groupingEnabled}
                isGroupingTarget={isGroupingTarget.current}
                childCards={(card?.children as Card[]) || []}
                onEditChildCard={onEditChildCard}
              />
            )}

            {votingEnabled && (
              <>
                {isGroup && <Divider />}
                <Center bg="white" borderRadius="lg">
                  <MemoizedBoardCardVoteControls
                    cardId={card.id}
                    boardId={boardId}
                    currentUserId={currentUserId}
                    voteCount={card.votes?.length || 0}
                    hasVotesToSpend={hasVotesToSpend}
                    onVote={onVote}
                    containsVotesFromCurrentUser={card.votes.includes(
                      currentUserId
                    )}
                    mt={isGroup ? 3 : 0}
                  />
                </Center>
              </>
            )}

            {isDraggingVar() && (
              <AnimatePresence>
                {isGroupingTarget.current && (
                  <motion.div
                    {...(isLightweightMode
                      ? CREATE_GROUP_LIGHTWEIGHT_PROPS
                      : CREATE_GROUP_PROPS)}
                  >
                    <Text color="gray.400" fontSize={12}>
                      {isGroup ? 'Add card to group' : 'Create Group'}
                    </Text>
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </Box>
          <AnimatePresence>
            {commentsOpen && (
              <MemoizedBoardCardComments
                cardId={card.id}
                comments={(card.comments as CommentType[]) || []}
                onAddComment={onAddComment}
                onDeleteComment={onDeleteComment}
                skipEnterAnimation={isFirstRender}
                currentUserId={currentUserId}
              />
            )}
          </AnimatePresence>
        </Flex>
      )}
    </Draggable>
  );
};

export const MemoizedBoardCard = memo(BoardCard, (prevProps, nextProps) => {
  if (!shallowEqualsExceptFor(prevProps, nextProps, ['card'])) return false;

  const cardHasChanged = hashCard(prevProps.card) !== hashCard(nextProps.card);

  if (cardHasChanged) return false;
  return true;
});
