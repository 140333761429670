import { FC } from 'react';
import { Flex, FlexProps } from './Flex';
import { InfoTooltip } from './InfoTooltip';
import { Skeleton } from './Skeleton';
import { HStack } from './Stack/HStack';
import { Text } from './Text';

type Props = FlexProps & {
  isFirst?: boolean;
  isLast?: boolean;
  highlight?: boolean;
  label: string;
  infoTooltip?: string;
  value: string;
  loading?: boolean;
};
export const LifecycleBar: FC<Props> = ({
  infoTooltip,
  label,
  value,
  highlight,
  isFirst,
  isLast,
  loading,
  flex,
  ...rest
}) => {
  const barColor = loading
    ? 'gray.100'
    : highlight
    ? 'primary.400'
    : 'primary.100';

  return (
    <Flex
      flexDir="column"
      transition="flex 0.2s ease-out"
      flex={loading ? 1 : flex || 1}
      {...rest}
    >
      <HStack alignItems="center" spacing={1} h="22px" pr={2}>
        <Skeleton isLoaded={!loading} mb={1} mt={loading ? '6px' : 0}>
          <Text
            color="gray.600"
            fontSize={12}
            lineHeight={1}
            pt="2px"
            whiteSpace="nowrap"
          >
            {label}
          </Text>
        </Skeleton>
        {Boolean(infoTooltip && !loading) && (
          <InfoTooltip text={infoTooltip} maxW={175} />
        )}
      </HStack>
      <Flex w="full">
        {!isFirst && (
          <>
            <Flex width="2px" h="11px" bg="white" zIndex={1} ml="-2px" />
            <Flex
              width={0}
              height={0}
              borderTop="6px solid transparent"
              borderBottom="6px solid transparent"
              borderLeft="4px solid"
              borderLeftColor="white"
              zIndex={2}
              mr="-4px"
            />
          </>
        )}
        <Flex
          borderLeftRadius={isFirst ? 'sm' : 0}
          borderRightRadius={isLast ? 'sm' : 0}
          flex="1"
          h="11px"
          zIndex={0}
          bg={barColor}
        />
        {!isLast && (
          <Flex
            width={0}
            height={0}
            borderTop="6px solid transparent"
            borderBottom="6px solid transparent"
            borderLeft="4px solid"
            borderLeftColor={barColor}
            zIndex={3}
          />
        )}
      </Flex>
      <Skeleton
        mt={1}
        maxH={4}
        minW={loading ? 45 : undefined}
        isLoaded={!loading}
        w="fit-content"
      >
        <Text
          fontSize={14}
          color={highlight ? 'primary.500' : 'gray.700'}
          fontWeight={highlight ? 500 : 400}
        >
          {value || '0d'}
        </Text>
      </Skeleton>
    </Flex>
  );
};
