import { motion } from 'framer-motion';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useResendVerificationCodeHandler } from '../../queries';
import { useVerifyEmailMutation } from '@spoke/graphql';
import {
  INVALID_VERIFICATION_CODE,
  useDebounce,
  SpkHead,
  Card,
  VStack,
  Heading,
  Center,
  CodeInput,
  Button,
  log,
  Link,
  Image,
  Text,
  useAssets,
} from '@spoke/common';

const CODE_LENGTH = 5;

const messages = {
  required: 'Verification code is required',
  badFormat: 'Please enter a valid code.',
  rejected: INVALID_VERIFICATION_CODE,
};

type AuthVerifyEmailProps = {
  email: string;
  onSuccess: (code: string) => void;
  onChangeAccountEmail?: () => void;
  onLogout?: () => void;
};
export const AuthVerifyEmail: FC<AuthVerifyEmailProps> = ({
  email,
  onLogout,
  onSuccess,
  onChangeAccountEmail,
}) => {
  const [verifyEmail, { loading: isSubmitting }] = useVerifyEmailMutation();
  const [handleResendVerificationCode] = useResendVerificationCodeHandler();
  const onResendVerificationCode = useDebounce(async () => {
    await handleResendVerificationCode();
    setCode('');
    setError('');
  }, 3000);
  const { illustrationHello } = useAssets();

  const lastSubmitCode = useRef<string>();

  const [code, setCode] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = useCallback(async () => {
    if (code.length !== CODE_LENGTH) {
      setError(messages.badFormat);
      return;
    }

    log.info('Submitting verify email form', { code, email });

    const { data, errors } = await verifyEmail({
      variables: { email, verificationToken: code },
    });

    if (errors) {
      log.warn('verifyEmail mutation responded with error', { errors });
      setError(messages.rejected);
      return;
    }

    const emailLinkedToCode = data?.verifyEmail?.email;

    if (emailLinkedToCode !== email) {
      log.warn('User submitted a code that is linked to another email', {
        email,
        emailLinkedToCode,
      });
      setError(messages.rejected);
      return;
    }

    onSuccess(code);
  }, [code, email, onSuccess, verifyEmail]);

  const onCodeChange = async (value: string) => {
    setCode(value);
    setError('');
  };

  useEffect(() => {
    if (code.length !== CODE_LENGTH || code === lastSubmitCode.current) return;
    lastSubmitCode.current = code;
    handleSubmit();
  }, [handleSubmit, code, lastSubmitCode]);

  return (
    <motion.div
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{ duration: 0.15, ease: 'easeOut' }}
    >
      <SpkHead title="Confirm Email" />
      <Card gap={0} w={450} p={10}>
        <Image mb={4} src={illustrationHello} alt="Hello from ScatterSpoke" />
        <VStack mb={3}>
          <Heading size="md" fontWeight={500} color="gray.700">
            Verify your email
          </Heading>
          <Text fontSize="sm" color="gray.500" textAlign="center" px={5}>
            Enter the code that was sent to {email} below.
          </Text>
        </VStack>
        <VStack spacing={4} w="full">
          <Center>
            <CodeInput
              value={code}
              onChange={onCodeChange}
              isInvalid={!!error}
              size="lg"
              autoFocus
            />
          </Center>
          {error && (
            <Text
              textAlign="center"
              display="block"
              fontSize="xs"
              color="red.500"
            >
              {error}
            </Text>
          )}
          <Button w="full" isLoading={isSubmitting} onClick={handleSubmit}>
            Verify account
          </Button>
          <Text fontSize="xs" color="gray.500" w="full" textAlign="left">
            Didn&apos;t get the email? Check your spam folder or{' '}
            <Link onClick={onResendVerificationCode}>resend the code</Link>
          </Text>
          {onChangeAccountEmail && (
            <Text fontSize="xs" color="gray.500" w="full" textAlign="left">
              Is {email} not your email?{' '}
              <Link onClick={() => onChangeAccountEmail()}>Change email</Link>{' '}
              or <Link onClick={() => onLogout?.()}>logout</Link>
            </Text>
          )}
        </VStack>
      </Card>
    </motion.div>
  );
};
