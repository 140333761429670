import { FC, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import {
  ConfirmationModal,
  FormControl,
  Switch,
  FormLabel,
  Tooltip,
  Icon,
  useAssets,
} from '@spoke/common';

type EndRetroConfirmationModalProps = {
  onClose: () => unknown;
  onConfirm: (sendSummary: boolean) => unknown;
  isOpen: boolean;
};
export const EndRetroConfirmationModal: FC<EndRetroConfirmationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const [sendSummary, setSendSummary] = useState(false);
  const { illustrationCanvas } = useAssets();

  return (
    <ConfirmationModal
      close={onClose}
      isOpen={isOpen}
      image={illustrationCanvas}
      imageSize={250}
      title="Are you sure you want to end retrospective?"
      text="This will prevent all edits in the future. You can always re-open the retrospective."
      confirmText="Yes, end retrospective"
      cancelText="No, go back"
      onConfirm={() => onConfirm(sendSummary)}
    >
      <FormControl display="flex" alignItems="center" justifyContent="center">
        <Switch
          onChange={() => setSendSummary((send) => !send)}
          isChecked={sendSummary}
          id="set-send-summary"
          mr={2}
        />
        <FormLabel
          htmlFor="set-send-summary"
          mb="0"
          fontWeight={500}
          color="gray.700"
          fontSize={15}
        >
          Send summary to participants
        </FormLabel>
        <Tooltip
          label="This will send an email to each participant with a summary of the retrospective"
          variant="white"
          placement="top"
          textAlign="center"
          maxW={250}
          shouldWrapChildren
        >
          <Icon
            as={BiInfoCircle}
            color="gray.500"
            w={5}
            h={5}
            mt={2}
            ml="-4px"
          />
        </Tooltip>
      </FormControl>
    </ConfirmationModal>
  );
};
