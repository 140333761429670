import { QueryConfig } from '@spoke/common';
import { useBoardAnalyticsQuery } from '@spoke/graphql';
import { useCurrentTeam, useCurrentOrgTeam } from '@spoke/user';

type Config = QueryConfig<typeof useBoardAnalyticsQuery>;
type QueryRef = ReturnType<typeof useBoardAnalyticsQuery>;
type QueryData = NonNullable<QueryRef['data']>;

type Input = {
  endDate: Date;
  timeWindowLength: number;
  recursive: boolean;
};

export const useBoardAnalytics = ({
  endDate,
  timeWindowLength,
  recursive = true,
}: Input): [QueryData | null, QueryRef] => {
  const [currentTeam] = useCurrentTeam();
  const [currentOrgTeam] = useCurrentOrgTeam();

  const config: Config = {
    variables: {
      teamId: currentTeam?.id as string,
      organizationTeamId: currentOrgTeam?.id as string,
      endDate: endDate.getTime(),
      middleDate: new Date(endDate.getTime() - timeWindowLength).getTime(),
      startDate: new Date(endDate.getTime() - 2 * timeWindowLength).getTime(),
      recursive,
    },
    skip: !currentTeam?.id || !currentOrgTeam?.id,
  };

  const boardAnalyticsQuery = useBoardAnalyticsQuery(config);

  const data: QueryData | null =
    boardAnalyticsQuery.data ?? boardAnalyticsQuery.previousData ?? null;

  return [data, boardAnalyticsQuery];
};
