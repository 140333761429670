import {
  VStack as ChakraVStack,
  StackProps as ChakraVStackProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = ChakraVStackProps;

export const VStack = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ChakraVStack ref={ref} {...props} />
));
