import { useToken } from '@chakra-ui/react';
import { useEffect } from 'react';
import {
  NeueMachinaFont,
  Flex,
  SpkLink,
  Link,
  Text,
  Image,
  useRouter,
  useAssets,
} from '@spoke/common';

export const SomethingWentWrongScreen = () => {
  const [gray50, gray75] = useToken('colors', ['gray.50', 'gray.75']);
  const { illustrationTable } = useAssets();

  const router = useRouter();

  useEffect(() => {
    const reloadApp = () => (window.location.pathname = '');
    router.events.on('routeChangeStart', reloadApp);
    return () => router.events.off('routeChangeStart', reloadApp);
  }, [router.events]);

  return (
    <>
      <NeueMachinaFont />
      <Flex
        background={`linear-gradient(0deg, ${gray75} 0%, ${gray50} 100%)`}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        minH="100vh"
        position="relative"
      >
        <Image src={illustrationTable} alt="Page not found" w={450} />
        <Text
          mt={6}
          maxW={600}
          textAlign="center"
          fontFamily="neuemachina-ultrabold"
          fontSize={32}
          color="primary.500"
        >
          Oops... Something went wrong!
        </Text>
        <Text
          color="gray.700"
          fontSize={17}
          textAlign="center"
          maxW={500}
          mb={2}
        >
          Our engineers have been notified and are working on it.
        </Text>
        <SpkLink passHref href="/">
          <Link mb={10} fontSize={14} textDecoration="underline">
            Click here to go back
          </Link>
        </SpkLink>
        <Text
          position="absolute"
          bottom={8}
          mx="auto"
          mt={2}
          fontSize={12}
          color="gray.600"
          as="span"
          display="block"
        >
          If you need immediate help, you can always
          <Link textDecor="underline" href="mailto:support@scatterspoke.com">
            talk to us
          </Link>
          .
        </Text>
      </Flex>
    </>
  );
};
