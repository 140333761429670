import {
  Stack as ChakraStack,
  StackProps as ChakraStackProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = ChakraStackProps;

export const Stack = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ChakraStack ref={ref} {...props} />
));
