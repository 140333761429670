import { useCallback, useEffect, useRef } from 'react';
import { isNullish } from '../../util';

export const useDebounce = <T extends (...args: any[]) => any | (() => any)>(
  callback: T,
  delayMs: number,
  debounceFirstRun: boolean = false
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const clearTimeoutIfExists = useCallback(() => {
    if (!isNullish(timeoutRef.current)) clearTimeout(timeoutRef.current!);
  }, []);

  const debouncedFn = useCallback(
    (...args: Parameters<T>) => {
      const isFirstRun = isNullish(timeoutRef.current);
      clearTimeoutIfExists();
      timeoutRef.current = setTimeout(
        () => callback(...args),
        isFirstRun && !debounceFirstRun ? 0 : delayMs
      );
    },
    [callback, clearTimeoutIfExists, delayMs, debounceFirstRun]
  );

  useEffect(
    () => clearTimeoutIfExists, // Cleaning up on component unmount
    [clearTimeoutIfExists]
  );

  return debouncedFn;
};
