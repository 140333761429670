import { AnimatePresence } from 'framer-motion';
import { FC } from 'react';
import {
  useCurrentBoard,
  MotionFlex,
  NAV_BAR_HEIGHT,
  Spinner,
  Divider,
  Image,
  Text,
  useAssets,
} from '@spoke/common';
import { BoardStage } from '@spoke/graphql';

const TEXT_BY_STAGE: Record<BoardStage, string> = {
  [BoardStage.Reflect]:
    'Reflect on what happened to create a shared understanding',
  [BoardStage.Group]: 'Group similar topics together to identify patterns',
  [BoardStage.Vote]: 'Vote on the most important items to review together',
  [BoardStage.Discuss]: 'Discuss predominant items to decide what to do',
  [BoardStage.Finished]:
    'Great retrospective! Kick off your next retro by reviewing open action items',
  [BoardStage.FactsStats]: '',
};

export const BoardStageTransition: FC = () => {
  const [_board, _boardQuery, changingStageTo] = useCurrentBoard();
  const { illustrationCollaboration } = useAssets();

  return (
    <AnimatePresence>
      {changingStageTo && (
        <MotionFlex
          w="full"
          minH={`calc(100vh - ${NAV_BAR_HEIGHT})`}
          top={NAV_BAR_HEIGHT}
          left={0}
          position="absolute"
          alignItems="center"
          justifyContent="center"
          bg="gray.50"
          zIndex={5}
          flex="1"
          overflow="hidden"
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
        >
          <MotionFlex
            flexDir="column"
            alignItems="center"
            justifyItems="center"
            maxWidth={700}
            textAlign="center"
            animate={{ opacity: 1, transform: 'scale(1)' }}
            initial={{ opacity: 0, transform: 'scale(0)' }}
            exit={{ opacity: 0, transform: 'scale(0)' }}
          >
            <Image
              w={450}
              src={illustrationCollaboration}
              alt="Changing board stage"
              mb={5}
            />
            <Spinner
              h="45px"
              w="45px"
              speed="1s"
              thickness="5px"
              color="gray.500"
              mb={5}
            />
            <Text color="gray.500" fontSize={14} fontWeight={500}>
              Next phase
            </Text>
            <Text color="primary.500" fontSize={24} fontWeight={600} mb={4}>
              {TEXT_BY_STAGE[changingStageTo]}
            </Text>
            <Divider w="80%" mb={4} />
            <Text color="gray.500" fontSize={14} fontWeight={500} mb={1}>
              Tip of the day
            </Text>
            <Text color="gray.600" fontSize={16}>
              Rich Feedback is Great, Actionable Takeaways Are Better.
            </Text>
          </MotionFlex>
        </MotionFlex>
      )}
    </AnimatePresence>
  );
};
