import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentBoardId,
  TOAST_ERROR_GENERIC,
  getBoardFromCache,
  log,
} from '@spoke/common';
import { useFinishBoardMutation, BoardStage } from '@spoke/graphql';

export const useFinishBoardHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [boardId] = useCurrentBoardId();
  const [finishBoard] = useFinishBoardMutation();

  const handleFinishBoard = useCallback(
    async (sendSummary: boolean) => {
      if (!boardId) {
        log.error('Cannot finish board without loaded boardId', {
          boardId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const board = getBoardFromCache({ boardId, cache });

      if (!board?.state?.stage) {
        log.error(
          'Cannot check for already finished board (cached board not found). Finishing board anyway',
          { board }
        );
      }

      if (board?.state?.stage === BoardStage.Finished) {
        log.warn('No-op: finishing a board that is already finished', {
          boardId,
          board,
        });
        return;
      }

      log.info(`Finishing board ${boardId}`, { boardId, board });

      await finishBoard({
        variables: { boardId, sendSummary },
        optimisticResponse: {
          __typename: 'Mutation',
          finishBoard: {
            id: boardId,
            state: {
              stage: BoardStage.Finished,
            },
          },
        },
      });
    },
    [boardId, cache, toast, finishBoard]
  );

  return [handleFinishBoard];
};
