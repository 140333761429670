import { useState, useEffect, useCallback } from 'react';
import { useInstalledUserIntegrations } from './useInstalledUserIntegrations';
import { SpkHttp, isServer } from '@spoke/common';

export type JiraProject = {
  id: string;
  key: string;
  name: string;
};

type UseJiraIntegrationResult = {
  jiraProjects: JiraProject[] | null;
  authenticateJira: () => void;
  jiraLoading: boolean;
};
export const useJiraIntegration = (): UseJiraIntegrationResult => {
  const { jira, loading: integrationInstalledLoading } =
    useInstalledUserIntegrations();
  const [jiraProjects, setJiraProjects] =
    useState<UseJiraIntegrationResult['jiraProjects']>(null);
  const [jiraProjectsLoading, setJiraProjectsLoading] = useState(false);

  useEffect(() => {
    if (!jira) return;
    (async () => {
      setJiraProjectsLoading(true);
      const projectsRes = await SpkHttp.getJiraProjects();
      setJiraProjectsLoading(false);
      setJiraProjects(projectsRes.projects);
    })();
  }, [jira]);

  const authenticateJira = useCallback(() => {
    if (isServer()) return;
    const frontendRedirectAfterAuth = new URL(window.location.origin);
    frontendRedirectAfterAuth.pathname = '/integrations/jira/callback';
    const jiraAuthUrl = new URL(process.env.NEXT_PUBLIC_API_URL || '');
    jiraAuthUrl.pathname = '/integrations/jira/auth';
    const stateJson = JSON.stringify({
      redirectUri: frontendRedirectAfterAuth.toString(),
    });
    jiraAuthUrl.searchParams.append('state', stateJson);
    window?.open(jiraAuthUrl.toString(), '_blank')?.focus();
  }, []);

  const loading = integrationInstalledLoading || jiraProjectsLoading;

  return { jiraProjects, authenticateJira, jiraLoading: loading };
};
