import { useMemo } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import { FaChevronLeft } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';
import { MdOutlineBookmarkBorder } from 'react-icons/md';
import { useCurrentOrg } from '@spoke/user';
import {
  useCurrentUser,
  HStack,
  NAV_BAR_HEIGHT,
  Flex,
  Icon,
  SpkLink,
  Skeleton,
  Button,
  Spinner,
  Badge,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Link,
  Image,
  Text,
  useRouter,
  useAssets,
  useRoutes,
} from '@spoke/common';
import { useActionItems } from '@spoke/action-item';

export const OrgNav = () => {
  const router = useRouter();
  const routes = useRoutes();

  const [currentUser] = useCurrentUser();
  const [currentOrg] = useCurrentOrg();
  const [{ unresolved: actionItems }] = useActionItems();

  const { logoIcon } = useAssets();

  const parkingLotItemsCount = actionItems?.length ?? null;

  const teamId = useMemo(() => currentUser?.team?.id, [currentUser]);

  const loading = !currentUser || !teamId || !currentOrg || !actionItems;

  return (
    <HStack
      w="full"
      h={NAV_BAR_HEIGHT}
      px={6}
      bg="white"
      justifyContent="space-between"
      borderBottomWidth={1}
      borderColor="gray.100"
    >
      <Flex alignItems="center">
        <Icon
          color="primary.500"
          as={FaChevronLeft}
          h={5}
          w={5}
          ml={2}
          cursor="pointer"
          onClick={() => router.push(routes.Dashboard)}
        />
        <SpkLink href={routes.Dashboard} passHref>
          <Link>
            <Image src={logoIcon} alt="ScatterSpoke Logo" w={10} />
          </Link>
        </SpkLink>
        <Skeleton isLoaded={Boolean(currentOrg)} ml={3}>
          <Text
            color="primary.500"
            fontFamily="museo-sans-rd-700"
            fontSize={20}
          >
            {currentOrg?.name}
          </Text>
        </Skeleton>
      </Flex>
      <HStack>
        <Button
          variant="outlineGray"
          leftIcon={<MdOutlineBookmarkBorder size={20} />}
          rightIcon={
            loading ? (
              <Spinner w={4} h={4} />
            ) : (
              <Badge colorScheme="gray" mb="-2px">
                {parkingLotItemsCount}
              </Badge>
            )
          }
        >
          Action items
        </Button>
        <Menu>
          <MenuButton as="div" style={{ cursor: 'pointer' }}>
            <Button px={0} variant="outlineGray">
              <Icon as={BsThreeDots} />
            </Button>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => router.push(routes.Logout)}>
              <Icon as={FiLogOut} mr={2} color="gray.600" w={4} h={4} />
              <Text color="gray.700" fontWeight={500}>
                Logout
              </Text>
            </MenuItem>
          </MenuList>
        </Menu>
      </HStack>
    </HStack>
  );
};
