import { FC, ReactNode, useEffect, memo } from 'react';
import {
  DraggingStyle,
  NotDraggingStyle,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import { isDraggingAGroupCardVar } from '../../util';
import { MemoizedCardBody } from './BoardCardBody';
import { SPOKE_GROUP_CHILD_DND_PORTAL_ELEMENT_ID } from './DndPortal';
import { Card } from '@spoke/graphql';
import {
  isServer,
  useForceRender,
  shallowEqualsExceptFor,
  hashCards,
  useIsLightweightMode,
  Box,
  Flex,
} from '@spoke/common';

const portalElement = isServer()
  ? null
  : document.getElementById(SPOKE_GROUP_CHILD_DND_PORTAL_ELEMENT_ID);

const CHILD_CARD_STYLE_ON_HOVER = {
  borderWidth: '1px',
  borderRadius: '4px',
  boxShadow: 'sm',
};

type BoardCardChildrenProps = {
  listId: string;
  parentId: string;
  groupingEnabled: boolean;
  isGroupingTarget: boolean;
  childCards: Card[];
  onEditChildCard: (cardId: string, newText: string) => void;
};
export const BoardCardChildren: FC<BoardCardChildrenProps> = ({
  listId,
  parentId,
  groupingEnabled,
  isGroupingTarget,
  childCards,
  onEditChildCard,
}) => {
  const forceRender = useForceRender();
  const isLightweightMode = useIsLightweightMode();

  /**
   * Generally speaking calling reactive vars like this inside components can cause
   * unexpected behavior (it will only be updated as side effect of another render,
   * updates on itself will not rerender). But in this case we need it to gain performance
   */
  const shouldShowCreateGroupOnHover =
    !isDraggingAGroupCardVar() && groupingEnabled;

  /**
   * Part of a hack to fix a positioning issue with nested draggables
   * https://github.com/atlassian/react-beautiful-dnd/issues/128#issuecomment-347212054
   */
  const portalIfDragging = (
    styles: DraggingStyle | NotDraggingStyle | undefined,
    element: ReactNode
  ) => {
    const isBeingDragged = (styles as DraggingStyle)?.position === 'fixed';
    if (isBeingDragged && portalElement && !isLightweightMode) {
      return createPortal(element, portalElement);
    }
    return element;
  };

  /**
   * This is yet another hack to work around a race condition between when this component
   * renders after a list change vs. when we update the global isDraggingAGroupCardVar.
   * Not doing this would make this component keep dragging disabled until
   * we rerendered it by other means
   */
  useEffect(() => {
    const timeout = setTimeout(() => forceRender(), 100);
    return () => clearTimeout(timeout);
  }, [listId, forceRender]);

  return (
    <Droppable
      droppableId={`${listId}:childrenOf:${parentId}`}
      key={`${listId}:childrenOf:${parentId}`}
      isDropDisabled
      isCombineEnabled={false}
    >
      {(groupDroppable) => (
        <Flex
          /* This forceRender updates "isDraggingAGroupCard", but only for groups that are hovered...
             It's too expensive to repaint all groups in the board at the same time when that changes */
          onMouseEnter={forceRender}
          onFocus={forceRender}
          ref={groupDroppable.innerRef}
          transition="all 0.2s ease-out"
          flexDir="column"
          borderRadius="lg"
          bg="white"
          _hover={
            shouldShowCreateGroupOnHover && isGroupingTarget
              ? { px: 3, py: 1, gap: '4px' }
              : {}
          }
          {...groupDroppable.droppableProps}
        >
          {childCards.map(
            (childCard, idx) =>
              childCard && (
                <Draggable
                  key={`${childCard.id}:childOf:${parentId}`}
                  draggableId={`${childCard.id}:childOf:${parentId}`}
                  isDragDisabled={!shouldShowCreateGroupOnHover}
                  index={idx}
                >
                  {(groupDraggable) => (
                    <>
                      {portalIfDragging(
                        groupDraggable.draggableProps.style,
                        <MemoizedCardBody
                          cardId={childCard.id}
                          badgeColor={childCard.originalListBadge?.color || ''}
                          badgeText={childCard.originalListBadge?.text || ''}
                          text={childCard.text}
                          onEdit={onEditChildCard}
                          transition="all 0.2s ease-out"
                          borderWidth="1px 0 0 0"
                          borderTopWidth="1px"
                          isChild={true}
                          ref={groupDraggable.innerRef}
                          {...groupDraggable.draggableProps}
                          {...groupDraggable.dragHandleProps}
                          _hover={
                            shouldShowCreateGroupOnHover && isGroupingTarget
                              ? CHILD_CARD_STYLE_ON_HOVER
                              : undefined
                          }
                          draggable={isLightweightMode}
                          borderRadius="lg"
                        />
                      )}
                    </>
                  )}
                </Draggable>
              )
          )}
          <Box display="none">{groupDroppable.placeholder}</Box>
        </Flex>
      )}
    </Droppable>
  );
};

export const MemoizedBoardCardChildren = memo(
  BoardCardChildren,
  (prevProps, nextProps) => {
    if (!shallowEqualsExceptFor(prevProps, nextProps, ['childCards'])) {
      return false;
    }

    const cardsHaveChanged =
      hashCards(prevProps.childCards) !== hashCards(nextProps.childCards);

    if (cardsHaveChanged) return false;
    return true;
  }
);
