import { useEffect } from 'react';
import { useInstalledOrgIntegrations } from './useInstalledOrgIntegrations';
import {
  useBitbucketRateLimitLock,
  isExternalRateLimitError,
  log,
} from '@spoke/common';
import { useLinkedBitbucketRepositoriesQuery } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type QueryRef = ReturnType<typeof useLinkedBitbucketRepositoriesQuery>;
type QueryData = NonNullable<
  NonNullable<QueryRef['data']>['team']
>['linkedBitbucketRepositories'];

export const useCurrentTeamBitbucketRepositories = (): [
  QueryData | null,
  QueryRef
] => {
  const [_, fireBitbucketRateLimitLock] = useBitbucketRateLimitLock();
  const { bitbucket: installedOnOrg } = useInstalledOrgIntegrations();
  const [team] = useCurrentTeam();

  const reposQuery = useLinkedBitbucketRepositoriesQuery({
    skip: !team?.id || !installedOnOrg,
    variables: { teamId: team?.id || '' },
    // https://scatterspoke.atlassian.net/browse/SC-594
    errorPolicy: 'none',
  });

  useEffect(() => {
    if (!reposQuery.error) return;
    const hasRateLimitError = reposQuery.error?.graphQLErrors.some(
      isExternalRateLimitError
    );
    if (hasRateLimitError) {
      log.error('Bitbucket linkedRepositories responded with rate limited', {
        error: reposQuery.error,
      });
      fireBitbucketRateLimitLock();
    }
  }, [fireBitbucketRateLimitLock, reposQuery.error]);

  const repos =
    reposQuery.data?.team?.linkedBitbucketRepositories ??
    reposQuery.previousData?.team?.linkedBitbucketRepositories ??
    null;

  return [repos, reposQuery];
};
