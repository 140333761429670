import { FC, useState, useMemo } from 'react';
import {
  csvMapToImportGoalDataInput,
  useImportImprovementGoalDataHandler,
} from '../../handlers';
import {
  ImportDataFieldMapping,
  ImportDataWizardFieldMapping,
} from './ImportDataWizardFieldMapping';
import { ImportDataWizardSummary } from './ImportDataWizardSummary';
import { ImportDataWizardUpload } from './ImportDataWizardUpload';
import { ModalProps, Modal, ModalOverlay, log } from '@spoke/common';

type ImportDataWizardModalProps = Omit<ModalProps, 'children'> & {
  improvementGoalId: string;
};

const ImportDataWizardModalContents: FC<ImportDataWizardModalProps> = (
  props
) => {
  const [handleImportData] = useImportImprovementGoalDataHandler();
  const [data, setData] = useState<Record<string, string>[] | null>(null);
  const [fieldMapping, setFieldMapping] =
    useState<ImportDataFieldMapping | null>(null);

  const parsedData = useMemo(
    () =>
      !data || !fieldMapping
        ? null
        : csvMapToImportGoalDataInput(data, fieldMapping),
    [data, fieldMapping]
  );

  const onImportBoard = async () => {
    if (!parsedData) {
      log.error(
        'No-op: tried importing improvement goal data without parsed data',
        {
          parsedData,
        }
      );
      return;
    }
    log.info('Importing improvement goal data', { parsedData });
    await handleImportData(props.improvementGoalId, parsedData);
    props.onClose();
  };

  if (!data) {
    return (
      <ImportDataWizardUpload onClose={props.onClose} onSubmit={setData} />
    );
  }

  if (!fieldMapping || !parsedData) {
    return (
      <ImportDataWizardFieldMapping
        data={data}
        onCancel={() => setData(null)}
        onSubmit={setFieldMapping}
      />
    );
  }

  return (
    <ImportDataWizardSummary
      parsedData={parsedData}
      onCancel={() => setFieldMapping(null)}
      onConfirm={onImportBoard}
    />
  );
};

export const ImportDataWizardModal: FC<ImportDataWizardModalProps> = (
  props
) => (
  <Modal {...props} isOpen={props.isOpen}>
    <ModalOverlay />
    <ImportDataWizardModalContents {...props} />
  </Modal>
);
