import { Component, FC, PropsWithChildren } from 'react';
import { log } from '../../SpkLog';

export type ErrorBoundaryFallbackComponentProps = {
  onDismissError?: () => void;
};

type ErrorBoundaryProps = {
  fallbackComponent: FC<ErrorBoundaryFallbackComponentProps>;
};

/**
 * Copypasta from https://nextjs.org/docs/advanced-features/error-handling
 */
export class ErrorBoundary extends Component {
  public override state: { hasError: boolean };

  public override props: PropsWithChildren<ErrorBoundaryProps>;

  constructor(props: PropsWithChildren<ErrorBoundaryProps>) {
    super(props);
    this.props = props;
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error) {
    return { hasError: true };
  }

  override componentDidCatch(error: Error, errorInfo: unknown) {
    log.error(`An error has hit ErrorBoundary.`, { error, errorInfo });
  }

  override render() {
    if (this.state.hasError) {
      const FallbackComponent = this.props.fallbackComponent;
      return (
        <FallbackComponent
          onDismissError={() => this.setState({ hasError: false })}
        />
      );
    }

    return this.props.children;
  }
}
