import { BoardStage } from '@spoke/graphql';

export const BOARD_STAGES_ORDER: BoardStage[] = [
  BoardStage.Reflect,
  BoardStage.Group,
  BoardStage.Vote,
  BoardStage.Discuss,
  BoardStage.Finished,
];

export const BOARD_STAGE_DISPLAY_NAMES: Record<BoardStage, string> = {
  [BoardStage.Reflect]: 'Reflect',
  [BoardStage.Group]: 'Group',
  [BoardStage.Vote]: 'Vote',
  [BoardStage.Discuss]: 'Discuss',
  [BoardStage.Finished]: 'Finished',
  [BoardStage.FactsStats]: '',
};
