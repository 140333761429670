import { useCallback } from 'react';
import { useCurrentUser, log } from '@spoke/common';
import { useUpdateUserMutation } from '@spoke/graphql';

export const useSwitchTeamHandler = () => {
  const [currentUser] = useCurrentUser();
  const [updateUserMutation] = useUpdateUserMutation();
  const userId = currentUser?.id;

  const handleSwitchTeam = useCallback(
    async ({ teamId }: { teamId: string }): Promise<boolean> => {
      if (!userId) {
        log.error('Cannot switch team without loaded current userId', {
          userId,
        });
        return false;
      }

      log.info(`Changing currentTeam to [${teamId}]`);
      const { errors } = await updateUserMutation({
        variables: { userInput: { currentTeamId: teamId } },
      });

      if (errors) {
        log.warn('Handled currentTeam change GraphQL error', errors);
        return false;
      }

      return true;
    },
    [updateUserMutation, userId]
  );

  return [handleSwitchTeam];
};
