import { useCallback } from 'react';
import {
  useMarkDiscussReadyHandler,
  useFinishBoardHandler,
  useSelectDiscussCardHandler,
  useChangeBoardDiscussSortingKeyHandler,
  useDeleteCardCommentHandler,
  useAddCardCommentHandler,
} from '../../handlers';
import { useCurrentBoardRules } from '../../queries/useCurrentBoardRules';
import { EndRetroConfirmationModal } from '../EndRetroConfirmationModal';
import { NoCardsMessage } from '../NoCardsMessage';
import { MemoizedDiscussBoardList } from './DiscussBoardList';
import { DiscussBoardSpotlight } from './DiscussBoardSpotlight';
import {
  BoardDiscussionCardsSortingKey,
  CardDiscussionMovement,
  Card,
} from '@spoke/graphql';
import {
  useCurrentBoard,
  useDisclosure,
  Flex,
  HStack,
  log,
  NAV_BAR_HEIGHT,
  useCurrentUser,
} from '@spoke/common';

export const DiscussBoard = () => {
  const [board] = useCurrentBoard();
  const [currentUser] = useCurrentUser();
  const { isFacilitator } = useCurrentBoardRules();

  const cardList = board?.cardsOnDiscussion;
  const selectedCard = board?.cardBeingDiscussed;
  const sortByTags = board?.state.discussSortByTags ?? false;
  const sortKey =
    board?.state.discussionCardsSortingKey ||
    BoardDiscussionCardsSortingKey.Votes;

  const [handleMarkDiscussReady] = useMarkDiscussReadyHandler();
  const [handleFinishBoard] = useFinishBoardHandler();
  const [handleSelectDiscussCard] = useSelectDiscussCardHandler();
  const [handleChangeDiscussSortKey] = useChangeBoardDiscussSortingKeyHandler();
  const [handleDeleteComment] = useDeleteCardCommentHandler();
  const [handleAddComment] = useAddCardCommentHandler({
    boardId: board?.id || '',
    teamId: board?.teamId || '',
  });

  const endRetroConfirmationModal = useDisclosure();

  const handleSelectNextCard = useCallback(() => {
    const currentCardId = selectedCard?.id;

    if (!cardList) {
      log.error(
        'Cannot select next disscussion card without loaded discussion cardList',
        { cardList, selectedCard }
      );
      return;
    }

    const notDiscussedCards = cardList.filter((card) => !card?.wasDiscussed);

    const currentCardIdx = notDiscussedCards.findIndex(
      (card) => card?.id === currentCardId
    );

    if (currentCardIdx === -1) {
      log.error(`Could not find current discussed card in discussion list`, {
        cardList,
        selectedCard,
      });
      return;
    }

    const nextCardId = notDiscussedCards[currentCardIdx + 1]?.id;

    if (!nextCardId) {
      log.warn('No-op: attempted to move to next card when in last card', {
        cardList,
        selectedCard,
      });
      return;
    }
    handleSelectDiscussCard(nextCardId, CardDiscussionMovement.ToDiscussed);
  }, [cardList, handleSelectDiscussCard, selectedCard]);

  const isLastCard = cardList?.[cardList.length - 1]?.id === selectedCard?.id;

  const cardCount = board?.lists?.reduce(
    (acc, list) => acc + (list?.cards?.length || 0),
    0
  );

  const showNoCardsMessage = Boolean(cardCount === 0);

  return (
    <Flex
      w="full"
      h="fit-content"
      align="stretch"
      justifyContent="flex-start"
      minH={`calc(100vh - ${NAV_BAR_HEIGHT})`}
      gap={20}
    >
      {showNoCardsMessage && <NoCardsMessage />}
      {!showNoCardsMessage && (
        <MemoizedDiscussBoardList
          cards={(cardList as Card[]) || []}
          selectedCard={selectedCard as Card}
          sortKey={sortKey}
          sortByTags={sortByTags}
          onSortKeyChange={isFacilitator ? handleChangeDiscussSortKey : null}
          onSelectDiscussCard={isFacilitator ? handleSelectDiscussCard : null}
        />
      )}
      {!showNoCardsMessage && selectedCard && (
        <HStack flex="1" py={3} justifyContent="center">
          <DiscussBoardSpotlight
            card={selectedCard as Card}
            onAddComment={(text) => handleAddComment(selectedCard.id, text)}
            onDeleteComment={handleDeleteComment}
            onlineUserCount={board?.onlineUserIds.length || 0}
            readyUserCount={board?.discussReadyUserIds.length || 0}
            onSelectNextCard={isFacilitator ? handleSelectNextCard : null}
            onReadyChange={handleMarkDiscussReady}
            onEndRetro={isFacilitator ? endRetroConfirmationModal.open : null}
            isLast={isLastCard}
            currentUserId={currentUser?.id || ''}
            isReady={Boolean(
              currentUser?.id &&
                board?.discussReadyUserIds.includes(currentUser.id)
            )}
            onMoveBackToDiscussion={() =>
              handleSelectDiscussCard(
                selectedCard.id,
                CardDiscussionMovement.ToDiscussion
              )
            }
          />
        </HStack>
      )}
      <EndRetroConfirmationModal
        isOpen={endRetroConfirmationModal.isOpen}
        onClose={endRetroConfirmationModal.close}
        onConfirm={(sendSummary: boolean) => {
          endRetroConfirmationModal.close();
          handleFinishBoard(sendSummary);
        }}
      />
    </Flex>
  );
};
