import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import { useDiscardLivePollMutation } from '@spoke/graphql';

export const useDiscardLivePollHandler = () => {
  const [discardLivePoll] = useDiscardLivePollMutation();
  const [toast] = useToast();

  const handleDiscardLivePoll = useCallback(
    async ({ pollId }: { pollId: string }): Promise<boolean> => {
      if (!pollId) {
        log.error('Missing critical data to discardLivePoll', {
          pollId,
        });
        return false;
      }

      log.info('Discarding LivePoll', { pollId });

      const { errors } = await discardLivePoll({
        variables: { livePollId: pollId },
      });

      if (errors) {
        log.error('discardLivePoll call responded with errors', {
          pollId,
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      toast({
        status: 'success',
        title: 'Success',
        description: 'Poll results discarded successfully',
      });

      return true;
    },
    [discardLivePoll, toast]
  );

  return [handleDiscardLivePoll];
};
