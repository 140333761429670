import { FC, memo } from 'react';
import { MdOutlineChatBubbleOutline } from 'react-icons/md';
import { Tooltip, HStack, Icon, Text } from '@spoke/common';

type BoardCardCommentsButtonProps = {
  toggleCommentsOpen: () => void;
  commentCount: number;
  isGroup: boolean;
  votingEnabled: boolean;
};
export const BoardCardCommentsButton: FC<BoardCardCommentsButtonProps> = ({
  commentCount,
  toggleCommentsOpen,
  isGroup,
  votingEnabled,
}) => (
  <Tooltip label="Comments" variant="white" placement="left" openDelay={300}>
    <HStack
      onClick={toggleCommentsOpen}
      className="card-options"
      opacity={commentCount ? 1 : 0}
      transition="opacity 0.1s ease-out"
      spacing={1}
      position="absolute"
      bottom={isGroup && votingEnabled ? '17px' : 3}
      right={3}
      cursor="pointer"
      _hover={{
        '& .comments-icon': {
          color: 'gray.900',
        },
      }}
    >
      <Text
        position="absolute"
        bottom="1px"
        right={(commentCount as number) > 9 ? '4px' : '6px'}
        fontSize={7}
        lineHeight="14px"
        color="gray.500"
        textAlign="center"
        transition="color 0.1s ease-out"
        className="comments-icon"
      >
        {commentCount ?? 0}
      </Text>

      <Icon
        as={MdOutlineChatBubbleOutline}
        color="gray.400"
        w="16px"
        h="16px"
        transition="color 0.1s ease-out"
        className="comments-icon"
      />
    </HStack>
  </Tooltip>
);

export const MemoizedBoardCardCommentsButton = memo(BoardCardCommentsButton);
