import { StyleProps } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import {
  ButtonProps,
  useIsFirstRender,
  Button,
  MotionFlex,
  Badge,
  Box,
  Tooltip,
} from '@spoke/common';

type DashboardSidebarItemProps = ButtonProps & {
  icon: ReactElement;
  text: string;
  tooltipText?: string;
  isSelected?: boolean;
  isCollapsed?: boolean;
  badge?: ReactElement | string | number | null;
  onClick?: () => void;
};

const HOVER_STYLE: StyleProps = {
  bg: 'gray.100',
};

const ACTIVE_HOVER_STYLE: StyleProps = {};

const ACTIVE_STYLE: StyleProps = {
  color: 'primary.500',
};

const _SELECTED_ITEM_BACKGROUND_LAYOUT_ID =
  'SELECTED_ITEM_BACKGROUND_LAYOUT_ID';

export const DashboardSidebarItem: FC<DashboardSidebarItemProps> = ({
  isSelected,
  onClick,
  icon,
  text,
  children,
  isCollapsed,
  tooltipText,
  badge,
  ...rest
}) => {
  const isFirstRender = useIsFirstRender();

  return (
    <Tooltip
      variant="white"
      placement="right"
      label={isCollapsed ? tooltipText ?? text : ''}
    >
      <Button
        onClick={onClick}
        p={0}
        pl="10px"
        pt="3px"
        h="42px"
        w="full"
        fontSize={14}
        display="flex"
        leftIcon={icon}
        textAlign="left"
        fontWeight={500}
        color="gray.600"
        variant="unstyled"
        alignItems="center"
        _hover={isSelected ? ACTIVE_HOVER_STYLE : HOVER_STYLE}
        isActive={isSelected}
        _active={ACTIVE_STYLE}
        justifyContent="flex-start"
        transition=" box-shadow 0.1s ease-out"
        position="relative"
        {...rest}
      >
        {isSelected && (
          <MotionFlex
            // Not sure it's a nice effect
            // layoutId={_SELECTED_ITEM_BACKGROUND_LAYOUT_ID}
            initial={{ opacity: 0, translateX: -100 }}
            animate={{ opacity: 1, translateX: 0 }}
            transition={{
              duration: isFirstRender ? 0.5 : 0,
              ease: 'easeOut',
            }}
            boxShadow="extralight"
            position="absolute"
            minW="full"
            minH="full"
            bg="primary.50"
            borderRadius="md"
            zIndex={-1}
            left={0}
            top={0}
            pointerEvents="none"
          />
        )}
        <Box
          as="span"
          opacity={isCollapsed ? 0 : 1}
          transition="opacity 0.1s ease-out"
          mr={1}
        >
          {text}
        </Box>
        {typeof badge === 'object' && badge}
        {Boolean(typeof badge === 'string' || typeof badge === 'number') && (
          <Badge colorScheme="gray" color="gray.500">
            {badge}
          </Badge>
        )}
        {children}
      </Button>
    </Tooltip>
  );
};
