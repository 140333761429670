import { FC, useState, useEffect } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import {
  SelectOption,
  FlexProps,
  useCurrentUser,
  useDisclosure,
  truncateWithCount,
  Flex,
  FormControl,
  FormLabel,
  MultiSelect,
  Button,
  Spacer,
  Icon,
  Select,
  FormErrorMessage,
  TimeInput,
  log,
  Text,
  Option,
} from '@spoke/common';
import {
  ImprovementGoalUserReminderFrequency,
  MinimalUserFragment,
} from '@spoke/graphql';
import { useFetchTeamMembers, useFetchUsersByIds } from '@spoke/user';

const FREQUENCY_INFO: Record<ImprovementGoalUserReminderFrequency, string> = {
  [ImprovementGoalUserReminderFrequency.Daily]: '',
  [ImprovementGoalUserReminderFrequency.TwiceWeek]:
    'Every Tuesday and Thursday',
  [ImprovementGoalUserReminderFrequency.Weekly]: 'Every Monday',
  [ImprovementGoalUserReminderFrequency.Monthly]: 'Every 1st of the month',
};

const FREQUENCY_OPTIONS: SelectOption[] = [
  { label: 'Daily', value: ImprovementGoalUserReminderFrequency.Daily },
  {
    label: 'Twice a week',
    value: ImprovementGoalUserReminderFrequency.TwiceWeek,
  },
  { label: 'Weekly', value: ImprovementGoalUserReminderFrequency.Weekly },
  { label: 'Monthly', value: ImprovementGoalUserReminderFrequency.Monthly },
];

type GoalReminderFormProps = FlexProps & {
  userIds: string[];
  frequency: ImprovementGoalUserReminderFrequency | null;
  timeMs: number | null;
  isDisabled?: boolean;
  onUserIdsChange: (userIds: string[]) => void;
  onFrequencyChange: (
    frequency: ImprovementGoalUserReminderFrequency | null
  ) => void;
  onTimeChange: (timeMs: number | null) => void;
  showValidationErrors: boolean;
  variant?: 'default' | 'condensed';
};
export const GoalReminderForm: FC<GoalReminderFormProps> = ({
  userIds,
  frequency,
  timeMs,
  isDisabled,
  onFrequencyChange,
  onTimeChange,
  onUserIdsChange,
  showValidationErrors,
  variant,
  ...rest
}) => {
  const [currentUser] = useCurrentUser();
  const usersDropdown = useDisclosure();

  const [fetchTeamMembers] = useFetchTeamMembers();
  const [fetchUsersByIds] = useFetchUsersByIds();

  const [selectedUsers, setSelectedUsers] = useState<MinimalUserFragment[]>([]);
  const [selectedUsersLoading, setSelectedUsersLoading] = useState(false);

  const showFrequencyRequiredMessage = Boolean(
    showValidationErrors && !frequency
  );

  // Handling initially selected users
  useEffect(() => {
    (async () => {
      const selectedUserIds = selectedUsers.map((user) => user.id);
      const userIdsToSelect = userIds.filter(
        (id) => !selectedUserIds.includes(id)
      );
      const userIdsToDeselect = selectedUserIds.filter(
        (id) => !userIds.includes(id)
      );
      if (userIdsToSelect.length) {
        setSelectedUsersLoading(true);
        const users = await fetchUsersByIds({ userIds: userIdsToSelect });
        setSelectedUsers((prev) =>
          [...prev, ...users].filter((u) => !userIdsToDeselect.includes(u.id))
        );
        setSelectedUsersLoading(false);
      } else if (userIdsToDeselect.length) {
        setSelectedUsers((prev) =>
          [...prev].filter((u) => !userIdsToDeselect.includes(u.id))
        );
      }
    })();
  }, [fetchUsersByIds, selectedUsers, userIds]);

  const onSelectedUsersChange = (newSelectedUsers: MinimalUserFragment[]) => {
    setSelectedUsers(newSelectedUsers);
    onUserIdsChange(newSelectedUsers.map((user) => user.id));
  };

  const showTimeRequiredMessage = Boolean(showValidationErrors && !timeMs);

  const getUserOptionsByTerm = async (
    term: string
  ): Promise<MinimalUserFragment[]> => {
    const teamId = currentUser?.team?.id;

    if (!teamId) {
      log.error(
        'Cannot fetch goal reminder user options without loaded teamId',
        { currentUser }
      );
      return [];
    }

    return fetchTeamMembers({
      term,
      teamId,
    });
  };

  const usersLabel =
    truncateWithCount(selectedUsers, 'name', 20) || 'Select users';

  const isCondensed = variant === 'condensed';

  return (
    <Flex gap={5} my={2} {...rest}>
      <FormControl>
        <FormLabel
          fontWeight={isCondensed ? 500 : 400}
          color={isCondensed ? 'gray.700' : 'gray.600'}
          fontSize={isCondensed ? 14 : 16}
        >
          Who to remind
        </FormLabel>
        <MultiSelect<MinimalUserFragment>
          searchable
          values={selectedUsers}
          onChange={onSelectedUsersChange}
          onClose={usersDropdown.close}
          isOpen={usersDropdown.isOpen}
          getOptions={(term) => getUserOptionsByTerm(term)}
          idKey="id"
          labelKeyOrFn="name"
        >
          <Button
            justifyContent="start"
            onClick={usersDropdown.toggle}
            isLoading={selectedUsersLoading}
            variant="outlineGray"
            w="full"
            fontWeight={400}
            isDisabled={isDisabled}
          >
            {usersLabel}
            <Spacer />
            <Icon as={BsChevronDown} />
          </Button>
        </MultiSelect>
      </FormControl>
      <FormControl isInvalid={showFrequencyRequiredMessage}>
        <FormLabel
          fontWeight={isCondensed ? 500 : 400}
          color={isCondensed ? 'gray.700' : 'gray.600'}
          fontSize={isCondensed ? 14 : 16}
          htmlFor="frequencySelector"
        >
          Frequency
        </FormLabel>
        <Select
          id="frequencySelector"
          placeholder="Select frequency"
          value={(frequency as string) ?? ''}
          h={10}
          color="gray.600"
          isDisabled={isDisabled}
          onChange={(e) =>
            onFrequencyChange(
              e.target.value as ImprovementGoalUserReminderFrequency
            )
          }
        >
          {FREQUENCY_OPTIONS?.map(({ label, value }) => (
            <Option key={value} value={value}>
              {label}
            </Option>
          ))}
        </Select>
        {Boolean(frequency && FREQUENCY_INFO[frequency]) && (
          <Text
            textAlign="left"
            fontSize={12}
            color="gray.500"
            fontWeight={500}
            pt="3px"
            ml={1}
          >
            {FREQUENCY_INFO[frequency!]}
          </Text>
        )}
        <FormErrorMessage>
          {showFrequencyRequiredMessage &&
            'Please select a frequency for your reminder.'}
        </FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={showTimeRequiredMessage}>
        <FormLabel
          fontWeight={isCondensed ? 500 : 400}
          color={isCondensed ? 'gray.700' : 'gray.600'}
          fontSize={isCondensed ? 14 : 16}
          htmlFor="frequencySelector"
        >
          Time of day
        </FormLabel>
        <TimeInput
          value={timeMs}
          isDisabled={isDisabled}
          onChange={onTimeChange}
          minH={10}
        />
        <FormErrorMessage>
          {showTimeRequiredMessage &&
            'Please select a time of day for your reminder.'}
        </FormErrorMessage>
      </FormControl>
    </Flex>
  );
};
