import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import { useNotifyTeamMutation } from '@spoke/graphql';

export type UseNotifyTeamHandler = {
  boardId: string;
};

export const useNotifyTeamHandler = () => {
  const [toast] = useToast();
  const [notifyTeam] = useNotifyTeamMutation({
    optimisticResponse: {
      notifyTeam: true,
    },
  });

  const handler = useCallback(
    async ({ boardId }: UseNotifyTeamHandler): Promise<boolean> => {
      if (!boardId) {
        log.error('Missing critical data to notify team', { boardId });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      log.info('Requesting team notification', { boardId });
      const { data, errors } = await notifyTeam({ variables: { boardId } });

      if (errors?.length) {
        log.error('NotifyTeam call responded with errors', { errors, data });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return data?.notifyTeam ?? false;
    },
    [notifyTeam, toast]
  );

  return [handler];
};
