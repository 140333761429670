import { useEffect } from 'react';
import { useCurrentOrg } from './useCurrentOrg';
import { QueryConfig, deepMerge, DeepPartial } from '@spoke/common';
import { useMembershipsByOrgIdAndEmailLazyQuery } from '@spoke/graphql';

type Config = QueryConfig<typeof useMembershipsByOrgIdAndEmailLazyQuery>;
type QueryRef = ReturnType<typeof useMembershipsByOrgIdAndEmailLazyQuery>[1];
type QueryData = {
  users:
    | NonNullable<
        NonNullable<
          NonNullable<QueryRef['data']>['organizationById']
        >['memberships']
      >['memberships']
    | null;
  totalUserCount:
    | NonNullable<
        NonNullable<
          NonNullable<QueryRef['data']>['organizationById']
        >['memberships']
      >['totalSize']
    | null;
};

export const useCurrentOrgMemberships = (
  config: DeepPartial<Config>
): [QueryData, QueryRef] => {
  const [currentOrg] = useCurrentOrg();
  const organizationId = currentOrg?.id ?? null;

  const baseConfig: Config = {
    variables: {
      organizationId: organizationId || '',
      limit: 10,
      offset: 0,
      sort: null,
      email: '',
    },
  };

  const [loadUsers, usersQuery] = useMembershipsByOrgIdAndEmailLazyQuery(
    (config ? deepMerge(baseConfig, config) : baseConfig) as Config
  );

  useEffect(() => {
    const shouldInit =
      baseConfig.variables?.organizationId && !usersQuery.called;
    if (shouldInit) loadUsers();
  }, [baseConfig.variables?.organizationId, loadUsers, usersQuery.called]);

  const userCount =
    usersQuery.data?.organizationById?.memberships?.totalSize ??
    usersQuery.previousData?.organizationById?.memberships?.totalSize ??
    null;

  const users =
    usersQuery.data?.organizationById?.memberships?.memberships ??
    usersQuery.previousData?.organizationById?.memberships?.memberships ??
    null;

  return [{ users, totalUserCount: userCount }, usersQuery];
};
