import { FC, memo } from 'react';
import {
  MotionFlexProps,
  MotionFlex,
  Flex,
  AddCommentBox,
  Comment,
} from '@spoke/common';
import { Comment as CommentType } from '@spoke/graphql';

type BoardCardCommentProps = MotionFlexProps & {
  cardId: string;
  comments: CommentType[];
  onAddComment: (cardId: string, text: string) => boolean | Promise<boolean>;
  onDeleteComment: (commentId: string) => boolean | Promise<boolean>;
  skipEnterAnimation: boolean;
  currentUserId: string;
};
export const BoardCardComments: FC<BoardCardCommentProps> = ({
  cardId,
  onAddComment,
  onDeleteComment,
  comments,
  skipEnterAnimation,
  currentUserId,
  ...rest
}) => (
  <MotionFlex
    initial={
      skipEnterAnimation
        ? { height: 'fit-content' }
        : { height: '0px', opacity: 0 }
    }
    animate={{ height: 'fit-content', opacity: 1 }}
    exit={{ height: '0px', opacity: 0 }}
    transition={{ duration: 0.07, ease: 'easeOut' }}
    {...rest}
  >
    <Flex
      flexDir="column"
      p={3}
      borderTopWidth="1px"
      borderTopColor="gray.100"
      borderTopStyle="solid"
      cursor="default"
      w="full"
    >
      <AddCommentBox
        onAdd={(text) => onAddComment(cardId, text)}
        autofocus={!skipEnterAnimation}
      />
      {comments?.map((comment) =>
        comment ? (
          <Comment
            key={comment.id}
            id={comment.id}
            authorName={comment.author?.name}
            canDelete={comment.author?.id === currentUserId}
            createdAt={comment.createdAt}
            text={comment.text}
            onDelete={() => onDeleteComment(comment.id)}
            mt={3}
          />
        ) : null
      )}
    </Flex>
  </MotionFlex>
);

export const MemoizedBoardCardComments = memo(BoardCardComments);
