import { motion } from 'framer-motion';
import { FC, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateUserMutation, User } from '@spoke/graphql';
import {
  SpokeFormRules,
  EMAIL_PATTERN,
  INVALID_VERIFICATION_CODE,
  SpkHead,
  Card,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  log,
  Input,
  Link,
  Image,
  Text,
  useAssets,
} from '@spoke/common';

export type ChangeAccountEmailFormSchema = {
  newEmail: string;
};

const rules: SpokeFormRules<ChangeAccountEmailFormSchema> = {
  newEmail: {
    required: 'Email is required',
    pattern: { value: EMAIL_PATTERN, message: 'Please enter a valid email' },
  },
};

type AuthChangeAccountEmailProps = {
  currentEmail: string;
  onSuccess: (newUser: User) => void;
  onCancel?: () => void;
};
export const AuthChangeAccountEmail: FC<AuthChangeAccountEmailProps> = ({
  currentEmail,
  onSuccess,
  onCancel,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors, isSubmitting, isValid },
  } = useForm<ChangeAccountEmailFormSchema>();

  const [updateUser] = useUpdateUserMutation();

  const [error, setError] = useState('');

  const { illustrationHello } = useAssets();

  const handleValidSubmit = useCallback(
    async (formData: ChangeAccountEmailFormSchema) => {
      log.info('Submitting change account email form', formData);
      const { newEmail } = formData;

      const { data, errors } = await updateUser({
        variables: { userInput: { email: newEmail } },
      });

      if (errors) {
        log.warn(
          'updateUser mutation on ChangeAccountEmail responded with error',
          { error: errors }
        );
        setError(INVALID_VERIFICATION_CODE);
        return;
      }

      const newUser = data?.updateUser;

      onSuccess(newUser as User);
    },
    [onSuccess, updateUser]
  );

  return (
    <motion.div
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{ duration: 0.15, ease: 'easeOut' }}
    >
      <SpkHead title="Reset Password" />
      <Card w={460} p={10}>
        <Image src={illustrationHello} alt="Hello from ScatterSpoke" />
        <VStack>
          <Heading size="md" fontWeight={500} color="gray.700">
            Change email address
          </Heading>
          <Text fontSize="sm" color="gray.500" textAlign="center" px={5}>
            Enter your new email address below.
          </Text>
        </VStack>
        <VStack
          spacing={4}
          w="full"
          as="form"
          onSubmit={handleSubmit(handleValidSubmit)}
        >
          <FormControl isInvalid={!isValid}>
            <VStack spacing={4}>
              <FormControl isInvalid={Boolean(formErrors.newEmail)}>
                <FormLabel htmlFor="newEmail">New email</FormLabel>
                <Input
                  id="newEmail"
                  {...register('newEmail', rules.newEmail)}
                />
                <FormErrorMessage>
                  {formErrors?.newEmail?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </FormControl>
          {error && (
            <FormControl isInvalid={Boolean(error)} m="0">
              <FormErrorMessage textAlign="center">{error}</FormErrorMessage>
            </FormControl>
          )}
          <Button w="full" isLoading={isSubmitting} type="submit">
            Confirm
          </Button>
          {onCancel && (
            <Text fontSize="xs" color="gray.500" w="full" textAlign="left">
              Is {currentEmail} your email?{' '}
              <Link onClick={() => onCancel()}>Go back</Link>
            </Text>
          )}
        </VStack>
      </Card>
    </motion.div>
  );
};
