import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { RiChatPollLine } from 'react-icons/ri';
import {
  useSkipLivePollHandler,
  useAnswerLivePollHandler,
  useUndoLivePollAnswerHandler,
  useFinishLivePollsByBoardIdHandler,
  useDiscardLivePollHandler,
} from '../handlers';
import { MemoizedLivePollGoalCard } from './LivePollGoalCard/LivePollGoalCard';
import {
  LivePollFragmentFragment,
  LivePollUserStatus,
  ImprovementGoalTypeTargetType,
} from '@spoke/graphql';
import {
  isLivePollRunning,
  getFirstScrollableParent,
  useDisclosure,
  Flex,
  HStack,
  BlinkingDot,
  Spacer,
  Button,
  ConfirmationModalCondensed,
  Icon,
  Divider,
  Box,
  Text,
} from '@spoke/common';

type LivePollGoalCardsProps = {
  polls: LivePollFragmentFragment[];
  teamMemberCount: number;
  isFacilitator: boolean;
  boardId: string;
};
export const LivePollGoalCards: FC<LivePollGoalCardsProps> = ({
  polls,
  teamMemberCount,
  isFacilitator,
  boardId,
}) => {
  const [skipPoll] = useSkipLivePollHandler();
  const [answerPoll] = useAnswerLivePollHandler();
  const [undoPollAnswer] = useUndoLivePollAnswerHandler();
  const [finishPolls] = useFinishLivePollsByBoardIdHandler();
  const [discardPoll] = useDiscardLivePollHandler();

  const containerRef = useRef<HTMLDivElement>(null);

  const noPollsToShow = !polls.length;
  const runningPolls = polls.filter(isLivePollRunning);
  const aPollIsRunning = Boolean(runningPolls.length);
  const allPollsAreDone = !noPollsToShow && !aPollIsRunning;

  // Only show running polls if there are any running
  const pollsToShow = aPollIsRunning ? runningPolls : polls;

  useEffect(() => {
    // A poll has just started, scroll to top
    if (!aPollIsRunning || !containerRef.current) return;
    const scrollableParent = getFirstScrollableParent(containerRef.current);
    if (!scrollableParent) return;
    scrollableParent.scrollTo({ top: 0, behavior: 'smooth' });
  }, [aPollIsRunning]);

  const title = useMemo(() => {
    if (aPollIsRunning) return 'A poll is running.';
    if (allPollsAreDone) return 'Poll results';
    return '';
  }, [aPollIsRunning, allPollsAreDone]);

  const subtitle = useMemo(() => {
    const userHasAnsweredAllPolls = !polls.find(
      (poll) => poll.status === LivePollUserStatus.Running
    );

    if (isFacilitator && aPollIsRunning) {
      return 'You must end the poll to finish it.';
    }
    if (!isFacilitator && aPollIsRunning && !userHasAnsweredAllPolls) {
      return 'You answer is private, no one can see who answered.';
    }
    if (!isFacilitator && aPollIsRunning && userHasAnsweredAllPolls) {
      return 'Waiting for the facilitator to end the poll.';
    }
    if (allPollsAreDone) {
      return 'The poll results have been added to the goals.';
    }
    return '';
  }, [aPollIsRunning, allPollsAreDone, isFacilitator, polls]);

  const [endPollLoading, setEndPollLoading] = useState(false);
  const endPollConfirmation = useDisclosure();

  const onEndPoll = async () => {
    if (endPollLoading) return;
    setEndPollLoading(true);
    await finishPolls({ boardId });
    endPollConfirmation.close();
    setEndPollLoading(false);
  };

  if (noPollsToShow) return null;

  return (
    <Box ref={containerRef}>
      <Flex flexDir="column" layerStyle="outlineGray" px={4} gap={3}>
        <HStack spacing={4}>
          {aPollIsRunning && <BlinkingDot size={8} color="green.600" />}
          <Flex flexDir="column" alignItems="flex-start">
            <Text
              fontSize={15}
              fontWeight={500}
              color={aPollIsRunning ? 'green.600' : 'gray.700'}
            >
              {title}
            </Text>
            <Text lineHeight={3} fontSize={14} color="gray.600">
              {subtitle}
            </Text>
          </Flex>
          <Spacer />
          {Boolean(isFacilitator && aPollIsRunning) && (
            <Button
              isLoading={endPollLoading}
              onClick={endPollConfirmation.open}
            >
              End poll now
            </Button>
          )}
        </HStack>
        {pollsToShow.map((poll) => (
          <MemoizedLivePollGoalCard
            key={poll.id}
            pollId={poll.id}
            title={poll.title}
            status={poll.status}
            skippedCount={poll.skippedUserIds?.length ?? 0}
            answeredCount={poll.answeredUserIds?.length ?? 0}
            teamMemberCount={teamMemberCount}
            isFacilitator={isFacilitator}
            goalTypeColor={poll.improvementGoal?.type.color ?? ''}
            goalTypeName={poll.improvementGoal?.type.name ?? ''}
            goalTypeTarget={poll.improvementGoal?.target ?? 0}
            goalTypeTargetType={
              poll.improvementGoal?.type.targetType ??
              ImprovementGoalTypeTargetType.Above
            }
            result={poll.result ?? null}
            onDiscard={discardPoll}
            onSkip={skipPoll}
            onSubmit={answerPoll}
            onUndo={undoPollAnswer}
          />
        ))}
        <ConfirmationModalCondensed
          icon={<Icon w={6} h={6} as={RiChatPollLine} />}
          title="Manually end poll"
          close={endPollConfirmation.close}
          isOpen={endPollConfirmation.isOpen}
          text="You will manually end this running poll.<br>Are you sure you want to proceed?"
          confirmText="End poll"
          onConfirm={onEndPoll}
        />
      </Flex>
      <Divider my={3} />
    </Box>
  );
};
