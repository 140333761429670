import { FC } from 'react';
import { Text, FlexProps, Flex } from '@spoke/common';

type ActionPlanCreateActionItemProps = FlexProps;
export const ActionPlanCreateActionItem: FC<ActionPlanCreateActionItemProps> = (
  props
) => (
  <Flex
    borderWidth={2}
    borderColor="gray.200"
    borderStyle="dashed"
    bg="gray.50"
    transition="background 0.2s ease-out"
    _hover={{ bg: 'gray.100' }}
    cursor="pointer"
    borderRadius="lg"
    h={85}
    w="full"
    alignItems="center"
    justifyContent="center"
    {...props}
  >
    <Text fontWeight={500}>+ Add Action Item</Text>
  </Flex>
);
