import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client';
import { SourceControlOrigin, WorkItemOrigin } from '@spoke/graphql';
import { useRef, useCallback } from 'react';
import { ONE_MINUTE_MS } from '../../constants';

export const createReactiveLockHook =
  (reactiveVar: ReactiveVar<boolean>, ttlMs: number = ONE_MINUTE_MS * 5) =>
  (): [boolean, () => void] => {
    const isLocked = useReactiveVar(reactiveVar);
    const timeoutId = useRef<NodeJS.Timeout | null>(null);

    const lock = useCallback(() => {
      if (timeoutId.current) clearTimeout(timeoutId.current);
      reactiveVar(true);
      timeoutId.current = setTimeout(() => {
        reactiveVar(false);
      }, ttlMs);
    }, []);

    return [isLocked, lock];
  };

// ======
// Should the below be in a separate file?

const bitbucketLockVar = makeVar<boolean>(false);
const githubLockVar = makeVar<boolean>(false);
const jiraLockVar = makeVar<boolean>(false);

export const useBitbucketRateLimitLock =
  createReactiveLockHook(bitbucketLockVar);
export const useGithubRateLimitLock = createReactiveLockHook(githubLockVar);
export const useJiraRateLimitLock = createReactiveLockHook(jiraLockVar);

/**
 * Returns all locked SourceControl providers and a callback
 * to fire all locks related to SourceControl. Useful in a context
 * where we don't know the exact provider.
 */
export const useSourceControlRateLimitLock = (): [
  SourceControlOrigin[] | null,
  () => void
] => {
  const [bitbucketLock, fireBitbucketLock] = useBitbucketRateLimitLock();
  const [githubLock, fireGithubLock] = useGithubRateLimitLock();

  const locked: SourceControlOrigin[] = [];
  if (bitbucketLock) locked.push(SourceControlOrigin.Bitbucket);
  if (githubLock) locked.push(SourceControlOrigin.Github);

  const fireLock = useCallback(() => {
    fireBitbucketLock();
    fireGithubLock();
  }, [fireBitbucketLock, fireGithubLock]);

  return [locked.length ? locked : null, fireLock];
};

/**
 * Returns all locked ProjectManagement providers and a callback
 * to fire all locks related to ProjectManagement. Useful in a context
 * where we don't know the exact provider.
 */
export const useProjectManagementRateLimitLock = (): [
  WorkItemOrigin[] | null,
  () => void
] => {
  const [jiraLock, fireJiraLock] = useJiraRateLimitLock();

  const locked: WorkItemOrigin[] = [];
  if (jiraLock) locked.push(WorkItemOrigin.Jira);

  const fireLock = useCallback(() => {
    fireJiraLock();
  }, [fireJiraLock]);

  return [locked.length ? locked : null, fireLock];
};
