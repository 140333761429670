import { useEffect } from 'react';
import { useInstalledOrgIntegrations } from './useInstalledOrgIntegrations';
import {
  useGithubRateLimitLock,
  isExternalRateLimitError,
  log,
} from '@spoke/common';
import { useLinkedGithubRepositoriesQuery } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type QueryRef = ReturnType<typeof useLinkedGithubRepositoriesQuery>;
type QueryData = NonNullable<
  NonNullable<QueryRef['data']>['team']
>['linkedGithubRepositories'];

export const useCurrentTeamGithubRepositories = (): [
  QueryData | null,
  QueryRef
] => {
  const [_, fireGithubRateLimitLock] = useGithubRateLimitLock();
  const { github: installedOnOrg } = useInstalledOrgIntegrations();
  const [team] = useCurrentTeam();

  const reposQuery = useLinkedGithubRepositoriesQuery({
    skip: !team?.id || !installedOnOrg,
    variables: { teamId: team?.id || '' },
    // https://scatterspoke.atlassian.net/browse/SC-594
    errorPolicy: 'none',
  });

  useEffect(() => {
    if (!reposQuery.error) return;
    const hasRateLimitError = reposQuery.error?.graphQLErrors.some(
      isExternalRateLimitError
    );
    if (hasRateLimitError) {
      log.error('Github linkedRepositories responded with rate limited', {
        error: reposQuery.error,
      });
      fireGithubRateLimitLock();
    }
  }, [fireGithubRateLimitLock, reposQuery.error]);

  const repos = reposQuery.data?.team?.linkedGithubRepositories ?? null;

  return [repos, reposQuery];
};
