import { useApolloClient } from '@apollo/client';
import { DeepPartial } from '@chakra-ui/react';
import { useCallback } from 'react';
import {
  useToast,
  optimisticUpdateBoard,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useUpdateBoardMutation, Board } from '@spoke/graphql';

export const useUpdateBoardPrivacyHandler = () => {
  const [updateBoard] = useUpdateBoardMutation();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  const handleUpdateBoardPrivacy = useCallback(
    async (boardId: string, newPrivacy: boolean) => {
      log.info(`Updating board privacy to '${newPrivacy}'`, {
        newPrivacy,
        boardId,
      });

      const updateBoardFields: DeepPartial<Board> = { public: newPrivacy };
      const expected = optimisticUpdateBoard({
        boardId,
        fields: updateBoardFields,
        cache,
      });

      const { errors } = await updateBoard({
        variables: { boardId, fields: updateBoardFields },
        optimisticResponse: { __typename: 'Mutation', updateBoard: expected },
      });

      if (errors) {
        log.error('Board name update responded with errors', { errors });
        toast(TOAST_ERROR_GENERIC);
        return;
      } else {
        toast({
          status: 'success',
          title: 'Success',
          description: 'Retrospective privacy successfully updated',
        });
      }
    },
    [cache, toast, updateBoard]
  );

  return [handleUpdateBoardPrivacy];
};
