import { ChakraProps, ComponentStyleConfig } from '@chakra-ui/react';
import { FC } from 'react';
import { IconType } from 'react-icons';
import { TabList } from '../TabList';
import { TabPanel } from '../TabPanel';
import { TabPanels } from '../TabPanels';
import { Tabs } from '../Tabs';
import { SpkTab } from './SpkTab';

export type SpkTabsElement = {
  key: string;
  label?: string;
  Icon?: IconType;
  iconW?: number;
  iconH?: number;
  page: JSX.Element;
};

export type SpkTabsProps = ChakraProps & {
  elements: SpkTabsElement[];
  selectedTabKey?: string;
  onSelect?: (key: string) => unknown;
  size?: Size;
};

export const SpkTabsComponentStyle: ComponentStyleConfig = {
  baseStyle: {},
};

type Size = 'md' | 'sm';

const FONT_SIZE_BY_SIZE: Record<Size, number> = {
  md: 15,
  sm: 14,
};

export const SpkTabs: FC<SpkTabsProps> = ({
  elements,
  selectedTabKey,
  onSelect,
  size = 'md',
  ...rest
}) => {
  const fontSize = FONT_SIZE_BY_SIZE[size];

  const selectedTabIndex = elements.findIndex(
    (element) => element.key === selectedTabKey
  );

  const onTabSelect = (index: number) => {
    const key = elements[index].key;
    onSelect?.(key);
  };

  return (
    <Tabs isLazy index={selectedTabIndex} onChange={onTabSelect} {...rest}>
      <TabList>
        {elements.map((element) => (
          <SpkTab
            key={element.label}
            Icon={element.Icon}
            fontSize={fontSize}
            iconH={element.iconH}
            iconW={element.iconW}
            py={3}
          >
            {element.label}
          </SpkTab>
        ))}
      </TabList>
      <TabPanels py={5}>
        {elements.map(({ page, key }) => (
          <TabPanel key={key} p={0}>
            {page}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
