export class SpkStr {
  static takeLast(str: string, count: number): string {
    return str.slice(-count);
  }

  static capitalizeFirstLetter(str: string): string {
    return str?.charAt?.(0)?.toUpperCase?.() + str?.slice?.(1)?.toLowerCase?.();
  }

  static extractNumber(str: string): number {
    return Number(str?.replace?.(/\D/g, ''));
  }
}
