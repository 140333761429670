import {
  ComponentStyleConfig,
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps as ChakraFormErrorMessageProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const FormErrorMessageComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    text: { fontSize: 'xs' },
    icon: { fontSize: 'xs' },
  },
};

type Props = ChakraFormErrorMessageProps;

export const FormErrorMessage: FC<Props> = (props) => (
  <ChakraFormErrorMessage {...props} />
);
