import { ApolloCache } from '@apollo/client';
import { deepMerge } from '../../../etc';
import { log } from '../../../../SpkLog';
import { DeepPartial } from '../../../../types';
import { getParkingLotItemFromCache } from '../../getters';
import { ParkingLotItem } from '@spoke/graphql';

type OptimisticUpdateParkingLotItemArgs = {
  parkingLotItemId: string;
  updateFields: DeepPartial<ParkingLotItem>;
  cache: ApolloCache<object>;
};
export const optimisticUpdateParkingLotItem = ({
  parkingLotItemId,
  updateFields,
  cache,
}: OptimisticUpdateParkingLotItemArgs): ParkingLotItem | undefined => {
  const parkingLotItemFromCache = getParkingLotItemFromCache({
    cache,
    parkingLotItemId,
  });

  if (!parkingLotItemFromCache) {
    log.error(
      `Could not find parking lot ${parkingLotItemId} in cache. Optimistic update failed.`
    );
    return undefined;
  }

  const merged = deepMerge(
    parkingLotItemFromCache,
    updateFields
  ) as ParkingLotItem;

  return merged;
};
