import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import {
  useCreateTeamMutation,
  TeamType,
  CreateTeamMutation,
  CurrentUserDocument,
  TeamTreeDocument,
} from '@spoke/graphql';

export const useCreateTeamHandler = () => {
  const [toast] = useToast();
  const [createTeam] = useCreateTeamMutation();

  const handleCreateTeam = useCallback(
    async ({
      organizationId,
      teamName,
      type = TeamType.Team,
      children = [],
    }: {
      teamName: string;
      organizationId: string;
      type?: TeamType;
      children?: string[];
    }): Promise<CreateTeamMutation['createTeam'] | null> => {
      if (!teamName || !organizationId) {
        log.error('Missing critical data to create team', {
          teamName,
          organizationId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const { errors, data } = await createTeam({
        variables: {
          teamInput: {
            name: teamName,
            organizationId,
            type,
            children,
          },
        },
        // We need to resync with the backend, it changes the current team to new team
        // should we turn all this into an optimistic response?
        refetchQueries: [
          { query: CurrentUserDocument },
          { query: TeamTreeDocument },
        ],
        awaitRefetchQueries: true,
      });

      if (errors) {
        log.warn('createTeam mutation on onboarding responded with error', {
          error: errors,
        });
        return null;
      }

      const createdTeam = data?.createTeam;

      return createdTeam;
    },
    [createTeam, toast]
  );

  return [handleCreateTeam];
};
