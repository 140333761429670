import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  optimisticUpdateParkingLotItem,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import {
  useUpdateParkingLotItemTextMutation,
  ParkingLotItem,
} from '@spoke/graphql';

export const useUpdateParkingLotItemTextHandler = () => {
  const [updateParkingLotItemText] = useUpdateParkingLotItemTextMutation();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  const handleUpdateParkingLotItemText = useCallback(
    async (parkingLotItemId: string, text: string) => {
      const updateFields: Partial<ParkingLotItem> = { text };

      const expected = optimisticUpdateParkingLotItem({
        cache,
        parkingLotItemId,
        updateFields,
      });

      const { errors } = await updateParkingLotItemText({
        variables: {
          itemId: parkingLotItemId,
          text,
        },
        optimisticResponse: !expected
          ? undefined
          : {
              __typename: 'Mutation',
              changeParkingLotItemText: expected,
            },
      });

      if (errors) {
        log.error('UpdateParkingLotItemText call responded with errors', {
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [cache, toast, updateParkingLotItemText]
  );

  return [handleUpdateParkingLotItemText];
};
