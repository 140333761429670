import { useCurrentTeamBitbucketRepositories } from './useCurrentTeamBitbucketRepositories';
import { useCurrentTeamGithubRepositories } from './useCurrentTeamGithubRepositories';
import { useCurrentTeamJiraBoards } from './useCurrentTeamJiraBoards';
import { useInstalledOrgIntegrations } from './useInstalledOrgIntegrations';
import { useBetaFeatures, useCurrentOrg, useCurrentTeam } from '@spoke/user';
import { DisableFeatureState } from '@spoke/graphql';
import { DefaultPlanSlug } from '@spoke/common';

export enum TeamMetricsState {
  HiddenCompletely = 'HIDDEN_COMPLETELY',
  DisabledByTeam = 'DISABLED_BY_TEAM',
  NotInstalledInOrg = 'NOT_INSTALLED_IN_ORG',
  NotSetupInTeam = 'NOT_SETUP_IN_TEAM',
  Ready = 'READY',
}

function getStateForMetricsIntegrations(args: {
  featureFlag: boolean;
  installedInOrg: boolean;
  linkedEntriesLength?: number;
  teamFeatureState?: DisableFeatureState;
  isFreePlan: boolean;
}) {
  const state = args.isFreePlan
    ? TeamMetricsState.NotInstalledInOrg
    : !args.featureFlag
    ? TeamMetricsState.HiddenCompletely
    : !args.teamFeatureState ||
      args.teamFeatureState !== DisableFeatureState.Enabled
    ? TeamMetricsState.DisabledByTeam
    : !args.installedInOrg
    ? TeamMetricsState.NotInstalledInOrg
    : !args.linkedEntriesLength
    ? TeamMetricsState.NotSetupInTeam
    : TeamMetricsState.Ready;

  return assembleFlags(state);
}

function max(...values: TeamMetricsState[]) {
  const metricsValues = Object.values(TeamMetricsState);
  return metricsValues[
    Math.max(...values.map((v) => metricsValues.indexOf(v)))
  ];
}

function assembleFlags(state: TeamMetricsState) {
  return {
    state,
    hidden:
      state === TeamMetricsState.HiddenCompletely ||
      state === TeamMetricsState.DisabledByTeam,
  };
}

export const useTeamMetricsFeatureState = () => {
  const [org] = useCurrentOrg();
  const [team, { loading }] = useCurrentTeam();
  const betaFeatures = useBetaFeatures();
  const installedInOrg = useInstalledOrgIntegrations();

  const [jiraBoards, { loading: loadingJiraBoards }] =
    useCurrentTeamJiraBoards();
  const [githubRepos, { loading: loadingGithubRepos }] =
    useCurrentTeamGithubRepositories();
  const [bitbucketRepos, { loading: loadingBitbucketRepos }] =
    useCurrentTeamBitbucketRepositories();

  const jiraMetrics = getStateForMetricsIntegrations({
    featureFlag: betaFeatures.jiraMetrics,
    installedInOrg: installedInOrg.jira,
    linkedEntriesLength: jiraBoards?.length,
    teamFeatureState: team?.settings?.jiraMetricsEnabled,
    isFreePlan: org?.plan?.slug === DefaultPlanSlug.Free,
  });

  const githubMetrics = getStateForMetricsIntegrations({
    featureFlag: betaFeatures.githubMetrics,
    installedInOrg: installedInOrg.github,
    linkedEntriesLength: githubRepos?.length,
    teamFeatureState: team?.settings?.githubMetricsEnabled,
    isFreePlan: org?.plan?.slug === DefaultPlanSlug.Free,
  });

  const bitbucketMetrics = getStateForMetricsIntegrations({
    featureFlag: betaFeatures.bitbucketMetrics,
    installedInOrg: installedInOrg.bitbucket,
    linkedEntriesLength: bitbucketRepos?.length,
    teamFeatureState: team?.settings?.bitbucketMetricsEnabled,
    isFreePlan: org?.plan?.slug === DefaultPlanSlug.Free,
  });

  return {
    loading:
      loading ||
      loadingJiraBoards ||
      loadingGithubRepos ||
      loadingBitbucketRepos,

    vcs: assembleFlags(max(githubMetrics.state, bitbucketMetrics.state)),
    flow: assembleFlags(max(jiraMetrics.state)),

    jiraMetrics,
    githubMetrics,
    bitbucketMetrics,
  };
};
