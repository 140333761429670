import { useCallback } from 'react';
import {
  useToast,
  useCurrentUser,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import {
  useUninstallBitbucketMetricsIntegrationMutation,
  InstalledOrgIntegrationsDocument,
} from '@spoke/graphql';

export const useUninstallBitbucketMetricsIntegrationHandler = () => {
  const [toast] = useToast();
  const [currentUser] = useCurrentUser();
  const [uninstallBitbucketMetricsIntegrationMutation] =
    useUninstallBitbucketMetricsIntegrationMutation();

  const handleUninstallBitbucketMetricsIntegration =
    useCallback(async (): Promise<boolean> => {
      log.info(`Uninstalling Bitbucket Metrics integration`);

      const { data, errors } =
        await uninstallBitbucketMetricsIntegrationMutation({
          refetchQueries: [
            {
              query: InstalledOrgIntegrationsDocument,
              variables: {
                organizationId: currentUser?.organization?.id ?? '',
              },
            },
          ],
        });

      if (errors?.length) {
        toast(TOAST_ERROR_GENERIC);
        log.error(`Could not uninstall Bitbucket Metrics integration`, {
          data,
          errors,
          currentUser: currentUser?.id,
        });
        return false;
      }

      toast({
        title: 'Success',
        description: 'Bitbucket Metrics integration has been uninstalled.',
        status: 'success',
      });

      return true;
    }, [
      currentUser?.id,
      currentUser?.organization?.id,
      uninstallBitbucketMetricsIntegrationMutation,
      toast,
    ]);

  return [handleUninstallBitbucketMetricsIntegration] as const;
};
