import { TextProps } from '@chakra-ui/react';
import { isNullish, SpkMath } from '@spoke/common';
import { FC } from 'react';
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi';
import { MdTrendingFlat } from 'react-icons/md';
import { Icon, IconProps } from './Icon';
import { Skeleton } from './Skeleton';
import { Text } from './Text';

type Props = TextProps & {
  value?: number | null;
  prevValue?: number | null;
  iconSize?: string | number;
  ideal?: 'up' | 'down';
  loading?: boolean;
  icon?: IconProps;
};
export const PercentChange: FC<Props> = ({
  value,
  prevValue,
  iconSize = 4,
  loading,
  ideal = 'up',
  icon = {},
  ...rest
}) => {
  const isNull = isNullish(value) || isNullish(prevValue);
  const hasNotChanged = isNull || value === prevValue;
  const trendingUp = !isNull && value! > prevValue!;
  const isBad = ideal === 'up' ? !trendingUp : trendingUp;

  const percentChange = isNull ? 0 : SpkMath.percentChange(value!, prevValue!);

  const trendColor = hasNotChanged
    ? 'neutralYellow'
    : !isBad
    ? 'green.600'
    : 'negativeRed';

  const trendIcon = hasNotChanged
    ? MdTrendingFlat
    : trendingUp
    ? BiTrendingUp
    : BiTrendingDown;

  return (
    <Skeleton
      isLoaded={!loading}
      display="flex"
      h={loading ? 5 : undefined}
      w={loading ? 38 : undefined}
    >
      <Icon
        mt={hasNotChanged ? '0.5%' : trendingUp ? '1%' : '0%'}
        as={trendIcon}
        color={trendColor}
        mr="2px"
        w={iconSize}
        h={iconSize}
        {...icon}
      />
      <Text color={trendColor} {...rest}>
        {percentChange}%
      </Text>
    </Skeleton>
  );
};
