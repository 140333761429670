import { ApolloCache } from '@apollo/client';
import { randomString } from '../../../etc';
import { log } from '../../../../SpkLog';
import { convertParkingLotItemToCard } from '../../../spoke';
import { getBoardFromCache, getParkingLotItemFromCache } from '../../getters';
import { BoardFragmentFragment, List, ParkingLotItem } from '@spoke/graphql';

type OptimisticAddParkingLotListArgs = {
  boardId: string;
  cache: ApolloCache<object>;
  parkingLotItemIds: string[];
};

export const optimisticAddParkingLotList = ({
  boardId,
  cache,
  parkingLotItemIds,
}: OptimisticAddParkingLotListArgs): BoardFragmentFragment | undefined => {
  const boardFromCache = getBoardFromCache({ boardId, cache });
  const parkingLotItems = parkingLotItemIds.map((id) =>
    getParkingLotItemFromCache({ cache, parkingLotItemId: id })
  ) as ParkingLotItem[];

  if (
    !boardFromCache ||
    !parkingLotItemIds.length ||
    parkingLotItemIds.find((e) => !e)
  ) {
    log.error(
      'Missing critical cache data to create addParkingLotList optimistic response',
      { boardFromCache, parkingLotItems, parkingLotItemIds, boardId }
    );
    return;
  }

  const convertedParkingLotItems = parkingLotItems.map(
    convertParkingLotItemToCard
  );

  const newListId = randomString();

  const newList: List = {
    __typename: 'List',
    id: newListId,
    boardId: boardFromCache.id,
    index: boardFromCache.lists?.length || 0,
    name: 'Parking Lot',
    ownerId: boardFromCache.ownerId,
    teamId: boardFromCache.teamId,
    cardColor: '',
    archived: false,
    cards: convertedParkingLotItems.map((card, idx) => ({
      ...card,
      index: idx,
      listId: newListId,
    })),
  };

  const newLists = boardFromCache.lists?.concat(newList) || [newList];

  const newBoard = {
    ...boardFromCache,
    lists: newLists,
  };

  return newBoard;
};
