import { ApolloCache } from '@apollo/client';
import { deepMerge } from '../../../etc';
import { DeepPartial } from '../../../../types';
import { getTeamFromCache } from '../../getters';
import { Team, TeamFragmentFragment } from '@spoke/graphql';

type OptimisticUpdateTeamArgs = {
  cache: ApolloCache<object>;
  teamId: string;
  updateFields: DeepPartial<Team>;
};
export const optimisticUpdateTeam = ({
  cache,
  updateFields,
  teamId,
}: OptimisticUpdateTeamArgs): TeamFragmentFragment | null => {
  const teamFromCache = getTeamFromCache({ cache, teamId });
  const newTeam = deepMerge(teamFromCache, updateFields);
  return (newTeam as TeamFragmentFragment) || null;
};
