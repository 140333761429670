import { FC, useCallback, useState } from 'react';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import {
  SpokeFormRules,
  EMAIL_PATTERN,
  SpkHead,
  Card,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  log,
  Input,
  Link,
  Image,
  Text,
  useRouter,
  useAssets,
} from '@spoke/common';
import { useLoginMutation, User } from '@spoke/graphql';

export type LoginFormSchema = {
  email: string;
  password: string;
};

const rules: SpokeFormRules<LoginFormSchema> = {
  email: {
    required: 'Email is required',
    pattern: { value: EMAIL_PATTERN, message: 'Please enter a valid email' },
  },
  password: { required: 'Password is required' },
};

type AuthEmailPasswordProps = {
  greeting?: string;
  onSuccess: (user: User) => void;
  onResetPassword?: () => void;
  onRegister?: () => void;
  onSelectMethod?: () => void;
};
export const AuthEmailPassword: FC<AuthEmailPasswordProps> = ({
  greeting = 'Welcome Back!',
  onSuccess,
  onRegister,
  onResetPassword,
  onSelectMethod,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors: formErrors, isSubmitting, isValid },
  } = useForm<LoginFormSchema>();
  const router = useRouter();

  const { illustrationHello } = useAssets();

  const [login] = useLoginMutation();
  const [error, setError] = useState('');

  const handleLogin = useCallback(
    async (formData: LoginFormSchema) => {
      log.info('Submitting login form', formData);
      const { email, password } = formData;
      /*
       if the invitation comes with the route pattern as /invitation/team/[teamInviteToken]
       then we get it from the query params with the name teamInviteToken, otherwise we get it 
       from the query params with the name inviteToken
      */
      const inviteToken = router.query.invitation
        ? (router.query.invitation as string)
        : router.query.teamInviteToken
        ? (router.query.teamInviteToken as string)
        : undefined;

      const { errors, data } = await login({
        variables: { loginInput: { email, password, inviteToken } },
      });

      if (errors?.length) {
        log.warn('Handled login form GraphQL error', errors);
        setError(errors[0].message);
        return;
      }

      const user = data?.login;

      log.info('Successfully logged in', data);
      onSuccess(user as User);
    },
    [login, onSuccess, router.query.invitation, router.query.teamInviteToken]
  );

  return (
    <motion.div
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{ duration: 0.15, ease: 'easeOut' }}
    >
      <SpkHead title="Login" />
      <Card w={460} p={10}>
        <Image src={illustrationHello} alt="Hello from ScatterSpoke" />
        <VStack>
          <VStack spacing={1}>
            <Heading
              textAlign="center"
              size="md"
              fontWeight={500}
              color="gray.700"
            >
              {greeting}
            </Heading>
            <Text fontSize="sm" color="gray.500" textAlign="center" px={10}>
              Continue to your organization{' '}
              {onSelectMethod && (
                <>
                  or{' '}
                  <Link onClick={() => onSelectMethod()}>
                    choose another method.
                  </Link>
                </>
              )}
            </Text>
          </VStack>
        </VStack>
        <VStack
          spacing={4}
          w="full"
          as="form"
          onSubmit={handleSubmit(handleLogin)}
        >
          <FormControl isInvalid={!isValid}>
            <VStack spacing={4}>
              <FormControl isInvalid={Boolean(formErrors.email)}>
                <FormLabel htmlFor="email">Email address</FormLabel>
                <Input id="email" {...register('email', rules.email)} />
                <FormErrorMessage>
                  {formErrors?.email?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(formErrors.password)}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  id="password"
                  type="password"
                  {...register('password', rules.password)}
                />
                <FormErrorMessage>
                  {formErrors?.password?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </FormControl>
          {error && (
            <FormControl isInvalid={Boolean(error)} m="0">
              <FormErrorMessage textAlign="center">{error}</FormErrorMessage>
            </FormControl>
          )}
          <VStack w="full">
            {onResetPassword && (
              <Text fontSize="xs" color="gray.500" w="full" textAlign="left">
                Forgot your password?{' '}
                <Link onClick={() => onResetPassword()}>Reset</Link>
              </Text>
            )}
            {onRegister && (
              <Text fontSize="xs" color="gray.500" w="full" textAlign="left">
                Don&apos;t have an account?{' '}
                <Link onClick={() => onRegister()}>Register</Link>
              </Text>
            )}
          </VStack>
          <Button w="full" isLoading={isSubmitting} id="submit" type="submit">
            Sign in
          </Button>
        </VStack>
      </Card>
    </motion.div>
  );
};
