import { ApolloCache } from '@apollo/client';
import {
  List,
  ListFragmentFragment,
  ListFragmentFragmentDoc,
} from '@spoke/graphql';

type GetListFromCacheArgs = {
  listId: string;
  cache: ApolloCache<object>;
};
export function getListFromCache({
  listId,
  cache,
}: GetListFromCacheArgs): List | null {
  return (
    (cache.readFragment({
      fragment: ListFragmentFragmentDoc,
      fragmentName: 'ListFragment',
      id: `List:${listId}`,
    }) as ListFragmentFragment as List) || null
  );
}
