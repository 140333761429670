import { FC, useMemo } from 'react';
import { SparklineChart } from '@spoke/charts';
import {
  SpkTime,
  SpkMath,
  ONE_DAY_MS,
  TypewriterTextSection,
  formatWithGoalUnit,
  Flex,
  Typewriter,
  truncateDecimals,
  PercentChange,
  Text,
} from '@spoke/common';
import {
  Trend,
  ImprovementGoalTypeTargetType,
  MetricsProviderCategory,
  TeamInsightFactCauseSolutionFactFragmentFragment,
  TeamInsightFactCauseSolutionFactMetricDeterioration as TeamInsightFactCauseSolutionFactMetricDeteriorationTypedef,
  TeamInsightFactCauseSolutionFactMetricInadequacy,
  TeamInsightFactCauseSolutionFactOutlierWorkItemTopic,
  TeamInsightFactCauseSolutionFactMetricImprovement,
} from '@spoke/graphql';
import { MetricProviderIcons } from '@spoke/metrics';

type Props = {
  skipIntroductionText?: boolean;
  fontSize?: number;
  date: Date;
  fact: TeamInsightFactCauseSolutionFactFragmentFragment['fact'];
  skipAnimations?: boolean;
};
export const TeamInsightFactCauseSolutionFact: FC<Props> = ({
  fact,
  date,
  skipAnimations,
  fontSize = 18,
  skipIntroductionText = false,
}) => {
  if (
    fact.__typename === 'TeamInsightFactCauseSolutionFactMetricDeterioration'
  ) {
    return (
      <FactMetricDeterioration
        fact={fact}
        date={date}
        skipAnimations={skipAnimations}
        skipIntroductionText={skipIntroductionText}
        fontSize={fontSize}
      />
    );
  }

  if (fact.__typename === 'TeamInsightFactCauseSolutionFactMetricInadequacy') {
    return (
      <FactMetricBelowRecommended
        fact={fact}
        date={date}
        skipAnimations={skipAnimations}
        skipIntroductionText={skipIntroductionText}
        fontSize={fontSize}
      />
    );
  }

  if (
    fact.__typename === 'TeamInsightFactCauseSolutionFactOutlierWorkItemTopic'
  ) {
    return (
      <FactOutlierWorkItemTopic
        fact={fact}
        date={date}
        skipAnimations={skipAnimations}
        skipIntroductionText={skipIntroductionText}
        fontSize={fontSize}
      />
    );
  }

  if (fact.__typename === 'TeamInsightFactCauseSolutionFactMetricImprovement') {
    return (
      <FactMetricImprovement
        fact={fact}
        date={date}
        skipAnimations={skipAnimations}
        skipIntroductionText={skipIntroductionText}
        fontSize={fontSize}
      />
    );
  }

  return null;
};

const FactMetricDeterioration: FC<{
  fact: TeamInsightFactCauseSolutionFactMetricDeteriorationTypedef;
  date: Date;
  skipAnimations?: boolean;
  fontSize?: number;
  skipIntroductionText?: boolean;
}> = ({
  fact,
  date,
  skipAnimations,
  fontSize = 18,
  skipIntroductionText = false,
}) => {
  const { history, type } = fact;
  const formattedDate = SpkTime.format(date, 'MMM dd');

  const lastDataPoint = history[history.length - 1];
  const factVerb = type.ideal === Trend.Up ? 'decreased' : 'increased';

  const { latestValue, comparedValue, changeTimeMs, changeValue } =
    SpkMath.changeByTrend(
      history,
      type.ideal === Trend.Up ? Trend.Down : Trend.Up
    );
  const formattedChangeValue = Math.round(Math.abs(changeValue));
  const factTimeSpanDays = Math.ceil(changeTimeMs / ONE_DAY_MS);

  const timeSeriesWithId = history.map((data, idx) => ({
    ...data,
    id: `${idx}`,
    date: new Date(data.date).toISOString(),
  }));

  const introText: TypewriterTextSection[] = useMemo(
    () => [
      { content: 'On ', type: 'normal' },
      { content: formattedDate, type: 'bold' },
      { content: ', we noticed that...', type: 'normal' },
    ],
    [formattedDate]
  );

  const factText: TypewriterTextSection[] = useMemo(
    () => [
      { content: 'Your ', type: 'normal' },
      {
        content: (
          <MetricProviderIcons
            providerCategory={type.providerCategory}
            show="installed-on-org"
          />
        ),
        type: 'component',
      },
      { content: ` ${type.name} `, type: 'bold' },
      { content: `${factVerb} by `, type: 'normal' },
      {
        content: `${formatWithGoalUnit(formattedChangeValue, type.unit)}`,
        type: 'bold',
      },
      { content: ` within the past `, type: 'normal' },
      { content: `${factTimeSpanDays} days.`, type: 'bold' },
    ],
    [
      formattedChangeValue,
      factTimeSpanDays,
      factVerb,
      type.name,
      type.providerCategory,
      type.unit,
    ]
  );

  return (
    <Flex w="full" flexDir="column">
      {!skipIntroductionText && (
        <Typewriter
          skipAnimation={skipAnimations}
          content={introText}
          startDelayMs={500}
          caretFadeDelayMs={600}
          fontSize={18}
          lineHeight="24px"
        />
      )}
      <Flex w="full" justifyContent="space-between">
        <Typewriter
          skipAnimation={skipAnimations}
          content={factText}
          startDelayMs={skipAnimations ? 0 : 3000}
          caretFadeDelayMs={1000}
          fontSize={fontSize}
          lineHeight={`${fontSize * 1.33}px`}
        />
        <Flex gap={1} mr={8} alignItems="center" justifyContent="flex-end">
          <Text color="negativeRed" fontWeight={500} fontSize={16} pl={1}>
            {!lastDataPoint && 'N/A'}
            {Boolean(lastDataPoint) &&
              `${formatWithGoalUnit(
                truncateDecimals(lastDataPoint?.value || 0, 1),
                type.unit
              )}`}
          </Text>
          <PercentChange
            prevValue={comparedValue}
            value={latestValue}
            ideal={type.ideal === Trend.Up ? 'up' : 'down'}
            loading={false}
            iconSize="20px"
            icon={{ paddingTop: '3px' }}
            fontSize={fontSize}
            fontWeight={500}
          />
          <SparklineChart
            data={timeSeriesWithId}
            targetDirection={
              type.ideal === Trend.Up
                ? ImprovementGoalTypeTargetType.Above
                : ImprovementGoalTypeTargetType.Below
            }
            // Is this ok?
            target={Math.abs(latestValue - comparedValue) / 2}
            h="45px"
            w={120}
            ml={2}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const FactMetricImprovement: FC<{
  fact: TeamInsightFactCauseSolutionFactMetricImprovement;
  date: Date;
  skipAnimations?: boolean;
  fontSize?: number;
  skipIntroductionText?: boolean;
}> = ({
  fact,
  date,
  skipAnimations,
  fontSize = 18,
  skipIntroductionText = false,
}) => {
  const { history, type } = fact;
  const formattedDate = SpkTime.format(date, 'MMM dd');

  const lastDataPoint = history[history.length - 1];
  const factVerb =
    type.ideal === Trend.Down || type.ideal === Trend.Stable
      ? 'decreased'
      : 'increased';

  const { latestValue, comparedValue, changeTimeMs, changeValue } =
    SpkMath.changeByTrend(
      history,
      type.ideal === Trend.Down || type.ideal === Trend.Stable
        ? Trend.Down
        : Trend.Up
    );
  const formattedChangeValue = Math.round(Math.abs(changeValue));
  const factTimeSpanDays = Math.ceil(changeTimeMs / ONE_DAY_MS);

  const timeSeriesWithId = history.map((data, idx) => ({
    ...data,
    id: `${idx}`,
    date: new Date(data.date).toISOString(),
  }));

  const introText: TypewriterTextSection[] = useMemo(
    () => [
      { content: 'On ', type: 'normal' },
      { content: formattedDate, type: 'bold' },
      { content: ', we noticed that...', type: 'normal' },
    ],
    [formattedDate]
  );

  const factText: TypewriterTextSection[] = useMemo(
    () => [
      { content: 'Your ', type: 'normal' },
      {
        content: (
          <MetricProviderIcons
            providerCategory={type.providerCategory}
            show="installed-on-org"
          />
        ),
        type: 'component',
      },
      { content: ` ${type.name} `, type: 'bold' },
      { content: `${factVerb} by `, type: 'normal' },
      {
        content: `${formatWithGoalUnit(formattedChangeValue, type.unit)}`,
        type: 'bold',
      },
      { content: ` within the past `, type: 'normal' },
      { content: `${factTimeSpanDays} days.`, type: 'bold' },
    ],
    [
      formattedChangeValue,
      factTimeSpanDays,
      factVerb,
      type.name,
      type.providerCategory,
      type.unit,
    ]
  );

  return (
    <Flex w="full" flexDir="column">
      {!skipIntroductionText && (
        <Typewriter
          skipAnimation={skipAnimations}
          content={introText}
          startDelayMs={500}
          caretFadeDelayMs={600}
          fontSize={18}
          lineHeight="24px"
        />
      )}
      <Flex w="full" justifyContent="space-between">
        <Typewriter
          skipAnimation={skipAnimations}
          content={factText}
          startDelayMs={skipAnimations ? 0 : 3000}
          caretFadeDelayMs={1000}
          fontSize={fontSize}
          lineHeight={`${fontSize * 1.33}px`}
        />
        <Flex gap={1} mr={8} alignItems="center" justifyContent="flex-end">
          <Text color="negativeRed" fontWeight={500} fontSize={16} pl={1}>
            {!lastDataPoint && 'N/A'}
            {Boolean(lastDataPoint) &&
              `${formatWithGoalUnit(
                truncateDecimals(lastDataPoint?.value || 0, 1),
                type.unit
              )}`}
          </Text>
          <PercentChange
            prevValue={comparedValue}
            value={latestValue}
            ideal={type.ideal === Trend.Up ? 'up' : 'down'}
            loading={false}
            iconSize="20px"
            icon={{ paddingTop: '3px' }}
            fontSize={fontSize}
            fontWeight={500}
          />
          <SparklineChart
            data={timeSeriesWithId}
            targetDirection={
              type.ideal === Trend.Up
                ? ImprovementGoalTypeTargetType.Above
                : ImprovementGoalTypeTargetType.Below
            }
            // Is this ok?
            target={Math.abs(latestValue - comparedValue) / 2}
            h="45px"
            w={120}
            ml={2}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const FactMetricBelowRecommended: FC<{
  fact: TeamInsightFactCauseSolutionFactMetricInadequacy;
  date: Date;
  skipAnimations?: boolean;
  fontSize?: number;
  skipIntroductionText?: boolean;
}> = ({
  fact,
  date,
  skipAnimations,
  fontSize = 18,
  skipIntroductionText = false,
}) => {
  const { history, type, recommended } = fact;
  const formattedDate = SpkTime.format(date, 'MMM dd');

  const lastDataPoint = history[history.length - 1];
  const factAdverb = type.ideal === Trend.Up ? 'below' : 'above';

  const { latestValue, comparedValue, changeTimeMs, changeValue } =
    SpkMath.changeByTrend(
      history,
      type.ideal === Trend.Up ? Trend.Down : Trend.Up
    );
  const formattedChangeValue = Math.round(Math.abs(changeValue));
  const factTimeSpanDays = Math.ceil(changeTimeMs / ONE_DAY_MS);

  const timeSeriesWithId = history.map((data, idx) => ({
    ...data,
    id: `${idx}`,
    date: new Date(data.date).toISOString(),
  }));

  const introText: TypewriterTextSection[] = useMemo(
    () => [
      { content: 'On ', type: 'normal' },
      { content: formattedDate, type: 'bold' },
      { content: ', we noticed that...', type: 'normal' },
    ],
    [formattedDate]
  );

  const factText: TypewriterTextSection[] = useMemo(
    () => [
      { content: 'Your ', type: 'normal' },
      {
        content: (
          <MetricProviderIcons
            providerCategory={type.providerCategory}
            show="installed-on-org"
          />
        ),
        type: 'component',
      },
      { content: ` ${type.name} `, type: 'bold' },
      { content: `${factAdverb} by `, type: 'normal' },
      {
        content: `${formatWithGoalUnit(formattedChangeValue, type.unit)}`,
        type: 'bold',
      },
      { content: ` within the past `, type: 'normal' },
      { content: `${factTimeSpanDays} days.`, type: 'bold' },
    ],
    [
      formattedChangeValue,
      factTimeSpanDays,
      factAdverb,
      type.name,
      type.providerCategory,
      type.unit,
    ]
  );

  return (
    <Flex w="full" flexDir="column">
      {!skipIntroductionText && (
        <Typewriter
          skipAnimation={skipAnimations}
          content={introText}
          startDelayMs={500}
          caretFadeDelayMs={600}
          fontSize={18}
          lineHeight="24px"
        />
      )}
      <Flex w="full" justifyContent="space-between">
        <Typewriter
          skipAnimation={skipAnimations}
          content={factText}
          startDelayMs={skipAnimations ? 0 : 3000}
          caretFadeDelayMs={1000}
          fontSize={fontSize}
          lineHeight={`${fontSize * 1.33}px`}
        />
        <Flex gap={1} mr={8} alignItems="center" justifyContent="flex-end">
          <Text color="negativeRed" fontWeight={500} fontSize={16} pl={1}>
            {!lastDataPoint && 'N/A'}
            {Boolean(lastDataPoint) &&
              `${formatWithGoalUnit(
                truncateDecimals(lastDataPoint?.value || 0, 1),
                type.unit
              )}`}
          </Text>
          <PercentChange
            prevValue={comparedValue}
            value={latestValue}
            ideal={type.ideal === Trend.Up ? 'up' : 'down'}
            loading={false}
            iconSize="20px"
            icon={{ paddingTop: '3px' }}
            fontSize={fontSize}
            fontWeight={500}
          />
          <SparklineChart
            data={timeSeriesWithId}
            targetDirection={
              type.ideal === Trend.Up
                ? ImprovementGoalTypeTargetType.Above
                : ImprovementGoalTypeTargetType.Below
            }
            // Is this ok?
            target={Math.abs(latestValue - comparedValue) / 2}
            h="45px"
            w={120}
            ml={2}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const FactOutlierWorkItemTopic: FC<{
  fact: TeamInsightFactCauseSolutionFactOutlierWorkItemTopic;
  date: Date;
  skipAnimations?: boolean;
  fontSize?: number;
  skipIntroductionText?: boolean;
}> = ({
  fact,
  date,
  skipAnimations,
  fontSize = 18,
  skipIntroductionText = false,
}) => {
  const { topic } = fact;
  const formattedDate = SpkTime.format(date, 'MMM dd');

  const introText: TypewriterTextSection[] = useMemo(
    () => [
      { content: 'On ', type: 'normal' },
      { content: formattedDate, type: 'bold' },
      { content: ', we noticed that...', type: 'normal' },
    ],
    [formattedDate]
  );

  const factText: TypewriterTextSection[] = useMemo(
    () => [
      { content: 'Usually, ', type: 'normal' },
      {
        content: (
          <MetricProviderIcons
            providerCategory={MetricsProviderCategory.ProjectManagement}
            show="installed-on-org"
          />
        ),
        type: 'component',
      },
      { content: ` work related to `, type: 'normal' },
      { content: `${topic}`, type: 'bold' },
      {
        content: ` takes your team the most time to finish.`,
        type: 'normal',
      },
    ],
    [topic]
  );

  return (
    <Flex w="full" flexDir="column">
      {!skipIntroductionText && (
        <Typewriter
          skipAnimation={skipAnimations}
          content={introText}
          startDelayMs={500}
          caretFadeDelayMs={600}
          fontSize={18}
          lineHeight="24px"
        />
      )}
      <Flex w="full" justifyContent="space-between" gap={1}>
        <Typewriter
          skipAnimation={skipAnimations}
          content={factText}
          startDelayMs={skipAnimations ? 0 : 3000}
          caretFadeDelayMs={1000}
          fontSize={fontSize}
          lineHeight={`${fontSize * 1.33}px`}
        />
      </Flex>
    </Flex>
  );
};
