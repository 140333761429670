import { useEffect } from 'react';
import {
  QueryConfig,
  deepMerge,
  DeepPartial,
  useNetworkContext,
} from '@spoke/common';
import {
  usePaginatedTeamBoardsLazyQuery,
  BoardCreatedSubscription,
  BoardCreatedSubscriptionVariables,
  BoardCreatedDocument,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type Config = QueryConfig<typeof usePaginatedTeamBoardsLazyQuery>;
type QueryRef = ReturnType<typeof usePaginatedTeamBoardsLazyQuery>[1];
type QueryData = {
  teamBoards: NonNullable<
    NonNullable<QueryRef['data']>['teamBoardsPagination']
  >['boards'];
  teamBoardCount:
    | NonNullable<
        NonNullable<QueryRef['data']>['teamBoardsPagination']
      >['totalSize']
    | null;
};

export const useCurrentTeamBoards = (
  config?: DeepPartial<Config>
): [QueryData, QueryRef] => {
  const [currentTeam] = useCurrentTeam();
  const { shouldPoll } = useNetworkContext();

  const baseConfig: Config = {
    /*
     * When we are rendering a component that require the latest boards we must make sure that the cache is up-to-date,
     *  because when the boardCreated subscription is triggered there might not be anything subscribed to that in order to update the cache.
     *
     * Examples of that are when we create a board: The board creation screen does not have any subscriptions to boardCreate active thus the just created board does not make it into our cache,
     *  consequence of that is that when we create a board and then go back to the dashboard the UI wouldn't show the just created board if we don't ask to fetch it from network.
     */
    fetchPolicy: 'cache-and-network',
    variables: {
      teamId: currentTeam?.id || '',
      archived: false,
      offset: 0,
      limit: 10,
    },
  };

  const finalConfig = (
    config ? deepMerge(baseConfig, config) : baseConfig
  ) as Config;

  const [loadTeamBoards, teamBoardsQuery] =
    usePaginatedTeamBoardsLazyQuery(finalConfig);

  useEffect(() => {
    const shouldInit = finalConfig.variables?.teamId && !teamBoardsQuery.called;
    if (shouldInit) {
      loadTeamBoards();
    }
  }, [finalConfig.variables?.teamId, teamBoardsQuery.called, loadTeamBoards]);

  useEffect(() => {
    const isHeadOfBoardList =
      (config?.variables?.offset ?? baseConfig.variables!.offset) === 0;
    const shouldSubscribe = Boolean(
      isHeadOfBoardList && !shouldPoll && teamBoardsQuery.called
    );

    if (!shouldSubscribe) return;

    teamBoardsQuery.subscribeToMore<
      BoardCreatedSubscription,
      BoardCreatedSubscriptionVariables
    >({
      document: BoardCreatedDocument,
      variables: {
        teamId: config?.variables?.teamId ?? baseConfig.variables!.teamId,
      },
      updateQuery(prev, { subscriptionData }) {
        if (!subscriptionData.data) return prev;

        const newBoard = subscriptionData.data.boardCreated;
        const isDuplicated = !!prev.teamBoardsPagination?.boards?.some(
          (board) => board?.id === newBoard.id
        );

        if (isDuplicated) return prev;

        return {
          ...prev,
          teamBoardsPagination: {
            ...prev.teamBoardsPagination,
            totalSize: (prev.teamBoardsPagination?.totalSize ?? 0) + 1,
            boards: [newBoard, ...(prev.teamBoardsPagination?.boards ?? [])],
          },
        };
      },
    });
  }, [
    baseConfig.variables,
    config?.variables?.offset,
    config?.variables?.teamId,
    shouldPoll,
    teamBoardsQuery,
  ]);

  const teamBoards =
    teamBoardsQuery.data?.teamBoardsPagination?.boards ??
    teamBoardsQuery.previousData?.teamBoardsPagination?.boards ??
    [];

  const teamBoardCount =
    teamBoardsQuery.data?.teamBoardsPagination?.totalSize ??
    teamBoardsQuery.previousData?.teamBoardsPagination?.totalSize ??
    null;

  return [{ teamBoards, teamBoardCount }, teamBoardsQuery];
};
