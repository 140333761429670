import { NextPage } from 'next';
import { useState, useEffect, useLayoutEffect } from 'react';
import {
  useCurrentUser,
  compareRoutes,
  log,
  useRouter,
  useRoutes,
  isServer,
  SpkPath,
} from '@spoke/common';
import { MembershipRole } from '@spoke/graphql';

const APPLY_ON_ROUTES: string[] = [];

export const OrgAdminGuard: NextPage = ({ children }) => {
  const router = useRouter();
  const routes = useRoutes();
  const [currentUser, currentUserQuery] = useCurrentUser();
  const role: MembershipRole | null = currentUser?.role || null;
  const isAdmin = role === MembershipRole.Admin;

  const [guardPassed, setGuardPassed] = useState(false);

  const shouldApply = APPLY_ON_ROUTES.some((r) =>
    compareRoutes(r, router.route, router.asPath)
  );

  (isServer() ? useEffect : useLayoutEffect)(() => {
    const { asPath, isReady } = router;
    const redirectTo = routes.Dashboard;

    if (!isReady || currentUserQuery.loading) return;

    if (isAdmin || !shouldApply) {
      setGuardPassed(true);
      return;
    }

    log.info(`OrgAdminGuard redirecting to ${redirectTo}`, {
      isAdmin,
      redirectFrom: asPath,
      redirectTo,
    });

    const redirectCfg: SpkPath = {
      pathname: redirectTo,
    };

    setGuardPassed(false);
    if (!isServer()) router.replace(redirectCfg);
  }, [
    currentUser?.verified,
    router,
    shouldApply,
    currentUserQuery.loading,
    isAdmin,
  ]);

  if (shouldApply && !guardPassed) return null;
  return <>{children}</>;
};
