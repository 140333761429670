import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log, isNullish } from '@spoke/common';
import { useReplaceGoalDataMutation } from '@spoke/graphql';

export const useReplaceImprovementGoalDataHandler = () => {
  const [replaceGoalData] = useReplaceGoalDataMutation();
  const [toast] = useToast();

  const handleReplaceGoalData = useCallback(
    async (data: {
      newValue: number;
      goalDataId: string;
    }): Promise<boolean> => {
      const { newValue, goalDataId } = data;

      if (!goalDataId || isNullish(newValue)) {
        log.error('Missing critical data to replace improvement goal data', {
          newValue,
          goalDataId,
        });
        return false;
      }

      log.info('Replacing improvement goal data', { data });

      const { errors } = await replaceGoalData({
        variables: { goalDataId, newValue },
      });

      if (errors) {
        log.error('replaceImprovementGoalData call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [replaceGoalData, toast]
  );

  return [handleReplaceGoalData];
};
