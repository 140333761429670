import { FC } from 'react';
import { ViewPage } from '@atlaskit/embedded-confluence';
import {
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  ModalProps,
  Modal,
  ModalOverlay,
} from '@spoke/common';
import { ConfluencePageUri } from '@spoke/graphql';

type Props = {
  pageUri?: ConfluencePageUri | null;
};
export const ConfluenceEmbeddedPageModalContent: FC<Props> = ({ pageUri }) => (
  <ModalContent ml={3} p={8} pb={5} maxW={650}>
    <ModalCloseButton />
    <ModalBody p={0} w="full">
      {pageUri && (
        <Flex
          h={650}
          alignItems="stretch"
          justifyContent="stretch"
          flexDir="column"
          w="full"
        >
          <ViewPage
            contentId={pageUri.contentId}
            locale="en-US"
            hostname={pageUri.hostname}
            parentProduct="scatterspoke"
            spaceKey={pageUri.spaceKey}
          />
        </Flex>
      )}
    </ModalBody>
  </ModalContent>
);

type ConfluenceEmbeddedPageModalProps = Omit<ModalProps, 'children'> & Props;
export const ConfluenceEmbeddedPageModal: FC<
  ConfluenceEmbeddedPageModalProps
> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <ConfluenceEmbeddedPageModalContent {...props} />
  </Modal>
);
