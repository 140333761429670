import { ReactElement, FC } from 'react';
import { SpkAssetKey, useAssets } from '../hooks';
import { NO_OP } from '../util';
import { Box } from './Box';
import { FlexProps, Flex } from './Flex';
import { Image } from './Image';
import { Text } from './Text';

type StepsTutorialStep = { image: SpkAssetKey; text: string };

export type StepsTutorialProps = FlexProps & {
  title: string;
  subtitle: string;
  steps: StepsTutorialStep[];
  onHeaderClick?: () => void;
  footer?: ReactElement;
};
export const StepsTutorial: FC<StepsTutorialProps> = ({
  steps,
  subtitle,
  title,
  footer,
  onHeaderClick,
  ...rest
}) => {
  const assets = useAssets();

  return (
    <Flex flexDir="column" gap={8} {...rest}>
      <Flex
        flexDir="column"
        onClick={onHeaderClick ?? NO_OP}
        cursor={onHeaderClick ? 'pointer' : 'default'}
      >
        <Text fontWeight={500} color="gray.900">
          {title}
        </Text>
        <Text color="gray.500">{subtitle}</Text>
      </Flex>
      {Boolean(steps.length) && (
        <Flex justifyContent="space-between" gap={3}>
          {steps.map(({ image, text }, index) => (
            <Box key={index} w={`${100 / steps.length}%`}>
              <Image src={assets[image]} alt={`step ${index + 1}`} />
              <Flex direction="row" mt={2} gap={2}>
                <Flex
                  borderRadius="50%"
                  minW="24px"
                  h="24px"
                  bg="primary.25"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text fontSize={12} color="primary.500">
                    {index + 1}
                  </Text>
                </Flex>
                <Text color="gray.500" fontSize={14}>
                  {text}
                </Text>
              </Flex>
            </Box>
          ))}
        </Flex>
      )}

      {footer}
    </Flex>
  );
};
