import { makeVar, useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import {
  useNetworkContext,
  useInstanceCount,
  SpkTime,
  uniqueBy,
  sortByCreatedAt,
  AscDesc,
  log,
} from '@spoke/common';
import {
  ImprovementGoalDataUpdatesByTeamIdSubscription,
  ImprovementGoalDataUpdatesByTeamIdDocument,
  ImprovementGoalsByTeamIdDocument,
  ImprovementGoalsByTeamIdQuery,
  ImprovementGoalSummaryQuery,
  ImprovementGoalSummaryDocument,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

export type UseImprovementGoalsSubscriptionConfig = {
  takeLastNPeriodsWithData: number;
  /**
   * Whether or not to start a GraphQL subscription, defaults to false
   */
  subscribeToChanges?: boolean;
  /**
   * Whether or not to unsubscribe on unmount if subscribed, defaults to false
   * Note that if true, regardless of whether has initialized a sub or not, will still kill
   * subscriptions made by other instances of this hook if subscription variables match
   */
  keepSubscribed?: boolean;
};

const DEFAULT_HOOK_CONFIG: UseImprovementGoalsSubscriptionConfig = {
  takeLastNPeriodsWithData: 5,
  subscribeToChanges: false,
  keepSubscribed: false,
};

/**
 * Keeps references for all unsubscription functions for active subs, keyed by `${teamId}-${takeLastNPeriodsWithData}`
 */
const unsubscribeFunctionsVar = makeVar<Record<string, (() => void)[]>>({});
const isSubscribed = (subscriptionKey: string) =>
  Object.keys(unsubscribeFunctionsVar()).includes(subscriptionKey);

/**
 * This hook manages the lifecycle of a subscription for ImprovementGoalData,
 * and updating queries that need to react to that (when manual updating is needed)
 * @note - this file ended up pretty confusing but it's a simple idea behind it.
 */
export const useImprovementGoalsSubscription = (
  hookConfig: Partial<UseImprovementGoalsSubscriptionConfig> = DEFAULT_HOOK_CONFIG
) => {
  const [team] = useCurrentTeam();
  const teamId = team?.id;

  const { subscribeToChanges, keepSubscribed, takeLastNPeriodsWithData } =
    Object.assign({}, DEFAULT_HOOK_CONFIG, hookConfig);

  const { shouldPoll } = useNetworkContext();
  const apollo = useApolloClient();

  const [getInstanceCount] = useInstanceCount(
    `useImprovementGoals-${teamId}-${takeLastNPeriodsWithData}`
  );

  useEffect(() => {
    const subscriptionKey = `${teamId}-${takeLastNPeriodsWithData}`;
    const isAlreadySubscribed = isSubscribed(subscriptionKey);

    const shouldSubscribe =
      subscribeToChanges &&
      teamId &&
      !shouldPoll &&
      getInstanceCount() > 0 &&
      !isAlreadySubscribed;

    if (shouldSubscribe) {
      log.info(
        `Starting ImprovementGoalData by teamId subscription taking last ${takeLastNPeriodsWithData} periods`,
        { teamId, takeLastNPeriodsWithData }
      );
      const vars = {
        teamId: teamId ?? '',
        limit: 20,
        takeLastNPeriodsWithData,
        offset: 0,
        utcOffsetMs: SpkTime.getUtcOffsetMs(),
      };
      const observable =
        apollo.subscribe<ImprovementGoalDataUpdatesByTeamIdSubscription>({
          query: ImprovementGoalDataUpdatesByTeamIdDocument,
          variables: vars,
        });

      const subscription = observable.subscribe((subscriptionData) => {
        const prevData = apollo.readQuery({
          query: ImprovementGoalsByTeamIdDocument,
          variables: vars,
        });

        const updatedGoals =
          subscriptionData.data?.improvementGoalDataUpdatesByTeamId;
        if (!updatedGoals?.length) return;
        const prevGoals =
          prevData?.teamById?.improvementGoals?.improvementGoals ?? [];

        const newGoals = uniqueBy([...prevGoals, ...updatedGoals], 'id').sort(
          sortByCreatedAt(AscDesc.Desc)
        );
        const newTotalSize = newGoals.length;

        // Updating queries manually because we're merging lists. This is kinda fragile in
        // the sense we're counting all queries need the same data. Could refactor eventually
        // to merge previous and new data for each individually

        apollo.cache.updateQuery<ImprovementGoalsByTeamIdQuery>(
          { query: ImprovementGoalsByTeamIdDocument, variables: vars },
          () => ({
            __typename: 'Query',
            teamById: {
              __typename: 'Team',
              id: prevData?.teamById?.id ?? teamId ?? '',
              improvementGoals: {
                __typename: 'TeamImprovementGoalResults',
                improvementGoals: newGoals,
                totalSize: newTotalSize,
              },
            },
          })
        );

        apollo.cache.updateQuery<ImprovementGoalSummaryQuery>(
          {
            query: ImprovementGoalSummaryDocument,
            variables: vars,
          },
          (prevSummary) => ({
            __typename: 'Query',
            teamById: {
              __typename: 'Team',
              id: prevData?.teamById?.id ?? teamId ?? '',
              improvementGoals: {
                __typename: 'TeamImprovementGoalResults',
                improvementGoals: newGoals,
                totalSize: newTotalSize,
                insight:
                  prevSummary?.teamById?.improvementGoals.insight ?? null,
              },
            },
          })
        );
      });

      unsubscribeFunctionsVar({
        ...unsubscribeFunctionsVar(),
        [subscriptionKey]: [subscription.unsubscribe.bind(subscription)],
      });
    }

    return () => {
      const isStillSubscribed = isSubscribed(subscriptionKey);
      const isLastInstance = getInstanceCount() === 0;
      const shouldUnsubscribe =
        isStillSubscribed && isLastInstance && !keepSubscribed;
      if (shouldUnsubscribe) {
        // No instances with matching variables are mounted, so unsubscribe
        log.info(
          `Stopping ImprovementGoalData by teamId subscription taking last ${takeLastNPeriodsWithData} periods`,
          { teamId, takeLastNPeriodsWithData }
        );
        const functions = unsubscribeFunctionsVar();
        for (const unsubscribe of functions[subscriptionKey]) unsubscribe();
        delete functions[subscriptionKey];
        unsubscribeFunctionsVar(functions);
      }
    };
  }, [
    teamId,
    takeLastNPeriodsWithData,
    getInstanceCount,
    shouldPoll,
    subscribeToChanges,
    keepSubscribed,
    apollo,
  ]);
};
