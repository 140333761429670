import { Flex, Image, Text, useAssets } from '@spoke/common';

export const ExportBoardEmptyState = () => {
  const { illustrationCanvas } = useAssets();

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      layerStyle="outlineGray"
      py={12}
      justifyContent="center"
      w="full"
    >
      <Image src={illustrationCanvas} w={200} alt="No cards to export" />
      <Text fontSize={18} fontWeight={500} color="gray.700" mt={4}>
        No cards to export
      </Text>
      <Text fontSize={14} color="gray.600">
        Try creating some cards first
      </Text>
    </Flex>
  );
};
