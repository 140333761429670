import { StackProps } from '@chakra-ui/react';
import { FC } from 'react';
import { AiOutlineWarning } from 'react-icons/ai';
import {
  useSourceControlRateLimitLock,
  useProjectManagementRateLimitLock,
  DISPLAY_NAME_BY_SOURCE_CONTROL_ORIGIN,
  DISPLAY_NAME_BY_WORK_ITEM_ORIGIN,
  VStack,
  Alert,
  Icon,
  Text,
} from '@spoke/common';

/**
 * This works on global state so easier to just have it as a smart component
 */
export const RateLimitedMetricsAlerts: FC<StackProps> = (props) => {
  const [rateLimitedSourceControlProviders] = useSourceControlRateLimitLock();
  const [rateLimitedProjectManagementProviders] =
    useProjectManagementRateLimitLock();

  const rateLimitedProviders = (rateLimitedSourceControlProviders ?? [])
    .map((p) => DISPLAY_NAME_BY_SOURCE_CONTROL_ORIGIN[p])
    .concat(
      (rateLimitedProjectManagementProviders ?? []).map(
        (p) => DISPLAY_NAME_BY_WORK_ITEM_ORIGIN[p]
      )
    );

  if (!rateLimitedProviders.length) return null;

  return (
    <VStack {...props}>
      {rateLimitedProviders.map((displayName) => (
        <Alert
          key={displayName}
          bg="orange.100"
          color="orange.800"
          h="fit-content"
        >
          <Icon
            as={AiOutlineWarning}
            mr={2}
            color="orange.800"
            w={5}
            h={5}
            borderRadius="sm"
          />
          <Text as="span">
            Our{' '}
            <Text as="span" fontWeight={500}>
              {displayName}
            </Text>{' '}
            integration is currently unavailable. Please try again in a few
            moments.
          </Text>
        </Alert>
      ))}
    </VStack>
  );
};
