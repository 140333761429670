import { randomString } from '../etc';
import { convertCardCommentToActionItemComment } from './convertCardCommentToActionItemComment';
import {
  ActionItem,
  ActionItemChild,
  ActionItemStatus,
  Card,
} from '@spoke/graphql';

export const convertCardToActionItem = (card: Partial<Card>): ActionItem => ({
  __typename: 'ActionItem',
  id: randomString(),
  archived: card.archived || false,
  authorId: card.authorId || '',
  status: ActionItemStatus.Unresolved,
  teamId: '',
  title: card.text || '',
  description: '',
  assignees: [],
  improvementGoals: [],
  author: card.author,
  board: null,
  boardId: null,
  dueDate: null,
  jiraIssueLink: null,
  resolvedAt: null,
  team: null,
  updatedAt: card.updatedAt || Date.now(),
  createdAt: card.createdAt || Date.now(),
  comments:
    card.comments?.filter(Boolean).map(convertCardCommentToActionItemComment) ??
    [],
  children:
    (card.children
      ?.map((c) => (c ? { id: c.id, text: c.text } : c))
      .filter(Boolean) as ActionItemChild[]) ?? [],
});
