import { FC } from 'react';
import { FiInbox } from 'react-icons/fi';
import { useParkingLot } from '../queries';
import { ParkingLotModalBody } from './ParkingLotModalBody';
import { useCurrentTeam, useTeamType } from '@spoke/user';
import {
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  Badge,
  ModalBody,
  ModalProps,
  Modal,
  ModalOverlay,
  Text,
} from '@spoke/common';

export const ParkingLotModalContent = () => {
  const [currentTeam] = useCurrentTeam();
  const [parkingLotItems] = useParkingLot();
  const { capitalizedTeamType } = useTeamType();

  return (
    <ModalContent p={8} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Icon as={FiInbox} w={6} h={6} />
          <Heading mb={1} fontSize={24} color="gray.900">
            Parking Lot
          </Heading>
          <Badge
            bg="gray.100"
            color="gray.800"
            pt="1px"
            fontSize={18}
            lineHeight="20px"
          >
            {parkingLotItems?.length}
          </Badge>
          <Text color="gray.600">
            ({currentTeam?.name} {capitalizedTeamType})
          </Text>
        </HStack>
        <Text fontWeight={500} fontSize={16}>
          Add notes or save items for future retrospectives
        </Text>
      </ModalHeader>
      <ModalBody p={0}>
        <ParkingLotModalBody />
      </ModalBody>
    </ModalContent>
  );
};

type ParkingLotModalProps = Omit<ModalProps, 'children'>;
export const ParkingLotModal: FC<ParkingLotModalProps> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <ParkingLotModalContent />
  </Modal>
);
