import {
  ComponentStyleConfig,
  PopoverTrigger as ChakraPopoverTrigger,
} from '@chakra-ui/react';
import { FC } from 'react';

export const PopoverTriggerComponentStyle: ComponentStyleConfig = {};

export const PopoverTrigger: FC = (props) => (
  <ChakraPopoverTrigger {...props} />
);
