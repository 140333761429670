import { FC } from 'react';
import { Flex, capitalizeFirstLetter, Skeleton, Text } from '@spoke/common';

const COLOR_BY_TYPE = {
  positive: 'green.600',
  negative: 'negativeRed',
  neutral: 'neutralYellow',
} as const;

type Props = {
  type: 'positive' | 'negative' | 'neutral';
  percentage: number;
  loading?: boolean;
  topics: string[];
};
export const SentimentTopicsCard: FC<Props> = ({
  percentage,
  topics,
  loading,
  type,
}) => (
  <Flex
    overflow="hidden"
    borderRadius="md"
    layerStyle="outlineGray"
    flex={1}
    p={0}
  >
    <Flex
      minH="full"
      minW={1}
      bg={loading ? 'gray.300' : COLOR_BY_TYPE[type]}
      transition="background 0.2s ease-out"
    />
    <Flex flexDir="column" p={3} w="full">
      <Text fontSize={14} color="gray.500">
        {capitalizeFirstLetter(type)}
      </Text>
      <Skeleton
        isLoaded={!loading}
        minW={loading ? 220 : 0}
        minH={loading ? '18px' : 0}
        mb={loading ? '3px' : 0}
        w="fit-content"
      >
        {!loading && (
          <Flex gap={1} color="gray.500" fontSize={14}>
            <Text color="gray.700">{percentage}%</Text> topics discussed were{' '}
            <Text color="gray.700">{type}</Text>
          </Flex>
        )}
      </Skeleton>
      {Boolean(topics?.length) && (
        <>
          <Text mt={2} fontSize={14} color="gray.500">
            Most discussed
          </Text>
          <Flex gap={1} color="gray.500">
            {Array(3)
              .fill(null)
              .map((_, idx) => (
                <Skeleton
                  isLoaded={!loading}
                  key={idx}
                  minH="18px"
                  minW={loading ? 20 : 0}
                  mt={loading ? '6px' : 0}
                  w="fit-content"
                >
                  {Boolean(topics[idx] && !loading) && (
                    <Text
                      borderRadius="md"
                      bg="gray.100"
                      color="gray.700"
                      fontSize={12}
                      px={1}
                      display="inline-flex"
                    >
                      {topics[idx]}
                    </Text>
                  )}
                </Skeleton>
              ))}
          </Flex>{' '}
        </>
      )}
    </Flex>
  </Flex>
);
