import { useCallback } from 'react';
import { useBillingDetails } from './useBillingDetails';
import { useCurrentOrg } from './useCurrentOrg';
import { useCancelSubscriptionMutation, PaymentStatus } from '@spoke/graphql';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';

export const useCancelSubscriptionHandler = () => {
  const [org] = useCurrentOrg();
  const [toast] = useToast();
  const [, { refetch: refetchBilling }] = useBillingDetails();
  const [cancelSubscriptionMutation] = useCancelSubscriptionMutation();

  const orgId = org?.id ?? '';
  const orgStatus = org?.status ?? '';

  const handleCancelSubscription = useCallback(async () => {
    if (!orgId) {
      log.error('Cannot switch orgs without loaded current orgId', {
        orgId,
      });
      return;
    }
    log.info(`Canceling subscription for organizationId [${orgId}]`);

    const { errors } = await cancelSubscriptionMutation({
      variables: { organizationId: orgId },
    });

    if (errors) {
      log.warn('Handled subscription canceling GraphQL error', errors);
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const { data: updatedBillingDetails } = await refetchBilling();

    const stillHasRemainingTime = Boolean(
      updatedBillingDetails?.billingDetails?.canceled ||
        orgStatus !== PaymentStatus.TrialExpired
    );
    if (stillHasRemainingTime) {
      toast({
        status: 'success',
        title: 'Subscription cancelled',
        description:
          'You will be downgraded to the free plan at the end of your billing cycle.',
      });
    } else {
      toast({
        status: 'success',
        title: 'Subscription cancelled',
        description: 'Your are now on the free plan.',
      });
    }
  }, [orgId, cancelSubscriptionMutation, refetchBilling, toast, orgStatus]);

  return [handleCancelSubscription];
};
