// False positive
// eslint-disable-next-line import/no-unresolved
import { stringify } from 'csv-stringify/sync';
import { isGroup } from './isGroup';
import { BoardFragmentFragment, Team } from '@spoke/graphql';

/**
 * @deprecated This should live in backend
 */
export const generateBoardCsv = async (
  team: Team,
  board: BoardFragmentFragment
): Promise<string> => {
  const data = [] as string[][];

  if (!board?.lists || !team?.name) return '';

  for (const column of board.lists) {
    if (!column?.cards) continue;
    for (const card of column.cards) {
      if (!card) continue;
      const row = [] as string[];
      if (isGroup(card) === null) {
        row.push(team.name);
        row.push(board.name);
        row.push(column.name);
        row.push(card.text);
        row.push(card.votes ? `${card.votes.length}` : `${0}`);
        row.push(
          card.comments
            ? card.comments.map((comment) => comment?.text).join(' | ')
            : ''
        );
      } else if (isGroup(card) !== null) {
        let groupCardtext = '';
        if (!card.children) continue;
        for (const groupCard of card.children) {
          if (!groupCard) continue;
          groupCardtext += groupCard.text + ' | ';
        }
        row.push(team.name);
        row.push(board.name);
        row.push(column.name);
        row.push(`(${card.text}) - ${groupCardtext}`);
        row.push(card.votes ? `${card.votes.length}` : `${0}`);
        row.push(
          card.comments?.map((comment) => comment?.text).join(' | ') ?? ''
        );
      }
      data.push(row);
    }
  }

  const HEADERS = [
    'Team Name',
    'Retrospective Name',
    'Column',
    'Card',
    'Votes',
    'Action Item',
    'Comments',
  ];

  return stringify([HEADERS].concat(data));
};
