import { ApolloCache } from '@apollo/client';
import { log } from '../../../SpkLog';
import {
  ActionItem,
  ActionItemFragmentFragment,
  ActionItemFragmentFragmentDoc,
} from '@spoke/graphql';

type GetActionItemFromCacheArgs = {
  actionItemId: string;
  cache: ApolloCache<object>;
};
export function getActionItemFromCache({
  actionItemId,
  cache,
}: GetActionItemFromCacheArgs): ActionItem | null {
  const cachedData =
    (cache.readFragment({
      fragment: ActionItemFragmentFragmentDoc,
      fragmentName: 'ActionItemFragment',
      id: `ActionItem:${actionItemId}`,
      returnPartialData: true,
    }) as ActionItemFragmentFragment as ActionItem) || null;
  if (!cachedData) {
    log.error('Unsuccessful attempt to read an ActionItemFragment from cache', {
      actionItemId,
      cachedData,
    });
    return null;
  }
  return cachedData;
}
