import { MutationUpdaterFn } from '@apollo/client';
import { useCallback } from 'react';
import { useFlowMetrics } from '../queries';
import { useToast, TOAST_ERROR_GENERIC, NO_OP, log } from '@spoke/common';
import {
  useUpdateLinkedJiraBoardsMutation,
  UpdateLinkedJiraBoardsMutation,
  LinkedJiraBoardsQuery,
  LinkedJiraBoardsDocument,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

export const useUpdateLinkedJiraBoardsHandler = () => {
  const [_, flowMetricsQuery] = useFlowMetrics();
  const [updateLinkedJiraBoards] = useUpdateLinkedJiraBoardsMutation();
  const [currentTeam] = useCurrentTeam();
  const [toast] = useToast();

  const handleUpdateLinkedJiraBoards = useCallback(
    async ({ jiraBoardIds }: { jiraBoardIds: string[] }): Promise<boolean> => {
      const teamId = currentTeam?.id;
      if (!Array.isArray(jiraBoardIds) || !teamId) {
        log.error('Missing critical data to updateLinkedJiraBoards', {
          jiraBoardIds,
          teamId,
        });
        return false;
      }

      log.info('Updating currently linked Jira boards', {
        jiraBoardIds,
        teamId,
      });

      const { errors } = await updateLinkedJiraBoards({
        variables: { jiraBoardIds, teamId },
        update: updateLinkedJiraBoardsQueryAfterMutation,
      });

      if (errors) {
        log.error('updateLinkedJiraBoards call responded with errors', {
          jiraBoardIds,
          teamId,
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      toast({
        status: 'success',
        title: 'Success',
        description: 'Successfully updated linked Jira boards',
      });

      flowMetricsQuery.refetch().catch(NO_OP);

      return true;
    },
    [currentTeam?.id, updateLinkedJiraBoards, toast, flowMetricsQuery]
  );

  return [handleUpdateLinkedJiraBoards];
};

type UpdateLinkedJiraBoardsQueryAfterMutation =
  MutationUpdaterFn<UpdateLinkedJiraBoardsMutation>;

const updateLinkedJiraBoardsQueryAfterMutation: UpdateLinkedJiraBoardsQueryAfterMutation =
  (cache, { data }) => {
    cache.updateQuery<LinkedJiraBoardsQuery>(
      {
        query: LinkedJiraBoardsDocument,
        variables: { teamId: data?.updateLinkedJiraBoards.id },
      },
      (prev) => ({
        ...prev,
        team: {
          id: data?.updateLinkedJiraBoards.id ?? '',
          linkedJiraBoards: data?.updateLinkedJiraBoards.linkedJiraBoards ?? [],
        },
      })
    );
  };
