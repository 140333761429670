import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BsSearch } from 'react-icons/bs';
import { MdOutlinedFlag } from 'react-icons/md';
import { RiChatPollLine } from 'react-icons/ri';
import { useImprovementGoals, useImprovementGoalTypes } from '../../queries';
import {
  ImprovementGoalTypeFragmentFragment,
  ImprovementGoalTypeCategory,
  ImprovementGoalType,
} from '@spoke/graphql';
import {
  isServer,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  ModalBody,
  Flex,
  InputGroup,
  InputLeftElement,
  Button,
  Badge,
  SpkLink,
  Skeleton,
  Tooltip,
  Circle,
  Input,
  Text,
  Image,
  Box,
  Link,
  useAssets,
} from '@spoke/common';

enum FilterType {
  None = 'NONE',
  Focus = 'FOCUS',
  Framework = 'FRAMEWORK',
  Integration = 'INTEGRATION',
}

enum Focus {
  Satisfaction = 'Satisfaction',
  Communication = 'Comm & Collab',
  Performance = 'Performance',
  Efficiency = 'Efficiency & Flow',
  Activity = 'Activity',
}

enum AgileFramework {
  Space = 'SPACE',
  Dora = 'DORA',
  Agile = 'AGILE',
}

enum GoalIntegration {
  SpokePolls = 'Compatible with ScatterSpoke Polls',
  Jira = 'Compatible with Jira Cloud',
  GitHub = 'Compatible with GitHub',
}

type Filter = {
  type: FilterType;
  term: string;
  nameTerm: string;
};

const filterGoalsByName =
  (filter: Filter) => (goalType?: ImprovementGoalTypeFragmentFragment | null) =>
    !filter.nameTerm ||
    goalType?.name.toLowerCase().includes(filter.nameTerm.toLowerCase());

const filterGoals =
  (filter: Filter) =>
  (goalType?: ImprovementGoalTypeFragmentFragment | null) => {
    const { categories, subCategory, isPollable } = goalType || {};
    const { type, term } = filter;

    if (type === FilterType.None) return true;
    if (type === FilterType.Focus) return subCategory === term;

    if (type === FilterType.Framework) {
      return categories?.includes(term as ImprovementGoalTypeCategory);
    }

    if (type === FilterType.Integration) {
      if (term === GoalIntegration.SpokePolls) return isPollable;
    }

    return false;
  };

const sortGoalsByNumberOfIntegrations = (
  goalA?: ImprovementGoalTypeFragmentFragment | null,
  goalB?: ImprovementGoalTypeFragmentFragment | null
): number => {
  const aIntegrationCount = [goalA?.isPollable].filter(Boolean);
  const bIntegrationCount = [goalB?.isPollable].filter(Boolean);
  return bIntegrationCount.length - aIntegrationCount.length;
};

const sortGoalsByAlreadySet =
  (teamGoalTypeIds: string[]) =>
  (
    goalA?: ImprovementGoalTypeFragmentFragment | null,
    goalB?: ImprovementGoalTypeFragmentFragment | null
  ) => {
    const aAlreadySet = teamGoalTypeIds.includes(goalA?.id || '');
    const bAlreadySet = teamGoalTypeIds.includes(goalB?.id || '');
    return Number(aAlreadySet) - Number(bAlreadySet);
  };

const FILTER_OPTIONS: Partial<
  Record<FilterType, Array<{ term: string; label: string }>>
> = {
  [FilterType.Focus]: [
    { term: Focus.Satisfaction, label: 'Satisfaction' },
    { term: Focus.Performance, label: 'Performance' },
    { term: Focus.Activity, label: 'Activity' },
    { term: Focus.Communication, label: 'Communication and Collaboration' },
    { term: Focus.Efficiency, label: 'Efficiency and Flow' },
  ],
  [FilterType.Framework]: [
    { term: AgileFramework.Space, label: 'SPACE' },
    { term: AgileFramework.Dora, label: 'DORA' },
    { term: AgileFramework.Agile, label: 'Agile' },
  ],
  // Coming soon
  // [FilterType.Integration]: [
  //   { term: GoalIntegration.SpokePolls, label: 'ScatterSpoke Polls' },
  //   { term: GoalIntegration.Jira, label: 'Jira' },
  //   { term: GoalIntegration.GitHub, label: 'GitHub' },
  // ],
};

const FRAMEWORK_INFO: Record<
  AgileFramework,
  { displayName: string; description?: string; learnMoreUrl?: string }
> = {
  [AgileFramework.Space]: {
    displayName: 'SPACE',
    description:
      'The SPACE framework was created to capture several qualities of developer productivity. Productivity cannot be measured by a single metric or activity data alone. We can think about productivity in a much larger frame that includes team context and, more importantly, people.',
    learnMoreUrl:
      'https://support.scatterspoke.com/en/knowledge/what-is-the-s.p.a.c.e.-framework',
  },
  [AgileFramework.Dora]: {
    displayName: 'DORA',
    description:
      'The DevOps Research and Assessment (DORA) team spent six years to identify four key performance indicators for software development teams. Setting these performance metrics as the baseline for your organization can help improve efficiency and effectiveness of delivering work to customers.',
    learnMoreUrl:
      'https://support.scatterspoke.com/en/knowledge/dora-framework-introduction',
  },
  [AgileFramework.Agile]: {
    displayName: 'Agile',
    description:
      'Agile is an iterative approach to project management where teams reflect and review after every iteration.  Not only does this allow them change direction but they can see if there was anything they could have done better and then adjust their strategy for the next iteration of work.  ',
    learnMoreUrl:
      'https://support.scatterspoke.com/en/knowledge/agile-metrics-overview',
  },
};

type Props = {
  onSelect: (goalType: ImprovementGoalType) => void;
  onCancel: () => void;
};

// TODO this component lags a bit when first rendering because of the list size.
// If this turns into a problem we'll need to add list virtualization
// https://www.npmjs.com/package/react-virtualized
export const CreateGoalWizardTypeSelector: FC<Props> = ({ onSelect }) => {
  const [{ allGoals: teamGoals }] = useImprovementGoals();
  const teamGoalTypeIds = teamGoals?.map((goal) => goal.type.id) ?? [];

  const { illustrationWriting } = useAssets();

  const [goalTypes, { loading }] = useImprovementGoalTypes();

  const [filter, setFilter] = useState({
    type: FilterType.None,
    term: '',
    nameTerm: '',
  });

  const goalListRef = useRef<HTMLDivElement | null>(null);

  const filteredGoals = goalTypes
    ?.filter(filterGoalsByName(filter))
    .filter(filterGoals(filter))
    .sort(sortGoalsByNumberOfIntegrations)
    .sort(sortGoalsByAlreadySet(teamGoalTypeIds));

  const onFilterChange = (type: FilterType, term?: string) => {
    setFilter((prev) => ({ ...prev, type, term: term ?? prev.term }));
  };

  const onNameTermChange = (term: string) => {
    setFilter((prev) => ({ ...prev, nameTerm: term }));
  };

  const isSelected = (type: FilterType, term?: string) => {
    if (type === FilterType.None) {
      return type === filter.type;
    }
    return filter.type === type && filter.term === term;
  };

  const getColor = (type: FilterType, term?: string) =>
    isSelected(type, term) ? 'primary.500' : 'gray.500';

  const getWeight = (type: FilterType, term?: string) =>
    isSelected(type, term) ? '500' : '400';

  const getCount = (type: FilterType, term?: string) =>
    goalTypes
      ?.filter(
        filterGoals({ type, term: term || '', nameTerm: filter.nameTerm })
      )
      .filter(
        filterGoalsByName({ type, term: term || '', nameTerm: filter.nameTerm })
      ).length || 0;

  const teamAlreadyHasGoal = (goalTypeId: string) =>
    teamGoalTypeIds.includes(goalTypeId);

  const frameworkInfo =
    filter.type === FilterType.Framework
      ? FRAMEWORK_INFO[filter.term as AgileFramework]
      : null;

  (isServer() ? useEffect : useLayoutEffect)(() => {
    if (!goalListRef.current) return;
    goalListRef.current.scrollTop = 0;
  }, [filter.type, filter.term]);

  return (
    <ModalContent p={8} maxW={800}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Icon as={MdOutlinedFlag} w={6} h={6} />
          <Heading mb={1} fontSize={24} color="gray.900">
            Goals
          </Heading>
        </HStack>
      </ModalHeader>
      <ModalBody d="flex" p={0} gap={3}>
        <Flex flexDir="column" alignItems="flex-start" flex={2}>
          <InputGroup>
            <InputLeftElement>
              <Icon as={BsSearch} color="gray.500" w={4} h={4} />
            </InputLeftElement>
            <Input
              value={filter.nameTerm}
              onChange={(e) => onNameTermChange(e.target.value)}
              placeholder="Search goals"
            />
          </InputGroup>
          <HStack mt={5} w="full">
            <Button
              fontWeight={400}
              variant="unstyled"
              h="fit-content"
              w="full"
              textAlign="left"
              borderRadius="sm"
              color={isSelected(FilterType.None) ? 'primary.500' : 'gray.500'}
              onClick={() => onFilterChange(FilterType.None)}
            >
              All goals
            </Button>
            <Badge colorScheme="gray" color="gray.500" bg="gray.100">
              {getCount(FilterType.None)}
            </Badge>
          </HStack>
          {Object.entries(FILTER_OPTIONS).map(([type, options]) => {
            const castType = type as FilterType;
            return (
              <Flex flexDir="column" w="full" mt={5} key={type}>
                <Text fontWeight={500} color="gray.700">
                  Filter by {type.toLowerCase()}
                </Text>
                {options.map((option) => (
                  <HStack w="full" key={option.term}>
                    <Button
                      variant="unstyled"
                      h="fit-content"
                      borderRadius="sm"
                      w="full"
                      whiteSpace="pre-wrap"
                      textAlign="left"
                      color={getColor(castType, option.term)}
                      fontWeight={getWeight(castType, option.term)}
                      onClick={() => onFilterChange(castType, option.term)}
                    >
                      {option.label}
                    </Button>
                    <Badge colorScheme="gray" color="gray.500" bg="gray.100">
                      {getCount(castType, option.term)}
                    </Badge>
                  </HStack>
                ))}
              </Flex>
            );
          })}
        </Flex>
        <Flex
          flex={5}
          gap={3}
          w="full"
          flexDir="column"
          maxH={550}
          overflowY="auto"
          px={4}
          ref={goalListRef}
        >
          {Boolean(
            (loading || filteredGoals?.length) &&
              filter.type === FilterType.None
          ) && (
            <Text fontSize={18} fontWeight={500}>
              All goals
              <Badge ml={2} colorScheme="gray" color="gray.500" bg="gray.100">
                {getCount(FilterType.None)}
              </Badge>
            </Text>
          )}
          {Boolean(
            filteredGoals?.length &&
              (filter.type === FilterType.Focus ||
                filter.type === FilterType.Integration)
          ) && (
            <Text fontSize={18} fontWeight={500}>
              {filter.term}
              <Badge ml={2} colorScheme="gray" color="gray.500" bg="gray.100">
                {getCount(filter.type, filter.term)}
              </Badge>
            </Text>
          )}
          {Boolean(
            filteredGoals?.length &&
              filter.type === FilterType.Framework &&
              frameworkInfo
          ) && (
            <Flex flexDir="column" mb={1}>
              <Text fontSize={16} fontWeight={700} color="gray.900">
                {frameworkInfo?.displayName}
                <Badge ml={2} colorScheme="gray" color="gray.500" bg="gray.100">
                  {getCount(filter.type, filter.term)}
                </Badge>
              </Text>
              {frameworkInfo?.description && (
                <Text fontSize={14} color="gray.600" my={1}>
                  {frameworkInfo?.description}
                </Text>
              )}
              {frameworkInfo?.learnMoreUrl && (
                <SpkLink href={frameworkInfo?.learnMoreUrl as string} passHref>
                  <Link textDecor="underline" fontSize={14} target="_blank">
                    Learn more
                  </Link>
                </SpkLink>
              )}
            </Flex>
          )}
          {Boolean(!loading && !filteredGoals?.length) && (
            <Flex flexDir="column" mx="auto" mt={16} alignItems="center">
              <Image
                src={illustrationWriting}
                alt="No goal types found"
                w={150}
                mb={4}
              />
              <Text fontSize={20} fontWeight={500}>
                Nothing to show here...
              </Text>
              <Text fontSize={16} color="gray.500">
                Try changing your search criteria.
              </Text>
            </Flex>
          )}
          {loading && <Skeleton w="full" h={550} />}
          {Boolean(!loading && filteredGoals?.length) &&
            filteredGoals?.map((goalType) => {
              const alreadyHas = teamAlreadyHasGoal(goalType?.id || '');
              return !goalType ? null : (
                <Tooltip
                  variant="white"
                  placement="top"
                  hasArrow
                  openDelay={150}
                  key={goalType.id}
                  label={
                    alreadyHas ? 'This goal is already set for this team.' : ''
                  }
                >
                  <HStack
                    tabIndex={1}
                    w="full"
                    layerStyle="outlineGray"
                    justifyContent="space-between"
                    spacing={3}
                    role={alreadyHas ? undefined : 'group'}
                    _hover={alreadyHas ? {} : { bg: 'gray.50' }}
                    cursor={alreadyHas ? 'default' : 'pointer'}
                    bg={alreadyHas ? 'gray.100' : 'white'}
                    onClick={() => (alreadyHas ? null : onSelect(goalType))}
                  >
                    <Flex flexDir="column">
                      <HStack w="full">
                        <Text fontSize={15} fontWeight={500} color="gray.600">
                          {goalType.name}
                        </Text>
                        {goalType.isPollable && (
                          <Tooltip
                            variant="white"
                            placement="top"
                            hasArrow
                            maxW={150}
                            label={
                              <Text>
                                Compatible with{' '}
                                <Text fontWeight={600} as="span">
                                  ScatterSpoke Polls
                                </Text>
                              </Text>
                            }
                          >
                            <Box tabIndex={-1}>
                              <Icon
                                transform="translateY(2px)"
                                as={RiChatPollLine}
                                color="gray.700"
                                h={4}
                                w={4}
                              />
                            </Box>
                          </Tooltip>
                        )}
                      </HStack>
                      <Text noOfLines={2} color="gray.500" fontSize={14}>
                        {goalType.description}
                      </Text>
                    </Flex>
                    <Circle
                      opacity={0}
                      _groupHover={{ opacity: 1 }}
                      _hover={{
                        bg: 'primary.500',
                        color: 'white',
                        borderColor: 'primary.500',
                        cursor: 'pointer',
                      }}
                      transition="opacity 0.1s ease-out, background-color 0.1s ease-out"
                      bg="transparent"
                      borderColor="gray.500"
                      borderStyle="solid"
                      borderWidth={1}
                      color="gray.500"
                      p={1}
                    >
                      <Icon as={AiOutlineArrowRight} w={4} h={4} />
                    </Circle>
                  </HStack>
                </Tooltip>
              );
            })}
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};
