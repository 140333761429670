import { useReactiveVar } from '@apollo/client';
import { ToastId, useToast, UseToastOptions } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { appMustUpdateVar } from '../../context';
import { log } from '../../SpkLog';
import { Toast, Button, Text } from '../../ui';
import { NO_OP } from '../../util';

export const AppUpdater = () => {
  const appMustUpdate = useReactiveVar(appMustUpdateVar);
  const toast = useToast();
  const toastIdRef = useRef<ToastId | undefined>(undefined);

  const [seconds, setSeconds] = useState(50);

  const getCountdownToast = (secondsLeft: number) => () =>
    (
      <Toast
        isClosable={false}
        onClose={NO_OP}
        description=""
        title="Update available"
        bottom={2}
        status="info"
      >
        <Text>
          A new version of ScatterSpoke is available! You page will be refreshed
          in {secondsLeft} seconds, or you can{' '}
          <Button
            variant="link"
            textDecor="underline"
            onClick={() => window.location.reload()}
          >
            refresh it now.
          </Button>
        </Text>
      </Toast>
    );

  useEffect(() => {
    if (!appMustUpdate) return;
    log.warn('Update signal received. Showing update toast.', {
      appMustUpdate,
    });
    const interval = setInterval(() => {
      setSeconds((s) => s - 10);
    }, 10000);
    return () => clearInterval(interval);
  }, [appMustUpdate]);

  useEffect(() => {
    if (!appMustUpdate) return;
    if (seconds === 0) window.location.reload();
    const toastCfg: UseToastOptions = {
      duration: null,
      render: getCountdownToast(seconds),
    };
    if (!toastIdRef.current) toastIdRef.current = toast(toastCfg);
    else toast.update(toastIdRef.current, toastCfg);
  }, [seconds, toast, appMustUpdate]);

  return null;
};
