import { makeVar } from '@apollo/client';
import { useCallback, useRef, useState } from 'react';
import { DragStart, DragUpdate, DropResult } from 'react-beautiful-dnd';
import { log } from '../../SpkLog';

const getDraggableElement = (draggableId: string): Element | null => {
  const QUERY_ATTR = 'data-rbd-drag-handle-draggable-id';
  const domQuery = `[${QUERY_ATTR}='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);
  return draggedDOM;
};

const getDroppableElement = (draggableId: string): Element | null => {
  const QUERY_ATTR = 'data-rbd-droppable-id';
  const domQuery = `[${QUERY_ATTR}='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);
  return draggedDOM;
};

export type CustomDndPlaceholderProps = {
  droppableId: string;
  clientHeight: number;
  clientWidth: number;
  clientY: number;
  clientX: number;
} | null;

const shouldLagVar = makeVar<boolean>(false);

type UseCustomDndPlaceholderRespondersArgs = {
  isEnabled: boolean;
};
export const useCustomDndPlaceholderResponders = ({
  isEnabled = false,
}: UseCustomDndPlaceholderRespondersArgs) => {
  const [customDndPlaceholderProps, setCustomDndPlaceholderProps] =
    useState<CustomDndPlaceholderProps | null>(null);

  const handleDragStart = useCallback(
    (event: DragStart) => {
      if (!isEnabled) return;

      const draggedDOM = getDraggableElement(event.draggableId);

      if (!draggedDOM?.parentNode) {
        log.error('Failed to update custom placeholder', { event, draggedDOM });
        return;
      }

      const parentStyle = window.getComputedStyle(
        draggedDOM.parentNode as Element
      );

      const { clientHeight, clientWidth, parentNode } = draggedDOM;
      const sourceIndex = event.source.index;
      const clientY =
        parseFloat(parentStyle.paddingTop) +
        [...Array.from(parentNode.children)]
          .slice(0, sourceIndex)
          .reduce((total, curr) => {
            const style =
              (curr as any).currentStyle || window.getComputedStyle(curr);
            const marginBottom = parseFloat(style.marginBottom);
            return total + curr.clientHeight + marginBottom;
          }, 0);

      const newProps = {
        droppableId: event.source.droppableId,
        clientHeight,
        clientWidth,
        clientY,
        clientX: parseFloat(parentStyle.paddingLeft),
      };

      setCustomDndPlaceholderProps(newProps);
    },
    [isEnabled]
  );

  const dragUpdateTimeoutRef = useRef<NodeJS.Timeout>();

  const handleDragUpdate = useCallback(
    (event: DragUpdate) => {
      if (!isEnabled) return;

      // Little hack to prevent placeholder from taking "create group" placeholder
      // into account when calculating top offset
      if (event.combine) {
        setCustomDndPlaceholderProps(null);
        shouldLagVar(true);
        return;
      }

      if (shouldLagVar()) {
        dragUpdateTimeoutRef.current = setTimeout(
          () => handleDragUpdate(event),
          100
        );
        shouldLagVar(false);
        return;
      }

      if (dragUpdateTimeoutRef.current) {
        clearTimeout(dragUpdateTimeoutRef.current);
      }

      if (!event.destination) return;

      const draggableId = event.draggableId;
      const sourceDroppableId = event.source.droppableId;
      const droppableId = event.destination.droppableId;

      const draggableDOM = getDraggableElement(draggableId);
      const droppableDOM = getDroppableElement(droppableId);

      if (!draggableDOM || !droppableDOM) {
        log.error('Failed to update custom placeholder', {
          event,
          draggableDOM,
          droppableDOM,
        });
        return;
      }

      const { clientHeight, clientWidth } = draggableDOM;
      const destinationIndex = event.destination.index;
      const sourceIndex = event.source.index;

      const droppableStyle = window.getComputedStyle(droppableDOM);

      const cardsInListDOM = Array.from(droppableDOM.children);
      const movedItem = cardsInListDOM[sourceIndex];

      if (sourceDroppableId === droppableId) {
        cardsInListDOM.splice(sourceIndex, 1);
      }

      const updatedArray = [
        ...cardsInListDOM.slice(0, destinationIndex),
        movedItem,
        ...cardsInListDOM.slice(destinationIndex + 1),
      ];

      const clientY =
        parseFloat(droppableStyle.paddingTop) +
        updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
          const style =
            (curr as any).currentStyle || window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

      setCustomDndPlaceholderProps({
        droppableId: event.destination.droppableId,
        clientHeight,
        clientWidth,
        clientY,
        clientX: parseFloat(droppableStyle.paddingLeft),
      });
    },
    [isEnabled]
  );

  const handleDragEnd = useCallback((_result: DropResult) => {
    setCustomDndPlaceholderProps(null);
  }, []);

  return {
    handleDragStart,
    handleDragUpdate,
    handleDragEnd,
    customDndPlaceholderProps,
  };
};
