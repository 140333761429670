import { FC, useCallback, useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BiInfoCircle } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import {
  useToggleTooltipHintsHandler,
  useUpdateBoardNameHandler,
} from '../handlers';
import { useCurrentBoardTeam } from '../queries';
import { useAddParkingLotListHandler, useParkingLot } from '@spoke/parking-lot';
import {
  ModalProps,
  useCurrentBoard,
  useToast,
  useDebounce,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Heading,
  ModalBody,
  Flex,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Spinner,
  Icon,
  HStack,
  Tooltip,
  Switch,
  Button,
  Input,
  Text,
  Image,
} from '@spoke/common';

export const BoardSettings: FC<Omit<ModalProps, 'children'>> = (props) => {
  const [board] = useCurrentBoard();
  const [boardTeam] = useCurrentBoardTeam();
  const [parkingLot] = useParkingLot();
  const [toast] = useToast();

  const parkingLotItemCount = parkingLot?.length || 0;

  const [boardName, setBoardName] = useState('');

  const [boardNameSuccess, setBoardNameSuccess] = useState(false);
  const [boardNameLoading, setBoardNameLoading] = useState(false);

  const [addParkingLotListLoading, setAddParkingLotListLoading] =
    useState(false);

  const [handleToggleTooltipHints] = useToggleTooltipHintsHandler();
  const [handleUpdateBoardName] = useUpdateBoardNameHandler();
  const [addParkingLotList] = useAddParkingLotListHandler();

  useEffect(() => {
    const timeout = setTimeout(() => setBoardNameSuccess(false), 3000);
    return () => clearTimeout(timeout);
  }, [boardNameSuccess]);

  useEffect(() => {
    if (board?.name) setBoardName(board.name);
  }, [board?.name]);

  const updateBoardName = useCallback(
    async (newName: string) => {
      await handleUpdateBoardName(newName);
      setBoardNameLoading(false);
      setBoardNameSuccess(true);
    },
    [handleUpdateBoardName]
  );

  const onAddParkingLotList = useCallback(async () => {
    setAddParkingLotListLoading(true);
    await addParkingLotList();
    setAddParkingLotListLoading(false);
    toast({
      status: 'success',
      title: 'Parking lot list added',
      description:
        'A list with your parking lot items has been added to this retrospective',
    });
  }, [addParkingLotList, toast]);

  const debouncedUpdateBoardName = useDebounce(updateBoardName, 1250, true);

  const format = board?.format;

  return (
    <Modal {...props}>
      <ModalOverlay />
      <ModalContent p={8} maxW={536}>
        <ModalCloseButton />
        <ModalHeader p={0}>
          <Heading mb={1} fontSize={24} color="gray.900">
            Retrospective Settings
          </Heading>
          <Text mb={3} fontSize={16} fontWeight={400} color="gray.500">
            Manage your retrospective options
          </Text>
        </ModalHeader>
        <ModalBody p={0}>
          <Flex flexDir="column" gap={6}>
            <FormControl>
              <FormLabel size="md">Retrospective name</FormLabel>
              <InputGroup size="lg">
                <Input
                  maxLength={40}
                  value={boardName}
                  onChange={(e) => {
                    setBoardName(e.target.value);
                    debouncedUpdateBoardName(e.target.value);
                    setBoardNameLoading(true);
                  }}
                />
                {boardNameLoading && (
                  <InputRightElement mr={1} pointerEvents="none">
                    <Spinner
                      h={5}
                      w={5}
                      speed="0.8s"
                      thickness="3px"
                      mx="auto"
                      my={2}
                    />
                  </InputRightElement>
                )}
                {!boardNameLoading && boardNameSuccess && (
                  <InputRightElement mr={2} pointerEvents="none">
                    <Icon color="primary.600" w={8} h={8} as={BsCheck} />
                  </InputRightElement>
                )}
              </InputGroup>
            </FormControl>
            {format && (
              <FormControl>
                <FormLabel size="md">Selected template</FormLabel>
                <Flex layerStyle="outlineGray">
                  <Image
                    src={format.iconImageUrl ?? ''}
                    alt={format.displayName ?? ''}
                    w={16}
                    h={16}
                    mr={3}
                  />
                  <Flex flexDir="column">
                    <Text fontSize={16} fontWeight={500} color="gray.800">
                      {format.displayName}
                    </Text>
                    <Text fontSize={12} color="gray.500">
                      {format.description}
                    </Text>
                  </Flex>
                </Flex>
              </FormControl>
            )}
            <FormControl>
              <FormLabel size="md">Retrospective settings</FormLabel>
              <Flex
                justify="space-between"
                layerStyle="outlineGray"
                px={3}
                py={1}
              >
                <HStack>
                  <Text>Tooltip hints</Text>
                  <Tooltip
                    label="Get instructions at the start of each phase"
                    variant="white"
                    maxW={200}
                    placement="top"
                    shouldWrapChildren
                  >
                    <Icon
                      as={BiInfoCircle}
                      color="gray.500"
                      w={5}
                      h={5}
                      mt={2}
                    />
                  </Tooltip>
                </HStack>
                <Switch
                  my="auto"
                  onChange={handleToggleTooltipHints}
                  isChecked={Boolean(boardTeam?.settings?.tooltipHintsEnabled)}
                />
              </Flex>
            </FormControl>
            <FormControl>
              <FormLabel size="md">Retrospective import</FormLabel>
              <Flex
                justify="space-between"
                alignItems="center"
                layerStyle="outlineGray"
                px={3}
                py={1}
              >
                <HStack>
                  <Text>Add parking lot items as column</Text>
                  <Tooltip
                    label="Adds your parking lot items as a column in this retro"
                    variant="white"
                    placement="top"
                    shouldWrapChildren
                  >
                    <Icon
                      as={BiInfoCircle}
                      color="gray.500"
                      w={5}
                      h={5}
                      mt={2}
                    />
                  </Tooltip>
                </HStack>
                <Tooltip
                  label={!parkingLotItemCount ? 'No items in parking lot' : ''}
                  variant="white"
                  placement="top"
                  shouldWrapChildren
                >
                  <Button
                    size="sm"
                    onClick={onAddParkingLotList}
                    _disabled={{ opacity: 0.4 }}
                    isDisabled={
                      addParkingLotListLoading || !parkingLotItemCount
                    }
                    cursor={
                      addParkingLotListLoading || !parkingLotItemCount
                        ? 'default'
                        : 'pointer'
                    }
                  >
                    {addParkingLotListLoading ? (
                      <Spinner w={3} h={3} mx="2px" color="white" size="sm" />
                    ) : (
                      <Icon w={4} h={4} as={AiOutlineArrowRight} />
                    )}
                  </Button>
                </Tooltip>
              </Flex>
            </FormControl>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
