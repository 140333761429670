import {
  ComponentStyleConfig,
  Tooltip as ChakraTooltip,
  TooltipProps as ChakraTooltipProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export const TooltipComponentStyle: ComponentStyleConfig = {
  variants: {
    white: {
      color: 'gray.700',
      fontWeight: 400,
      px: 3,
      py: 1,
      fontSize: 'sm',
      textAlign: 'center',
      maxW: 275,
    },
  },
  defaultProps: {
    placement: 'top',
  },
};

type Props = ChakraTooltipProps;

export const Tooltip = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ChakraTooltip
    ref={ref}
    {...(props.variant === 'white' ? { hasArrow: true, bg: 'white' } : {})}
    {...props}
  />
));
