import { createContext, FC, useContext } from 'react';

export enum SpkAssetKey {
  LogoFull = 'logoFull',
  LogoIcon = 'logoIcon',

  IllustrationHello = 'illustrationHello',
  IllustrationLook = 'illustrationLook',
  IllustrationTeammates = 'illustrationTeammates',
  IllustrationYellowPage = 'illustrationYellowPage',
  IllustrationCanvas = 'illustrationCanvas',
  IllustrationCollaboration = 'illustrationCollaboration',
  IllustrationTakeaways = 'illustrationTakeaways',
  IllustrationCards = 'illustrationCards',
  IllustrationCalendar = 'illustrationCalendar',
  IllustrationStopwatch = 'illustrationStopwatch',
  IllustrationHandoff = 'illustrationHandoff',
  IllustrationPlans = 'illustrationPlans',
  IllustrationWriting = 'illustrationWriting',
  IllustrationPerpetrator = 'illustrationPerpetrator',
  IllustrationIncrease = 'illustrationIncrease',
  IllustrationTable = 'illustrationTable',
  IllustrationInvestigate = 'illustrationInvestigate',
  Illustration404 = 'illustration404',

  ImageGoalTutorial1 = 'imageGoalTutorial1',
  ImageGoalTutorial2 = 'imageGoalTutorial2',
  ImageGoalTutorial3 = 'imageGoalTutorial3',

  ImageGoalTutorialPoll1 = 'imageGoalTutorialPoll1',
  ImageGoalTutorialPoll2 = 'imageGoalTutorialPoll2',
  ImageGoalTutorialPoll3 = 'imageGoalTutorialPoll3',

  ImageGoalTutorialManual1 = 'imageGoalTutorialManual1',
  ImageGoalTutorialManual2 = 'imageGoalTutorialManual2',
  ImageGoalTutorialManual3 = 'imageGoalTutorialManual3',

  ImagePoweredByStripe = 'imagePoweredByStripe',
  ImageConnectMetricsCallToAction = 'imageConnectMetricsCallToAction',
  ImagePlanWaveBackground = 'imagePlanWaveBackground',
  ImageGetStarted = 'imageGetStarted',
  ImageGetStartedQuestions = 'imageGetStartedQuestions',
  ImageGetStartedQuestionsIntegrations = 'imageGetStartedQuestionsIntegrations',
  ImageGetStartedRetroBoard = 'imageGetStartedRetroBoard',
  ImageGetStartedIntegrations = 'imageGetStartedIntegrations',
  ImageAnnouncementModalBanner = 'imageAnnouncementModalBanner',
  ImageIntegrationsJiraMetrics = 'imageIntegrationsJiraMetrics',

  PlaceholderSourceControlMetrics = 'placeholderSourceControlMetrics',
  PlaceholderFlowMetrics = 'placeholderFlowMetrics',
  PlaceholderWorkItemAgeTable = 'placeholderWorkItemAgeTable',
  PlaceholderWorkItemHeatmap = 'placeholderWorkItemHeatmap',
  PlaceholderFullFlowMetrics = 'placeholderFullFlowMetrics',
  PlaceholderFullSourceControlMetrics = 'placeholderFullSourceControlMetrics',
  PlaceholderCodeActivity = 'placeholderCodeActivity',

  ImagePulseDemo = 'imagePulseDemo',
  ImageGoalsDemo = 'imageGoalsDemo',
}

export type SpkAssets = Record<SpkAssetKey, string>;

export const EMPTY_ASSETS: SpkAssets = {
  logoFull: '',
  logoIcon: '',
  illustrationHello: '',
  illustrationLook: '',
  illustrationTeammates: '',
  illustrationYellowPage: '',
  illustrationCanvas: '',
  illustrationCollaboration: '',
  illustrationTakeaways: '',
  illustrationCards: '',
  illustrationCalendar: '',
  illustrationStopwatch: '',
  illustrationHandoff: '',
  illustrationPlans: '',
  illustrationWriting: '',
  illustrationPerpetrator: '',
  illustrationIncrease: '',
  illustrationTable: '',
  illustrationInvestigate: '',
  illustration404: '',
  imageGoalTutorial1: '',
  imageGoalTutorial2: '',
  imageGoalTutorial3: '',
  imageGoalTutorialPoll1: '',
  imageGoalTutorialPoll2: '',
  imageGoalTutorialPoll3: '',
  imageGoalTutorialManual1: '',
  imageGoalTutorialManual2: '',
  imageGoalTutorialManual3: '',
  imagePoweredByStripe: '',
  imageConnectMetricsCallToAction: '',
  imagePlanWaveBackground: '',
  imageGetStarted: '',
  imageGetStartedQuestions: '',
  imageGetStartedQuestionsIntegrations: '',
  imageGetStartedRetroBoard: '',
  imageGetStartedIntegrations: '',
  imageAnnouncementModalBanner: '',
  imageIntegrationsJiraMetrics: '',
  placeholderSourceControlMetrics: '',
  placeholderFlowMetrics: '',
  placeholderWorkItemAgeTable: '',
  placeholderWorkItemHeatmap: '',
  placeholderFullFlowMetrics: '',
  placeholderFullSourceControlMetrics: '',
  placeholderCodeActivity: '',
  imagePulseDemo: '',
  imageGoalsDemo: '',
};

const AssetContext = createContext<SpkAssets>(EMPTY_ASSETS);

export const useAssets = () => useContext(AssetContext);

export const AssetsProvider: FC<{ assets: SpkAssets }> = ({
  children,
  assets,
}) => <AssetContext.Provider value={assets}>{children}</AssetContext.Provider>;
