import { ApolloCache, MutationUpdaterFn } from '@apollo/client';
import { randomString } from '../../../etc/randomString';
import { uniqueBy } from '../../../etc/uniqueBy';
import { log } from '../../../../SpkLog';
import {
  ActionItem,
  ActionItemFragmentFragment,
  ActionItemsByFiltersDocument,
  ActionItemsByFiltersQuery,
  ActionItemsByFiltersQueryVariables,
  ActionItemStatus,
  CreateActionItemMutation,
  UpdateActionItemMutation,
} from '@spoke/graphql';

type OptimisticCreateActionItemArgs = Pick<
  ActionItem,
  'title' | 'description' | 'dueDate' | 'teamId' | 'boardId' | 'authorId'
>;
export const optimisticCreateActionItem = ({
  teamId,
  title,
  boardId,
  authorId,
  description = '',
  dueDate = null,
}: OptimisticCreateActionItemArgs): CreateActionItemMutation => ({
  __typename: 'Mutation',
  createActionItem: {
    __typename: 'ActionItem',
    id: randomString(),
    status: ActionItemStatus.Unresolved,
    resolvedAt: null,
    archived: false,
    authorId,
    teamId,
    title,
    boardId,
    description,
    dueDate,
    assignees: [],
    improvementGoals: [],
    author: { name: 'Loading...' },
    createdAt: Date.now(),
    jiraIssueLink: null,
    children: [],
    comments: [],
  },
});

export const createActionItemUpdateFunction: MutationUpdaterFn<
  CreateActionItemMutation
> = (cache, { data }) =>
  updateActionItemsByFiltersQueryAfterActionItemCreateOrUpdate({
    actionItemCreatedOrUpdated: data?.createActionItem,
    cache,
  });

export const updateActionItemUpdateFunction: MutationUpdaterFn<
  UpdateActionItemMutation
> = (cache, { data }) =>
  updateActionItemsByFiltersQueryAfterActionItemCreateOrUpdate({
    actionItemCreatedOrUpdated: data?.updateActionItem,
    cache,
  });

type UpdateActionItemsByFiltersQueryAfterActionItemCreateOrUpdateArgs = {
  actionItemCreatedOrUpdated: ActionItemFragmentFragment | null | undefined;
  cache: ApolloCache<object>;
};
const updateActionItemsByFiltersQueryAfterActionItemCreateOrUpdate = ({
  actionItemCreatedOrUpdated,
  cache,
}: UpdateActionItemsByFiltersQueryAfterActionItemCreateOrUpdateArgs): void => {
  if (!actionItemCreatedOrUpdated) {
    log.error(
      'No actionItem received in updateActionItemsByFiltersQueryAfterActionItemCreateOrUpdate',
      { actionItemCreatedOrUpdated }
    );
    return;
  }

  const emptyVariables: ActionItemsByFiltersQueryVariables = {
    teamId: '',
    assigneeIds: [],
    improvementGoals: [],
    dueDate: [null, null],
    endDate: null,
    startDate: null,
    status: null,
  };

  /**
   * We are updating every query that needs to have this action item pushed to it,
   * But this won't work in all possible filter variations the user could be searching.
   * Is there a better way? Like getting all current active queries and their variables?
   */
  const queryVariablesToUpdate: ActionItemsByFiltersQueryVariables[] = [
    { ...emptyVariables, teamId: actionItemCreatedOrUpdated.teamId },
    {
      ...emptyVariables,
      teamId: actionItemCreatedOrUpdated.teamId,
      status: ActionItemStatus.All,
    },
    {
      ...emptyVariables,
      teamId: actionItemCreatedOrUpdated.teamId,
      status: actionItemCreatedOrUpdated.status,
    },
    {
      ...emptyVariables,
      teamId: actionItemCreatedOrUpdated.teamId,
      status: ActionItemStatus.All,
      boardId: actionItemCreatedOrUpdated.boardId,
    },
    {
      ...emptyVariables,
      teamId: actionItemCreatedOrUpdated.teamId,
      status: actionItemCreatedOrUpdated.status,
      boardId: actionItemCreatedOrUpdated.boardId,
    },
  ];

  for (const variables of queryVariablesToUpdate) {
    cache.updateQuery<ActionItemsByFiltersQuery>(
      { query: ActionItemsByFiltersDocument, variables },
      (cacheData) => ({
        ...(cacheData || {}),
        actionItemsByFilters: uniqueBy(
          [
            ...(cacheData?.actionItemsByFilters || []),
            actionItemCreatedOrUpdated,
          ],
          (entry) => entry?.id || ''
        ),
      })
    );
  }
};
