import { FC, useMemo } from 'react';
import { InFlightWorkItemsQueryDataWorkItem } from '../../queries';
import { WorkItemHeatmapStatus } from './WorkItemHeatmapStatus';
import { QualityName } from '@spoke/graphql';
import {
  FlexProps,
  Flex,
  HStack,
  Text,
  SX_HORIZONTAL_SCROLL_ON_HOVER,
} from '@spoke/common';

const sortByQuality = (
  wi1: InFlightWorkItemsQueryDataWorkItem,
  wi2: InFlightWorkItemsQueryDataWorkItem
): 1 | 0 | -1 => {
  if (wi1.statusAgeQuality === wi2.statusAgeQuality) return 0;
  if (wi1.statusAgeQuality === QualityName.Good) return 1;
  if (wi2.statusAgeQuality === QualityName.Good) return -1;
  if (wi1.statusAgeQuality === QualityName.Regular) return 1;
  if (wi2.statusAgeQuality === QualityName.Regular) return -1;
  return 0;
};

type Props = FlexProps & {
  workItems: InFlightWorkItemsQueryDataWorkItem[];
  statusNames: string[];
  loading?: boolean;
  truncateOn?: number;
};
export const WorkItemHeatmap: FC<Props> = ({
  workItems,
  statusNames,
  truncateOn = 40,
  ...rest
}) => {
  const workItemsByStatusName = useMemo(
    () =>
      workItems.reduce((acc, cur) => {
        if (!acc[cur.externalStatusName]) acc[cur.externalStatusName] = [];
        return {
          ...acc,
          [cur.externalStatusName]: [...acc[cur.externalStatusName], cur].sort(
            sortByQuality
          ),
        };
      }, {} as Record<string, InFlightWorkItemsQueryDataWorkItem[]>),
    [workItems]
  );

  const workItemCountBystatusAgeQuality = useMemo(
    () =>
      workItems.reduce(
        (acc, cur) => {
          if (!acc[cur.statusAgeQuality]) acc[cur.statusAgeQuality] = 0;
          return {
            ...acc,
            [cur.statusAgeQuality]: acc[cur.statusAgeQuality] + 1,
          };
        },
        {
          [QualityName.Good]: 0,
          [QualityName.Regular]: 0,
          [QualityName.Poor]: 0,
        } as Record<QualityName, number>
      ),
    [workItems]
  );

  return (
    <Flex flexDir="column" {...rest}>
      <HStack mb={3} spacing={5}>
        <Flex flexDir="column">
          <Text fontSize={14} color="gray.500" fontWeight={500}>
            On target
          </Text>
          <Text fontSize={14} color="green.600" fontWeight="500">
            {workItemCountBystatusAgeQuality[QualityName.Good]}
          </Text>
        </Flex>
        <Flex flexDir="column">
          <Text fontSize={14} color="gray.500" fontWeight={500}>
            At Risk
          </Text>
          <Text fontSize={14} color="neutralYellow" fontWeight="500">
            {workItemCountBystatusAgeQuality[QualityName.Regular]}
          </Text>
        </Flex>
        <Flex flexDir="column">
          <Text fontSize={14} color="gray.500" fontWeight={500}>
            Over
          </Text>
          <Text fontSize={14} color="negativeRed" fontWeight="500">
            {workItemCountBystatusAgeQuality[QualityName.Poor]}
          </Text>
        </Flex>
        <Flex flexDir="column">
          <Text fontSize={14} color="gray.500" fontWeight={500}>
            Total
          </Text>
          <Text fontSize={14} color="gray.700" fontWeight="500">
            {workItems.length}
          </Text>
        </Flex>
      </HStack>
      <Flex gap={12} overflowY="hidden" sx={SX_HORIZONTAL_SCROLL_ON_HOVER}>
        {statusNames.map((status) => (
          <WorkItemHeatmapStatus
            key={status}
            name={status}
            items={workItemsByStatusName[status] ?? []}
            truncateOn={truncateOn}
          />
        ))}
      </Flex>
    </Flex>
  );
};
