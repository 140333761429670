import { useCallback, useEffect, useState } from 'react';
import { useInstalledUserIntegrations } from './useInstalledUserIntegrations';
import { SpkHttp, isServer } from '@spoke/common';

export type TrelloCard = {
  name: string;
  desc: string;
  idList: string;
  pos: string;
};

export type TrelloList = {
  id: string;
  name: string;
  idBoard: string;
};

export type TrelloBoard = {
  id: string;
  name: string;
  url: string;
  shortUrl: string;
  idOrganization: string;
};

type UseTrelloIntegrationResult = {
  trelloLists: TrelloList[] | null;
  trelloBoards: TrelloBoard[] | null;
  selectTrelloBoard: (trelloBoard: TrelloBoard | null) => void;
  authenticateTrello: () => void;
  trelloLoading: boolean;
  trelloListsLoading: boolean;
};
export const useTrelloIntegration = (): UseTrelloIntegrationResult => {
  const { trello, loading: integrationInstalledLoading } =
    useInstalledUserIntegrations();

  const [trelloBoards, setTrelloBoards] =
    useState<UseTrelloIntegrationResult['trelloBoards']>(null);
  const [trelloBoardsLoading, setTrelloBoardsLoading] = useState(false);

  const [selectedBoard, setSelectedBoard] = useState<TrelloBoard | null>(null);

  const [trelloLists, setTrelloLists] =
    useState<UseTrelloIntegrationResult['trelloLists']>(null);
  const [trelloListsLoading, setTrelloListsLoading] = useState(true);

  useEffect(() => {
    if (!trello) return;
    (async () => {
      setTrelloBoardsLoading(true);
      const boardsRes = await SpkHttp.getTrelloBoards();
      setTrelloBoardsLoading(false);
      setTrelloBoards(boardsRes);
    })();
  }, [trello]);

  useEffect(() => {
    if (!selectedBoard?.id) return;
    (async () => {
      setTrelloListsLoading(true);
      setTrelloLists(null);
      const listsRes = await SpkHttp.getTrelloBoardLists(selectedBoard.id);
      setTrelloListsLoading(false);
      setTrelloLists(listsRes);
    })();
  }, [selectedBoard]);

  const authenticateTrello = useCallback(() => {
    if (isServer()) return;
    const frontendRedirectAfterAuth = new URL(window.location.origin);
    frontendRedirectAfterAuth.pathname = '/integrations/trello/callback';
    const trelloAuthUrl = new URL(process.env.NEXT_PUBLIC_API_URL || '');
    trelloAuthUrl.pathname = '/integrations/trello/auth';
    const stateJson = JSON.stringify({
      redirectUri: frontendRedirectAfterAuth.toString(),
    });
    localStorage.setItem('trelloAuthState', stateJson);
    window?.open(trelloAuthUrl.toString(), '_blank')?.focus();
  }, []);

  const trelloLoading = integrationInstalledLoading || trelloBoardsLoading;

  return {
    trelloBoards,
    trelloLists,
    selectTrelloBoard: setSelectedBoard,
    authenticateTrello,
    trelloLoading,
    trelloListsLoading,
  };
};
