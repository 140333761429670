import { useCallback } from 'react';
import {
  useToast,
  TOAST_ERROR_GENERIC,
  optimisticCreateParkingLotItem,
  createParkingLotItemUpdateFunction,
  log,
} from '@spoke/common';
import { useCreateParkingLotItemMutation } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

export const useCreateParkingLotItemHandler = () => {
  const [currentTeam] = useCurrentTeam();
  const teamId = currentTeam?.id;
  const [createParkingLotItem] = useCreateParkingLotItemMutation();
  const [toast] = useToast();

  const handleCreateParkingLotItem = useCallback(
    async (text: string): Promise<boolean> => {
      if (!teamId || !text) {
        log.error('Missing critical data to create parking lot item', {
          text,
          teamId,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      const expected = optimisticCreateParkingLotItem({
        text,
        teamId,
      });

      const { errors } = await createParkingLotItem({
        variables: {
          teamId,
          text,
        },
        optimisticResponse: !expected
          ? undefined
          : {
              __typename: 'Mutation',
              createParkingLotCard: expected,
            },
        update: createParkingLotItemUpdateFunction,
      });

      if (errors) {
        log.error('ArchiveParkingLotiTem call responded with errors', {
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [createParkingLotItem, teamId, toast]
  );

  return [handleCreateParkingLotItem];
};
