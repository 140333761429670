import { useCallback } from 'react';
import {
  useToast,
  useCurrentUser,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import {
  useInstallJiraMetricsIntegrationMutation,
  InstalledOrgIntegrationsDocument,
} from '@spoke/graphql';

export const useInstallJiraMetricsIntegrationHandler = () => {
  const [toast] = useToast();
  const [currentUser] = useCurrentUser();
  const [installJiraMetricsIntegrationMutation, mutationResult] =
    useInstallJiraMetricsIntegrationMutation();

  const handleInstallJiraMetricsIntegration = useCallback(
    async (code: string): Promise<boolean> => {
      if (!code) {
        toast(TOAST_ERROR_GENERIC);
        log.error(`Invalid code when installing Jira Metrics integration`, {
          code,
          currentUser: currentUser?.id,
        });
        return false;
      }

      log.info(`Installing Jira Metrics integration`, {
        code,
      });

      const { data, errors } = await installJiraMetricsIntegrationMutation({
        variables: { code },
        refetchQueries: [
          {
            query: InstalledOrgIntegrationsDocument,
            variables: { organizationId: currentUser?.organization?.id ?? '' },
          },
        ],
      });

      if (errors?.length) {
        toast({
          status: 'error',
          title: 'Error',
          description:
            'Could not install Jira Metrics integration. Please check your installation code.',
        });
        log.error(`Could not install Jira Metrics integration`, {
          code,
          data,
          errors,
          currentUser: currentUser?.id,
        });
        return false;
      }

      toast({
        title: 'Success',
        description: 'Jira Metrics integration has been installed.',
        status: 'success',
      });

      return true;
    },
    [
      currentUser?.id,
      currentUser?.organization?.id,
      installJiraMetricsIntegrationMutation,
      toast,
    ]
  );

  return [handleInstallJiraMetricsIntegration, mutationResult] as const;
};
