import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  getCardFromCache,
  optimisticUpdateCardOriginalList,
  TOAST_ERROR_GENERIC,
  log,
  useToast,
} from '@spoke/common';
import {
  useUpdateCardMutation,
  ListFragmentFragmentDoc,
  List,
} from '@spoke/graphql';

export const useUpdateCardOriginalListHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [updateCard] = useUpdateCardMutation();

  const updateCardOriginalList = useCallback(
    async (cardId: string, listId: string) => {
      const updateCardFields = {
        originalListId: listId,
      };

      const listCacheId = `List:${listId}`;
      const listFromCache = cache.readFragment({
        id: listCacheId,
        fragment: ListFragmentFragmentDoc,
        fragmentName: 'ListFragment',
      }) as List;

      if (!listFromCache) {
        log.error(
          'Could not perform optimistic update for updateCardOriginalList: list not found in cache',
          { listCacheId, listFromCache }
        );
      }

      const cardFromCache = getCardFromCache({ cardId, cache });

      const expected = optimisticUpdateCardOriginalList({
        card: cardFromCache,
        list: listFromCache,
      });

      const { data, errors } = await updateCard({
        variables: { cardId, fields: updateCardFields },
        optimisticResponse: expected,
      });

      if (errors) {
        log.error('Update original card list call responded with errors', {
          errors,
          data,
          listCacheId,
          listFromCache,
          cardFromCache,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [cache, toast, updateCard]
  );

  return [updateCardOriginalList];
};
