import { GraphQLError } from 'graphql';
import {
  useToast,
  TOAST_ERROR_GENERIC,
  EMAIL_PATTERN,
  log,
} from '@spoke/common';
import { useInviteToTeamByEmailMutation } from '@spoke/graphql';

export enum BulkInviteToTeamResult {
  Success = 'SUCCESS',
  Error = 'ERROR',
  OrgFull = 'ORG_FULL',
}

export const useBulkInviteToTeamHandler = () => {
  const [inviteToTeamByEmail] = useInviteToTeamByEmailMutation();
  const [toast] = useToast();

  const handleBulkInviteToTeam = async (
    teamId: string,
    emails: string[]
  ): Promise<BulkInviteToTeamResult> => {
    if (!teamId) {
      log.error('Missing critical data to invite to team', {
        teamId,
      });
      toast(TOAST_ERROR_GENERIC);
      return BulkInviteToTeamResult.Error;
    }

    const validEmails = emails.filter((email) => email.match(EMAIL_PATTERN));

    if (!validEmails.length) {
      log.warn('No-op: no valid emails to invite to team', { validEmails });
      return BulkInviteToTeamResult.Error;
    }

    log.info('Sending bulk team invites by email', { emails, teamId });

    const results = await Promise.all(
      emails.map((email) =>
        inviteToTeamByEmail({ variables: { teamId, email } })
      )
    );

    const errors = results.reduce(
      (acc, cur) => [...acc, ...(cur.errors || [])],
      [] as GraphQLError[]
    );

    const orgFullErrors = errors.filter((e: any) => e.code === 402);
    if (orgFullErrors.length) {
      return BulkInviteToTeamResult.OrgFull;
    }

    // Do not catch org full errors here
    if (errors.length - orgFullErrors.length) {
      log.error('Bulk team invites responded with errors', {
        teamId,
        validEmails,
        errors,
      });
      toast(TOAST_ERROR_GENERIC);
      return BulkInviteToTeamResult.Error;
    }

    toast({
      status: 'success',
      title: 'Invites sent',
      description: `${validEmails.length} team member${
        validEmails.length !== 1 ? 's' : ''
      } invited successfuly.`,
    });

    return BulkInviteToTeamResult.Success;
  };

  return [handleBulkInviteToTeam];
};
