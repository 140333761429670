import {
  Circle as ChakraCircle,
  SquareProps as ChakraCircleProps,
  forwardRef,
} from '@chakra-ui/react';

type CircleProps = ChakraCircleProps;

export const Circle = forwardRef<CircleProps, 'div'>((props, ref) => (
  <ChakraCircle ref={ref} {...props} />
));
