import { FC } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { CreateTeamWizardModal } from './CreateTeamWizard';
import { useDisclosure, Button, Icon, ButtonProps } from '@spoke/common';

export const CreateTeamButton: FC<ButtonProps> = ({ ...props }) => {
  const createTeamModal = useDisclosure();

  return (
    <>
      <Button w="fit-content" {...props} onClick={createTeamModal.open}>
        <Icon w={4} h={4} as={AiOutlinePlus} mr={2} /> Create team
      </Button>

      <CreateTeamWizardModal
        isOpen={createTeamModal.isOpen}
        onClose={() => createTeamModal.close()}
      />
    </>
  );
};
