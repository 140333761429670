import { FC, useState } from 'react';
import { ImprovementGoalsHookData } from '../queries';
import { ImportDataWizardModal } from './ImportDataWizard';
import { ImprovementGoalCard } from './ImprovementGoalCard';
import { ReportGoalDataModal } from './ReportGoalDataModal';
import { useDisclosure, Skeleton, GridItem, Grid } from '@spoke/common';

type ImprovementGoalListProps = {
  loading: boolean;
  list: ImprovementGoalsHookData;
  columns: number;
};
export const ImprovementGoalList: FC<ImprovementGoalListProps> = ({
  list,
  loading,
  columns,
}) => {
  const [reportingGoalId, setReportingGoalId] = useState<string | null>(null);
  const reportDisclosure = useDisclosure();
  const importDisclosure = useDisclosure();

  return (
    <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={5}>
      {loading
        ? Array.from({ length: 4 }).map((_, id) => (
            <Skeleton key={`loading-improvement-goal-${id}`}>
              <GridItem
                h={284}
                borderStyle="solid"
                borderWidth={1}
                borderColor="gray.200"
                borderRadius={8}
                padding={4}
              />
            </Skeleton>
          ))
        : list?.map((goal) => (
            <ImprovementGoalCard
              key={goal.id}
              goal={goal}
              onReportClick={() => {
                setReportingGoalId(goal.id);
                reportDisclosure.open();
              }}
            />
          ))}
      <ImportDataWizardModal
        isOpen={importDisclosure.isOpen}
        onClose={importDisclosure.close}
        improvementGoalId={reportingGoalId ?? ''}
      />
      <ReportGoalDataModal
        isOpen={reportDisclosure.isOpen}
        onClose={() => {
          setReportingGoalId(null);
          reportDisclosure.close();
        }}
        improvementGoalId={reportingGoalId as string}
        onImportCSVClicked={() => {
          importDisclosure.open();
          reportDisclosure.close();
        }}
      />
    </Grid>
  );
};
