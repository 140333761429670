export const AT_LEAST_ONE_DIGIT = /(?=.*\d)/;
export const AT_LEAST_ONE_UPPERCASE = /(?=.*[A-Z])/;
export const AT_LEAST_ONE_LOWERCASE = /(?=.*[a-z])/;
export const ALPHA_ONLY = /^[\p{L}'][ \p{L}'-]*[\p{L}]$/u;
export const NUMBER_ONLY = /^\d+$/;
export const AT_LEAST_ONE_DIGIT_AND_ONE_UPPERCASE =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{0,}$/;
/**
 * TODO: Fix this regex. It is passing for 'name@domain' (without TLD)
 */
export const EMAIL_PATTERN =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;

export const IMPROVEMENT_GOAL_DATA_MAX_VALUE = 9999999;
export const IMPROVEMENT_GOAL_DATA_MAX_DECIMALS = 2;
