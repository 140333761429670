import { ApolloCache } from '@apollo/client';
import { log } from '../../../../SpkLog';
import { getTeamFromCache } from '../../getters';
import { RemoveTeamFromProgramMutation } from '@spoke/graphql';

type OptimisticRemoveTeamFromProgramArgs = {
  cache: ApolloCache<object>;
  teamId: string;
  programId: string;
};
export const optimisticRemoveTeamFromProgram = ({
  cache,
  programId,
  teamId,
}: OptimisticRemoveTeamFromProgramArgs):
  | RemoveTeamFromProgramMutation
  | undefined => {
  const programFromCache = getTeamFromCache({ cache, teamId: programId });
  if (!programFromCache?.children) {
    log.error(
      'Cannot perform removeTeamFromProgram optimistic response: program not found in cache'
    );
    return;
  }

  const newChildren = [...programFromCache.children].filter(
    (team) => team?.id !== teamId
  );

  const newProgram = {
    ...programFromCache,
    children: newChildren,
  };

  return {
    __typename: 'Mutation',
    leaveProgram: newProgram,
  };
};
