import {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { GoRepo } from 'react-icons/go';
import { useFetchLinkableGithubRepositories } from '../../fetchers/useFetchLinkableGithubRepositories';
import { useUpdateLinkedGithubRepositoriesHandler } from '../../handlers';
import { useCurrentTeamGithubRepositories } from '../../queries';
import { useCurrentTeam } from '@spoke/user';
import { GithubRepository } from '@spoke/graphql';
import {
  FlexProps,
  useGithubRateLimitLock,
  useDisclosure,
  truncateWithCount,
  Flex,
  Icon,
  GithubIcon,
  MultiSelect,
  Button,
  Spinner,
  Spacer,
  Text,
} from '@spoke/common';

const MAX_LINKS = 10;

type Props = Omit<FlexProps, 'onChange'> & {
  maxLabelLength?: number;
  showSaveButton?: boolean;
  onSave?: () => void;
  onChange?: (linksChanged: boolean) => void;
  onLoadingChange?: (loading: boolean) => void;
  showProductIcon?: boolean;
};

export type TeamGithubRepoLinkSelectorRefAttributes = {
  onSaveLinkedGithubRepos: () => void;
};

const TeamGithubRepoLinkSelectorComponent: ForwardRefRenderFunction<
  TeamGithubRepoLinkSelectorRefAttributes,
  Props
> = (
  {
    maxLabelLength = 60,
    showSaveButton = true,
    showProductIcon = true,
    onSave,
    onChange,
    onLoadingChange,
    ...rest
  },
  ref
) => {
  const [githubRateLimited] = useGithubRateLimitLock();
  const [currentTeam] = useCurrentTeam();

  const [fetchLinkableRepos] = useFetchLinkableGithubRepositories();
  const [updateLinkedGithubRepos] = useUpdateLinkedGithubRepositoriesHandler();

  const [linkedGithubRepos, { loading: linkedGithubReposLoading }] =
    useCurrentTeamGithubRepositories();
  const [linkedGithubReposSubmitting, setLinkedGithubReposSubmitting] =
    useState(false);

  const githubReposDropdown = useDisclosure();

  const [editedGithubRepos, setEditedGithubRepos] = useState<
    GithubRepository[]
  >([]);
  useEffect(() => {
    if (!linkedGithubRepos || linkedGithubReposLoading) return;
    setEditedGithubRepos(linkedGithubRepos);
  }, [linkedGithubRepos, linkedGithubReposLoading]);

  const githubRepositoriesLabel = editedGithubRepos.length
    ? truncateWithCount(editedGithubRepos, (repo) => repo.name, maxLabelLength)
    : 'Select your GitHub repositories';

  const linkedGithubReposWereNotEdited =
    linkedGithubRepos
      ?.map((r) => r.id)
      .sort()
      .join('-') ===
    editedGithubRepos
      .map((r) => r.id)
      .sort()
      .join('-');

  const onSaveLinkedGithubRepos = useCallback(async () => {
    if (linkedGithubReposWereNotEdited) return;
    onSave?.();
    setLinkedGithubReposSubmitting(true);
    await updateLinkedGithubRepos({
      githubRepositoryIds: editedGithubRepos.map((repo) => repo.id),
    });
    setLinkedGithubReposSubmitting(false);
  }, [
    editedGithubRepos,
    linkedGithubReposWereNotEdited,
    onSave,
    updateLinkedGithubRepos,
  ]);

  const getLinkableGithubReposByTerm = useCallback(
    async (term: string): Promise<GithubRepository[]> => {
      const values = term
        ? []
        : [...(linkedGithubRepos ?? [])].filter((repo) =>
            term ? repo.name.toLowerCase().includes(term.toLowerCase()) : true
          );

      if (!currentTeam?.id) return values;
      const results = await fetchLinkableRepos({ search: term });
      values.push(...results);

      return values
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(
          (repo, index, self) =>
            self.findIndex((r) => r.id === repo.id) === index
        );
    },
    [currentTeam?.id, fetchLinkableRepos, linkedGithubRepos]
  );

  useEffect(() => {
    onChange?.(!linkedGithubReposWereNotEdited);
  }, [linkedGithubReposWereNotEdited, onChange]);

  useEffect(() => {
    onLoadingChange?.(linkedGithubReposSubmitting);
  }, [linkedGithubReposSubmitting, onLoadingChange]);

  useImperativeHandle(
    ref,
    () => ({
      onSaveLinkedGithubRepos,
    }),
    [onSaveLinkedGithubRepos]
  );

  if (githubRateLimited) {
    return (
      <Flex w="full" {...rest}>
        <Text color="orange.300" fontSize="sm" fontWeight="400">
          Our <Icon as={GithubIcon} pb="2px" />{' '}
          <Text as="span" fontWeight={600}>
            GitHub
          </Text>{' '}
          integration is currently unavailable. Please try again in a few
          moments.
        </Text>
      </Flex>
    );
  }

  return (
    <Flex justifyContent="flex-end" w="full" gap={2} flexWrap="wrap" {...rest}>
      <MultiSelect
        searchable
        values={editedGithubRepos}
        onChange={setEditedGithubRepos}
        onClose={githubReposDropdown.close}
        isOpen={githubReposDropdown.isOpen}
        getOptions={getLinkableGithubReposByTerm}
        limit={MAX_LINKS}
        idKey="id"
        labelKeyOrFn="name"
        flex={rest.flexDir === 'column' ? undefined : '1'}
        placeholder="Type to search more repositories"
      >
        <Button
          justifyContent="start"
          onClick={githubReposDropdown.toggle}
          variant="outlineGray"
          leftIcon={
            <Icon
              as={showProductIcon ? GithubIcon : GoRepo}
              mr="-2px"
              ml="-4px"
            />
          }
          disabled={linkedGithubReposLoading}
          w="full"
          flex="1"
          overflow="hidden"
        >
          {linkedGithubReposLoading ? (
            <Spinner size="sm" ml={2} />
          ) : (
            <Text
              color={editedGithubRepos.length ? 'gray.700' : 'gray.500'}
              fontWeight={editedGithubRepos.length ? 500 : 400}
              fontSize={15}
              noOfLines={1}
            >
              {githubRepositoriesLabel}
            </Text>
          )}
          <Spacer />
          <Icon as={BsChevronDown} />
        </Button>
      </MultiSelect>
      {showSaveButton && (
        <Button
          isDisabled={linkedGithubReposWereNotEdited}
          isLoading={linkedGithubReposSubmitting}
          height={10}
          onClick={onSaveLinkedGithubRepos}
          alignSelf={rest.flexDir === 'column' ? 'flex-end' : undefined}
        >
          Save
        </Button>
      )}
    </Flex>
  );
};

export const TeamGithubRepoLinkSelector = forwardRef(
  TeamGithubRepoLinkSelectorComponent
);
