import { FC } from 'react';
import { useAssets } from '../hooks';
import { Flex, FlexProps } from './Flex';
import { Image } from './Image';
import { Spinner } from './Spinner';

export const LogoSpinner: FC<FlexProps> = (props) => {
  const { logoIcon } = useAssets();

  return (
    <Flex position="relative" {...props}>
      <Image
        src={logoIcon}
        alt="ScatterSpoke Logo"
        w={65}
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      />
      <Spinner color="primary.500" w={90} h={90} thickness="6px" speed="1s" />
    </Flex>
  );
};
