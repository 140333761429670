import { flattenListsCards } from './flattenListsCards';
import { List } from '@spoke/graphql';

type CountVotesByUserIdArgs = {
  userId: string;
  lists: List[];
};
export const countVotesByUserId = ({
  userId,
  lists,
}: CountVotesByUserIdArgs): number =>
  flattenListsCards(lists?.filter(Boolean) || []).reduce(
    (acc, cur) =>
      acc + cur.votes.filter((voteUserId) => voteUserId === userId).length,
    0
  );
