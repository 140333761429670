import { FC, useState, useCallback, useMemo, memo } from 'react';
import { FiSettings, FiCheckCircle } from 'react-icons/fi';
import { MdOutlineThumbsUpDown } from 'react-icons/md';
import {
  useFinishBoardHandler,
  useGrantRevokeFacilitatorHandler,
  useUpdateBoardVoteCountHandler,
  useResetAllVotesHandler,
} from '../../handlers';
import { useParticipations } from '../../queries';
import {
  useBoardSidebarState,
  setBoardSidebarWidth,
  setBoardSidebarIsCollapsed,
  boardSidebarIsCollapsedVar,
} from '../../util';
import { BoardSettings } from '../BoardSettings';
import { EndRetroConfirmationModal } from '../EndRetroConfirmationModal';
import { MemoizedBoardSidebarHelp } from './BoardSidebarHelp';
import { MemoizedBoardSidebarParticipants } from './BoardSidebarParticipants';
import { MemoizedBoardVoteCountPopover } from './BoardVoteCountPopover';
import { BoardStage, BoardParticipationStatus } from '@spoke/graphql';
import {
  useDisclosure,
  FlexProps,
  SpokeResizable,
  Flex,
  VStack,
  Popover,
  PopoverTrigger,
  Icon,
  Spacer,
  Button,
  Text,
  NAV_BAR_HEIGHT,
} from '@spoke/common';

type BoardSidebarProps = {
  currentStage: BoardStage | null;
  isGuided: boolean;
  isFacilitator: boolean;
  boardVoteCount: number;
};
export const BoardSidebar: FC<BoardSidebarProps> = ({
  currentStage,
  boardVoteCount,
  isFacilitator,
  isGuided,
}) => {
  const [{ participations }, manuallyUpdateParticipations] = useParticipations({
    isFacilitator,
  });

  const { sidebarIsCollapsed, sidebarWidth } = useBoardSidebarState();
  const [resetBoardVotesLoading, setResetBoardVotesLoading] = useState(false);
  const settingsModal = useDisclosure();

  const [handleEndRetro] = useFinishBoardHandler();
  const [handleGrantRevokeFacilitator] = useGrantRevokeFacilitatorHandler();
  const [handleUpdateBoardVoteCount] = useUpdateBoardVoteCountHandler();
  const [resetAllVotesHandler] = useResetAllVotesHandler({
    manuallyUpdateParticipations,
  });

  const votePopover = useDisclosure();
  const endRetroConfirmationModal = useDisclosure();

  const handleResetAllVotes = useCallback(async () => {
    setResetBoardVotesLoading(true);
    await resetAllVotesHandler();
    setResetBoardVotesLoading(false);
  }, [resetAllVotesHandler]);

  const { Vote, FactsStats } = BoardStage;
  const { Online } = BoardParticipationStatus;

  const usedTotalVotes =
    participations?.reduce((acc, p) => acc + p.numberOfVotes, 0) || 0;

  const maxTotalVotes =
    (participations?.filter((p) => p.status === Online).length ?? 0) *
    boardVoteCount;

  const votedPercentage = ((usedTotalVotes / maxTotalVotes) * 100 || 0).toFixed(
    0
  );

  const handleRevokeFacilitator = useCallback(
    (userId: string) => {
      handleGrantRevokeFacilitator(userId, false);
    },
    [handleGrantRevokeFacilitator]
  );

  const handleGrantFacilitator = useCallback(
    (userId: string) => {
      handleGrantRevokeFacilitator(userId, true);
    },
    [handleGrantRevokeFacilitator]
  );

  const onResizeEnd = useCallback(
    (newWidth) => setBoardSidebarWidth(newWidth),
    []
  );

  const onCollapseClick = useCallback(
    () => setBoardSidebarIsCollapsed(!boardSidebarIsCollapsedVar()),
    []
  );

  const resizableContainerProps: FlexProps = useMemo(
    () => ({
      position: 'fixed',
      zIndex: 5,
      role: 'group',
    }),
    []
  );

  return (
    <SpokeResizable
      onResizeEnd={onResizeEnd}
      onCollapseClick={onCollapseClick}
      isCollapsed={sidebarIsCollapsed}
      size={sidebarWidth}
      containerProps={resizableContainerProps}
      minSize={100}
      maxSize={600}
      alignItems="stretch"
      h={`calc(100vh - ${NAV_BAR_HEIGHT})`}
      whiteSpace="nowrap"
      bg="white"
    >
      <Flex
        flexDir="column"
        pt={6}
        pb={4}
        w="full"
        transition="opacity 0.05s ease-out"
        opacity={sidebarIsCollapsed ? 0 : 1}
        _groupHover={{ opacity: 1 }}
      >
        <MemoizedBoardSidebarParticipants
          teamMembers={participations}
          currentStage={currentStage}
          onRevokeFacilitator={handleRevokeFacilitator}
          onGrantFacilitator={handleGrantFacilitator}
        />
        <VStack
          w="full"
          alignItems="stretch"
          spacing={4}
          borderTop="1px solid"
          borderColor="gray.200"
          pt={5}
          px={4}
          transition="opacity 0.05s ease-out"
          opacity={sidebarIsCollapsed ? 0 : 1}
          _groupHover={{ opacity: 1 }}
        >
          {(!isGuided || currentStage === Vote) && (
            <Popover
              onClose={votePopover.close}
              onOpen={votePopover.open}
              isOpen={votePopover.isOpen}
              placement="right"
            >
              <PopoverTrigger>
                <Button
                  variant="outlinePrimary"
                  tabIndex={sidebarIsCollapsed ? -1 : 0}
                  leftIcon={
                    <Icon
                      as={MdOutlineThumbsUpDown}
                      color="primary.600"
                      w={5}
                      h={5}
                    />
                  }
                >
                  <Text fontSize={14}>Set vote count</Text>
                  <Spacer />
                  <Text fontSize={14} ml="auto">
                    {usedTotalVotes} of {maxTotalVotes}
                  </Text>
                </Button>
              </PopoverTrigger>
              <MemoizedBoardVoteCountPopover
                boardVoteCount={boardVoteCount}
                handleResetAllVotes={handleResetAllVotes}
                handleUpdateBoardVoteCount={handleUpdateBoardVoteCount}
                resetBoardVotesLoading={resetBoardVotesLoading}
                votedPercentage={votedPercentage}
                isOpen={votePopover.isOpen}
              />
            </Popover>
          )}
          <Button
            onClick={settingsModal.toggle}
            variant="outlinePrimary"
            tabIndex={sidebarIsCollapsed ? -1 : 0}
            leftIcon={<Icon as={FiSettings} color="primary.600" w={5} h={5} />}
          >
            <Text fontSize={14}>Retro Settings</Text>
          </Button>

          <MemoizedBoardSidebarHelp tabIndex={sidebarIsCollapsed ? -1 : 0} />

          {currentStage !== FactsStats && (
            <Button
              onClick={endRetroConfirmationModal.open}
              variant="outlinePrimary"
              tabIndex={sidebarIsCollapsed ? -1 : 0}
              leftIcon={
                <Icon as={FiCheckCircle} color="primary.600" w={5} h={5} />
              }
            >
              <Text fontSize={14}>End Retrospective</Text>
            </Button>
          )}
        </VStack>
      </Flex>

      <BoardSettings
        isOpen={settingsModal.isOpen}
        onClose={settingsModal.close}
      />

      <EndRetroConfirmationModal
        isOpen={endRetroConfirmationModal.isOpen}
        onClose={endRetroConfirmationModal.close}
        onConfirm={(sendSummary: boolean) => {
          endRetroConfirmationModal.close();
          handleEndRetro(sendSummary);
        }}
      />
    </SpokeResizable>
  );
};

export const MemoizedBoardSidebar = memo(BoardSidebar);
