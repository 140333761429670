import { BoxProps, ChakraProps, Th as ChakraTh } from '@chakra-ui/react';
import { forwardRef } from 'react';

export type ThProps = ChakraProps & BoxProps;

export const Th = forwardRef<HTMLTableCellElement, ThProps>((props, ref) => (
  <ChakraTh
    borderBottom="none"
    p={0}
    pl={0}
    pt={0}
    pb={0}
    pr={0}
    ref={ref}
    {...props}
  />
));
