import { ApolloCache } from '@apollo/client';
import {
  ActionItemComment,
  ActionItemCommentFragmentFragment,
  ActionItemCommentFragmentFragmentDoc,
} from '@spoke/graphql';

type GetCommentFromCacheArgs = {
  commentId: string;
  cache: ApolloCache<object>;
};
export function getActionItemCommentFromCache({
  commentId,
  cache,
}: GetCommentFromCacheArgs): ActionItemComment | null {
  return (
    (cache.readFragment({
      fragment: ActionItemCommentFragmentFragmentDoc,
      fragmentName: 'ActionItemCommentFragment',
      id: `ActionItemComment:${commentId}`,
    }) as ActionItemCommentFragmentFragment as ActionItemComment) || null
  );
}
