import { makeVar, useReactiveVar } from '@apollo/client';
import { isServer } from '@spoke/common';

const localStorage = isServer() ? null : window.localStorage;

const BOARD_SIDEBAR_COLLAPSED = 'BoardSidebarCollapsed';

export const boardSidebarIsCollapsedVar = makeVar<boolean>(
  localStorage?.getItem(BOARD_SIDEBAR_COLLAPSED) === 'true'
);

export const setBoardSidebarIsCollapsed = (newIsCollapsed: boolean) => {
  localStorage?.setItem(BOARD_SIDEBAR_COLLAPSED, `${newIsCollapsed}`);
  boardSidebarIsCollapsedVar(newIsCollapsed);
};

const BOARD_SIDEBAR_WIDTH = 'BoardSidebarWidth';

export const boardSidebarWidthVar = makeVar<number>(
  Number(localStorage?.getItem(BOARD_SIDEBAR_WIDTH)) || 400
);

export const setBoardSidebarWidth = (newWidth: number) => {
  localStorage?.setItem(BOARD_SIDEBAR_WIDTH, `${newWidth}`);
  boardSidebarWidthVar(newWidth);
};

export const useBoardSidebarState = () => ({
  sidebarIsCollapsed: useReactiveVar(boardSidebarIsCollapsedVar),
  sidebarWidth: useReactiveVar(boardSidebarWidthVar),
});

/**
 * This is used to show or hide elements depending on whether a
 * group card is being dragged. This is a reactive var and not
 * a plain state for performance reasons
 */
export const isDraggingVar = makeVar<boolean>(false);

export const isDraggingAGroupCardVar = makeVar<boolean>(false);
export const useIsDraggingAGroupCard = () =>
  useReactiveVar(isDraggingAGroupCardVar);

export const boardPasswordPassedVar = makeVar<boolean>(false);
export const useBoardPasswordPassed = () =>
  useReactiveVar(boardPasswordPassedVar);

/**
 * This exists to work around the fact that a card is completely reset when
 * switching lists, not persisting local commentsOpen state
 */
export const cardsWithOpenCommentsVar = makeVar<string>('');
export const addToCardsWithOpenComments = (cardId: string) =>
  cardsWithOpenCommentsVar(cardsWithOpenCommentsVar() + `${cardId}:`);
export const removeFromCardsWithOpenComments = (cardId: string) =>
  cardsWithOpenCommentsVar(
    cardsWithOpenCommentsVar().replace(`${cardId}:`, '')
  );
export const hasCommentsOpen = (cardId: string): boolean =>
  Boolean(cardsWithOpenCommentsVar().includes(cardId));
