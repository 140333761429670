export const plural = (word: string, count: number, suffix = 's') =>
  count === 1 ? word : word + suffix;

export const pluralSwitch = (
  singularWord: string,
  pluralWord: string,
  count: number
) => (count === 1 ? singularWord : pluralWord);

export const pluralState = (count: number) =>
  count === 1 ? 'singular' : 'plural';
