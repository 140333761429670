import { Flex, Skeleton, Divider, HStack } from '@spoke/common';

export const ReportGoalDataModalLoadingState = () => (
  <Flex flexDir="column">
    <Flex gap={1.5} flexDir="column">
      <Skeleton borderRadius="sm" h={5} w="50%" />
      <Skeleton borderRadius="sm" h={9} w="full" />
    </Flex>
    <Divider my={2} />
    <HStack>
      <Skeleton borderRadius="sm" h={14} flex={1} />
      <Skeleton borderRadius="sm" h={14} flex={1} />
    </HStack>
    <Skeleton w="full" h={48} my={4} />
    <HStack mb={8}>
      <Skeleton borderRadius="sm" h={12} flex={1} />
      <Skeleton borderRadius="sm" h={12} flex={1} />
    </HStack>
  </Flex>
);
