import { SyntheticEvent } from 'react';

export function parseCardText(text: string): string[] {
  if (!text) return [];
  const URL_REGEX =
    /(http|https)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?/g;
  const NEW_LINE = /\n/g;

  const linksFound = text.match(URL_REGEX);

  if (!linksFound || linksFound?.length === 0) return text.split(NEW_LINE);

  let result: string[] = [];
  let newCardText: string = '';

  for (const link of linksFound) {
    newCardText = text.replace(link, '***IMAGE***');
  }

  const firstSplit: string[] = newCardText.split('***IMAGE***');

  result = firstSplit
    .map((v, i) => [v, linksFound[i]].filter(Boolean))
    .reduce((a, b) => a.concat(b));

  return result;
}

export function isImage(text: string): boolean {
  const startsWith = text.startsWith('http');
  const endsWith = text.match(/\.(jpeg|jpg|gif|png)$/) != null;
  return startsWith && endsWith;
}

export const handleCardImageError = (
  event: SyntheticEvent<HTMLImageElement, Event>
) => {
  event.currentTarget.onerror = null; // prevents looping
  event.currentTarget.src = ' ' + event.currentTarget.src;
  return event;
};
