import { FC, memo } from 'react';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { ImprovementGoalValueInput } from '../ImprovementGoalValueInput';
import {
  ImprovementGoalUnit,
  ImprovementGoalAggregationPeriod,
} from '@spoke/graphql';
import {
  SpkTime,
  ONE_WEEK_MS,
  HStack,
  DatePickerPopover,
  Button,
  Icon,
  Spacer,
  WeekPickerPopover,
} from '@spoke/common';

type ReportGoalDataModalFormProps = {
  value: string;
  date: Date | null;
  unit: ImprovementGoalUnit;
  aggregationPeriod: ImprovementGoalAggregationPeriod;
  max?: number | null;
  min?: number | null;
  isInvalid: boolean;
  onValueChange: (value: string) => void;
  onDateChange: (date: Date | null) => void;
  onSubmit: () => void;
};
const ReportGoalDataModalForm: FC<ReportGoalDataModalFormProps> = ({
  date,
  onDateChange,
  onValueChange,
  aggregationPeriod,
  unit,
  isInvalid,
  onSubmit,
  value,
  max,
  min,
}) => {
  const dateLabel = date ? SpkTime.format(date, 'MMM dd, yyyy') : 'Select date';

  const weekLabel = date
    ? SpkTime.format(date, 'MMM dd') +
      ' - ' +
      SpkTime.format(new Date(date.getTime() + ONE_WEEK_MS - 1), 'MMM dd, yyyy')
    : 'Select week';

  return (
    <HStack alignItems="flex-start" mt={2} mb={4}>
      <ImprovementGoalValueInput
        onChange={onValueChange}
        unit={unit}
        isInvalid={isInvalid}
        onSubmit={onSubmit}
        value={value}
        max={max}
        min={min}
        flex={1}
      />
      {aggregationPeriod === ImprovementGoalAggregationPeriod.Day && (
        <DatePickerPopover
          childrenContainerProps={{ flex: 1 }}
          value={date}
          onChange={onDateChange}
          closeOnChange
          pastOnly
        >
          <Button
            w="full"
            id="dueDate"
            variant="outlineGray"
            leftIcon={<Icon as={AiTwotoneCalendar} />}
            justifyContent="start"
            flex={1}
          >
            {dateLabel}
            <Spacer />
            <Icon as={BsChevronDown} />
          </Button>
        </DatePickerPopover>
      )}
      {aggregationPeriod === ImprovementGoalAggregationPeriod.Week && (
        <WeekPickerPopover
          childrenContainerProps={{ flex: 1 }}
          value={date}
          onChange={onDateChange}
          closeOnChange
        >
          <Button
            w="full"
            id="dueDate"
            variant="outlineGray"
            leftIcon={<Icon as={AiTwotoneCalendar} />}
            justifyContent="start"
            flex={1}
          >
            {weekLabel}
            <Spacer />
            <Icon as={BsChevronDown} />
          </Button>
        </WeekPickerPopover>
      )}
    </HStack>
  );
};

export const MemoizedReportGoalDataModalForm = memo(ReportGoalDataModalForm);
