import { Portal } from '@visx/tooltip';
import { FC, memo, useState } from 'react';
import { FiSmile } from 'react-icons/fi';
import { MdSend } from 'react-icons/md';
import { RiFileGifLine } from 'react-icons/ri';
import {
  useDisclosure,
  Flex,
  SpokeTextArea,
  HStack,
  Popover,
  PopoverAnchor,
  Icon,
  PopoverContent,
  PopoverArrow,
  EmojiPicker,
  GiphySearch,
  Box,
  Text,
} from '@spoke/common';

type AddCardBoxProps = {
  onAdd: (content: string) => boolean | Promise<boolean>;
};
export const AddCardBox: FC<AddCardBoxProps> = ({ onAdd }) => {
  const [text, setText] = useState('');
  const [focused, setFocused] = useState(false);

  const emojiPopover = useDisclosure();
  const giphyPopover = useDisclosure();

  const handleAdd = async (textToAdd: string) => {
    const trimmedText = textToAdd.trim();
    if (!trimmedText) return;
    onAdd(trimmedText);
    setText('');
    emojiPopover.close();
    giphyPopover.close();
  };

  const valid = Boolean(text.trim());

  return (
    <Flex flexDir="column" pb={2} mb={3} position="relative">
      <Box overflow="hidden" borderRadius="lg" boxShadow="md">
        <SpokeTextArea
          onChange={(e) => setText(e.target.value)}
          onSubmit={() => handleAdd(text)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={text}
          placeholder="Write here to add a card..."
          borderBottomWidth="1px"
          borderBottomColor="gray.300"
          borderBottomStyle="solid"
          borderRadius="none"
          borderTopLeftRadius="lg"
          borderTopRightRadius="lg"
        />

        <HStack px={3} py="12px" justifyContent="space-between" bg="gray.50">
          <HStack justifyContent="space-between">
            <Popover
              isOpen={emojiPopover.isOpen}
              onClose={emojiPopover.close}
              placement="bottom-start"
              offset={[-10, 10]}
            >
              <PopoverAnchor>
                <Box onClick={emojiPopover.toggle} h="17px">
                  <Icon as={FiSmile} color="gray.400" cursor="pointer" />
                </Box>
              </PopoverAnchor>
              <PopoverContent w="full">
                <PopoverArrow />
                {emojiPopover.isOpen && (
                  <EmojiPicker
                    onEmojiSelect={(emoji) => {
                      setText((t) => t + emoji.native);
                      emojiPopover.close();
                    }}
                  />
                )}
              </PopoverContent>
            </Popover>
            <Popover
              isOpen={giphyPopover.isOpen}
              onClose={giphyPopover.close}
              placement="bottom-start"
              offset={[-10, 10]}
            >
              <PopoverAnchor>
                <Box onClick={giphyPopover.toggle} h="17px">
                  <Icon as={RiFileGifLine} color="gray.400" cursor="pointer" />
                </Box>
              </PopoverAnchor>
              <Portal>
                <PopoverContent w="full">
                  <PopoverArrow />
                  {giphyPopover.isOpen && (
                    <GiphySearch
                      onSelectGif={(gifUrl) => {
                        handleAdd(gifUrl);
                        giphyPopover.close();
                      }}
                    />
                  )}
                </PopoverContent>
              </Portal>
            </Popover>
          </HStack>
          <Icon
            as={MdSend}
            color={focused && valid ? 'gray.500' : 'gray.300'}
            _hover={{ color: valid ? 'primary.500' : 'gray.300' }}
            cursor="pointer"
            onClick={() => handleAdd(text)}
          />
        </HStack>
      </Box>
      {focused && valid && (
        <Text
          position="absolute"
          bottom="-10px"
          right="0"
          fontSize={10}
          color="gray.500"
          mt={1}
          textAlign="right"
        >
          <b>Enter</b> to add card, <b>Shift + Enter</b> for new line
        </Text>
      )}
    </Flex>
  );
};

export const MemoizedAddCardBox = memo(AddCardBox);
