import { useMemo } from 'react';
import { useCurrentUser } from '@spoke/common';

type QueryRef = ReturnType<typeof useCurrentUser>[1];
type QueryData = NonNullable<
  ReturnType<typeof useCurrentUser>[0]
>['organization'];

export const useCurrentOrg = (): [QueryData | null, QueryRef] => {
  const [currentUser, currentUserQuery] = useCurrentUser();
  const currentOrg = useMemo(
    () => currentUser?.organization ?? null,
    [currentUser?.organization]
  );
  return [currentOrg, currentUserQuery];
};
