import {
  Image as ChakraImage,
  ImageProps as ChakraImageProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = ChakraImageProps;

export const Image = forwardRef<HTMLImageElement, Props>((props, ref) => (
  <ChakraImage {...props} ref={ref} />
));
