import { css, Global } from '@emotion/react';
import { useEffect, useState } from 'react';
import { isServer } from '../../util';

const HIDE_OUTLINES = css`
  *:focus {
    outline: none !important;
    box-shadow: none !important;
  }
`;

const SHOW_OUTLINES = css`
  *:focus-visible {
    outline: solid 2px #5133b3;
    z-index: 1000;
  }
`;

/**
 * This disables accessibility outlines and activates them if user presses tab
 */
export const AccessibilityOnDemand = () => {
  const [isUsingKeyboard, setIsUsingKeyboard] = useState(false);

  useEffect(() => {
    if (isServer()) return;
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab') setIsUsingKeyboard(true);
    };
    const handleMouseDown = () => setIsUsingKeyboard(false);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('mousedown', handleMouseDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('mousedown', handleMouseDown);
    };
  });

  return <Global styles={!isUsingKeyboard ? HIDE_OUTLINES : SHOW_OUTLINES} />;
};
