import { ChakraProps } from '@chakra-ui/react';
import React, { FC, useState } from 'react';
import { FiSmile } from 'react-icons/fi';
import { MdSend } from 'react-icons/md';
import { RiFileGifLine } from 'react-icons/ri';
import { useDisclosure, useOnce } from '../../hooks';
import {
  Flex,
  Box,
  SpokeTextArea,
  Popover,
  PopoverAnchor,
  Icon,
  PopoverContent,
  PopoverArrow,
  EmojiPicker,
  GiphySearch,
  Text,
} from '../../ui';

type AddCommentBoxProps = ChakraProps & {
  onAdd: (content: string) => boolean | Promise<boolean>;
  autofocus?: boolean;
};
export const AddCommentBox: FC<AddCommentBoxProps> = ({
  onAdd,
  autofocus,
  ...rest
}) => {
  const [text, setText] = useState('');
  const [focused, setFocused] = useState(false);

  const giphyPopover = useDisclosure();
  const emojiPopover = useDisclosure();

  // Little hack to prevent two things
  // 1. Popover focus border not wrapping full width
  // 2. Browser viewport gets overflowed horizontally because of Emoji selector until opened once, for some reason
  const openedEmojiOnce = useOnce(emojiPopover.isOpen);

  const handleAdd = async (textToAdd: string) => {
    if (!textToAdd) return;
    onAdd(textToAdd);
    setText('');
    emojiPopover.close();
    giphyPopover.close();
  };

  return (
    <Flex flexDir="column" pb={2} position="relative" {...rest}>
      <Box
        borderRadius="lg"
        borderWidth="1px"
        borderStyle="solid"
        borderColor={focused ? 'primary.600' : 'gray.300'}
        position="relative"
      >
        <SpokeTextArea
          onChange={(e) => setText(e.target.value)}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onSubmit={() => handleAdd(text)}
          value={text}
          placeholder="Write a comment..."
          textAlign="left"
          minH="38px"
          pr="85px"
          autoFocus={autofocus}
          _focus={{ color: 'gray.600' }}
        />
        <Flex gap={2} px={3} py="12px" position="absolute" right={1} top={-1}>
          <Popover isOpen={emojiPopover.isOpen} onClose={emojiPopover.close}>
            <PopoverAnchor>
              <Box onClick={() => emojiPopover.toggle()} h="17px">
                <Icon as={FiSmile} color="gray.400" cursor="pointer" />
              </Box>
            </PopoverAnchor>
            <PopoverContent w={openedEmojiOnce ? 'full' : undefined}>
              <PopoverArrow />
              <EmojiPicker
                onEmojiSelect={(emoji) => {
                  setText((t) => t + emoji.native);
                  emojiPopover.close();
                }}
              />
            </PopoverContent>
          </Popover>
          <Popover isOpen={giphyPopover.isOpen} onClose={giphyPopover.close}>
            <PopoverAnchor>
              <Box onClick={() => giphyPopover.toggle()} h="17px">
                <Icon as={RiFileGifLine} color="gray.400" cursor="pointer" />
              </Box>
            </PopoverAnchor>
            <PopoverContent w="full">
              <PopoverArrow />
              <GiphySearch
                onSelectGif={(gifUrl) => {
                  handleAdd(gifUrl);
                  giphyPopover.close();
                }}
              />
            </PopoverContent>
          </Popover>
          <Icon
            as={MdSend}
            color={focused && text.length > 0 ? 'gray.500' : 'gray.300'}
            _hover={{ color: text.length > 0 ? 'primary.500' : 'gray.300' }}
            cursor="pointer"
            onClick={() => handleAdd(text)}
          />
        </Flex>
      </Box>
      {focused && text.length > 0 && (
        <Text
          position="absolute"
          bottom="-10px"
          right="0"
          fontSize={10}
          color="gray.500"
          mt={1}
          textAlign="right"
        >
          <b>Enter</b> to comment, <b>Shift + Enter</b> for new line
        </Text>
      )}
    </Flex>
  );
};
