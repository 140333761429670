import { useCallback } from 'react';
import { useSwitchTeamHandler } from './useSwitchTeamHandler';
import {
  useCurrentUser,
  useToast,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useUpdateUserMutation, TeamTreeDocument } from '@spoke/graphql';

export const useSwitchOrgHandler = () => {
  const [currentUser] = useCurrentUser();
  const [toast] = useToast();
  const [updateUserMutation] = useUpdateUserMutation();
  const [handleSwitchTeam] = useSwitchTeamHandler();

  const userId = currentUser?.id;

  const handleSwitchOrg = useCallback(
    async (orgId: string) => {
      if (!userId) {
        log.error('Cannot switch orgs without loaded current userId', {
          userId,
        });
        return;
      }
      log.info(`Changing organizationId to [${orgId}]`);

      const { errors, data } = await updateUserMutation({
        variables: { userInput: { organizationId: orgId } },
        refetchQueries: [
          {
            query: TeamTreeDocument,
          },
        ],
      });

      if (errors) {
        log.warn('Handled organizationId change GraphQL error', errors);
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const newOrgLevelTeamId = data?.updateUser?.organization?.team?.id;

      if (!newOrgLevelTeamId) {
        log.error(
          'Organization change responded with empty new org level team',
          data
        );
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      await handleSwitchTeam({ teamId: newOrgLevelTeamId });
    },
    [handleSwitchTeam, toast, updateUserMutation, userId]
  );

  return [handleSwitchOrg];
};
