import { Placement } from '@chakra-ui/react';
import { callIfExists } from '@spoke/common';
import { FC, useState } from 'react';
import { Button, ButtonProps } from './Button';
import { Tooltip } from './Tooltip';

export type ClickConfirmationProps = Omit<ButtonProps, 'onClick' | 'onBlur'> & {
  onConfirm: () => void;
  onClick?: () => void;
  text?: string;
  confirmScale?: number;
  placement?: Placement;
};
export const ClickConfirmation: FC<ClickConfirmationProps> = ({
  onConfirm,
  onClick,
  disabled,
  text = 'Click again to confirm',
  confirmScale = 1.06,
  placement,
  ...props
}) => {
  const [confirming, setConfirming] = useState(false);

  const handleClick = () => {
    if (disabled) return;
    callIfExists(onClick);
    if (!confirming) {
      setConfirming(true);
      return;
    }
    setConfirming(false);
    callIfExists(onConfirm);
  };

  return (
    <Tooltip
      label={text}
      isOpen={confirming}
      variant="white"
      placement={placement}
    >
      <Button
        transition="all 0.1s ease-out"
        transform={confirming ? `scale(${confirmScale})` : 'scale(1)'}
        {...props}
        onMouseLeave={() => setConfirming(false)}
        onBlur={() => setConfirming(false)}
        onClick={handleClick}
      />
    </Tooltip>
  );
};
