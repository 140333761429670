import { MutationUpdaterFn } from '@apollo/client';
import { useCallback } from 'react';
import { useSourceControlMetrics } from '../queries';
import { useToast, TOAST_ERROR_GENERIC, NO_OP, log } from '@spoke/common';
import {
  useUpdateLinkedGithubRepositoriesMutation,
  UpdateLinkedGithubRepositoriesMutation,
  LinkedGithubRepositoriesQuery,
  LinkedGithubRepositoriesDocument,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

export const useUpdateLinkedGithubRepositoriesHandler = () => {
  const [_, sourceControlMetricsQuery] = useSourceControlMetrics();
  const [updateLinkedGithubRepositories] =
    useUpdateLinkedGithubRepositoriesMutation();
  const [currentTeam] = useCurrentTeam();
  const [toast] = useToast();

  const handleUpdateLinkedGithubRepositories = useCallback(
    async ({
      githubRepositoryIds,
    }: {
      githubRepositoryIds: string[];
    }): Promise<boolean> => {
      const teamId = currentTeam?.id;
      if (!Array.isArray(githubRepositoryIds) || !teamId) {
        log.error('Missing critical data to updateLinkedGithubRepositories', {
          githubRepositoryIds,
          teamId,
        });
        return false;
      }

      log.info('Updating currently linked GitHub repositories', {
        githubRepositoryIds,
        teamId,
      });

      const { errors } = await updateLinkedGithubRepositories({
        variables: { githubRepositoryIds, teamId },
        update: updateLinkedGithubReposQueryAfterMutation,
      });

      if (errors) {
        log.error('updateLinkedGithubRepositories call responded with errors', {
          githubRepositoryIds,
          teamId,
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      toast({
        status: 'success',
        title: 'Success',
        description: 'Successfully updated linked GitHub repositories',
      });

      sourceControlMetricsQuery.refetch().catch(NO_OP);

      return true;
    },
    [
      currentTeam?.id,
      updateLinkedGithubRepositories,
      toast,
      sourceControlMetricsQuery,
    ]
  );

  return [handleUpdateLinkedGithubRepositories];
};

type UpdateLinkedGithubReposQueryAfterMutation =
  MutationUpdaterFn<UpdateLinkedGithubRepositoriesMutation>;

const updateLinkedGithubReposQueryAfterMutation: UpdateLinkedGithubReposQueryAfterMutation =
  (cache, { data }) => {
    cache.updateQuery<LinkedGithubRepositoriesQuery>(
      {
        query: LinkedGithubRepositoriesDocument,
        variables: { teamId: data?.updateLinkedGithubRepositories.id },
      },
      (prev) => ({
        ...prev,
        team: {
          id: data?.updateLinkedGithubRepositories.id ?? '',
          linkedGithubRepositories:
            data?.updateLinkedGithubRepositories.linkedGithubRepositories ?? [],
        },
      })
    );
  };
