import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useActionItemsAnalytics } from '../queries';
import {
  useNow,
  log,
  ONE_DAY_MS,
  optimisticUpdateActionItem,
  updateActionItemUpdateFunction,
  TOAST_ERROR_GENERIC,
  NO_OP,
  useToast,
} from '@spoke/common';
import { useUpdateActionItemMutation, ActionItem } from '@spoke/graphql';

export const useEditActionItemHandler = () => {
  const [updateActionItem] = useUpdateActionItemMutation();
  const [toast] = useToast();
  const { cache } = useApolloClient();

  const now = useNow({ roundTo: 'ten-minutes' });

  const [__, actionItemAnalyticsQuery] = useActionItemsAnalytics({
    endDate: now,
    timeWindowLength: 90 * ONE_DAY_MS,
  });

  const handleUpdateActionItem = useCallback(
    async (
      actionItemId: string,
      data: Pick<
        ActionItem,
        'title' | 'description' | 'dueDate' | 'boardId'
      > & {
        improvementGoalIds?: string[];
        assigneeIds?: string[];
        jiraProjectKey?: string;
      }
    ): Promise<boolean> => {
      log.info('Updating action item', {
        data,
      });

      const expected = optimisticUpdateActionItem({
        cache,
        actionItemId,
        fields: data,
      });

      const {
        title,
        description,
        dueDate,
        improvementGoalIds,
        assigneeIds,
        jiraProjectKey,
        boardId,
      } = data;

      const { errors } = await updateActionItem({
        variables: {
          actionItemId,
          fields: {
            title,
            assigneeIds,
            boardId,
            description,
            dueDate,
            improvementGoalIds,
            jiraProjectKey,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateActionItem: expected,
        },
        update: updateActionItemUpdateFunction,
      });

      if (errors) {
        log.error('updateActionItem call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      actionItemAnalyticsQuery.refetch().catch(NO_OP);

      return true;
    },
    [actionItemAnalyticsQuery, cache, toast, updateActionItem]
  );

  return [handleUpdateActionItem];
};
