import { FC, memo } from 'react';
import { BiLinkExternal } from 'react-icons/bi';
import { FiHelpCircle } from 'react-icons/fi';
import {
  ButtonProps,
  Popover,
  PopoverTrigger,
  Button,
  Icon,
  PopoverContent,
  PopoverArrow,
  VStack,
  Link,
  SpkLink,
  Text,
} from '@spoke/common';

const HELP_POPOVER_LINKS: Array<{ label: string; href: string }> = [
  {
    label: 'Phase 1: Reflect Guide',
    href: 'https://support.scatterspoke.com/en/knowledge/reflect-phase',
  },
  {
    label: 'Phase 2: Group Guide',
    href: 'https://support.scatterspoke.com/en/knowledge/group-phase',
  },
  {
    label: 'Phase 3: Vote Guide',
    href: 'https://support.scatterspoke.com/en/knowledge/vote-phase',
  },
  {
    label: 'Phase 4: Discuss Guide',
    href: 'https://support.scatterspoke.com/en/knowledge/discuss-phase',
  },
  {
    label: 'Retrospective best practices',
    href: 'https://support.scatterspoke.com/en/knowledge/retrospective-best-practices',
  },
];

const BoardSidebarHelp: FC<ButtonProps> = (props) => (
  <Popover placement="right-start">
    <PopoverTrigger>
      <Button
        variant="outlinePrimary"
        leftIcon={<Icon as={FiHelpCircle} color="primary.600" w={5} h={5} />}
        {...props}
      >
        <Text fontSize={14}>Help</Text>
      </Button>
    </PopoverTrigger>
    <PopoverContent maxW={270}>
      <PopoverArrow />
      <VStack alignItems="flex-start" p={6} spacing={4}>
        <Text fontWeight={500} color="gray.500" fontSize={16}>
          Help guides
        </Text>
        {HELP_POPOVER_LINKS.map((link) => (
          <SpkLink key={link.href} href={link.href} passHref>
            <Link
              target="_blank"
              w="full"
              justifyContent="space-between"
              display="flex"
            >
              <Text
                fontWeight={500}
                maxW={200}
                whiteSpace="pre-wrap"
                color="primary.500"
              >
                {link.label}
              </Text>
              <Icon as={BiLinkExternal} color="gray.500" />
            </Link>
          </SpkLink>
        ))}
      </VStack>
    </PopoverContent>
  </Popover>
);

export const MemoizedBoardSidebarHelp = memo(BoardSidebarHelp);
