import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  getCardFromCache,
  TOAST_ERROR_GENERIC,
  getListFromCache,
  useToast,
  log,
  useCurrentUser,
} from '@spoke/common';
import { useDuplicateCardMutation } from '@spoke/graphql';

export const useDuplicateCardHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [duplicateCard] = useDuplicateCardMutation();
  const [currentUser] = useCurrentUser();

  const handleDuplicateCard = useCallback(
    async (cardId: string) => {
      const card = getCardFromCache({ cardId, cache });

      if (!card?.listId) {
        log.error('Missing critical data to duplicate card', { card });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const list = getListFromCache({ listId: card?.listId, cache });

      if (!list || !currentUser?.id) {
        log.error('Missing critical data to duplicate card', {
          currentUserId: currentUser?.id,
          list,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const listCards = list?.cards || [];

      const { errors } = await duplicateCard({
        variables: {
          card,
          oldCardIdsOrder: listCards?.map((_card) => _card?.id) as string[],
          ownerId: currentUser.id,
        },
      });

      if (errors) {
        log.error('DuplicateCard call responded with errors', { errors });
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [cache, currentUser?.id, duplicateCard, toast]
  );

  return [handleDuplicateCard];
};
