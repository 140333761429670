import { TeamBusinessType } from '@spoke/graphql';

export const teamBusinessTypeNames: { [key in TeamBusinessType]: string } = {
  [TeamBusinessType.Analytics]: 'Analytics',
  [TeamBusinessType.Design]: 'Design',
  [TeamBusinessType.Facilities]: 'Facilities',
  [TeamBusinessType.Finance]: 'Finance',
  [TeamBusinessType.HumanResources]: 'Human Resources',
  [TeamBusinessType.It]: 'IT',
  [TeamBusinessType.Legal]: 'Legal',
  [TeamBusinessType.Marketing]: 'Marketing',
  [TeamBusinessType.NotSpecified]: 'Not Specified',
  [TeamBusinessType.Operations]: 'Operations',
  [TeamBusinessType.Other]: 'Other',
  [TeamBusinessType.Personal]: 'Personal',
  [TeamBusinessType.ProductManagement]: 'Product Management',
  [TeamBusinessType.ServiceManagement]: 'Service Management',
  [TeamBusinessType.SoftwareDev]: 'Software Development',
  [TeamBusinessType.WorkManagement]: 'Work Management',
};
