import { ApolloCache } from '@apollo/client';
import {
  ParkingLotItem,
  ParkingLotItemFragmentFragment,
  ParkingLotItemFragmentFragmentDoc,
} from '@spoke/graphql';

type GetParkingLotItemFromCacheArgs = {
  parkingLotItemId: string;
  cache: ApolloCache<object>;
};
export function getParkingLotItemFromCache({
  parkingLotItemId,
  cache,
}: GetParkingLotItemFromCacheArgs): ParkingLotItem | null {
  return (
    (cache.readFragment({
      fragment: ParkingLotItemFragmentFragmentDoc,
      fragmentName: 'ParkingLotItemFragment',
      id: `ParkingLotItem:${parkingLotItemId}`,
    }) as ParkingLotItemFragmentFragment as ParkingLotItem) || null
  );
}
