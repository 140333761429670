import { MimeType } from '../../types';

// Unknown source, adapted from angular code
export const downloadBlob = (
  bufferSource: string,
  mimeType: MimeType,
  filename: string
) => {
  const blob = new Blob([bufferSource], { type: mimeType });

  // Internet Explorer
  if ('navigator' in window && 'msSaveOrOpenBlob' in window.navigator) {
    (window.navigator as any).msSaveOrOpenBlob(blob, filename);
    return;
  }

  // Other browsers
  const data = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = data;
  link.download = filename;
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
};
