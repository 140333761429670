import { memo } from 'react';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { BiTrashAlt } from 'react-icons/bi';
import { BsThreeDots } from 'react-icons/bs';
import { MdOutlineModeEdit } from 'react-icons/md';
import {
  UpDown,
  Menu,
  MenuButton,
  Icon,
  MenuList,
  MenuItem,
} from '@spoke/common';

type BoardListMenuProps = {
  onEditTitleStart: () => void;
  onMoveList: (upDown: UpDown) => void;
  onDeleteList: () => void;
};
export const BoardListMenu = ({
  onEditTitleStart,
  onMoveList,
  onDeleteList,
}: BoardListMenuProps) => (
  <Menu>
    <MenuButton tabIndex={-1}>
      <Icon as={BsThreeDots} color="gray.600" />
    </MenuButton>
    <MenuList pb={2} maxW={2}>
      <MenuItem onClick={() => onEditTitleStart()}>
        <Icon mr={2} mb="2px" w={4} h={4} as={MdOutlineModeEdit} />
        Edit column title
      </MenuItem>
      <MenuItem onClick={() => onMoveList(UpDown.Up)}>
        <Icon mr={2} mb="2px" w={4} h={4} as={AiOutlineArrowLeft} />
        Move to the left
      </MenuItem>
      <MenuItem onClick={() => onMoveList(UpDown.Down)}>
        <Icon mr={2} mb="2px" w={4} h={4} as={AiOutlineArrowRight} />
        Move to the right
      </MenuItem>
      <MenuItem onClick={onDeleteList}>
        <Icon mr={2} mb="2px" w={4} h={4} as={BiTrashAlt} />
        Delete column
      </MenuItem>
    </MenuList>
  </Menu>
);

export const MemoizedBoardListMenu = memo(BoardListMenu);
