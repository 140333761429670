export enum SpokeAuthView {
  MethodSelector = 'method',
  EmailPassword = 'email',
  Register = 'register',
  VerifyEmail = 'verify-email',
  ChangeAccountEmail = 'change-account-email',
  ResetPassword = 'reset-password',
  ConfirmResetPassword = 'confirm-reset-password',
  CreateNewPassword = 'create-new-password',
  Sso = 'sso',
}
