import {
  ComponentWithAs,
  forwardRef,
  Icon as ChakraIcon,
  IconProps as ChakraIconProps,
} from '@chakra-ui/react';

export type IconProps = ChakraIconProps;

export const Icon: ComponentWithAs<'svg', IconProps> = forwardRef(
  (props, ref) => <ChakraIcon {...props} ref={ref} />
);
