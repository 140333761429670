import { FC, Fragment } from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { FiGlobe } from 'react-icons/fi';
import { MdLink } from 'react-icons/md';
import { useBoardPublicStatusChangeHandler } from '../../handlers';
import {
  useSpringState,
  getCurrentOrigin,
  HStack,
  InputGroup,
  InputLeftElement,
  Icon,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  capitalizeFirstLetter,
  Box,
  Input,
  Text,
  useCurrentBoard,
  useCurrentUser,
  useRoutes,
} from '@spoke/common';

const DROPDOWN_LABEL_BY_PUBLIC_BOARD_STATUS: Record<string, string> = {
  false: 'must already be in this team',
  true: 'will automatically be added to this team',
};

const COPY_LINK_LABEL_BY_PUBLIC_BOARD_STATUS: Record<string, string> = {
  false: 'This link will be private to existing members of your team',
  true: 'Team members with this link will be added to your team as trial guests',
};

export const BoardShareLink: FC = () => {
  const [board] = useCurrentBoard();
  const [currentUser] = useCurrentUser();
  const routes = useRoutes();

  const [handlePublicStatusChange] = useBoardPublicStatusChangeHandler();

  const [showBoardLinkCopySuccess, setShowBoardLinkCopySuccess] =
    useSpringState(false, 2000);

  const getBoardLink = () => {
    const boardId = board?.id;
    if (!boardId) return '';

    const url = new URL(getCurrentOrigin());
    url.pathname = `${routes.Board(boardId)}`;
    url.searchParams.set('inviter', currentUser?.id ?? '');
    return url.toString();
  };

  const handleCopyBoardLink = async () => {
    const url = getBoardLink();
    await navigator.clipboard.writeText(url.toString());
    setShowBoardLinkCopySuccess(true);
  };

  return (
    <Fragment>
      <Box>
        <Text fontWeight={500} mb={1} color="gray.900" fontSize={16}>
          Share retrospective link
        </Text>
        <HStack h={10} my={1} justifyContent="space-between">
          <InputGroup minH={10} flex="1">
            <InputLeftElement minH={10} pointerEvents="none">
              <Icon as={MdLink} color="gray.700" />
            </InputLeftElement>
            <Input
              minH={10}
              type="text"
              value={getBoardLink()}
              color="gray.700"
              readOnly
            />
          </InputGroup>
          <Button onClick={handleCopyBoardLink} px={4}>
            {showBoardLinkCopySuccess
              ? 'Invite link copied!'
              : 'Copy retro link'}
          </Button>
        </HStack>
        <Text fontSize={14} mt={2} color="gray.600">
          {
            COPY_LINK_LABEL_BY_PUBLIC_BOARD_STATUS[
              board?.public.toString() ?? ''
            ]
          }
        </Text>
      </Box>
      <Divider borderColor="gray.200" my={2} />
      <HStack h={10}>
        <Icon as={FiGlobe} color="gray.600" w={5} h={5} />
        <Flex alignItems="baseline">
          <Text fontWeight={500} color="gray.700">
            Anyone with the link
          </Text>
          <Menu placement="bottom" variant="unstyled">
            <MenuButton>
              <Flex alignItems="center">
                <Text
                  ml={1}
                  color="primary.500"
                  fontWeight={500}
                  textDecorationLine="underline"
                  cursor="pointer"
                >
                  {DROPDOWN_LABEL_BY_PUBLIC_BOARD_STATUS[`${board?.public}`]}
                </Text>
                <Icon ml={1} as={BsChevronDown} />
              </Flex>
            </MenuButton>
            <MenuList>
              <MenuItem
                h={8}
                fontSize={16}
                onClick={() => handlePublicStatusChange(false)}
              >
                {capitalizeFirstLetter(
                  DROPDOWN_LABEL_BY_PUBLIC_BOARD_STATUS.false
                )}
              </MenuItem>
              <MenuItem
                h={8}
                fontSize={16}
                onClick={() => handlePublicStatusChange(true)}
              >
                {capitalizeFirstLetter(
                  DROPDOWN_LABEL_BY_PUBLIC_BOARD_STATUS.true
                )}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Fragment>
  );
};
