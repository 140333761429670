import { useApolloClient } from '@apollo/client';
import { useCallback, useState } from 'react';
import {
  BeforeCapture,
  DropResult,
  DragDropContext,
} from 'react-beautiful-dnd';
import { AiOutlinePlus } from 'react-icons/ai';
import { useBoardDragEndHandler, useCreateListHandler } from '../handlers';
import { useCurrentBoardTeam } from '../queries';
import { useCurrentBoardRules } from '../queries/useCurrentBoardRules';
import { isDraggingVar, isDraggingAGroupCardVar } from '../util';
import { useCurrentVoteCount } from '../queries/useCurrentVoteCount';
import { PromoteCardToProgramModal } from './PromoteCardToProgramModal';
import { MemoizedBoardList } from './BoardList/BoardList';
import { MemoizedBoardInstructions } from './BoardInstructions';
import { NoCardsMessage } from './NoCardsMessage';
import { useCreateParkingLotItemFromCardIdHandler } from '@spoke/parking-lot';
import { BoardStage, Card, List } from '@spoke/graphql';
import {
  useCurrentBoard,
  useCurrentUser,
  useIsLightweightMode,
  getCardFromCache,
  Flex,
  filterArchived,
  deepClone,
  privateCardsFilter,
  privateVotesFilter,
  Button,
  Icon,
  convertCardToActionItem,
  Box,
  useCustomDndPlaceholderResponders,
  NAV_BAR_HEIGHT,
} from '@spoke/common';
import { CreateActionItemModal } from '@spoke/action-item';

const BOARD_CONTROLS_HEIGHT = '66px';
const BOARD_INSTRUCTIONS_HEIGHT = '25px';

export const ColumnBoard = () => {
  const [board] = useCurrentBoard();
  const [boardTeam] = useCurrentBoardTeam();
  const {
    canGroupCards,
    canAddCards,
    showColumns,
    canVote,
    privateVotes,
    privateCards,
    isFacilitator,
    currentStage,
    isGuided,
  } = useCurrentBoardRules();

  const [currentUser] = useCurrentUser();
  const [votesLeft] = useCurrentVoteCount();

  const [handleDragEnd] = useBoardDragEndHandler();
  const [handleCreateList] = useCreateListHandler();
  const [handleCreateParkingLotItemFromCardId] =
    useCreateParkingLotItemFromCardIdHandler();

  const isLightweightMode = useIsLightweightMode();

  const {
    handleDragStart,
    handleDragEnd: customPlaceholderDragEnd,
    handleDragUpdate,
    customDndPlaceholderProps,
  } = useCustomDndPlaceholderResponders({
    isEnabled: isLightweightMode,
  });

  const { cache } = useApolloClient();

  const onBeforeDragStart = useCallback((before: BeforeCapture) => {
    const { draggableId } = before;
    const isGroup = draggableId.includes(':group');
    isDraggingVar(true);
    // Delays perceived rerender hiccup for a bit
    setTimeout(() => isDraggingAGroupCardVar(isGroup), 100);
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      handleDragEnd(result);
      customPlaceholderDragEnd(result);
    },
    [customPlaceholderDragEnd, handleDragEnd]
  );

  const cardCount = board?.lists?.reduce(
    (acc, list) => acc + (list?.cards?.length || 0),
    0
  );

  const { Reflect } = BoardStage;

  const showNoCardsMessage =
    isGuided && currentStage !== Reflect && cardCount === 0;

  const [creatingActionItemFromCard, setCreatingActionItemFromCard] =
    useState<Card | null>(null);

  const [promotingCard, setPromotingCard] = useState<Card | null>(null);

  const handleSaveCardAsActionItem = useCallback(
    (cardId: string) => {
      const cardFromCache = getCardFromCache({ cache, cardId });
      setCreatingActionItemFromCard(cardFromCache);
    },
    [cache]
  );

  const onPromoteCard = useCallback(
    (cardId: string) => {
      const cardFromCache = getCardFromCache({ cache, cardId });
      setPromotingCard(cardFromCache);
    },
    [cache]
  );

  const groupingEnabled = canGroupCards;
  const votingEnabled = canVote;

  const canPromoteCardsToProgramIds =
    boardTeam?.parents?.map((p) => p.id) || [];
  const canPromoteCards = !!canPromoteCardsToProgramIds.length;

  const listMinHeight = `calc(100vh - ${NAV_BAR_HEIGHT} - ${BOARD_CONTROLS_HEIGHT} - ${
    isGuided ? BOARD_INSTRUCTIONS_HEIGHT : '0px'
  })`;

  return (
    <Box
      w="fit-content"
      h="fit-content"
      flex="1"
      display="flex"
      flexDir="column"
      pt={3}
    >
      {showNoCardsMessage && <NoCardsMessage />}
      {!showNoCardsMessage && (
        <MemoizedBoardInstructions
          currentStage={currentStage}
          isFacilitator={isFacilitator}
          isGuided={isGuided}
          votesLeft={votesLeft}
        />
      )}
      {!showNoCardsMessage && (
        <Flex gap={2} alignItems="stretch" flex="1" pt={1}>
          {/* https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/responders.md */}
          <DragDropContext
            onDragStart={handleDragStart}
            onDragUpdate={handleDragUpdate}
            onDragEnd={onDragEnd}
            // reducedMotion={isLightweightMode} // Performance mode proof of concept patch. Still a wip
            onBeforeDragStart={onBeforeDragStart}
          >
            {board?.lists?.filter(filterArchived)?.map((_list) => {
              const list = (
                privateCards || privateVotes
                  ? (deepClone(_list) as List)
                  : _list
              ) as List;
              const listCardCount = list?.cards?.length || 0;
              const userId = currentUser?.id;
              if (!list || !userId) return null;
              if (privateCards) {
                list.cards = list.cards?.filter(privateCardsFilter(userId));
              }
              if (privateVotes) {
                list.cards = list.cards?.map(privateVotesFilter(userId));
              }
              return (
                <MemoizedBoardList
                  key={list.id}
                  list={list as List}
                  teamId={board.teamId}
                  cardCount={listCardCount}
                  isFacilitator={isFacilitator}
                  groupingEnabled={groupingEnabled}
                  votingEnabled={votingEnabled}
                  addCardsEnabled={canAddCards}
                  votesLeft={votesLeft || 0}
                  showColumns={showColumns}
                  currentUserId={userId}
                  onSaveCardAsActionItem={handleSaveCardAsActionItem}
                  onSaveCardAsParkingLot={handleCreateParkingLotItemFromCardId}
                  minHeight={listMinHeight}
                  onPromoteCard={onPromoteCard}
                  canPromoteCards={canPromoteCards}
                  customPlaceholderProps={
                    customDndPlaceholderProps?.droppableId === list.id
                      ? customDndPlaceholderProps
                      : null
                  }
                />
              );
            })}
          </DragDropContext>
          {Boolean(
            isFacilitator && (currentStage === Reflect || !isGuided)
          ) && (
            <Button
              onClick={handleCreateList}
              colorScheme="gray"
              leftIcon={<Icon as={AiOutlinePlus} />}
              color="gray.500"
              w={320}
              py={7}
              order={99}
            >
              Add New Column
            </Button>
          )}
        </Flex>
      )}
      <CreateActionItemModal
        isOpen={Boolean(creatingActionItemFromCard)}
        onClose={() => setCreatingActionItemFromCard(null)}
        originalCardId={creatingActionItemFromCard?.id}
        initialValues={
          !creatingActionItemFromCard
            ? null
            : convertCardToActionItem(creatingActionItemFromCard)
        }
      />
      <PromoteCardToProgramModal
        isOpen={Boolean(promotingCard)}
        onClose={() => setPromotingCard(null)}
        card={promotingCard as Card}
      />
    </Box>
  );
};
