import { SpkFormat } from '@spoke/common';
import { FC } from 'react';
import { Box, BoxProps } from './Box';
import { Center } from './Center';
import { Divider } from './Divider';
import { Heading } from './Heading';
import { Skeleton } from './Skeleton';
import { Spacer } from './Spacer';
import { HStack } from './Stack';
import { Text } from './Text';

export type TrendCardProps = Omit<BoxProps, 'prefix' | 'suffix'> & {
  title: string;
  info?: string;
  loading?: boolean;

  primaryValue: number;
  currentValue: number;
  previousValue: number;
  secondaryValue: number;

  prefix?: {
    primaryValue?: string;
    differenceValue?: string;
    secondaryValue?: string;
  };

  suffix?: {
    primaryValue?: string;
    differenceValue?: string;
    secondaryValue?: string;
  };
};

export const TrendCard: FC<TrendCardProps> = ({
  title,
  info,
  loading,

  primaryValue,
  currentValue,
  previousValue,
  secondaryValue,

  prefix,
  suffix,

  ...rest
}) => {
  const difference = currentValue - previousValue;

  return (
    <Box
      backgroundColor="white"
      border="solid 1px"
      borderColor="gray.200"
      borderRadius={8}
      p={4}
      {...rest}
    >
      <HStack marginBottom={2}>
        <Heading
          size="xs"
          color="gray.600"
          fontWeight={500}
          fontSize={14}
          noOfLines={1}
        >
          {title}
        </Heading>
        <Spacer />
        {info && (
          <Text color="gray.500" fontWeight={500} fontSize={12}>
            {info}
          </Text>
        )}
      </HStack>
      {!loading ? (
        <>
          <HStack alignItems="flex-end">
            <Text fontWeight={700} fontSize={20} color="gray.900">
              {prefix?.primaryValue ?? null}
              {Math.round(primaryValue)}
              {suffix?.primaryValue ?? null}
            </Text>

            {!!difference && (
              <Text
                pb="4px"
                color="gray.500"
                borderRadius={4}
                fontSize="13px"
                as="span"
              >
                {prefix?.differenceValue ?? null}
                {SpkFormat.showSign(Math.round(difference))}
                {suffix?.differenceValue ?? null}
              </Text>
            )}
          </HStack>
          <Divider my={3} />
          <Text fontWeight={500} fontSize={12} color="gray.500">
            {prefix?.secondaryValue ?? null}
            <Text fontWeight={500} fontSize={12} color="gray.600" as="span">
              {Math.round(secondaryValue)}
              {suffix?.secondaryValue ?? null}
            </Text>
          </Text>
        </>
      ) : (
        <Center flexDir="column">
          <Skeleton w="full" h={5} />
          <Divider my={4} />
          <Skeleton w="full" h={5} />
        </Center>
      )}
    </Box>
  );
};
