import { ApolloCache } from '@apollo/client';
import { log } from '../../../SpkLog';
import { countVotesByUserId } from '../../spoke';
import { getBoardFromCache } from './getBoardFromCache';
import { List } from '@spoke/graphql';

export const getUserVoteCountFromCache = ({
  cache,
  boardId,
  userId,
}: {
  cache: ApolloCache<object>;
  boardId: string;
  userId: string;
}): {
  userVoteCount: number;
  boardMaxVotes: number;
  remainingVotes: number;
} | null => {
  const board = getBoardFromCache({ cache, boardId });
  if (!board?.lists) {
    log.warn('Cannot get user vote count from cache: cached board not found');
    return null;
  }

  const voteCount = countVotesByUserId({
    lists: board.lists.filter(Boolean) as List[],
    userId,
  });

  const boardMaxVotes = board.state.voteCount || 0;

  const remainingVotes = boardMaxVotes - voteCount;

  return { userVoteCount: voteCount, boardMaxVotes, remainingVotes };
};
