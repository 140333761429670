import { SkeletonProps } from '@chakra-ui/react';
import { FC } from 'react';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import { MetricsInsight } from '../MetricsInsight';
import { SourceControlMetricsQueryData } from '../../queries';
import { SourceControlMetricsStatuses } from './SourceControlMetricsStatuses';
import { DistributionBars } from '@spoke/charts';
import { QualityName, ImprovementGoalUnit } from '@spoke/graphql';
import {
  Skeleton,
  Flex,
  Center,
  Icon,
  truncateDecimals,
  SpkTime,
  Spacer,
  HStack,
  COLOR_BY_QUALITY_NAME,
  QUALITY_DISPLAY_NAME,
  Divider,
  Text,
} from '@spoke/common';

type Props = SkeletonProps & {
  sourceControlMetrics: SourceControlMetricsQueryData | null;
  periodLabel: string;
  loading?: boolean;
};

export const FullSourceControlMetrics: FC<Props> = ({
  sourceControlMetrics,
  periodLabel,
  loading,
  ...rest
}) => {
  const reviewDistribution = sourceControlMetrics?.reviewLeaderboard.map(
    (reviewer) => ({ label: reviewer.userName, value: reviewer.count })
  );

  return (
    <Skeleton
      isLoaded={!loading}
      layerStyle="outlineGray"
      py={5}
      px={5}
      borderRadius="2xl"
      {...rest}
    >
      <Flex flexDir="column" p={0}>
        <Flex mb={3}>
          <Center backgroundColor="primary.25" borderRadius={8} w={10} h={10}>
            <Icon
              as={MdOutlineStickyNote2}
              color="primary.600"
              floodOpacity={0.5}
              w={5}
              h={5}
            />
          </Center>
          <Flex flexDir="column" ml={2}>
            <Text color="gray.700" fontWeight={600} fontSize={18} mt="1px">
              {truncateDecimals(
                SpkTime.millisToDays(sourceControlMetrics?.cycleTimeMs ?? 0),
                1
              )}{' '}
              days
            </Text>
            <Text
              color="gray.500"
              fontWeight={400}
              fontSize={13}
              lineHeight="10px"
            >
              Pull Request 85th Percentile Cycle Time
            </Text>
          </Flex>
          <Spacer />
          <Text alignSelf="center" fontSize={12} color="gray.600">
            {periodLabel}
          </Text>
        </Flex>
        <SourceControlMetricsStatuses
          pickupTimeMs={sourceControlMetrics?.pickupTimeMs ?? 0}
          approvalTimeMs={sourceControlMetrics?.approvalTimeMs ?? 0}
          mergeTimeMs={sourceControlMetrics?.mergeTimeMs ?? 0}
          overrallCycleTimeMs={sourceControlMetrics?.cycleTimeMs ?? 0}
          loading={loading}
        />
        <HStack my={4} spacing={5}>
          <Flex flexDir="column">
            <Text fontSize={14} color="gray.500">
              Consistency
            </Text>
            <Text
              fontSize={14}
              color={
                COLOR_BY_QUALITY_NAME[
                  sourceControlMetrics?.consistencyQuality ?? QualityName.Good
                ]
              }
              fontWeight="500"
            >
              {
                QUALITY_DISPLAY_NAME[
                  sourceControlMetrics?.consistencyQuality ?? QualityName.Good
                ]
              }
            </Text>
          </Flex>
          <Flex flexDir="column">
            <Text fontSize={14} color="gray.500">
              Througput
            </Text>
            <Text fontSize={14} color="gray.500">
              <Text as="span" color="gray.700">
                {truncateDecimals(sourceControlMetrics?.prsPerDay ?? 0)} items
              </Text>
              /day
            </Text>
          </Flex>
        </HStack>
        <Flex h={150}>
          <DistributionBars
            title="Reviewer distribution"
            description="The distribution of Pull Request reviewers in the team"
            data={reviewDistribution ?? []}
            unit={ImprovementGoalUnit.PullRequests}
            minH={200}
          />
        </Flex>
      </Flex>
      <Spacer />
      <Divider my={3} />
      <MetricsInsight
        loading={loading}
        insight={sourceControlMetrics?.insight}
      />
    </Skeleton>
  );
};
