import { FC, useState } from 'react';
import { FiInbox } from 'react-icons/fi';
import { usePromoteParkingLotItemHandler } from '../handlers';
import {
  useToast,
  callIfExists,
  TOAST_ERROR_GENERIC,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  Badge,
  ModalBody,
  RadioGroup,
  Radio,
  ModalFooter,
  Button,
  ModalProps,
  Modal,
  ModalOverlay,
  log,
  Text,
  Stack,
} from '@spoke/common';
import { TeamType } from '@spoke/graphql';
import { getIconByTeamType, useCurrentTeamParentPrograms } from '@spoke/user';

type ParkingLotPromoteModalContentsProps = {
  parkingLotItemIds: string[];
  onClose: () => void;
  onPromoted?: () => void;
};

const ParkingLotPromoteModalContents: FC<
  ParkingLotPromoteModalContentsProps
> = ({ parkingLotItemIds, onClose, onPromoted }) => {
  const [toast] = useToast();
  const [parents] = useCurrentTeamParentPrograms();
  const [promoteParkingLotItemHandler] = usePromoteParkingLotItemHandler();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedProgramId, setSelectedProgramId] = useState<string | null>(
    null
  );

  const onSubmit = async () => {
    if (!selectedProgramId) return;
    const program = parents?.find((parent) => parent.id === selectedProgramId);
    if (!program) {
      log.error('Missing critical data to promote parking lot item', {
        parents,
        selectedProgramId,
      });
      return;
    }

    log.info('Promoting parking lot items', {
      parkingLotItemIds,
      selectedProgramId,
    });

    setIsSubmitting(true);
    await Promise.all(
      parkingLotItemIds.map((id) =>
        promoteParkingLotItemHandler({
          variables: { parkingLotItemId: id, programId: selectedProgramId },
        })
      )
    )
      .then(() => {
        toast({
          status: 'success',
          title:
            parkingLotItemIds.length !== 1
              ? `Parking lot items were promoted`
              : `Parking lot item was promoted`,

          description:
            parkingLotItemIds.length !== 1
              ? `${parkingLotItemIds.length} parking lot items were successfully copied to ${program.name}`
              : `The parking lot item was copied to ${program.name}`,
        });
        callIfExists(onPromoted);
      })
      .catch(() => toast(TOAST_ERROR_GENERIC))
      .finally(() => {
        setIsSubmitting(false);
        onClose();
      });
  };

  return (
    <ModalContent p={8} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Icon as={FiInbox} w={6} h={6} />
          <Heading mb={1} fontSize={24} color="gray.900">
            Promote parking lot items
          </Heading>
          <Badge
            bg="gray.100"
            color="gray.800"
            pt="1px"
            fontSize={18}
            lineHeight="20px"
          />
          <Text color="gray.600" />
        </HStack>
      </ModalHeader>
      <ModalBody p={0}>
        <Text fontWeight={500} color="gray.600" mb={2}>
          This action will copy {parkingLotItemIds.length} parking lot item
          {parkingLotItemIds?.length ? 's' : ''} to the selected program:
        </Text>
        <RadioGroup>
          <Stack borderColor="gray.100" borderWidth={1} borderRadius="lg" p={4}>
            {parents?.map((team) => (
              <Radio
                key={team.id}
                value={team.id}
                onChange={(e) => setSelectedProgramId(e.target.value)}
              >
                <HStack cursor="pointer" w="full">
                  {getIconByTeamType(team.type as TeamType)}

                  <Text
                    fontSize={14}
                    color={
                      selectedProgramId === team.id ? 'primary.500' : 'gray.600'
                    }
                    fontWeight={500}
                    flex="8"
                    pt="2px"
                  >
                    {team.name}
                  </Text>
                </HStack>
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </ModalBody>
      <ModalFooter p={0} mt={4}>
        <HStack justifyContent="flex-end">
          <Button
            size="lg"
            disabled={isSubmitting}
            variant="outlineGray"
            type="submit"
            w="fit-content"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="lg"
            w="fit-content"
            isLoading={isSubmitting}
            isDisabled={!parkingLotItemIds.length || !selectedProgramId}
            onClick={onSubmit}
            type="submit"
          >
            Promote
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};

type ParkingLotPromoteModalProps = Omit<ModalProps, 'children'> & {
  parkingLotItemIds: string[];
  onPromoted?: () => void;
};

export const ParkingLotPromoteModal: FC<ParkingLotPromoteModalProps> = (
  props
) => (
  <Modal {...props}>
    <ModalOverlay />
    <ParkingLotPromoteModalContents {...props} />
  </Modal>
);
