import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useCurrentOrgTeam,
  useSwitchTeamHandler,
  useTeamTree,
} from '../queries';
import {
  useToast,
  useCurrentUser,
  TOAST_ERROR_GENERIC,
  optimisticLeaveTeam,
  log,
} from '@spoke/common';
import { useLeaveTeamMutation, LeaveTeamMutation } from '@spoke/graphql';

export const useLeaveTeamHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [_, teamTreeQuery] = useTeamTree();
  const [handleSwitchTeam] = useSwitchTeamHandler();
  const [currentUser] = useCurrentUser();
  const [orgTeam] = useCurrentOrgTeam();
  const [leaveTeam] = useLeaveTeamMutation();

  const orgTeamId = orgTeam?.id ?? '';
  const userId = currentUser?.id;

  const handleLeaveTeam = useCallback(
    async ({
      teamId,
    }: {
      teamId: string;
    }): Promise<LeaveTeamMutation['leaveTeam'] | null> => {
      if (!teamId || !userId || !orgTeamId) {
        log.error('Missing critical data to leave team', {
          teamId,
          userId,
          orgTeamId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const expected = optimisticLeaveTeam({ cache, teamId, userId });

      const [{ errors, data }] = await Promise.all([
        leaveTeam({
          variables: {
            teamId,
            userId,
          },
          optimisticResponse: expected,
        }),
        handleSwitchTeam({ teamId: orgTeamId }),
      ]);

      await teamTreeQuery.refetch();

      if (errors) {
        log.warn('leaveTeam mutation responded with error', {
          error: errors,
        });
        return null;
      }

      const teamName = expected?.leaveTeam?.name ?? '';

      toast({
        status: 'success',
        title: 'Success',
        description: `You have left the ${
          teamName ? `"${teamName}" ` : ''
        }team.`,
      });

      return data?.leaveTeam;
    },
    [
      cache,
      handleSwitchTeam,
      leaveTeam,
      orgTeamId,
      teamTreeQuery,
      userId,
      toast,
    ]
  );

  return [handleLeaveTeam];
};
