import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log } from '@spoke/common';
import {
  useImportImprovementGoalDataMutation,
  ImprovementGoalDataImportInput,
} from '@spoke/graphql';

export const useImportImprovementGoalDataHandler = () => {
  const [toast] = useToast();
  const [importData] = useImportImprovementGoalDataMutation();

  const handleImportImprovementGoalData = useCallback(
    async (
      improvementGoalId: string,
      data: ImprovementGoalDataImportInput[]
    ) => {
      if (!data?.length) {
        log.error(
          'No-op: tried importing improvement goal data but no data provided',
          {
            data,
          }
        );
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      log.info(`Importing ${data.length} boards`, { data });

      const { errors } = await importData({
        variables: { improvementGoalId, data },
      });

      if (errors) {
        log.error('ImportImprovementGoalData call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      log.info('Imported improvement goal data successfully', { data });
      toast({
        status: 'success',
        title: 'Success',
        description: `Imported ${data.length} data points successfully`,
      });
    },
    [importData, toast]
  );

  return [handleImportImprovementGoalData];
};
