import { useImprovementGoalsByTeamIdsQuery } from '@spoke/graphql';
import { QueryConfig, filterArchived } from '@spoke/common';
import {
  UseImprovementGoalsByTeamIdsSubscriptionConfig,
  useImprovementGoalsByTeamIdsSubscription,
} from './useImprovementGoalsByTeamIdsSubscription';

type Config = QueryConfig<typeof useImprovementGoalsByTeamIdsQuery>;
type QueryRef = ReturnType<typeof useImprovementGoalsByTeamIdsQuery>;

export type ImprovementGoalsByTeamIdsHookData = NonNullable<
  NonNullable<QueryRef['data']>['improvementGoalsByTeamIds']
>['improvementGoals'];
export type ImprovementGoalByTeamIdsResult = NonNullable<
  NonNullable<ImprovementGoalsByTeamIdsHookData>[0]
>;
export type ImprovementGoalByTeamIdsDataResult = NonNullable<
  NonNullable<ImprovementGoalsByTeamIdsHookData>[0]['data']
>;
export type ImprovementGoalsByTeamIdsQueryData = NonNullable<
  NonNullable<ImprovementGoalsByTeamIdsHookData>[0]
>;

type FormattedResult = {
  activeGoals: ImprovementGoalsByTeamIdsHookData | null;
  pausedGoals: ImprovementGoalsByTeamIdsHookData | null;
  allGoals: ImprovementGoalsByTeamIdsHookData | null;
  totalSize: number;
};

export const useImprovementGoalsByTeamsIds = (
  config: Config
): [FormattedResult, QueryRef] => {
  const query = useImprovementGoalsByTeamIdsQuery(config);

  useImprovementGoalsByTeamIdsSubscription({
    queryVars: config,
    subscribeToChanges: true,
  });

  const goals =
    query.data?.improvementGoalsByTeamIds?.improvementGoals.filter(
      filterArchived
    ) ??
    query.previousData?.improvementGoalsByTeamIds?.improvementGoals?.filter(
      filterArchived
    );

  const activeGoals = goals?.filter(({ paused }) => !paused) ?? null;
  const pausedGoals = goals?.filter(({ paused }) => paused) ?? null;

  return [
    {
      activeGoals,
      pausedGoals,
      allGoals: goals ?? null,
      totalSize: goals?.length || 0,
    },
    query,
  ];
};
