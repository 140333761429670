import {
  NumberInputField as ChakraNumberInputField,
  NumberInputFieldProps as ChakraNumberInputFieldProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = ChakraNumberInputFieldProps;

export const NumberInputField = forwardRef<HTMLInputElement, Props>(
  (props, ref) => <ChakraNumberInputField {...props} ref={ref} />
);
