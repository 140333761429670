import React, { FC, useRef, useState } from 'react';
import { Box } from './Box';

type PickerProps = {
  onEmojiSelect: (emoji: { native: string }) => void;
};

export const EmojiPicker: FC<PickerProps> = (props) => {
  const ref = useRef<HTMLDivElement>(null);

  useState(() => {
    // At present moment the typedefs for this library are very outdated
    // @ts-ignore
    import('emoji-mart').then((EmojiMart) => {
      new EmojiMart.Picker({
        ...props,
        data: async () => {
          const response = await fetch(
            'https://cdn.jsdelivr.net/npm/@emoji-mart/data'
          );
          return response.json();
        },
        ref,
      } as PickerProps);
    });
  });

  return <Box ref={ref} />;
};
