import { ChakraProps } from '@chakra-ui/react';
import { User } from '@sentry/nextjs';
import { FC, memo } from 'react';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BiUser, BiTrashAlt } from 'react-icons/bi';
import { BsCheck2Circle, BsThreeDots } from 'react-icons/bs';
import { CgRedo } from 'react-icons/cg';
import { MdOutlinedFlag, MdOutlineModeEditOutline } from 'react-icons/md';
import { ActionItem, ImprovementGoal } from '@spoke/graphql';
import {
  truncateWithCount,
  SpkTime,
  callIfExists,
  Flex,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Square,
  Tooltip,
  VStack,
  Text,
  IssueLink,
} from '@spoke/common';

type ActionItemCardProps = ChakraProps & {
  actionItem: ActionItem;
  onEditClick?: (actionItemId: string) => void;
  onDelete?: (actionItemId: string) => void;
  onResolveToggle?: (actionItemId: string) => void;
  variant?: 'flat' | 'solid';
};
export const ActionItemCard: FC<ActionItemCardProps> = ({
  actionItem,
  onDelete,
  onEditClick,
  onResolveToggle,
  variant = 'flat',
  ...rest
}) => {
  const {
    title,
    assignees,
    dueDate,
    improvementGoals,
    jiraIssueLink,
    resolvedAt,
    id,
  } = actionItem;

  const improvementGoalsLabel = improvementGoals?.length
    ? truncateWithCount(
        improvementGoals as ImprovementGoal[],
        (goal) => goal.type.name,
        20
      )
    : 'No goals';
  const assigneesLabel = assignees?.length
    ? truncateWithCount(assignees as User[], 'name', 20)
    : 'Unassigned';

  const isOverdue =
    dueDate &&
    SpkTime.getEndOf('day', new Date(dueDate)).getTime() <
      SpkTime.getEndOf('day', new Date()).getTime();
  const dueTimeAgo = isOverdue && SpkTime.timeAgo(dueDate);
  const dueDateLabel = dueDate
    ? SpkTime.format(dueDate, 'MMM dd, yyyy')
    : 'No due date';

  const fullImprovementGoals =
    improvementGoals?.map((goal) => goal?.type.name ?? '').join(', ') || '';
  const fullAssigneeNames =
    assignees?.map((a) => a?.name ?? '').join(', ') || '';

  return (
    <Flex
      layerStyle={variant === 'flat' ? 'outlineGray' : undefined}
      bg="white"
      boxShadow={variant === 'solid' ? 'light' : undefined}
      justifyContent="space-between"
      {...rest}
      p={4}
    >
      <VStack spacing={1} alignItems="start">
        <Text
          color="gray.700"
          fontWeight={600}
          onClick={() => callIfExists(onEditClick, id)}
          cursor={onEditClick ? 'pointer' : 'default'}
          _hover={{ textDecoration: onEditClick ? 'underline' : 'none' }}
        >
          {title}
        </Text>
        <HStack spacing={4}>
          <HStack spacing={1} color={isOverdue ? 'red.500' : 'gray.500'}>
            <Icon as={AiTwotoneCalendar} />
            <Text cursor="default" fontWeight={500} pt="3px" fontSize={14}>
              {dueDateLabel}
              {isOverdue && <> &bull; {dueTimeAgo}</>}
            </Text>
          </HStack>
          <HStack spacing={1}>
            <Icon as={BiUser} color="gray.500" />
            <Tooltip
              label={fullAssigneeNames}
              isDisabled={(assignees?.length || 0) < 2}
              shouldWrapChildren
              placement="top"
              variant="white"
            >
              <Text
                cursor="default"
                fontWeight={500}
                pt="3px"
                fontSize={14}
                color="gray.500"
              >
                {assigneesLabel}
              </Text>
            </Tooltip>
          </HStack>
          <HStack spacing={1}>
            <Icon as={MdOutlinedFlag} color="gray.500" />
            <Tooltip
              label={fullImprovementGoals}
              isDisabled={(improvementGoals?.length || 0) < 2}
              shouldWrapChildren
              placement="top"
              variant="white"
            >
              <Text
                cursor="default"
                fontWeight={500}
                pt="3px"
                fontSize={14}
                color="gray.500"
              >
                {improvementGoalsLabel}
              </Text>
            </Tooltip>
          </HStack>
          {jiraIssueLink && (
            <IssueLink
              text={jiraIssueLink.jiraIssueKey ?? ''}
              url={jiraIssueLink.jiraIssueUrl ?? ''}
            />
          )}
        </HStack>
      </VStack>
      <HStack>
        {onResolveToggle && (
          <Square
            borderRadius="lg"
            boxShadow="extralight"
            layerStyle="outlineGray"
            as="button"
            onClick={() => onResolveToggle(id)}
            p={2}
          >
            <Icon
              color="gray.600"
              as={resolvedAt ? CgRedo : BsCheck2Circle}
              w={5}
              h={5}
            />
          </Square>
        )}
        {Boolean(onEditClick || onDelete) && (
          <Menu>
            <MenuButton>
              <Square
                borderRadius="lg"
                boxShadow="extralight"
                layerStyle="outlineGray"
                p={2}
              >
                <Icon color="gray.600" as={BsThreeDots} w={5} h={5} />
              </Square>
            </MenuButton>
            <MenuList>
              {onEditClick && (
                <MenuItem
                  alignItems="center"
                  gap={2}
                  onClick={() => onEditClick(id)}
                >
                  <Icon color="gray.600" as={MdOutlineModeEditOutline} />
                  <Text>Edit</Text>
                </MenuItem>
              )}
              {onDelete && (
                <MenuItem
                  alignItems="center"
                  gap={2}
                  onClick={() => onDelete(id)}
                >
                  <Icon color="gray.600" as={BiTrashAlt} />
                  <Text>Delete</Text>
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        )}
      </HStack>
    </Flex>
  );
};

export const MemoizedActionItemCard = memo(ActionItemCard);
