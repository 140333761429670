import { deepClone } from './deepClone';
import { shallowEquals } from './shallowEquals';

export function shallowEqualsExceptFor<T extends Record<string, unknown>>(
  a: T,
  b: T,
  exceptions: Array<keyof T>
): boolean {
  const aFiltered = Object.isFrozen(a) ? deepClone(a) : { ...a };
  const bFiltered = Object.isFrozen(b) ? deepClone(b) : { ...b };

  for (const key of exceptions) {
    delete aFiltered[key];
    delete bFiltered[key];
  }

  return shallowEquals(aFiltered, bFiltered);
}
