import { useCurrentTeamJiraBoards } from './useCurrentTeamJiraBoards';
import { useInstalledOrgIntegrations } from './useInstalledOrgIntegrations';

export const useTeamHasFlowMetricsInstalled = (): {
  loading: boolean;
  setUpOnOrg: boolean;
  setUpOnTeam: boolean;
  has: boolean;
} => {
  const { jira: setUpOnOrg, loading: orgIntegrationsLoading } =
    useInstalledOrgIntegrations();
  const [
    linkedJiraBoards,
    { loading: linkedJiraBoardsLoading, called: linkedJiraBoardsCalled },
  ] = useCurrentTeamJiraBoards();

  const loading =
    linkedJiraBoardsLoading ||
    orgIntegrationsLoading ||
    (setUpOnOrg && !linkedJiraBoardsCalled);

  const setUpOnTeam = Boolean(setUpOnOrg && linkedJiraBoards?.length);

  const has = setUpOnOrg && setUpOnTeam && !loading;

  return { loading, setUpOnOrg, setUpOnTeam, has };
};
