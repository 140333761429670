import { InMemoryCache } from '@apollo/client';
import { log } from '@spoke/common';

log.info('Apollo Cache is starting');
export const cache = new InMemoryCache({
  typePolicies: {
    Card: {
      fields: {
        originalListBadge: { merge: false },
        comments: { merge: false },
        children: { merge: false },
        votes: { merge: false },
      },
    },
    List: {
      fields: {
        cards: { merge: false },
      },
    },
    Board: {
      fields: {
        cardsOnDiscussion: { merge: false },
        cards: { merge: false },
        format: { merge: false },
        state: { merge: false },
        lists: { merge: false },
      },
    },
    Query: {
      fields: {
        actionItemsByFilters: { merge: false },
        parkingLotByTeamId: { merge: false },
        boardAnalytics: { merge: false },
      },
    },
  },
});
