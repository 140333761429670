import { useCurrentOrg } from './useCurrentOrg';
import { useCurrentUser } from '@spoke/common';

type QueryData = NonNullable<
  NonNullable<ReturnType<typeof useCurrentUser>[0]>['organization']
>['team'];

export const useCurrentOrgTeam = (): [QueryData | null] => {
  const [currentOrg] = useCurrentOrg();
  return [currentOrg?.team ?? null];
};
