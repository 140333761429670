import { cloneDeep } from '@apollo/client/utilities';
import { BoardFragmentFragment, UpdateListOrderMutation } from '@spoke/graphql';

type OptimisticUpdateListOrderArgs = {
  board: BoardFragmentFragment;
  newListIdsOrder: string[];
};
export const optimisticUpdateListOrder = ({
  board: _board,
  newListIdsOrder,
}: OptimisticUpdateListOrderArgs): UpdateListOrderMutation => {
  const board = cloneDeep(_board);

  board.lists =
    board.lists?.sort(
      (a, b) =>
        newListIdsOrder.indexOf(a?.id || '') -
        newListIdsOrder.indexOf(b?.id || '')
    ) || [];

  return { __typename: 'Mutation', updateListOrder: board };
};
