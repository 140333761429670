import {
  ModalOverlay as ChakraModalOverlay,
  ModalOverlayProps as ChakraModalOverlayProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export type ModalOverlayProps = ChakraModalOverlayProps;

export const ModalOverlay: FC<ModalOverlayProps> = (props) => (
  <ChakraModalOverlay {...props} />
);
