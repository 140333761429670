import { Icon, IconProps } from '../Icon';

export const ProgramIconColor = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      stroke="#001B45"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M37 15.5H19v35h18M37 33H19M19 7v15"
    />
    <path
      fill="#fff"
      stroke="#001B45"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M37 8.5H49V20.5H37z"
    />
    <path
      fill="#fff"
      stroke="#001B45"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M37 26.5H49V38.5H37z"
    />
    <path
      fill="#FFCC3E"
      stroke="#001B45"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M37 44.5H49V56.5H37z"
    />
  </Icon>
);
