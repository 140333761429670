import { useApolloClient } from '@apollo/client';
import { useCurrentBoardTeam } from '../../queries';
import {
  useCurrentUser,
  useToast,
  DeepPartial,
  optimisticUpdateTeam,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useUpdateTeamMutation, Team } from '@spoke/graphql';

export const useToggleTooltipHintsHandler = () => {
  const [boardTeam] = useCurrentBoardTeam();
  const [currentUser] = useCurrentUser();
  const [updateTeam] = useUpdateTeamMutation();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  const handleToggleTooltipHints = async () => {
    if (!boardTeam?.id || !currentUser?.id) {
      log.error('Missing critical data to toggle tooltip hints', {
        boardTeamId: boardTeam?.id,
        currentUserId: currentUser?.id,
      });
      return;
    }

    const tooltipHintsEnabled = !boardTeam.settings?.tooltipHintsEnabled;

    log.info(`Updating team tooltipHintsEnabled to ${tooltipHintsEnabled}`, {
      tooltipHintsEnabled,
      boardTeamId: boardTeam?.id,
      currentUserId: currentUser?.id,
    });

    const updateTeamFields: DeepPartial<Team> = {
      settings: { tooltipHintsEnabled },
    };

    const { errors, data } = await updateTeam({
      variables: {
        userId: currentUser.id,
        teamId: boardTeam?.id,
        fields: updateTeamFields,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        updateTeam: optimisticUpdateTeam({
          cache,
          updateFields: updateTeamFields,
          teamId: boardTeam?.id,
        }),
      },
    });

    if (errors) {
      log.error('Update team (toggle tooltip hints) responded with error', {
        boardTeamId: boardTeam.id,
        currentUserId: currentUser.id,
        errors,
        data,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }
  };

  return [handleToggleTooltipHints];
};
