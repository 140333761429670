import {
  ComponentStyleConfig,
  Alert as ChakraAlert,
  AlertProps as ChakraAlertProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const AlertComponentStyle: ComponentStyleConfig = {};

type Props = ChakraAlertProps;

export const Alert: FC<Props> = (props) => <ChakraAlert {...props} />;
