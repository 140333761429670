import { FC, useState } from 'react';
import {
  SelectOption,
  Flex,
  HStack,
  Button,
  Select,
  isNullish,
  Text,
  Option,
} from '@spoke/common';
import { LivePollSkipReason } from '@spoke/graphql';

const SKIP_REASON_OPTIONS: SelectOption<LivePollSkipReason>[] = [
  {
    label: 'This question is not meant for me',
    value: LivePollSkipReason.NotForMe,
  },
  {
    label: "I don't feel comfortable answering this",
    value: LivePollSkipReason.NotComfortable,
  },
];

type LivePollGoalCardRunningProps = {
  title: string;
  onSubmit: (answer: number) => void;
  onSkip: (skipReason: LivePollSkipReason) => void;
};
export const LivePollGoalCardRunning: FC<LivePollGoalCardRunningProps> = ({
  title,
  onSkip,
  onSubmit,
}) => {
  const [answer, setAnswer] = useState<number | null>(null);
  const [skipReason, setSkipReason] = useState<LivePollSkipReason | null>(null);
  const [isSkipping, setIsSkipping] = useState(false);

  const isValid = isSkipping ? Boolean(skipReason) : !isNullish(answer);

  const onSubmitClick = () => {
    if (!isValid) return;
    if (isSkipping) onSkip(skipReason!);
    else onSubmit(answer!);
  };

  return (
    <Flex
      flexDir="column"
      alignItems="center"
      layerStyle="outlineGray"
      gap={3}
      py={6}
    >
      <Text fontSize={15}>{title}</Text>
      {!isSkipping && (
        <HStack>
          {[...new Array(10)]
            .map((_, idx) => idx + 1)
            .map((value) => {
              const isSelected = value === answer;
              return (
                <Button
                  key={value}
                  onClick={() =>
                    isSelected ? setAnswer(null) : setAnswer(value)
                  }
                  variant="unstyled"
                  w={10}
                  h={10}
                  borderStyle="solid"
                  borderWidth={isSelected ? 2 : 1}
                  bg={isSelected ? 'primary.50' : 'transparent'}
                  color={isSelected ? 'primary.500' : 'gray.500'}
                  borderColor={isSelected ? 'primary.500' : 'gray.300'}
                  _hover={{ bg: isSelected ? 'primary.50' : 'gray.75' }}
                  transition="none"
                >
                  {value}
                </Button>
              );
            })}
        </HStack>
      )}
      {isSkipping && (
        <>
          <Text color="gray.600" fontSize={15}>
            You are skipping this one.
          </Text>
          <Select
            h={10}
            color="gray.600"
            value={skipReason || ''}
            placeholder="Select a reason to skip"
            maxW={350}
            onChange={(e) =>
              setSkipReason(e.target.value as LivePollSkipReason)
            }
          >
            {SKIP_REASON_OPTIONS.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </>
      )}
      <HStack>
        <Button
          h={10}
          variant="outlineGray"
          onClick={() => setIsSkipping((skipping) => !skipping)}
        >
          {isSkipping ? 'Back' : 'Skip'}
        </Button>
        <Button h={10} isDisabled={!isValid} onClick={onSubmitClick}>
          {isSkipping ? 'Skip' : 'Submit'}
        </Button>
      </HStack>
    </Flex>
  );
};
