import {
  ActionItem,
  ActionItemComment,
  CardComment,
  User,
} from '@spoke/graphql';

export const convertCardCommentToActionItemComment = (
  cardComment: Partial<CardComment>
): ActionItemComment => ({
  actionItem: {} as ActionItem,
  actionItemId: cardComment.cardId ?? '',
  archived: cardComment.archived ?? false,
  author: cardComment.author ?? ({} as User),
  authorId: cardComment.authorId ?? '',
  createdAt: cardComment.createdAt ?? Date.now(),
  id: cardComment.id ?? '',
  text: cardComment.text ?? '',
  updatedAt: cardComment.updatedAt ?? Date.now(),
  __typename: 'ActionItemComment',
});
