import {
  ComponentStyleConfig,
  forwardRef,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';

export const InputComponentStyle: ComponentStyleConfig = {
  sizes: {
    md: {
      field: {
        fontSize: 'md',
        height: 9,
        px: 2,
      },
    },
  },
  defaultProps: {
    focusBorderColor: 'primary.500',
  },
};

export type InputProps = ChakraInputProps;

export const Input = forwardRef<InputProps, 'input'>((props, ref) => (
  <ChakraInput {...props} ref={ref} />
));

// Required for InputGroup to work correctly
Input.id = 'Input';
