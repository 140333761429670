import { useCallback } from 'react';
import {
  TOAST_ERROR_GENERIC,
  optimisticCreateCardComment,
  createCardCommentUpdateFunction,
  log,
  useToast,
  useCurrentUser,
} from '@spoke/common';
import { useCreateCardCommentMutation } from '@spoke/graphql';

export type AddCommentHandler = (
  cardId: string,
  text: string
) => Promise<boolean>;

type UseAddCardCommentHandlerArgs = {
  boardId: string;
  teamId: string;
};
export const useAddCardCommentHandler = ({
  boardId,
  teamId,
}: UseAddCardCommentHandlerArgs) => {
  const [currentUser] = useCurrentUser();
  const [createComment] = useCreateCardCommentMutation();
  const [toast] = useToast();

  const handleAddComment = useCallback(
    async (cardId: string, text: string): Promise<boolean> => {
      const authorId = currentUser?.id;
      const authorName = currentUser?.name;

      if (!cardId || !teamId || !boardId || !authorId || !authorName) {
        log.error('Missing critical data to add a card comment', {
          cardId,
          teamId,
          boardId,
          authorId,
          authorName,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      const { errors } = await createComment({
        variables: {
          cardId,
          text,
        },
        optimisticResponse: optimisticCreateCardComment({
          authorName,
          cardId,
          authorId,
          text,
        }),
        update: createCardCommentUpdateFunction,
      });

      if (errors) {
        log.error('CreateCardComment call responded with error', { errors });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [boardId, createComment, currentUser?.id, currentUser?.name, teamId, toast]
  );

  return [handleAddComment];
};
