import { ChangeEvent, FC, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { FaChevronLeft } from 'react-icons/fa';
import {
  truncateText,
  SpkTime,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  HStack,
  Heading,
  VStack,
  Icon,
  Spacer,
  Select,
  Button,
  Tooltip,
  log,
  Text,
  Option,
} from '@spoke/common';

export type ImportDataFieldMapping = {
  value: string | null;
  date: string | null;
};

const EMPTY_MAP: ImportDataFieldMapping = {
  value: null,
  date: null,
};

const MAPPING_DISPLAY_NAMES: ImportDataFieldMapping = {
  value: 'Value',
  date: 'Date',
};

const REQUIRED_MAPPINGS: Array<keyof ImportDataFieldMapping> = [
  'value',
  'date',
];

const IGNORE_FIELD = 'IGNORE_FIELD';

type ImportDataWizardFieldMappingProps = {
  data: Record<string, string>[];
  onSubmit: (data: ImportDataFieldMapping) => void;
  onCancel: () => void;
};
export const ImportDataWizardFieldMapping: FC<
  ImportDataWizardFieldMappingProps
> = ({ data, onSubmit, onCancel }) => {
  const rowCount = data.length;

  const [fieldsMap, setFieldsMap] = useState(EMPTY_MAP);
  const [error, setError] = useState<string | null>(null);

  const csvColumns = Object.keys(data[0]);

  const valuesLeftToMap: Array<keyof ImportDataFieldMapping> = Object.entries(
    fieldsMap
  )
    .filter(([_, value]) => value === null)
    .map(([key]) => key as keyof ImportDataFieldMapping);

  const requiredNotMappedFields = REQUIRED_MAPPINGS.filter(
    (field) => fieldsMap[field] === null
  )
    .map((field) => MAPPING_DISPLAY_NAMES[field])
    .join(', ');

  const areAllRequiredFieldsMapped = REQUIRED_MAPPINGS.every(
    (field) => fieldsMap[field] !== null
  );

  const getExamplesOf = (column: string) => {
    const examples = data
      .map((row) => row[column])
      .filter(Boolean)
      .map((value) => truncateText(value, 25))
      .slice(0, 3);
    return examples.join(', ');
  };

  const getMappedFieldFor = (column: string) =>
    (Object.entries(fieldsMap).find(([_, csvKey]) => csvKey === column)?.[0] ??
      null) as keyof ImportDataFieldMapping | null;

  const setMapping = (field: keyof ImportDataFieldMapping, value: string) => {
    setError(null);
    setFieldsMap((prev) => ({ ...prev, [field]: value }));
  };

  const removeMapping = (column: string) => {
    const mappedField = getMappedFieldFor(column);
    if (!mappedField) return;
    return setFieldsMap((prev) => ({ ...prev, [mappedField]: null }));
  };

  const validateMapping = (): string | null => {
    if (!areAllRequiredFieldsMapped) {
      return `Please map the following required fields: ${requiredNotMappedFields}`;
    }

    const dateCsvColumn = fieldsMap.date as string;
    const valueCsvColumn = fieldsMap.value as string;

    const ERROR_SUFFIX = 'Please verify your CSV file or field mappings.';

    for (let index = 0; index < data.length; index++) {
      const row = data[index];
      const value = row[valueCsvColumn];
      if (!value) {
        return `One or more rows do not have a value for "${valueCsvColumn}" which is required. ${ERROR_SUFFIX}`;
      }

      if (isNaN(Number(value))) {
        return `Column ${valueCsvColumn} contains a non-numeric value at line ${
          index + 1
        }. ${ERROR_SUFFIX}`;
      }

      if (dateCsvColumn) {
        const rowDataDate = row[dateCsvColumn];
        if (!rowDataDate) {
          return `Column "${dateCsvColumn}" contains no date value at line ${
            index + 1
          }. ${ERROR_SUFFIX}`;
        }

        const parsedDate = SpkTime.parseIfValid(rowDataDate);

        if (!parsedDate) {
          return `Column "${dateCsvColumn}" contains an invalid date at line ${
            index + 1
          }. ${ERROR_SUFFIX}`;
        }
      }
    }

    return null;
  };

  const onMappingSubmit = () => {
    const validationError = validateMapping();
    if (validationError) {
      setError(validationError);
      log.warn('Prevented submit of invalid mapping', {
        validationError,
        fieldsMap,
        data,
      });
      return;
    }

    onSubmit(fieldsMap);
  };

  return (
    <ModalContent p={8} pb={4} maxW={600}>
      <ModalCloseButton />
      <ModalBody p={0}>
        <ModalHeader p={0}>
          <HStack>
            <Heading mb={1} fontSize={24} color="gray.900">
              We found {rowCount} rows to import!
            </Heading>
          </HStack>
          <Text fontSize={16} fontWeight={400} color="gray.700" mt={1}>
            Please map the CSV columns to the correct ScatterSpoke fields.
          </Text>
        </ModalHeader>
        <VStack spacing={2} my={4}>
          {csvColumns.map((column) => {
            const examples = getExamplesOf(column);
            const mappedField = getMappedFieldFor(column);

            const onChangeMapping = (e: ChangeEvent<HTMLSelectElement>) => {
              const field = e.target.value as
                | keyof ImportDataFieldMapping
                | typeof IGNORE_FIELD;
              removeMapping(column);
              if (field === IGNORE_FIELD) return;
              setMapping(field, column);
            };

            return (
              <HStack
                key={column}
                spacing={2}
                w="100%"
                borderBottomColor="gray.200"
                borderBottomWidth={1}
                borderBottomStyle="solid"
                pb={2}
              >
                <VStack spacing={0} alignItems="flex-start" w={300}>
                  <Text fontSize={14} fontWeight={500} color="gray.700">
                    {column}
                    {}
                  </Text>
                  <Text fontSize={12} fontWeight={400} color="gray.500">
                    {examples ? `(e.g. ${examples})` : '(all rows are empty)'}
                  </Text>
                </VStack>
                <Icon as={BsArrowRight} />
                <Spacer flex={0.01} />
                <Select
                  value={mappedField || IGNORE_FIELD}
                  flex="1"
                  color="gray.600"
                  h={8}
                  onChange={onChangeMapping}
                >
                  <Option value={IGNORE_FIELD}>Do not map</Option>
                  {mappedField && (
                    <Option value={mappedField}>
                      {MAPPING_DISPLAY_NAMES[mappedField]}
                    </Option>
                  )}
                  {valuesLeftToMap?.map((value) => (
                    <Option key={value} value={value}>
                      {MAPPING_DISPLAY_NAMES[value]}
                      {REQUIRED_MAPPINGS.includes(value) && '*'}
                    </Option>
                  ))}
                </Select>
              </HStack>
            );
          })}
          <Text color="red.500" pt={2} textAlign="center" fontSize={14}>
            {error}
          </Text>
        </VStack>
        <HStack mb={1} justifyContent="flex-end">
          <Button
            size="lg"
            variant="outlineGray"
            type="button"
            w="fit-content"
            onClick={onCancel}
            leftIcon={
              <Icon as={FaChevronLeft} pb="2px" color="gray.600" mr="-4px" />
            }
          >
            Go back
          </Button>
          <Tooltip
            label={
              areAllRequiredFieldsMapped ? null : (
                <Text>
                  Please map the following required fields:
                  <Text as="span" display="block" fontWeight="500">
                    {requiredNotMappedFields}
                  </Text>
                </Text>
              )
            }
            maxW={500}
            variant="white"
            placement="top"
            shouldWrapChildren
          >
            <Button
              size="lg"
              w="fit-content"
              type="submit"
              isDisabled={!areAllRequiredFieldsMapped}
              onClick={onMappingSubmit}
            >
              Next
            </Button>
          </Tooltip>
        </HStack>
      </ModalBody>
    </ModalContent>
  );
};
