import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  optimisticUpdateCard,
  getCardFromCache,
  archiveCardUpdateFunction,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useUpdateCardMutation, Card } from '@spoke/graphql';

export type DeleteCardHandler = (cardId: string) => Promise<void>;

export const useDeleteCardHandler = () => {
  const [updateCard] = useUpdateCardMutation();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  const handleDeleteCard = useCallback(
    async (cardId: string) => {
      const fields: Partial<Card> = { archived: true };

      log.info(`Deleting card ${cardId}}`);

      const { errors } = await updateCard({
        variables: {
          cardId,
          fields,
        },
        optimisticResponse: optimisticUpdateCard({
          newFields: fields,
          oldFields: getCardFromCache({ cache, cardId }),
        }),
        update: archiveCardUpdateFunction,
      });

      if (errors) {
        log.error('UpdateCard (archive) call responded with errors', {
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [cache, toast, updateCard]
  );

  return [handleDeleteCard];
};
