import {
  Grid as ChakraGrid,
  GridProps as ChakraGridProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export type GridProps = ChakraGridProps;

export const Grid = forwardRef<HTMLDivElement, GridProps>((props, ref) => (
  <ChakraGrid {...props} ref={ref} />
));
