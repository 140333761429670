import { Image, Text, Center, Flex, useAssets } from '@spoke/common';

export const ActionItemEmptyState = () => {
  const { illustrationCollaboration } = useAssets();

  return (
    <Center>
      <Flex alignItems="center" flexDir="column">
        <Image
          src={illustrationCollaboration}
          alt="No action items yet"
          width="24em"
          mt={16}
          mb={2}
        />
        <Text fontWeight={700} fontSize="20px" color="grey.900">
          No action items yet
        </Text>
        <Text fontSize={16} color="gray.500" maxW={300} textAlign="center">
          Create your first action item to start driving improvement.
        </Text>
      </Flex>
    </Center>
  );
};
