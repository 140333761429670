import { makeVar, useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import {
  useNetworkContext,
  useCurrentUser,
  useInstanceCount,
  log,
} from '@spoke/common';
import {
  TeamInsightUpdateSubscription,
  TeamInsightUpdateDocument,
  CurrentUserQuery,
  CurrentUserDocument,
} from '@spoke/graphql';

export type UseTeamInsightsSubscriptionConfig = {
  teamId: string;
};

/**
 * Keeps references for all unsubscription functions for active subs, keyed by teamId
 */
const unsubscribeFunctionsVar = makeVar<Record<string, (() => void)[]>>({});
const isSubscribed = (subscriptionKey: string) =>
  Object.keys(unsubscribeFunctionsVar()).includes(subscriptionKey);

/**
 * This hook manages the lifecycle of a subscription for TeamInsights events
 * and updates queries that need to react to that.
 */
export const useTeamInsightsSubscription = ({
  teamId,
}: UseTeamInsightsSubscriptionConfig) => {
  const { shouldPoll } = useNetworkContext();
  const [user] = useCurrentUser();
  const apollo = useApolloClient();

  const userId = user?.id ?? '';

  const [getInstanceCount] = useInstanceCount(
    `useTeamInsightsSubscription-${teamId}`
  );

  useEffect(() => {
    const subscriptionKey = teamId;
    const isAlreadySubscribed = isSubscribed(subscriptionKey);

    const shouldSubscribe =
      teamId &&
      userId &&
      !shouldPoll &&
      getInstanceCount() > 0 &&
      !isAlreadySubscribed;

    if (shouldSubscribe) {
      log.info(`Starting TeamInsights subscription`, {
        organizationId: teamId,
      });

      const observable = apollo.subscribe<TeamInsightUpdateSubscription>({
        query: TeamInsightUpdateDocument,
        variables: { teamId, userId },
      });

      const subscription = observable.subscribe((subscriptionData) => {
        if (!subscriptionData.data?.teamInsightUpdate) return;

        const data = subscriptionData.data.teamInsightUpdate;

        apollo.cache.updateQuery<CurrentUserQuery>(
          { query: CurrentUserDocument },
          (prev) =>
            !prev
              ? null
              : {
                  __typename: 'Query',
                  user: {
                    ...prev?.user,
                    team: {
                      ...prev?.user?.team,
                      insights: data.insights,
                    },
                  } as CurrentUserQuery['user'],
                }
        );
      });

      unsubscribeFunctionsVar({
        ...unsubscribeFunctionsVar(),
        [subscriptionKey]: [subscription.unsubscribe.bind(subscription)],
      });
    }

    return () => {
      const isStillSubscribed = isSubscribed(subscriptionKey);
      const isLastInstance = getInstanceCount() === 0;
      const shouldUnsubscribe = isStillSubscribed && isLastInstance;
      if (shouldUnsubscribe) {
        // No instances with matching variables are mounted, so unsubscribe
        log.info(`Stopping TeamInsights subscription`, {
          organizationId: teamId,
        });
        const functions = unsubscribeFunctionsVar();
        for (const unsubscribe of functions[subscriptionKey]) unsubscribe();
        delete functions[subscriptionKey];
        unsubscribeFunctionsVar(functions);
      }
    };
  }, [teamId, getInstanceCount, shouldPoll, apollo, userId]);
};
