import {
  ModalHeader as ChakraModalHeader,
  ModalHeaderProps as ChakraModalHeaderProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export type ModalHeaderProps = ChakraModalHeaderProps;

export const ModalHeader: FC<ModalHeaderProps> = (props) => (
  <ChakraModalHeader {...props} />
);
