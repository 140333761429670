import { ApolloCache } from '@apollo/client';
import { deepMerge } from '../../../etc';
import { DeepPartial } from '../../../../types';
import { getBoardFromCache } from '../../getters';
import { Board, PaginatedTeamBoardsQuery } from '@spoke/graphql';

type OptimisticUpdateBoardArgs = {
  boardId: string;
  fields: DeepPartial<Board>;
  cache: ApolloCache<object>;
};
export const optimisticUpdateBoard = ({
  cache,
  boardId,
  fields,
}: OptimisticUpdateBoardArgs): Board | null => {
  const board = getBoardFromCache({ boardId, cache });
  if (!board) return null;
  const updatedBoard = deepMerge(board, fields) as Board;
  return updatedBoard;
};

export const removeBoardFromPaginatedTeamBoards = (
  { teamBoardsPagination }: PaginatedTeamBoardsQuery,
  boardId: string
): PaginatedTeamBoardsQuery => {
  if (!teamBoardsPagination) {
    return {
      teamBoardsPagination: null,
    };
  }

  let { boards, totalSize } = teamBoardsPagination;

  boards = boards.filter((board) => board?.id !== boardId);
  totalSize = totalSize - 1;

  return {
    teamBoardsPagination: {
      boards,
      totalSize,
    },
  };
};
