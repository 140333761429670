import { FC } from 'react';
import { JiraIcon, BitbucketIcon, GithubIcon } from '../ui';
import { WorkItemOrigin, SourceControlOrigin } from '@spoke/graphql';

export const ICON_BY_WORK_ITEM_ORIGIN: Record<WorkItemOrigin, FC> = {
  [WorkItemOrigin.Jira]: JiraIcon,
};

export const ICON_BY_SOURCE_CONTROL_ORIGIN: Record<SourceControlOrigin, FC> = {
  [SourceControlOrigin.Bitbucket]: BitbucketIcon,
  [SourceControlOrigin.Github]: GithubIcon,
};

export const DISPLAY_NAME_BY_WORK_ITEM_ORIGIN: Record<WorkItemOrigin, string> =
  {
    [WorkItemOrigin.Jira]: 'Jira',
  };

export const DISPLAY_NAME_BY_SOURCE_CONTROL_ORIGIN: Record<
  SourceControlOrigin,
  string
> = {
  [SourceControlOrigin.Bitbucket]: 'BitBucket',
  [SourceControlOrigin.Github]: 'GitHub',
};
