import { useCallback } from 'react';
import {
  useCurrentUser,
  useToast,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useUpdateUserMutation } from '@spoke/graphql';

export const useUpdateUserProfileHandler = () => {
  const [currentUser] = useCurrentUser();
  const [toast] = useToast();
  const [updateUserMutation] = useUpdateUserMutation();

  const userId = currentUser?.id;

  const handleUpdateUserProfile = useCallback(
    async (newData: { firstName?: string; lastName?: string }) => {
      if (!userId) {
        log.error('Cannot update user profile without loaded current userId', {
          userId,
        });
        return;
      }

      log.info('Updating current user profile', { newData, userId });

      const { errors } = await updateUserMutation({
        variables: { userInput: newData },
      });

      if (errors) {
        log.error('Update user profile call responded with errors', errors);
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [toast, updateUserMutation, userId]
  );

  return [handleUpdateUserProfile];
};
