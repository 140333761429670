import { FC, useMemo, useState } from 'react';
import { BsArrowRightCircle } from 'react-icons/bs';
import { TeamInsightQueryData } from '../queries';
import { getTeamInsightSummary } from '../util';
import { TeamInsightsModal } from './TeamInsightsModal';
import {
  SpkColumn,
  SpkTime,
  IconButton,
  Icon,
  SpokeTable,
  Text,
} from '@spoke/common';

type Props = {
  insights: TeamInsightQueryData[];
};
export const TeamInsightsHistory: FC<Props> = ({ insights }) => {
  const [viewingInsightId, setViewingInsightId] = useState<string | null>(null);

  const viewingInsight = insights?.find(
    (insight) => insight.id === viewingInsightId
  );

  const columns = useMemo(() => {
    const tableColumns: SpkColumn<TeamInsightQueryData>[] = [
      {
        colKey: 'title',
        header: 'Topic',
        cell: (insight) => (
          <Text color="gray.700" fontSize={14} py="12px" pl={3}>
            {getTeamInsightSummary(insight)}
          </Text>
        ),
      },
      {
        colKey: 'date',
        header: 'Date',
        cell: (insight) => SpkTime.format(insight.date, 'MMM dd, yyyy'),
      },
      {
        colKey: 'actions',
        header: '',
        cell: (insight) => (
          <IconButton
            onClick={() => setViewingInsightId(insight.id)}
            variant="link"
            color="gray.700"
            fontWeight={400}
            textDecor="underline"
            fontSize={14}
            pt={1}
            aria-label="See insight"
            icon={<Icon as={BsArrowRightCircle} w={4} h={4} color="gray.700" />}
          />
        ),
        width: 28,
      },
    ];

    return tableColumns;
  }, []);

  return (
    <>
      <SpokeTable
        data={insights?.slice().sort((a, b) => b.date - a.date) ?? []}
        pageSize={10}
        columns={columns}
      />
      <TeamInsightsModal
        isOpen={Boolean(viewingInsightId)}
        onClose={() => setViewingInsightId(null)}
        insights={viewingInsight ? [viewingInsight] : []}
        skipAnimations
      />
    </>
  );
};
