import { BiInfoCircle } from 'react-icons/bi';
import { useDiscussionAnalytics } from '../queries';
import { SentimentTopicsCard } from '@spoke/charts';
import {
  ONE_DAY_MS,
  useNow,
  Flex,
  HStack,
  TrendCardCondensed,
  Divider,
  Icon,
  Tooltip,
  Box,
  isNullish,
  Text,
} from '@spoke/common';
import { MetricsInsight } from '@spoke/metrics';

const msToDay = (ms?: number | null): number | null =>
  isNullish(ms) ? null : Math.round(ms! / ONE_DAY_MS);

const intOrZero = (value?: number | null): number =>
  isNullish(value) ? 0 : parseInt(`${value}`);

export const DiscussionAnalytics = () => {
  const now = useNow({ roundTo: 'ten-minutes' });

  const [data, { loading }] = useDiscussionAnalytics({
    endDate: now,
    timeWindowLength: 90 * ONE_DAY_MS,
  });

  const current = data?.current;
  const past = data?.past;
  const orgAvg = data?.organization;

  return (
    <Flex
      flex={1}
      flexDir="column"
      layerStyle="outlineGray"
      px={5}
      py={4}
      w="full"
    >
      <Flex mb={2} alignItems="baseline" justifyContent="space-between">
        <Text fontWeight={500}>Discussion Statistics</Text>
        <Text fontSize={12} color="gray.500" fontWeight={400}>
          Last quarter
        </Text>
      </Flex>
      <HStack alignItems="space-between" w="full" mb={1}>
        <TrendCardCondensed
          label="Average time between retros"
          suffix="d"
          loading={loading}
          value={msToDay(current?.avgTimeBetweenRetrosMs)}
          prevValue={msToDay(past?.avgTimeBetweenRetrosMs)}
          orgAvg={msToDay(current?.avgTimeBetweenRetrosMs)}
          ideal="down"
        />
        <TrendCardCondensed
          label="Participation rate"
          suffix="%"
          loading={loading}
          value={current?.participationRatePercent || 0}
          prevValue={past?.participationRatePercent || 0}
          orgAvg={orgAvg?.participationRatePercent || 0}
          ideal="up"
        />
        <TrendCardCondensed
          label="Time to close action items"
          suffix="d"
          loading={loading}
          value={msToDay(current?.averageTimeToResolveMs)}
          prevValue={msToDay(past?.averageTimeToResolveMs)}
          orgAvg={msToDay(current?.averageTimeToResolveMs)}
          ideal="down"
        />
      </HStack>
      <Divider my={3} />
      <HStack spacing={1} mb={1}>
        <Text fontSize={14} color="gray.500">
          Team sentiment
        </Text>
        <Tooltip
          label={
            "We use A.I to analyze your team's retrospective data such as cards, groups and words to understand what people are saying and how they feel about it."
          }
          openDelay={350}
          placement="top"
          fontSize={12}
          maxW={250}
          variant="white"
          hasArrow
        >
          <Box tabIndex={-1} pt="3px">
            <Icon as={BiInfoCircle} color="gray.500" />
          </Box>
        </Tooltip>
      </HStack>
      <HStack w="full" alignItems="stretch" spacing={3}>
        <SentimentTopicsCard
          type="positive"
          loading={loading}
          percentage={intOrZero(current?.positiveCardPercentage)}
          topics={current?.positiveKeywordExamples || []}
        />
        <SentimentTopicsCard
          type="neutral"
          loading={loading}
          percentage={intOrZero(current?.neutralCardPercentage)}
          topics={current?.neutralKeywordExamples || []}
        />
        <SentimentTopicsCard
          type="negative"
          loading={loading}
          percentage={intOrZero(current?.negativeCardPercentage)}
          topics={current?.negativeKeywordExamples || []}
        />
      </HStack>
      {Boolean(loading || current?.insight) && (
        <>
          <Divider my={4} />
          <MetricsInsight
            loading={loading}
            insight={current?.insight ?? null}
          />
        </>
      )}
    </Flex>
  );
};
