import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  TOAST_ERROR_GENERIC,
  optimisticLeaveTeam,
  log,
} from '@spoke/common';
import { useLeaveTeamMutation, LeaveTeamMutation } from '@spoke/graphql';

export const useRemoveUserFromTeamHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [removeUserFromTeam] = useLeaveTeamMutation();

  const handleRemoveUserFromTeam = useCallback(
    async ({
      teamId,
      userId,
    }: {
      teamId: string;
      userId: string;
    }): Promise<LeaveTeamMutation['leaveTeam'] | null> => {
      if (!teamId || !userId) {
        log.error('Missing critical data to remove user from team', {
          teamId,
          userId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const expected = optimisticLeaveTeam({ cache, teamId, userId });

      const { errors, data } = await removeUserFromTeam({
        variables: {
          teamId,
          userId,
        },
        optimisticResponse: expected,
      });

      if (errors) {
        log.warn('removeUserFromTeam mutation responded with error', {
          error: errors,
        });
        return null;
      }

      return data?.leaveTeam;
    },
    [cache, removeUserFromTeam, toast]
  );

  return [handleRemoveUserFromTeam];
};
