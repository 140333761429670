import { FC } from 'react';
import { BiLinkAlt } from 'react-icons/bi';
import { BsChevronDown } from 'react-icons/bs';
import { useJiraIntegration } from '@spoke/user';
import {
  JiraProject,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Divider,
  Button,
} from '@spoke/common';

type JiraOAuthProjectSelectorProps = {
  selectedProject: JiraProject | null;
  onSelectProject: (jiraProject: JiraProject | null) => void;
};
export const JiraOAuthProjectSelector: FC<JiraOAuthProjectSelectorProps> = ({
  onSelectProject,
  selectedProject,
}) => {
  const { jiraProjects, authenticateJira, jiraLoading } = useJiraIntegration();

  return !jiraProjects ? (
    <Button
      variant="outlineGray"
      onClick={authenticateJira}
      isLoading={jiraLoading}
      leftIcon={<Icon as={BiLinkAlt} />}
    >
      Connect
    </Button>
  ) : (
    <Menu>
      <MenuButton type="button" _hover={{ bg: 'gray.100' }}>
        <Button
          as="span"
          variant="outlineGray"
          type="button"
          rightIcon={<Icon as={BsChevronDown} />}
        >
          {selectedProject?.name || 'Select a project'}
        </Button>
      </MenuButton>
      <MenuList>
        {jiraProjects.map((project) => (
          <MenuItem key={project.id} onClick={() => onSelectProject(project)}>
            {project.name}
          </MenuItem>
        ))}
        <Divider my={2} />
        <MenuItem
          key="unselect"
          fontWeight={500}
          onClick={() => onSelectProject(null)}
        >
          None
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
