import { DisableFeatureState } from '@spoke/graphql';

export const featureDisableReasonNames: {
  [key in DisableFeatureState]: string;
} = {
  [DisableFeatureState.Enabled]: 'Enabled',
  [DisableFeatureState.TooExpensive]: 'This tool is too expensive',
  [DisableFeatureState.SuperiorsWontInstall]:
    "I can't get my superiors to install this",
  [DisableFeatureState.NotUsedByTeam]: 'My team does not use this tool',
  [DisableFeatureState.Other]: 'Other',
};
