import { FC, useState } from 'react';
import {
  AiFillInfoCircle,
  AiOutlineDownload,
  AiOutlineQrcode,
} from 'react-icons/ai';
import { useInstallJiraMetricsIntegrationHandler } from '../handlers';
import {
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  HStack,
  Icon,
  JiraIcon,
  Heading,
  VStack,
  Flex,
  Alert,
  InputGroup,
  InputLeftElement,
  Button,
  ModalProps,
  Modal,
  ModalOverlay,
  Circle,
  Input,
  Link,
  Text,
} from '@spoke/common';

const JIRA_METRICS_MARKETPLACE_URL =
  'https://marketplace.atlassian.com/apps/1230517/scatterspoke-metrics-for-jira';

type JiraMetricsInstallationModalModalProps = {
  onClose: () => void;
};
export const JiraMetricsInstallationModalModalContent: FC<
  JiraMetricsInstallationModalModalProps
> = ({ onClose }) => {
  const [code, setCode] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [handleInstallJiraMetrics] = useInstallJiraMetricsIntegrationHandler();

  const onInstall = async () => {
    if (!code || submitting) return;
    setSubmitting(true);
    const success = await handleInstallJiraMetrics(code);
    setSubmitting(false);
    if (!success) return;
    onClose();
  };

  return (
    <ModalContent p={8} pb={4} maxW={700}>
      <ModalCloseButton />
      <ModalBody p={0}>
        <ModalHeader p={0}>
          <HStack>
            <Icon w={6} h={6} as={JiraIcon} />
            <Heading mb={1} fontSize={24} color="gray.900">
              Jira Metrics Installation
            </Heading>
          </HStack>
        </ModalHeader>
        <VStack
          alignItems="flex-start"
          pt={4}
          spacing={5}
          sx={{
            '& b': {
              color: 'primary.600',
              fontWeight: 500,
            },
          }}
        >
          <Flex flexDir="column" alignItems="flex-start" gap={1}>
            <Alert
              bg="blue.50"
              color="blue.500"
              status="info"
              alignItems="flex-start"
              fontSize={16}
              mb={2}
            >
              <Icon
                as={AiFillInfoCircle}
                mr={2}
                mt={1}
                color="blue.400"
                w={4}
                h={4}
                borderRadius="sm"
              />
              This integration is in beta state at this moment. Make sure to
              send us feedback with any issues or suggestions!
            </Alert>
            <HStack>
              <Circle
                borderStyle="solid"
                borderColor="gray.300"
                color="gray.600"
                fontWeight={500}
                borderWidth={1}
                size={6}
              >
                1
              </Circle>
              <Text fontWeight={400} color="gray.700">
                Install ScatterSpoke Jira Metrics on your site from the{' '}
                <Link
                  fontWeight={500}
                  color="primary.600"
                  textDecor="underline"
                  target="_blank"
                  href={JIRA_METRICS_MARKETPLACE_URL}
                >
                  Atlassian Marketplace
                </Link>
                .
              </Text>
            </HStack>
          </Flex>
          <Flex flexDir="column" alignItems="flex-start" gap={1}>
            <HStack alignItems="flex-start">
              <Circle
                borderStyle="solid"
                borderColor="gray.300"
                color="gray.600"
                fontWeight={500}
                borderWidth={1}
                size={6}
              >
                2
              </Circle>
              <Text fontWeight={400} color="gray.700">
                When prompted, click <b>Get Started</b>
              </Text>
            </HStack>
          </Flex>
          <Flex flexDir="column" gap={2} w="full">
            <HStack>
              <Circle
                borderStyle="solid"
                borderColor="gray.300"
                color="gray.600"
                fontWeight={500}
                borderWidth={1}
                size={6}
              >
                3
              </Circle>
              <Text fontWeight={400} color="gray.700">
                Paste your installation code and click <b>Install</b> below
              </Text>
            </HStack>
            <InputGroup maxWidth={365} ml={8} mt={1} mb={4}>
              <InputLeftElement>
                <Icon as={AiOutlineQrcode} />
              </InputLeftElement>
              <Input
                placeholder="Paste your installation code here"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </InputGroup>
          </Flex>
        </VStack>
        <HStack w="full" justifyContent="flex-end" mt={4} mb={2}>
          <Button
            size="lg"
            variant="outlineGray"
            type="submit"
            w="fit-content"
            onClick={onClose}
            isDisabled={submitting}
          >
            Cancel
          </Button>
          <Button
            onClick={onInstall}
            size="lg"
            alignSelf="center"
            ml={2}
            isLoading={submitting}
            leftIcon={<Icon mr="-3px" as={AiOutlineDownload} w={5} h={5} />}
          >
            <Text pt="2px">Install</Text>
          </Button>
        </HStack>
      </ModalBody>
    </ModalContent>
  );
};

type CreateTeamInnerModalProps = Omit<ModalProps, 'children' | 'onClose'> &
  JiraMetricsInstallationModalModalProps;

export const JiraMetricsInstallationModalModal: FC<
  CreateTeamInnerModalProps
> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <JiraMetricsInstallationModalModalContent {...props} />
  </Modal>
);
