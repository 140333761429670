import { FC } from 'react';
import { MotionFlex, MotionFlexProps } from '../Flex';

export const FadeUp: FC<
  MotionFlexProps & { delay?: number; duration?: number }
> = ({ delay = 0, duration = 1, ...props }) => (
  <MotionFlex
    w="full"
    flexDir="column"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: 20 }}
    transition={{ delay, ease: 'easeOut', duration }}
    {...props}
  />
);
