import { motion } from 'framer-motion';
import { FC } from 'react';
import {
  Card,
  VStack,
  Heading,
  Button,
  GoogleIcon,
  MicrosoftIcon,
  AtlassianIcon,
  Image,
  Text,
  useAssets,
} from '@spoke/common';

type AuthMethodSelectorProps = {
  error?: string | null;
  greeting?: string;
  onContinueWithGoogle?: () => void;
  onContinueWithMicrosoft?: () => void;
  onContinueWithAtlassian?: () => void;
  onContinueWithEmailPassword?: () => void;
  onContinueWithSso?: () => void;
};
export const AuthMethodSelector: FC<AuthMethodSelectorProps> = ({
  error,
  greeting = 'Welcome Back!',
  onContinueWithGoogle,
  onContinueWithMicrosoft,
  onContinueWithAtlassian,
  onContinueWithSso,
  onContinueWithEmailPassword,
}) => {
  const { illustrationHello } = useAssets();

  return (
    <motion.div
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: -50, opacity: 0 }}
      transition={{ duration: 0.15, ease: 'easeOut' }}
    >
      <Card w={460} p={10} gap={5}>
        <Image src={illustrationHello} alt="Hello from ScatterSpoke" />
        <VStack>
          <VStack spacing={1}>
            <Heading
              textAlign="center"
              size="md"
              fontWeight={500}
              color="gray.700"
            >
              {greeting}
            </Heading>
            <Text fontSize="sm" color="gray.500">
              Choose how you want to login or register.
            </Text>
          </VStack>
          {error && (
            <Text textAlign="center" fontSize="sm" color="red.400">
              {error}
            </Text>
          )}
        </VStack>
        <VStack w="full">
          {onContinueWithGoogle && (
            <Button
              onClick={() => onContinueWithGoogle()}
              variant="outlineGray"
              leftIcon={<GoogleIcon h="6" w="6" />}
              w="full"
              py="6"
            >
              Continue with Google
            </Button>
          )}
          {onContinueWithMicrosoft && (
            <Button
              onClick={() => onContinueWithMicrosoft()}
              variant="outlineGray"
              leftIcon={<MicrosoftIcon h="6" w="6" />}
              w="full"
              py="6"
            >
              Continue with Microsoft
            </Button>
          )}
          {onContinueWithAtlassian && (
            <Button
              onClick={() => onContinueWithAtlassian()}
              variant="outlineGray"
              leftIcon={<AtlassianIcon h="6" w="6" />}
              w="full"
              py="6"
            >
              Continue with Atlassian
            </Button>
          )}
          {onContinueWithSso && (
            <Button
              onClick={() => onContinueWithSso()}
              w="full"
              py="6"
              variant="outlineGray"
            >
              Use single sign-on (SSO)
            </Button>
          )}
          {onContinueWithEmailPassword && (
            <Button
              id="local_auth"
              onClick={() => onContinueWithEmailPassword()}
              w="full"
              py="6"
              variant="outlineGray"
            >
              Use email and password
            </Button>
          )}
        </VStack>
      </Card>
    </motion.div>
  );
};
