import { useCallback } from 'react';
import { log } from '@spoke/common';
import {
  useSearchMembershipsByOrganizationIdAndEmailLazyQuery,
  MembershipRole,
} from '@spoke/graphql';

type QueryRef = ReturnType<
  typeof useSearchMembershipsByOrganizationIdAndEmailLazyQuery
>[1];
type QueryData = NonNullable<
  NonNullable<QueryRef['data']>['organizationById']
>['memberships']['memberships'];
type FetchArgs = { organizationId: string; email: string };

export const useFetchOrgMemberships = (): [
  (args: FetchArgs) => Promise<QueryData>,
  QueryRef
] => {
  const [membershipsByOrgIdAndEmail, queryRef] =
    useSearchMembershipsByOrganizationIdAndEmailLazyQuery();

  const fetchMembershipsByOrdIdAndEmail = useCallback(
    async ({ email, organizationId }: FetchArgs) => {
      log.info(`Fetching memberships by org id and email`, {
        organizationId,
        email,
      });

      const offset = 0;
      const limit = 100;
      const roleFilter: MembershipRole[] = []; // Empty means all

      if (!organizationId) {
        log.error('Cannot query for org memberships without org id', {
          organizationId,
        });
        return [];
      }

      const { error, data } = await membershipsByOrgIdAndEmail({
        variables: { organizationId, email, limit, offset, roleFilter },
      });

      const memberships = data?.organizationById?.memberships.memberships;

      if (error || !memberships) {
        log.error('membershipsByOrgIdAndEmail query responded with errors', {
          organizationId,
          email,
          offset,
          limit,
          roleFilter,
          error,
          data,
        });
        return [];
      }

      return memberships;
    },
    [membershipsByOrgIdAndEmail]
  );

  return [fetchMembershipsByOrdIdAndEmail, queryRef];
};
