import { AnimatePresence } from 'framer-motion';
import { BiFilterAlt } from 'react-icons/bi';
import { GoPlus } from 'react-icons/go';
import { useActionItems } from '../queries';
import { ActionItemFilterProps, ActionItemFilter } from './ActionItemFilter';
import { ActionItemList } from './ActionItemList';
import { ActionItemQuickAdd } from './ActionItemQuickAdd';
import { Tab, ActionItemTabs } from './ActionItemTabs';
import { MotionFlex, Flex, Icon, Divider, Button } from '@spoke/common';

type ActionItemsModalBodyProps = {
  selectedTab: Tab;
  setSelectedTab: (value: Tab) => void;

  setEditingActionItemId: (value: string) => void;

  filtersOpen: boolean;
  setFiltersOpen: (value: boolean | ((value: boolean) => boolean)) => void;

  filters: ActionItemFilterProps;
};

export const ActionItemsModalBody = ({
  setEditingActionItemId,
  selectedTab,
  setSelectedTab,

  filters,
  filtersOpen,
  setFiltersOpen,
}: ActionItemsModalBodyProps) => {
  const [actionItems] = useActionItems({
    variables: {
      dueDate: [
        filters.dateRange[0]?.getTime(),
        filters.dateRange[1]?.getTime(),
      ],
      assigneeIds: filters.selectedAssignees.map((user) => user.id),
      improvementGoals: filters.selectedGoals.map((goal) => goal.id),
    },
  });

  const { resolved, unresolved } = actionItems;

  const currentActionItems =
    selectedTab === Tab.Resolved ? resolved : unresolved;

  const onActionItemQuickAdded = () => {
    setSelectedTab(Tab.Unresolved);
    setFiltersOpen(false);
  };

  return (
    <>
      <Flex
        gap={6}
        py={4}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
      >
        <ActionItemQuickAdd
          onAdded={onActionItemQuickAdded}
          confirmButtonLabel={<Icon as={GoPlus} />}
        />
      </Flex>
      <Divider mb={4} />
      <Flex justifyContent="space-between" alignItems="center" w="full">
        <AnimatePresence initial={false}>
          {!filtersOpen && (
            <MotionFlex
              initial={{
                opacity: 0,
                transform: 'translateX(-20px)',
                position: 'absolute',
              }}
              exit={{
                opacity: 0,
                transform: 'translateX(-20px)',
                position: 'absolute',
              }}
              animate={{
                opacity: 1,
                transform: 'translateX(0px)',
                position: 'static',
              }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
            >
              <ActionItemTabs
                tab={selectedTab}
                setTab={setSelectedTab}
                resolvedLength={resolved.length}
                unresolvedLength={unresolved.length}
                size="sm"
                w={250}
              />
            </MotionFlex>
          )}
        </AnimatePresence>
        <AnimatePresence initial={false}>
          {filtersOpen && (
            <MotionFlex
              initial={{ opacity: 0, transform: 'translateX(50px)' }}
              exit={{
                opacity: 0,
                transform: 'translateX(50px)',
                position: 'absolute',
              }}
              animate={{ opacity: 1, transform: 'translateX(0px)' }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
            >
              <ActionItemFilter {...filters} />
            </MotionFlex>
          )}
        </AnimatePresence>
        <Button
          variant="outlineGray"
          onClick={() => setFiltersOpen((open) => !open)}
          leftIcon={<Icon as={BiFilterAlt} />}
          bg={filtersOpen ? 'gray.100' : 'white'}
          h="40px"
        >
          {filtersOpen ? 'Close' : 'Filters'}
        </Button>
      </Flex>
      <ActionItemList
        maxH={350}
        actionItems={currentActionItems}
        setEditingActionItemId={setEditingActionItemId}
      />
    </>
  );
};
