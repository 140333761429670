import { ApolloCache, MutationUpdaterFn } from '@apollo/client';
import { randomString } from '../../../etc';
import { log } from '../../../../SpkLog';
import { CacheModifiers, DeepPartial } from '../../../../types';
import { getListFromCache } from '../../getters';
import { CreateCardMutation, List } from '@spoke/graphql';

type OptimisticCreateCardArgs = {
  cache: ApolloCache<object>;
  listId: string;
  text: string;
  authorId: string;
};
export const optimisticCreateCard = ({
  cache,
  listId,
  authorId,
  text,
}: OptimisticCreateCardArgs): CreateCardMutation => {
  const listFromCache = getListFromCache({ listId, cache });
  const index = listFromCache?.cards?.length || 0;

  return {
    __typename: 'Mutation',
    createCard: {
      id: randomString(),
      __typename: 'Card',
      listId,
      authorId,
      parentId: null,
      children: [],
      comments: [],
      text,
      index,
      votes: [],
      archived: false,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      promotedToPrograms: [],
      wasDiscussed: false,
      originalListBadge: {
        __typename: 'OriginalListBadge',
        originalListId: listFromCache?.id,
        text: listFromCache?.name,
        color: listFromCache?.cardColor,
      },
    },
  };
};

export const createCardUpdateFunction: MutationUpdaterFn<CreateCardMutation> = (
  cache,
  { data }
) => {
  const newCard = data?.createCard;

  if (!newCard) {
    log.error('No card received in createCardUpdateFunction', { newCard });
    return;
  }

  const listId = newCard?.listId;
  const listCacheId = 'List:' + listId;

  const updateFields: CacheModifiers<DeepPartial<List>> = {
    cards: (prevCards) => {
      if (!Array.isArray(prevCards)) {
        log.error(
          'Failed to update list in createCardUpdateFunction. List is missing "cards" property.',
          { newCard, prevCards, listId }
        );
        return prevCards;
      }

      // This is only needed here (and in processNewCardSubscriptionData) because of our incorrect setup of
      // subscribing to new cards without an underlying query. Both the optimistic response of creating cards
      // and this are trying to push the same card into list
      const isCardAlreadyInList = Boolean(
        prevCards.some((card) => card?.id === newCard.id)
      );

      if (isCardAlreadyInList) return prevCards;

      return [...prevCards, newCard];
    },
  };

  cache.modify({
    id: listCacheId,
    fields: updateFields,
  });
};
