import { deepClone, isNullish } from '../../../etc';
import { log } from '../../../../SpkLog';
import { Board, Card } from '@spoke/graphql';

type OptimisticAddCardToGroupArgs = {
  dragCard: Card;
  dropCard: Card;
  board: Board;
};
export const optimisticAddCardToGroup = ({
  board: _board,
  dragCard: _dragCard,
  dropCard: _dropCard,
}: OptimisticAddCardToGroupArgs): Board => {
  const board = deepClone(_board);
  const dropCard = deepClone(_dropCard);
  const dragCard = deepClone(_dragCard);

  const dropListIdx = board.lists?.findIndex(
    (list) => list?.id === dropCard.listId
  ) as number;

  const dropCardIdx = board.lists?.[dropListIdx]?.cards?.findIndex(
    (card) => card?.id === dropCard.id
  ) as number;

  if (!board.lists?.[dropListIdx]?.cards?.[dropCardIdx]?.children) {
    log.error('An error has occurred in optimisticAddCardToGroup', {
      lists: board.lists,
      dropListIdx,
      dropCardIdx,
    });
    return board;
  }

  const childCount =
    board.lists[dropListIdx]?.cards?.[dropCardIdx]?.children?.length || 0;

  // Adding dragCard to group (dropCard)
  board.lists[dropListIdx]?.cards?.[dropCardIdx]?.children?.push({
    ...dragCard,
    index: childCount,
    parentId: dropCard.id,
  });

  // Removing dragCard from where it were before
  if (dragCard.parentId) {
    // Card is being dragged from one group to another
    const dragListIdx = board.lists?.findIndex((list) =>
      list?.cards?.some((card) => card?.id === dragCard.parentId)
    );

    if (
      isNullish(dragListIdx) ||
      dragListIdx < 0 ||
      dragListIdx > board.lists.length - 1
    ) {
      log.error('An error has occurred in optimisticAddCardToGroup', {
        dragListIdx,
      });
      return board;
    }

    const dragCardGroupIdx = board.lists[dragListIdx]?.cards?.findIndex(
      (card) => card?.id === dragCard.parentId
    ) as number;

    const dragCardIdxInsideGroup = board.lists[dragListIdx]?.cards?.[
      dragCardGroupIdx
    ]?.children?.findIndex((card) => card?.id === dragCard.id) as number;

    board.lists[dragListIdx]?.cards?.[dragCardGroupIdx]?.children?.splice(
      dragCardIdxInsideGroup,
      1
    );
  } else {
    // Card didn't have a group before
    const dragListIdx = board.lists.findIndex(
      (list) => list?.id === dragCard.listId
    );
    const dragCardIdx = board.lists[dragListIdx]?.cards?.findIndex(
      (card) => card?.id === dragCard.id
    ) as number;

    if (
      isNullish(dragListIdx) ||
      dragListIdx < 0 ||
      dragListIdx > board.lists.length - 1
    ) {
      log.error('An error has occurred in optimisticAddCardToGroup', {
        dragListIdx,
      });
      return board;
    }

    board.lists[dragListIdx]?.cards?.splice(dragCardIdx, 1);
  }

  return board;
};
