import {
  TabList as ChakraTabList,
  TabListProps as ChakraTabListProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

type Props = ChakraTabListProps;

export const TabList = forwardRef<HTMLDivElement, Props>((props, ref) => (
  <ChakraTabList {...props} ref={ref} />
));
