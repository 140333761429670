import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  getListFromCache,
  TOAST_ERROR_GENERIC,
  optimisticDeleteList,
  DeepPartial,
  log,
} from '@spoke/common';
import { useUpdateListMutation, List } from '@spoke/graphql';

type UseDeleteListHandlerArgs = {
  listId: string;
};
export const useDeleteListHandler = ({ listId }: UseDeleteListHandlerArgs) => {
  const [toast] = useToast();
  const [updateList] = useUpdateListMutation();
  const { cache } = useApolloClient();

  const handleDeleteList = useCallback(async () => {
    const list = getListFromCache({ listId, cache });

    if (!list) {
      log.error('Could not archive list: cached list not found', {
        list,
        listId,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const updateListFields: DeepPartial<List> = { archived: true };

    const expected = optimisticDeleteList({ list });

    const { data, errors } = await updateList({
      variables: { listId, fields: updateListFields },
      optimisticResponse: expected,
    });

    if (errors) {
      log.error('Archive list call responded with errors', {
        data,
        errors,
        listId,
        updateListFields,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }
  }, [cache, listId, toast, updateList]);

  return [handleDeleteList];
};
