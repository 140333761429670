import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useCurrentBoardId,
  useToast,
  TOAST_ERROR_GENERIC,
  DeepPartial,
  optimisticUpdateBoard,
  log,
} from '@spoke/common';
import { useUpdateBoardMutation, Board } from '@spoke/graphql';

export const useBoardPublicStatusChangeHandler = () => {
  const [updateBoard] = useUpdateBoardMutation();
  const [boardId] = useCurrentBoardId();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  const handlePublicStatusChange = useCallback(
    async (newStatus: boolean) => {
      if (!boardId) {
        log.error('Cannot change board public status without loaded boardId', {
          boardId,
          newStatus,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const updateBoardFields: DeepPartial<Board> = { public: newStatus };
      const expected = optimisticUpdateBoard({
        cache,
        boardId,
        fields: updateBoardFields,
      });

      const { data, errors } = await updateBoard({
        variables: { boardId, fields: updateBoardFields },
        optimisticResponse: {
          __typename: 'Mutation',
          updateBoard: expected,
        },
      });

      if (errors) {
        log.error(
          'updateBoard (changing public status) responded with errors',
          {
            data,
            errors,
            newStatus,
            boardId,
          }
        );
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [boardId, cache, toast, updateBoard]
  );

  return [handlePublicStatusChange];
};
