import { SpkTime } from '@spoke/common';
import {
  ImprovementGoalTeam,
  useImprovementGoalByIdQuery,
} from '@spoke/graphql';

type QueryRef = ReturnType<typeof useImprovementGoalByIdQuery>;
export type ImprovementGoalQueryData = NonNullable<
  QueryRef['data']
>['findImprovementGoalById'];

type UseImprovementGoalArgs = {
  id: string;
  takeLastNPeriodsWithData?: number;
  reportingDataTeamIds: ImprovementGoalTeam[] | ImprovementGoalTeam;
  fromDate?: Date | undefined;
  toDate?: Date | undefined;
};
export const useImprovementGoal = ({
  id,
  takeLastNPeriodsWithData = 5,
  reportingDataTeamIds,
  fromDate,
  toDate,
}: UseImprovementGoalArgs): [ImprovementGoalQueryData | null, QueryRef] => {
  const goalQuery = useImprovementGoalByIdQuery({
    variables: {
      id,
      takeLastNPeriodsWithData,
      utcOffsetMs: SpkTime.getUtcOffsetMs(),
      reportingDataTeamIds,
      fromDate: fromDate?.getTime(),
      toDate: toDate?.getTime(),
    },
  });
  const goal =
    goalQuery.data?.findImprovementGoalById ??
    goalQuery.previousData?.findImprovementGoalById ??
    null;
  return [goal, goalQuery];
};
