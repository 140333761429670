import { FC, useState, useMemo } from 'react';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdOutlineBookmarkBorder, MdOutlineFlag } from 'react-icons/md';
import { useLinkTeamInsightActionItemOriginHandler } from '../../handlers';
import { useCurrentTeamInsights } from '../../queries';
import { CreateActionItemModal, EditActionItemModal } from '@spoke/action-item';
import {
  useDisclosure,
  TypewriterTextSection,
  IconButton,
  Icon,
  SpkTime,
  Flex,
  VStack,
  Divider,
  Typewriter,
  Button,
  convertCardToActionItem,
  log,
  Tooltip,
  Text,
} from '@spoke/common';
import {
  TeamInsightFactCauseSolutionSolutionActionItemFragmentFragment,
  TeamInsightFactCauseSolutionSolutionGoalFragmentFragment,
  ImprovementGoalTypeFragmentFragment,
} from '@spoke/graphql';
import {
  useImprovementGoals,
  CreateGoalWizardModal,
} from '@spoke/improvement-goals';
import { useCurrentTeam } from '@spoke/user';

type Props = {
  solution:
    | TeamInsightFactCauseSolutionSolutionActionItemFragmentFragment
    | TeamInsightFactCauseSolutionSolutionGoalFragmentFragment;
  typeStartDelayMs?: number;
};
export const TeamInsightFactCauseSolutionSolution: FC<Props> = ({
  solution,
  typeStartDelayMs = 0,
}) => {
  if (
    solution.__typename === 'TeamInsightFactCauseSolutionSolutionActionItem'
  ) {
    return (
      <SolutionActionItem
        typeStartDelayMs={typeStartDelayMs}
        solution={solution}
      />
    );
  }

  if (solution.__typename === 'TeamInsightFactCauseSolutionSolutionGoal') {
    return (
      <SolutionGoal typeStartDelayMs={typeStartDelayMs} solution={solution} />
    );
  }

  return null;
};

const SolutionActionItem: FC<{
  solution: TeamInsightFactCauseSolutionSolutionActionItemFragmentFragment;
  typeStartDelayMs: number;
}> = ({ solution, typeStartDelayMs }) => {
  const createModalDisclosure = useDisclosure();
  const [_, insightsQuery] = useCurrentTeamInsights();
  const [linkActionItem] = useLinkTeamInsightActionItemOriginHandler();
  const [editingActionItemId, setEditingActionItemId] = useState<string | null>(
    null
  );

  const afterActionItemCreate = async (actionItemId: string) => {
    await linkActionItem({
      actionItemId,
      teamInsightSolutionId: solution.id,
    });
  };

  const afterActionItemDelete = async () => {
    log.info(
      'Action item was delete within TeamInsights modal. Refetching TeamInsights'
    );
    await insightsQuery.refetch();
  };

  const typewriterContent: TypewriterTextSection[] = useMemo(() => {
    if (!solution.originatedActionItem) {
      return [{ content: solution.text, type: 'normal' }];
    }

    return [
      {
        content: `✅ You have created the action item `,
        type: 'normal',
      },
      { content: `"${solution.originatedActionItem.title}" `, type: 'bold' },
      {
        content: (
          <IconButton
            d="inline"
            h={5}
            aria-label="Edit action item"
            variant="unstyled"
            icon={<Icon as={AiOutlineEdit} color="primary.700" />}
            onClick={() =>
              setEditingActionItemId(solution?.originatedActionItem?.id ?? null)
            }
          />
        ),
        type: 'component',
      },
      {
        content: ` on ${SpkTime.format(
          solution.originatedActionItem.createdAt ?? Date.now(),
          'MMM dd, yyyy'
        )}`,
        type: 'normal',
      },
    ];
  }, [solution.originatedActionItem, solution.text]);

  return (
    <Flex
      layerStyle="outlineGray"
      alignItems="center"
      borderRadius="lg"
      boxShadow="sm"
      p={4}
      py={3}
      minH={10}
    >
      <VStack spacing={0} color="gray.800" w={30}>
        <Text fontWeight={500} fontSize={12}>
          Act
        </Text>
        <Icon w={5} h={5} as={MdOutlineBookmarkBorder} />
      </VStack>
      <Divider
        minH={12}
        orientation="vertical"
        mx={2}
        borderColor="gray.300"
        mr={4}
      />
      <Typewriter
        content={typewriterContent}
        skipAnimation={typeStartDelayMs === 0}
        startDelayMs={typeStartDelayMs}
        mr={4}
        flex={1}
        fontSize={15}
      />
      {!solution.originatedActionItem && (
        <Tooltip
          variant="white"
          fontSize={12}
          placement="top"
          openDelay={300}
          label="Edit text"
        >
          <IconButton
            onClick={createModalDisclosure.open}
            h={9}
            pt="1px"
            variant="outlineGray"
            mr={2}
            aria-label="Edit"
            icon={<Icon as={AiOutlineEdit} mx={2} />}
          />
        </Tooltip>
      )}
      {!solution.originatedActionItem && (
        <Button
          onClick={createModalDisclosure.open}
          w="fit-content"
          pt="1px"
          fontSize={14}
          h={9}
        >
          Create action item
        </Button>
      )}
      <CreateActionItemModal
        isOpen={Boolean(createModalDisclosure.isOpen)}
        onClose={createModalDisclosure.close}
        initialValues={convertCardToActionItem({ text: solution.text })}
        onCreated={(actionItem) => afterActionItemCreate(actionItem.id)}
      />
      <EditActionItemModal
        isOpen={Boolean(editingActionItemId)}
        onClose={() => setEditingActionItemId(null)}
        actionItemId={editingActionItemId}
        onDeleted={afterActionItemDelete}
      />
    </Flex>
  );
};

const SolutionGoal: FC<{
  solution: TeamInsightFactCauseSolutionSolutionGoalFragmentFragment;
  typeStartDelayMs: number;
}> = ({ solution, typeStartDelayMs }) => {
  const modalDisclosure = useDisclosure();
  const [team] = useCurrentTeam();
  const [teamGoals] = useImprovementGoals({}, { subscribeToChanges: true });
  const temAlreadyHasGoalSet = teamGoals.allGoals?.some(
    (goal) => goal.type.id === solution.type.id
  );

  const [preSelectedGoalType, setPreSelectedGoalType] =
    useState<ImprovementGoalTypeFragmentFragment | null>(solution.type);

  const onCreateRecommendedClick = () => {
    setPreSelectedGoalType(solution.type);
    modalDisclosure.open();
  };

  const typewriterContent: TypewriterTextSection[] = useMemo(() => {
    if (!temAlreadyHasGoalSet) {
      return [
        {
          content: 'Track your ',
          type: 'normal',
        },
        { content: solution.type.name, type: 'bold' },
        {
          content: solution.type.isPollable
            ? ' with a survey before retrospectives'
            : ' going forward',
          type: 'normal',
        },
      ];
    }

    return [
      {
        content: `✅ You are currently tracking the goal `,
        type: 'normal',
      },
      { content: `"${solution.type.name}"`, type: 'bold' },
      {
        content: ` for team `,
        type: 'normal',
      },
      {
        content: team?.name ?? '',
        type: 'bold',
      },
    ];
  }, [
    solution.type.isPollable,
    solution.type.name,
    team?.name,
    temAlreadyHasGoalSet,
  ]);

  return (
    <Flex
      layerStyle="outlineGray"
      alignItems="center"
      borderRadius="lg"
      boxShadow="sm"
      p={4}
      py={3}
      minH={10}
    >
      <VStack spacing={0} color="gray.800" w={30}>
        <Text fontWeight={500} fontSize={12}>
          Track
        </Text>
        <Icon w={5} h={5} as={MdOutlineFlag} />
      </VStack>
      <Divider
        minH={12}
        orientation="vertical"
        mx={2}
        borderColor="gray.300"
        mr={4}
      />
      <Typewriter
        skipAnimation={typeStartDelayMs === 0}
        content={typewriterContent}
        startDelayMs={typeStartDelayMs}
        mr={4}
        flex={1}
        fontSize={15}
      />
      {!temAlreadyHasGoalSet && (
        <Button
          onClick={onCreateRecommendedClick}
          w="fit-content"
          pt="1px"
          fontSize={14}
          h={9}
        >
          Configure team goal
        </Button>
      )}
      <CreateGoalWizardModal
        isOpen={Boolean(modalDisclosure.isOpen)}
        onClose={modalDisclosure.close}
        preSelectedGoalType={preSelectedGoalType}
      />
    </Flex>
  );
};
