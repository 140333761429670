import type { AppProps } from 'next/app';
import { ChakraProvider } from '@chakra-ui/react';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import { SearchContextManager as GiphyContext } from '@giphy/react-components';
import React from 'react';
import { useRouter as useNextRouter } from 'next/router';
import { SomethingWentWrongScreen } from '../screens/etc/500';
import { spokeTheme } from '../util/theme';
import { AppGuards } from '../guards/AppGuards';
import { LayoutProvider } from '../components/Layout/LayoutProvider';
import { ApolloProvider } from '../apollo/client';
import { WEB_ASSETS } from '../util/assets';
import {
  useIsLightweightMode,
  GoogleTagManager,
  Fonts,
  AccessibilityOnDemand,
  AppUpdater,
  InactivityDetection,
  VisitorIdentification,
  ErrorBoundary,
  RouterProvider,
  RoutesProvider,
  AssetsProvider,
  WEB_ROUTES,
  AppContextProvider,
  SpkApp,
} from '@spoke/common';
import { DndPortal } from '@spoke/board';

/* Ability to disable all memos for debugging purposes */
if (process.env.NEXT_PUBLIC_DISABLE_REACT_MEMO === 'true') {
  React.memo = (a: any) => a;
}

const WEB_APP: SpkApp = {
  name: 'web',
};

const giphyApiKey = process.env.NEXT_PUBLIC_GIPHY_API_KEY as string;
function MyApp({ Component, pageProps }: AppProps) {
  const nextRouter = useNextRouter();
  const isLightweightMode = useIsLightweightMode();

  return (
    <ChakraProvider theme={spokeTheme}>
      <GoogleTagManager />
      <Fonts />
      <DndPortal />
      <AccessibilityOnDemand />
      {/* <SlowBrowserDetection /> This is just a proof of concept, so disabled for now */}
      <AppContextProvider app={WEB_APP}>
        <RoutesProvider routes={WEB_ROUTES}>
          <RouterProvider router={nextRouter}>
            <AssetsProvider assets={WEB_ASSETS}>
              <ApolloProvider>
                <AppUpdater />
                <InactivityDetection />
                <VisitorIdentification>
                  <GiphyContext apiKey={giphyApiKey}>
                    <MotionConfig
                      reducedMotion={isLightweightMode ? 'always' : 'user'}
                    >
                      <ErrorBoundary
                        fallbackComponent={SomethingWentWrongScreen}
                      >
                        <AppGuards>
                          <AnimatePresence exitBeforeEnter>
                            <LayoutProvider>
                              <Component
                                {...pageProps}
                                key={nextRouter.pathname}
                              />
                            </LayoutProvider>
                          </AnimatePresence>
                        </AppGuards>
                      </ErrorBoundary>
                    </MotionConfig>
                  </GiphyContext>
                </VisitorIdentification>
              </ApolloProvider>
            </AssetsProvider>
          </RouterProvider>
        </RoutesProvider>
      </AppContextProvider>
    </ChakraProvider>
  );
}

export default MyApp;
