import { ApolloCache, MutationUpdaterFn } from '@apollo/client';
import { deepMerge } from '../../../etc';
import { log } from '../../../../SpkLog';
import { CacheModifiers, DeepPartial } from '../../../../types';
import { getCardCommentFromCache } from '../../getters';
import { ArchiveCardCommentMutation, Card, CardComment } from '@spoke/graphql';

type OptimisticArchiveCardCommentArgs = {
  cache: ApolloCache<object>;
  commentId: string;
};
export const optimisticArchiveCardComment = ({
  cache,
  commentId,
}: OptimisticArchiveCardCommentArgs):
  | ArchiveCardCommentMutation
  | undefined => {
  const oldFields = getCardCommentFromCache({ cache, commentId });
  const newFields: DeepPartial<CardComment> = { archived: true };

  if (!oldFields?.id) {
    log.error(
      'Missing critical data for archiveCardComment optimistic response',
      {
        id: oldFields?.id,
      }
    );

    return;
  }

  const merged = deepMerge(oldFields, newFields);

  const { cardId, authorId, text, author, archived, createdAt } =
    merged as CardComment;

  return {
    __typename: 'Mutation',
    archiveCardComment: {
      __typename: 'CardComment',
      id: oldFields.id,
      cardId,
      authorId,
      text,
      archived,
      createdAt,
      author,
    },
  };
};

export const archiveCardCommentUpdateFunction: MutationUpdaterFn<
  ArchiveCardCommentMutation
> = (cache, { data }) => {
  const deletedComment = data?.archiveCardComment;

  if (!deletedComment) {
    log.error('No comment received in archiveCommentUpdateFunction', {
      deletedComment,
    });
    return;
  }

  const cardCacheId = 'Card:' + deletedComment?.cardId;

  const updateFields: CacheModifiers<DeepPartial<Card>> = {
    comments: (prev = []) => {
      if (Array.isArray(prev)) {
        return prev.filter((comment) => comment?.id !== deletedComment.id);
      }
      return [prev];
    },
  };

  cache.modify({
    id: cardCacheId,
    fields: updateFields,
  });
};
