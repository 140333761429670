import { TextProps } from '@chakra-ui/react';
import { FC } from 'react';
import { Text } from '../Text';

type SpokeTableCellProps = TextProps;
export const SpokeTableCell: FC<SpokeTableCellProps> = ({
  children,
  ...rest
}) => (
  <Text color="gray.500" fontSize={14} py="12px" {...rest}>
    {children}
  </Text>
);
