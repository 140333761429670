import { ApolloCache } from '@apollo/client';
import { log } from '../../../SpkLog';
import {
  LivePollFragmentFragment,
  LivePollFragmentFragmentDoc,
} from '@spoke/graphql';

type GetLivePollFromCacheArgs = {
  pollId: string;
  cache: ApolloCache<object>;
};
export function getLivePollFromCache({
  pollId,
  cache,
}: GetLivePollFromCacheArgs): LivePollFragmentFragment | null {
  const cachedData = cache.readFragment({
    fragment: LivePollFragmentFragmentDoc,
    fragmentName: 'LivePollFragment',
    id: `LivePoll:${pollId}`,
    returnPartialData: true,
  }) as LivePollFragmentFragment | null;
  if (!cachedData) {
    log.error('Unsuccessful attempt to read a LivePollFragment from cache', {
      pollId,
      cachedData,
    });
    return null;
  }
  return cachedData;
}
