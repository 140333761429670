import { useMemo } from 'react';
import { SpkTime } from '../../SpkTime';

export const useNow = (cfg?: { roundTo?: 'ten-minutes' }): Date => {
  const now = useMemo(
    () =>
      cfg?.roundTo === 'ten-minutes'
        ? SpkTime.getEndOf('ten-minutes')
        : new Date(),
    [cfg?.roundTo]
  );
  return now;
};
