import { useEffect } from 'react';
import { useCurrentBoard } from '@spoke/common';
import { useTeamByIdLazyQuery } from '@spoke/graphql';

type QueryRef = ReturnType<typeof useTeamByIdLazyQuery>[1];
type QueryData = NonNullable<QueryRef['data']>['teamById'];

export const useCurrentBoardTeam = (): [QueryData, QueryRef] => {
  const [board] = useCurrentBoard();
  const [loadTeam, teamQuery] = useTeamByIdLazyQuery();

  useEffect(() => {
    if (!board?.teamId || teamQuery.called) return;
    loadTeam({ variables: { teamId: board.teamId } });
  }, [board?.teamId, loadTeam, teamQuery.called]);

  const team = teamQuery.data?.teamById;

  return [team, teamQuery];
};
