import { FC, useMemo } from 'react';
import {
  MdLockOutline,
  MdOutlineAccountBalanceWallet,
  MdOutlineIntegrationInstructions,
} from 'react-icons/md';
import { AiOutlineUser } from 'react-icons/ai';
import {
  SpkLink,
  HStack,
  Icon,
  ProfileIcon,
  ProgramsIcon,
  TeamsIcon,
  VStack,
  OrganizationIcon,
  Skeleton,
  Divider,
  Flex,
  compareRoutes,
  Stack,
  Text,
  useRouter,
  useRoutes,
} from '@spoke/common';
import { useCurrentOrg } from '@spoke/user';

type NavItemData = {
  Icon: FC;
  label: string;
  route: string;
};

type NavItemProps = { item: NavItemData; isSelected: boolean };
const NavItem: FC<NavItemProps> = ({ isSelected, item }) => (
  <SpkLink href={item.route} passHref>
    <HStack
      as="a"
      color={isSelected ? 'primary.500' : 'gray.500'}
      my={2}
      w="full"
    >
      <Icon as={item.Icon} h={5} w={5} />
      <Text fontSize="sm" fontWeight="500">
        {item.label}
      </Text>
    </HStack>
  </SpkLink>
);

export const OrgSidebar = () => {
  const [currentOrg] = useCurrentOrg();
  const router = useRouter();
  const routes = useRoutes();

  const settingsNavItems: NavItemData[] = useMemo(
    () => [
      { Icon: ProfileIcon, label: 'Account', route: '' },
      { Icon: MdLockOutline, label: 'Security', route: '' },
      { Icon: MdOutlineAccountBalanceWallet, label: 'Billing', route: '' },
      {
        Icon: MdOutlineIntegrationInstructions,
        label: 'Integrations',
        route: '',
      },
    ],
    []
  );

  const peopleManagementNavItems: NavItemData[] = useMemo(
    () => [
      { Icon: ProgramsIcon, label: 'Programs', route: '' },
      { Icon: TeamsIcon, label: 'Teams', route: '' },
      { Icon: AiOutlineUser, label: 'Users', route: routes.Users },
    ],
    [routes.Users]
  );

  return (
    <VStack
      pt={2}
      pb={4}
      px={2}
      w={300}
      alignItems="flex-start"
      borderRightWidth={1}
      borderColor="gray.100"
    >
      <HStack
        justifyContent="space-between"
        w="full"
        h={10}
        px={2}
        bg="primary.25"
        borderRadius={7}
      >
        <HStack>
          <OrganizationIcon w={5} h={5} color="primary.500" />
          <Skeleton isLoaded={Boolean(currentOrg)}>
            <Text
              fontSize={14}
              fontWeight={500}
              color="primary.500"
              letterSpacing="2px"
              cursor="default"
            >
              {currentOrg?.name}
            </Text>
          </Skeleton>
        </HStack>
      </HStack>
      <Divider />
      <Stack
        direction="column"
        alignItems="stretch"
        p={4}
        w="full"
        spacing={10}
      >
        <Flex flexDirection="column" alignItems="flex-start">
          <Text color="gray.700" fontSize={14} mb={3} fontWeight={600}>
            Settings
          </Text>
          {settingsNavItems.map((navItem) => (
            <NavItem
              key={navItem.label}
              item={navItem}
              isSelected={compareRoutes(router.route, navItem.route)}
            />
          ))}
        </Flex>
        <Flex flexDirection="column" alignItems="flex-start">
          <Text color="gray.700" fontSize={14} mb={3} fontWeight={600}>
            People Management
          </Text>
          {peopleManagementNavItems.map((navItem) => (
            <NavItem
              key={navItem.label}
              item={navItem}
              isSelected={compareRoutes(router.route, navItem.route)}
            />
          ))}
        </Flex>
      </Stack>
    </VStack>
  );
};
