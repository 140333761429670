import { useCallback } from 'react';
import { useToast, TOAST_ERROR_GENERIC, log, isNullish } from '@spoke/common';
import { useAddGoalDataMutation } from '@spoke/graphql';

export const useAddImprovementGoalDataHandler = () => {
  const [addGoalData] = useAddGoalDataMutation();
  const [toast] = useToast();

  const handleAddGoalData = useCallback(
    async (data: {
      improvementGoalId: string;
      reportingTeamId: string;
      value: number;
      date: Date;
    }): Promise<boolean> => {
      const { improvementGoalId, reportingTeamId, date, value } = data;

      if (!improvementGoalId || !date || isNullish(value)) {
        log.error('Missing critical data to add improvement goal data', {
          improvementGoalId,
          date,
          value,
        });
        return false;
      }

      log.info('Adding improvement goal data', { data });

      const { errors } = await addGoalData({
        variables: {
          date: date.getTime(),
          goalId: improvementGoalId,
          reportingTeamId,
          value,
        },
      });

      if (errors) {
        log.error('addImprovementGoalData call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [addGoalData, toast]
  );

  return [handleAddGoalData];
};
