import { FC, useState } from 'react';
import { BiWallet } from 'react-icons/bi';
import { FaFileCsv, FaFilePdf } from 'react-icons/fa';
import {
  useCurrentOrg,
  useBillingDetails,
  useCancelSubscriptionHandler,
} from '../../queries';
import { PricingPlan, PricingPlanProps } from './PricingPlan';
import {
  SpkHttp,
  Icon,
  BusinessPlanIcon,
  TeamPlanIcon,
  useCurrentUser,
  DefaultPlanSlug,
  useDisclosure,
  SpkTime,
  isServer,
  ModalContent,
  ModalHeader,
  HStack,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ConfirmationModalCondensed,
  ModalProps,
  Modal,
  ModalOverlay,
  IntegrationType,
  Text,
  INTEGRATION_STATIC_DATA,
  Link,
  Flex,
} from '@spoke/common';
import { MembershipRole, PaymentStatus } from '@spoke/graphql';

const { JiraMetrics, Github, BitbucketMetrics, Slack } = IntegrationType;

const LITE_PRICING: Record<
  'yearly' | 'monthly',
  {
    perTeam: number;
    checkoutUrl: string;
    discountInPercent?: number;
  }
> = {
  monthly: {
    perTeam: 115,
    checkoutUrl: `${SpkHttp.BASE_URL}/stripe/checkout?periodicity=monthly`, // TOOD: add pricing plan to this URL param
  },
  yearly: {
    perTeam: 90,
    checkoutUrl: `${SpkHttp.BASE_URL}/stripe/checkout?periodicity=yearly`, // TOOD: add pricing plan to this URL param
    discountInPercent: 22,
  },
};

const BUSINESS_PRICING: Record<
  'yearly' | 'monthly',
  {
    perTeam: number;
    checkoutUrl: string;
    discountInPercent?: number;
  }
> = {
  monthly: {
    perTeam: 195,
    checkoutUrl: `${SpkHttp.BASE_URL}/stripe/checkout?periodicity=monthly`, // TOOD: add pricing plan to this URL param
  },
  yearly: {
    perTeam: 150,
    checkoutUrl: `${SpkHttp.BASE_URL}/stripe/checkout?periodicity=yearly`, // TOOD: add pricing plan to this URL param
    discountInPercent: 23,
  },
};

const STATIC_BUSINESS_PLAN_PROPS: Omit<
  PricingPlanProps,
  | 'buttonLabel'
  | 'price'
  | 'period'
  | 'onPeriodChange'
  | 'onUpgradeClick'
  | 'priceDescription'
> = {
  bannerBgColor: 'primary.500',
  bannerColor: 'white',
  description: 'Automatically collect & analyze unlimited team feedback.',
  buttonDescription: '',
  features: [
    'Issues + Sentiment Over Time',
    'Rollup Team Feedback Across Teams',
    'Automated Team Feedback Collection',
    'Recurring Feedback Automation',
    'Retrospective AI Copilot',
    'Software Engineering Metrics',
    'SAML SSO',
  ],
  icon: <Icon color="white" fill="white" w={12} h={12} as={BusinessPlanIcon} />,
  integrations: [
    {
      icon: <Icon as={FaFilePdf} color="red" />,
      title: 'PDF',
      description: 'Export your retrospective data as a PDF',
    },
    {
      icon: <Icon as={INTEGRATION_STATIC_DATA[Slack].icon} />,
      title: INTEGRATION_STATIC_DATA[Slack].title,
      description: INTEGRATION_STATIC_DATA[Slack].description,
    },
    {
      icon: <Icon as={INTEGRATION_STATIC_DATA[JiraMetrics].icon} />,
      title: INTEGRATION_STATIC_DATA[JiraMetrics].title,
      description: INTEGRATION_STATIC_DATA[JiraMetrics].description,
    },
    {
      icon: <Icon as={INTEGRATION_STATIC_DATA[BitbucketMetrics].icon} />,
      title: INTEGRATION_STATIC_DATA[BitbucketMetrics].title,
      description: INTEGRATION_STATIC_DATA[BitbucketMetrics].description,
    },
    {
      icon: <Icon as={INTEGRATION_STATIC_DATA[Github].icon} />,
      title: INTEGRATION_STATIC_DATA[Github].title,
      description: INTEGRATION_STATIC_DATA[Github].description,
    },
  ],
  name: 'Business',
  bannerWaves: true,
  preFeatures: '...everything in Lite, plus:',
};

const STATIC_LITE_PLAN_PROPS: Omit<
  PricingPlanProps,
  | 'buttonLabel'
  | 'price'
  | 'period'
  | 'onPeriodChange'
  | 'onUpgradeClick'
  | 'priceDescription'
> = {
  bannerBgColor: 'primary.50',
  bannerColor: 'primary.400',
  buttonDescription:
    'Get started with basic, continuous improvement cycles for up to 50 members.',
  description:
    'Standardize gathering & analyzing team feedback for your teams.',
  features: [
    'Team Feedback Intelligence',
    'Async Team Feedback',
    'Retrospectives',
    'Goals',
  ],
  icon: (
    <Icon color="gray.700" fill="gray.700" w={12} h={12} as={TeamPlanIcon} />
  ),
  name: 'Lite',
  bannerDescriptionColor: 'gray.600',
  buttonVariant: 'outline',
  integrations: [],
};

const STATIC_ENTERPRISE_PLAN_PROPS: PricingPlanProps = {
  bannerBgColor: 'primary.50',
  bannerColor: 'primary.400',
  buttonDescription:
    'Get started with basic, continuous improvement cycles for up to 50 members.',
  buttonLabel: 'Free',
  description: 'Scale team feedback insights accross your entire orgnaization.',
  features: [
    'Metrics + Feedback Insights',
    'Custom Team Feedback Recap Timeframes',
    'Central management and insights for tracking adoption and usage',
    'Automate License Management',
    'Custom Data Retention Policies',
    'Custom Integrations',
  ],
  icon: (
    <Icon color="gray.700" fill="gray.700" w={12} h={12} as={TeamPlanIcon} />
  ),
  name: 'Enterprise',
  price: 0,
  priceDescription: 'Free for up to 50 users',
  bannerDescriptionColor: 'gray.600',
  buttonVariant: 'outline',
  integrations: [],
  preFeatures: '...everything in Business, plus:',
};

export const PricingModalContent: FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const [org] = useCurrentOrg();
  const [billing] = useBillingDetails();

  const [user] = useCurrentUser();

  const isAdmin = user?.role === MembershipRole.Admin;

  const userCount = org?.userCount ?? 0;
  const teamCount = org?.numberOfTeams ?? 0;
  const planSlug = org?.plan?.slug ?? DefaultPlanSlug.Free;

  const [handleCancelSubscription] = useCancelSubscriptionHandler();
  const cancelSubscriptionConfirmation = useDisclosure();

  const canCancelPlan =
    (planSlug !== DefaultPlanSlug.Free &&
      planSlug !== DefaultPlanSlug.UnlimitedTrial) ||
    org?.status === PaymentStatus.TrialExpired ||
    (planSlug === DefaultPlanSlug.UnlimitedTrial && org?.trialEndsAt); // Is on trial but didn't decide whether to upgrade or not yet

  const [businessPeriod, setBusinessPeriod] = useState<'monthly' | 'yearly'>(
    'yearly'
  );

  const litePrice =
    teamCount *
    LITE_PRICING[businessPeriod].perTeam *
    (businessPeriod === 'yearly' ? 12 : 1);
  const litePriceDescription = `$${
    BUSINESS_PRICING[businessPeriod].perTeam
  }/team${businessPeriod === 'yearly' ? '/month paid annually' : ''}`;
  const businessPrice =
    businessPeriod === 'yearly'
      ? teamCount * BUSINESS_PRICING[businessPeriod].perTeam * 12
      : teamCount * BUSINESS_PRICING[businessPeriod].perTeam;
  const businessPriceDescription = `$${
    BUSINESS_PRICING[businessPeriod].perTeam
  }/team${businessPeriod === 'yearly' ? '/month paid annually' : ''}`;

  const liteUpgradeLabel = 'Talk to us';
  const liteStartLabel = !billing?.subscriptionStartsAt
    ? null
    : `Plan will start on ${SpkTime.format(
        billing.subscriptionStartsAt,
        'MMM dd'
      )}`;
  const businessUpgradeLabel = 'Talk to us';
  const businessStartLabel = !billing?.subscriptionStartsAt
    ? null
    : `Plan will start on ${SpkTime.format(
        billing.subscriptionStartsAt,
        'MMM dd'
      )}`;

  const liteDiscount = LITE_PRICING[businessPeriod].discountInPercent;
  const businessesDiscount = BUSINESS_PRICING[businessPeriod].discountInPercent;
  const businessPlanScheduledToStart = !!billing?.subscriptionStartsAt;

  // the way this worked before, is we passed the period as url param to our stripe endpoint
  // which builds the url to redirect to stripe billing page
  const onUpgradeClick = () => {
    if (!isServer()) {
      window.open(
        'https://meet.scatterspoke.com/meetings/organization/pricing-page-checkout',
        '_blank'
      );
    }
  };

  const businessPlanProps: PricingPlanProps = {
    ...STATIC_BUSINESS_PLAN_PROPS,
    buttonLabel: businessStartLabel || businessUpgradeLabel,
    priceDescription: businessPriceDescription,
    onPeriodChange: setBusinessPeriod,
    period: businessPlanScheduledToStart ? undefined : businessPeriod,
    price: businessPrice,
    disabledReason: isAdmin
      ? undefined
      : 'You need to be an organization admin to change plans.',
    onUpgradeClick,
    discountInPercent: businessesDiscount,
  };

  const onCancelSubscription = async () => {
    await handleCancelSubscription();
    onClose();
  };

  const litePlanProps: PricingPlanProps = {
    ...STATIC_LITE_PLAN_PROPS,
    price: litePrice,
    onPeriodChange: setBusinessPeriod,
    period: businessPlanScheduledToStart ? undefined : businessPeriod,
    priceDescription: litePriceDescription,
    disabledReason: isAdmin
      ? undefined
      : 'You need to be an organization admin to change plans.',
    onUpgradeClick,
    buttonLabel: canCancelPlan ? liteUpgradeLabel : 'Currently selected',
    buttonDescription: '',
    discountInPercent: liteDiscount,
  };

  const enterprisePlanProps: PricingPlanProps = {
    ...STATIC_ENTERPRISE_PLAN_PROPS,
    priceDescription: '',
    disabledReason: isAdmin
      ? undefined
      : 'You need to be an organization admin to change plans.',
    buttonLabel: 'Talk to us',
    buttonDescription: '',
    onUpgradeClick,
  };

  return (
    <ModalContent maxW={1080}>
      <ModalHeader pb={1} pt={5}>
        <HStack>
          <Icon as={BiWallet} />
          <Text fontWeight={500}>
            Pricing for{' '}
            <Text as="span" fontWeight={600}>
              {org?.name}
            </Text>
          </Text>
        </HStack>
        <Text fontSize={13}>
          To see the full breakdown of each plan, view our{' '}
          <Link
            textDecor="underline"
            href="https://www.scatterspoke.com/pricing"
          >
            full pricing page
          </Link>{' '}
          to learn more.
        </Text>
        <ModalCloseButton top={4} right={4} />
      </ModalHeader>
      <ModalBody>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          alignItems="stretch"
          gap={4}
        >
          <PricingPlan {...litePlanProps} />
          <PricingPlan {...businessPlanProps} />
          <PricingPlan {...enterprisePlanProps} />
        </Flex>
        <ModalFooter px={0} flexDirection="column" gap={4}>
          <Flex direction="column" gap={2}>
            {planSlug !== DefaultPlanSlug.UnlimitedTrial &&
              planSlug !== DefaultPlanSlug.Lite &&
              planSlug !== DefaultPlanSlug.BusinessTeamBased && (
                <Text fontSize={13}>
                  We&apos;re transitioning to team-based pricing. Our team will
                  manually manage subscription updates during this period.
                  Please reach out to our team if you have any
                  questions.
                </Text>
              )}
            {/* {(planSlug === DefaultPlanSlug.Lite ||
              planSlug === DefaultPlanSlug.BusinessTeamBased ||
              planSlug === DefaultPlanSlug.UnlimitedTrial) && (
              <Text fontSize={13}>
                We are migrating billing systems and will be handling new
                subscriptions manually until the migration is complete.
              </Text>
            )} */}
          </Flex>
          <Text fontSize={13}>
            If you have any questions or prefer to make changes via support,
            please{' '}
            <Link textDecor="underline" href="mailto:support@scatterspoke.com">
              contact us
            </Link>{' '}
            and one of our specialists will assist you.
          </Text>
        </ModalFooter>
      </ModalBody>
      <ConfirmationModalCondensed
        isOpen={cancelSubscriptionConfirmation.isOpen}
        close={cancelSubscriptionConfirmation.close}
        onConfirm={onCancelSubscription}
        title="Are you sure?"
        text="<div style='display: flex;flex-direction: column;gap: 10px;'><b>Please review the following changes to switch to the free plan:</b><p><b>User limit:</b> If you have more than 50 users in your organization, you must remove some users to continue using ScatterSpoke for free.</p><p><b>Metrics & Insights:</b> If you have set up any integrations to collect engineering metrics, we will remove the metrics dashboards, and you will no longer receive AI-driven insights to boost your organization's performance.</p></div>"
        confirmText="Downgrade to free"
        confirmButtonProps={{ colorScheme: 'red' }}
      />
    </ModalContent>
  );
};

export const PricingModal: FC<Omit<ModalProps, 'children'>> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <PricingModalContent {...props} />
  </Modal>
);
