import { useCallback, useState } from 'react';

export type Disclosure = {
  isOpen: boolean;
  open: () => unknown;
  close: () => unknown;
  toggle: () => unknown;
};
export const useDisclosure = (initialIsOpen: boolean = false): Disclosure => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((prev) => !prev), []);

  return { isOpen, close, open, toggle };
};
