import {
  VStack,
  Flex,
  Icon,
  JiraIcon,
  HStack,
  Button,
  Text,
  useRouter,
} from '@spoke/common';

export const TeamJiraBoardLinkEmptyState = () => {
  const router = useRouter();

  return (
    <VStack h={117} alignItems="stretch" layerStyle="outlineGray" spacing={4}>
      <Flex flexDir="column" flex="1">
        <Text fontSize={15} fontWeight={500} color="gray.700">
          <Icon as={JiraIcon} mr={1} mb="3px" />
          Jira
        </Text>
        <Text mb={2} fontSize={14} color="gray.500">
          Select which Jira boards are linked to this team.
        </Text>
        <HStack flex={1}>
          <Text color="gray.500" fontSize={14}>
            Your organization has not set up Jira integration yet. You can do
            that within your{' '}
            <Button
              variant="link"
              fontSize={14}
              textDecor="underline"
              fontWeight={400}
              onClick={() => router.push(`/integrations`)}
            >
              organization settings
            </Button>
            .
          </Text>
        </HStack>
      </Flex>
    </VStack>
  );
};
