import { Global } from '@emotion/react';

export const Fonts = () => (
  <Global
    styles={`
      @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

      @font-face {
        font-family: 'intro-bold';
        font-style: normal;
        font-weight: normal;
        src: url('/assets/next/fonts/intro-bold/intro-bold.eot');
        src: url('/assets/next/fonts/intro-bold/intro-bold.eot?#iefix') format('embedded-opentype'),
          url('/assets/next/fonts/intro-bold/intro-bold.woff2') format('woff2'),
          url('/assets/next/fonts/intro-bold/intro-bold.woff') format('woff'),
          url('/assets/next/fonts/intro-bold/intro-bold.ttf') format('truetype'),
          url('/assets/next/fonts/intro-bold/intro-bold.svg#fontawesome') format('svg');
      }

      @font-face {
        font-family: 'museo-sans';
        font-style: normal;
        font-weight: normal;
        src: url('/assets/next/fonts/museo-sans/museo-sans.eot');
        src: url('/assets/next/fonts/museo-sans/museo-sans.eot?#iefix') format('embedded-opentype'),
          url('/assets/next/fonts/museo-sans/museo-sans.woff2') format('woff2'),
          url('/assets/next/fonts/museo-sans/museo-sans.woff') format('woff'),
          url('/assets/next/fonts/museo-sans/museo-sans.ttf') format('truetype'),
          url('/assets/next/fonts/museo-sans/museo-sans.svg#fontawesome') format('svg');
      }

      @font-face {
        font-family: 'museo-sans-rd-500';
        font-style: normal;
        font-weight: 500;
        src: url('/assets/next/fonts/museo-sans/museo-sans-rounded-500.eot');
        src: url('/assets/next/fonts/museo-sans/museo-sans-rounded-500.eot?#iefix')
            format('embedded-opentype'),
          url('/assets/next/fonts/museo-sans/museo-sans-rounded-500.woff2') format('woff2'),
          url('/assets/next/fonts/museo-sans/museo-sans-rounded-500.woff') format('woff'),
          url('/assets/next/fonts/museo-sans/museo-sans-rounded-500.ttf') format('truetype');
      }

      @font-face {
        font-family: 'museo-sans-rd-700';
        font-style: normal;
        font-weight: 700;
        src: url('/assets/next/fonts/museo-sans/museo-sans-rounded-700.eot');
        src: url('/assets/next/fonts/museo-sans/museo-sans-rounded-700.eot?#iefix')
            format('embedded-opentype'),
          url('/assets/next/fonts/museo-sans/museo-sans-rounded-700.woff2') format('woff2'),
          url('/assets/next/fonts/museo-sans/museo-sans-rounded-700.woff') format('woff'),
          url('/assets/next/fonts/museo-sans/museo-sans-rounded-700.ttf') format('truetype');
      }

      @font-face {
        font-family: 'museo-sans-900';
        font-style: normal;
        font-weight: 900;
        src: url('/assets/next/fonts/museo-sans/museo-sans-900.eot');
        src: url('/assets/next/fonts/museo-sans/museo-sans-900.eot?#iefix')
            format('embedded-opentype'),
          url('/assets/next/fonts/museo-sans/museo-sans-900.woff') format('woff'),
          url('/assets/next/fonts/museo-sans/museo-sans-900.ttf') format('truetype');
      }

      @font-face {
        font-family: 'proxima-nova';
        font-style: normal;
        font-weight: normal;
        src: url('/assets/next/fonts/proxima-nova/proxima-nova.eot');
        src: url('/assets/next/fonts/proxima-nova/proxima-nova.eot?#iefix') format('embedded-opentype'),
          url('/assets/next/fonts/proxima-nova/proxima-nova.woff2') format('woff2'),
          url('/assets/next/fonts/proxima-nova/proxima-nova.woff') format('woff'),
          url('/assets/next/fonts/proxima-nova/proxima-nova.ttf') format('truetype'),
          url('/assets/next/fonts/proxima-nova/proxima-nova.svg#fontawesome') format('svg');
      }

      @font-face {
        font-family: 'proxima-nova-md';
        font-style: normal;
        font-weight: 500;
        src: url('/assets/next/fonts/proxima-nova/proxima-nova-md.eot');
        src: url('/assets/next/fonts/proxima-nova/proxima-nova-md.eot?#iefix')
            format('embedded-opentype'),
          url('/assets/next/fonts/proxima-nova/proxima-nova-md.woff2') format('woff2'),
          url('/assets/next/fonts/proxima-nova/proxima-nova-md.woff') format('woff'),
          url('/assets/next/fonts/proxima-nova/proxima-nova-md.ttf') format('truetype'),
          url('/assets/next/fonts/proxima-nova/proxima-nova-md.svg#fontawesome') format('svg');
      }

      @font-face {
        font-family: 'proxima-nova-sb';
        font-style: normal;
        font-weight: 600;
        src: url('/assets/next/fonts/proxima-nova/proxima-nova-sb.eot');
        src: url('/assets/next/fonts/proxima-nova/proxima-nova-sb.eot?#iefix')
            format('embedded-opentype'),
          url('/assets/next/fonts/proxima-nova/proxima-nova-sb.woff2') format('woff2'),
          url('/assets/next/fonts/proxima-nova/proxima-nova-sb.woff') format('woff'),
          url('/assets/next/fonts/proxima-nova/proxima-nova-sb.ttf') format('truetype'),
          url('/assets/next/fonts/proxima-nova/proxima-nova-sb.svg#fontawesome') format('svg');
      }
      `}
  />
);
