import { FlexboxProps, FlexProps } from '@chakra-ui/react';
import { FC } from 'react';
import { TeamJiraBoardLinkSelector } from '../LinkSelector';
import {
  useProjectManagementRateLimitLock,
  Flex,
  Icon,
  JiraIcon,
  Text,
} from '@spoke/common';

type Props = Omit<FlexProps, 'direction'> & {
  direction?: FlexboxProps['flexDir'];
};
export const FlowMetricsTeamInstallBox: FC<Props> = ({
  direction = 'column',
  ...rest
}) => {
  const [projectManagementRateLimited] = useProjectManagementRateLimitLock();

  if (projectManagementRateLimited) return null;

  return (
    <Flex
      flex={1}
      flexDir="column"
      layerStyle="outlineGray"
      px={5}
      py={4}
      {...rest}
    >
      <Flex justifyContent="space-between">
        <Text fontWeight={500}>Flow</Text>
        <Icon as={JiraIcon} h={5} w={5} mt={1} />
      </Flex>
      <Text fontSize={14} color="gray.600" mb={1}>
        Choose which Jira boards are linked to this team to get insights on your
        work.
      </Text>
      <TeamJiraBoardLinkSelector flexDir={direction} maxLabelLength={60} />
    </Flex>
  );
};
