import { hashComments } from './hashComments';
import { hashPromotedPrograms } from './hashPromotedPrograms';
import { Card, Comment } from '@spoke/graphql';

/**
 * Creates a string representing all data from a card that is visible in the screen
 * (i.e. data that, when changed, should trigger a re-render)
 */
function _hashCards(cards: Card[] | Card): string {
  function hashCard(c: Card): string {
    return `${c?.id}:${c?.text}:${c.votes?.length}:${_hashCards(
      (c.children as Card[]) || []
    )}:${c.archived}:${c.originalListBadge?.color}:${
      c.originalListBadge?.text
    }:${hashComments((c.comments as Comment[]) || [])}:${hashPromotedPrograms(
      c?.promotedToPrograms
    )};`;
  }

  if (!Array.isArray(cards)) return hashCard(cards as Card);
  return cards.reduce((a, c) => `${a}${hashCard(c)}`, '');
}

export const hashCard = _hashCards;
export const hashCards = _hashCards;
