import {
  FC,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MdOutlineTimer } from 'react-icons/md';
import { AnimationProps } from 'framer-motion';
import { MemoizedBoardTimerPopover } from './BoardTimerPopover';
import {
  useDisclosure,
  useForceRender,
  Box,
  Popover,
  PopoverTrigger,
  MotionFlex,
  Tooltip,
  Button,
  Icon,
  ONE_MINUTE_MS,
  ONE_SECOND_MS,
  Text,
} from '@spoke/common';

const SHAKE_ANIMATION: AnimationProps['animate'] = {
  transform: [
    'translate(1px, 1px) rotate(0deg)',
    'translate(-1px, -2px) rotate(-1deg)',
    'translate(-3px, 0px) rotate(1deg)',
    'translate(3px, 2px) rotate(0deg)',
    'translate(1px, -1px) rotate(1deg)',
    'translate(-1px, 2px) rotate(-1deg)',
    'translate(-3px, 1px) rotate(0deg)',
    'translate(3px, 1px) rotate(-1deg)',
    'translate(-1px, -1px) rotate(1deg)',
    'translate(1px, 2px) rotate(0deg)',
    'translate(1px, -2px) rotate(-1deg)',
    'translate(0px, 0px) rotate(0deg)',
  ],
};

export type BoardTimerProps = {
  startTimeMs: number | null;
  stopTimeMs: number | null;
  readOnly?: boolean;
  onTimerChange?: (
    startMs: number | null,
    stopMs: number | null
  ) => Promise<void>;
};
export const BoardTimer: FC<BoardTimerProps> = ({
  startTimeMs,
  stopTimeMs,
  readOnly,
  onTimerChange,
}) => {
  const { isOpen, close, toggle } = useDisclosure();
  const forceRender = useForceRender();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);

  const elapsedTime = startTimeMs ? Date.now() - startTimeMs : 0;

  const targetTime =
    startTimeMs && stopTimeMs
      ? new Date(stopTimeMs).getTime() - startTimeMs
      : 0;

  const remainingMs = targetTime - elapsedTime;

  const displayMinutes = Math.floor(remainingMs / ONE_MINUTE_MS)
    .toString()
    .padStart(2, '0');

  const displaySeconds = Math.floor(
    (remainingMs % ONE_MINUTE_MS) / ONE_SECOND_MS
  )
    .toString()
    .substring(0, 2)
    .padStart(2, '0');

  const isActive = stopTimeMs !== null;
  const timeIsUp = isActive && remainingMs <= 0;

  useEffect(() => {
    const cleanup = () => {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
        intervalRef.current = null;
      }
    };

    if (!isActive || timeIsUp) {
      cleanup();
      return cleanup;
    } else {
      intervalRef.current = setInterval(() => forceRender(), ONE_SECOND_MS);
      return cleanup;
    }
  }, [isActive, forceRender, timeIsUp]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (!popoverRef.current?.contains(e.target as Node) && isOpen) close();
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [close, isOpen]);

  const handleReset = useCallback(async () => {
    close();
    if (onTimerChange) await onTimerChange(0, 0);
  }, [close, onTimerChange]);

  const handleStart = useCallback(async () => {
    close();
    const nowMs = Date.now();
    const selectedTimeMs = minutes * ONE_MINUTE_MS + seconds * ONE_SECOND_MS;
    if (onTimerChange) await onTimerChange(nowMs, nowMs + selectedTimeMs);
  }, [close, minutes, onTimerChange, seconds]);

  const handleUpdateStopTime = useCallback(async () => {
    if (minutes === 0 && seconds === 0) return;
    close();
    const nowMs = Date.now();
    const selectedTimeMs = minutes * ONE_MINUTE_MS + seconds * ONE_SECOND_MS;
    if (onTimerChange) await onTimerChange(null, nowMs + selectedTimeMs);
  }, [close, minutes, onTimerChange, seconds]);

  const handleAddOneMin = useCallback(() => {
    if (typeof stopTimeMs === 'number') {
      close();
      const newStopTime = timeIsUp
        ? Date.now() + ONE_MINUTE_MS
        : stopTimeMs + ONE_MINUTE_MS;
      if (onTimerChange) onTimerChange(null, newStopTime);
    }
  }, [close, onTimerChange, stopTimeMs, timeIsUp]);

  const handleInputChanged = useCallback(
    (timeInSeconds: number) => {
      if (typeof stopTimeMs === 'number') {
        const newStopTime = Date.now() + timeInSeconds * 1000;
        if (onTimerChange) onTimerChange(null, newStopTime);
      }
    },
    [onTimerChange, stopTimeMs]
  );

  const handleInputKeydown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        if (isActive) handleUpdateStopTime();
        else handleStart();
      }
    },
    [handleStart, handleUpdateStopTime, isActive]
  );

  // TODO all popovers are logging a popper.js warning in console when clicked. Probably a bug with chakra but i'd look into it
  // UPDATE: this is caused by using tooltips/popovers inside HStack or VStack. We should use Flex instead.
  // VStack and HStack automatically override margins in its children and this confuses popper.js
  return (
    <Popover isOpen={!readOnly && isOpen}>
      <PopoverTrigger>
        <MotionFlex
          initial={{ maxWidth: '0px' }}
          animate={isActive ? { maxWidth: '140px' } : { maxWidth: '48px' }}
        >
          <MotionFlex
            animate={timeIsUp ? SHAKE_ANIMATION : {}}
            transition={{ duration: 1 }}
          >
            <Tooltip variant="white" label="Timer">
              <Button
                variant="outlineRoundedIcon"
                transition="width 0.1s ease-out"
                position="relative"
                overflow="hidden"
                bg={timeIsUp ? 'red.500' : 'transparent'}
                w={isActive ? '120px' : '48px'}
                onClick={toggle}
              >
                <Icon
                  color={
                    timeIsUp ? '#fff' : isActive ? 'primary.500' : 'gray.600'
                  }
                  zIndex={1}
                  as={MdOutlineTimer}
                  w={5}
                  h={5}
                />
                {isActive && !timeIsUp && (
                  <Box
                    minH="55px"
                    bg="primary.100"
                    transition="min-width 0.5s ease-out"
                    position="absolute"
                    zIndex={0}
                    left={0}
                    top={-2}
                    style={{
                      minWidth: `${
                        100 - (elapsedTime * 100) / targetTime || 100
                      }%`,
                    }}
                  />
                )}
                {isActive && !timeIsUp && (
                  <Text color="primary.500" zIndex={1} mt="2px" ml={1}>
                    {displayMinutes}:{displaySeconds}
                  </Text>
                )}
                {timeIsUp && (
                  <Text color="#fff" ml={1} fontWeight={500}>
                    Time is up
                  </Text>
                )}
              </Button>
            </Tooltip>
          </MotionFlex>
        </MotionFlex>
      </PopoverTrigger>

      <MemoizedBoardTimerPopover
        handleInputKeydown={handleInputKeydown}
        handleInputChanged={handleInputChanged}
        handleAddOneMin={handleAddOneMin}
        handleReset={handleReset}
        handleStart={handleStart}
        setMinutes={setMinutes}
        setSeconds={setSeconds}
        isActive={isActive}
        timeIsUp={timeIsUp}
        minutes={minutes}
        seconds={seconds}
        ref={popoverRef}
      />
    </Popover>
  );
};
