import { FC, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { FaChevronLeft } from 'react-icons/fa';
import { useCreateTeamHandler } from '../../handlers';
import { useCurrentOrg } from '../../queries';
import {
  SpokeFormRules,
  useToast,
  TOAST_ERROR_GENERIC,
  callIfExists,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Button,
  log,
  Input,
  Text,
} from '@spoke/common';

type CreateTeamModalContentProps = {
  afterSubmit: () => void;
  onCancel: () => void;
};

export type CreateTeamFormSchema = {
  name: string;
};

const rules: SpokeFormRules<CreateTeamFormSchema> = {
  name: {
    required: 'Team name is required',
    minLength: {
      value: 3,
      message: 'Team name needs to be at least 3 characters long',
    },
  },
};

export const CreateTeamModalContent: FC<CreateTeamModalContentProps> = ({
  afterSubmit,
  onCancel,
}) => {
  const [toast] = useToast();
  const [handleCreateTeam] = useCreateTeamHandler();
  const [currentOrg] = useCurrentOrg();

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<CreateTeamFormSchema>();

  const onSubmit = useCallback(
    async (form: CreateTeamFormSchema) => {
      const organizationId = currentOrg?.id;
      const { name } = form;

      if (!organizationId || !name) {
        log.error('Missing critical data to create team', {
          organizationId,
          name,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      log.info('Handling CreateTeam submit', { form });

      const success = await handleCreateTeam({
        organizationId,
        teamName: name,
      });

      if (success) callIfExists(afterSubmit);
    },
    [afterSubmit, currentOrg?.id, handleCreateTeam, toast]
  );

  return (
    <ModalContent p={8} pb={4} maxW={600}>
      <ModalCloseButton />
      <ModalBody p={0}>
        <ModalHeader p={0}>
          <HStack>
            <Icon w={6} h={6} as={AiOutlineUsergroupAdd} />
            <Heading mb={1} fontSize={24} color="gray.900">
              Create Team
            </Heading>
          </HStack>
          <Text fontSize={14} fontWeight={400} color="gray.500" mt={1}>
            Create a group of individuals to participate in retrospectives
          </Text>
        </ModalHeader>
        <Flex flexDir="column" as="form" onSubmit={handleSubmit(onSubmit)}>
          <HStack spacing={2} mt={4} mb={6}>
            <FormControl isInvalid={Boolean(errors.name)}>
              <FormLabel fontSize={15} htmlFor="name">
                Team name
              </FormLabel>
              <Input
                id="name"
                placeholder="Enter team name"
                minH={10}
                alignItems="center"
                autoFocus
                {...register('name', rules.name)}
              />
              <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
            </FormControl>
          </HStack>
          <HStack mb={1} justifyContent="flex-end">
            <Button
              size="lg"
              variant="outlineGray"
              type="button"
              w="fit-content"
              disabled={isSubmitting}
              onClick={onCancel}
              leftIcon={
                <Icon as={FaChevronLeft} pb="2px" color="gray.600" mr="-4px" />
              }
            >
              Go back
            </Button>
            <Button
              size="lg"
              w="fit-content"
              isLoading={isSubmitting}
              type="submit"
            >
              Create Team
            </Button>
          </HStack>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};
