import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useActionItemsAnalytics } from '../queries';
import {
  useNow,
  ONE_DAY_MS,
  optimisticUpdateActionItem,
  TOAST_ERROR_GENERIC,
  NO_OP,
  useToast,
  log,
  DeepPartial,
} from '@spoke/common';
import { useUpdateActionItemMutation, ActionItem } from '@spoke/graphql';

export const useDeleteActionItemHandler = () => {
  const [updateActionItem] = useUpdateActionItemMutation();
  const [toast] = useToast();
  const { cache } = useApolloClient();

  const now = useNow({ roundTo: 'ten-minutes' });

  const [__, actionItemAnalyticsQuery] = useActionItemsAnalytics({
    endDate: now,
    timeWindowLength: 90 * ONE_DAY_MS,
  });

  const handleDeleteActionItem = useCallback(
    async (actionItemId: string): Promise<boolean> => {
      log.info('Deleting action item', {
        actionItemId,
      });

      const updateFields: DeepPartial<ActionItem> = { archived: true };

      const expected = optimisticUpdateActionItem({
        actionItemId,
        cache,
        fields: updateFields,
      });

      const { errors } = await updateActionItem({
        variables: {
          actionItemId,
          fields: updateFields,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          updateActionItem: expected,
        },
      });

      if (errors) {
        log.error('updateActionItem (archive) call responded with errors', {
          errors,
          updateFields,
          actionItemId,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      actionItemAnalyticsQuery.refetch().catch(NO_OP);

      return true;
    },
    [cache, toast, updateActionItem, actionItemAnalyticsQuery]
  );

  return [handleDeleteActionItem];
};
