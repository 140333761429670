import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentUser,
  TOAST_ERROR_GENERIC,
  DeepPartial,
  optimisticUpdateTeam,
  log,
} from '@spoke/common';
import {
  useUpdateTeamMutation,
  ArchiveTeamMutation,
  Team,
} from '@spoke/graphql';

export const useUpdateTeamNameHandler = () => {
  const [toast] = useToast();
  const [currentUser] = useCurrentUser();
  const [updateTeam] = useUpdateTeamMutation();
  const { cache } = useApolloClient();

  const userId = currentUser?.id;

  const handleUpdateTeamName = useCallback(
    async ({
      teamId,
      newName,
    }: {
      teamId: string;
      newName: string;
    }): Promise<ArchiveTeamMutation['archiveTeam'] | null> => {
      if (!teamId || !newName || !userId) {
        log.error('Missing critical data to update team name', {
          teamId,
          newName,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const updateFields: DeepPartial<Team> = {
        name: newName,
      };

      const expected = optimisticUpdateTeam({ cache, teamId, updateFields });

      const { errors, data } = await updateTeam({
        variables: { teamId, userId, fields: updateFields },
        optimisticResponse: {
          __typename: 'Mutation',
          updateTeam: expected,
        },
      });

      if (errors) {
        log.warn('Update team name mutation responded with error', {
          error: errors,
        });
        return null;
      }

      toast({
        status: 'success',
        title: 'Success',
        description: `Team name successfully updated`,
      });

      return data?.updateTeam;
    },
    [cache, toast, updateTeam, userId]
  );

  return [handleUpdateTeamName];
};
