import { ChakraProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { BiTrashAlt } from 'react-icons/bi';
import { SpkTime } from '../../SpkTime';
import { VStack, HStack, Icon, Text, Image } from '../../ui';
import { isImage, handleCardImageError } from '../../util';

type CommentProps = ChakraProps & {
  id: string;
  text: string;
  createdAt: number;
  authorName?: string | null;
  canDelete: boolean;
  onDelete: (commentId: string) => boolean | Promise<boolean>;
};
export const Comment: FC<CommentProps> = ({
  id,
  text,
  createdAt,
  authorName,
  canDelete,
  onDelete,
  ...rest
}) => {
  const isOptimisticResponse = id.includes('TEMP');
  return (
    <VStack alignItems="stretch" spacing={1} {...rest}>
      <HStack justifyContent="space-between">
        <HStack>
          <Text
            color={isOptimisticResponse ? 'gray.400' : 'gray.600'}
            fontSize={12}
            fontWeight={500}
          >
            {authorName}
          </Text>
          <Text
            color={isOptimisticResponse ? 'gray.400' : 'gray.500'}
            fontSize={12}
          >
            {SpkTime.timeAgo(createdAt)}
          </Text>
        </HStack>
        {Boolean(!isOptimisticResponse && canDelete) && (
          <Icon
            onClick={() => onDelete(id)}
            as={BiTrashAlt}
            color={isOptimisticResponse ? 'gray.400' : 'gray.500'}
            _hover={{ color: 'gray.800' }}
            transition="color 0.1s ease-out"
            cursor="pointer"
            w={4}
            h={4}
          />
        )}
      </HStack>
      {isImage(text) ? (
        <Image src={text} alt="" onError={handleCardImageError} />
      ) : (
        <Text
          color={isOptimisticResponse ? 'gray.400' : 'gray.600'}
          fontSize={14}
          whiteSpace="pre-wrap"
        >
          {text}
        </Text>
      )}
    </VStack>
  );
};
