import { FC, useState } from 'react';
import { Button, Icon, Spacer } from '@chakra-ui/react';
import { FiCalendar } from 'react-icons/fi';
import { BsChevronDown } from 'react-icons/bs';
import {
  TeamsIcon,
  MultiSelect,
  DatePickerPopover,
  truncateWithCount,
  useDisclosure,
  useCurrentUser,
  DateRangePickerPopover,
} from '@spoke/common';
import { useCurrentTeam, useTeamTree, useTeamType } from '@spoke/user';
import { MinimalTeamFragmentFragment, TeamType } from '@spoke/graphql';
import { set, format } from 'date-fns';
import { DateRange } from 'react-day-picker';

export const AnalyticsRollupFilterBar: FC<{
  initialDateRange?: DateRange;
  onProgramsChange: (selectedPrograms: string[]) => void;
  onTeamsChange: (selectedTeams: string[]) => void;
  onDateRangeChange: (range: DateRange) => void;
}> = ({
  initialDateRange,
  onProgramsChange,
  onTeamsChange,
  onDateRangeChange,
}) => {
  const [{ teamTree }] = useTeamTree();
  const teamType = useTeamType();
  const { isProgram, isOrg, isAdmin } = teamType;
  const [currentTeam] = useCurrentTeam();
  const [currentUser] = useCurrentUser();
  const teamsDropdown = useDisclosure();
  const programsDropdown = useDisclosure();
  const [isProgramssSelected, setIsProgramsSelected] = useState(false);
  const [isTeamsSelected, setIsTeamsSelected] = useState(false);

  const [range, setRange] = useState<DateRange | undefined>();

  const programs = teamTree
    ?.filter((treeNode) => treeNode.team.type === TeamType.Program)
    .map((treeNode) => treeNode.team);

  let teams;
  let isAdminUserForProgram = false;
  if (isProgram) {
    isAdminUserForProgram = currentTeam?.admin?.id === currentUser?.id;
    teams = currentTeam?.children;
  } else {
    teams = teamTree
      ?.filter((treeNode) => treeNode.team.type === TeamType.Team)
      .map((treeNode) => treeNode.team);
  }

  const [selectedChildrenTeams, setSelectedChildrenTeams] = useState<
    MinimalTeamFragmentFragment[]
  >([]);

  const [selectedChildrenPrograms, setSelectedChildrenPrograms] = useState<
    MinimalTeamFragmentFragment[]
  >([]);

  const teamsLabel =
    truncateWithCount(selectedChildrenTeams, 'name', 55) || 'All teams';
  const programsLabel =
    truncateWithCount(selectedChildrenPrograms, 'name', 55) || 'All programs';
  const periodLabel =
    range?.from && range?.to
      ? `${format(range.from, 'MMM dd, yyyy')} - ${format(
          range.to,
          'MMM dd, yyyy'
        )}`
      : 'Period';

  const getTeamOptions = (term: string): MinimalTeamFragmentFragment[] =>
    teams
      ?.filter(Boolean)
      .filter((team) =>
        team.name?.toLowerCase().includes(term.toLowerCase())
      ) ?? [];

  const getProgramOptions = (term: string): MinimalTeamFragmentFragment[] =>
    programs
      ?.filter(Boolean)
      .filter((team) =>
        team.name?.toLowerCase().includes(term.toLowerCase())
      ) ?? [];

  const handleProgramSelect = (programs) => {
    if (programs.length === 0) {
      setIsProgramsSelected(false);
    } else {
      setIsProgramsSelected(true);
    }
    setSelectedChildrenPrograms(programs);
    onProgramsChange(programs.map((team) => team.id));
  };

  const handleTeamSelect = (teams) => {
    if (teams.length === 0) {
      setIsTeamsSelected(false);
    } else {
      setIsTeamsSelected(true);
    }
    setSelectedChildrenTeams(teams);
    onTeamsChange(teams.map((team) => team.id));
  };

  const handleDateRangeChange = (range: DateRange) => {
    setRange(range);

    if (!range?.from || !range?.to) {
      return;
    }
    onDateRangeChange(range);
  };

  return (
    <>
      <>
        <DateRangePickerPopover value={range} onChange={handleDateRangeChange}>
          <Button
            variant="outlineGray"
            leftIcon={<Icon as={FiCalendar} />}
            isDisabled={!isAdmin && !isAdminUserForProgram}
          >
            {periodLabel}
          </Button>
        </DateRangePickerPopover>

        {isOrg || isProgram ? (
          <>
            <MultiSelect
              searchable
              values={selectedChildrenPrograms}
              onChange={handleProgramSelect}
              onClose={programsDropdown.close}
              isOpen={programsDropdown.isOpen}
              getOptions={getProgramOptions}
              idKey="id"
              labelKeyOrFn="name"
            >
              <Button
                justifyContent="start"
                onClick={programsDropdown.toggle}
                variant="outlineGray"
                leftIcon={<Icon as={TeamsIcon} />}
                w="full"
                isDisabled={
                  (!isAdmin && !isAdminUserForProgram) || isTeamsSelected
                }
              >
                {programsLabel}
                <Spacer />
                <Icon as={BsChevronDown} />
              </Button>
            </MultiSelect>
            <MultiSelect
              searchable
              values={selectedChildrenTeams}
              onChange={handleTeamSelect}
              onClose={teamsDropdown.close}
              isOpen={teamsDropdown.isOpen}
              getOptions={getTeamOptions}
              idKey="id"
              labelKeyOrFn="name"
            >
              <Button
                justifyContent="start"
                onClick={teamsDropdown.toggle}
                variant="outlineGray"
                leftIcon={<Icon as={TeamsIcon} />}
                w="full"
                isDisabled={
                  (!isAdmin && !isAdminUserForProgram) || isProgramssSelected
                }
              >
                {teamsLabel}
                <Spacer />
                <Icon as={BsChevronDown} />
              </Button>
            </MultiSelect>
          </>
        ) : null}
      </>
    </>
  );
};
