import { FC } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { BiLockAlt } from 'react-icons/bi';
import { SpkAssetKey } from '../hooks';
import {
  JiraIcon,
  TeamsIcon,
  GithubIcon,
  BitbucketIcon,
  ConfluenceIcon,
  SlackIcon,
  TrelloIcon,
} from '../ui';

export const INSTALL_GITHUB_URL = `${process.env.NEXT_PUBLIC_API_URL}/integrations/github/install`;
export const INSTALL_BITBUCKET_URL = `${process.env.NEXT_PUBLIC_API_URL}/integrations/bitbucket/install`;

export type IntegrationMoreInfoData = {
  title: string;
  image?: SpkAssetKey;
  about: string;
  usefulFor: string[];
  access: { icon: FC; description: string }[];
};

export type IntegrationStaticData = {
  key: IntegrationType;
  icon: FC;
  providerName: string;
  linkedResourceName?: string;
  title: string;
  description: string;
  uninstallText?: string;
  infoModal?: IntegrationMoreInfoData;
};

// Members are lowercase because they're used as query parameters on /integrations?i=<key>
export enum IntegrationType {
  JiraMetrics = 'jirametrics',
  JiraExporting = 'jiraexporting',
  Github = 'github',
  BitbucketMetrics = 'bitbucketmetrics',
  Slack = 'slack',
  Trello = 'trello',
  Confluence = 'confluence',
}

export const INTEGRATION_STATIC_DATA: Record<
  IntegrationType,
  IntegrationStaticData
> = {
  [IntegrationType.JiraMetrics]: {
    key: IntegrationType.JiraMetrics,
    providerName: 'Jira',
    linkedResourceName: 'boards',
    title: 'ScatterSpoke Jira Metrics',
    description:
      'Pull data directly from your Jira projects to track your goals automatically without the need of manual reporting.',
    uninstallText:
      "You are disconnecting <b>ScatterSpoke Jira Metrics</b> from your organization. Are you sure you want to proceed?<br/><br/>Current team goals linked to Jira projects won't be able to auto report anymore.",
    icon: JiraIcon,
    infoModal: {
      title: 'ScatterSpoke Jira Metrics',
      image: SpkAssetKey.ImageIntegrationsJiraMetrics,
      about:
        'Pull data directly from your Jira projects to track your goals automatically without the need of manual reporting.',
      usefulFor: ['Reporting team goals', 'Keeping track of team metrics'],
      access: [
        {
          icon: AiOutlineEye,
          description:
            'This integration gives ScatterSpoke <b>read only</b> access to Jira projects',
        },
        {
          icon: TeamsIcon,
          description:
            'Any teams in ScatterSpoke <b>will be able to use the data</b> for team metrics.',
        },
        {
          icon: BiLockAlt,
          description:
            "To set up this integration you will need to have <b>admin access</b> in your team's or company's Jira account.",
        },
      ],
    },
  },
  [IntegrationType.JiraExporting]: {
    key: IntegrationType.JiraExporting,
    providerName: 'Jira',
    title: 'Jira Issue Exporting',
    description:
      'Send your retrospective cards or action items directly to your backlog in Jira.',
    uninstallText:
      "You are disconnecting <b>Jira Issue Exporting</b> from your ScatterSpoke account. Are you sure you want to proceed?<br/><br/>You won't be able to export data to Jira unless you authorize again.",
    icon: JiraIcon,
    infoModal: {
      title: 'Jira Issue Exporting',
      about:
        'The <b>Jira Issue Exporting</b> exporting integration gives users the ability to execute on the insights generated from your retrospectives. The Jira integration allows you to send your retrospective cards or action items directly to your backlog in Jira so that you can include them in your next sprint or epic.',
      usefulFor: ['Task Management', 'Prioritization', 'Action Item Metrics'],
      access: [
        {
          icon: AiOutlineEye,
          description:
            'This integration gives ScatterSpoke <b>Create Issue</b> access rights to Jira projects',
        },
        {
          icon: TeamsIcon,
          description:
            '<b>Only you</b> will be to export data to Jira. For other users to also be able to export data to Jira, they need to authorize the integration themselves.',
        },
        {
          icon: BiLockAlt,
          description:
            "You <b>don't need admin access</b> in your team's or company's Jira account to set up this integration. But you need to have <b>Create Issue</b> access rights yourself.",
        },
      ],
    },
  },
  [IntegrationType.Github]: {
    key: IntegrationType.Github,
    providerName: 'GitHub',
    linkedResourceName: 'repositories',
    title: 'ScatterSpoke GitHub Metrics',
    description:
      'Pull data directly from your GitHub repositories to track your goals automatically without the need of manual reporting.',
    uninstallText:
      "You are disconnecting <b>ScatterSpoke GitHub Metrics</b> from your organization. Are you sure you want to proceed?<br/><br/>Current team goals linked to GitHub repositories won't be able to auto report anymore.gain.",
    icon: GithubIcon,
    infoModal: {
      title: 'ScatterSpoke GitHub Metrics',
      about:
        'Pull data directly from your GitHub repositories to track your goals automatically without the need of manual reporting.',
      usefulFor: ['Reporting team goals', 'Keeping track of team metrics'],
      access: [
        {
          icon: AiOutlineEye,
          description:
            'This integration gives ScatterSpoke <b>read only</b> access to GitHub repositories',
        },
        {
          icon: TeamsIcon,
          description:
            'Any teams in ScatterSpoke <b>will be able to use the data</b> for team metrics.',
        },
        {
          icon: BiLockAlt,
          description:
            "To set up this integration you will need to have <b>admin access</b> in your team's or company's GitHub organization.",
        },
      ],
    },
  },
  [IntegrationType.BitbucketMetrics]: {
    key: IntegrationType.BitbucketMetrics,
    providerName: 'BitBucket',
    linkedResourceName: 'repositories',
    title: 'ScatterSpoke BitBucket Metrics',
    description:
      'Pull data directly from your BitBucket repositories to track your goals automatically without the need of manual reporting.',
    uninstallText:
      "You are disconnecting <b>ScatterSpoke BitBucket Metrics</b> from your organization. Are you sure you want to proceed?<br/><br/>Current team goals linked to BitBucket repositories won't be able to auto report anymore.gain.",
    icon: BitbucketIcon,
    infoModal: {
      title: 'ScatterSpoke BitBucket Metrics',
      about:
        'Pull data directly from your BitBucket repositories to track your goals automatically without the need of manual reporting.',
      usefulFor: ['Reporting team goals', 'Keeping track of team metrics'],
      access: [
        {
          icon: AiOutlineEye,
          description:
            'This integration gives ScatterSpoke <b>read only</b> access to BitBucket repositories',
        },
        {
          icon: TeamsIcon,
          description:
            'Any teams in ScatterSpoke <b>will be able to use the data</b> for team metrics.',
        },
        {
          icon: BiLockAlt,
          description:
            "To set up this integration you will need to have <b>admin access</b> in your team's or company's BitBucket workspace.",
        },
      ],
    },
  },
  [IntegrationType.Confluence]: {
    key: IntegrationType.Confluence,
    providerName: 'Confluence',
    title: 'Confluence',
    description:
      "Send your retrospective data directly into your team's Confluence page.",
    uninstallText:
      "You are disconnecting <b>Confluence</b> from your organization. Are you sure you want to proceed?<br/><br/>You won't be able to export data to Confluence unless you authorize again.",
    icon: ConfluenceIcon,
    infoModal: {
      title: 'Confluence',
      about:
        'The <b>Confluence</b> integration gives users the ability to execute on the insights generated from your retrospectives. The Jira integration allows you to send your retrospective cards or action items directly to your backlog in Jira so that you can include them in your next sprint or epic.',
      usefulFor: [
        'Centralized retrospective data',
        'Sharing with organization',
      ],
      access: [
        {
          icon: AiOutlineEye,
          description:
            'This integration gives ScatterSpoke <b>Create Page</b> access rights to Confluence wikis',
        },
        {
          icon: TeamsIcon,
          description:
            '<b>Only you</b> will be to export data to Confluence. For other users to also be able to export data to Jira, they need to authorize the integration themselves.',
        },
        {
          icon: BiLockAlt,
          description:
            "You <b>don't need admin access</b> in your team's or company's Confluence workspace to set up this integration. But you need to have <b>Create Page</b> access rights yourself.",
        },
      ],
    },
  },
  [IntegrationType.Slack]: {
    key: IntegrationType.Slack,
    providerName: 'Slack',
    title: 'Slack',
    description:
      'Install a global shortcut into your Slack workspace. Allows you to create retrospectives, action items, and parking lot items and share them with any Slack channel or direct message.',
    uninstallText:
      "You are disconnecting <b>Slack</b> from your organization. Are you sure you want to proceed?<br/><br/>You won't be able to use ScatterSpoke Bot within your Slack workspace.",
    icon: SlackIcon,
    infoModal: {
      title: 'Slack',
      about:
        'The <b>Slack</b> integration allows the user to install a global shortcut into their Slack workspace. You then can create retrospectives, action items, and parking lot items and share them with any Slack channel or direct message.',
      usefulFor: [
        'Creating Retrospectives',
        'Creating Action Items',
        'Sharing links',
      ],
      access: [
        {
          icon: TeamsIcon,
          description:
            'Any user within your Slack workspace <b>will be able to use ScatterSpoke Bot</b> to interact with ScatterSpoke as themselves.',
        },
        {
          icon: BiLockAlt,
          description:
            "To set up this integration you will need to have <b>admin access</b> in your team's or company's Slack workspace.",
        },
      ],
    },
  },
  [IntegrationType.Trello]: {
    key: IntegrationType.Trello,
    providerName: 'Trello',
    title: 'Trello',
    description:
      'Send your retrospective cards or action items directly to your Trello board.',
    uninstallText:
      "You are disconnecting <b>Trello</b> from your organization. Are you sure you want to proceed?<br/><br/>You won't be able to export data to Trello unless you authorize again.",
    icon: TrelloIcon,
    infoModal: {
      title: 'Confluence',
      about:
        'The <b>Trello</b> integration allows you to send your retrospective cards or action items directly to your Trello board so that you can start work as soon as you prioritize it.',
      usefulFor: ['Task Management', 'Prioritization', 'Action Item Metrics'],
      access: [
        {
          icon: AiOutlineEye,
          description:
            'This integration gives ScatterSpoke <b>Create Task</b> access rights to Trello boards',
        },
        {
          icon: TeamsIcon,
          description:
            '<b>Only you</b> will be to export data to Trello. For other users to also be able to export data to Trello, they need to authorize the integration themselves.',
        },
        {
          icon: BiLockAlt,
          description:
            "You <b>don't need admin access</b> in your team's or company's Trello account to set up this integration. But you need to have <b>Create Task</b> access rights yourself.",
        },
      ],
    },
  },
};
