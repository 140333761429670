import { FC, ReactElement, useState } from 'react';
import { useIsMounted } from '../hooks';
import { callIfExists } from '../util';
import {
  ModalContentProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from './Modal';
import { Heading } from './Heading';
import { ModalHeader } from './Modal/ModalHeader';
import { Text } from './Text';
import { Button } from './Button';
import { Tooltip } from './Tooltip';

/**
 * Confirmation modal abstraction for the most common use cases.
 * For more specific use cases, use @components/ui/Modal/Modal.tsx instead.
 */
export type ConfirmationModalCondensedProps = ModalContentProps & {
  title?: string | ReactElement;
  text?: string | ReactElement;
  icon?: ReactElement;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => unknown;
  confirmDisabledReason?: string;
  onCancel?: () => unknown;
  isOpen: boolean;
  close: () => unknown;
  closeOnCancel?: boolean;
  confirmButtonProps?: Omit<
    React.ComponentProps<typeof Button>,
    'children' | 'onClick'
  >;
};
export const ConfirmationModalCondensed: FC<
  ConfirmationModalCondensedProps
> = ({
  closeOnCancel = true,
  close,
  isOpen,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  confirmDisabledReason,
  text,
  title,
  children,
  icon,
  confirmButtonProps = {},
  ...rest
}) => {
  const [isMounted] = useIsMounted();
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    await callIfExists(onConfirm);
    if (isMounted) setIsLoading(false);
  };

  const handleCancel = () => {
    callIfExists(onCancel);
    if (closeOnCancel) close();
  };

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalOverlay />
      <ModalContent maxW={550} top="4.75rem" {...rest}>
        <ModalHeader py={6} pb={2} display="flex" alignItems="center" gap={1}>
          {icon}
          <Heading fontSize={20} color="gray.900">
            {title}
          </Heading>
          <ModalCloseButton top={5} />
        </ModalHeader>
        <ModalBody
          alignItems="center"
          textAlign="left"
          display="flex"
          flexDir="column"
          pb={6}
        >
          {typeof text === 'object' ? (
            text
          ) : (
            <Text
              color="gray.600"
              w="full"
              fontSize={16}
              mb={2}
              whiteSpace="pre-wrap"
              dangerouslySetInnerHTML={{ __html: text || '' }}
              sx={{
                '& b': {
                  color: 'primary.600',
                  fontWeight: 500,
                },
              }}
            />
          )}
          {children}
        </ModalBody>
        <ModalFooter justifyContent="flex-end" gap={3} pt={0} pb={8}>
          <Button
            size="lg"
            fontSize={16}
            variant="outlineGray"
            disabled={isLoading}
            onClick={handleCancel}
          >
            {cancelText || 'Cancel'}
          </Button>
          <Tooltip
            label={confirmDisabledReason}
            placement="top"
            hasArrow
            maxW={200}
            variant="white"
          >
            <Button
              isLoading={isLoading}
              size="lg"
              fontSize={16}
              onClick={handleConfirm}
              isDisabled={Boolean(confirmDisabledReason)}
              {...confirmButtonProps}
            >
              {confirmText || 'Confirm'}
            </Button>
          </Tooltip>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
