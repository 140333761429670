import { useCurrentTeam } from './useCurrentTeam';
import { useCurrentUser, capitalizeFirstLetter } from '@spoke/common';
import { TeamType, MembershipRole } from '@spoke/graphql';

export type UseTeamTypeResult = {
  capitalizedTeamType: string | null;
  lowercaseTeamType: string | null;
  teamType: TeamType | null;
  isOrg: boolean;
  isProgram: boolean;
  isTeam: boolean;
  isAdmin: boolean;
};
export const useTeamType = (): UseTeamTypeResult => {
  const [currentUser] = useCurrentUser();
  const [currentTeam] = useCurrentTeam();

  const teamType = currentTeam?.type ?? null;
  const lowercaseTeamType = teamType?.toLowerCase() ?? null;
  const capitalizedTeamType = lowercaseTeamType
    ? capitalizeFirstLetter(lowercaseTeamType)
    : null;

  const isOrg = teamType === TeamType.Organization;
  const isProgram = teamType === TeamType.Program;
  const isTeam = teamType === TeamType.Team;

  const isAdmin = currentUser?.role === MembershipRole.Admin;

  return {
    capitalizedTeamType,
    lowercaseTeamType,
    teamType,
    isOrg,
    isProgram,
    isTeam,
    isAdmin,
  };
};
