import { useState, useEffect, useRef } from 'react';
import { useCreateOrganizationHandler } from '../queries';
import { useCreateTeamHandler } from './useCreateTeamHandler';
import { TeamType } from '@spoke/graphql';
import { useCurrentUser, log } from '@spoke/common';

/**
 * Auto creates an org and team for the user, whichever are missing
 */
export const useAutoCreateOrgAndTeam = (): { loading: boolean } => {
  const [user] = useCurrentUser();
  const [operationLoading, setOperationLoading] = useState(false);
  const teamCreateTriggered = useRef(false);
  const [handleCreateOrg] = useCreateOrganizationHandler();
  const [handleCreateTeam] = useCreateTeamHandler();

  useEffect(() => {
    (async () => {
      if (!user?.verified) return;
      if (user.organization) return;
      if (operationLoading) return;

      log.info(`Auto creating org for user ${user.id}`);

      setOperationLoading(true);
      await handleCreateOrg({
        orgName: `${user.firstName}'s Organization`,
        skipUserRefetch: true,
      });
    })();
  }, [
    handleCreateOrg,
    operationLoading,
    user?.firstName,
    user?.id,
    user?.organization,
    user?.verified,
  ]);

  useEffect(() => {
    (async () => {
      if (!user?.verified) return;
      if (!user?.organization?.id) return;
      if (user.team?.type !== TeamType.Organization) return;
      if (teamCreateTriggered.current) return;

      log.info(`Auto creating team under org ${user.organization.id}`);

      teamCreateTriggered.current = true;
      setOperationLoading(true);

      await handleCreateTeam({
        organizationId: user.organization?.id,
        teamName: `${user.firstName}'s Team`,
      });

      setOperationLoading(false);
    })();
  }, [
    user?.team,
    user?.organization?.id,
    user?.firstName,
    user?.verified,
    operationLoading,
    handleCreateTeam,
    user,
  ]);

  const loading = Boolean(
    user?.verified && (!user?.organization || !user?.team || operationLoading)
  );

  return { loading };
};
