import {
  ComponentStyleConfig,
  Avatar as ChakraAvatar,
  AvatarProps as ChakraAvatarProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const AvatarComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    container: {
      bg: 'primary.50',
      color: 'primary.500',
    },
  },
};

type Props = ChakraAvatarProps;

const defaultGetInitials = (name: string) =>
  name
    ?.split(' ')
    .map((n) => n[0])
    .slice(0, 2)
    .join('') ?? '';

export const Avatar: FC<Props> = (props) => (
  <ChakraAvatar
    height="10px"
    width="10px"
    padding="18px"
    getInitials={defaultGetInitials}
    /**
     * This is not ideal.. but the only way that worked to change font size
     */
    sx={{
      '.chakra-avatar__initials': {
        fontSize: props.fontSize ?? 16,
        lineHeight: 0,
      },
    }}
    {...props}
  />
);
