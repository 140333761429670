import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentBoardId,
  useCurrentUser,
  TOAST_ERROR_GENERIC,
  getBoardFromCache,
  DeepPartial,
  optimisticUpdateBoard,
  log,
} from '@spoke/common';
import { useMarkDiscussionReadinessMutation, Board } from '@spoke/graphql';

export const useMarkDiscussReadyHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [boardId] = useCurrentBoardId();
  const [currentUser] = useCurrentUser();
  const userId = currentUser?.id;
  const [markDiscussReadiness] = useMarkDiscussionReadinessMutation();

  const handleMarkDiscussReady = useCallback(
    async (newReady: boolean) => {
      if (!boardId || !userId) {
        log.error('Missing critical data to mark discuss ready', {
          newReady,
          boardId,
          userId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const board = getBoardFromCache({ boardId, cache });
      const boardStage = board?.state?.stage;

      if (!boardStage) {
        log.error(
          'Cannot perform discuss ready optimistic response: cached board not found',
          {
            newReady,
            board,
            userId,
          }
        );
      }

      const updateBoardFields: DeepPartial<Board> | null = board
        ? {
            discussReadyUserIds: newReady
              ? [...board.discussReadyUserIds, userId]
              : board.discussReadyUserIds.filter((id) => id !== userId),
          }
        : null;

      const expected =
        updateBoardFields && board
          ? optimisticUpdateBoard({
              boardId: board.id,
              fields: updateBoardFields,
              cache,
            })
          : null;

      await markDiscussReadiness({
        variables: { boardId, isReady: newReady },
        optimisticResponse: {
          __typename: 'Mutation',
          markCardDiscussionReadiness: expected,
        },
      });
    },
    [boardId, cache, toast, markDiscussReadiness, userId]
  );

  return [handleMarkDiscussReady];
};
