import type { ParsedUrlQuery } from 'querystring';
import { createContext, FC, useContext } from 'react';

type SpkPathQuery = ParsedUrlQuery;

export type SpkPath =
  | string
  | { pathname?: string; query?: SpkPathQuery | string }; // Query as string is expected to be in format key1=value1&key2=value2

type SpkRouterEvent = 'routeChangeStart';

export type SpkRouter = {
  push: (path: SpkPath) => void;
  replace: (path: SpkPath) => void;
  back: () => void;
  isReady: boolean;
  pathname: string;
  route: string;
  asPath: string;
  query: SpkPathQuery;
  events: {
    on: (event: SpkRouterEvent, callback: () => void) => void;
    off: (event: SpkRouterEvent, callback: () => void) => void;
  };
};

const NO_OP_ROUTER: SpkRouter = {
  push: () => {},
  replace: () => {},
  back: () => {},
  isReady: false,
  pathname: '/',
  route: '/',
  asPath: '/',
  query: {},
  events: {
    on: () => {},
    off: () => {},
  },
};

const RouterContext = createContext<SpkRouter>(NO_OP_ROUTER);

export const useRouter = () => useContext(RouterContext);

export const RouterProvider: FC<{ router: SpkRouter }> = ({
  children,
  router,
}) => (
  <RouterContext.Provider value={router}>{children}</RouterContext.Provider>
);
