import { ImportDataFieldMapping } from '../components';
import { ImprovementGoalDataImportInput } from '@spoke/graphql';

export const csvMapToImportGoalDataInput = (
  data: Record<string, string>[],
  map: ImportDataFieldMapping
): ImprovementGoalDataImportInput[] => {
  const inputs: ImprovementGoalDataImportInput[] = [];

  for (const row of data) {
    inputs.push({
      date: new Date(row[map.date as string]).getTime(),
      value: Number(row[map.value as string]),
    });
  }

  return inputs;
};
