import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  optimisticUpdateImprovementGoal,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useDeleteImprovementGoalMutation } from '@spoke/graphql';

export const useDeleteImprovementGoalHandler = () => {
  const [deleteImprovementGoal] = useDeleteImprovementGoalMutation();
  const { cache } = useApolloClient();
  const [toast] = useToast();

  const handleDeleteImprovementGoal = useCallback(
    async (data: { improvementGoalId: string }): Promise<boolean> => {
      const { improvementGoalId } = data;

      if (!improvementGoalId) {
        log.error('Missing critical data to delete improvement goal', {
          improvementGoalId,
        });
        return false;
      }

      log.info('Deleting improvement goal', {
        data,
      });

      const expected = optimisticUpdateImprovementGoal({
        cache,
        fields: { archived: true },
        improvementGoalId,
      });

      const { errors } = await deleteImprovementGoal({
        variables: { id: improvementGoalId },
        optimisticResponse: !expected
          ? undefined
          : {
              __typename: 'Mutation',
              removeImprovementGoal: expected,
            },
      });

      if (errors) {
        log.error('deleteImprovementGoal call responded with errors', {
          errors,
          data,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [cache, deleteImprovementGoal, toast]
  );

  return [handleDeleteImprovementGoal];
};
