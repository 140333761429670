import {
  ComponentStyleConfig,
  Fade as ChakraFade,
  FadeProps as ChakraFadeProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const FadeComponentStyle: ComponentStyleConfig = {};

type Props = ChakraFadeProps;

export const Fade: FC<Props> = (props) => <ChakraFade {...props} />;
