import { ApolloCache } from '@apollo/client';
import { log } from '../../../../SpkLog';
import { getImprovementGoalFromCache } from '../../getters';
import {
  ImprovementGoal,
  ImprovementGoalFragmentFragment,
} from '@spoke/graphql';

type OptimisticUpdateImprovementGoalArgs = {
  improvementGoalId: string;
  fields: Partial<ImprovementGoal>;
  cache: ApolloCache<object>;
};
export const optimisticUpdateImprovementGoal = ({
  cache,
  fields,
  improvementGoalId,
}: OptimisticUpdateImprovementGoalArgs):
  | ImprovementGoalFragmentFragment
  | undefined => {
  const goalFromCache = getImprovementGoalFromCache({
    cache,
    improvementGoalId,
  });

  if (!goalFromCache) {
    log.error(
      `Cannot perform updateImprovementGoal optimistic response: goal not found in cache`,
      { improvementGoalId }
    );
    return;
  }

  return {
    __typename: 'ImprovementGoal',
    ...goalFromCache,
    ...fields,
  };
};
