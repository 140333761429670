import { useEffect, useState } from 'react';

export const useLagState = <T,>(value: T, lag: number = 200) => {
  const [lagValue, setLagValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLagValue(value);
    }, lag);

    return () => clearTimeout(timeout);
  }, [value, lag]);

  return [lagValue] as const;
};
