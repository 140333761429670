import { FC } from 'react';
import { VStack, Heading, Button, Image, Text, useAssets } from '@spoke/common';

type TeamSwitcherEmptyStateProps = { onCreateNewTeam: () => unknown };
export const TeamSwitcherEmptyState: FC<TeamSwitcherEmptyStateProps> = ({
  onCreateNewTeam,
}) => {
  const { illustrationHello } = useAssets();

  return (
    <VStack px="4" spacing="4" pb={8} pt={7}>
      <Image src={illustrationHello} alt="No teams created" />
      <Heading size="md" fontWeight="500" color="gray.700">
        No teams created yet
      </Heading>
      <Text fontSize="xs" color="gray.500" textAlign="center">
        Creating a team allows you to keep retrospective data private to team
        members
      </Text>
      <Button onClick={() => onCreateNewTeam()} w="full">
        Create new team
      </Button>
    </VStack>
  );
};
