import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentBoardId,
  TOAST_ERROR_GENERIC,
  getBoardFromCache,
  DeepPartial,
  optimisticUpdateBoard,
  log,
} from '@spoke/common';
import {
  useUpdateBoardMutation,
  useBroadcastBoardMessageMutation,
  Board,
} from '@spoke/graphql';

export const useGrantRevokeFacilitatorHandler = () => {
  const [toast] = useToast();
  const [boardId] = useCurrentBoardId();
  const [updateBoard] = useUpdateBoardMutation();
  const { cache } = useApolloClient();
  const [broadcastBoardMessage] = useBroadcastBoardMessageMutation();

  const handleGrantRevokeFacilitator = useCallback(
    async (userId: string, granting: boolean) => {
      if (!boardId) {
        log.error(
          `Cannot ${
            granting ? 'grant' : 'revoke'
          } facilitator without loaded boardId`,
          {
            boardId,
          }
        );
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const board = getBoardFromCache({ boardId, cache });

      if (!board) {
        log.error(
          `Cannot ${
            granting ? 'grant' : 'revoke'
          } facilitator: cached board not found`,
          {
            userId,
            boardId,
            board,
          }
        );
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      log.info(
        `${
          granting ? 'Granting' : 'Revoking'
        } user ${userId} facilitator rights`,
        {
          userId,
          boardId: board.id,
        }
      );

      await broadcastBoardMessage({
        variables: {
          boardMessageBroadcastInput: {
            boardId: board.id,
            title: granting ? 'Facilitator grant' : 'Facilitator revoke',
            newFacilitatorUserId: userId,
            message: granting
              ? 'You have been made a facilitator for this retrospective.'
              : 'Your facilitator rights for this retrospective have been removed.',
          },
        },
      });

      const updateBoardFields: DeepPartial<Board> = granting
        ? {
            facilitators: [...(board.facilitators || []), userId],
          }
        : {
            facilitators: board.facilitators?.filter(
              (facilitatorUserId) => facilitatorUserId !== userId
            ),
          };

      const expected = optimisticUpdateBoard({
        boardId,
        cache,
        fields: updateBoardFields,
      });

      const { errors } = await updateBoard({
        variables: { boardId, fields: updateBoardFields },
        optimisticResponse: { __typename: 'Mutation', updateBoard: expected },
      });

      if (errors) {
        log.error(
          `${
            granting ? 'Granting' : 'Revoking'
          } facilitator responded with errors`,
          { errors }
        );
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [boardId, broadcastBoardMessage, cache, updateBoard, toast]
  );

  return [handleGrantRevokeFacilitator];
};
