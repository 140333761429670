import { FC } from 'react';
import { isNullish } from '@spoke/common';
import { Flex } from './Flex';
import { Skeleton } from './Skeleton';
import { PercentChange } from './PercentChange';
import { Text } from './Text';

type Props = {
  label: string;
  value: number | null;
  prevValue: number | null;
  orgAvg: number | null;
  suffix?: string;
  loading?: boolean;
  ideal?: 'up' | 'down';
};
export const TrendCardCondensed: FC<Props> = ({
  label,
  orgAvg,
  prevValue,
  value,
  suffix,
  loading,
  ideal = 'up',
}) => (
  <Flex alignItems="flex-start" flexDir="column" flex={1}>
    <Text color="gray.500" fontSize={14} mb="1px">
      {label}
    </Text>
    <Flex fontSize={14}>
      <Skeleton
        isLoaded={!loading}
        mr={1}
        h={loading ? 5 : undefined}
        w={loading ? 22 : undefined}
      >
        <Text mr={1}>
          {isNullish(value) ? 'N/A' : `${value?.toFixed()}${suffix}`}
        </Text>
      </Skeleton>
      <PercentChange
        prevValue={prevValue || value}
        value={value}
        loading={loading}
        ideal={ideal}
      />
    </Flex>
    <Skeleton
      isLoaded={!loading}
      mt={loading ? 1 : 0}
      mb={loading ? '3px' : 0}
      h={loading ? 3 : undefined}
      minW={loading ? 120 : undefined}
    >
      {orgAvg !== undefined && (
        <Text fontSize={12} color="gray.600">
          Organization avg.{' '}
          <Text as="span" color="gray.700" fontWeight={500}>
            {isNullish(orgAvg) ? 'N/A' : `${orgAvg?.toFixed()}${suffix}`}
          </Text>
        </Text>
      )}
    </Skeleton>
  </Flex>
);
