import { FC } from 'react';
import { ConfirmationModal, Text, useAssets } from '@spoke/common';

type ReopenRetroConfirmationModalProps = {
  onClose: () => unknown;
  onConfirm: () => unknown;
  isOpen: boolean;
};
export const ReopenRetroConfirmationModal: FC<
  ReopenRetroConfirmationModalProps
> = ({ isOpen, onClose, onConfirm }) => {
  const { illustrationCanvas } = useAssets();

  return (
    <ConfirmationModal
      close={onClose}
      isOpen={isOpen}
      image={illustrationCanvas}
      imageSize={250}
      title="Are you sure you want to reopen this retrospective?"
      text={
        <Text color="gray.600" fontSize={16} mb={2}>
          This will reopen this retrospective in <b>Discuss</b> stage and allow
          editing the retrospective contents again.
        </Text>
      }
      confirmText="Yes, reopen retrospective"
      cancelText="No, go back"
      onConfirm={onConfirm}
    />
  );
};
