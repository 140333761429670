import { FC, useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { BiUser } from 'react-icons/bi';
import { BsChevronDown } from 'react-icons/bs';
import { MdOutlineBookmarkAdd, MdOutlinedFlag } from 'react-icons/md';
import { useCreateActionItemHandler } from '../handlers';
import { JiraOAuthProjectSelector } from './JiraOAuthProjectSelector';
import { useFetchTeamMembers } from '@spoke/user';
import { useImprovementGoals } from '@spoke/improvement-goals';
import { ActionItem, ImprovementGoal, User } from '@spoke/graphql';
import {
  SpokeFormRules,
  uniqueBy,
  TOAST_ERROR_GENERIC,
  truncateWithCount,
  SpkTime,
  TextArea,
  DatePickerPopover,
  MultiSelect,
  ImprovementGoalOption,
  JiraIcon,
  ModalProps,
  useToast,
  useDisclosure,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  ModalBody,
  Flex,
  FormControl,
  VStack,
  FormLabel,
  Input,
  FormErrorMessage,
  Spacer,
  Square,
  Modal,
  ModalOverlay,
  log,
  Button,
  Text,
  useCurrentBoardId,
  useCurrentUser,
  JiraProject,
} from '@spoke/common';

export type CreateActionItemFormSchema = {
  title: string;
  description: string;
};

const rules: SpokeFormRules<CreateActionItemFormSchema> = {
  title: { required: 'Title is required' },
  description: {},
};

type CreateActionItemModalProps = Omit<ModalProps, 'children'> & {
  initialValues?: ActionItem | null;
  originalCardId?: string;
  initiallySelectedGoalIds?: string[];
  onCreated?: (actionItem: Pick<ActionItem, 'title' | 'id'>) => void;
};

export const CreateActionItemModalContent: FC<CreateActionItemModalProps> = (
  props
) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<CreateActionItemFormSchema>({
    defaultValues: {
      title: props.initialValues?.title || '',
      description: props.initialValues?.description || '',
    },
  });

  const { originalCardId, onClose, initiallySelectedGoalIds } = props;

  const [currentUser] = useCurrentUser();
  const [boardId] = useCurrentBoardId();
  const [{ activeGoals: teamGoals }] = useImprovementGoals();
  const [fetchTeamMembers] = useFetchTeamMembers();

  // Ideally these states would live in the form schema itself, but typescript gets mad about something
  // https://github.com/react-hook-form/react-hook-form/issues/6679
  const [selectedAssignees, setSelectedAssignees] = useState<User[]>([]);
  const [selectedGoals, setSelectedGoals] = useState<ImprovementGoal[]>([]);
  const [dueDate, setDueDate] = useState<Date | null>(null);

  const [selectedJiraProject, setSelectedJiraProject] =
    useState<JiraProject | null>(null);

  const [handleCreateActionItem] = useCreateActionItemHandler();
  const [toast] = useToast();

  const assigneesDropdown = useDisclosure();
  const goalsDropdown = useDisclosure();

  useEffect(() => {
    if (!teamGoals?.length) return;
    if (!initiallySelectedGoalIds?.length) return;
    if (selectedGoals.length) return;

    const goalsToSelect = teamGoals.filter((goal) =>
      initiallySelectedGoalIds.includes(goal.id)
    );

    if (!goalsToSelect.length) return;

    setSelectedGoals(
      (prev) =>
        uniqueBy(
          [...(prev ? prev : []), ...goalsToSelect],
          'id'
        ) as ImprovementGoal[]
    );
  }, [teamGoals, initiallySelectedGoalIds, selectedGoals]);

  const onSubmit = useCallback(
    async (form: CreateActionItemFormSchema) => {
      const teamId = currentUser?.team?.id;

      if (!teamId) {
        log.error('Cannot create action item without current team id', {
          teamId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      log.info('Handling CreateActionItem submit', { form });

      const createdActionItem = await handleCreateActionItem({
        title: form.title,
        description: form.description,
        dueDate: dueDate?.getTime(),
        boardId,
        assigneeIds: selectedAssignees.map((u) => u.id),
        improvementGoalIds: selectedGoals.map((g) => g.id),
        teamId,
        jiraProjectKey: selectedJiraProject?.key ?? undefined,
        originalCardId,
      });

      const success = Boolean(createdActionItem);

      if (success) props.onClose();
      if (success) {
        props.onCreated?.({
          title: form.title,
          id: createdActionItem?.id ?? '',
        });
      }
    },
    [
      boardId,
      currentUser?.team?.id,
      dueDate,
      handleCreateActionItem,
      props,
      selectedAssignees,
      selectedGoals,
      toast,
      selectedJiraProject,
      originalCardId,
    ]
  );

  const getAssigneeOptionsByTerm = async (term: string): Promise<User[]> => {
    const teamId = currentUser?.team?.id;

    if (!teamId) {
      log.error('Cannot fetch assignee options without loaded user teamId', {
        currentUser,
      });
      return [];
    }

    return fetchTeamMembers({
      term,
      teamId,
    }) as Promise<User[]>;
  };

  const improvementGoalsLabel =
    truncateWithCount(selectedGoals, (goal) => goal.type.name, 20) ||
    'Select a goal';
  const assigneesLabel =
    truncateWithCount(selectedAssignees, 'name', 20) || 'Unassigned';
  const dueDateLabel = dueDate
    ? SpkTime.format(dueDate, 'MMM dd, yyyy')
    : 'Select due date';

  return (
    <ModalContent p={8} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Icon as={MdOutlineBookmarkAdd} w={6} h={6} />
          <Heading mb={1} fontSize={24} color="gray.900">
            Create Action Item
          </Heading>
        </HStack>
      </ModalHeader>
      <ModalBody p={0}>
        <Flex
          gap={4}
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl>
            <VStack spacing={4}>
              <FormControl isInvalid={Boolean(errors.title)}>
                <FormLabel fontSize={15} htmlFor="title">
                  Title
                </FormLabel>
                <Input
                  id="title"
                  placeholder="What needs to be done?"
                  minH={10}
                  alignItems="center"
                  autoFocus
                  {...register('title', rules.title)}
                />
                <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
              </FormControl>
            </VStack>
          </FormControl>
          <FormControl>
            <VStack spacing={4}>
              <FormControl isInvalid={Boolean(errors.description)}>
                <FormLabel fontSize={15} htmlFor="description">
                  Description
                </FormLabel>
                <TextArea
                  id="description"
                  placeholder="Write a description for this action item"
                  h={120}
                  resize="none"
                  focusBorderColor="primary.500"
                  p={2}
                  {...register('description', rules.description)}
                />
                <FormErrorMessage>
                  {errors?.description?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </FormControl>
          <HStack w="full" justifyContent="stretch">
            <FormControl flex="1">
              <FormLabel fontSize={15} htmlFor="dueDate">
                Due date
              </FormLabel>
              <DatePickerPopover
                value={dueDate}
                onChange={(newDate) => {
                  setDueDate(newDate as Date);
                }}
              >
                <Button
                  id="dueDate"
                  variant="outlineGray"
                  leftIcon={<Icon as={AiTwotoneCalendar} />}
                  justifyContent="start"
                  w="full"
                >
                  {dueDateLabel}
                  <Spacer />
                  <Icon as={BsChevronDown} />
                </Button>
              </DatePickerPopover>
            </FormControl>
            <FormControl flex="1">
              <FormLabel fontSize={15}>Assignees</FormLabel>
              <MultiSelect
                searchable
                values={selectedAssignees}
                onChange={setSelectedAssignees}
                onClose={assigneesDropdown.close}
                isOpen={assigneesDropdown.isOpen}
                getOptions={(term) => getAssigneeOptionsByTerm(term)}
                idKey="id"
                labelKeyOrFn="name"
              >
                <Button
                  justifyContent="start"
                  onClick={assigneesDropdown.toggle}
                  variant="outlineGray"
                  leftIcon={<Icon as={BiUser} />}
                  w="full"
                >
                  {assigneesLabel}
                  <Spacer />
                  <Icon as={BsChevronDown} />
                </Button>
              </MultiSelect>
            </FormControl>
            <FormControl flex="1">
              <FormLabel fontSize={15}>Improvement Goals</FormLabel>
              <MultiSelect
                values={selectedGoals}
                onChange={setSelectedGoals}
                onClose={goalsDropdown.close}
                isOpen={goalsDropdown.isOpen}
                getOptions={() => (teamGoals as ImprovementGoal[]) || []}
                renderOption={ImprovementGoalOption}
                idKey="id"
                labelKeyOrFn={(goal) => goal.type?.name ?? ''}
              >
                <Button
                  justifyContent="start"
                  onClick={goalsDropdown.toggle}
                  variant="outlineGray"
                  leftIcon={<Icon as={MdOutlinedFlag} />}
                  w="full"
                >
                  {improvementGoalsLabel}
                  <Spacer />
                  <Icon as={BsChevronDown} />
                </Button>
              </MultiSelect>
            </FormControl>
          </HStack>
          <FormControl flex="1">
            <FormLabel fontSize={15}>Export to</FormLabel>
            <Flex layerStyle="outlineGray" alignItems="center">
              <Square layerStyle="outlineGray" p={2} mr={3}>
                <Icon w={6} h={6} as={JiraIcon} />
              </Square>
              <Flex flexDir="column" pt="6px">
                <Text color="gray.700" lineHeight={1} fontWeight={500}>
                  Jira
                </Text>
                <Text color="gray.500">Creates an issue on a Jira project</Text>
              </Flex>
              <Spacer />
              <JiraOAuthProjectSelector
                onSelectProject={setSelectedJiraProject}
                selectedProject={selectedJiraProject}
              />
            </Flex>
          </FormControl>
          <HStack ml="auto">
            <Button
              size="lg"
              isDisabled={isSubmitting}
              variant="outlineGray"
              type="button"
              w="fit-content"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="lg"
              w="fit-content"
              isLoading={isSubmitting}
              type="submit"
            >
              Save Action Item
            </Button>
          </HStack>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

export const CreateActionItemModal: FC<CreateActionItemModalProps> = (
  props
) => (
  <Modal {...props}>
    <ModalOverlay />
    <CreateActionItemModalContent {...props} />
  </Modal>
);
