import { useCallback } from 'react';
import { useBoardAnalytics } from '../../queries';
import {
  NO_OP,
  ONE_DAY_MS,
  removeBoardFromPaginatedTeamBoards,
  TOAST_ERROR_GENERIC,
  useNow,
  log,
  useToast,
} from '@spoke/common';
import {
  PaginatedTeamBoardsQuery,
  useUpdateBoardMutation,
} from '@spoke/graphql';

export const useArchiveBoardHandler = () => {
  const [archiveBoard] = useUpdateBoardMutation();
  const [toast] = useToast();

  const now = useNow({ roundTo: 'ten-minutes' });

  const [_, boardAnalyticsQuery] = useBoardAnalytics({
    endDate: now,
    timeWindowLength: 90 * ONE_DAY_MS,
    recursive: false,
  });

  const handleBoardArchive = useCallback(
    async (boardId: string) => {
      log.info(`Archiving board`, {
        boardId,
      });

      const { errors } = await archiveBoard({
        variables: { boardId, fields: { archived: true } },
        updateQueries: {
          PaginatedTeamBoards: (prev) =>
            removeBoardFromPaginatedTeamBoards(
              prev as PaginatedTeamBoardsQuery,
              boardId
            ),
        },
      });

      if (errors) {
        log.error('Board archive responded with errors', { errors });
        toast(TOAST_ERROR_GENERIC);
        return;
      } else {
        boardAnalyticsQuery.refetch().catch(NO_OP);
        toast({
          status: 'success',
          title: 'Success',
          description: 'Retrospective successfully archived',
        });
      }
    },
    [toast, archiveBoard, boardAnalyticsQuery]
  );

  return [handleBoardArchive];
};
