import { AlertProps } from '@chakra-ui/react';
import { FC } from 'react';
import { AiFillInfoCircle } from 'react-icons/ai';
import { IoReloadOutline } from 'react-icons/io5';
import {
  Alert,
  Icon,
  Spacer,
  Flex,
  Spinner,
  Button,
  Box,
  Text,
} from '@spoke/common';

type Props = AlertProps & {
  metricsName: string;
  onRefreshClick: () => void;
  isRefreshing: boolean;
};
export const ProcessingMetricsAlert: FC<Props> = ({
  metricsName,
  onRefreshClick,
  isRefreshing,
  ...rest
}) => (
  <Alert bg="blue.50" color="blue.500" status="info" mb={2} as="span" {...rest}>
    <Icon
      as={AiFillInfoCircle}
      mr={2}
      color="blue.400"
      w={5}
      h={5}
      borderRadius="sm"
    />
    <Box as="span">
      Almost there! We&apos;re processing your{' '}
      <Text as="span" d="inline" fontWeight={500}>
        {metricsName}
      </Text>{' '}
      data and will have it ready soon. Check back in a few minutes.{' '}
    </Box>
    <Spacer />
    <Flex minW="60px" justifyContent="flex-end">
      {isRefreshing ? (
        <Spinner
          mr={2}
          color="blue.500"
          thickness="2px"
          size="xs"
          speed="0.5s"
        />
      ) : (
        <Button
          leftIcon={<Icon as={IoReloadOutline} mr="-4px" />}
          colorScheme="blue"
          disabled={isRefreshing}
          color="blue.500"
          variant="link"
          textDecor="underline"
          onClick={onRefreshClick}
          fontWeight={400}
          h="fit-content"
          fontSize={14}
        >
          Refresh
        </Button>
      )}
    </Flex>
  </Alert>
);
