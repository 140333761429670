import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { DeepPartial } from 'react-hook-form';
import {
  useToast,
  getListFromCache,
  TOAST_ERROR_GENERIC,
  optimisticUpdateList,
  getUpdateListUpdateFunctionForBoardById,
  log,
} from '@spoke/common';
import { useUpdateListMutation, List } from '@spoke/graphql';

type UseUpdateListColorHandlerArgs = {
  listId: string;
};
export const useUpdateListColorHandler = ({
  listId,
}: UseUpdateListColorHandlerArgs) => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [updateList] = useUpdateListMutation();

  const updateListColor = useCallback(
    async (_newColor: string) => {
      if (!_newColor || typeof _newColor !== 'string') {
        log.warn('No-op: updating list color with invalid string', {
          newColor: _newColor,
        });
        return;
      }

      const list = getListFromCache({ listId, cache });

      if (!list) {
        log.error('Failed to update list: cached list not found', {
          newColor: _newColor,
          listId,
          list,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const newColor = _newColor.trim();

      const updateListFields: DeepPartial<List> = { cardColor: newColor };

      const expected = optimisticUpdateList({
        list,
        updateFields: updateListFields,
      });

      const { data, errors } = await updateList({
        variables: { listId: list.id, fields: updateListFields },
        optimisticResponse: expected,
        updateQueries: {
          BoardById: getUpdateListUpdateFunctionForBoardById(),
        },
      });

      if (errors) {
        log.error(
          'Update list mutation (update list color) responded with errors',
          { errors, data, newColor, list }
        );
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [cache, listId, toast, updateList]
  );

  return [updateListColor];
};
