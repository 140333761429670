const isScrollable = (element: HTMLElement): boolean => {
  const isOverflowing = element.scrollHeight > element.clientHeight;

  const overflowYStyle = window.getComputedStyle(element).overflowY;
  const scrollingEnabled =
    overflowYStyle === 'auto' || overflowYStyle === 'scroll';

  return isOverflowing && scrollingEnabled;
};

/**
 * Searches DOM for first scrollable parent starting from passed element
 * Source: https://htmldom.dev/get-the-first-scrollable-parent-of-an-element/
 */
export const getFirstScrollableParent = (element: HTMLElement): HTMLElement =>
  !element || element === document.body
    ? document.body
    : isScrollable(element)
    ? element
    : getFirstScrollableParent(element.parentNode as HTMLElement);
