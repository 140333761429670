import {
  HStack as ChakraHStack,
  StackProps as ChakraHStackProps,
} from '@chakra-ui/react';
import { forwardRef } from 'react';

export type HStackProps = ChakraHStackProps;

export const HStack = forwardRef<HTMLDivElement, HStackProps>((props, ref) => (
  <ChakraHStack ref={ref} {...props} />
));
