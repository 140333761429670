import { AnimatePresence } from 'framer-motion';
import { FC, memo } from 'react';
import { MemoizedBoardListColorPill } from './BoardListColorPill';
import { MemoizedBoardListMenu } from './BoardListMenu';
import {
  UpDown,
  Flex,
  HStack,
  NO_OP,
  truncateText,
  Badge,
  MotionFlex,
  Input,
  Text,
} from '@spoke/common';

type BoardListHeaderProps = {
  cardColor?: string | null;
  cardCount?: number | null;
  isEditingTitle: boolean;
  isFacilitator: boolean;
  editedTitle: string;
  setEditedTitle: (title: string) => void;
  onEditTitleCommit: () => void;
  onEditTitleCancel: () => void;
  onEditTitleStart: () => void;
  handleMoveList: (upOrDown: UpDown) => void;
  handleDeleteList: () => void;
  handleUpdateListColor: (newColor: string) => void;
};
export const BoardListHeader: FC<BoardListHeaderProps> = ({
  cardColor,
  cardCount,
  editedTitle,
  handleDeleteList,
  handleMoveList,
  handleUpdateListColor,
  isEditingTitle,
  onEditTitleCancel,
  isFacilitator,
  onEditTitleCommit,
  onEditTitleStart,
  setEditedTitle,
}) => (
  <Flex flexDir="column" mb={3}>
    <HStack justifyContent="space-between" p={2} pb={0}>
      <HStack flex={1}>
        <MemoizedBoardListColorPill
          color={cardColor || ''}
          onChangeColor={handleUpdateListColor}
          isFacilitator={isFacilitator}
        />
        {!isEditingTitle && (
          <Text
            onClick={isFacilitator ? onEditTitleStart : NO_OP}
            fontFamily="museo-sans-rd-700"
            fontSize={14}
          >
            {/*  editedTitle instead of title to avoid UI flicker due to minor optimistic response delay */}
            {truncateText(editedTitle, 70)}
          </Text>
        )}
        {isEditingTitle && (
          <Input
            value={editedTitle}
            fontFamily="museo-sans-rd-700"
            fontSize={14}
            variant="unstyled"
            onChange={(e) => setEditedTitle(e.target.value)}
            autoFocus
            flex={1}
            onBlur={onEditTitleCancel}
            onKeyDown={({ key }) => {
              if (key === 'Enter') onEditTitleCommit();
              else if (key === 'Escape') onEditTitleCancel();
            }}
          />
        )}
        {!isEditingTitle && (
          <Badge colorScheme="blackAlpha" color="gray.500">
            {cardCount}
          </Badge>
        )}
      </HStack>
      {isFacilitator && (
        <MemoizedBoardListMenu
          onEditTitleStart={onEditTitleStart}
          onMoveList={handleMoveList}
          onDeleteList={handleDeleteList}
        />
      )}
    </HStack>
    <AnimatePresence>
      {isEditingTitle && (
        <MotionFlex
          initial={{ maxHeight: 0, opacity: 0, marginTop: 0 }}
          animate={{ maxHeight: 20, opacity: 1, marginTop: 3 }}
          exit={{ maxHeight: 0, opacity: 0, marginTop: 0 }}
          transition={{ duration: 0.1 }}
        >
          <Text mb={1} mx="auto" fontSize={12} color="gray.500">
            <Text as="span" fontWeight={500} color="gray.600">
              Enter
            </Text>{' '}
            to commit changes
          </Text>
        </MotionFlex>
      )}
    </AnimatePresence>
  </Flex>
);

export const MemoizedBoardListHeader = memo(BoardListHeader);
