import { deepClone } from '../../../etc';
import { log } from '../../../../SpkLog';
import { generateGroupTitle } from '../../../spoke';
import { generateTempId } from '../../generateTempId';
import { Board, Card, OriginalListBadge } from '@spoke/graphql';

type TurnCardsIntoGroupArgs = {
  dragCard: Card;
  dropCard: Card;
};
export const turnCardsIntoGroup = ({
  dragCard: _dragCard,
  dropCard: _dropCard,
}: TurnCardsIntoGroupArgs): Card => {
  const groupCard = deepClone(_dropCard);
  const dragCard = deepClone(_dragCard);
  const dropCard = deepClone(_dropCard);

  const originalListBadge: OriginalListBadge = {
    __typename: 'OriginalListBadge',
    color: dropCard.originalListBadge?.color,
    originalListId: dropCard.originalListBadge?.originalListId,
    text: dropCard.originalListBadge?.text,
  };

  groupCard.id = generateTempId();
  groupCard.children = [];
  groupCard.votes = dropCard.votes?.concat(...(dragCard.votes ?? []));
  groupCard.text =
    generateGroupTitle(dropCard.text, dragCard.text) ?? dropCard.text;
  groupCard.originalListBadge = originalListBadge;

  dropCard.index = 0;
  dropCard.parentId = groupCard.id;
  dropCard.listId = dragCard.listId;

  dragCard.index = 1;
  dragCard.parentId = groupCard.id;

  groupCard.children.push(dropCard);
  groupCard.children.push(dragCard);

  return groupCard;
};

type OptimisticCreateGroupCardArgs = {
  board: Board;
  groupCard: Card;
  dragCard: Card;
  dropCard: Card;
};

const optimisticCreateGroupCardComingFromExistingGroup = ({
  board,
  dragCard,
  dropCard,
  groupCard,
}: OptimisticCreateGroupCardArgs): Board => {
  const optimisticBoard = deepClone(board);

  const dropCardListIdx = board.lists?.findIndex(
    (list) => list?.id === dropCard.listId
  ) as number;

  const dropCardIdx = board?.lists?.[dropCardListIdx]?.cards?.findIndex(
    (card) => card?.id === dropCard.id
  ) as number;

  const dragGroupCardListIdx = board.lists?.findIndex((list) =>
    list?.cards?.some((card) => dragCard.parentId === card?.id)
  ) as number;

  const dragGroupCardIdx = board?.lists?.[
    dragGroupCardListIdx
  ]?.cards?.findIndex((card) => card?.id === dragCard.parentId) as number;

  const dragCardIdx = optimisticBoard?.lists?.[dragGroupCardListIdx]?.cards?.[
    dragGroupCardIdx
  ]?.children?.findIndex((card) => card?.id === dragCard.id) as number;

  if (
    dropCardIdx < 0 ||
    dropCardListIdx < 0 ||
    dragCardIdx < 0 ||
    dragGroupCardIdx < 0 ||
    dragGroupCardListIdx < 0
  ) {
    log.error(
      'An error has occurred in optimisticCreateGroupCard (coming from existing group)',
      {
        dropCardIdx,
        dropCardListIdx,
        dragCardIdx,
        dragGroupCardIdx,
        dragGroupCardListIdx,
      }
    );
    return board;
  }

  if (!optimisticBoard?.lists?.[dropCardListIdx]?.cards?.[dropCardIdx]) {
    log.error(
      'An error has occurred in optimisticCreateGroupCard (coming from existing group)',
      {
        boardLists: optimisticBoard.lists,
        dropCardIdx,
        dropCardListIdx,
      }
    );
    return board;
  }

  if (!optimisticBoard.lists[dragGroupCardListIdx]?.cards?.[dragGroupCardIdx]) {
    log.error(
      'An error has occurred in optimisticCreateGroupCard (coming from existing group)',
      {
        boardLists: optimisticBoard.lists,
        dragCardIdx,
        dragGroupCardIdx,
      }
    );
    return board;
  }

  optimisticBoard.lists[dropCardListIdx]!.cards![dropCardIdx] = groupCard;
  optimisticBoard.lists[dragGroupCardListIdx]?.cards?.[
    dragGroupCardIdx
  ]?.children?.splice(dragCardIdx, 1);

  return optimisticBoard;
};

export const optimisticCreateGroupCard = ({
  board,
  groupCard,
  dragCard,
  dropCard,
}: OptimisticCreateGroupCardArgs): Board => {
  const dragCardAlreadyInAGroup = Boolean(dragCard.parentId);
  if (dragCardAlreadyInAGroup) {
    return optimisticCreateGroupCardComingFromExistingGroup({
      board,
      dragCard,
      dropCard,
      groupCard,
    });
  }

  const optimisticBoard = deepClone(board);

  const dropCardListIdx = board.lists?.findIndex(
    (list) => list?.id === dropCard.listId
  ) as number;

  const dropCardIdx = board.lists?.[dropCardListIdx]?.cards?.findIndex(
    (card) => card?.id === dropCard.id
  ) as number;

  const dragCardListIdx = board.lists?.findIndex(
    (list) => list?.id === dragCard.listId
  ) as number;

  const dragCardIdx = board.lists?.[dragCardListIdx]?.cards?.findIndex(
    (card) => card?.id === dragCard.id
  ) as number;

  if (
    dropCardIdx < 0 ||
    dropCardListIdx < 0 ||
    dragCardIdx < 0 ||
    dragCardListIdx < 0
  ) {
    log.error('An error has occurred in optimisticCreateGroupCard', {
      dragCardIdx,
      dragCardListIdx,
      dropCardIdx,
      dropCardListIdx,
    });
    return board;
  }

  if (!optimisticBoard?.lists?.[dropCardListIdx]?.cards?.[dropCardIdx]) {
    log.error('An error has occurred in optimisticCreateGroupCard', {
      boardLists: optimisticBoard.lists,
      dropCardIdx,
      dropCardListIdx,
    });
    return board;
  }

  if (!optimisticBoard.lists[dragCardListIdx]?.cards?.[dragCardIdx]) {
    log.error('An error has occurred in optimisticCreateGroupCard', {
      boardLists: optimisticBoard.lists,
      dragCardIdx,
      dragCardListIdx,
    });
    return board;
  }

  optimisticBoard.lists[dropCardListIdx]!.cards![dropCardIdx] = groupCard;
  optimisticBoard.lists[dragCardListIdx]?.cards?.splice(dragCardIdx, 1);

  return optimisticBoard;
};
