import { Icon, IconProps } from '../Icon';

export const TeamIconColor = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M49.82 47.064l-8.922-4.866A3.643 3.643 0 0139 39v-5s2.618-3.132 3.617-7.484A2.999 2.999 0 0044.002 24v-4c0-.88-.392-1.667-1-2.217V12s1.187-9-11-9c-12.188 0-11 9-11 9v5.783c-.61.55-1 1.337-1 2.217v4c0 1.054.553 1.981 1.383 2.516C22.382 30.868 25 34 25 34v5c0 1.333-.729 2.56-1.898 3.198l-8.922 4.866A8.022 8.022 0 0010 54.106V61h44v-6.894a8.023 8.023 0 00-4.18-7.042z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      stroke="#001B45"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M49.82 47.064l-8.922-4.866A3.643 3.643 0 0139 39v-5s2.618-3.132 3.617-7.484A2.999 2.999 0 0044.002 24v-4c0-.88-.392-1.667-1-2.217V12s1.187-9-11-9c-12.188 0-11 9-11 9v5.783c-.61.55-1 1.337-1 2.217v4c0 1.054.553 1.981 1.383 2.516C22.382 30.868 25 34 25 34v5c0 1.333-.729 2.56-1.898 3.198l-8.922 4.866A8.022 8.022 0 0010 54.106V61h44v-6.894a8.023 8.023 0 00-4.18-7.042z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M57.39 40.122l-6.704-4.203a3.146 3.146 0 01-1.64-2.762V30s2.898-2.704 3.76-6.463A2.588 2.588 0 0054 21.364v-3.455c0-.76-.474-1.439-1-1.914V11s-.437-8-10-8c-2.094 0-3.73.397-5.04.997C43.741 6.337 43 12 43 12v5.783c.61.55 1 1.337 1 2.217v4a3 3 0 01-1.382 2.517C41.619 30.869 39 34 39 34v5c0 1.333.729 2.56 1.899 3.198l8.922 4.866A8.009 8.009 0 0152.152 49H61v-2.797a6.926 6.926 0 00-3.61-6.081z"
      clipRule="evenodd"
    />
    <path
      fill="#FFCC3E"
      fillRule="evenodd"
      stroke="#001B45"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M57.39 40.122l-6.704-4.203a3.146 3.146 0 01-1.64-2.762V30s2.898-2.704 3.76-6.463A2.588 2.588 0 0054 21.364v-3.455c0-.76-.474-1.439-1-1.914V11s-.437-8-10-8c-2.094 0-3.73.397-5.04.997C43.741 6.337 43 12 43 12v5.783c.61.55 1 1.337 1 2.217v4a3 3 0 01-1.382 2.517C41.619 30.869 39 34 39 34v5c0 1.333.729 2.56 1.899 3.198l8.922 4.866A8.009 8.009 0 0152.152 49H61v-2.797a6.926 6.926 0 00-3.61-6.081z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M14.18 47.064l8.922-4.866A3.643 3.643 0 0025 39v-5s-2.618-3.132-3.617-7.484A2.999 2.999 0 0120 24v-4c0-.88.391-1.667 1-2.217V12s-.74-5.664 5.04-8.004C24.731 3.397 23.095 3 21 3c-9.562 0-10 8-10 8v4.994c-.525.475-1 1.154-1 1.915v3.454a2.59 2.59 0 001.195 2.174C12.057 27.295 14.954 30 14.954 30v3.156a3.144 3.144 0 01-1.639 2.762l-6.704 4.204A6.925 6.925 0 003 46.203V49h8.849a8.009 8.009 0 012.331-1.936z"
      clipRule="evenodd"
    />
    <path
      fill="#FFCC3E"
      fillRule="evenodd"
      stroke="#001B45"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14.18 47.064l8.922-4.866A3.643 3.643 0 0025 39v-5s-2.618-3.132-3.617-7.484A2.999 2.999 0 0120 24v-4c0-.88.391-1.667 1-2.217V12s-.74-5.664 5.04-8.004C24.731 3.397 23.095 3 21 3c-9.562 0-10 8-10 8v4.994c-.525.475-1 1.154-1 1.915v3.454a2.59 2.59 0 001.195 2.174C12.057 27.295 14.954 30 14.954 30v3.156a3.144 3.144 0 01-1.639 2.762l-6.704 4.204A6.925 6.925 0 003 46.203V49h8.849a8.009 8.009 0 012.331-1.936z"
      clipRule="evenodd"
    />
  </Icon>
);
