import { ApolloCache } from '@apollo/client';
import {
  ImprovementGoal,
  ImprovementGoalFragmentFragment,
  ImprovementGoalFragmentFragmentDoc,
} from '@spoke/graphql';

type GetImprovementGoalFromCacheArgs = {
  improvementGoalId: string;
  cache: ApolloCache<object>;
};
export function getImprovementGoalFromCache({
  improvementGoalId,
  cache,
}: GetImprovementGoalFromCacheArgs): ImprovementGoal | null {
  return (
    (cache.readFragment({
      fragment: ImprovementGoalFragmentFragmentDoc,
      fragmentName: 'ImprovementGoalFragment',
      id: `ImprovementGoal:${improvementGoalId}`,
    }) as ImprovementGoalFragmentFragment as ImprovementGoal) || null
  );
}
