import { useCurrentTeam } from './useCurrentTeam';
import { useTeamParentPrograms } from './useTeamParentPrograms';

type QueryData = ReturnType<typeof useTeamParentPrograms>[0];
type QueryRef = ReturnType<typeof useTeamParentPrograms>[1];

export const useCurrentTeamParentPrograms = (): [
  QueryData | null,
  QueryRef
] => {
  const [currentTeam] = useCurrentTeam();
  const [programs, query] = useTeamParentPrograms({
    skip: !currentTeam?.id,
    variables: { teamId: currentTeam?.id ?? '' },
  });

  return [programs, query];
};
