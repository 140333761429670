import { useCallback } from 'react';
import { useToast, log } from '@spoke/common';
import {
  useExportTeamInsightToConfluenceMutation,
  ConfluencePageUri,
} from '@spoke/graphql';

export const useExportTeamInsightToConfluenceHandler = () => {
  const [exportTeamInsightToConfluence] =
    useExportTeamInsightToConfluenceMutation();

  const [toast] = useToast();

  const handleExportTeamInsightToConfluence = useCallback(
    async ({
      confluenceSpaceKey,
      teamInsightId,
    }: {
      confluenceSpaceKey: string;
      teamInsightId: string;
    }): Promise<ConfluencePageUri | false> => {
      if (!teamInsightId) {
        log.error(
          'Missing critical data to export team insight to Confluence',
          { teamInsightId, confluenceSpaceKey }
        );
        return false;
      }

      log.info(`Exporting TeamInsight to Confluence`, {
        teamInsightId,
        confluenceSpaceKey,
      });

      const { errors, data } = await exportTeamInsightToConfluence({
        variables: { teamInsightId, confluenceSpaceKey },
      });

      if (errors || !data) {
        log.error('exportTeamInsightToConfluence call responded with errors', {
          errors,
          data,
        });
        toast({
          status: 'error',
          title: 'Something went wrong',
          description: 'Error exporting Team Insight to Confluence',
        });
        return false;
      }

      toast({
        status: 'success',
        title: 'Team Insight exported to Confluence',
        description: 'Team Insight exported to Confluence sucessfully.',
      });

      return data.exportTeamInsightToConfluence;
    },
    [exportTeamInsightToConfluence, toast]
  );

  return [handleExportTeamInsightToConfluence];
};
