import { ApolloCache, FetchResult } from '@apollo/client';
import { log } from '../../../../SpkLog';
import { CacheModifiers, DeepPartial } from '../../../../types';
import { List, NewCardsByBoardIdSubscription } from '@spoke/graphql';

export const getNewCardsSubscriptionProcessor = (
  cache: ApolloCache<object>
) => {
  const processNewCardSubscriptionData = (
    subscriptionData: FetchResult<NewCardsByBoardIdSubscription>
  ) => {
    if (!subscriptionData?.data?.cardUpdate) {
      log.error(
        'Unable to process NewCardsByBoardId subscription data: invalid data received.',
        { subscriptionData }
      );
      return;
    }

    const newCard = subscriptionData?.data?.cardUpdate;

    const listId = newCard.listId;
    const listCacheId = `List:${listId}`;

    const updateFields: CacheModifiers<DeepPartial<List>> = {
      cards: (prevCards) => {
        if (!Array.isArray(prevCards)) {
          log.error(
            'Unable to process NewCardsByBoardId subscription data: list is missing "cards" property.',
            { newCard, prevCards, listId }
          );
          return prevCards;
        }

        // This is only needed here (and in createCardUpdateFunction) because of our incorrect setup of
        // subscribing to new cards without an underlying query. Both the optimistic response of creating cards
        // and this are trying to push the same card into list
        const isCardAlreadyInList = Boolean(
          prevCards.some((card) => card?.id === newCard.id)
        );

        if (isCardAlreadyInList) {
          return prevCards;
        }

        return [...prevCards, newCard];
      },
    };

    cache.modify({
      id: listCacheId,
      fields: updateFields,
    });
  };

  return processNewCardSubscriptionData;
};
