import { CSSObject } from '@emotion/react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { isNullish } from '@spoke/common';
import {
  FC,
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Box } from './Box';
import { Flex, FlexProps } from './Flex';

const SPLIDE_CSS_OVERRIDES: CSSObject = {
  '.splide__arrow.splide__arrow--prev': {
    left: 0,
    transform: 'translate(-50%,-60%)',
  },
  '.splide__arrow.splide__arrow--next': {
    right: 0,
    transform: 'translate(50%,-60%)',
  },
  '.splide__arrow svg': {
    fill: 'gray.600',
    transition: 'fill 0.2s ease-out',
  },
  '.splide__arrow:disabled svg': {
    fill: 'gray.300',
  },
  '.splide__arrow': {
    bg: 'white',
    opacity: 1,
    boxShadow: '1px 1px 7px rgba(0, 0, 0, 0.15) !important',
    transition: 'box-shadow 0.2s ease-out',
    color: 'gray.500',
    '&:hover': {
      boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.30) !important',
    },
    '&:disabled': {
      boxShadow: '1px 1px 7px rgba(0, 0, 0, 0.15) !important',
      cursor: 'default',
    },
  },
  '.splide.is-focus-in .splide__arrow:focus': {
    outline: '2px solid #6a4dcc',
    outlineOffset: 0,
  },
};

type Props = Omit<FlexProps, 'onChange'> & {
  pages: ReactElement[];
  selectedIndex?: number;
  onChange?: (newIndex: number) => void;
};
export const Carousel: FC<Props> = ({
  pages,
  selectedIndex = 0,
  onChange = () => {},
  ...rest
}) => {
  const splideRef = useRef<Splide>(null);

  /**
   * This is to make sure the component is updated once the ref is available.
   * We don't get a render when an actual useRef is set.
   */
  const [currentSlideRef, setCurrentSlideRef] = useState<HTMLDivElement | null>(
    null
  );

  const [currentSlideHeight, setCurrentSlideHeight] = useState<number>(0);

  /**
   * This is to lag the introduction of the transition property in the slide
   * container by one render. Because it takes one or two renders before the
   * actual content size is calculated, and we don't want to animate
   * from 0 to <height> on mount.
   */
  const [heightSetOnce, setHeightSetOnce] = useState<boolean>(false);

  useEffect(() => {
    if (heightSetOnce) return;
    if (!currentSlideHeight) return;
    setHeightSetOnce(true);
  }, [currentSlideHeight, heightSetOnce]);

  const handleCurrentSlideRef = useCallback((ref: HTMLDivElement) => {
    if (!ref) return;
    setCurrentSlideRef(ref);
  }, []);

  useLayoutEffect(() => {
    if (!currentSlideRef) return;
    setCurrentSlideHeight(currentSlideRef.getBoundingClientRect().height);
  }, [currentSlideRef]);

  useEffect(() => {
    if (!splideRef.current) return;
    splideRef.current.go(selectedIndex);
  }, [selectedIndex]);

  return (
    <Flex
      layerStyle="outlineGray"
      borderRadius="lg"
      w="92%"
      mx="auto"
      py={5}
      px={0}
      position="relative"
      sx={SPLIDE_CSS_OVERRIDES}
      {...rest}
    >
      <Splide
        options={{
          pagination: false,
        }}
        ref={(ref) => {
          if (!ref) return;
          (splideRef as MutableRefObject<Splide>).current = ref;
          ref.splide?.off('move');
          ref.splide?.on('move', () => {
            if (!splideRef.current) return;
            const curIdx = ref.splide?.index;
            if (isNullish(curIdx)) return;
            onChange(curIdx as number);
          });
        }}
        style={{
          width: '100%',
          minHeight: 120,
          height: currentSlideHeight + 5,
          transition: heightSetOnce ? 'height 0.2s ease-out' : undefined,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {pages.map((page, idx) => (
          // TODO hidden slides are still tabbable. The library doesn't do anything about this
          <SplideSlide
            key={idx}
            style={{
              overflow: 'hidden',
              height: currentSlideHeight + 5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <Box ref={selectedIndex === idx ? handleCurrentSlideRef : null}>
              {page}
            </Box>
          </SplideSlide>
        ))}
      </Splide>
    </Flex>
  );
};
