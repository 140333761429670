import { GraphQLError } from 'graphql';
import {
  useCurrentBoardId,
  useToast,
  TOAST_ERROR_GENERIC,
  EMAIL_PATTERN,
  log,
} from '@spoke/common';
import { useInviteToBoardByEmailMutation } from '@spoke/graphql';

export const useBulkInviteToBoardHandler = () => {
  const [boardId] = useCurrentBoardId();
  const [inviteToBoardByEmail] = useInviteToBoardByEmailMutation();
  const [toast] = useToast();

  const handleBulkInviteToBoard = async (emails: string[]) => {
    if (!boardId) {
      log.error('Cannot invite to board without loaded boardId', {
        boardId,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const validEmails = emails.filter((email) => email.match(EMAIL_PATTERN));

    if (!validEmails.length) {
      log.warn('No-op: no valid emails to invite to board', { validEmails });
      return;
    }

    log.info('Sending bulk board invites by email', { emails, boardId });

    const results = await Promise.all(
      emails.map((email) =>
        inviteToBoardByEmail({ variables: { boardId, email } })
      )
    );

    const errors = results.reduce(
      (acc, cur) => [...acc, ...(cur.errors || [])],
      [] as GraphQLError[]
    );

    if (errors.length) {
      log.error('Bulk board invites responded with errors', {
        boardId,
        validEmails,
        errors,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    toast({
      status: 'success',
      title: 'Invites sent',
      description: `${validEmails.length} team member${
        validEmails.length !== 1 ? 's' : ''
      } invited successfuly.`,
    });
  };

  return [handleBulkInviteToBoard];
};
