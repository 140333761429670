import { FC, useState } from 'react';
import { useCurrentOrg, useTeamTree } from '../../queries';
import { ChooseTeamTypeModalContent } from './ChooseTeamTypeModalContent';
import { CreateProgramModalContent } from './CreateProgramModalContent';
import { CreateTeamModalContent } from './CreateTeamModalContent';
import { TeamType } from '@spoke/graphql';
import {
  hardRedirect,
  ModalProps,
  Modal,
  ModalOverlay,
  useRouter,
  useRoutes,
} from '@spoke/common';

type CreateTeamWizardModalContentProps = {
  close: () => void;
};
export const CreateTeamWizardModalContent: FC<
  CreateTeamWizardModalContentProps
> = ({ close }) => {
  const router = useRouter();
  const routes = useRoutes();
  const [currentOrg] = useCurrentOrg();
  const [{ teamTree }] = useTeamTree();

  const teams = teamTree?.filter(
    (treeNode) => treeNode.team.type === TeamType.Team
  );

  const noTeamsCreated = !teams || teams?.length === 0;

  const [creatingTeamType, setCreatingTeamType] = useState<TeamType | null>(
    null
  );

  const canCreatePrograms = Boolean(currentOrg?.plan?.programs);

  const onSelectTeamType = (type: TeamType) => {
    const shouldRedirectToBilling =
      type === TeamType.Program && !canCreatePrograms;

    if (shouldRedirectToBilling) {
      hardRedirect(routes.Billing);
      return;
    }

    setCreatingTeamType(type);
  };

  const onFinish = () => {
    close();
    router.push(routes.Dashboard);
  };

  if (creatingTeamType === TeamType.Team) {
    return (
      <CreateTeamModalContent
        afterSubmit={onFinish}
        onCancel={() => setCreatingTeamType(null)}
      />
    );
  }

  if (creatingTeamType === TeamType.Program) {
    return (
      <CreateProgramModalContent
        afterSubmit={onFinish}
        onCancel={() => setCreatingTeamType(null)}
      />
    );
  }

  return (
    <ChooseTeamTypeModalContent
      onSelect={onSelectTeamType}
      canCreatePrograms={canCreatePrograms}
      hasTeamsCreated={!noTeamsCreated}
    />
  );
};

type CreateTeamInnerModalProps = Omit<ModalProps, 'children'>;
export const CreateTeamWizardModal: FC<CreateTeamInnerModalProps> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <CreateTeamWizardModalContent close={props.onClose} />
  </Modal>
);
