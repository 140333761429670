import { FC } from 'react';
import { BsGear, BsPlus } from 'react-icons/bs';
import { ImprovementGoalsHookData } from '../queries';
import { PointChart } from '@spoke/charts';
import {
  GridItem,
  Flex,
  IconButton,
  Icon,
  Button,
  truncateDecimals,
  Box,
  SpkTime,
  isNullish,
  Circle,
  Text,
  formatWithGoalUnit,
  useRouter,
  useRoutes,
  Tooltip,
  HStack,
  OrganizationIcon,
  ProgramIcon,
} from '@spoke/common';
import { ImprovementGoalTypeTargetType, TeamType } from '@spoke/graphql';
import { useCurrentOrg, useTeamType } from '@spoke/user';
import { BiInfoCircle } from 'react-icons/bi';

type ImprovementGoalCardProps = {
  goal: ImprovementGoalsHookData[0];
  reportOnly?: boolean;
  onReportClick: () => void;
};
export const ImprovementGoalCard: FC<ImprovementGoalCardProps> = ({
  goal,
  reportOnly = false,
  onReportClick,
}) => {
  const router = useRouter();
  const routes = useRoutes();
  const { isTeam, isOrg, isProgram } = useTeamType();
  const lastEntry = goal.data?.[goal.data?.length - 1];
  const lastValue = lastEntry?.value ?? null;
  const lastEntryDate = lastEntry?.date ?? null;

  const isOnTarget =
    goal.type.targetType === ImprovementGoalTypeTargetType.Above
      ? Boolean(lastValue >= goal.target)
      : Boolean(lastValue <= goal.target);

  const valueColor = isNullish(lastEntry)
    ? 'gray.800'
    : isOnTarget
    ? 'green.700'
    : 'negativeRed';

  return (
    <GridItem
      borderStyle="solid"
      borderWidth={1}
      borderColor="gray.200"
      borderRadius={8}
      padding={4}
      key={`improvement-goal-${goal.id}`}
      bg="white"
      boxShadow={
        isOnTarget
          ? `0 0 0 1px rgb(0, 128, 89, 0.4)`
          : `0 0 0 1px rgb(250, 82, 82, 0.4)`
      }
    >
      <Flex justifyContent="space-between">
        <Flex gap={2} alignItems="flex-start">
          <Circle mt="5px" bg={goal.type.color} size={2} />
          <Text
            cursor={reportOnly ? 'default' : 'pointer'}
            onClick={() =>
              reportOnly
                ? null
                : router.push(routes.ImprovementGoalDrilldown(goal.id))
            }
            fontSize={15}
            color="gray.700"
            fontWeight={500}
            _hover={{ textDecor: reportOnly ? 'none' : 'underline' }}
          >
            {goal.type.name}
          </Text>
          <Tooltip
            label={`${goal.type.description}`}
            openDelay={350}
            placement="top"
            fontSize={12}
            maxW={250}
            variant="white"
            hasArrow
          >
            <Box tabIndex={-1} pt="3px">
              <Icon as={BiInfoCircle} color="gray.500" />
            </Box>
          </Tooltip>
        </Flex>
        {!reportOnly && (
          <Flex gap={2} alignItems="center">
            {!goal.paused && isTeam && (
              <IconButton
                aria-label="Report data"
                onClick={onReportClick}
                icon={<Icon as={BsPlus} color="gray.600" w={7} h={7} />}
              />
            )}

            <IconButton
              aria-label="Goal details"
              onClick={() =>
                router.push(routes.ImprovementGoalDrilldown(goal.id))
              }
              icon={<Icon as={BsGear} color="gray.600" w={4} h={4} />}
            />
          </Flex>
        )}
        {reportOnly && (
          <Button
            variant="outlineGray"
            onClick={onReportClick}
            leftIcon={<Icon as={BsPlus} color="gray.600" w={7} h={7} />}
          >
            Report
          </Button>
        )}
      </Flex>

      <Flex gap={6}>
        <Box>
          <Text fontSize={14} color="gray.600" fontWeight={500}>
            Target
          </Text>
          <Text fontSize={14} color="gray.900" fontWeight={500}>
            {goal.type.targetType === ImprovementGoalTypeTargetType.Above
              ? '>'
              : '<'}
            {formatWithGoalUnit(goal.target, goal.type.unit, 'short')}
          </Text>
        </Box>
        <Box>
          <Text fontSize={14} color="gray.600" fontWeight={500}>
            Current
          </Text>
          <Text fontSize={14} color={valueColor} fontWeight={500}>
            {lastValue
              ? formatWithGoalUnit(
                  truncateDecimals(lastValue, 1),
                  goal.type.unit,
                  'short'
                )
              : 'N/A'}
          </Text>
        </Box>
        <Box>
          <Text fontSize={14} color="gray.600" fontWeight={500}>
            Days in progress
          </Text>
          <Text fontSize={14} color="gray.900" fontWeight={500}>
            {`${SpkTime.amountBetween(goal.createdAt, new Date(), 'days')}d`}
          </Text>
        </Box>
        <Box>
          <Text fontSize={14} color="gray.600" fontWeight={500}>
            Associated action items
          </Text>
          <Text fontSize={14} color="gray.900" fontWeight={500}>
            {goal.timesUsed}
          </Text>
        </Box>
      </Flex>
      <Box>
        <Box onClick={onReportClick}>
          <PointChart
            mt={4}
            mb={2}
            w="full"
            data={goal.data?.map((goalData) => ({
              id: goalData?.id || '',
              value: goalData?.value || 0,
              date: goalData?.date
                ? new Date(goalData?.date).toISOString()
                : new Date().toISOString(),
            }))}
            height={180}
            targetDirection={goal.type.targetType}
            target={goal.target}
            unit={goal.type.unit}
          />
        </Box>

        <HStack justifyContent="space-between">
          <Text fontSize={12} color="gray.600" fontWeight={500}>
            Last entry:{' '}
            {!lastEntryDate
              ? 'N/A'
              : SpkTime.format(lastEntryDate, 'MMM dd, yyyy')}
          </Text>

          <>
            {goal.team.type === TeamType.Organization ? (
              <Tooltip
                label={`Goal established for the entire organization; all teams will inherit this goal.`}
                openDelay={350}
                placement="top"
                fontSize={12}
                maxW={250}
                variant="white"
                hasArrow
              >
                <Text fontSize={12} color="gray.600" fontWeight={500}>
                  <OrganizationIcon w={5} h={5} color="primary.500" />{' '}
                  {goal.team.name}
                </Text>
              </Tooltip>
            ) : goal.team.type === TeamType.Program ? (
              <Tooltip
                label={`Goal established for the entire program; all teams within this program will inherit this goal.`}
                openDelay={350}
                placement="top"
                fontSize={12}
                maxW={250}
                variant="white"
                hasArrow
              >
                <Text fontSize={12} color="gray.600" fontWeight={500}>
                  <ProgramIcon
                    w={5}
                    h={5}
                    cursor="pointer"
                    pointerEvents="none"
                  />{' '}
                  {goal.team.name}
                </Text>
              </Tooltip>
            ) : null}
          </>
        </HStack>
      </Box>
    </GridItem>
  );
};
