import { FC } from 'react';
import { FaFileCsv, FaFilePdf } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import {
  useExportBoardPdfHandler,
  useExportBoardCsvHandler,
} from '../../handlers';
import { ExportBoardConfluenceModal } from './ExportBoardConfluenceModal';
import { ExportBoardEmailModal } from './ExportBoardEmailModal';
import { ExportBoardJiraModal } from './ExportBoardJiraModal';
import { ExportBoardTrelloModal } from './ExportBoardTrelloModal';
import {
  ToastOptions,
  ModalProps,
  useToast,
  useDisclosure,
  callIfExists,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  Heading,
  ModalBody,
  Flex,
  Button,
  Icon,
  JiraIcon,
  TrelloIcon,
  ConfluenceIcon,
  Modal,
  ModalOverlay,
  Text,
} from '@spoke/common';

const EXPORT_LOADING_TOAST: ToastOptions = {
  title: 'Processing export',
  description:
    'Your export is being prepared and will be downloaded in a few moments.',
  status: 'loading',
};

type ExportBoardModalProps = Omit<ModalProps, 'children'>;

export const ExportBoardModalContent: FC<ExportBoardModalProps> = (props) => {
  const [toast] = useToast();

  const exportEmailModal = useDisclosure();
  const exportJiraModal = useDisclosure();
  const exportTrelloModal = useDisclosure();
  const exportConfluenceModal = useDisclosure();

  const [handleExportPdf] = useExportBoardPdfHandler();
  const [handleExportCsv] = useExportBoardCsvHandler();

  const exportToPdf = async () => {
    callIfExists(props.onClose);
    const closeToast = toast(EXPORT_LOADING_TOAST);
    await handleExportPdf();
    closeToast();
  };

  const exportToCsv = async () => {
    callIfExists(props.onClose);
    await handleExportCsv();
  };

  return (
    <ModalContent p={8} maxW={580}>
      <ModalCloseButton />
      <ModalHeader p={0}>
        <Heading mb={1} fontSize={24} color="gray.900">
          Export retrospective
        </Heading>
        <Text mb={3} fontSize={16} fontWeight={400} color="gray.500">
          Export retrospective in your favorite format
        </Text>
      </ModalHeader>
      <ModalBody p={0}>
        <Flex
          gap={6}
          pt={2}
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Button onClick={exportToPdf} variant="squareOutlineGray">
            <Icon as={FaFilePdf} mt={2} w={12} h={12} color="red" />
            <Text color="gray.500" fontSize={14}>
              PDF File
            </Text>
          </Button>
          <Button onClick={exportToCsv} variant="squareOutlineGray">
            <Icon as={FaFileCsv} mt={2} w={12} h={12} color="green" />
            <Text color="gray.500" fontSize={14}>
              CSV File
            </Text>
          </Button>
          <Button
            onClick={exportEmailModal.open}
            variant="squareOutlineGray"
            gap="9px"
          >
            <Icon as={MdEmail} mt={2} w={32} h={14} color="blue.500" />
            <Text color="gray.500" fontSize={14}>
              Email
            </Text>
          </Button>
          <Button
            onClick={exportJiraModal.open}
            variant="squareOutlineGray"
            gap="9px"
          >
            <Icon as={JiraIcon} mt={2} w={32} h={14} color="blue.500" />
            <Text color="gray.500" fontSize={14}>
              Jira Cloud
            </Text>
          </Button>
          <Button
            onClick={exportTrelloModal.open}
            variant="squareOutlineGray"
            gap="9px"
          >
            <Icon as={TrelloIcon} mt={2} w={32} h={14} color="blue.500" />
            <Text color="gray.500" fontSize={14}>
              Trello
            </Text>
          </Button>
          <Button
            onClick={exportConfluenceModal.open}
            variant="squareOutlineGray"
            gap="9px"
          >
            <Icon as={ConfluenceIcon} mt={2} w={32} h={14} color="blue.500" />
            <Text color="gray.500" fontSize={14}>
              Confluence Cloud
            </Text>
          </Button>
        </Flex>
      </ModalBody>
      <ExportBoardEmailModal
        isOpen={exportEmailModal.isOpen}
        onClose={exportEmailModal.close}
      />
      <ExportBoardJiraModal
        isOpen={exportJiraModal.isOpen}
        onClose={exportJiraModal.close}
      />
      <ExportBoardTrelloModal
        isOpen={exportTrelloModal.isOpen}
        onClose={exportTrelloModal.close}
      />
      <ExportBoardConfluenceModal
        isOpen={exportConfluenceModal.isOpen}
        onClose={exportConfluenceModal.close}
      />
    </ModalContent>
  );
};

export const ExportBoardModal: FC<ExportBoardModalProps> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <ExportBoardModalContent {...props} />
  </Modal>
);
