import { AlertProps } from '@chakra-ui/react';
import { FC } from 'react';
import { MdOutlineAutoAwesome } from 'react-icons/md';
import { TeamInsightsModal } from './TeamInsightsModal';
import {
  useDisclosure,
  Alert,
  Icon,
  plural,
  Spacer,
  Button,
  Text,
} from '@spoke/common';
import { TeamInsightFactCauseSolutionFragmentFragment } from '@spoke/graphql';

type Props = AlertProps & {
  insights: Array<TeamInsightFactCauseSolutionFragmentFragment>; // | ...
};
export const TeamInsightsAlert: FC<Props> = ({ insights, ...props }) => {
  const insightsModalDisclosure = useDisclosure();

  const unseenInsightCount = insights?.length || 0;

  if (!unseenInsightCount) return null;

  return (
    <Alert
      bg="primary.50"
      color="primary.500"
      status="info"
      borderRadius="md"
      {...props}
    >
      <Icon as={MdOutlineAutoAwesome} mr={2} w={5} h={5} color="primary.500" />
      We&apos;ve identified
      <Text mx="2px" as="span" fontWeight={600}>
        {unseenInsightCount}
      </Text>{' '}
      beneficial {plural('action', unseenInsightCount)} for your team.
      <Spacer />
      <Button
        onClick={insightsModalDisclosure.open}
        variant="outlineGray"
        bg="white"
        h={9}
        _hover={{ bg: 'gray.50' }}
      >
        See {plural('action', unseenInsightCount)}
      </Button>
      <TeamInsightsModal
        isOpen={insightsModalDisclosure.isOpen}
        onClose={insightsModalDisclosure.close}
        insights={insights}
      />
    </Alert>
  );
};
