import { IconProps, Icon } from '../Icon';

export const TeamPlanIcon = (props: IconProps) => (
  <Icon viewBox="0 0 64 67" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Team Plan Icon</title>
    <circle
      cx="62.4762"
      cy="32.5817"
      r="1.52381"
      transform="rotate(90 62.4762 32.5817)"
      fill="currentColor"
    />
    <circle
      cx="53.3331"
      cy="32.5817"
      r="1.52381"
      transform="rotate(90 53.3331 32.5817)"
      fill="currentColor"
    />
    <circle
      cx="44.1905"
      cy="32.5817"
      r="1.52381"
      transform="rotate(90 44.1905 32.5817)"
      fill="currentColor"
    />
    <circle
      r="1.52381"
      transform="matrix(-0.697617 0.716471 -0.697617 -0.716471 54.1176 54.9844)"
      fill="currentColor"
    />
    <circle
      r="1.52381"
      transform="matrix(-0.697617 0.716471 -0.697617 -0.716471 47.7397 48.4339)"
      fill="currentColor"
    />
    <circle
      r="1.52381"
      transform="matrix(-0.697617 0.716471 -0.697617 -0.716471 41.3612 41.8832)"
      fill="currentColor"
    />
    <circle
      cx="32.7618"
      cy="64.7465"
      r="1.52381"
      transform="rotate(-180 32.7618 64.7465)"
      fill="currentColor"
    />
    <circle
      cx="32.7618"
      cy="55.6036"
      r="1.52381"
      transform="rotate(-180 32.7618 55.6036)"
      fill="currentColor"
    />
    <circle
      cx="32.7618"
      cy="46.4607"
      r="1.52381"
      transform="rotate(-180 32.7618 46.4607)"
      fill="currentColor"
    />
    <circle
      r="1.52381"
      transform="matrix(-0.697617 -0.716471 0.697617 -0.716471 10.9888 56.1207)"
      fill="currentColor"
    />
    <circle
      r="1.52381"
      transform="matrix(-0.697617 -0.716471 0.697617 -0.716471 17.3667 49.5701)"
      fill="currentColor"
    />
    <circle
      r="1.52381"
      transform="matrix(-0.697617 -0.716471 0.697617 -0.716471 23.7451 43.0195)"
      fill="currentColor"
    />
    <circle
      cx="1.52381"
      cy="34.2291"
      r="1.52381"
      transform="rotate(-90 1.52381 34.2291)"
      fill="currentColor"
    />
    <circle
      cx="10.6669"
      cy="34.2291"
      r="1.52381"
      transform="rotate(-90 10.6669 34.2291)"
      fill="currentColor"
    />
    <circle
      cx="19.8095"
      cy="34.2291"
      r="1.52381"
      transform="rotate(-90 19.8095 34.2291)"
      fill="currentColor"
    />
    <circle
      r="1.52381"
      transform="matrix(0.697617 -0.716471 0.697617 0.716471 9.88242 11.8263)"
      fill="currentColor"
    />
    <circle
      r="1.52381"
      transform="matrix(0.697617 -0.716471 0.697617 0.716471 16.2608 18.377)"
      fill="currentColor"
    />
    <circle
      r="1.52381"
      transform="matrix(0.697617 -0.716471 0.697617 0.716471 22.6388 24.9276)"
      fill="currentColor"
    />
    <circle cx="31.2382" cy="2.06428" r="1.52381" fill="currentColor" />
    <circle cx="31.2382" cy="11.2071" r="1.52381" fill="currentColor" />
    <circle cx="31.2382" cy="20.3499" r="1.52381" fill="currentColor" />
    <circle
      r="1.52381"
      transform="matrix(0.697617 0.716471 -0.697617 0.716471 53.0112 10.69)"
      fill="currentColor"
    />
    <circle
      r="1.52381"
      transform="matrix(0.697617 0.716471 -0.697617 0.716471 46.6333 17.2407)"
      fill="currentColor"
    />
    <circle
      r="1.52381"
      transform="matrix(0.697617 0.716471 -0.697617 0.716471 40.2549 23.7912)"
      fill="currentColor"
    />
  </Icon>
);
