import { FC } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { useNotifyTeamHandler } from '../../handlers';
import { useCurrentBoardId, HStack, Icon, Button, Text } from '@spoke/common';

export type BoardShareNotifyTeamProps = {
  teamNotified: boolean;
  setTeamNotified: (value: boolean) => void;
};

export const BoardShareNotifyTeam: FC<BoardShareNotifyTeamProps> = ({
  teamNotified,
  setTeamNotified,
}) => {
  const [boardId] = useCurrentBoardId();
  const [handleNotifyTeam] = useNotifyTeamHandler();

  const notifyTeam = async () => {
    setTeamNotified(true);
    await handleNotifyTeam({ boardId: boardId! });
  };

  return (
    <HStack h={10} justifyContent="space-between">
      <HStack>
        <Icon as={AiOutlineMail} color="gray.600" w={5} h={5} />
        <Text fontWeight={500} color="gray.700">
          Notify the team about this retro
        </Text>
      </HStack>
      <Button
        variant="outlineGray"
        pt={1}
        disabled={teamNotified}
        onClick={() => !teamNotified && notifyTeam()}
      >
        {teamNotified ? 'Team notified!' : 'Notify team'}
      </Button>
    </HStack>
  );
};
