import * as Sentry from '@sentry/nextjs';
import { isServer } from './util/next/isServer';

/* eslint-disable no-console */
const isDev = process.env.NODE_ENV === 'development';
const devLogDisabled = process.env.NEXT_PUBLIC_DEV_LOG_DISABLED === 'true';
const shouldLogInConsole = () =>
  // @ts-ignore
  (isDev && !devLogDisabled) || (!isServer() && window.enableSpkLog);

class SpkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'SpkError';
  }
}

export class SpokeSentry {
  static info(message: string, info?: object | null): void {
    Sentry.addBreadcrumb({
      level: 'info',
      category: 'SpkLog',
      message,
      data: info ?? undefined,
    });
  }

  static warn(message: string, info?: object | null): void {
    Sentry.addBreadcrumb({
      level: 'warning',
      category: 'SpkLog',
      message,
      data: info ?? undefined,
    });
  }

  static error(message: string, info?: object | null) {
    Sentry.withScope((scope) => {
      scope.setLevel('error');
      scope.setExtra('data', JSON.stringify(info ?? {}));
      Sentry.captureException(new SpkError(message));
    });
  }
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export class log {
  static info(message: string, info?: object | null): void {
    SpokeSentry.info(message, info);
    const from = log.getCaller();
    if (shouldLogInConsole()) {
      console.log(
        `\x1b[46m \x1b[0m  INFO: ${message}`,
        info ? '\n' : '',
        info ? { from, info } : { from }
      );
    }
  }

  static warn(message: string, info?: object | null): void {
    SpokeSentry.warn(message, info);
    const from = log.getCaller();
    if (shouldLogInConsole()) {
      console.log(
        `\x1b[43m \x1b[0m  WARN: ${message}`,
        info ? '\n' : '',
        info ? { from, info } : { from }
      );
    }
  }

  static error(message: string, info?: object | null): void {
    SpokeSentry.error(message, info);
    const from = log.getCaller();
    if (shouldLogInConsole()) {
      console.log(
        `\x1b[41m \x1b[107m ERROR: ${message}`,
        info ? '\n' : '',
        info ? { from, info } : { from }
      );
    }
  }

  static getCaller(level: number = 3) {
    return new Error().stack
      ?.split('\n')
      [level]?.split('///')[1]
      ?.split(')')[0];
  }
}
