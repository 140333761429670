import { makeVar } from '@apollo/client';
import { useToast } from '@chakra-ui/react';
import { useCallback, useEffect, useRef } from 'react';
import { setIsLightweightMode, isLightweightModeVar } from '../../context';
import { log } from '../../SpkLog';
import { Toast } from '../../ui';
import { isServer } from '../../util';

/**
 * What we consider to be abnormally slow task
 */
const TASK_DURATION_THRESHOLD = 150;

/**
 * Higher = less sensitive to slow tasks
 */
const SLOW_TASKS_TIME_DROP_RATE_MS = 3500;

/**
 * How many slow tasks in time window to suggest lightweight mode
 */
const SLOW_TASKS_UNTIL_SUGGEST = 12;

const slowBrowserDetectionEnabledVar = makeVar<boolean>(true);

export const enableSlowBrowserDetection = () => {
  // log.info('Enabling slow browser detection');
  slowBrowserDetectionEnabledVar(true);
};

export const disableSlowBrowserDetection = () => {
  // log.info('Disabling slow browser detection');
  slowBrowserDetectionEnabledVar(false);
};

export const SlowBrowserDetection = () => {
  const slowTaskCounter = useRef<number>(0);
  const counterDropTimeout = useRef<NodeJS.Timeout | null>(null);
  const suggested = useRef<boolean>(false);

  const chakraToast = useToast();

  const suggest = useCallback(() => {
    log.warn('Falling back to lightweight mode');
    suggested.current = true;
    setTimeout(() => setIsLightweightMode(true), 3000);
    if (counterDropTimeout.current) {
      clearInterval(counterDropTimeout.current);
    }
    chakraToast({
      duration: 10000,
      position: 'bottom-left',
      render: ({ onClose }) => (
        <Toast
          status="info"
          title="Slow browser detected"
          onClose={onClose}
          description="Looks like your browser is a bit slow, so we enabled Lightweight Mode for you. You can always tweak that under User Settings."
          isClosable
        />
      ),
    });
  }, [chakraToast]);

  useEffect(() => {
    if (isServer()) return;
    log.info('Enabling slow browser detection');
    const observer = new PerformanceObserver((list) => {
      for (const e of list.getEntries()) {
        if (suggested.current) return; // Only suggest once per session
        if (!slowBrowserDetectionEnabledVar()) return;
        if (isLightweightModeVar()) return;
        if (e.duration < TASK_DURATION_THRESHOLD) return;

        slowTaskCounter.current += 1;

        log.info(
          `Long task received ${e.duration}ms. Total in span: ${slowTaskCounter.current}`
        );

        if (counterDropTimeout.current) {
          clearInterval(counterDropTimeout.current);
        }

        counterDropTimeout.current = setInterval(() => {
          if (slowTaskCounter.current > 0) {
            log.info(
              `Slow task span count dropping to ${slowTaskCounter.current - 1}`
            );
            slowTaskCounter.current -= 1;
          }
        }, SLOW_TASKS_TIME_DROP_RATE_MS);

        if (slowTaskCounter.current >= SLOW_TASKS_UNTIL_SUGGEST) {
          suggest();
        }
      }
    });

    observer.observe({ type: 'longtask', buffered: true });

    return () => {
      observer.disconnect();
    };
  }, [suggest]);

  return <></>;
};
