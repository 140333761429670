import { useBoardAnalytics } from '../../queries';
import {
  useToast,
  useNow,
  ONE_DAY_MS,
  TOAST_ERROR_GENERIC,
  NO_OP,
  log,
} from '@spoke/common';
import { useCloneBoardMutation } from '@spoke/graphql';

type HandleCloneBoardProps = {
  boardIdToClone: string;
  nameOfClone: string;
};
export const useCloneBoardHandler = () => {
  const [cloneBoard] = useCloneBoardMutation();
  const [toast] = useToast();

  const now = useNow({ roundTo: 'ten-minutes' });

  const [_, boardAnalyticsQuery] = useBoardAnalytics({
    endDate: now,
    timeWindowLength: 90 * ONE_DAY_MS,
    recursive: false,
  });

  const handleCloneBoard = async ({
    boardIdToClone,
    nameOfClone,
  }: HandleCloneBoardProps): Promise<boolean> => {
    if (!boardIdToClone || !nameOfClone) {
      log.error(
        'CloneBoard submit called without boardIdToClone and nameOfClone data',
        { boardIdToClone, nameOfClone }
      );
      toast(TOAST_ERROR_GENERIC);
      return false;
    }

    const { errors } = await cloneBoard({
      variables: {
        boardIdToClone,
        nameOfClone,
      },
    });

    if (errors) {
      log.error('cloneBoard call responded with errors', errors);
      toast(TOAST_ERROR_GENERIC);
      return false;
    }

    toast({
      status: 'success',
      title: 'Success',
      description: 'Retrospective successfully cloned',
    });

    boardAnalyticsQuery.refetch().catch(NO_OP);

    return true;
  };

  return [handleCloneBoard];
};
