import { FC, useEffect } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { BiInfoCircle } from 'react-icons/bi';
import { MdLinkOff } from 'react-icons/md';
import { IntegrationMoreInfoModal } from './IntegrationMoreInfoModal';
import { MembershipRole } from '@spoke/graphql';
import {
  IntegrationStaticData,
  useDisclosure,
  useRouteState,
  useCurrentUser,
  Flex,
  Center,
  Icon,
  Spacer,
  HStack,
  Button,
  Tooltip,
  ConfirmationModalCondensed,
  Box,
  Text,
} from '@spoke/common';

export type IntegrationCardProps = {
  isInstalled: boolean;
  onInstall: () => void;
  onUninstall: () => unknown | Promise<unknown>;
  isAvailableOnPlan: boolean;
  data: IntegrationStaticData;
};
export const IntegrationCard: FC<IntegrationCardProps> = ({
  data: { description, icon, title, key, infoModal, uninstallText },
  isInstalled,
  isAvailableOnPlan,
  onInstall,
  onUninstall,
}) => {
  const moreInfoDisclosure = useDisclosure();
  const uninstallDisclosure = useDisclosure();
  const [integrationInfoQueryParam] = useRouteState('', 'i');

  const [user] = useCurrentUser();

  const isAdmin = user?.role === MembershipRole.Admin;
  const planName = user?.organization?.plan?.displayName ?? '';

  const disabledReason = !isAvailableOnPlan
    ? `This integration is not available on the ${planName} plan. Visit your billing settings for details.`
    : isAdmin === true
    ? ''
    : 'You need to be an organization admin to manage integrations';

  useEffect(() => {
    if (!integrationInfoQueryParam) return;
    if (integrationInfoQueryParam !== key) return;
    moreInfoDisclosure.open();
    // Why can't eslint understand the more granular dependency .open?
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationInfoQueryParam, key, moreInfoDisclosure.open]);

  return (
    <Flex layerStyle="outlineGray" p={4} py={5} alignItems="flex-start">
      <Center pt={1}>
        <Icon w={8} h={8} as={icon} />
      </Center>
      <Flex mx={4} flexDir="column">
        <Text fontSize={16} color="gray.700" fontWeight={500}>
          {title}
        </Text>
        <Text fontSize={16} color="gray.600">
          {description}
        </Text>
      </Flex>
      <Spacer />
      <HStack flex={1} justifyContent="flex-end">
        {infoModal && (
          <Button
            leftIcon={
              <Icon mr="-3px" as={BiInfoCircle} color="gray.500" w={5} h={5} />
            }
            variant="outlineGray"
            onClick={moreInfoDisclosure.open}
            fontSize={15}
            h={9}
            px={3}
            size="sm"
            alignSelf="center"
          >
            <Text pt="2px">Info</Text>
          </Button>
        )}
        <Tooltip
          label={disabledReason}
          maxW={200}
          variant="white"
          placement="left"
          hasArrow
        >
          <Box tabIndex={-1}>
            {Boolean(isInstalled && onUninstall) && (
              <Button
                onClick={uninstallDisclosure.open}
                fontSize={15}
                h={9}
                px={3}
                size="sm"
                alignSelf="center"
                ml={2}
                isDisabled={!isAdmin}
                leftIcon={<Icon mr="-3px" as={MdLinkOff} w={5} h={5} />}
              >
                <Text pt="2px">Uninstall</Text>
              </Button>
            )}
            {Boolean(!isInstalled && onInstall) && (
              <Button
                onClick={onInstall}
                fontSize={15}
                h={9}
                px={3}
                size="sm"
                alignSelf="center"
                ml={2}
                isDisabled={Boolean(disabledReason)}
                leftIcon={<Icon mr="-3px" as={AiOutlineDownload} w={5} h={5} />}
              >
                <Text pt="2px">Install</Text>
              </Button>
            )}
          </Box>
        </Tooltip>
      </HStack>
      <ConfirmationModalCondensed
        title="Disconnect integration"
        close={uninstallDisclosure.close}
        isOpen={uninstallDisclosure.isOpen}
        text={
          uninstallText ||
          `Are you sure you want to disconnect <b>${title}<b/> from your organization?`
        }
        confirmText="Disconnect"
        onConfirm={async () => {
          await onUninstall();
          uninstallDisclosure.close();
        }}
      />
      {infoModal && (
        <IntegrationMoreInfoModal
          onClose={moreInfoDisclosure.close}
          isAdmin={isAdmin}
          isOpen={moreInfoDisclosure.isOpen}
          icon={icon}
          onInstall={onInstall}
          onUninstall={uninstallDisclosure.open}
          isInstalled={isInstalled}
          installDisabledReason={disabledReason}
          {...infoModal}
        />
      )}
    </Flex>
  );
};
