import { FC } from 'react';
import {
  parseCardText,
  isImage,
  VStack,
  handleCardImageError,
  SpokeTextArea,
  Box,
  Image,
  ColorBadge,
} from '@spoke/common';
import { CardFragmentFragment } from '@spoke/graphql';

// TODO add group support
type BoardCardSummaryProps = {
  card: CardFragmentFragment;
};
export const BoardCardSummary: FC<BoardCardSummaryProps> = ({ card }) => {
  const badgeColor = card.originalListBadge?.color;
  const text = card.text;
  const imageUrl = parseCardText(text).find(isImage);

  return (
    <Box
      w="full"
      bg="white"
      textAlign="center"
      py={4}
      px={8}
      fontSize={13}
      color="gray.600"
      borderTopWidth="1px"
      borderColor="gray.200"
      borderStyle="solid"
      position="relative"
      transition="all 0.1s ease-out"
      display="flex"
      alignItems="center"
      minHeight="0px"
      borderRadius="lg"
      boxShadow="md"
    >
      <Box tabIndex={-1} top={2} left={2} position="absolute" cursor="pointer">
        <ColorBadge tabIndex={-1} color={badgeColor ?? ''} />
      </Box>
      <VStack spacing={0} w="full">
        {imageUrl && (
          <Image src={imageUrl} alt="" onError={handleCardImageError} />
        )}
        {!imageUrl && (
          <Box>
            <SpokeTextArea
              w="full"
              pointerEvents="none"
              userSelect="none"
              px={0}
              width="fit-content"
              mx="auto"
              maxH="unset"
              color="gray.600"
              tabIndex={-1}
              outline="none"
              value={text}
              _focus={{ color: 'gray.600' }}
              isReadOnly
            />
          </Box>
        )}
      </VStack>
    </Box>
  );
};
