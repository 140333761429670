import { isNullish } from '@apollo/client/cache/inmemory/helpers';
import { FC, useState } from 'react';
import {
  useCreateImprovementGoalHandler,
  useAddImprovementGoalDataHandler,
  useSetGoalRemindersHandler,
} from '../../handlers';
import { useImprovementGoals } from '../../queries';
import {
  CreateGoalFormSchema,
  CreateGoalWizardForm,
} from './CreateGoalWizardForm';
import { CreateGoalWizardTypeSelector } from './CreateGoalWizardTypeSelector';
import { useCurrentTeam } from '@spoke/user';
import { ImprovementGoalTypeFragmentFragment } from '@spoke/graphql';
import {
  ModalProps,
  useToast,
  callIfExists,
  Modal,
  ModalOverlay,
  log,
} from '@spoke/common';

type CreateGoalWizardModalProps = Omit<ModalProps, 'children'> & {
  preSelectedGoalType?: ImprovementGoalTypeFragmentFragment | null;
  afterCreate?: () => void;
};

export const CreateGoalWizardModalContent: FC<CreateGoalWizardModalProps> = ({
  preSelectedGoalType,
  onClose,
  afterCreate,
}) => {
  const [selectedGoalType, setSelectedGoalType] =
    useState<ImprovementGoalTypeFragmentFragment | null>(
      preSelectedGoalType ?? null
    );

  const [currentTeam] = useCurrentTeam();
  const [_, goalsQuery] = useImprovementGoals();

  const [createGoal] = useCreateImprovementGoalHandler();
  const [addGoalData] = useAddImprovementGoalDataHandler();
  const [setGoalReminders] = useSetGoalRemindersHandler();

  const [toast] = useToast();

  const onSubmit = async (form: CreateGoalFormSchema): Promise<boolean> => {
    if (!selectedGoalType || !currentTeam) {
      log.error('Cannot submit goal form: missing critical data', {
        form,
        selectedGoalType,
        currentTeam,
      });
      return false;
    }
    const {
      target,
      initial,
      reminderUserIds,
      reminderFrequency,
      reminderTime,
    } = form;

    const createdGoal = await createGoal({
      target,
      typeId: selectedGoalType.id,
    });

    const promises: Promise<boolean>[] = [];

    if (!createdGoal) return false;

    if (initial && createdGoal?.id) {
      const goalDataPromise = addGoalData({
        date: new Date(),
        value: initial,
        improvementGoalId: createdGoal.id,
        reportingTeamId: currentTeam?.id,
      });

      promises.push(goalDataPromise);
    }

    if (reminderUserIds.length) {
      if (!reminderFrequency || isNullish(reminderTime)) {
        log.error(
          'Cannot set reminder on goal creation: missing critical data',
          { reminderFrequency, reminderUserIds, reminderTime }
        );
      } else {
        await setGoalReminders({
          frequency: reminderFrequency,
          improvementGoalId: createdGoal?.id || '',
          notificationTimeMs: reminderTime as number,
          userIds: reminderUserIds,
        });
      }
    }

    await goalsQuery.refetch({ teamId: currentTeam?.id, limit: 3 });

    toast({
      status: 'success',
      title: 'Success',
      description: 'Goal created successfully.',
    });

    onClose();
    callIfExists(afterCreate);
    return true;
  };

  if (!selectedGoalType) {
    return (
      <CreateGoalWizardTypeSelector
        onCancel={onClose}
        onSelect={setSelectedGoalType}
      />
    );
  }

  return (
    <CreateGoalWizardForm
      goalType={selectedGoalType}
      onGoBack={() => setSelectedGoalType(null)}
      onSubmit={onSubmit}
    />
  );
};

export const CreateGoalWizardModal: FC<CreateGoalWizardModalProps> = (
  props
) => (
  <Modal {...props}>
    <ModalOverlay />
    <CreateGoalWizardModalContent {...props} />
  </Modal>
);
