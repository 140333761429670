import { useCurrentUser, ONE_WEEK_MS, SpkTime } from '@spoke/common';
import { useCurrentUserQuery } from '@spoke/graphql';

type QueryRef = ReturnType<typeof useCurrentUserQuery>;
type QueryData = NonNullable<
  NonNullable<ReturnType<typeof useCurrentUser>[0]>['team']
>['insights'];

export type TeamInsightQueryData = QueryData[0];

export const useCurrentTeamInsights = (): [
  { unseenInsights: QueryData | null; insights: QueryData | null },
  QueryRef
] => {
  const [currentUser, userQuery] = useCurrentUser();
  const insights = currentUser?.team?.insights ?? null;

  const OLD_INSIGHTS_THRESH_MS = ONE_WEEK_MS * 2;

  const unseenInsights = insights
    ?.filter((i) => i.seen === false)
    .filter(
      (i) =>
        SpkTime.amountBetween(i.date, new Date(), 'ms') < OLD_INSIGHTS_THRESH_MS
    );

  const data = {
    unseenInsights: unseenInsights ?? [],
    insights: insights ?? [],
  };

  return [data, userQuery];
};
