import { FC, ReactElement } from 'react';
import { BsCheck } from 'react-icons/bs';
import {
  PricingIntegration,
  PricingIntegrationIcon,
} from './PricingIntegrationIcon';
import {
  Tooltip,
  isNullish,
  Flex,
  Box,
  Badge,
  Divider,
  HStack,
  SpkButtonGroup,
  Button,
  NO_OP,
  Icon,
  Spacer,
  Text,
  Image,
  useAssets,
} from '@spoke/common';

export type PricingPlanProps = {
  icon: ReactElement;
  bannerBgColor: string;
  bannerColor: string;
  bannerDescriptionColor?: string;
  bannerBadge?: string;
  bannerWaves?: boolean;
  name: string;
  price: number;
  discountInPercent?: number;
  priceDescription: string;
  description: string;
  buttonLabel: string;
  buttonVariant?: 'primary' | 'outline';
  buttonDescription: string;
  buttonLoading?: boolean;
  disabledReason?: string;
  period?: 'yearly' | 'monthly';
  onPeriodChange?: (newPeriod: 'yearly' | 'monthly') => void;
  preFeatures?: string;
  features: string[];
  integrations: PricingIntegration[];
  onUpgradeClick?: () => void;
};
export const PricingPlan: FC<PricingPlanProps> = ({
  icon,
  bannerBgColor,
  bannerColor,
  bannerDescriptionColor,
  bannerBadge,
  bannerWaves,
  name,
  description,
  priceDescription,
  price,
  buttonVariant,
  buttonLabel,
  buttonDescription,
  period,
  onPeriodChange,
  onUpgradeClick,
  preFeatures,
  features,
  integrations,
  buttonLoading,
  disabledReason,
  discountInPercent,
}) => {
  const hasPeriodicity = !isNullish(period);
  const { imagePlanWaveBackground } = useAssets();

  return (
    <Flex
      p={2}
      flex={1}
      flexDir="column"
      borderRadius="md"
      borderWidth={2}
      borderColor="primary.50"
      borderStyle="solid"
    >
      <Flex
        bg={bannerBgColor}
        flexDir="column"
        p={3}
        pt={4}
        pb={3}
        borderTopRadius="md"
        position="relative"
      >
        <Flex mt={4} flexDir="column">
          <Text color={bannerColor} fontSize={16} fontWeight={500}>
            {name}
          </Text>
          <Text color={bannerDescriptionColor ?? bannerColor} fontSize={13}>
            {description}
          </Text>{' '}
        </Flex>
        {bannerWaves && (
          <Image
            src={imagePlanWaveBackground}
            alt="Waves background"
            h="full"
            position="absolute"
            top={0}
            right={0}
          />
        )}
      </Flex>
      <Divider borderWidth={1} borderStyle="dashed" my={2} />
      <Flex flexDir="column" w="full" flex={1} px={3}>
        <HStack justifyContent="space-between" mb={2}>
          <Flex flexDir="column">
            <Text
              fontFamily="Inter,sans-serif"
              fontSize={22}
              fontWeight={600}
              as="span"
              alignItems="center"
              display="flex"
              mb={price === 0 ? 9 : 0}
            >
              {price === 0 ? 'Request a quote' : `$${price}`}
              {Boolean(discountInPercent) && (
                <Text fontSize={14} as="span" marginLeft={1} color="green.600">
                  {`-${discountInPercent}%`}
                </Text>
              )}
            </Text>
            <Text fontSize={12} as="span">
              {priceDescription}
            </Text>
          </Flex>
          {hasPeriodicity && (
            <SpkButtonGroup
              size="sm"
              contentStyle={{ fontSize: 13 }}
              w={150}
              selectedElementKey={period!}
              onSelect={(newPeriod) =>
                onPeriodChange?.(newPeriod as 'yearly' | 'monthly')
              }
              elements={[
                { label: 'Monthly', key: 'monthly' },
                { label: 'Yearly', key: 'yearly' },
              ]}
            />
          )}
        </HStack>
        <Tooltip
          label={disabledReason}
          maxW={200}
          variant="white"
          placement="top"
          textAlign="center"
          fontSize={12}
          openDelay={100}
        >
          <Box>
            <Button
              cursor={onUpgradeClick ? 'pointer' : 'default'}
              _hover={onUpgradeClick ? undefined : { cursor: 'default' }}
              _active={onUpgradeClick ? undefined : { cursor: 'default' }}
              onClick={onUpgradeClick ?? NO_OP}
              isDisabled={Boolean(!onUpgradeClick && disabledReason)}
              isLoading={buttonLoading}
              variant={buttonVariant}
              w="full"
            >
              {buttonLabel}
            </Button>
          </Box>
        </Tooltip>
        <Text
          my={2}
          fontSize={12}
          mx="auto"
          color="gray.500"
          textAlign="center"
          w="90%"
        >
          {buttonDescription}
        </Text>
        {preFeatures && (
          <Text fontSize={14} pl={1} my={1} color="gray.800">
            {preFeatures}
          </Text>
        )}
        {features.map((feature) => (
          <Flex key={feature} my={1} fontSize={14} color="gray.800">
            <Icon w={5} h={5} as={BsCheck} color="primary.300" />
            <Text>{feature}</Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
