import {
  PinInputField as ChakraPinInputField,
  PinInputFieldProps as ChakraPinInputFieldProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export type PinInputFieldProps = ChakraPinInputFieldProps;

export const PinInputField: FC<PinInputFieldProps> = (props) => (
  <ChakraPinInputField
    _focus={{ borderColor: 'primary.500' }}
    fontSize={20}
    fontWeight={500}
    {...props}
  />
);
