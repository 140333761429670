import { FC } from 'react';
import { MemoizedActionItemCard } from '@spoke/action-item';
import {
  VStack,
  HStack,
  Heading,
  Badge,
  Skeleton,
  EmptyState,
  MotionFlex,
} from '@spoke/common';
import { ActionItem } from '@spoke/graphql';

type FactsStatsActionItemReviewColumnProps = {
  actionItems: ActionItem[];
  title: string;
  emptyStateTitle: string;
  emptyStateDescription: string;
  isLoading: boolean;
  onDelete: (actionItemId: string) => void;
  onEditClick: (actionItemId: string) => void;
  onResolveToggle: (actionItemId: string) => void;
};
export const FactsStatsActionItemReviewColumn: FC<
  FactsStatsActionItemReviewColumnProps
> = ({
  actionItems,
  isLoading,
  emptyStateTitle,
  emptyStateDescription,
  onDelete,
  onEditClick,
  onResolveToggle,
  title,
}) => (
  <VStack flex="1" align="start">
    <HStack>
      <Heading fontWeight={500} fontSize={18}>
        {title}
      </Heading>
      <Badge colorScheme="gray">{actionItems.length}</Badge>
    </HStack>
    {actionItems.length === 0 && (
      <Skeleton
        w="full"
        display="flex"
        justifyContent="center"
        alignItems="center"
        isLoaded={!isLoading}
        minHeight={450}
        layerStyle="outlineGray"
      >
        <EmptyState
          title={emptyStateTitle}
          description={emptyStateDescription}
        />
      </Skeleton>
    )}
    {actionItems.length > 0 &&
      actionItems.map((item) => (
        <MotionFlex
          transition={{
            duration: 0.4,
            ease: 'easeOut',
          }}
          w="full"
          key={item?.id}
          layoutId={item?.id}
        >
          <MemoizedActionItemCard
            actionItem={item as ActionItem}
            onDelete={onDelete}
            onEditClick={onEditClick}
            onResolveToggle={onResolveToggle}
            variant="solid"
            w="full"
          />
        </MotionFlex>
      ))}
  </VStack>
);
