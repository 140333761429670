import { useEffect, useState } from 'react';
import { SetState } from '../../types';

/**
 * State that, when set to a value, is reset back to another one after a timeout.
 */
export const useSpringState = <T,>(
  revertTo: T,
  ms: number = 2000
): [T, SetState<T>] => {
  const [spring, setSpring] = useState(revertTo);

  useEffect(() => {
    if (spring === revertTo) return;

    const timeout = setTimeout(() => {
      setSpring(revertTo);
    }, ms);

    return () => {
      clearTimeout(timeout);
    };
  }, [spring, ms, revertTo]);

  return [spring, setSpring];
};
