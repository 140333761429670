import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useCurrentBoardId,
  useToast,
  TOAST_ERROR_GENERIC,
  getCardFromCache,
  optimisticCreateParkingLotItem,
  createParkingLotItemUpdateFunction,
  log,
} from '@spoke/common';
import { useCreateParkingLotItemFromCardIdMutation } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

export const useCreateParkingLotItemFromCardIdHandler = () => {
  const [currentTeam] = useCurrentTeam();
  const [boardId] = useCurrentBoardId();
  const { cache } = useApolloClient();

  const teamId = currentTeam?.id;
  const [createParkingLotItemFromCardId] =
    useCreateParkingLotItemFromCardIdMutation();
  const [toast] = useToast();

  const handleCreateParkingLotItemFromCardId = useCallback(
    async (cardId: string): Promise<boolean> => {
      if (!cardId || !teamId || !boardId) {
        log.error(
          'Missing critical data to create parking lot item from card',
          { cardId, teamId, boardId }
        );
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      const cardFromCache = getCardFromCache({ cache, cardId });

      const expected = optimisticCreateParkingLotItem({
        text: cardFromCache?.text as string,
        teamId,
      });

      const { errors } = await createParkingLotItemFromCardId({
        variables: {
          teamId,
          boardId,
          cardId,
          ownerId: cardFromCache?.authorId as string,
        },
        optimisticResponse: !expected
          ? undefined
          : {
              __typename: 'Mutation',
              createParkingLotItemFromCardId: expected,
            },
        update: createParkingLotItemUpdateFunction,
      });

      if (errors) {
        log.error('createParkingLotItemFromCardId call responded with errors', {
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      } else {
        toast({
          title: 'Success',
          description: 'Parking lot has been successfully created.',
        });
      }

      return true;
    },
    [cache, createParkingLotItemFromCardId, teamId, toast, boardId]
  );

  return [handleCreateParkingLotItemFromCardId];
};
