import { useCallback } from 'react';
import { useLinkTeamInsightActionItemOriginMutation } from '@spoke/graphql';
import { log } from '@spoke/common';

export const useLinkTeamInsightActionItemOriginHandler = () => {
  const [linkActionItem] = useLinkTeamInsightActionItemOriginMutation();

  const linkActionItemToTeamInsightSolution = useCallback(
    async ({
      teamInsightSolutionId,
      actionItemId,
    }: {
      teamInsightSolutionId: string;
      actionItemId: string;
    }): Promise<boolean> => {
      if (!teamInsightSolutionId || !actionItemId) {
        log.error(
          'Missing critical data to link team insight solution to action item',
          { teamInsightSolutionId, actionItemId }
        );
        return false;
      }

      log.info('Marking team insight solution as done', {
        actionItemId,
        teamInsightSolutionId,
      });

      const { errors } = await linkActionItem({
        variables: { actionItemId, teamInsightSolutionId },
      });

      if (errors) {
        log.error(
          'linkActionItemToTeamInsightSolution call responded with errors',
          { errors }
        );
        return false;
      }

      return true;
    },
    [linkActionItem]
  );

  return [linkActionItemToTeamInsightSolution];
};
