import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useCurrentBoardId,
  useCurrentUser,
  useToast,
  DeepPartial,
  optimisticUpdateBoard,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useUpdateBoardMutation, Board } from '@spoke/graphql';

export const useUpdateBoardVoteCountHandler = () => {
  const { cache } = useApolloClient();
  const [boardId] = useCurrentBoardId();
  const [currentUser] = useCurrentUser();

  const [toast] = useToast();

  const [updateBoard] = useUpdateBoardMutation();

  const handleUpdateBoardVoteCount = useCallback(
    async (newBoardVoteCount: number) => {
      if (!boardId || !currentUser?.id) {
        log.error('Missing critical data to update board vote count', {
          newVoteCount: newBoardVoteCount,
          boardId,
          currentUserId: currentUser?.id,
        });
        return;
      }

      log.info(`Updating board vote count to ${newBoardVoteCount}`, {
        newVoteCount: newBoardVoteCount,
        boardId,
      });

      const updateBoardFields: DeepPartial<Board> = {
        state: { voteCount: newBoardVoteCount },
      };
      const expected = optimisticUpdateBoard({
        cache,
        boardId,
        fields: updateBoardFields,
      });

      const { errors } = await updateBoard({
        variables: { boardId, fields: updateBoardFields },
        optimisticResponse: { __typename: 'Mutation', updateBoard: expected },
      });

      if (errors) {
        log.error('Updating board vote count responded with errors', {
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }
    },
    [boardId, cache, currentUser?.id, toast, updateBoard]
  );

  return [handleUpdateBoardVoteCount];
};
