import { FC, useCallback } from 'react';
import {
  TeamSwitcherTreeNode,
  TeamSwitcherTreeNodeProps,
} from './TeamSwitcherTreeNode';
import { TeamSwitcherTreeNodeType } from './teamSwitcherUtil';
import { TeamType } from '@spoke/graphql';
import { SetState, Text, VStack } from '@spoke/common';

type TeamSwitcherTreeProps = {
  teamTree: TeamSwitcherTreeNodeType[];
  watchedTeamTree: TeamSwitcherTreeNodeType[];
  expandedTeamIds: string[];
  setExpandedTeamIds: SetState<string[]>;
  expandedWatchedTeamIds: string[];
  setExpandedWatchedTeamIds: SetState<string[]>;
  onSelectTeam: (teamId: string) => void;
  selectedTeamId: string;
  loadingTeamId: string | null;
};
export const TeamSwitcherTree: FC<TeamSwitcherTreeProps> = ({
  onSelectTeam,
  watchedTeamTree,
  selectedTeamId,
  teamTree,
  expandedTeamIds,
  expandedWatchedTeamIds,
  setExpandedWatchedTeamIds,
  loadingTeamId,
  setExpandedTeamIds,
}) => {
  const programNodes = teamTree.filter((t) => t.team.type === TeamType.Program);
  const teamNodes = teamTree.filter((t) => t.team.type === TeamType.Team);

  const onExpandedTeamChange = useCallback(
    (teamId: string, expanding: boolean) =>
      setExpandedTeamIds((prev) =>
        expanding
          ? [...prev, teamId as string]
          : prev.filter((id) => id !== (teamId as string))
      ),
    [setExpandedTeamIds]
  );

  const onExpandedWatchedTeamChange = useCallback(
    (teamId: string, expanding: boolean) =>
      setExpandedWatchedTeamIds((prev) =>
        expanding
          ? [...prev, teamId as string]
          : prev.filter((id) => id !== (teamId as string))
      ),
    [setExpandedWatchedTeamIds]
  );

  const TeamSwitcherTreeSection: FC<
    {
      title: string;
      nodes: TeamSwitcherTreeNodeType[];
      isWatchedTree?: boolean;
    } & Partial<TeamSwitcherTreeNodeProps>
  > = ({ title, nodes, isWatchedTree, ...rest }) => {
    if (!nodes.length) return null;

    return (
      <VStack alignItems="left" spacing={0}>
        <Text fontSize={14} color="gray.400" fontWeight={500}>
          {title}
        </Text>
        {nodes.map(
          (node) =>
            node && (
              <TeamSwitcherTreeNode
                key={node.team.id}
                node={node}
                selectedTeamId={selectedTeamId}
                loadingTeamId={loadingTeamId}
                onSelect={onSelectTeam}
                expandedTeamIds={expandedTeamIds}
                onExpandedChange={onExpandedTeamChange}
                isPartOfWatchedTeamTree={isWatchedTree}
                {...rest}
              />
            )
        )}
      </VStack>
    );
  };

  return (
    <VStack flex="1" p={2} alignItems="left" spacing={3}>
      <TeamSwitcherTreeSection nodes={teamNodes} title="Your Teams" />
      <TeamSwitcherTreeSection nodes={programNodes} title="Your Programs" />
      <TeamSwitcherTreeSection
        nodes={watchedTeamTree}
        title="Watched Teams"
        isWatchedTree={true}
        expandedTeamIds={expandedWatchedTeamIds}
        onExpandedChange={onExpandedWatchedTeamChange}
      />
    </VStack>
  );
};
