import { FC, useState, useCallback, memo } from 'react';
import {
  useReplaceImprovementGoalDataHandler,
  useArchiveImprovementGoalDataHandler,
} from '../../handlers';
import { improvementGoalDataToPointChartData } from '../../util';
import {
  ImprovementGoalDataFragmentFragment,
  ImprovementGoalUnit,
  ImprovementGoalTypeTargetType,
  ImprovementGoalAggregationPeriod,
  ImprovementGoalData,
} from '@spoke/graphql';
import {
  PointChart,
  getImprovementGoalDataPopover,
  PointChartData,
} from '@spoke/charts';

type ReportGoalModalCrudChartProps = {
  data: ImprovementGoalDataFragmentFragment[];
  unit: ImprovementGoalUnit;
  targetType: ImprovementGoalTypeTargetType;
  aggregationPeriod: ImprovementGoalAggregationPeriod;
  typeName: string;
  typeMin: number | null;
  typeMax: number | null;
  refetchData: () => Promise<void>;
  target?: number | null;
};
export const ReportGoalModalCrudChart: FC<ReportGoalModalCrudChartProps> = ({
  data,
  refetchData,
  targetType,
  typeMax,
  typeMin,
  typeName,
  aggregationPeriod,
  unit,
  target,
}) => {
  const [replaceGoalData] = useReplaceImprovementGoalDataHandler();
  const [archiveGoalData] = useArchiveImprovementGoalDataHandler();

  const [selectedPoint, setSelectedPoint] = useState<PointChartData | null>(
    null
  );

  const timeSeriesData: PointChartData[] = data
    ? improvementGoalDataToPointChartData(data as ImprovementGoalData[])
    : [];

  const onReplace = useCallback(
    async (goalDataId: string, newValue: number) => {
      await replaceGoalData({ goalDataId, newValue });
      await refetchData();
      setSelectedPoint(null);
    },
    [refetchData, replaceGoalData]
  );

  const onArchive = useCallback(
    async (goalDataId: string) => {
      await archiveGoalData({ goalDataId });
      await refetchData();
      setSelectedPoint(null);
    },
    [archiveGoalData, refetchData]
  );

  return (
    <PointChart
      h={200}
      data={timeSeriesData}
      unit={unit}
      target={target ?? 0}
      targetDirection={targetType ?? ImprovementGoalTypeTargetType.Above}
      selectedPoint={selectedPoint}
      onSelectPoint={setSelectedPoint}
      popover={getImprovementGoalDataPopover({
        goalName: typeName || '',
        onDelete: onArchive,
        onEdit: onReplace,
        target: target || 0,
        max: typeMax,
        min: typeMin,
        unit: unit ?? '',
        targetType: targetType || ImprovementGoalTypeTargetType.Above,
        aggregationPeriod,
      })}
    />
  );
};

export const MemoizedReportGoalModalCrudChart = memo(ReportGoalModalCrudChart);
