import { useEffect } from 'react';
import { useCurrentTeamJiraBoards } from './useCurrentTeamJiraBoards';
import { useInstalledOrgIntegrations } from './useInstalledOrgIntegrations';
import {
  QueryConfig,
  DeepPartial,
  useProjectManagementRateLimitLock,
  deepMerge,
  log,
  isExternalRateLimitError,
} from '@spoke/common';
import { useInFlightWorkItemsQuery } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type Config = QueryConfig<typeof useInFlightWorkItemsQuery>;
type QueryRef = ReturnType<typeof useInFlightWorkItemsQuery>;
export type InFlightWorkItemsQueryData = NonNullable<
  NonNullable<QueryRef>['data']
>['inFlightWorkItems'];

export type InFlightWorkItemsQueryDataWorkItem =
  InFlightWorkItemsQueryData['workItems'][0];

export const useInFlightWorkItems = (
  config?: DeepPartial<Config>
): [InFlightWorkItemsQueryData | null, QueryRef] => {
  const [_, fireProjectManagementRateLimitLock] =
    useProjectManagementRateLimitLock();
  const { jira: orgHasJira } = useInstalledOrgIntegrations();
  const [linkedJiraBoards] = useCurrentTeamJiraBoards();

  const installedOnOrg = Boolean(orgHasJira);
  const installedOnTeam = Boolean(linkedJiraBoards?.length);

  const [currentTeam] = useCurrentTeam();

  const baseConfig: DeepPartial<Config> = {
    variables: {
      teamId: currentTeam?.id ?? '',
    },
    skip: !currentTeam?.id || !installedOnOrg || !installedOnTeam,
  };

  const finalConfig = (
    config ? deepMerge(baseConfig, config) : baseConfig
  ) as Config;

  const query = useInFlightWorkItemsQuery(finalConfig);

  const data =
    query.data?.inFlightWorkItems ??
    query.previousData?.inFlightWorkItems ??
    null;

  useEffect(() => {
    if (!query.error) return;
    const hasRateLimitError = query.error?.graphQLErrors.some(
      isExternalRateLimitError
    );
    if (hasRateLimitError) {
      log.error('InFlightWorkItems responded with rate limited', {
        error: query.error,
      });
      fireProjectManagementRateLimitLock();
    }
  }, [fireProjectManagementRateLimitLock, query.error]);

  return [data, query];
};
