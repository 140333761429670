import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useCurrentUser,
  useToast,
  getLivePollFromCache,
  TOAST_ERROR_GENERIC,
  isNullish,
  log,
} from '@spoke/common';
import {
  useSubmitLivePollAnswerMutation,
  SubmitLivePollAnswerMutation,
  LivePollUserStatus,
} from '@spoke/graphql';

export const useAnswerLivePollHandler = () => {
  const [answerLivePoll] = useSubmitLivePollAnswerMutation();
  const [currentUser] = useCurrentUser();
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const userId = currentUser?.id;

  const handleAnswerLivePoll = useCallback(
    async ({
      pollId,
      answer,
    }: {
      pollId: string;
      answer: number;
    }): Promise<boolean> => {
      if (!pollId || isNullish(answer) || !userId) {
        log.error('Missing critical data to submitLivePollAnswer', {
          pollId,
          answer,
          userId,
        });
        return false;
      }

      log.info('Submitting LivePoll answer', { pollId, answer });

      const pollFromCache = getLivePollFromCache({ cache, pollId });

      const newAnsweredIds = [
        ...(pollFromCache?.answeredUserIds || []),
        userId,
      ];

      const expected: SubmitLivePollAnswerMutation = {
        __typename: 'Mutation',
        submitLivePollAnswer: {
          ...(pollFromCache || {}),
          id: pollId,
          status: LivePollUserStatus.Answered,
          answeredUserIds: newAnsweredIds,
        },
      };

      const { errors } = await answerLivePoll({
        variables: { answer, livePollId: pollId },
        optimisticResponse: expected,
      });

      if (errors) {
        log.error('submitLivePollAnswer call responded with errors', {
          pollId,
          answer,
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [answerLivePoll, cache, toast, userId]
  );

  return [handleAnswerLivePoll];
};
