import { FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BsChevronDown } from 'react-icons/bs';
import { FaChevronLeft } from 'react-icons/fa';
import { useCreateTeamHandler } from '../../handlers';
import { useCurrentOrg, useTeamTree } from '../../queries';
import { TeamType, MinimalTeamFragmentFragment } from '@spoke/graphql';
import {
  SpokeFormRules,
  useToast,
  useDisclosure,
  TOAST_ERROR_GENERIC,
  callIfExists,
  truncateWithCount,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  HStack,
  Icon,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  MultiSelect,
  Button,
  TeamsIcon,
  Spacer,
  plural,
  log,
  Input,
  Text,
} from '@spoke/common';

type CreateProgramModalContentProps = {
  afterSubmit: () => void;
  onCancel: () => void;
};

export type CreateProgramFormSchema = {
  name: string;
};

const rules: SpokeFormRules<CreateProgramFormSchema> = {
  name: { required: 'Program name is required' },
};

export const CreateProgramModalContent: FC<CreateProgramModalContentProps> = ({
  afterSubmit,
  onCancel,
}) => {
  const [toast] = useToast();
  const [handleCreateProgram] = useCreateTeamHandler();
  const [currentOrg] = useCurrentOrg();
  const [{ teamTree }] = useTeamTree();

  const teams = teamTree
    ?.filter((treeNode) => treeNode.team.type === TeamType.Team)
    .map((treeNode) => treeNode.team);

  const teamsDropdown = useDisclosure();

  const [selectedChildrenTeams, setSelectedChildrenTeams] = useState<
    MinimalTeamFragmentFragment[]
  >([]);

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<CreateProgramFormSchema>();

  /*
   * This is not an actual search... We've always fetched all teams and filtered
   * them on the client.
   * TODO change this to actual backend search
   */
  const getTeamOptions = (term: string): MinimalTeamFragmentFragment[] =>
    teams
      ?.filter(Boolean)
      .filter((team) =>
        team.name?.toLowerCase().includes(term.toLowerCase())
      ) ?? [];

  const onSubmit = useCallback(
    async (form: CreateProgramFormSchema) => {
      const organizationId = currentOrg?.id;
      const { name } = form;

      if (!organizationId || !name) {
        log.error('Missing critical data to create team', {
          organizationId,
          name,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      log.info('Handling CreateProgram submit', { form });

      const success = await handleCreateProgram({
        organizationId,
        teamName: name,
        type: TeamType.Program,
        children: selectedChildrenTeams.map((t) => t.id as string),
      });

      if (success) callIfExists(afterSubmit);
    },
    [
      afterSubmit,
      currentOrg?.id,
      handleCreateProgram,
      selectedChildrenTeams,
      toast,
    ]
  );

  const teamsLabel =
    truncateWithCount(selectedChildrenTeams, 'name', 55) || 'Select teams';

  return (
    <ModalContent p={8} pb={4} maxW={600}>
      <ModalCloseButton />
      <ModalBody p={0}>
        <ModalHeader p={0}>
          <HStack>
            <Icon w={6} h={6} as={AiOutlineUsergroupAdd} />
            <Heading mb={1} fontSize={24} color="gray.900">
              Create Program
            </Heading>
          </HStack>
          <Text fontSize={14} fontWeight={400} color="gray.500" mt={1}>
            Create a group of teams to run scaled retrospectives
          </Text>
        </ModalHeader>
        <Flex
          flexDir="column"
          gap={2}
          mt={4}
          as="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormControl isInvalid={Boolean(errors.name)}>
            <FormLabel fontSize={15} htmlFor="name">
              Program name
            </FormLabel>
            <Input
              id="title"
              placeholder="Enter program name"
              minH={10}
              alignItems="center"
              autoFocus
              {...register('name', rules.name)}
            />
            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl>
            <FormLabel fontSize={15} htmlFor="teams">
              Select teams to join program
            </FormLabel>
            <MultiSelect
              searchable
              values={selectedChildrenTeams}
              onChange={setSelectedChildrenTeams}
              onClose={teamsDropdown.close}
              isOpen={teamsDropdown.isOpen}
              getOptions={getTeamOptions}
              idKey="id"
              labelKeyOrFn="name"
            >
              <Button
                justifyContent="start"
                onClick={teamsDropdown.toggle}
                variant="outlineGray"
                leftIcon={<Icon as={TeamsIcon} />}
                w="full"
              >
                {teamsLabel}
                <Spacer />
                <Icon as={BsChevronDown} />
              </Button>
            </MultiSelect>
          </FormControl>
          <HStack mb={2} mt={3} justifyContent="flex-end">
            <Button
              size="lg"
              variant="outlineGray"
              type="submit"
              w="fit-content"
              disabled={isSubmitting}
              onClick={onCancel}
              leftIcon={
                <Icon as={FaChevronLeft} pb="2px" color="gray.600" mr="-4px" />
              }
            >
              Go back
            </Button>
            <Button
              size="lg"
              w="fit-content"
              isLoading={isSubmitting}
              type="submit"
            >
              Create Program
              {selectedChildrenTeams.length > 0 &&
                ` with ${selectedChildrenTeams.length} ${plural(
                  'team',
                  selectedChildrenTeams.length
                )}`}
            </Button>
          </HStack>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};
