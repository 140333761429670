import { useCurrentOrg } from './useCurrentOrg';
import { QueryConfig, DeepPartial, deepMerge } from '@spoke/common';
import { useBillingDetailsQuery } from '@spoke/graphql';

type Config = QueryConfig<typeof useBillingDetailsQuery>;
type QueryRef = ReturnType<typeof useBillingDetailsQuery>;
export type BillingDetailsQueryData = NonNullable<
  NonNullable<QueryRef>['data']
>['billingDetails'];

export const useBillingDetails = (
  config?: DeepPartial<Config>
): [BillingDetailsQueryData | null, QueryRef] => {
  const [org] = useCurrentOrg();

  const baseConfig: DeepPartial<Config> = {
    variables: {
      organizationId: org?.id ?? '',
    },
    skip: !org?.id,
  };

  const finalConfig = (
    config ? deepMerge(baseConfig, config) : baseConfig
  ) as Config;

  const query = useBillingDetailsQuery(finalConfig);

  const data =
    query.data?.billingDetails ?? query.previousData?.billingDetails ?? null;

  return [data, query];
};
