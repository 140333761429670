import {
  ComponentStyleConfig,
  Checkbox as ChakraCheckbox,
  CheckboxProps as ChakraCheckboxProps,
} from '@chakra-ui/react';
import { FC } from 'react';

export const CheckboxComponentStyle: ComponentStyleConfig = {};

type Props = ChakraCheckboxProps;

export const Checkbox: FC<Props> = (props) => <ChakraCheckbox {...props} />;
