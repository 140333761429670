import { useRouter } from 'next/router';
import { FC } from 'react';
import { useCurrentOrg, useCurrentOrgMemberships } from '../queries';
import {
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  HStack,
  Heading,
  FREE_MAX_USER_COUNT,
  ModalBody,
  ModalProps,
  Modal,
  ModalOverlay,
  Text,
  Link,
  useRoutes,
} from '@spoke/common';

export const OverFreeLimitModalContent = () => {
  const router = useRouter();
  const routes = useRoutes();
  const [currentOrg] = useCurrentOrg();
  const [{ totalUserCount }] = useCurrentOrgMemberships({
    variables: { limit: 0 },
  });

  return (
    <ModalContent p={8} pb={4} maxW={700}>
      <ModalCloseButton />
      <ModalHeader p={0} mb={4}>
        <HStack>
          <Heading mb={1} fontSize={24} color="gray.900">
            The free plan allows for a max of {FREE_MAX_USER_COUNT} team members
          </Heading>
        </HStack>
      </ModalHeader>
      <ModalBody p={0}>
        <Text mb={3}>
          <Text color="primary.500" fontWeight={500} as="span">
            {currentOrg?.name}
          </Text>{' '}
          has a total of{' '}
          <Text color="primary.500" fontWeight={500} as="span">
            {totalUserCount}
          </Text>{' '}
          team members. You have two options to continue using ScatterSpoke:
        </Text>

        <Text color="primary.500" fontWeight={500}>
          Option 1: Switch to a Business Plan
        </Text>
        <Text mb={3}>
          If you don&apos;t want to remove any members from your organization,
          consider{' '}
          <Link
            textDecor="underline"
            color="primary.500"
            fontWeight={500}
            onClick={() =>
              router.push(`${routes.Billing}?openPricingModal=true`)
            }
          >
            upgrading to a Business Plan
          </Link>
          .
        </Text>

        <Text color="primary.500" fontWeight={500}>
          Option 2: Remove Members
        </Text>
        <Text>
          If you prefer not to upgrade to a Business Plan, you will need to
          remove some members from your organization to stay within the allowed
          limit for the free plan. You can manage your team member count in{' '}
          <Link
            textDecor="underline"
            color="primary.500"
            fontWeight={500}
            onClick={() => router.push(routes.Users)}
          >
            organization settings
          </Link>
          .
        </Text>
      </ModalBody>
    </ModalContent>
  );
};

type OverFreeLimitInnerModalProps = Omit<ModalProps, 'children'>;
export const OverFreeLimitModal: FC<OverFreeLimitInnerModalProps> = (props) => (
  <Modal {...props}>
    <ModalOverlay />
    <OverFreeLimitModalContent />
  </Modal>
);
