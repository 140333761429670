import { IconProps, Icon } from '../Icon';

export const TrelloIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" {...props}>
    <defs>
      <linearGradient
        id="linearGradient-1"
        x1="50.032%"
        x2="50.032%"
        y1="100.016%"
        y2="0%"
      >
        <stop offset="18%" stopColor="#0052CC" />
        <stop offset="100%" stopColor="#2684FF" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g
        fill="url(#linearGradient-1)"
        fillRule="nonzero"
        transform="translate(-1258 -5049)"
      >
        <g transform="translate(0 3938)">
          <g transform="translate(250 290)">
            <g transform="translate(600)">
              <g transform="translate(64 774)">
                <g transform="translate(0 29)">
                  <g transform="translate(344 18)">
                    <g>
                      <path d="M24.516.223H3.502C2.452.223 1.51.68.867 1.4.327 2.007 0 2.801 0 3.67v20.686c0 1.904 1.568 3.447 3.502 3.447h21.014c1.927-.01 3.484-1.55 3.484-3.447V3.67C28 1.773 26.443.233 24.516.223zm-12.33 20.89c-.01.907-.758 1.637-1.68 1.637H5.334c-.924.005-1.679-.726-1.689-1.636V5.464c0-.44.179-.862.496-1.172.317-.31.746-.484 1.193-.481h5.174c.928 0 1.68.74 1.68 1.653v15.65zm12.192-6.894c0 .913-.752 1.654-1.68 1.654H17.51c-.928 0-1.68-.74-1.68-1.654v-8.75a1.64 1.64 0 01.49-1.172 1.694 1.694 0 011.19-.486h5.187c.928 0 1.68.74 1.68 1.653v8.755z" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
