import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentBoardId,
  TOAST_ERROR_GENERIC,
  getBoardFromCache,
  getCardFromCache,
  optimisticUpdateBoard,
  log,
} from '@spoke/common';
import {
  useSetBoardCardBeingDiscussedMutation,
  CardDiscussionMovement,
} from '@spoke/graphql';

export const useSelectDiscussCardHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [boardId] = useCurrentBoardId();
  const [setBoardCardBeingDiscussed] = useSetBoardCardBeingDiscussedMutation();

  const handleSelectDiscussCard = useCallback(
    async (
      cardId: string,
      moveCurrentCardTo: CardDiscussionMovement = CardDiscussionMovement.ToDiscussed
    ) => {
      if (!boardId) {
        log.error('Cannot select discussed card without loaded boardId', {
          cardId,
          boardId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const board = getBoardFromCache({ boardId, cache });

      if (!board?.cardBeingDiscussed) {
        log.error('Cannot select discussed card: cached board not found', {
          cardId,
          board,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const card = getCardFromCache({ cardId, cache });

      const moveCurrentCard: CardDiscussionMovement = moveCurrentCardTo;

      // TODO this will not handle mutation failures.. Ideally we update this using
      // a proper apollo optimistic update approach but couldn't find one yet.
      // optimisticUpdateBoard is pulling this card from cache and this reverts any other
      // changes we make here. That is why this is being modified directly
      cache.modify({
        id: `Card:${board.cardBeingDiscussed.id}`,
        fields: {
          wasDiscussed: () =>
            moveCurrentCard === CardDiscussionMovement.ToDiscussed,
        },
      });

      const expected = optimisticUpdateBoard({
        boardId: board.id,
        fields: {
          cardBeingDiscussed: card,
          discussReadyUserIds: [],
        },
        cache,
      });

      await setBoardCardBeingDiscussed({
        variables: { boardId: board?.id, cardId, moveCurrentCard },
        optimisticResponse: !expected
          ? undefined
          : {
              __typename: 'Mutation',
              setBoardCardBeingDiscussed: expected,
            },
      });
    },
    [boardId, cache, toast, setBoardCardBeingDiscussed]
  );

  return [handleSelectDiscussCard];
};
