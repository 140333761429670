import { IconProps, Icon } from '../Icon';
export const FedexLogo = (props: IconProps) => (
  <Icon viewBox="0 0 73 22" {...props}>
    <path
      d="M59.9546 7.77344L62.9326 11.0554L65.7996 7.77344H71.9216L65.9386 14.5014L72.0036 21.2844H65.6346L62.6846 17.9764L59.7616 21.2844H53.6116L59.6236 14.5294L53.6116 7.77344H59.9546Z"
      fill="#919CAB"
    />
    <path
      d="M53.6121 7.772V12.337H46.8031V16.526H53.6121V21.283H41.7991V0H53.6121V4.744H46.8031V7.772H53.6121Z"
      fill="#919CAB"
    />
    <path
      d="M36.8171 0V8.71H36.7621C35.6581 7.442 34.2801 7.001 32.6811 7.001C29.4051 7.001 26.9371 9.229 26.0711 12.173C25.0821 8.929 22.5341 6.94 18.7561 6.94C15.6881 6.94 13.2651 8.317 12.0011 10.561V7.772H5.6591V4.744H12.5801V0H0.00610352V21.283H5.6591V12.337H11.2941C11.1261 12.994 11.0361 13.698 11.0361 14.441C11.0361 18.88 14.4281 21.996 18.7561 21.996C22.3961 21.996 24.7951 20.287 26.0631 17.172H21.2181C20.5631 18.109 20.0661 18.386 18.7561 18.386C17.2371 18.386 15.9271 17.061 15.9271 15.49H25.7921C26.2201 19.016 28.9671 22.057 32.7361 22.057C34.3621 22.057 35.8511 21.257 36.7611 19.907H36.8161V21.285H41.7991V0H36.8171ZM16.0851 12.4C16.3991 11.048 17.4481 10.165 18.7571 10.165C20.1981 10.165 21.1931 11.021 21.4551 12.4C21.5651 12.4 16.0851 12.4 16.0851 12.4ZM33.7921 18.043C31.9551 18.043 30.8131 16.331 30.8131 14.544C30.8131 12.634 31.8061 10.797 33.7921 10.797C35.8511 10.797 36.6711 12.634 36.6711 14.544C36.6711 16.355 35.8021 18.043 33.7921 18.043Z"
      fill="#727E8F"
    />
  </Icon>
);
