import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentBoardId,
  TOAST_ERROR_GENERIC,
  getBoardFromCache,
  BOARD_STAGES_ORDER,
  DeepPartial,
  optimisticUpdateBoard,
  log,
} from '@spoke/common';
import { useUpdateBoardMutation, BoardStage, Board } from '@spoke/graphql';

export const useChangeBoardStageHandler = () => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [boardId] = useCurrentBoardId();
  const [updateBoard] = useUpdateBoardMutation();

  const handleChangeBoardStage = useCallback(
    async (stage: BoardStage | 'NEXT') => {
      if (!boardId) {
        log.error('Cannot update board stage without loaded boardId', {
          stage,
          boardId,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const board = getBoardFromCache({ boardId, cache });

      if (!board?.state?.stage) {
        log.error('Cannot update board stage: cached board not found', {
          stage,
          board,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      if (board?.state?.stage === stage) return;

      log.info(`Changing board stage to ${stage}`, { boardId, board, stage });

      const currentStageIdx = BOARD_STAGES_ORDER.indexOf(board?.state?.stage);
      if (stage === 'NEXT') stage = BOARD_STAGES_ORDER[currentStageIdx + 1];

      const updateBoardFields: DeepPartial<Board> = { state: { stage } };
      const expected = optimisticUpdateBoard({
        boardId: board.id,
        fields: updateBoardFields,
        cache,
      });

      await updateBoard({
        variables: { boardId: board?.id, fields: updateBoardFields },
        optimisticResponse: {
          __typename: 'Mutation',
          updateBoard: expected,
        },
      });
    },
    [boardId, cache, toast, updateBoard]
  );

  return [handleChangeBoardStage];
};
