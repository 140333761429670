import {
  ApolloCache,
  ApolloLink,
  from,
  HttpLink,
  makeVar,
  split,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { onError as onErrorApolloLink } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { UseToastOptions } from '@chakra-ui/react';
import { createClient } from 'graphql-ws';
import { isLoggedOutError, isServer, log } from '@spoke/common';

export const APP_NAME = 'web';

/**
 * Preventing sync external calls or heavy queries from holding off lighter ones
 */
const QUERIES_TO_SKIP_BATCHING: string[] = [
  'FlowMetrics',
  'SourceControlMetrics',
  'TeamMetricsOverTime',
  'InFlightWorkItems',

  'LinkableJiraBoards',
  'LinkableGithubRepositories',
  'LinkableBitbucketRepositories',

  'LinkedJiraBoards',
  'LinkedGithubRepositories',
  'LinkedBitbucketRepositories',
];

const TOAST_NETWORK_ERROR: UseToastOptions = {
  status: 'error',
  title: 'Connection error',
  description:
    'We are having trouble connecting to the internet. Please check your connection.',
  isClosable: true,
  duration: 8000,
};

const APP_NAME_HEADER = 'spk-app-name';
const APP_VERSION_HEADER = 'spk-app-version';
const APP_UPDATE_HEADER = 'spk-update';
const APP_POLLING_HEADER = 'spk-polling';

const TEN_SECONDS_MS = 10000;
const WS_RECONNECTION_ATTEMPTS = 1000;

export const wsBlockedVar = makeVar<boolean>(false);
const wsConnectedOnceVar = makeVar<boolean>(false);

export const appMustUpdateVar = makeVar<boolean>(false);
export const lastUserKickMsVar = makeVar<number>(0);

export const getApolloClientLink = ({
  toast,
}: { toast?: (cfg: UseToastOptions) => void } = {}): ApolloLink => {
  if (!isServer()) {
    setTimeout(() => {
      if (wsConnectedOnceVar()) return;
      wsBlockedVar(true);
      log.error('WebSockets are disabled. Falling back to polling.');
    }, TEN_SECONDS_MS);
  }

  const wsClient = !isServer()
    ? createClient({
        url: `${process.env.NEXT_PUBLIC_API_WS_URL}/graphql`,
        lazy: false,
        retryAttempts: WS_RECONNECTION_ATTEMPTS,
        retryWait: (count) =>
          new Promise((r) => setTimeout(r, Math.min(1000 * count, 30000))),
        on: {
          connected: () => {
            wsConnectedOnceVar(true);
            log.info('WebSocket client connected.');
          },
          closed: () => log.warn('WebSocket client disconnected.'),
        },
      })
    : null;

  const batchHttpLink = new BatchHttpLink({
    uri: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
    batchMax: 5,
    batchInterval: 20,
    credentials: 'include',
  });

  const nonBatchHttpLink = new HttpLink({
    uri: `${process.env.NEXT_PUBLIC_API_URL}/graphql`,
    credentials: 'include',
  });

  const httpLink = split(
    (op) =>
      op.operationName === 'IntrospectionQuery' ||
      QUERIES_TO_SKIP_BATCHING.includes(op.operationName),
    nonBatchHttpLink,
    batchHttpLink
  );

  // Not needed but leaving here for future reference
  // const retryLink = new RetryLink({
  //   attempts: { max: Number.MAX_SAFE_INTEGER },
  //   delay: { initial: 2000, max: 4000 },
  // });

  const errorLink = onErrorApolloLink(({ networkError }) => {
    if (networkError) {
      log.warn('Apollo network error', networkError);
      toast?.(TOAST_NETWORK_ERROR);
    }
  });

  const versionLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        [APP_NAME_HEADER]: APP_NAME,
        [APP_VERSION_HEADER]: process.env.NEXT_PUBLIC_APP_VERSION,
        [APP_POLLING_HEADER]: `${wsBlockedVar()}`,
      },
    }));

    return forward(operation).map((data) => {
      const spkUpdateHeader = operation
        .getContext()
        .response?.headers?.get(APP_UPDATE_HEADER);
      const mustUpdateApp = spkUpdateHeader === 'true';
      if (mustUpdateApp) appMustUpdateVar(true);
      return data;
    });
  });

  const logoutLink = new ApolloLink((operation, forward) =>
    forward(operation).map((data) => {
      if (!data.errors?.length) return data;
      const hasLoggedOutError = data.errors.some(isLoggedOutError);
      if (!hasLoggedOutError) return data;
      log.warn(
        'Received "Not logged in" as response. Resetting Apollo cache.',
        { data, operation }
      );
      const cache = operation.getContext()?.cache as ApolloCache<object>;
      if (!cache) return data;
      const tenSecondsAgoMs = Date.now() - TEN_SECONDS_MS;
      // When the cache is reset, all queries that stay mounted will be refetched
      // If they respond with 401s again, we get into an endless loop. This is to break it
      const userHasBeenKickedRecently = lastUserKickMsVar() >= tenSecondsAgoMs;
      if (userHasBeenKickedRecently) return data;
      lastUserKickMsVar(Date.now());
      cache.reset();
      data.errors = undefined; // Prevents additional handling from happenning, such as toasts
      return data;
    })
  );

  const wsLink = wsClient ? new GraphQLWsLink(wsClient) : null;

  // https://www.apollographql.com/docs/react/data/subscriptions
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink || httpLink,
    httpLink
  );

  return from([versionLink, logoutLink, errorLink, splitLink]);
};
