import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  useToast,
  useCurrentBoardId,
  useCurrentUser,
  DeepPartial,
  optimisticUpdateBoard,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import {
  useUpdateTeamMutation,
  useUpdateBoardMutation,
  Board,
  Team,
} from '@spoke/graphql';

type UseToggleGuidedProcessHandlerArgs = {
  boardTeamId: string;
  isGuided: boolean;
};
export const useToggleGuidedProcessHandler = ({
  boardTeamId,
  isGuided,
}: UseToggleGuidedProcessHandlerArgs) => {
  const [toast] = useToast();
  const { cache } = useApolloClient();

  const [boardId] = useCurrentBoardId();
  const [currentUser] = useCurrentUser();

  const [updateTeam] = useUpdateTeamMutation();
  const [updateBoard] = useUpdateBoardMutation();

  const toggleGuidedProcess = useCallback(
    async (rememberChoice: boolean = false) => {
      const newGuided = !isGuided;
      const updateBoardFields: DeepPartial<Board> = {
        state: { isGuided: newGuided },
      };

      if (!boardId) {
        log.error('Missing critical data to toggle board guided/unguided', {
          boardId,
          newGuided,
        });
        return;
      }

      const expected = optimisticUpdateBoard({
        cache,
        boardId,
        fields: updateBoardFields,
      });

      const updateBoardResult = await updateBoard({
        variables: { boardId, fields: updateBoardFields },
        optimisticResponse: { __typename: 'Mutation', updateBoard: expected },
      });

      if (updateBoardResult.errors) {
        log.error('Toggle board guided/unguided responded with error', {
          boardId,
          updateBoardFields,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const shouldUpdateTeamDefaultGuided = Boolean(rememberChoice);

      if (shouldUpdateTeamDefaultGuided) {
        const userId = currentUser?.id;
        const teamId = boardTeamId;
        const updateTeamFields: DeepPartial<Team> = {
          settings: { defaultGuidedRetrospectives: newGuided },
        };

        if (!userId || !teamId) {
          log.error(
            'Missing critical data to update team default guided settings',
            { userId, teamId }
          );
          return;
        }

        const updateTeamResult = await updateTeam({
          variables: { userId, teamId, fields: updateTeamFields },
        });

        if (updateTeamResult.errors) {
          log.error(
            'Update team default guided settings responded with error',
            {
              userId,
              teamId,
              updateTeamFields,
              errors: updateTeamResult.errors,
              data: updateTeamResult.data,
            }
          );
          return;
        }
      }
    },
    [
      boardId,
      boardTeamId,
      cache,
      currentUser?.id,
      isGuided,
      toast,
      updateBoard,
      updateTeam,
    ]
  );

  return [toggleGuidedProcess];
};
