import { MutationUpdaterFn } from '@apollo/client';
import { log } from '../../../../SpkLog';
import { CacheModifiers } from '../../../../types/etc/CacheModifiers';
import { DeepPartial } from '../../../../types/etc/DeepPartial';
import { generateTempId } from '../../generateTempId';
import { ActionItem, CreateActionItemCommentMutation } from '@spoke/graphql';

type OptimisticCreateActionItemCommentArgs = {
  actionItemId: string;
  text: string;
  authorName: string;
};
export const optimisticCreateActionItemComment = ({
  authorName,
  actionItemId,
  text,
}: OptimisticCreateActionItemCommentArgs): CreateActionItemCommentMutation => ({
  __typename: 'Mutation',
  createActionItemComment: {
    __typename: 'ActionItemComment',
    id: generateTempId(),
    actionItemId,
    text,
    archived: false,
    createdAt: Date.now(),
    author: { __typename: 'User', id: '', name: authorName },
  },
});

export const createActionItemCommentUpdateFunction: MutationUpdaterFn<
  CreateActionItemCommentMutation
> = (cache, { data }) => {
  const newComment = data?.createActionItemComment;

  if (!newComment) {
    log.error('No comment received in createCommentUpdateFunction', {
      newComment,
    });
    return;
  }

  const actionItemCacheId = 'ActionItem:' + newComment?.actionItemId;

  const updateFields: CacheModifiers<DeepPartial<ActionItem>> = {
    comments: (prev = []) => {
      if (Array.isArray(prev)) return [...prev, newComment];
      return [newComment];
    },
  };

  cache.modify({
    id: actionItemCacheId,
    fields: updateFields,
  });
};
