import {
  MenuButton as ChakraMenuButton,
  MenuButtonProps as ChakraMenuButtonProps,
} from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

export type MenuButtonProps = ChakraMenuButtonProps & { leftIcon?: ReactNode };

export const MenuButton: FC<MenuButtonProps & { variant?: string }> = ({
  variant,
  leftIcon,
  ...props
}) => (
  <ChakraMenuButton
    {...(leftIcon ? { leftIcon } : {})}
    {...(variant ? { variant } : {})}
    {...props}
  />
);
