import { deepClone, noneAreNullish, isNullish } from '../../../etc';
import { log } from '../../../../SpkLog';
import { flattenListsCards } from '../../../spoke';
import { DeepPartial } from '../../../../types';
import { Board, Card, List } from '@spoke/graphql';

type HandleSameListMovementArgs = {
  board: DeepPartial<Board>;
  card: DeepPartial<Card>;
  list: DeepPartial<List>;
  fromIndex: number;
  toIndex: number;
};
function handleSameListMovement({
  board,
  card,
  list,
  fromIndex,
  toIndex,
}: HandleSameListMovementArgs): DeepPartial<Board> {
  const newList = deepClone(list);
  newList.cards?.splice(fromIndex, 1);
  newList.cards?.splice(toIndex, 0, card);

  const newBoard = deepClone(board);
  newBoard.lists = newBoard.lists?.map((_list) => {
    if (_list?.id === newList.id) return newList;
    return _list;
  });

  return newBoard;
}

type HandleMovementBetweenListsArgs = {
  board: DeepPartial<Board>;
  listFrom: DeepPartial<List>;
  listTo: DeepPartial<List>;
  card: DeepPartial<Card>;
  toIndex: number;
};
function handleMovementBetweenLists({
  board,
  card,
  listFrom,
  listTo,
  toIndex,
}: HandleMovementBetweenListsArgs) {
  const newCard = deepClone(card);
  const newListFrom = deepClone(listFrom);
  const newListTo = deepClone(listTo);

  newListFrom.cards = listFrom.cards?.filter(
    (_card) => _card?.id !== newCard.id
  );
  newCard.listId = newListTo.id;
  newListTo.cards?.splice(toIndex, 0, newCard);

  const newBoard = { ...board };
  newBoard.lists = newBoard.lists?.map((list) => {
    if (list?.id === listFrom.id) return newListFrom;
    if (list?.id === listTo.id) return newListTo;
    return list;
  });

  return newBoard;
}

type HandleRemoveCardFromGroupArgs = {
  board: DeepPartial<Board>;
  card: DeepPartial<Card>;
  listTo: DeepPartial<List>;
  toIndex: number;
};
const handleRemoveCardFromGroup = ({
  board,
  card,
  listTo,
  toIndex,
}: HandleRemoveCardFromGroupArgs) => {
  const newBoard = deepClone(board);
  const newCard = deepClone(card);

  const parentId = card.parentId;

  const listToIdx = newBoard.lists?.findIndex(
    (_list) => _list?.id === listTo.id
  ) as number;

  const groupCardListIdx = board.lists?.findIndex((list) =>
    list?.cards?.some((_card) => _card?.id === parentId)
  ) as number;

  const groupCardIdx = board.lists?.[groupCardListIdx]?.cards?.findIndex(
    (_card) => _card?.id === parentId
  ) as number;

  if (
    !noneAreNullish(listToIdx, groupCardListIdx, groupCardIdx) ||
    listToIdx < 0 ||
    groupCardListIdx < 0 ||
    groupCardIdx < 0 ||
    !newBoard.lists?.[groupCardListIdx]?.cards?.[groupCardIdx]?.children
  ) {
    log.error('Could not perform removeCardFromGroup optimistic update', {
      lists: newBoard.lists,
      listToIdx,
      groupCardListIdx,
      groupCardIdx,
    });
    return board;
  }

  newBoard.lists![groupCardListIdx]!.cards![groupCardIdx]!.children =
    newBoard.lists[groupCardListIdx]?.cards?.[groupCardIdx]?.children?.filter(
      (_card) => _card?.id !== card.id
    );

  newCard.parentId = null;

  // Currently we're not fetching these for children
  // This is backfilling so Apollo won't complain
  newCard.wasDiscussed = false;

  newBoard.lists![listToIdx]!.cards?.splice(toIndex, 0, newCard);

  return newBoard;
};

export type OptimisticMoveCardIntoListArgs<T extends DeepPartial<Board>> = {
  board: T;
  cardId: string;
  fromId: string;
  toId: string;
  toIndex: number;
};
export const optimisticMoveCardIntoList = <T extends DeepPartial<Board>>({
  board,
  cardId,
  fromId,
  toId,
  toIndex,
}: OptimisticMoveCardIntoListArgs<T>): T => {
  const listTo = board.lists?.find((list) => list?.id === toId);

  const isLeavingGroup = fromId.includes(':childrenOf');
  if (isLeavingGroup) {
    const groupCardId = fromId.split(':')[2];
    const groupCard = flattenListsCards(board.lists as List[]).find(
      (_card) => _card.id === groupCardId
    );
    const card = groupCard?.children?.find(
      (_card) => _card?.id === cardId
    ) as Card;

    if (!groupCard || !card) {
      log.error(
        'Cannot perform MoveCardIntoList (leaving group) optimistic update. Missing critical data',
        { fromId, groupCard, card, lists: board.lists }
      );
      return board;
    }

    return handleRemoveCardFromGroup({
      board,
      card,
      listTo: listTo as List,
      toIndex,
    }) as T;
  }

  const listFrom = board.lists?.find((list) => list?.id === fromId);
  const card = listFrom?.cards?.find((_card) => _card?.id === cardId);
  const fromIndex = listFrom?.cards?.findIndex((_card) => _card?.id === cardId);

  if (
    !listFrom ||
    !listTo ||
    !card ||
    isNullish(fromIndex) ||
    isNullish(toIndex)
  ) {
    log.error(
      'Cannot perform MoveCardIntoList optimistic update. Missing critical data',
      { cardId, fromId, toId, toIndex, board, listFrom, listTo, card }
    );
    return board;
  }

  if (listFrom.id === listTo.id) {
    return handleSameListMovement({
      board,
      list: listFrom,
      card,
      fromIndex: fromIndex as number,
      toIndex,
    }) as T;
  } else {
    return handleMovementBetweenLists({
      board,
      card,
      listFrom,
      listTo,
      toIndex,
    }) as T;
  }
};
