import { Flex, Heading, Image, Text, useAssets } from '@spoke/common';

export const NoCardsMessage = () => {
  const { illustrationPlans } = useAssets();

  return (
    <Flex
      flex={1}
      direction="column"
      h="full"
      minH={700}
      alignItems="center"
      justifyContent="center"
    >
      <Image
        src={illustrationPlans}
        alt="This board does not have any cards"
        w={270}
        mb={4}
      />
      <Heading mb={2} fontSize={22} fontWeight={500} color="primary.600">
        No cards to show here...
      </Heading>
      <Text fontSize={16} color="gray.600" textAlign="center">
        Try switching back to{' '}
        <Text as="span" display="inline" fontWeight={500}>
          Reflect Phase
        </Text>{' '}
        and adding some cards!
      </Text>
    </Flex>
  );
};
