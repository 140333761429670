import {
  Flex as ChakraFlex,
  StackProps as ChakraFlexProps,
} from '@chakra-ui/react';
import { FC, forwardRef, RefAttributes } from 'react';
import { motion, MotionProps } from 'framer-motion';

export type FlexProps = ChakraFlexProps & {
  variant?: string;
};

export const Flex = forwardRef<HTMLDivElement, FlexProps>((props, ref) => (
  <ChakraFlex ref={ref} {...props} />
));

export type MotionFlexProps = Omit<FlexProps, 'transition'> &
  MotionProps &
  RefAttributes<HTMLDivElement>;
export const MotionFlex = motion(Flex) as FC<MotionFlexProps>;
