export const groupBy = <T, K extends keyof T>(
  arr: T[],
  keyOrKeyFn: ((entry: T) => string | number) | K
): Record<string, T[]> => {
  const map: Record<string | number | symbol, T[]> = {};

  const keyFn =
    typeof keyOrKeyFn === 'function'
      ? keyOrKeyFn
      : (entry: T) => entry[keyOrKeyFn] as unknown as string;

  for (const entry of arr) {
    const key = keyFn(entry);
    if (!map[key]) map[key] = [] as T[];
    map[key].push(entry);
  }

  return map;
};
