import { makeVar, useReactiveVar } from '@apollo/client';
import { isServer } from '@spoke/common';

const localStorage = isServer() ? null : window.localStorage;

const DASHBOARD_SIDEBAR_COLLAPSED = 'DASHBOARD_SIDEBAR_COLLAPSED';

export const dashboardSidebarIsCollapsedVar = makeVar<boolean>(
  localStorage?.getItem(DASHBOARD_SIDEBAR_COLLAPSED) === 'true'
);

export const setDashboardSidebarIsCollapsed = (newIsCollapsed: boolean) => {
  localStorage?.setItem(DASHBOARD_SIDEBAR_COLLAPSED, `${newIsCollapsed}`);
  dashboardSidebarIsCollapsedVar(newIsCollapsed);
};

export const useDashboardSidebarState = () => ({
  sidebarIsCollapsed: useReactiveVar(dashboardSidebarIsCollapsedVar),
});
