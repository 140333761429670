import { ChakraProps, ComponentStyleConfig } from '@chakra-ui/react';

import { useStyleConfig } from '@chakra-ui/react';
import { FC } from 'react';
import { Button, ButtonProps } from './Button';
import { HStack } from './Stack/HStack';
import { Icon } from './Icon';

export type SpkButtonGroupElement = {
  key: string;
  label?: string;
  Icon?: FC;
  iconW?: number;
  iconH?: number;
};

export type SpkButtonGroupProps = ChakraProps & {
  elements: SpkButtonGroupElement[];
  selectedElementKey: string;
  onSelect: (key: string) => unknown;
  size?: Size;
};

export const SpkButtonGroupComponentStyle: ComponentStyleConfig = {
  baseStyle: {},
};

type Size = 'md' | 'sm';

const HEIGHT_BY_SIZE: Record<Size, number> = {
  md: 12,
  sm: 10,
};

const FONT_SIZE_BY_SIZE: Record<Size, number> = {
  md: 15,
  sm: 14,
};

export const SpkButtonGroup: FC<
  SpkButtonGroupProps & {
    contentStyle?: ButtonProps;
  }
> = (props) => {
  const {
    variant,
    contentStyle,
    elements,
    selectedElementKey,
    onSelect,
    size = 'md',
    ...rest
  } = props as SpkButtonGroupProps & {
    variant: unknown;
    contentStyle: ButtonProps;
  }; // TODO figure out proper chakra way

  const styles = useStyleConfig('SpkButtonGroup', { variant, size });

  const fontSize = FONT_SIZE_BY_SIZE[size];
  const height = HEIGHT_BY_SIZE[size];

  return (
    <HStack
      __css={styles}
      h={height}
      bg="gray.100"
      p="2px"
      borderRadius="md"
      {...rest}
    >
      {elements.map((element) => {
        const { label, key, Icon: ElementIcon, iconW, iconH } = element;
        const isSelected = key === selectedElementKey;
        return (
          <Button
            key={key}
            onClick={() => onSelect(key)}
            variant="unstyled"
            leftIcon={
              ElementIcon ? (
                <Icon
                  as={ElementIcon}
                  w={iconW ? iconW : 5}
                  h={iconH ? iconH : 5}
                />
              ) : undefined
            }
            flex="1"
            display="flex"
            alignItems="center"
            h="93%"
            borderRadius="md"
            fontSize={fontSize}
            boxShadow={isSelected ? 'light' : 'none'}
            color={isSelected ? 'gray.900' : 'gray.500'}
            bg={isSelected ? 'white' : 'none'}
            outline="none"
            sx={{
              ':focus:not([data-focus-visible-added])': {
                boxShadow: isSelected ? 'md' : 'none',
              },
            }}
            {...contentStyle}
          >
            {label}
          </Button>
        );
      })}
    </HStack>
  );
};
