import { useBoardAnalytics } from '../../queries';
import {
  useCurrentUser,
  useToast,
  useNow,
  ONE_DAY_MS,
  TOAST_ERROR_GENERIC,
  BoardType,
  NO_OP,
  log,
} from '@spoke/common';
import {
  CreateBoardMutation,
  CreateWhiteboardMutation,
  AllRetroFormatsQuery,
  useCreateBoardMutation,
  useCreateWhiteboardMutation,
  BoardStage,
} from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

export type CreateRetroFormSchema = {
  name: string;
  type: BoardType;
  format: string;
  parkingLotItemIds?: string[] | null;
  startOnStage?: BoardStage;
  public?: boolean;
  query?: string;
};

export type CreateBoardHandler = (
  data: CreateRetroFormSchema
) => Promise<
  | CreateBoardMutation['createBoard']
  | CreateWhiteboardMutation['createWhiteboard']
  | null
>;

type UseCreateBoardHandlerProps = {
  retroFormats: AllRetroFormatsQuery['retrospectiveFormats'];
};
export const useCreateBoardHandler = ({
  retroFormats,
}: UseCreateBoardHandlerProps) => {
  const [currentUser] = useCurrentUser();
  const [currentTeam] = useCurrentTeam();
  const [createColumnBoard] = useCreateBoardMutation();
  const [createWhiteboard] = useCreateWhiteboardMutation();

  const [toast] = useToast();

  const now = useNow({ roundTo: 'ten-minutes' });

  const [_, boardAnalyticsQuery] = useBoardAnalytics({
    endDate: now,
    timeWindowLength: 90 * ONE_DAY_MS,
    recursive: false,
  });

  const handleCreateBoard: CreateBoardHandler = async (
    formData: CreateRetroFormSchema
  ): Promise<
    | CreateBoardMutation['createBoard']
    | CreateWhiteboardMutation['createWhiteboard']
    | null
  > => {
    if (!currentUser || !currentTeam) {
      log.error(
        'CreateRetro submit called without currentUser and currentTeam data',
        { currentUser, currentTeam, formData }
      );
      toast(TOAST_ERROR_GENERIC);
      return null;
    }

    log.info('Creating retrospective', formData);

    const selectedFormat = retroFormats?.find(
      (format) => formData.format === format?.slug
    );

    if (formData.type === BoardType.Column) {
      const { data, errors } = await createColumnBoard({
        variables: {
          name: formData.name,
          format: selectedFormat?.slug,
          listNames: selectedFormat?.columnNames, // This should live in the backend
          parkingLotItems: formData.parkingLotItemIds || [],
          startOnStage: formData.startOnStage,
          ownerId: currentUser?.id,
          teamId: currentTeam?.id as string,
          public:
            formData.public ||
            (currentTeam?.settings?.defaultPublicRetrospectives as boolean),
          guided: currentTeam?.settings?.defaultGuidedRetrospectives as boolean,
        },
      });

      if (errors) {
        log.error('CreateColumnBoard call responded with errors', errors);
        toast(TOAST_ERROR_GENERIC);
        return null;
      }

      boardAnalyticsQuery.refetch().catch(NO_OP);

      return data?.createBoard;
    } else if (formData.type === BoardType.Canvas) {
      const { data, errors } = await createWhiteboard({
        variables: {
          name: formData.name,
          format: formData.format,
          ownerId: currentUser.id as string,
          teamId: currentTeam.id as string,
          public: currentTeam.settings?.defaultPublicRetrospectives as boolean,
          guided: false, // There are no guided whiteboards..
        },
      });

      if (errors) {
        log.error('CreateWhiteboard call responded with errors', errors);
        toast(TOAST_ERROR_GENERIC);
        return null;
      }

      boardAnalyticsQuery.refetch().catch(NO_OP);

      return data?.createWhiteboard;
    }

    return null;
  };

  return [handleCreateBoard];
};
