import { MutationQueryReducer } from '@apollo/client';
import { log } from '../../../../SpkLog';
import { UpDown } from '../../../../types';
import { Card, VoteCardMutation } from '@spoke/graphql';

type OptimisticVoteCardArgs = {
  card?: Card | null;
  userId: string;
  upOrDown: UpDown;
};

export const optimisticVoteCard = ({
  card,
  userId,
  upOrDown,
}: OptimisticVoteCardArgs): VoteCardMutation | undefined => {
  if (!card?.id) {
    log.error('Missing critical data for card vote optimistic response', {
      id: card?.id,
    });

    return undefined;
  }

  if (upOrDown === UpDown.Up) {
    return {
      __typename: 'Mutation',
      voteCard: { ...card, votes: [...(card.votes ?? []), userId] },
    };
  } else {
    const voteIdx = card.votes?.findIndex(
      (voteUserId) => voteUserId === userId
    );
    const newVotes = [...(card.votes ?? [])];
    newVotes?.splice(voteIdx ?? 0, 1);
    return {
      __typename: 'Mutation',
      voteCard: { ...card, votes: newVotes },
    };
  }
};

type UpdateVoteCountByIdAndUserIdAfterVotingCardArgs = {
  upOrDown: UpDown;
};
// Ideally we would use a subscription to update this query. But it does not exist in backend
export const updateVoteCountByIdAndUserIdAfterVotingCard = ({
  upOrDown,
}: UpdateVoteCountByIdAndUserIdAfterVotingCardArgs): MutationQueryReducer<VoteCardMutation> => {
  const toAdd: number = upOrDown === UpDown.Up ? 1 : -1;
  const reducer: MutationQueryReducer<VoteCardMutation> = (prev) => ({
    ...prev,
    boardVoteCountByIdAndUserId: {
      ...prev.boardVoteCountByIdAndUserId,
      userVoteCount: prev.boardVoteCountByIdAndUserId.userVoteCount + toAdd,
    },
  });

  return reducer;
};
