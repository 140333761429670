import { Link } from '@chakra-ui/react';
import { FC, ReactElement } from 'react';
import { SparklineChart } from '@spoke/charts';
import {
  Icon,
  JiraIcon,
  GithubIcon,
  ONE_DAY_MS,
  Flex,
  formatWithGoalUnit,
  PercentChange,
  getIdealContrastForBackground,
  SpkTime,
  HStack,
  ICON_BY_SOURCE_CONTROL_ORIGIN,
  Spacer,
  Box,
  Tooltip,
  Text,
  ColorBadge,
} from '@spoke/common';
import {
  WorkItemOrigin,
  MetricsProviderCategory,
  Trend,
  ImprovementGoalTypeTargetType,
  TeamInsightFactCauseSolutionCauseOutlierWorkItems,
  TeamInsightFactCauseSolutionCauseOutlierPullRequests,
  TeamInsightFactCauseSolutionCauseUnreviewedPullRequests,
  TeamInsightFactCauseSolutionCause as TeamInsightCauseTypedef,
  TeamInsightFactCauseSolutionCauseTopic as TeamInsightCauseTopicTypedef,
  TeamInsightFactCauseSolutionCauseMetricDeterioration as TeamInsightFactCauseSolutionCauseMetricDeteriorationTypedef,
} from '@spoke/graphql';

type Props = {
  cause: TeamInsightCauseTypedef;
};
export const TeamInsightFactCauseSolutionCause: FC<Props> = ({ cause }) => {
  if (cause.__typename === 'TeamInsightFactCauseSolutionCauseTopic') {
    return <CauseTopic cause={cause} />;
  }

  if (
    cause.__typename === 'TeamInsightFactCauseSolutionCauseMetricDeterioration'
  ) {
    return <CauseMetricDeterioration cause={cause} />;
  }

  if (
    cause.__typename === 'TeamInsightFactCauseSolutionCauseOutlierWorkItems'
  ) {
    return <CauseWorkItems cause={cause} />;
  }

  if (
    cause.__typename === 'TeamInsightFactCauseSolutionCauseOutlierPullRequests'
  ) {
    return <CausePullRequests cause={cause} />;
  }

  if (
    cause.__typename ===
    'TeamInsightFactCauseSolutionCauseUnreviewedPullRequests'
  ) {
    return <CauseUnreviewedPullRequests cause={cause} />;
  }

  return null;
};

// TODO improve this abstraction with <WorkItemProviderBadge icon={} url={} slug={} />
const ICON_BY_WORK_ITEM_ORIGIN: Record<WorkItemOrigin, ReactElement> = {
  [WorkItemOrigin.Jira]: <Icon w={3} h={3} pt="2px" as={JiraIcon} />,
};

const ICON_BY_METRIC_PROVIDER_CATEGORY: Record<
  MetricsProviderCategory,
  ReactElement | null
> = {
  [MetricsProviderCategory.SourceControl]: <Icon pb="2px" as={GithubIcon} />,
  [MetricsProviderCategory.ProjectManagement]: <Icon pb="2px" as={JiraIcon} />,
  [MetricsProviderCategory.Scatterspoke]: null,
};
export const CauseMetricDeterioration: FC<{
  cause: TeamInsightFactCauseSolutionCauseMetricDeteriorationTypedef;
}> = ({ cause }) => {
  const { history, type } = cause;

  /**
   * @TODO once merged with bitbucket, extract useInsalledTeamIntegrations logic from metric boxes to a hook
   * and make this component render correct icons if multiple git providers
   */
  const icon = type.providerCategory
    ? ICON_BY_METRIC_PROVIDER_CATEGORY[type.providerCategory]
    : null;

  const firstDataPoint = history[0];
  const lastDataPoint = history[history.length - 1];
  const firstDataPointDate = new Date(firstDataPoint.date);
  const lastDataPointDate = new Date(lastDataPoint.date);
  const problemTimeSpanMs =
    lastDataPointDate.getTime() - firstDataPointDate.getTime();
  const problemTimeSpanDays = Math.ceil(problemTimeSpanMs / ONE_DAY_MS);
  const currentValue = lastDataPoint.value ?? 0;
  const previousValue = history[Math.max(history.length - 2, 0)]?.value ?? 0;
  const changeValue = Math.round(Math.abs(currentValue - previousValue));
  const problemVerb = type.ideal === Trend.Up ? 'decreased' : 'increased';

  const timeSeriesWithId = history.map((data, idx) => ({
    ...data,
    id: `${idx}`,
    date: new Date(data.date).toISOString(),
  }));

  return (
    <Flex
      w="full"
      flexDir="column"
      mx="auto"
      justifyContent="center"
      alignItems="center"
      gap={2}
      h="full"
    >
      <Text textAlign="center" maxW={280} fontSize={18} lineHeight="26px">
        Your{' '}
        <Text as="span" fontWeight={500}>
          {icon} {type.name}
        </Text>{' '}
        {problemVerb} by{' '}
        <Text as="span" fontWeight={500}>
          {formatWithGoalUnit(changeValue, type.unit)}
        </Text>{' '}
        within the past{' '}
        <Text as="span" fontWeight={500}>
          {problemTimeSpanDays} days
        </Text>
        .
      </Text>
      <Flex gap={1} alignItems="center">
        <Text color="negativeRed" fontWeight={500} fontSize={16} pl={1}>
          {!lastDataPoint && 'N/A'}
          {Boolean(lastDataPoint) &&
            `${formatWithGoalUnit(lastDataPoint?.value ?? 0, type.unit)}`}
        </Text>
        <PercentChange
          prevValue={previousValue || currentValue}
          value={currentValue}
          ideal={type.ideal === Trend.Up ? 'up' : 'down'}
          loading={false}
          iconSize="20px"
          icon={{ paddingTop: '3px' }}
          fontSize={16}
          fontWeight={500}
        />
        <SparklineChart
          data={timeSeriesWithId}
          targetDirection={
            type.ideal === Trend.Up
              ? ImprovementGoalTypeTargetType.Above
              : ImprovementGoalTypeTargetType.Below
          }
          target={previousValue}
          h="45px"
          w={120}
          ml={2}
        />
      </Flex>
    </Flex>
  );
};

const CauseTopic: FC<{
  cause: TeamInsightCauseTopicTypedef;
}> = ({ cause }) => (
  <Flex flexDir="column" w="full" gap={4}>
    {cause.topic && (
      <Text maxW={500} fontSize={18} textAlign="center" mx="auto">
        Your team had been talking negatively about
        <br />
        <Text as="span" fontWeight={500}>
          {cause.topic}
        </Text>
      </Text>
    )}
    <Flex
      flexWrap="wrap"
      gap={2}
      justifyContent="center"
      alignItems="center"
      w="full"
    >
      {cause.cards.map((card) => (
        <Flex
          key={card.text}
          maxW={250}
          flexDir="column"
          gap={1}
          justifyContent="center"
        >
          <Flex
            color="gray.600"
            borderRadius="lg"
            boxShadow="spreadDark"
            bg="white"
            textAlign="center"
            py={2}
            px={3}
            fontSize={13}
            alignItems="center"
            position="relative"
          >
            <Box mr={2} alignSelf="flex-start">
              <Tooltip
                placement="right"
                bg={card.badgeColor || ''}
                color={getIdealContrastForBackground(card.badgeColor || '')}
                label={card.badgeText}
                hasArrow
                arrowSize={6}
                shouldWrapChildren
              >
                <ColorBadge color={card.badgeColor} />
              </Tooltip>
            </Box>
            {card.text}
          </Flex>
          <Text fontSize={10}>
            {card.retroName} ({SpkTime.format(card.retroDate, 'MMM dd')})
          </Text>
        </Flex>
      ))}
    </Flex>
  </Flex>
);

const CauseWorkItems: FC<{
  cause: TeamInsightFactCauseSolutionCauseOutlierWorkItems;
}> = ({ cause }) => (
  <Flex flexDir="column" w="full" gap={4}>
    {cause.topic && (
      <Text maxW={320} fontSize={18} textAlign="center" mx="auto">
        Usually, your work items involving{' '}
        <Text as="span" fontWeight={600}>
          {cause.topic}{' '}
        </Text>
        are relevant outliers.
      </Text>
    )}
    <Flex
      flexWrap="wrap"
      gap={2}
      justifyContent="center"
      alignItems="center"
      w="full"
    >
      {cause.workItems.map((wi) => (
        <Flex
          key={wi.slug}
          minW={250}
          maxW={250}
          flexDir="column"
          gap={1}
          justifyContent="flex-start"
        >
          <Flex
            color="gray.600"
            borderRadius="4px"
            boxShadow="spreadDark"
            bg="white"
            textAlign="left"
            pb={2}
            pt={3}
            px={4}
            fontSize={14}
            alignItems="flex-start"
            flexDir="column"
            mb={1}
          >
            {wi.title}
            <HStack
              w="full"
              mt={1}
              justifyContent="space-between"
              alignItems="center"
              pr={2}
            >
              <Flex
                flexWrap="nowrap"
                mr={2}
                gap={1}
                alignSelf="flex-start"
                fontSize={12}
              >
                {wi.origin && ICON_BY_WORK_ITEM_ORIGIN[wi.origin]}
                <Link
                  target="_blank"
                  href={wi.url || ''}
                  textDecor="underline"
                  fontWeight={500}
                  whiteSpace="nowrap"
                >
                  {wi.slug}
                </Link>
              </Flex>
              <Text fontSize={11} whiteSpace="nowrap" pt="1px">
                Time to complete:{' '}
                <Text fontWeight={500} as="span" color="gray.700" fontSize={12}>
                  {SpkTime.formatDuration(wi.wipTimeMs, 'hours')}
                </Text>
              </Text>
            </HStack>
          </Flex>
        </Flex>
      ))}
    </Flex>
  </Flex>
);

const CausePullRequests: FC<{
  cause: TeamInsightFactCauseSolutionCauseOutlierPullRequests;
}> = ({ cause }) => (
  <Flex flexDir="column" w="full" gap={4}>
    {cause.topic && (
      <Text maxW={320} fontSize={18} textAlign="center" mx="auto">
        Usually, your pull requests involving{' '}
        <Text as="span" fontWeight={600}>
          {cause.topic}{' '}
        </Text>
        are relevant outliers.
      </Text>
    )}
    <Flex
      flexWrap="wrap"
      gap={2}
      justifyContent="center"
      alignItems="center"
      w="full"
    >
      {cause.pullRequests.map((pr) => (
        <Flex
          key={pr.slug}
          minW={250}
          maxW={250}
          flexDir="column"
          gap={1}
          justifyContent="flex-start"
        >
          <Flex
            color="gray.600"
            borderRadius="4px"
            boxShadow="spreadDark"
            bg="white"
            textAlign="left"
            pb={2}
            pt={3}
            px={4}
            fontSize={14}
            alignItems="flex-start"
            flexDir="column"
            mb={1}
          >
            {pr.title}
            <HStack
              w="full"
              mt={1}
              justifyContent="space-between"
              alignItems="center"
              pr={2}
            >
              <Flex
                flexWrap="nowrap"
                mr={2}
                gap={1}
                alignSelf="flex-start"
                fontSize={12}
              >
                {pr.origin && ICON_BY_SOURCE_CONTROL_ORIGIN[pr.origin]}
                <Link
                  target="_blank"
                  href={pr.externalUrl || ''}
                  textDecor="underline"
                  fontWeight={500}
                  whiteSpace="nowrap"
                >
                  #{pr.slug}
                </Link>
              </Flex>
              <Text fontSize={11} whiteSpace="nowrap" pt="1px">
                Time to complete:{' '}
                <Text fontWeight={500} as="span" color="gray.700" fontSize={12}>
                  {SpkTime.formatDuration(pr.wipTimeMs ?? 0, 'hours')}
                </Text>
              </Text>
            </HStack>
          </Flex>
        </Flex>
      ))}
    </Flex>
  </Flex>
);

const CauseUnreviewedPullRequests: FC<{
  cause: TeamInsightFactCauseSolutionCauseUnreviewedPullRequests;
}> = ({ cause }) => (
  <Flex flexDir="column" w="full" gap={4}>
    {cause.topic && (
      <Text maxW={280} fontSize={18} textAlign="center" mx="auto">
        Pull requests about
        <Text as="span" fontWeight={500}>
          {' '}
          {cause.topic}{' '}
        </Text>
        often go unreviewed.
      </Text>
    )}
    <Flex
      flexWrap="wrap"
      gap={2}
      justifyContent="center"
      alignItems="center"
      w="full"
    >
      {cause.pullRequests.map((pr) => (
        <Flex
          key={pr.slug}
          minW={250}
          maxW={250}
          flexDir="column"
          gap={1}
          justifyContent="flex-start"
        >
          <Flex
            color="gray.600"
            borderRadius="4px"
            boxShadow="spreadDark"
            bg="white"
            textAlign="left"
            pb={2}
            pt={3}
            px={4}
            fontSize={14}
            alignItems="flex-start"
            flexDir="column"
            mb={1}
          >
            {pr.title}
            <HStack
              w="full"
              mt={1}
              justifyContent="space-between"
              alignItems="center"
              pr={2}
            >
              <Flex
                flexWrap="nowrap"
                mr={2}
                gap={1}
                alignSelf="flex-start"
                fontSize={12}
              >
                {pr.origin && (
                  <Icon
                    as={ICON_BY_SOURCE_CONTROL_ORIGIN[pr.origin]}
                    w={3}
                    h={3}
                    mt="2px"
                  />
                )}
                <Link
                  target="_blank"
                  href={pr.externalUrl || ''}
                  textDecor="underline"
                  fontWeight={500}
                  whiteSpace="nowrap"
                >
                  PR #{pr.slug}
                </Link>
              </Flex>
              <Spacer />
              <Text fontWeight={500} as="span" color="gray.600" fontSize={12}>
                {SpkTime.format(pr.createdAt, 'MMM dd, yyyy')}
              </Text>
            </HStack>
          </Flex>
        </Flex>
      ))}
    </Flex>
  </Flex>
);
