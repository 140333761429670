import { AnimatePresence } from 'framer-motion';
import {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BiTrashAlt } from 'react-icons/bi';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { PointChartData } from '../PointChart';
import {
  ImprovementGoalTypeTargetType,
  ImprovementGoalAggregationPeriod,
  ImprovementGoalUnit,
} from '@spoke/graphql';
import {
  IMPROVEMENT_GOAL_DATA_MAX_VALUE,
  dataPointIsOnTarget,
  IMPROVEMENT_GOAL_DATA_MAX_DECIMALS,
  pluralSwitch,
  GOAL_UNIT_MAP,
  SpkTime,
  Flex,
  truncateDecimals,
  HStack,
  IconButton,
  Icon,
  MotionFlex,
  isNullish,
  Text,
  Input,
} from '@spoke/common';

const getAboveOrBelowLabel = (
  isGood: boolean,
  targetType: ImprovementGoalTypeTargetType
): 'above' | 'below' => {
  if (targetType === ImprovementGoalTypeTargetType.Above) {
    return isGood ? 'above' : 'below';
  } else {
    return isGood ? 'below' : 'above';
  }
};

export const ImprovementGoalDataPopoverDataPoint = ({
  dataPoint,
  onDelete,
  onEdit,
  target,
  goalName,
  targetType,
  // aggregationPeriod,
  unit,
  min,
  max,
}: {
  dataPoint: PointChartData;
  target: number;
  min: number | null | undefined;
  max: number | null | undefined;
  targetType: ImprovementGoalTypeTargetType;
  aggregationPeriod: ImprovementGoalAggregationPeriod;
  goalName: string;
  unit: ImprovementGoalUnit;
  onDelete: (goalDataId: string) => Promise<void> | void;
  onEdit: (goalDataId: string, newValue: number) => Promise<void> | void;
}) => {
  const editDataButtonRef = useRef<SVGSVGElement | null>();
  const [editedValue, setEditedValue] = useState(dataPoint.value);
  const [isEditing, setIsEditing] = useState(false);
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const [changingToNegative, setChangingToNegative] = useState(false);
  const [addingDot, setAddingDot] = useState(false);

  const isInvalid =
    editedValue > IMPROVEMENT_GOAL_DATA_MAX_VALUE ||
    (!isNullish(max) && editedValue > max!) ||
    (!isNullish(min) && editedValue < min!);

  const [loading, setLoading] = useState(false);

  const isGood = dataPointIsOnTarget(editedValue, target, targetType);
  const aboveOrBelow = getAboveOrBelowLabel(isGood, targetType);

  const color = isInvalid ? 'red.500' : isGood ? 'green.600' : 'red.500';
  const over = Math.abs(target - editedValue);

  const onEditCancel = () => {
    setEditedValue(dataPoint.value);
    setIsEditing(false);
  };

  const onEditChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === '-' || e.target.value === '') {
      setEditedValue(0);
      return;
    }

    const decimalCount = e.target.value.split('.')[1]?.length ?? 0;
    if (decimalCount > IMPROVEMENT_GOAL_DATA_MAX_DECIMALS) return;

    const newValue = Number(e.target.value);
    if (changingToNegative) setChangingToNegative(false);
    if (addingDot) setAddingDot(false);
    if (newValue > IMPROVEMENT_GOAL_DATA_MAX_VALUE) return;
    if (Number.isNaN(newValue)) return;
    setEditedValue(newValue);
  };

  const onEditFinish = async () => {
    if (isInvalid) return;
    setIsEditing(false);
    setLoading(true);
    await onEdit(dataPoint.id, editedValue);
  };

  const onEditKeydown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onEditFinish();
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      onEditCancel();
    }
    if ((e.key === '-' || e.key === '+') && editedValue === 0) {
      e.preventDefault();
      setChangingToNegative(true);
    }
    if (
      !changingToNegative &&
      !isNullish(editedValue) &&
      `${editedValue}`.length &&
      e.key === '.'
    ) {
      e.preventDefault();
      setAddingDot(true);
    }
  };

  const onDeleteClick = async () => {
    if (!confirmingDelete) {
      setConfirmingDelete(true);
      return;
    }
    setLoading(true);
    await onDelete(dataPoint.id);
    setLoading(false);
    setConfirmingDelete(false);
  };

  useEffect(() => {
    if (!isEditing) editDataButtonRef.current?.focus();
  }, [isEditing]);

  const valueUnitDisplayName = pluralSwitch(
    GOAL_UNIT_MAP[unit].singular,
    GOAL_UNIT_MAP[unit].plural,
    editedValue
  );

  const overageUnitDisplayName = pluralSwitch(
    GOAL_UNIT_MAP[unit].singular,
    GOAL_UNIT_MAP[unit].plural,
    over
  );

  const dateLabel = SpkTime.format(new Date(dataPoint.date), 'MMM dd, yyyy');

  // const weekLabel =
  //   SpkTime.format(new Date(dataPoint.date), 'MMM dd') +
  //   ' - ' +
  //   SpkTime.format(
  //     new Date(new Date(dataPoint.date).getTime() + ONE_WEEK_MS - 1),
  //     'MMM dd, yyyy'
  //   );

  const label =
    // aggregationPeriod === ImprovementGoalAggregationPeriod.Week
    //   ? weekLabel
    //   :
    dateLabel;

  return (
    <Flex flexDir="column" alignItems="flex-start" p={2} pb={isEditing ? 1 : 2}>
      <Text fontSize={14} fontWeight={500} color="gray.700">
        {label}
      </Text>
      <Text fontSize={14} color="gray.700">
        {goalName}:
        {!isEditing && (
          <Text as="span" ml={1} color={color} fontWeight={500}>
            <Text mr={1} as="span" onClick={() => setIsEditing(true)}>
              {truncateDecimals(editedValue, 2)}
            </Text>
            {valueUnitDisplayName}
          </Text>
        )}
        {isEditing && (
          <Text as="span" ml={1} color={color} fontWeight={500}>
            <Input
              value={
                changingToNegative
                  ? '-'
                  : `${editedValue}${addingDot ? '.' : ''}`
              }
              onKeyDown={onEditKeydown}
              onBlur={onEditCancel}
              onChange={onEditChange}
              fontSize={14}
              fontWeight={500}
              autoFocus
              variant="flushed"
              display="inline-block"
              textAlign="center"
              w={`${9 * editedValue.toString().length + (addingDot ? 4 : 0)}px`}
              maxH="18px"
              p={0}
              mr={1}
              _focus={{
                borderColor: color,
                borderBottomWidth: 2,
              }}
            />
            {valueUnitDisplayName ? valueUnitDisplayName : null}
          </Text>
        )}
      </Text>
      {!isInvalid && (
        <Text fontSize={14} color={color} fontWeight={500}>
          {truncateDecimals(over, 2)}{' '}
          {overageUnitDisplayName ? overageUnitDisplayName + ' ' : null}
          {aboveOrBelow} target
        </Text>
      )}
      {isInvalid && (
        <Text fontSize={12} color={color} fontWeight={500}>
          Value out of range
          <br />
          <Text as="span" fontSize={12}>
            ({!isNullish(min) && `Min: ${min}`}
            {!isNullish(min) && !isNullish(max) && `, `}
            {!isNullish(max) && `Max: ${max}`})
          </Text>
        </Text>
      )}
      {!isEditing && (
        <HStack mt={1}>
          <IconButton
            ref={editDataButtonRef as any}
            variant="unstyled"
            aria-label="Edit data point"
            isLoading={!confirmingDelete && loading}
            isDisabled={loading}
            onClick={() =>
              confirmingDelete ? setConfirmingDelete(false) : setIsEditing(true)
            }
            w={4}
            h={4}
            icon={
              <Icon
                w={4}
                h={4}
                color="gray.600"
                as={
                  confirmingDelete ? AiOutlineClose : MdOutlineModeEditOutline
                }
              />
            }
          />

          <HStack spacing={1}>
            {!(loading && !confirmingDelete) && (
              <IconButton
                variant="unstyled"
                aria-label="Delete data point"
                isLoading={confirmingDelete && loading}
                isDisabled={loading}
                onClick={onDeleteClick}
                w={4}
                h={4}
                icon={
                  <Icon
                    w={4}
                    h={4}
                    color="gray.600"
                    as={confirmingDelete ? AiOutlineCheck : BiTrashAlt}
                  />
                }
              />
            )}

            <AnimatePresence>
              {confirmingDelete && (
                <MotionFlex
                  initial={{ opacity: 0, translateX: -5 }}
                  animate={{ opacity: 1, translateX: 0 }}
                  exit={{ opacity: 0, translateX: -5 }}
                  transition={{ duration: 0.2 }}
                >
                  <Text
                    lineHeight={0}
                    fontSize={11}
                    fontWeight={500}
                    color="gray.600"
                    userSelect="none"
                  >
                    Delete data?
                  </Text>
                </MotionFlex>
              )}
            </AnimatePresence>
          </HStack>
        </HStack>
      )}
      {Boolean(isEditing && !isInvalid) && (
        <Text
          w="full"
          fontSize={12}
          color="gray.600"
          mt="6px"
          textAlign="center"
        >
          Hit
          <Text ml={1} fontWeight={600} as="span">
            Enter
          </Text>{' '}
          to commit
        </Text>
      )}{' '}
    </Flex>
  );
};
