import { useCallback } from 'react';
import {
  useCurrentBoardId,
  useToast,
  TOAST_ERROR_GENERIC,
  log,
} from '@spoke/common';
import { useStartGoalsLivePollMutation } from '@spoke/graphql';

export const useStartGoalsLivePollHandler = () => {
  const [startGoalsPoll] = useStartGoalsLivePollMutation();
  const [boardId] = useCurrentBoardId();
  const [toast] = useToast();

  const handleStartGoalsLivePoll = useCallback(
    async ({ goalIds }: { goalIds: string[] }): Promise<boolean> => {
      if (!goalIds.length || !boardId) {
        log.error('Missing critical data to start goals live poll', {
          boardId,
          goalIds,
        });
        return false;
      }

      log.info('Starting goals LivePoll', { boardId, goalIds });

      const { errors } = await startGoalsPoll({
        variables: { boardId, improvementGoalIds: goalIds },
      });

      if (errors) {
        log.error('startGoalsLivePoll call responded with errors', {
          boardId,
          goalIds,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [boardId, startGoalsPoll, toast]
  );

  return [handleStartGoalsLivePoll];
};
