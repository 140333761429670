import { ComponentStyleConfig } from '@chakra-ui/react';

export const CardComponentStyle: ComponentStyleConfig = {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    alignItems: 'center',
    gap: 6,
    p: 6,
    boxShadow: 'md',
    borderRadius: 'md',
  },
  variants: {
    modal: {
      p: 8,
      gap: 0,
      alignItems: 'stretch',
      w: 'full',
      borderRadius: '2xl',
    },
  },
};

import { Box, useStyleConfig } from '@chakra-ui/react';
import { FC } from 'react';
import { BoxProps } from './Box';

// TODO figure out proper chakra typedef
export type CardProps = BoxProps & {
  variant?: string;
};

export const Card: FC<CardProps> = (props) => {
  const { variant, ...rest } = props;

  const styles = useStyleConfig('Card', { variant });

  return <Box __css={styles} {...rest} />;
};
