import { FC, memo } from 'react';
import { OriginalListSelector } from './OriginalListSelector';
import {
  getIdealContrastForBackground,
  Menu,
  Tooltip,
  MenuButton,
  Portal,
  MenuList,
  Box,
  ColorBadge,
} from '@spoke/common';

type OriginalListBadgeProps = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  badgeText: string;
  badgeColor: string;
  handleUpdateOriginalList: (newListId: string) => void;
};
export const OriginalListBadge: FC<OriginalListBadgeProps> = ({
  close,
  isOpen,
  open,
  badgeText,
  badgeColor,
  handleUpdateOriginalList,
}) => (
  <Menu isOpen={isOpen} onOpen={open} onClose={close}>
    <Tooltip
      label={badgeText}
      bg={badgeColor ?? ''}
      variant="white"
      placement="right"
      boxShadow="lg"
      openDelay={200}
      hasArrow={false}
      color={getIdealContrastForBackground(badgeColor ?? '')}
    >
      <Box as="span" tabIndex={-1}>
        <MenuButton tabIndex={-1}>
          <ColorBadge tabIndex={-1} color={badgeColor ?? ''} />
        </MenuButton>
      </Box>
    </Tooltip>
    <Portal>
      <MenuList>
        {isOpen && <OriginalListSelector onSelect={handleUpdateOriginalList} />}
      </MenuList>
    </Portal>
  </Menu>
);

export const MemoizedOriginalListBadge = memo(OriginalListBadge);
