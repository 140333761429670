import {
  forwardRef,
  PropsWithChildren,
  useState,
  useEffect,
  useCallback,
  memo,
} from 'react';
import { Droppable } from 'react-beautiful-dnd';
import {
  useAddCardHandler,
  useDuplicateCardHandler,
  useEditCardTextHandler,
  useAddCardCommentHandler,
  useDeleteCardCommentHandler,
  useDeleteCardHandler,
  useCardVoteHandler,
  useUpdateCardOriginalListHandler,
  useDeleteListHandler,
  useUpdateListTitleHandler,
  useUpdateListColorHandler,
  useMoveListHandler,
} from '../../handlers';
import { useIsDraggingAGroupCard } from '../../util';
import { MemoizedAddCardBox } from '../AddCardBox';
import { MemoizedBoardCard } from '../BoardCard';
import { MemoizedBoardListHeader } from './BoardListHeader';
import { List, Card } from '@spoke/graphql';
import {
  filterArchived,
  shallowEqualsExceptFor,
  hashCards,
  Flex,
  VStack,
  Box,
  CustomDndPlaceholder,
  CustomDndPlaceholderProps,
} from '@spoke/common';

type BoardListProps = {
  list: List;
  currentUserId: string;
  teamId: string;
  groupingEnabled: boolean;
  votingEnabled: boolean;
  addCardsEnabled: boolean;
  votesLeft: number;
  cardCount: number;
  showColumns: boolean;
  canPromoteCards: boolean;
  isFacilitator: boolean;
  minHeight?: string;
  customPlaceholderProps?: CustomDndPlaceholderProps;
  onSaveCardAsActionItem: (cardId: string) => void;
  onSaveCardAsParkingLot: (cardId: string) => void;
  onPromoteCard: (cardId: string) => void;
};

export const BoardList = forwardRef<
  HTMLDivElement,
  PropsWithChildren<BoardListProps>
>(
  (
    {
      list,
      teamId,
      cardCount,
      groupingEnabled,
      addCardsEnabled,
      showColumns,
      votingEnabled,
      onSaveCardAsActionItem,
      onSaveCardAsParkingLot,
      onPromoteCard,
      canPromoteCards,
      children,
      customPlaceholderProps,
      votesLeft,
      currentUserId,
      isFacilitator,
      minHeight,
      ...rest
    },
    ref
  ) => {
    const { id: listId, name: title, cards, boardId } = list;

    const isDraggingAGroupCard = useIsDraggingAGroupCard();

    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [editedTitle, setEditedTitle] = useState(title);

    useEffect(() => setEditedTitle(title), [title]);

    const [handleAddCard] = useAddCardHandler({
      boardId,
      listId,
      teamId,
    });
    const [handleDuplicateCard] = useDuplicateCardHandler();
    const [handleEditCardText] = useEditCardTextHandler();
    const [handleAddComment] = useAddCardCommentHandler({ boardId, teamId });
    const [handleDeleteComment] = useDeleteCardCommentHandler();
    const [handleDeleteCard] = useDeleteCardHandler();
    const [handleCardVote] = useCardVoteHandler({ boardId });
    const [handleUpdateCardOriginalList] = useUpdateCardOriginalListHandler();
    const [handleDeleteList] = useDeleteListHandler({ listId });
    const [handleUpdateListTitle] = useUpdateListTitleHandler({ listId });
    const [handleUpdateListColor] = useUpdateListColorHandler({ listId });
    const [handleMoveList] = useMoveListHandler({ listId, boardId });

    const cardColor = list.cardColor;
    const hasVotesToSpend = Boolean(votesLeft && votesLeft > 0);

    const onEditTitleCommit = useCallback(() => {
      setIsEditingTitle(false);
      handleUpdateListTitle(editedTitle);
    }, [editedTitle, handleUpdateListTitle]);

    const onEditTitleCancel = useCallback(() => {
      setIsEditingTitle(false);
      setEditedTitle(title);
    }, [title]);

    const onEditTitleStart = useCallback(() => setIsEditingTitle(true), []);

    const droppableId = listId;

    return (
      <Flex
        flexDir="column"
        bg={showColumns ? 'gray.100' : 'transparent'}
        minH={minHeight}
        borderRadius="lg"
        borderBottomRadius="none"
        w={320}
        px={showColumns ? 4 : 1}
        pt={1}
        pb="89px"
        ref={ref}
        {...rest}
      >
        {showColumns && (
          <MemoizedBoardListHeader
            cardColor={cardColor}
            cardCount={cardCount}
            editedTitle={editedTitle}
            handleDeleteList={handleDeleteList}
            handleMoveList={handleMoveList}
            handleUpdateListColor={handleUpdateListColor}
            isEditingTitle={isEditingTitle}
            onEditTitleCancel={onEditTitleCancel}
            onEditTitleCommit={onEditTitleCommit}
            onEditTitleStart={onEditTitleStart}
            setEditedTitle={setEditedTitle}
            isFacilitator={isFacilitator}
          />
        )}
        <VStack alignItems="stretch" spacing={0} h="full">
          {addCardsEnabled && <MemoizedAddCardBox onAdd={handleAddCard} />}
          <Droppable
            droppableId={droppableId}
            isCombineEnabled={groupingEnabled && !isDraggingAGroupCard}
          >
            {({ droppableProps, innerRef, placeholder }) => (
              <Flex
                alignItems="stretch"
                flexDir="column"
                position="relative"
                ref={innerRef}
                h="full"
                {...droppableProps}
              >
                {cards
                  ?.filter(filterArchived)
                  .map(
                    (card, idx) =>
                      card && (
                        <MemoizedBoardCard
                          key={card.id}
                          card={card as Card}
                          index={idx}
                          boardId={boardId}
                          hasVotesToSpend={hasVotesToSpend}
                          groupingEnabled={groupingEnabled}
                          votingEnabled={votingEnabled}
                          onEdit={handleEditCardText}
                          onAddComment={handleAddComment}
                          onDuplicate={handleDuplicateCard}
                          onDeleteCard={handleDeleteCard}
                          onDeleteComment={handleDeleteComment}
                          onUpdateOriginalList={handleUpdateCardOriginalList}
                          onSaveAsActionItem={onSaveCardAsActionItem}
                          onSaveAsParkingLot={onSaveCardAsParkingLot}
                          currentUserId={currentUserId}
                          isFacilitator={isFacilitator}
                          canPromote={canPromoteCards}
                          onPromote={onPromoteCard}
                          onVote={handleCardVote}
                          mb={4}
                        />
                      )
                  )}
                <Box>{placeholder}</Box>
                <CustomDndPlaceholder
                  placeholderProps={customPlaceholderProps}
                />
              </Flex>
            )}
          </Droppable>
          {children}
        </VStack>
      </Flex>
    );
  }
);

BoardList.displayName = 'BoardList';

export const MemoizedBoardList = memo(BoardList, (prevProps, nextProps) => {
  if (!shallowEqualsExceptFor(prevProps, nextProps, ['list'])) return false;

  if (!prevProps.list.index !== !nextProps.list.index) return false;
  if (!prevProps.list.cards || !nextProps.list.cards) return false;
  if (prevProps.list.cards.length !== nextProps.list.cards.length) return false;
  if (prevProps.list.name !== nextProps.list.name) return false;
  if (prevProps.list.cardColor !== nextProps.list.cardColor) return false;

  const prevCards = prevProps.list.cards as Card[];
  const nextCards = nextProps.list.cards as Card[];

  const cardsHaveChanged = hashCards(prevCards) !== hashCards(nextCards);

  if (cardsHaveChanged) return false;
  return true;
});
