import { FC, useState, ChangeEventHandler, useRef } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { BsCheck } from 'react-icons/bs';
import {
  ImprovementGoalUnit,
  ImprovementGoalTypeTargetType,
  ImprovementGoalAggregationPeriod,
} from '@spoke/graphql';
import {
  FlexProps,
  IMPROVEMENT_GOAL_DATA_MAX_DECIMALS,
  IMPROVEMENT_GOAL_DATA_MAX_VALUE,
  GOAL_UNIT_MAP,
  Flex,
  InputGroup,
  InputLeftAddon,
  callIfExists,
  InputRightElement,
  Spinner,
  Icon,
  InputRightAddon,
  capitalizeFirstLetter,
  HStack,
  Tooltip,
  Center,
  isNullish,
  Input,
  Text,
} from '@spoke/common';
import { is } from 'date-fns/locale';

type ImprovementGoalValueInputProps = Omit<
  FlexProps,
  'children' | 'onChange'
> & {
  value: string;
  isInvalid: boolean;
  unit: ImprovementGoalUnit;
  max?: number | null;
  min?: number | null;
  targetType?: ImprovementGoalTypeTargetType;
  aggregationPeriod?: ImprovementGoalAggregationPeriod;
  isDisabled?: boolean;
  loading?: boolean;
  submitSuccess?: boolean;
  onChange: (value: string) => void;
  onSubmit?: () => void;
};
export const ImprovementGoalValueInput: FC<ImprovementGoalValueInputProps> = ({
  isInvalid,
  onChange,
  onSubmit,
  value,
  max,
  min,
  unit,
  isDisabled,
  loading,
  submitSuccess,
  targetType,
  aggregationPeriod,
  ...rest
}) => {
  const [changingToNegative, setChangingToNegative] = useState(false);
  const [addingDot, setAddingDot] = useState(false);

  const onValueInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value === '-' || e.target.value === '') {
      onChange('');
      return;
    }

    const decimalCount = e.target.value.split('.')[1]?.length ?? 0;
    if (decimalCount > IMPROVEMENT_GOAL_DATA_MAX_DECIMALS) return;

    const newValue = Number(e.target.value);
    if (changingToNegative) setChangingToNegative(false);
    if (addingDot) setAddingDot(false);
    if (newValue > IMPROVEMENT_GOAL_DATA_MAX_VALUE) return;
    if (Number.isNaN(newValue)) return;
    onChange(newValue.toString());
  };

  const unitSuffixElement = useRef<HTMLDivElement | null>(null);

  const suffixWidth =
    unitSuffixElement.current?.getBoundingClientRect().width ?? 0;

  const unitDisplayName = GOAL_UNIT_MAP[unit]?.plural;

  const submitStatusOffsetRight = suffixWidth + (unitDisplayName ? 0 : 3);

  const targetLabel = !targetType
    ? null
    : targetType === ImprovementGoalTypeTargetType.Above
    ? '> Above'
    : '< Below';

  return (
    <Flex flexDir="column" gap={1} {...rest}>
      <InputGroup
        borderRadius="md"
        flex="1.3"
        borderColor={isInvalid ? 'red.500' : 'gray.100'}
        _focusWithin={{
          borderColor: isInvalid ? 'red.500' : 'gray.100',
        }}
        transition="border-color 0.2s ease-out"
        minH={10}
      >
        {targetLabel && (
          <InputLeftAddon
            fontSize={14}
            px={3}
            pt="2px"
            bg={isInvalid ? 'red.500' : 'gray.100'}
            color={isInvalid ? 'white' : 'gray.500'}
            fontWeight={500}
            transition="background-color 0.2s ease-out, color 0.2s ease-out"
          >
            {targetLabel}
          </InputLeftAddon>
        )}
        <Input
          size="lg"
          fontSize={16}
          h="unset"
          value={changingToNegative ? '-' : `${value}${addingDot ? '.' : ''}`}
          onChange={onValueInputChange}
          onKeyDown={(e) => {
            if (e.key === '-') {
              e.preventDefault();
              setChangingToNegative(true);
            }
            if (!changingToNegative && value.length && e.key === '.') {
              e.preventDefault();
              setAddingDot(true);
            }
            if (e.key === 'Enter') {
              e.preventDefault();
              callIfExists(onSubmit);
            }
          }}
          max={IMPROVEMENT_GOAL_DATA_MAX_VALUE}
          placeholder="0"
          _focus={{ boxShadow: 'none' }}
          _hover={{}}
          isDisabled={isDisabled}
        />
        {Boolean(loading && !isInvalid) && (
          <InputRightElement
            mt="2px"
            mr={`${submitStatusOffsetRight}px`}
            pointerEvents="none"
          >
            <Spinner
              h={5}
              w={5}
              speed="0.8s"
              thickness="3px"
              mx="auto"
              my={2}
              color="gray.500"
            />
          </InputRightElement>
        )}
        {Boolean(submitSuccess && !loading && !isInvalid) && (
          <InputRightElement
            mt="2px"
            mr={`${submitStatusOffsetRight}px`}
            pointerEvents="none"
          >
            <Icon color="gray.500" w={8} h={8} as={BsCheck} />
          </InputRightElement>
        )}
        {unitDisplayName && (
          <InputRightAddon
            bg={isInvalid ? 'red.500' : 'gray.100'}
            color={isInvalid ? 'white' : 'gray.500'}
            fontSize={14}
            px={3}
            pt="2px"
            fontWeight={500}
            ref={unitSuffixElement}
            transition="background-color 0.2s ease-out, color 0.2s ease-out"
          >
            {capitalizeFirstLetter(unitDisplayName || '')}
          </InputRightAddon>
        )}
      </InputGroup>

      {Boolean(!isNullish(min) || !isNullish(max)) && (
        <HStack spacing={1}>
          <Tooltip
            variant="white"
            placement="top"
            label="Acceptable value range for this goal"
            maxW={140}
            openDelay={200}
            fontSize={14}
            hasArrow
          >
            <Center>
              <Icon
                w="14px"
                h="14px"
                as={BiInfoCircle}
                color={isInvalid ? 'red.500' : 'gray.500'}
              />
            </Center>
          </Tooltip>
          <Text
            textAlign="left"
            fontSize={12}
            color={isInvalid ? 'red.500' : 'gray.500'}
            fontWeight={500}
            pt="3px"
            ml={1}
          >
            {isInvalid ? 'Value out of range - ' : ''}
            {!isNullish(min) && `Min: ${min}`}
            {!isNullish(min) && !isNullish(max) && `, `}
            {!isNullish(max) && `Max: ${max}`}{' '}
            {aggregationPeriod &&
              `| Per ${
                aggregationPeriod === ImprovementGoalAggregationPeriod.Day
                  ? 'day'
                  : 'week'
              }`}
          </Text>
        </HStack>
      )}
    </Flex>
  );
};
