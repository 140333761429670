import { useTeamHasFlowMetricsInstalled } from './useTeamHasFlowMetricsInstalled';
import { useTeamHasSourceControlMetricsInstalled } from './useTeamHasSourceControlMetricsInstalled';
import {
  QueryConfig,
  DeepPartial,
  useNow,
  SpkTime,
  ONE_WEEK_MS,
  deepMerge,
} from '@spoke/common';
import { useTeamMetricsOverTimeQuery, TimeWindow } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type Config = QueryConfig<typeof useTeamMetricsOverTimeQuery>;
type QueryRef = ReturnType<typeof useTeamMetricsOverTimeQuery>;
export type TeamMetricsOverTimeQueryData = NonNullable<
  NonNullable<QueryRef>['data']
>['teamMetricsOverTime'];

export const useTeamMetricsOverTime = (
  config?: DeepPartial<Config>
): [TeamMetricsOverTimeQueryData | null, QueryRef] => {
  const { loading: sourceControlInstallationLoading, has: hasSourceControl } =
    useTeamHasSourceControlMetricsInstalled();
  const { loading: flowMetricsInstallationLoading, has: hasFlowMetrics } =
    useTeamHasFlowMetricsInstalled();

  const installationsLoading =
    sourceControlInstallationLoading || flowMetricsInstallationLoading;

  const hasEitherSourceControlOrFlowMetrics =
    hasSourceControl || hasFlowMetrics;

  const now = useNow({ roundTo: 'ten-minutes' });

  const [currentTeam] = useCurrentTeam();

  const baseConfig: DeepPartial<Config> = {
    variables: {
      teamId: currentTeam?.id ?? '',
      utcOffsetMs: SpkTime.getUtcOffsetMs(),
      startDate: now.getTime() - ONE_WEEK_MS * 1,
      endDate: now.getTime(),
      timeWindow: TimeWindow.Day,
    },
    skip:
      !currentTeam?.id ||
      !hasEitherSourceControlOrFlowMetrics ||
      installationsLoading,
  };

  const finalConfig = (
    config ? deepMerge(baseConfig, config) : baseConfig
  ) as Config;

  const query = useTeamMetricsOverTimeQuery(finalConfig);

  const data =
    query.data?.teamMetricsOverTime ??
    query.previousData?.teamMetricsOverTime ??
    null;

  return [data, query];
};
