import {
  TOAST_ERROR_GENERIC,
  optimisticCreateList,
  getCreateListUpdateFunctionForBoardById,
  log,
  useToast,
  useCurrentBoard,
} from '@spoke/common';
import { useCreateListMutation, Board, List } from '@spoke/graphql';

export const useCreateListHandler = () => {
  const [toast] = useToast();
  const [board] = useCurrentBoard();
  const [createList] = useCreateListMutation();

  const handleCreateList = async () => {
    const boardId = board?.id;

    if (!board || !boardId) {
      log.error('Missing critical data to create new column', { board });
      toast(TOAST_ERROR_GENERIC);
      return;
    }

    const NEW_LIST_NAME = 'New Column';

    const expected = optimisticCreateList({
      board: board as Board,
      newListName: NEW_LIST_NAME,
    });

    const { data, errors } = await createList({
      variables: { boardId, name: NEW_LIST_NAME },
      optimisticResponse: expected,
      updateQueries: {
        BoardById: getCreateListUpdateFunctionForBoardById({
          optimisticList: expected.createList as List,
        }),
      },
    });

    if (errors) {
      log.error('Create list mutation responded with errors', {
        boardId,
        data,
        errors,
      });
      toast(TOAST_ERROR_GENERIC);
      return;
    }
  };

  return [handleCreateList];
};
