import { Group } from '@visx/group';
import { scaleLinear, scaleTime } from '@visx/scale';
import { FC, useMemo } from 'react';
import useMeasure from 'react-use-measure';
import { useToken } from '@chakra-ui/react';
import { AreaClosed } from '@visx/shape';
import { LinearGradient } from '@visx/gradient';
import {
  SpkTime,
  FlexProps,
  dataPointIsOnTarget,
  Flex,
  isNullish,
} from '@spoke/common';
import { ImprovementGoalTypeTargetType } from '@spoke/graphql';

const getYValue = (d: SparklineTimeSeries) => d.value ?? 0;
const getXValue = (d: SparklineTimeSeries) =>
  SpkTime.getStartOf('day', d.date) ?? new Date();

export type SparklineTimeSeries<T = Record<string, unknown>> = T & {
  id: string;
  date: string;
  value?: number | undefined | null;
};

type PointChartProps = FlexProps & {
  data: SparklineTimeSeries[];
  valueSuffix?: string;
  target?: number;
  targetDirection: ImprovementGoalTypeTargetType;
};
export const SparklineChart: FC<PointChartProps> = ({
  data: _data,
  targetDirection,
  target,
  ...rest
}) => {
  const [ref, bounds] = useMeasure();

  const [green700, red500, gray600] = useToken('colors', [
    'green.600',
    'red.300',
    'gray.600',
  ]);

  const data = [..._data].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  // If there's only one data point, span it all over in a straight line
  if (data.length === 1) {
    data.push({
      ...data[0],
      date: SpkTime.add(data[0].date, 1, 'days').toISOString(),
    });
  }

  const width = bounds.width || 100;
  const height = bounds.height || 100;

  const innerWidth = width;
  const innerHeight = height;

  const xScale = useMemo(
    () =>
      scaleTime({
        range: [0, innerWidth],
        domain: [
          new Date(Math.min(...data.map(getXValue).map((d) => d.getTime()))),
          new Date(Math.max(...data.map(getXValue).map((d) => d.getTime()))),
        ],
      }),
    [data, innerWidth]
  );

  const yScale = useMemo(() => {
    const minData = Math.min(...data.map(getYValue), target ?? Infinity);
    const maxData = Math.max(...data.map(getYValue), target ?? -Infinity);
    const absRange = Math.abs(maxData - minData);
    const fallbackToShowOnlyTargetLineOnCenter =
      (!Number.isFinite(minData) || !Number.isFinite(maxData)) && target;
    const FALLBACK_PADDING_BOTTOM = 100;
    const domain = fallbackToShowOnlyTargetLineOnCenter
      ? [target - FALLBACK_PADDING_BOTTOM, target]
      : [minData - absRange * 1, maxData * 1.1];
    return scaleLinear<number>({
      range: [innerHeight, 0],
      domain,
    });
  }, [data, innerHeight, target]);

  const isEmpty = data.length === 0;
  const latestValue = data[data.length - 1]?.value ?? 0;
  const onTarget = dataPointIsOnTarget(
    latestValue,
    target,
    targetDirection || ImprovementGoalTypeTargetType.Above
  );

  const color = onTarget || isNullish(target) ? green700 : red500;

  return (
    <Flex
      width="full"
      height="full"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      <Flex ref={ref} position="relative" width="full" height="full">
        <svg width="100%" height="100%" viewBox={`0 0 ${width} ${height}`}>
          <LinearGradient
            id={`sparkline_gradient_${color}`}
            from={color}
            to={color}
            fromOpacity={color === green700 ? 0.5 : 0.7}
            toOpacity={0}
          />
          <Group>
            <AreaClosed<SparklineTimeSeries>
              data={data}
              x={(d) => xScale(getXValue(d)) ?? 0}
              y={(d) => yScale(getYValue(d)) ?? 0}
              yScale={yScale}
              fill={`url(#sparkline_gradient_${color})`}
            />
          </Group>
          {target && (
            <line
              pointerEvents="none"
              y={2}
              x1={0}
              x2={innerWidth}
              y1={isEmpty ? innerHeight / 2 : yScale(target)}
              y2={isEmpty ? innerHeight / 2 : yScale(target)}
              rx={4}
              stroke={gray600}
              strokeWidth={1}
              strokeDasharray={`${innerWidth / 20},4`}
            />
          )}
        </svg>
      </Flex>
    </Flex>
  );
};
