import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import {
  useCurrentTeam,
  useCurrentOrg,
  useTeamTree,
  useSwitchOrgHandler,
  useSwitchTeamHandler,
  useCurrentOrgLockdown
} from '../../queries';
import { CreateTeamWizardModal } from '../CreateTeamWizard';
import { TeamSwitcherEmptyState } from './EmptyState';
import { OrgDropdown } from './OrgDropdown';
import { TeamSwitcherTree } from './TeamSwitcherTree';
import { findTeamPath, TeamSwitcherTreeNodeType } from './teamSwitcherUtil';
import { TeamType, Organization } from '@spoke/graphql';
import {
  useCurrentUser,
  useDisclosure,
  Flex,
  Skeleton,
  HStack,
  OrganizationIcon,
  Menu,
  Spinner,
  MenuButton,
  UpDownIcon,
  Divider,
  Spacer,
  Button,
  hardRedirect,
  Stack,
  Text,
  useRoutes,
} from '@spoke/common';

type TeamSwitcherProps = {
  treeMaxHeight?: number;
  afterTeamSwitch?: () => void;
};
export const TeamSwitcher: FC<TeamSwitcherProps> = ({
  afterTeamSwitch,
  treeMaxHeight,
}) => {
  const [currentUser] = useCurrentUser();
  const [currentTeam] = useCurrentTeam();
  const [currentOrg] = useCurrentOrg();
  const [isOrgLocked] = useCurrentOrgLockdown();
  const routes = useRoutes();

  const [{ teamTree, watchedTeamTree }] = useTeamTree();

  const teams =
    teamTree
      ?.map((node) => node.team)
      .filter((team) => team.type === TeamType.Team) ?? null;

  const programs =
    teamTree
      ?.map((node) => node.team)
      .filter((team) => team.type === TeamType.Program) ?? null;

  const watchedTeams = watchedTeamTree?.map((node) => node.team);

  const createTeamModal = useDisclosure();

  const [expandedTeamIds, setExpandedTeamIds] = useState<string[]>([]);
  const [expandedWatchedTeamIds, setExpandedWatchedTeamIds] = useState<
    string[]
  >([]);
  const [loadingTeamId, setLoadingTeamId] = useState<string | null>(null);
  const [orgSwitchLoading, setOrgSwitchLoading] = useState<boolean>(false);

  const [handleSwitchOrg] = useSwitchOrgHandler();
  const [handleSwitchTeam] = useSwitchTeamHandler();

  useEffect(() => {
    if (!currentTeam?.id || !teamTree || !watchedTeamTree) return;
    const teamPath = findTeamPath(
      teamTree.concat(watchedTeamTree) as TeamSwitcherTreeNodeType[],
      currentTeam.id
    );
    const teamPathIds = teamPath.map((team) => team.id) as string[];
    setExpandedTeamIds(teamPathIds);
  }, [currentTeam?.id, teamTree, watchedTeamTree]);

  const noTeamsToShow = useMemo<boolean>(
    () =>
      Boolean(
        teams?.length === 0 &&
          programs?.length === 0 &&
          watchedTeams?.length === 0
      ),
    [programs?.length, teams?.length, watchedTeams?.length]
  );

  const loading =
    !currentUser ||
    !currentTeam ||
    !currentOrg ||
    !teamTree ||
    !watchedTeamTree;

  const onSwitchTeam = useCallback(
    async (teamId: string) => {
      if (orgSwitchLoading || loadingTeamId) return;
      setLoadingTeamId(teamId);
      await handleSwitchTeam({ teamId });
      afterTeamSwitch?.();
      setLoadingTeamId(null);
    },
    [handleSwitchTeam, loadingTeamId, orgSwitchLoading, afterTeamSwitch]
  );

  const onSwitchOrg = useCallback(
    async (orgId: string) => {
      setOrgSwitchLoading(true);
      if (orgSwitchLoading || loadingTeamId) return;
      await handleSwitchOrg(orgId);
      setOrgSwitchLoading(false);
    },
    [handleSwitchOrg, loadingTeamId, orgSwitchLoading]
  );

  const orgs = (currentUser?.memberships?.map((m) => m.organization) ??
    []) as Organization[];

  return (
    <Flex
      pt={2}
      pb={4}
      px={2}
      w="full"
      alignItems="flex-start"
      flexDir="column"
      gap={2}
    >
      <Skeleton
        isLoaded={!loading}
        w="full"
        transition="opacity 0.05s ease-out"
        px={1}
        _groupHover={{ opacity: 1 }}
      >
        <HStack
          justifyContent="space-between"
          w="full"
          h={10}
          px={2}
          bg="primary.25"
          borderRadius={7}
        >
          <HStack>
            <OrganizationIcon w={5} h={5} color="primary.500" />
            <Text
              fontSize={14}
              fontWeight={500}
              color="primary.500"
              cursor="pointer"
              onClick={() =>
                currentOrg ? onSwitchOrg(currentOrg.id as string) : null
              }
            >
              {currentOrg?.name}
            </Text>
          </HStack>
          <Menu>
            {orgSwitchLoading ? (
              <Spinner size="sm" />
            ) : (
              <MenuButton>
                <UpDownIcon as="button" w="22px" h="22px" color="primary.500" />
              </MenuButton>
            )}

            <OrgDropdown
              orgs={orgs}
              currentOrgId={currentOrg?.id ?? ''}
              onSwitchOrg={onSwitchOrg}
            />
          </Menu>
        </HStack>
      </Skeleton>
      <Divider />

      {loading ? (
        <Skeleton isLoaded={!loading} w="full" flex="1" />
      ) : noTeamsToShow ? (
        <TeamSwitcherEmptyState
          onCreateNewTeam={() => createTeamModal.open()}
        />
      ) : (
        <Flex
          transition="opacity 0.05s ease-out"
          _groupHover={{ opacity: 1 }}
          h="full"
          w="full"
          direction="column"
        >
          <Flex flexDir="column" flex={1} maxH={treeMaxHeight} overflowY="auto">
            <TeamSwitcherTree
              teamTree={teamTree}
              watchedTeamTree={watchedTeamTree as TeamSwitcherTreeNodeType[]}
              expandedTeamIds={expandedTeamIds}
              setExpandedTeamIds={setExpandedTeamIds}
              expandedWatchedTeamIds={expandedWatchedTeamIds}
              setExpandedWatchedTeamIds={setExpandedWatchedTeamIds}
              onSelectTeam={onSwitchTeam}
              selectedTeamId={currentTeam.id as string}
              loadingTeamId={loadingTeamId}
            />
          </Flex>

          <Spacer />
          <Stack
            direction="row"
            justifyContent="stretch"
            p={2}
            w="full"
            spacing={5}
          >
            <Button
              fontSize={15}
              height={10}
              flex="1"
              variant="outlineGray"
              onClick={() => hardRedirect(routes.OrgTeams)}
            >
              Browse teams
            </Button>
            <Button
              onClick={() => createTeamModal.open()}
              fontSize={15}
              height={10}
              flex="1"
              disabled={isOrgLocked}
            >
              Create new
            </Button>
          </Stack>
        </Flex>
      )}
      <CreateTeamWizardModal
        isOpen={createTeamModal.isOpen}
        onClose={() => createTeamModal.close()}
      />
    </Flex>
  );
};
