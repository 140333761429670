import { FC, useMemo } from 'react';
import { MdOutlineStickyNote2 } from 'react-icons/md';
import { FlowMetricsQueryData } from '../../queries';
import { MetricsInsight } from '../MetricsInsight';
import { FlowMetricsStatuses } from './FlowMetricsStatuses';
import { HistogramData, QualityName } from '@spoke/graphql';
import {
  FlexProps,
  truncateDecimals,
  ONE_DAY_MS,
  plural,
  Skeleton,
  Flex,
  Center,
  Icon,
  SpkTime,
  Spacer,
  HStack,
  COLOR_BY_QUALITY_NAME,
  QUALITY_DISPLAY_NAME,
  Divider,
  Text,
} from '@spoke/common';
import { Histogram } from '@spoke/charts';

type Props = FlexProps & {
  flowMetrics: FlowMetricsQueryData | null;
  periodLabel: string;
  loading?: boolean;
};
export const FullFlowMetrics: FC<Props> = ({
  flowMetrics,
  periodLabel,
  loading,
  ...rest
}) => {
  const histogramValueSum = useMemo(
    () =>
      flowMetrics?.cycleTimeHistogram.reduce((acc, d) => acc + d.value, 0) ?? 1,
    [flowMetrics?.cycleTimeHistogram]
  );

  const getHistogramPercentage = (value: number) =>
    `${truncateDecimals((value / histogramValueSum) * 100)}%`;

  const getHistogramTooltipText = (data: HistogramData) =>
    `${data.value} (${getHistogramPercentage(
      data.value
    )}) completed in ${Math.ceil(data.from / ONE_DAY_MS)} ${plural(
      'day',
      Math.ceil(data.from / ONE_DAY_MS)
    )}`;

  return (
    <Skeleton
      isLoaded={!loading}
      layerStyle="outlineGray"
      py={5}
      px={5}
      borderRadius="2xl"
      {...rest}
    >
      <Flex flexDir="column" p={0}>
        <Flex mb={3}>
          <Center backgroundColor="primary.25" borderRadius={8} w={10} h={10}>
            <Icon
              as={MdOutlineStickyNote2}
              color="primary.600"
              floodOpacity={0.5}
              w={5}
              h={5}
            />
          </Center>
          <Flex flexDir="column" ml={2}>
            <Text color="gray.700" fontWeight={600} fontSize={18} mt="1px">
              {truncateDecimals(
                SpkTime.millisToDays(flowMetrics?.cycleTimeMs ?? 0),
                1
              )}{' '}
              days
            </Text>
            <Text
              color="gray.500"
              fontWeight={400}
              fontSize={13}
              lineHeight="10px"
            >
              Work Item 85th Percentile Cycle Time
            </Text>
          </Flex>
          <Spacer />
          <Text alignSelf="center" fontSize={12} color="gray.600">
            {periodLabel}
          </Text>
        </Flex>
        <FlowMetricsStatuses
          statuses={flowMetrics?.statuses ?? []}
          overrallCycleTimeMs={flowMetrics?.cycleTimeMs ?? 0}
          loading={loading}
        />
        <HStack my={4} spacing={5}>
          <Flex flexDir="column">
            <Text fontSize={14} color="gray.500">
              Predictability
            </Text>
            <Text
              fontSize={14}
              color={
                COLOR_BY_QUALITY_NAME[
                  flowMetrics?.predictabilityQuality || QualityName.Good
                ]
              }
              fontWeight="500"
            >
              {
                QUALITY_DISPLAY_NAME[
                  flowMetrics?.predictabilityQuality || QualityName.Good
                ]
              }
            </Text>
          </Flex>
          <Flex flexDir="column">
            <Text fontSize={14} color="gray.500">
              Lead Time
            </Text>
            <Text fontSize={14} color="gray.700" fontWeight="500">
              {SpkTime.formatDuration(flowMetrics?.leadTimeMs ?? 0, 'days')}
            </Text>
          </Flex>
          <Flex flexDir="column">
            <Text fontSize={14} color="gray.500">
              Througput
            </Text>
            <Text fontSize={14} color="gray.500">
              <Text as="span" color="gray.700">
                {truncateDecimals(flowMetrics?.workItemsPerDay ?? 0)} items
              </Text>
              /day
            </Text>
          </Flex>
        </HStack>
        <Flex h={150}>
          <Histogram
            data={flowMetrics?.cycleTimeHistogram ?? []}
            yLabel="N° of Work Items"
            xLabel="Cycle Time"
            tip="Less variation means better predictability."
            getTooltipText={getHistogramTooltipText}
            h={150}
          />
        </Flex>
        <Spacer />
        <Divider my={3} />
        <MetricsInsight loading={loading} insight={flowMetrics?.insight} />
      </Flex>
    </Skeleton>
  );
};
