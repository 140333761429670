import { useCallback } from 'react';
import {
  useGithubRateLimitLock,
  isExternalRateLimitError,
  log,
} from '@spoke/common';
import { useLinkableGithubRepositoriesLazyQuery } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type QueryRef = ReturnType<typeof useLinkableGithubRepositoriesLazyQuery>[1];
type QueryData = NonNullable<
  NonNullable<
    NonNullable<QueryRef['data']>['team']
  >['searchLinkableGithubRepositories']
>;

type Params = {
  search: string;
};

export const useFetchLinkableGithubRepositories = (): [
  (params: Params) => Promise<QueryData>,
  QueryRef
] => {
  const [_, fireGithubRateLimitLock] = useGithubRateLimitLock();

  const [linkableGithubRepositories, queryRef] =
    useLinkableGithubRepositoriesLazyQuery({
      // https://scatterspoke.atlassian.net/browse/SC-594
      errorPolicy: 'none',
    });
  const [currentTeam] = useCurrentTeam();

  const fetchLinkableGithubRepositories = useCallback(
    async ({ search }: Params) => {
      const teamId = currentTeam?.id;

      if (!teamId) {
        log.error(
          'Missing critical data to fetch linkable Github repositories',
          {
            teamId,
            search,
          }
        );
        return [];
      }

      log.info(`Fetching linkable Github repositories`, {
        teamId,
      });

      const { error, data } = await linkableGithubRepositories({
        variables: { teamId, search: search.trim().slice(0, 64) },
      });

      const linkableRepos = data?.team?.searchLinkableGithubRepositories;

      if (error || !linkableRepos) {
        log.error('linkableGithubRepositories query responded with errors', {
          error,
          data,
          teamId,
          search,
        });

        if (error?.graphQLErrors.some(isExternalRateLimitError)) {
          log.error('Github linkableRepositories responded with rate limited', {
            error,
            data,
          });
          fireGithubRateLimitLock();
        }

        return [];
      }

      return linkableRepos;
    },
    [currentTeam?.id, fireGithubRateLimitLock, linkableGithubRepositories]
  );

  return [fetchLinkableGithubRepositories, queryRef];
};
