import {
  useImprovementGoalsSubscription,
  UseImprovementGoalsSubscriptionConfig,
} from './useImprovementGoalsSubscription';
import {
  QueryConfig,
  SpkTime,
  deepMerge,
  filterArchived,
  DeepPartial,
} from '@spoke/common';
import { useImprovementGoalsByTeamIdQuery } from '@spoke/graphql';
import { useCurrentTeam } from '@spoke/user';

type Config = QueryConfig<typeof useImprovementGoalsByTeamIdQuery>;
type QueryRef = ReturnType<typeof useImprovementGoalsByTeamIdQuery>;
export type ImprovementGoalsHookData = NonNullable<
  NonNullable<QueryRef['data']>['teamById']
>['improvementGoals']['improvementGoals'];
export type ImprovementGoalResult = NonNullable<
  NonNullable<ImprovementGoalsHookData>[0]
>;
export type ImprovementGoalDataResult = NonNullable<
  NonNullable<ImprovementGoalsHookData>[0]['data']
>;
export type ImprovementGoalsQueryData = NonNullable<
  NonNullable<ImprovementGoalsHookData>[0]
>;
type FormattedResult = {
  activeGoals: ImprovementGoalsHookData | null;
  pausedGoals: ImprovementGoalsHookData | null;
  allGoals: ImprovementGoalsHookData | null;
  totalSize: number;
};

export const useImprovementGoals = (
  queryConfig?: DeepPartial<Config>,
  subscriptionConfig?: Partial<UseImprovementGoalsSubscriptionConfig>
): [FormattedResult, QueryRef] => {
  const [team] = useCurrentTeam();
  const teamId = team?.id;

  const baseConfig: Config = {
    variables: {
      teamId: teamId || '',
      utcOffsetMs: SpkTime.getUtcOffsetMs(),
    },
    skip: !teamId,
  };

  useImprovementGoalsSubscription(subscriptionConfig);

  const finalConfig = (
    queryConfig ? deepMerge(baseConfig, queryConfig) : baseConfig
  ) as Config;

  const goalsQuery = useImprovementGoalsByTeamIdQuery(finalConfig);

  // Formatting results
  const goals =
    goalsQuery.data?.teamById?.improvementGoals?.improvementGoals?.filter(
      filterArchived
    ) ??
    goalsQuery.previousData?.teamById?.improvementGoals?.improvementGoals?.filter(
      filterArchived
    );
  const activeGoals = goals?.filter(({ paused }) => !paused) ?? null;
  const pausedGoals = goals?.filter(({ paused }) => paused) ?? null;

  return [
    {
      activeGoals,
      pausedGoals,
      allGoals: goals ?? null,
      totalSize: goals?.length || 0,
    },
    goalsQuery,
  ];
};
