import { FC } from 'react';
import {
  Box,
  Divider,
  Flex,
  FlexProps,
  HStack,
  Text,
  VStack,
} from '@spoke/common';
import { Board } from '@spoke/graphql';

type RetroSummaryTakeawayListProps = {
  takeaways: Omit<NonNullable<Board['takeaways']>['0'], 'createdAt'>[];
} & FlexProps;
export const RetroSummaryTakeawayList: FC<RetroSummaryTakeawayListProps> = ({
  takeaways,
  ...props
}) => (
  <Flex alignItems="stretch" gap={4} {...props}>
    <Box width="1px" bgColor="gray.200" />
    <VStack align="start" w="full">
      {takeaways?.map((takeaway, index) => (
        <VStack key={takeaway?.id} w="full">
          {Boolean(index) && <Divider my={4} />}
          <HStack w="full">
            <Text fontWeight="bold">{index + 1}.</Text>
            <Text>{takeaway?.text}</Text>
          </HStack>
        </VStack>
      ))}
    </VStack>
  </Flex>
);
