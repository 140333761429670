import { FC, useState, useCallback, useEffect } from 'react';
import { useUpdateImprovementGoalHandler } from '../../handlers';
import { useImprovementGoal } from '../../queries';
import { ImprovementGoalValueInput } from '../ImprovementGoalValueInput';
import {
  BoxProps,
  useSpringState,
  useDebounce,
  IMPROVEMENT_GOAL_DATA_MAX_VALUE,
  FormControl,
  FormLabel,
  Box,
  Skeleton,
  isNullish,
} from '@spoke/common';

export type GoalDrilldownConfigurationProps = BoxProps & {
  goal: ReturnType<typeof useImprovementGoal>[0];
  isDisabled?: boolean;
};

export const GoalDrilldownConfiguration: FC<
  GoalDrilldownConfigurationProps
> = ({ goal, isDisabled, ...rest }) => {
  const [handleUpdateGoal] = useUpdateImprovementGoalHandler();

  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useSpringState(false);
  const [loadedFirstTarget, setLoadedFirstTarget] = useState(false);

  const [value, setValue] = useState('0');
  const updateTarget = useCallback(
    async (newTarget: string) => {
      if (isNullish(value) || !goal) return;
      setSubmitting(true);
      await handleUpdateGoal({
        improvementGoalId: goal.id,
        target: parseInt(newTarget),
        paused: goal.paused,
      });
      setSubmitting(false);
      setSubmitSuccess(true);
    },
    [value, goal, handleUpdateGoal, setSubmitSuccess]
  );

  const debouncedUpdateTarget = useDebounce(updateTarget, 1250, true);

  const min = goal?.type.minValue;
  const max = goal?.type.maxValue;

  const isInvalid = (toValidate: string) => {
    if (!toValidate.length) return true;
    const castValue = Number(toValidate);
    if (Number.isNaN(castValue)) return true;
    if (castValue > IMPROVEMENT_GOAL_DATA_MAX_VALUE) return true;
    if (!isNullish(min) && castValue < min!) return true;
    if (!isNullish(max) && castValue > max!) return true;
    return false;
  };

  const onValueChange = (newValue: string) => {
    setValue(newValue);
    if (isInvalid(newValue)) return;
    setSubmitting(true);
    debouncedUpdateTarget(newValue);
  };

  useEffect(() => {
    if (goal && !loadedFirstTarget) {
      setValue(goal?.target?.toString() ?? '0');
      setLoadedFirstTarget(true);
    }
  }, [loadedFirstTarget, goal]);

  return (
    <Box {...rest}>
      <FormControl isInvalid={!value}>
        <FormLabel
          htmlFor="target"
          fontWeight={500}
          color="gray.800"
          fontSize={14}
        >
          Target
        </FormLabel>
        {goal ? (
          <ImprovementGoalValueInput
            isInvalid={isInvalid(value)}
            onChange={onValueChange}
            value={value}
            max={max}
            min={min}
            loading={submitting}
            submitSuccess={submitSuccess}
            unit={goal.type.unit}
            isDisabled={isDisabled}
          />
        ) : (
          <Skeleton h="14px" w="full" />
        )}
      </FormControl>
    </Box>
  );
};
