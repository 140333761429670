import { useCallback } from 'react';
import {
  log,
  useToast,
  TOAST_ERROR_GENERIC,
  optimisticCreateActionItemComment,
  createActionItemCommentUpdateFunction,
  useCurrentUser,
} from '@spoke/common';
import { useCreateActionItemCommentMutation } from '@spoke/graphql';

export type AddCommentHandler = (
  actionItemId: string,
  text: string
) => Promise<boolean>;

export const useAddActionItemCommentHandler = () => {
  const [currentUser] = useCurrentUser();
  const [createComment] = useCreateActionItemCommentMutation();
  const [toast] = useToast();

  const handleAddComment = useCallback(
    async (actionItemId: string, text: string): Promise<boolean> => {
      const authorId = currentUser?.id;
      const authorName = currentUser?.name;

      if (!actionItemId || !authorId || !authorName) {
        log.error('Missing critical data to add a actionitem comment', {
          actionItemId,
          authorId,
          authorName,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      const { errors } = await createComment({
        variables: {
          actionItemId,
          text,
        },
        optimisticResponse: optimisticCreateActionItemComment({
          authorName,
          actionItemId,
          text,
        }),
        update: createActionItemCommentUpdateFunction,
      });

      if (errors) {
        log.error('CreateActionItemComment call responded with error', {
          errors,
        });
        toast(TOAST_ERROR_GENERIC);
        return false;
      }

      return true;
    },
    [createComment, currentUser?.id, currentUser?.name, toast]
  );

  return [handleAddComment];
};
