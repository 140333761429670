import deepmerge from 'deepmerge';
import { DeepPartial } from '../../types';

export function deepMerge<T>(
  a: DeepPartial<T>,
  b: DeepPartial<T>,
  mergeArrays: boolean = false
): DeepPartial<T> {
  return deepmerge.all([a, b], {
    arrayMerge: mergeArrays ? undefined : (_, newData) => newData,
  }) as DeepPartial<T>;
}
