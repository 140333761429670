import {
  useState,
  useEffect,
  useCallback,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { useFetchLinkableBitbucketRepositories } from '../../fetchers/useFetchLinkableBitbucketRepositories';
import { useUpdateLinkedBitbucketRepositoriesHandler } from '../../handlers';
import { useCurrentTeamBitbucketRepositories } from '../../queries';
import { useCurrentTeam } from '@spoke/user';
import { BitbucketRepository } from '@spoke/graphql';
import {
  FlexProps,
  useBitbucketRateLimitLock,
  useDisclosure,
  truncateWithCount,
  Flex,
  Icon,
  BitbucketIcon,
  MultiSelect,
  Button,
  Spinner,
  Spacer,
  Text,
} from '@spoke/common';

const MAX_LINKS = 10;

type Props = Omit<FlexProps, 'onChange'> & {
  maxLabelLength?: number;
  showSaveButton?: boolean;
  onSave?: () => void;
  onChange?: (linksChanged: boolean) => void;
  onLoadingChange?: (loading: boolean) => void;
};

export type TeamBitbucketRepoLinkSelectorRefAttributes = {
  onSaveLinkedBitbucketRepos: () => void;
};

export const TeamBitbucketRepoLinkSelectorComponent: ForwardRefRenderFunction<
  TeamBitbucketRepoLinkSelectorRefAttributes,
  Props
> = (
  {
    maxLabelLength = 60,
    showSaveButton = true,
    onSave,
    onChange,
    onLoadingChange,
    ...rest
  },
  ref
) => {
  const [bitbucketRateLimited] = useBitbucketRateLimitLock();
  const [currentTeam] = useCurrentTeam();

  const [fetchLinkableRepos] = useFetchLinkableBitbucketRepositories();
  const [updateLinkedBitbucketRepos] =
    useUpdateLinkedBitbucketRepositoriesHandler();

  const [linkedBitbucketRepos, { loading: linkedBitbucketReposLoading }] =
    useCurrentTeamBitbucketRepositories();
  const [linkedBitbucketReposSubmitting, setLinkedBitbucketReposSubmitting] =
    useState(false);

  const bitbucketReposDropdown = useDisclosure();

  const [editedBitbucketRepos, setEditedBitbucketRepos] = useState<
    BitbucketRepository[]
  >([]);
  useEffect(() => {
    if (!linkedBitbucketRepos || linkedBitbucketReposLoading) return;
    setEditedBitbucketRepos(linkedBitbucketRepos);
  }, [linkedBitbucketRepos, linkedBitbucketReposLoading]);

  const bitbucketRepositoriesLabel = editedBitbucketRepos.length
    ? truncateWithCount(
        editedBitbucketRepos,
        (repo) => repo.name,
        maxLabelLength
      )
    : 'Select your BitBucket repositories';

  const linkedBitbucketReposWereNotEdited =
    linkedBitbucketRepos
      ?.map((r) => r.id)
      .sort()
      .join('-') ===
    editedBitbucketRepos
      .map((r) => r.id)
      .sort()
      .join('-');

  const onSaveLinkedBitbucketRepos = useCallback(async () => {
    if (linkedBitbucketReposWereNotEdited) return;
    onSave?.();
    setLinkedBitbucketReposSubmitting(true);
    await updateLinkedBitbucketRepos({
      bitbucketRepositoryIds: editedBitbucketRepos.map((repo) => repo.id),
    });
    setLinkedBitbucketReposSubmitting(false);
  }, [
    editedBitbucketRepos,
    linkedBitbucketReposWereNotEdited,
    onSave,
    updateLinkedBitbucketRepos,
  ]);

  const getLinkableBitbucketReposByTerm = useCallback(
    async (term: string): Promise<BitbucketRepository[]> => {
      const values = term.length
        ? []
        : [...(linkedBitbucketRepos ?? [])].filter((repo) =>
            term ? repo.name.toLowerCase().includes(term.toLowerCase()) : true
          );

      if (!currentTeam?.id) return values;
      const results = await fetchLinkableRepos({ search: term });
      values.push(...results);

      return values
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(
          (repo, index, self) =>
            self.findIndex((r) => r.id === repo.id) === index
        );
    },
    [currentTeam?.id, fetchLinkableRepos, linkedBitbucketRepos]
  );

  useEffect(() => {
    onChange?.(!linkedBitbucketReposWereNotEdited);
  }, [linkedBitbucketReposWereNotEdited, onChange]);

  useEffect(() => {
    onLoadingChange?.(linkedBitbucketReposSubmitting);
  }, [linkedBitbucketReposSubmitting, onLoadingChange]);

  useImperativeHandle(
    ref,
    () => ({
      onSaveLinkedBitbucketRepos,
    }),
    [onSaveLinkedBitbucketRepos]
  );

  if (bitbucketRateLimited) {
    return (
      <Flex w="full" {...rest}>
        <Text color="orange.300" fontSize="sm" fontWeight="400">
          Our <Icon as={BitbucketIcon} pb="2px" />{' '}
          <Text as="span" fontWeight={600}>
            BitBucket
          </Text>{' '}
          integration is currently unavailable. Please try again in a few
          moments.
        </Text>
      </Flex>
    );
  }

  return (
    <Flex justifyContent="flex-end" w="full" gap={2} flexWrap="wrap" {...rest}>
      <MultiSelect
        searchable
        values={editedBitbucketRepos}
        onChange={setEditedBitbucketRepos}
        onClose={bitbucketReposDropdown.close}
        isOpen={bitbucketReposDropdown.isOpen}
        getOptions={getLinkableBitbucketReposByTerm}
        limit={MAX_LINKS}
        idKey="id"
        labelKeyOrFn="name"
        flex={rest.flexDir === 'column' ? undefined : '1'}
        placeholder="Type to search more repositories"
      >
        <Button
          justifyContent="start"
          onClick={bitbucketReposDropdown.toggle}
          variant="outlineGray"
          leftIcon={<Icon as={BitbucketIcon} mr="-2px" ml="-4px" />}
          disabled={linkedBitbucketReposLoading}
          w="full"
          flex="1"
          overflow="hidden"
        >
          {linkedBitbucketReposLoading ? (
            <Spinner size="sm" ml={2} />
          ) : (
            <Text
              color={editedBitbucketRepos.length ? 'gray.700' : 'gray.500'}
              fontWeight={editedBitbucketRepos.length ? 500 : 400}
              fontSize={15}
              noOfLines={1}
            >
              {bitbucketRepositoriesLabel}
            </Text>
          )}
          <Spacer />
          <Icon as={BsChevronDown} />
        </Button>
      </MultiSelect>
      {showSaveButton && (
        <Button
          isDisabled={linkedBitbucketReposWereNotEdited}
          isLoading={linkedBitbucketReposSubmitting}
          height={10}
          onClick={onSaveLinkedBitbucketRepos}
          alignSelf={rest.flexDir === 'column' ? 'flex-end' : undefined}
        >
          Save
        </Button>
      )}
    </Flex>
  );
};

export const TeamBitbucketRepoLinkSelector = forwardRef(
  TeamBitbucketRepoLinkSelectorComponent
);
