import { FC, useState } from 'react';
import { AiTwotoneCalendar } from 'react-icons/ai';
import { FaChevronLeft } from 'react-icons/fa';
import { MdStickyNote2 } from 'react-icons/md';
import { BoardsInput } from '@spoke/graphql';
import {
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  HStack,
  Heading,
  VStack,
  plural,
  pluralSwitch,
  Flex,
  truncateText,
  Badge,
  Icon,
  SpkTime,
  Button,
  Text,
} from '@spoke/common';

type ImportBoardWizardSummaryProps = {
  parsedData: BoardsInput[];
  onConfirm: () => Promise<void>;
  onCancel: () => void;
};
export const ImportBoardWizardSummary: FC<ImportBoardWizardSummaryProps> = ({
  parsedData,
  onCancel,
  onConfirm,
}) => {
  const [loading, setLoading] = useState(false);
  const boardCount = parsedData.length;

  const onImportBoard = async () => {
    setLoading(true);
    await onConfirm();
    setLoading(false);
  };

  return (
    <ModalContent p={8} pb={4} maxW={600}>
      <ModalCloseButton />
      <ModalBody p={0}>
        <ModalHeader p={0}>
          <HStack>
            <Heading mb={1} fontSize={24} color="gray.900">
              Summary of data to import
            </Heading>
          </HStack>
          <Text fontSize={16} fontWeight={400} color="gray.700" mt={1}>
            Please review the data below and confirm that it looks correct.
          </Text>
        </ModalHeader>
        <VStack spacing={2} my={4}>
          <Text alignSelf="flex-start" fontWeight={500}>
            {boardCount} {plural('retrospective', boardCount)}{' '}
            {pluralSwitch('was', 'were', boardCount)} found:
          </Text>
          <Flex gap={2} w="full" flexDir="column">
            {parsedData.map((board) => {
              const listCount = board.lists?.length || 0;

              return (
                <Flex
                  key={board.boardName}
                  flexDir="column"
                  layerStyle="outlineGray"
                  flex={1}
                  p={4}
                  w="full"
                >
                  <HStack justifyContent="space-between">
                    <Text whiteSpace="nowrap" fontSize={20} fontWeight={600}>
                      {truncateText(board.boardName, 40)}
                    </Text>
                    <Badge variant="lightGray">
                      <Icon mb="3px" as={AiTwotoneCalendar} />
                      <Text>
                        {SpkTime.format(
                          new Date(board.boardDate || 0),
                          'MMM dd, yyyy'
                        )}
                      </Text>
                    </Badge>
                  </HStack>
                  <Text fontSize={12} color="gray.500" my={1}>
                    {listCount} {plural('list', listCount)}
                  </Text>
                  <Flex gap={2} flexWrap="wrap">
                    {board.lists?.map((list) => {
                      if (!list) {
                        return null;
                      }
                      const cardCount = list.cards?.length || 0;

                      return (
                        <HStack key={list.name} layerStyle="outlineGray" py={2}>
                          <Text whiteSpace="nowrap">{list.name}</Text>
                          <Badge variant="lightGray">
                            <Icon mb="3px" as={MdStickyNote2} />
                            {cardCount} {plural('card', cardCount)}
                          </Badge>
                        </HStack>
                      );
                    })}
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </VStack>
        <HStack mb={1} justifyContent="flex-end">
          <Button
            size="lg"
            variant="outlineGray"
            type="button"
            w="fit-content"
            onClick={onCancel}
            isDisabled={loading}
            leftIcon={
              <Icon as={FaChevronLeft} pb="2px" color="gray.600" mr="-4px" />
            }
          >
            Go back
          </Button>
          <Button
            isLoading={loading}
            size="lg"
            w="fit-content"
            type="submit"
            onClick={onImportBoard}
          >
            Next
          </Button>
        </HStack>
      </ModalBody>
    </ModalContent>
  );
};
