import { FC, useState } from 'react';
import { useExportTeamInsightToConfluenceHandler } from '../../handlers';
import { ConfluenceEmbeddedPageModal } from './ConfluenceEmbeddedPageModal';
import {
  ConfluenceSpace,
  Button,
  Icon,
  ConfluenceIcon,
  Menu,
  MenuButton,
  MenuList,
  Divider,
  Flex,
  MenuItem,
  Tooltip,
  Text,
} from '@spoke/common';
import { ConfluencePageUri } from '@spoke/graphql';
import { useConfluenceIntegration, useCurrentOrg } from '@spoke/user';

type Props = {
  teamInsightId: string;
};
export const ExportInsightToConfluence: FC<Props> = ({ teamInsightId }) => {
  const [editingConfluencePageUri, setEditingConfluencePageUri] =
    useState<ConfluencePageUri | null>(null);
  const [exportLoading, setExportLoading] = useState(false);
  const {
    authenticateConfluence,
    confluenceLoading,
    confluenceInstalled,
    confluenceSpaces,
  } = useConfluenceIntegration();
  const [org] = useCurrentOrg();
  const orgName = org?.name || 'organization';

  const tooltipText = `Share this insight in a confluence page that aggregates all important ${orgName} insights`;

  const [handleExportInsightToConfluence] =
    useExportTeamInsightToConfluenceHandler();

  const onExport = async (space: ConfluenceSpace) => {
    setExportLoading(true);
    const confluencePageUri = await handleExportInsightToConfluence({
      teamInsightId,
      confluenceSpaceKey: space.key,
    });
    setExportLoading(false);
    if (confluencePageUri) setEditingConfluencePageUri(confluencePageUri);
  };

  return !confluenceInstalled ? (
    <Tooltip
      variant="white"
      maxW={260}
      label={tooltipText}
      placement="top"
      fontSize={13}
      shouldWrapChildren
      offset={[0, 15]}
    >
      <Button
        variant="outlineGray"
        onClick={authenticateConfluence}
        isLoading={confluenceLoading || exportLoading}
        leftIcon={<Icon as={ConfluenceIcon} />}
      >
        Connect Confluence{' '}
      </Button>
    </Tooltip>
  ) : (
    <Menu placement="top">
      <Tooltip
        variant="white"
        maxW={260}
        label={tooltipText}
        placement="top"
        fontSize={13}
        shouldWrapChildren
        offset={[0, 15]}
      >
        <MenuButton disabled={exportLoading} _hover={{ bg: 'gray.100' }}>
          <Button
            as="span"
            variant="outlineGray"
            isLoading={exportLoading}
            leftIcon={<Icon as={ConfluenceIcon} />}
          >
            Export to Confluence
          </Button>
        </MenuButton>
      </Tooltip>
      <MenuList>
        <Text
          fontSize={13}
          color="gray.700"
          fontWeight={500}
          ml={1}
          maxW={200}
          mt={2}
        >
          Select Confluence space{' '}
        </Text>
        <Divider my={3} />
        <Flex flexDir="column" maxH={300} overflowY="auto">
          {confluenceSpaces?.map((space) => (
            <MenuItem key={space.id} onClick={() => onExport(space)}>
              {space.name}
            </MenuItem>
          ))}
        </Flex>
      </MenuList>
      <ConfluenceEmbeddedPageModal
        isOpen={!!editingConfluencePageUri}
        onClose={() => setEditingConfluencePageUri(null)}
        pageUri={editingConfluencePageUri}
      />
    </Menu>
  );
};
