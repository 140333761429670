import { FC, memo } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  Flex,
  LIST_COLORS,
  Box,
  Text,
  ColorBadge,
} from '@spoke/common';

type BoardListColorPillProps = {
  color: string;
  onChangeColor: (color: string) => void;
  isFacilitator: boolean;
};
export const BoardListColorPill: FC<BoardListColorPillProps> = ({
  color,
  onChangeColor,
  isFacilitator,
}) => (
  <Popover isOpen={isFacilitator ? undefined : false} placement="bottom-end">
    <PopoverTrigger>
      <Box>
        <ColorBadge
          cursor={isFacilitator ? 'pointer' : 'default'}
          color={color}
        />
      </Box>
    </PopoverTrigger>
    <PopoverContent maxW={120}>
      <PopoverArrow />
      <Flex direction="column" bg="white" borderRadius="sm" py={3} px={4}>
        <Text mb={1} fontSize={14} color="gray.700" fontWeight={500}>
          Column color
        </Text>
        <Flex gap="6px" flexWrap="wrap">
          {LIST_COLORS.map((listColor) => (
            <ColorBadge
              key={listColor}
              color={listColor}
              minW="12px"
              minH="30px"
              borderRadius="md"
              cursor="pointer"
              onClick={() => onChangeColor(listColor)}
            />
          ))}
        </Flex>
      </Flex>
    </PopoverContent>
  </Popover>
);

export const MemoizedBoardListColorPill = memo(BoardListColorPill);
