import {
  useCurrentUser,
  useCurrentBoard,
  countVotesByUserId,
} from '@spoke/common';
import { List } from '@spoke/graphql';

export const useCurrentVoteCount = (): [number | null] => {
  const [currentUser] = useCurrentUser();
  const [board] = useCurrentBoard();
  const userId = (currentUser?.id as string) ?? null;

  const userVoteCount = countVotesByUserId({
    lists: board?.lists as List[],
    userId,
  });

  const boardVoteCount = board?.state.voteCount || 0;

  const votesLeft = boardVoteCount - userVoteCount;

  return [votesLeft];
};
