import { Icon, IconProps } from '../Icon';
import React from 'react';

export const ConfluenceIcon = (props: IconProps) => (
  <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 48" {...props}>
    <defs>
      <linearGradient
        x1="99.140087%"
        y1="65.7068123%"
        x2="33.8589812%"
        y2="46.9329228%"
        id="linearGradient-1"
      >
        <stop stopColor="#0052CC" offset="18%" />
        <stop stopColor="#2684FF" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="0.92569163%"
        y1="34.3043893%"
        x2="66.1800713%"
        y2="53.0862751%"
        id="linearGradient-2"
      >
        <stop stopColor="#0052CC" offset="18%" />
        <stop stopColor="#2684FF" offset="100%" />
      </linearGradient>
    </defs>
    <g
      id="Retrospective-Board"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Export_Board_To"
        transform="translate(-1175.000000, -511.000000)"
        fillRule="nonzero"
      >
        <g id="Group-12-Copy-4" transform="translate(1100.000000, 468.000000)">
          <g id="Confluence-blue" transform="translate(75.000000, 43.000000)">
            <path
              d="M2.5234375,36.3515625 C2.015625,37.1796875 1.4453125,38.140625 0.9609375,38.90625 C0.527384271,39.6389009 0.760132998,40.5837913 1.484375,41.03125 L11.640625,47.28125 C11.9971303,47.5013624 12.4270998,47.5690754 12.8339999,47.4691866 C13.2409,47.3692978 13.5906359,47.1101769 13.8046875,46.75 C14.2109375,46.0703125 14.734375,45.1875 15.3046875,44.2421875 C19.328125,37.6015625 23.375,38.4140625 30.671875,41.8984375 L40.7421875,46.6875 C41.1235859,46.8690348 41.5622335,46.8884191 41.9581584,46.7412351 C42.3540833,46.5940512 42.6735503,46.2928398 42.84375,45.90625 L47.6796875,34.96875 C48.0213544,34.1875816 47.6737024,33.2767336 46.8984375,32.921875 C44.7734375,31.921875 40.546875,29.9296875 36.7421875,28.09375 C23.0546875,21.4453125 11.421875,21.875 2.5234375,36.3515625 Z"
              id="Path"
              fill="url(#linearGradient-1)"
            />
            <path
              d="M48.0625,11.53125 C48.5703125,10.703125 49.140625,9.7421875 49.625,8.9765625 C50.0585532,8.24391157 49.8258045,7.29902122 49.1015625,6.8515625 L38.9453125,0.6015625 C38.5858983,0.35948566 38.1420941,0.277540388 37.7199307,0.375304536 C37.2977674,0.473068685 36.9351634,0.741761113 36.71875,1.1171875 C36.3125,1.796875 35.7890625,2.6796875 35.21875,3.625 C31.1953125,10.265625 27.1484375,9.453125 19.8515625,5.96875 L9.8125,1.203125 C9.43110159,1.02159023 8.99245397,1.00220589 8.5965291,1.14938985 C8.20060424,1.29657382 7.88113718,1.59778518 7.7109375,1.984375 L2.875,12.921875 C2.53333314,13.7030434 2.88098505,14.6138914 3.65625,14.96875 C5.78125,15.96875 10.0078125,17.9609375 13.8125,19.796875 C27.53125,26.4375 39.1640625,25.9921875 48.0625,11.53125 Z"
              id="Path"
              fill="url(#linearGradient-2)"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
