import { Card } from '@spoke/graphql';

export const privateVotesFilter =
  (userId: string): ((card: Card | null) => Card | null) =>
  (card) =>
    !card
      ? card
      : {
          ...card,
          votes: card.votes?.filter((voteUserId) => voteUserId === userId),
        };
