import { ApolloCache } from '@apollo/client';
import {
  Team,
  TeamFragmentFragment,
  TeamFragmentFragmentDoc,
} from '@spoke/graphql';

type GetTeamFromCacheArgs = {
  teamId: string;
  cache: ApolloCache<object>;
};
export function getTeamFromCache({
  teamId,
  cache,
}: GetTeamFromCacheArgs): Team | null {
  return (
    (cache.readFragment({
      fragment: TeamFragmentFragmentDoc,
      fragmentName: 'TeamFragment',
      id: `Team:${teamId}`,
    }) as TeamFragmentFragment as Team) || null
  );
}
