import { FC } from 'react';
import { PointChartPopoverProps } from '../PointChart';
import { ImprovementGoalDataPopoverDataPoint } from './ImprovementGoalDataPopoverDataPoint';
import {
  ImprovementGoalTypeTargetType,
  ImprovementGoalUnit,
  ImprovementGoalAggregationPeriod,
} from '@spoke/graphql';
import { Flex, Divider } from '@spoke/common';

type GetImprovementGoalDataPopoverArgs = {
  goalName: string;
  target: number;
  min: number | null | undefined;
  max: number | null | undefined;
  targetType: ImprovementGoalTypeTargetType;
  unit: ImprovementGoalUnit;
  aggregationPeriod: ImprovementGoalAggregationPeriod;
  onDelete: (dataId: string) => Promise<void> | void;
  onEdit: (dataId: string, newValue: number) => Promise<void> | void;
};
export const getImprovementGoalDataPopover =
  ({
    goalName,
    target,
    targetType,
    unit,
    onDelete,
    aggregationPeriod,
    onEdit,
    max,
    min,
  }: GetImprovementGoalDataPopoverArgs): FC<PointChartPopoverProps> =>
  ({ data }) => {
    const dataPoints = data.children?.length ? data.children : [data];

    return (
      <Flex flexDir="column" overflowY="auto" maxH={400} pr={1}>
        {dataPoints.map((point, idx, arr) => (
          <Flex key={point.id} flexDir="column">
            <ImprovementGoalDataPopoverDataPoint
              onDelete={onDelete}
              onEdit={onEdit}
              target={target}
              goalName={goalName}
              aggregationPeriod={aggregationPeriod}
              targetType={targetType}
              unit={unit}
              dataPoint={point}
              min={min}
              max={max}
            />
            {!(idx === arr.length - 1) && (
              <Divider key={`DIVIDER_${point.id}`} my={2} />
            )}
          </Flex>
        ))}
      </Flex>
    );
  };
