import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import {
  UpDown,
  useToast,
  useCurrentUser,
  TOAST_ERROR_GENERIC,
  getCardFromCache,
  optimisticVoteCard,
  updateVoteCountByIdAndUserIdAfterVotingCard,
  log,
} from '@spoke/common';
import { useVoteCardMutation } from '@spoke/graphql';

export type CardVoteHandler = (
  cardId: string,
  upOrDown: UpDown
) => Promise<void>;

type UseCardVoteHandlerArgs = {
  boardId: string;
};
export const useCardVoteHandler = ({
  boardId,
}: UseCardVoteHandlerArgs): [CardVoteHandler] => {
  const [toast] = useToast();
  const { cache } = useApolloClient();
  const [currentUser] = useCurrentUser();
  const [voteCard] = useVoteCardMutation();

  const handleVote: CardVoteHandler = useCallback(
    async (cardId: string, upOrDown: UpDown) => {
      const userId = currentUser?.id;
      const voted = upOrDown === UpDown.Up;

      if (!userId || !boardId || !cardId) {
        log.error('Missing critical data to vote card', {
          userId,
          boardId,
          cardId,
          voted,
        });
        toast(TOAST_ERROR_GENERIC);
        return;
      }

      const card = getCardFromCache({ cache, cardId });

      const { errors } = await voteCard({
        variables: {
          cardId,
          voted,
          boardId,
          userId,
          anonymousUser: false,
        },
        optimisticResponse: optimisticVoteCard({ card, upOrDown, userId }),
        updateQueries: {
          BoardVoteCountByIdAndUserId:
            updateVoteCountByIdAndUserIdAfterVotingCard({ upOrDown }),
        },
      });

      if (errors) {
        log.error('VoteCard call responded with errors', {
          errors,
        });
        return;
      }
    },
    [boardId, cache, currentUser?.id, toast, voteCard]
  );

  return [handleVote];
};
