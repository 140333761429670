import { MutationUpdaterFn } from '@apollo/client';
import { log } from '../../../../SpkLog';
import { CacheModifiers, DeepPartial } from '../../../../types';
import { generateTempId } from '../../generateTempId';
import { Card, CreateCardCommentMutation } from '@spoke/graphql';

type OptimisticCreateCardCommentArgs = {
  cardId: string;
  authorId: string;
  text: string;
  authorName: string;
};
export const optimisticCreateCardComment = ({
  authorName,
  cardId,
  authorId,
  text,
}: OptimisticCreateCardCommentArgs): CreateCardCommentMutation => ({
  __typename: 'Mutation',
  createCardComment: {
    __typename: 'CardComment',
    id: generateTempId(),
    cardId,
    authorId,
    text,
    archived: false,
    createdAt: Date.now(),
    author: { __typename: 'User', id: authorId, name: authorName },
  },
});

export const createCardCommentUpdateFunction: MutationUpdaterFn<
  CreateCardCommentMutation
> = (cache, { data }) => {
  const newComment = data?.createCardComment;

  if (!newComment) {
    log.error('No comment received in createCommentUpdateFunction', {
      newComment,
    });
    return;
  }

  const cardCacheId = 'Card:' + newComment?.cardId;

  const updateFields: CacheModifiers<DeepPartial<Card>> = {
    comments: (prev = []) => {
      if (Array.isArray(prev)) return [...prev, newComment];
      return [newComment];
    },
  };

  cache.modify({
    id: cardCacheId,
    fields: updateFields,
  });
};
