import { IconProps, Icon } from '../Icon';

export const TeamsIcon = (props: IconProps) => (
  <Icon viewBox="0 0 25 26" {...props}>
    <title>E4E45E35-B80D-4423-ACB7-849CA439DE7F</title>
    <desc>Created with sketchtool.</desc>
    <g
      id="Dashboard"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Dashboard_team"
        transform="translate(-145.000000, -349.000000)"
        fill="currentColor"
      >
        <g id="Group-12" transform="translate(100.000000, 176.000000)">
          <g id="Group-35" transform="translate(0.000000, 87.000000)">
            <g id="Group-34" transform="translate(0.000000, 36.000000)">
              <g id="Group-31" transform="translate(45.000000, 49.000000)">
                <g id="Group-5-Copy" transform="translate(3.000000, 6.000000)">
                  <path
                    d="M17,5 C17,2.24 14.76,0 12,0 C9.24,0 7,2.24 7,5 C7,6.52 7.678,7.882 8.75,8.8 C6.52,9.97 5,12.306 5,15 C5,15.552 5.448,16 6,16 C6.552,16 7,15.552 7,15 C7,12.24 9.24,10 12,10 C14.76,10 17,12.24 17,15 C17,15.552 17.448,16 18,16 C18.552,16 19,15.552 19,15 C19,12.307 17.48,9.97 15.25,8.8 C16.323,7.88 17,6.52 17,5 Z M12,8 C10.343,8 9,6.657 9,5 C9,3.343 10.343,2 12,2 C13.657,2 15,3.343 15,5 C15,6.657 13.657,8 12,8 Z"
                    id="Shape"
                  />
                  <path
                    d="M6.98256902,1.4541104e-05 C6.30940047,0.648519309 5.83991378,1.49523802 5.52521681,2.38777555 C4.61473483,2.90161032 4,3.87894115 4,5 C4,5.99790431 4.48708953,6.88192469 5.2365109,7.42730347 C5.54694837,8.80985436 5.96746122,9.86088068 5.39722481,10.2651638 C3.4214035,10.9327936 2,12.8014125 2,15 C2,15.552 1.552,16 1,16 C0.448,16 0,15.552 0,15 C0,12.306 1.52,9.97 3.75,8.8 C2.678,7.882 2,6.52 2,5 C2,2.24 4.24,0 7,0 Z"
                    id="Combined-Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
);
