import { useCallback } from 'react';
import { QueryConfig } from '@spoke/common';
import { usePromoteParkingLotItemMutation } from '@spoke/graphql';

type Config = QueryConfig<typeof usePromoteParkingLotItemMutation> &
  Required<
    Pick<QueryConfig<typeof usePromoteParkingLotItemMutation>, 'variables'>
  >;

export const usePromoteParkingLotItemHandler = () => {
  const [promoteParkingLotItem] = usePromoteParkingLotItemMutation();

  const handlePromoteParkingLotItemToProgramHandler = useCallback(
    async (config: Config) => promoteParkingLotItem(config),
    [promoteParkingLotItem]
  );

  return [handlePromoteParkingLotItemToProgramHandler];
};
