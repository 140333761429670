import { IconProps, Icon } from '../Icon';

export const GoogleIcon = (props: IconProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <g clipPath="url(#clip0_4076_39243)">
      <mask
        id="mask0_4076_39243"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path
          d="M23.7209 9.81818H12.2791V14.4545H18.8651C18.2512 17.4 15.6837 19.0909 12.2791 19.0909C8.26046 19.0909 5.02326 15.9273 5.02326 12C5.02326 8.07273 8.26046 4.90909 12.2791 4.90909C14.0093 4.90909 15.5721 5.50909 16.8 6.49091L20.3721 3C18.1953 1.14545 15.4047 0 12.2791 0C5.46977 0 0 5.34545 0 12C0 18.6545 5.46977 24 12.2791 24C18.4186 24 24 19.6364 24 12C24 11.2909 23.8884 10.5273 23.7209 9.81818Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4076_39243)">
        <path
          d="M-1.11635 19.09V4.9082L8.37202 11.9991L-1.11635 19.09Z"
          fill="#FBBC05"
        />
      </g>
      <mask
        id="mask1_4076_39243"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path
          d="M23.7209 9.81818H12.2791V14.4545H18.8651C18.2512 17.4 15.6837 19.0909 12.2791 19.0909C8.26046 19.0909 5.02326 15.9273 5.02326 12C5.02326 8.07273 8.26046 4.90909 12.2791 4.90909C14.0093 4.90909 15.5721 5.50909 16.8 6.49091L20.3721 3C18.1953 1.14545 15.4047 0 12.2791 0C5.46977 0 0 5.34545 0 12C0 18.6545 5.46977 24 12.2791 24C18.4186 24 24 19.6364 24 12C24 11.2909 23.8884 10.5273 23.7209 9.81818Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_4076_39243)">
        <path
          d="M-1.11635 4.9082L8.37202 11.9991L12.279 8.67184L25.6744 6.54457V-1.0918H-1.11635V4.9082Z"
          fill="#EA4335"
        />
      </g>
      <mask
        id="mask2_4076_39243"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path
          d="M23.7209 9.81818H12.2791V14.4545H18.8651C18.2512 17.4 15.6837 19.0909 12.2791 19.0909C8.26046 19.0909 5.02326 15.9273 5.02326 12C5.02326 8.07273 8.26046 4.90909 12.2791 4.90909C14.0093 4.90909 15.5721 5.50909 16.8 6.49091L20.3721 3C18.1953 1.14545 15.4047 0 12.2791 0C5.46977 0 0 5.34545 0 12C0 18.6545 5.46977 24 12.2791 24C18.4186 24 24 19.6364 24 12C24 11.2909 23.8884 10.5273 23.7209 9.81818Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_4076_39243)">
        <path
          d="M-1.11635 19.09L15.6278 6.54457L20.0371 7.09002L25.6744 -1.0918V25.09H-1.11635V19.09Z"
          fill="#34A853"
        />
      </g>
      <mask
        id="mask3_4076_39243"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path
          d="M23.7209 9.81818H12.2791V14.4545H18.8651C18.2512 17.4 15.6837 19.0909 12.2791 19.0909C8.26046 19.0909 5.02326 15.9273 5.02326 12C5.02326 8.07273 8.26046 4.90909 12.2791 4.90909C14.0093 4.90909 15.5721 5.50909 16.8 6.49091L20.3721 3C18.1953 1.14545 15.4047 0 12.2791 0C5.46977 0 0 5.34545 0 12C0 18.6545 5.46977 24 12.2791 24C18.4186 24 24 19.6364 24 12C24 11.2909 23.8884 10.5273 23.7209 9.81818Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_4076_39243)">
        <path
          d="M25.6744 25.09L8.37204 11.9991L6.13948 10.3627L25.6744 4.9082V25.09Z"
          fill="#4285F4"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_4076_39243">
        <rect width={24} height={24} fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
