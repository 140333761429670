import {
  FormControl as ChakraFormControl,
  FormControlProps as ChakraFormControlProps,
} from '@chakra-ui/react';
import { FC } from 'react';

type Props = ChakraFormControlProps;

export const FormControl: FC<Props> = (props) => (
  <ChakraFormControl {...props} />
);
